import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../../../../../components/Form/Form";
import styled from "styled-components";
import { RegularTextSmall } from "../../../../../../components/Typography/Typography";
import { IDMobile } from "../../../../../../components/IDs/IDs";
import type {
  UploadAssetsResponseSchema,
  UploadedAssetInProgressSchema,
} from "../../../../../../types/types.PIM";
import { Table } from "../../../../../../components/Table/Table";
import type { Dispatch, FormEvent, SetStateAction } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TablePlaceholder, useStoreState } from "../../../../../../util/util";
import { formatBytes } from "../util/AssetsUtil";
import { IconGroup } from "../../../../../../components/DocumentCard/DocumentCard";
import {
  DeleteButton,
  EditButton,
  InvisibleButton,
  PrimaryButtonFitContainer,
} from "../../../../../../components/Buttons/Buttons";
import {
  LoadingIcon,
  WarningIcon,
} from "../../../../../../components/Icons/Icons";
import ReactTooltip from "react-tooltip";
import type { AxiosError } from "axios";
import axios from "axios";
import { endpoints } from "../../../../../../endpoints";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import {
  ActiveCheckIcon,
  DestructiveXIcon,
} from "../../components/ProductCollectionSchemaTable/ProductCollectionSchema.util";

const TableContainer = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.colors.secondaryLightGray};
  max-height: 70vh;
  min-height: 250px;
  div[class*="Table__TableWrapper"] {
    position: relative;
    th {
      position: sticky;
      top: -1px;
    }
  }
`;

const Input = styled.input.attrs({})`
  height: 32px;
  width: 100%;
  min-width: 100px;
  border: ${({ theme }) => `1 px solid ${theme.tertiaryBorder}`};
  padding: 4px 0;
  outline: none;
  margin: 0;
  &:focus {
    border-radius: 4px;
    border: ${({ theme }) => `2px solid ${theme.tertiaryBorder}`};
  }
  :focus:invalid {
    border: 2px solid ${({ theme }) => theme.errorColor};
  }
  :invalid {
    border: 1px solid ${({ theme }) => theme.errorColor};
  }
`;

type TableData = UploadedAssetInProgressSchema & { edit: boolean };

export const ProcessUploadedAssets = ({
  onComplete,
  uploadUUID,
  uploadNumber,
  uploadCount,
  setUploadCount,
  assets,
}: {
  onComplete: () => void;
  uploadUUID: string;
  uploadNumber: string;
  uploadCount: number;
  setUploadCount: Dispatch<SetStateAction<number>>;
  assets: UploadedAssetInProgressSchema[];
}) => {
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotifications();

  const [tableData, setTableData] = useState<TableData[]>(
    assets.map((asset) => ({ ...asset, edit: false }))
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState<string>();
  const [deletingId, setDeletingId] = useState<string>();

  const putUpdate = useCallback(
    (updatedTableData: TableData[]) => {
      const update = async () => {
        const requestBody = {
          data: updatedTableData.map(({ asset_name, id }) => ({
            id,
            asset_name,
          })),
        };
        try {
          const {
            data: { data },
          } = await axios.put<UploadAssetsResponseSchema>(
            endpoints.v2_tenants_tenant_id_pim_assets_uploads_upload_id(
              tenant_id,
              uploadUUID
            ),
            requestBody
          );
          setTableData(data.map((asset) => ({ ...asset, edit: false })));
          setIsEditing(false);
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("Could not update uploaded assets. Please try again later."),
            {
              error,
            }
          );
        } finally {
          setDeletingId(undefined);
          setEditingId(undefined);
        }
      };
      update();
    },
    [notifyError, t, tenant_id, uploadUUID]
  );

  const exitEditMode = (id: string) => {
    setIsEditing(false);
    setTableData((prev) =>
      prev.map((data) => (data.id === id ? { ...data, edit: false } : data))
    );
  };

  const enterEditMode = (id: string) => {
    setIsEditing(true);
    setTableData((prev) =>
      prev.map((data) => (data.id === id ? { ...data, edit: true } : data))
    );
  };

  const tableColumns = useMemo(() => {
    const deleteAsset = (id: string, existingData: TableData[]) => {
      setDeletingId(id);
      setIsEditing(true);
      setUploadCount(uploadCount - 1);
      const updatedData = existingData.filter((data) => data.id !== id);
      putUpdate(updatedData);
    };

    const updateTableData = ({
      id,
      text,
      existingData,
    }: {
      id: string;
      text: string;
      existingData: TableData[];
    }) => {
      setEditingId(id);
      const updatedTableData = existingData.map((data) =>
        data.id === id ? { ...data, asset_name: text } : data
      );
      putUpdate(updatedTableData);
    };

    const onEditSubmit = (id: string, text: string) => {
      updateTableData({
        id,
        text,
        existingData: tableData,
      });
    };

    return [
      {
        Header: t("File Name"),
        accessor: "file_name",
      },
      {
        Header: t("Asset Name"),
        accessor: "asset_name",
        Cell: ({
          value,
          row: { original },
        }: {
          value: string;
          row: {
            original: UploadedAssetInProgressSchema & { edit: boolean };
          };
        }) => {
          return original.edit ? (
            <EditAssetName
              name={original.id}
              text={original.asset_name}
              onSubmit={(textVal) => onEditSubmit(original.id, textVal)}
            />
          ) : (
            <span>{value}</span>
          );
        },
      },
      {
        Header: t("Size"),
        accessor: "size",
        Cell: ({ value }: { value: number }) => (
          <span>{formatBytes(value)}</span>
        ),
      },
      {
        Header: "",
        accessor: " ",
        Cell: ({
          row: { original },
        }: {
          row: {
            original: TableData;
          };
        }) => {
          return original.edit ? (
            editingId === original.id ? (
              <LoadingIcon width={20} height={20} />
            ) : (
              <IconGroup style={{ justifyContent: "flex-end" }}>
                <InvisibleButton form={`form-${original.id}`} type="submit">
                  <ActiveCheckIcon />
                </InvisibleButton>
                <InvisibleButton onClick={() => exitEditMode(original.id)}>
                  <DestructiveXIcon />
                </InvisibleButton>
              </IconGroup>
            )
          ) : (
            <IconGroup style={{ justifyContent: "flex-end" }}>
              {original.errors ? (
                <>
                  <span
                    data-tip={original.errors ? original.errors : ""}
                    data-for={`${original.id}_error_tooltip`}
                    style={{ width: "43px", textAlign: "center" }}
                  >
                    <WarningIcon width={20} height={20} />
                  </span>
                  <ReactTooltip
                    id={`${original.id}_error_tooltip`}
                    type="dark"
                  />
                </>
              ) : (
                <EditButton
                  disabled={isEditing}
                  onClick={() => enterEditMode(original.id)}
                />
              )}
              {deletingId === original.id ? (
                <LoadingIcon width={20} height={20} />
              ) : (
                <DeleteButton
                  disabled={isEditing}
                  onClick={() => deleteAsset(original.id, tableData)}
                />
              )}
            </IconGroup>
          );
        },
      },
    ];
  }, [
    deletingId,
    editingId,
    isEditing,
    putUpdate,
    t,
    tableData,
    setUploadCount,
    uploadCount,
  ]);

  const tableCaption = () => {
    const successfulUploads = tableData.filter((asset) => !asset.errors).length;
    const errorCount = tableData.length - successfulUploads;
    return tableData.length ? (
      <>
        {tableData.length < uploadCount && (
          <>
            <LoadingIcon width={22} height={22} />
          </>
        )}
        <span style={{ padding: "0 12px 0 8px" }}>
          {successfulUploads} / {uploadCount || tableData.length}{" "}
          {t("assets uploaded")}
        </span>
        {errorCount > 0 && (
          <>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "arrow",
              }}
            >
              (<WarningIcon width={26} height={18} />{" "}
              {`${errorCount} error${errorCount > 1 ? "s" : ""} found `})
            </span>
          </>
        )}
      </>
    ) : (
      ""
    );
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      // submit
      await axios.post(
        endpoints.v2_tenants_tenant_id_pim_assets_uploads_upload_id_submit(
          tenant_id,
          uploadUUID
        )
      );
      notifySuccess(`${uploadNumber} ${t("successfully uploaded")}`);
      setIsSubmitting(false);
      onComplete();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error uploading your files."),
        {
          error,
        }
      );
      setIsSubmitting(false);
    }
  };

  const hasError =
    !tableData.length || tableData.some((data) => Boolean(data.errors));

  useEffect(() => {
    if (assets) {
      setTableData(assets.map((asset) => ({ ...asset, edit: false })));
    }
  }, [assets]);

  return (
    <div style={{ marginTop: "37px" }}>
      <SectionTitle>{t("Assets")}</SectionTitle>
      <TableContainer>
        <div style={{ display: "flex", gap: "16px" }}>
          <IDMobile>{uploadNumber}</IDMobile>
          <RegularTextSmall style={{ display: "flex", alignItems: "center" }}>
            {tableCaption()}
          </RegularTextSmall>
        </div>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={false}
          error={undefined}
          Placeholder={<TablePlaceholder message={t("No items to show.")} />}
        />
      </TableContainer>
      <PrimaryButtonFitContainer
        style={{ marginTop: "37px", marginBottom: "20px", maxWidth: "512px" }}
        loading={isSubmitting}
        disabled={tableData.length < uploadCount || hasError}
        onClick={onSubmit}
      >
        {t("Save")}
      </PrimaryButtonFitContainer>
    </div>
  );
};

const EditAssetName = ({
  text,
  name,
  onSubmit,
}: {
  text: string;
  name: string;
  onSubmit: (value: string) => void;
}) => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    const formData = new FormData(form);
    onSubmit((formData.get(name) as String).trim());
    e.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit} id={`form-${name}`}>
      <Input
        type="text"
        id={name}
        name={name}
        autoComplete="edit_asset_name"
        required
        defaultValue={text}
      />
    </form>
  );
};
