import React, { useContext, useState } from "react";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { Form } from "../../layout/FormLayout";
import { useFormWrapper } from "../../util/util";
import { Title } from "../Typography/Typography";
import { TextField } from "../TextFields/TextFields";
import { TextArea } from "../TextArea/TextArea";
import type { IAboutUsData } from "./AboutUs";
import { useSaveHomePage } from "../../pages/public/HomePage/useSaveHomePage";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { Notifications } from "../Notifications/NotificationsContext";

type EditAboutUsProps = {
  endEditMode: () => void;
  homePageData: IHomePageData;
};

export const EditAboutUs = ({
  endEditMode,
  homePageData,
}: EditAboutUsProps) => {
  const { saveHomePage } = useSaveHomePage();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useContext(Notifications);

  const component = homePageData.config.components.find(
    (component) => component.name === "about_us"
  );
  const data = component?.data;
  const title: string = data?.title;
  const description = data?.desc;

  const { register, handleSubmit, formState, errors } = useFormWrapper({
    defaultValues: {
      title,
      desc: description,
    },
  });

  const onSubmit = async (input: IAboutUsData) => {
    setLoading(true);
    const component = homePageData.config.components.find(
      (component) => component.name === "about_us"
    );

    if (component) {
      component.data = input;
    }
    try {
      const success = await saveHomePage(homePageData);
      if (success) {
        setLoading(false);
        endEditMode();
      }
    } catch (error) {
      setLoading(false);
      notifyError("There was an error updating the homepage");
    }
  };

  return (
    <>
      <Title>Edit About Us</Title>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          name={"title"}
          label={"Section Title"}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <TextArea
          name={"desc"}
          label="Description"
          theref={register({ required: true })}
          formState={formState}
          required={false}
        />
        <PrimaryButtonFitContainer loading={loading}>
          Save Changes
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
