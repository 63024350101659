import React from "react";
import {
  FullWidthHorizontalSeparator,
  PageTitle,
  PageWrapper,
} from "../../../../layout/portalPageLayout";
import { useTranslation } from "react-i18next";
import { SellerAdminTeamsListingPage } from "./SellerAdminTeamsList";

/**
 * wrap the "teams table" for user as a standalone page.
 * @returns
 */
export function SellerAdminTeamsListPageWrapper() {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageTitle>{t("Teams")}</PageTitle>
      <FullWidthHorizontalSeparator />
      <SellerAdminTeamsListingPage />
    </PageWrapper>
  );
}
