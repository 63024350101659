import { useTranslation } from "react-i18next";
import { H3, H5 } from "../../../../../components/Typography/Typography";
import type { AttributeSchema } from "../../../../../types/types.PIM";
import {
  useFormWrapper,
  useStoreState,
  useSupportedLanguages,
} from "../../../../../util/util";
import { Form } from "../../../../../layout/FormLayout";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { useMemo, useState } from "react";
import {
  PrimaryButtonMedium,
  PrimaryCancelButton,
} from "../../../../../components/Buttons/Buttons";
import type {
  DataMutate,
  TranslationsSchema,
} from "../../../../../types/types";
import type { AxiosError } from "axios";
import axios from "axios";
import { endpoints } from "../../../../../endpoints";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import {
  LanguageTranslationContainer,
  TranslationsAccordionCard,
  TranslationsButtonContainer,
  TranslationsModifiedRow,
  TranslationsPropertyItem,
} from "../../../../../components/AccordionCard/TranslationsAccordionCard";
import { Table } from "../../../../../components/Table/Table";
import { ContentWrapper } from "../../../../../layout/portalPageLayout";
import { Break } from "../../../../../layout/shared";
import { getTranslation } from "../SellerAdminPIMAttributesCollections/util";
import { WarningIcon } from "../../../../../components/Icons/Icons";

export const AttributeTranslations = ({
  attribute,
  mutate,
}: {
  attribute: AttributeSchema;
  mutate: DataMutate<AttributeSchema>;
}) => {
  const { t } = useTranslation();

  return (
    <TranslationsAccordionCard
      tableElement={<TranslationsTable attribute={attribute} />}
      subtitle={t("Manage translations for this attribute.")}
      editElement={(onClose) => (
        <EditAttributeTranslations
          attribute={attribute}
          mutate={mutate}
          onClose={onClose}
        />
      )}
    />
  );
};

const TranslationsTable = ({ attribute }: { attribute: AttributeSchema }) => {
  const { t } = useTranslation();
  const { getLanguageLabel } = useSupportedLanguages();

  const defaultLang = "en";

  const tableColumns = useMemo(
    () => [
      {
        Header: t("Language"),
        accessor: "language",
      },
      {
        Header: t("Display Name"),
        accessor: "display_name",
      },
      {
        Header: t("Short Description"),
        accessor: "description",
      },
    ],
    [t]
  );

  return (
    <ContentWrapper>
      <Table
        columns={tableColumns}
        data={attribute.translations.map(
          ({ language, display_name, description }) => ({
            language: getLanguageLabel(language),
            display_name:
              language === defaultLang ? attribute.display_name : display_name,
            description:
              language === defaultLang ? attribute.description : description,
          })
        )}
        isLoading={false}
        lastChildleftAlign
        error={undefined}
      />
    </ContentWrapper>
  );
};

const EditAttributeTranslations = ({
  attribute,
  mutate,
  onClose,
}: {
  attribute: AttributeSchema;
  mutate: DataMutate<AttributeSchema>;
  onClose: () => void;
}) => {
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [warningText, setWarningText] = useState<{ [prop: string]: string }>(
    {}
  );
  const { supported_languages } = useSupportedLanguages();
  const { t, i18n } = useTranslation();
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useNotifications();
  const methodsOfUseForm = useFormWrapper();
  const { errors, formState, handleSubmit, register } = methodsOfUseForm;

  const defaultTranslation = {
    language: "en",
    description: attribute.description ?? "",
    display_name: attribute.display_name,
  };

  const isDefaultKey = (key: string) =>
    key.split("_")?.[0] === defaultTranslation.language;

  const onSubmit = async (formValues: { [prop: string]: string }) => {
    const warningObj = { ...warningText };
    let shouldReturn = false;
    for (let [key, value] of Object.entries(formValues)) {
      if (!value && !warningText[key] && !isDefaultKey(key)) {
        warningObj[key] = t("Missing translation");
        shouldReturn = true;
      } else {
        warningObj[key] = "";
      }
    }
    setWarningText(warningObj);
    if (shouldReturn) {
      return;
    }
    setOnSubmitting(true);
    try {
      const translationsArr = Object.keys(supported_languages).reduce<
        TranslationsSchema[]
      >(
        (acc, language) =>
          language === defaultTranslation.language
            ? acc
            : [
                ...acc,
                {
                  language,
                  display_name: formValues[`${language}_display_name`],
                  description: formValues[`${language}_description`],
                },
              ],
        []
      );
      await axios.put(
        endpoints.v2_tenants_tenant_id_pim_attribute_id_translations(
          tenant_id,
          "attributes",
          attribute.id
        ),
        { translations: translationsArr }
      );
      await mutate();
      await i18n.reloadResources();
      notifySuccess(t("Translations updated successfully"));
      setOnSubmitting(false);
      onClose();
      window.location.reload();
    } catch (error) {
      setOnSubmitting(false);
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not update translations. Please try again later."),
        {
          error,
        }
      );
    }
  };
  return (
    <>
      <H3 style={{ margin: "0 0 24px" }}>{t("Manage Translations")}</H3>
      <H5 style={{ margin: "0 0 16px" }}>{t("Attribute details")}</H5>
      <TranslationsModifiedRow>
        <div style={{ flex: 1 }}>
          <TranslationsPropertyItem
            property={t("Attribute Name")}
            value={attribute.name}
          />
        </div>
        <div style={{ flex: 1 }}>
          <TranslationsPropertyItem
            property={t("Display Name")}
            value={attribute.display_name ? t([attribute.display_name]) : "--"}
          />
        </div>
      </TranslationsModifiedRow>
      <TranslationsModifiedRow>
        <TranslationsPropertyItem
          property={t("Short Description")}
          value={attribute.description ? t([attribute.description]) : "--"}
        />
      </TranslationsModifiedRow>
      <Break style={{ marginBottom: "24px" }} />
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        {Object.entries(supported_languages).map(
          ([langCode, languageLabel]) => (
            <LanguageTranslationContainer key={langCode}>
              <H5>{languageLabel}</H5>
              <TextField
                name={`${langCode}_display_name`}
                label={t("Display Name")}
                theref={register({ required: false })}
                formState={formState}
                errors={errors}
                type="text"
                defaultValue={
                  getTranslation({
                    langCode,
                    translations: attribute.translations,
                    defaultTranslation,
                  }).display_name
                }
                readOnly={defaultTranslation.language === langCode}
                warningText={{
                  message: warningText[`${langCode}_display_name`],
                }}
                Icon={
                  warningText[`${langCode}_display_name`]
                    ? WarningIcon
                    : undefined
                }
              />
              <TextField
                name={`${langCode}_description`}
                label={t("Short Description")}
                theref={register({ required: false })}
                formState={formState}
                errors={errors}
                type="text"
                defaultValue={
                  getTranslation({
                    langCode,
                    translations: attribute.translations,
                    defaultTranslation,
                  }).description
                }
                readOnly={defaultTranslation.language === langCode}
                warningText={{
                  message: warningText[`${langCode}_description`],
                }}
                Icon={
                  defaultTranslation.language === langCode
                    ? undefined
                    : warningText[`${langCode}_description`]
                    ? WarningIcon
                    : undefined
                }
              />
            </LanguageTranslationContainer>
          )
        )}
        <TranslationsButtonContainer>
          <PrimaryCancelButton style={{ flex: 1 }} onClick={onClose}>
            {t("Cancel")}
          </PrimaryCancelButton>
          <PrimaryButtonMedium
            loading={onSubmitting}
            style={{ flex: 1 }}
            type="submit"
          >
            {t("Confirm")}
          </PrimaryButtonMedium>
        </TranslationsButtonContainer>
      </Form>
    </>
  );
};
