import React from "react";
import { Modal } from "../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { H4, H6Bold } from "../../../components/Typography/Typography";
import { InfoIconWithMessage } from "../../../components/Icons/Icons";
import { useForm } from "react-hook-form";
import { TextArea } from "../../../components/TextArea/TextArea";
import { Form } from "../../../layout/FormLayout";
import { ToggleSwitch } from "../../../components/ToggleSwitch/ToggleSwitch";
import {
  DestructiveButton,
  PrimaryButtonFitContainer,
} from "../../../components/Buttons/Buttons";
import styled from "styled-components/macro";
import type { LeadRejectionArgsSchema } from "../../../types/types";

const ButtonContainerSideBySide = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 6px;
`;

type FormValues = {
  internal_message: string;
  message_for_buyer: string;
  buyer_message_is_required: boolean;
  send_email_to_buyer: boolean;
};

type LeadRejectionModalProps = {
  titleString: string;
  show: boolean;
  closeModal: () => void;
  onFormSubmit: (_: LeadRejectionArgsSchema) => Promise<void>;
};

export function LeadRejectionModal({
  titleString,
  show,
  closeModal,
  onFormSubmit,
}: LeadRejectionModalProps) {
  const { t } = useTranslation();

  const { formState, register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      send_email_to_buyer: true,
    },
  });

  const sendRejectionEmail = watch("send_email_to_buyer");

  const onSubmit = ({
    internal_message,
    message_for_buyer,
    send_email_to_buyer,
  }: FormValues) => {
    onFormSubmit({
      internal_message,
      message_for_buyer,
      send_email_to_buyer,
    });
  };

  return (
    <Modal overlay show={show} closeModal={closeModal}>
      <H4 style={{ marginBottom: "16px" }}>{titleString}</H4>
      <H6Bold>{t("Please provide a reason below")}</H6Bold>
      <div style={{ paddingBottom: "16px", paddingTop: "8px" }}>
        <InfoIconWithMessage message={t("This is for internal use only")} />
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          name={"internal_message"}
          label={t("Reason for rejection")}
          theref={register({ required: false })}
          formState={formState}
          required={false}
          errors={errors}
        />
        <ToggleSwitch
          label={t("Send rejection message to lead")}
          name="send_email_to_buyer"
          theref={register({ required: false })}
          isChecked={true}
        />

        {sendRejectionEmail && (
          <>
            <InfoIconWithMessage
              message={t(
                "This note will be attached along with the automated email to the lead"
              )}
            />
            <TextArea
              name={"message_for_buyer"}
              label={t("Message to lead (Optional)")}
              theref={register({ required: false })}
              formState={formState}
              required={false}
              errors={errors}
            />
          </>
        )}
        <ButtonContainerSideBySide>
          <DestructiveButton type="button" onClick={closeModal}>
            {t("Cancel")}
          </DestructiveButton>
          <PrimaryButtonFitContainer type="submit">
            {t("Continue")}
          </PrimaryButtonFitContainer>
        </ButtonContainerSideBySide>
      </Form>
    </Modal>
  );
}
