import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import useSWR from "swr";
import { useAuthContext } from "../../../../../components/Auth";
import {
  GoBackButtonSmall,
  PrimaryButtonWithPlusIcon,
} from "../../../../../components/Buttons/Buttons";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../../components/Table/Table";
import { PageWrapper } from "../../../../../layout/portalPageLayout";
import { ContentWrapper } from "../../../../../layout/publicPageLayout";
import type { UUID } from "../../../../../types/types";
import type { AttributeTemplateSchema } from "../../../../../types/types.PIM";
import { useRoutePath } from "../../../../../util/Routing";
import { useStoreState, rowHover, toTitleCase } from "../../../../../util/util";
import { NameWithSystemDefaultIcon } from "../components/PIM.components.util";
import { AddGroupToTemplateForm } from "./AddGroupToTemplateForm";
import { TemplatesNav } from "./TemplatesNav";

export function SellerAdminTemplateGroupDetail() {
  const history = useHistory();
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const { template_id } = useParams<{ template_id: string }>();
  const { hasPermission } = useAuthContext();
  const [showAddGroupForm, setShowAddGroupForm] = useState(false);
  const hideAddGroupForm = () => setShowAddGroupForm(false);
  const setAddGroupFormVisible = () => setShowAddGroupForm(true);

  const {
    data: template,
    error: templateError,
    mutate: mutateTemplate,
  } = useSWR<AttributeTemplateSchema>(
    `/v2/tenants/${tenant_id}/pim/templates/${template_id}`
  );

  const isLoading = !template && !templateError;
  const [tableData, setTableData] = useState<
    {
      attribute_count: number;
      name: { name: string; is_system: boolean; id: string };
      id: UUID;
      display_name: string;
      restricted: "yes" | "no";
    }[]
  >([]);

  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Group Name"),
        accessor: "name",
        Cell: ({
          value,
        }: {
          value: { name: string; is_system: boolean; id: string };
        }) => (
          <NameWithSystemDefaultIcon
            name={value.name}
            id={value.id}
            is_system={value.is_system}
          />
        ),
      },
      { Header: t("Display Name"), accessor: "display_name" },
      {
        // I feel like this wrapping in styled divs shoudln't be needed but this
        // is a way to make it look like the design.
        Header: t("No. of Attributes"),
        accessor: "attribute_count",
        Cell: ({ value }: { value: number }) => (
          <div style={{ textAlign: "left" }}>{value}</div>
        ),
      },
      {
        Header: t("Restricted"),
        accessor: "restricted",
        Cell: ({ value }: { value: "yes" | "no" }) => (
          <div>{toTitleCase(value)}</div>
        ),
      },
    ],
    [t]
  );

  useEffect(() => {
    if (template) {
      setTableData(
        template.groups.map((group) => ({
          id: group.id,
          attribute_count: group.attribute_count,
          name: {
            name: group.name,
            is_system: group.is_system,
            id: group.id,
          },
          display_name: group.display_name ? t([group.display_name]) : "",
          restricted: group.is_restricted ? "yes" : "no",
        }))
      );
    }
  }, [template, setTableData, t]);

  const handleRowClick = (e: React.MouseEvent) => {
    history.push(
      `${adminPath}/pim/templates/${template_id}/groups/${e?.currentTarget?.id}`
    );
  };

  const isDefaultTemplate = template?.template_name === "Default Template";
  const addGroupTitle = isDefaultTemplate
    ? t("Cannot add groups to default template")
    : undefined;

  return (
    <PageWrapper>
      <div style={{ width: "fit-content" }}>
        <Link to={`${adminPath}/pim/templates`}>
          <GoBackButtonSmall text={"Back"} />
        </Link>
      </div>
      <TemplatesNav
        pageTitle={template?.template_name ?? "Template"}
        tabIndex={0}
        templateID={template_id}
      />
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={templateError}
          rowClick={handleRowClick}
          rowHover={rowHover}
          lastChildleftAlign
        />
        {hasPermission("modify_templates") && (
          <PrimaryButtonWithPlusIcon
            style={{ marginTop: "24px" }}
            onClick={setAddGroupFormVisible}
            disabled={isDefaultTemplate}
            title={addGroupTitle}
          >
            {t("Add Group")}
          </PrimaryButtonWithPlusIcon>
        )}
        <SlideOut show={showAddGroupForm} closeFlyout={hideAddGroupForm}>
          <AddGroupToTemplateForm
            templateID={template_id}
            isTemplateInUse={template?.is_frozen}
            onSuccess={() => {
              mutateTemplate();
              hideAddGroupForm();
            }}
          />
        </SlideOut>
      </ContentWrapper>
    </PageWrapper>
  );
}
