import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";
import { SecondaryButtonSmall } from "../Buttons/Buttons";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import type { OptionType } from "../../types/types";
import { FileTypeAndName } from "../FileCard/FileCard";
import { useFormWrapper } from "../../util/util";

export const UploadWrapper = styled.div<{ error?: boolean }>`
  padding: 18px 25px 20px;
  margin: 10px 0 30px !important;
  border: ${({ theme, error }) =>
    error ? "2px solid" + theme.errorColor : "1px solid" + theme.primaryBorder};
  border-radius: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: 13px;
  background: ${({ theme }) => theme.primaryBG};
  min-height: 115px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const DropHereMessage = styled.div`
  margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: 12px;
  margin-bottom: 20px !important;
`;

/**
 * A widget to let the user pick a file, usually to upload it. Supports picking
 * by drag and drop or by dialog. Any actual file uploading is done by a
 * callback function (the handleFile prop).
 *
 * It optionally shows a menu to select the type of the file (if fileTypes prop
 * is defined), and in that case requires one of those menu options to be
 * selected. By default the first one is selected. If the value of the first
 * one is an empty string then that doesn't count as having an option selected,
 * and the user will need to select another one.
 */
export const FilePicker = ({
  handleFile,
  fileTypes,
  placeHolderText = "Drag and drop a file here or",
}: {
  handleFile: (file: File, fileType?: string) => void;
  fileTypes?: OptionType<string>[];
  placeHolderText?: string;
}) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>();

  const [selectedFileType, setSelectedFileType] = useState<string | undefined>(
    fileTypes?.[0].value || undefined
  );
  const [showTypeError, setShowTypeError] = useState<boolean>(false);

  const { formState, errors } = useFormWrapper({});

  const onDrop = useCallback((files: File[]) => {
    setSelectedFile(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleFileTypeChange = (option: OptionType<string>) => {
    setSelectedFileType(option.value);
  };

  /**
   * Called when the selected file or selected file type changes. If everything
   * is ready, it calls the handleFile callback, passing the file and file type.
   */
  useEffect(() => {
    if (selectedFile) {
      if (fileTypes && !selectedFileType) {
        setShowTypeError(true);
      } else {
        setShowTypeError(false);
        handleFile(selectedFile, selectedFileType);
        setSelectedFile(undefined);
      }
    }
  }, [selectedFile, selectedFileType, fileTypes, handleFile]);

  return (
    <>
      {fileTypes && (
        <>
          <form noValidate>
            <SelectBoxV2
              name="Document_type"
              placeholder="Document Type"
              defaultValue={{ label: "Technical Data Sheet", value: "tds" }}
              required={true}
              onChange={handleFileTypeChange}
              options={fileTypes}
              errors={errors}
              formState={formState}
            />
          </form>
          {showTypeError && (
            <ErrorMessage>Select document type to upload.</ErrorMessage>
          )}
        </>
      )}

      <UploadWrapper {...getRootProps()}>
        {/* For automated UI testing, it is difficult to do drag-n-drop or the
            file picker dialog, so the tests are setting the file path property
            on this input directly.  Hence the need for this data-testid. */}
        <input {...getInputProps()} data-testid="file-picker-input" />

        {isDragActive ? (
          <p>Drop the document here...</p>
        ) : (
          <>
            {fileTypes && !selectedFileType && selectedFile?.name ? (
              // A file has been selected, but not a file type, so show the
              // file name until a file type is selected.
              <FileTypeAndName fileName={selectedFile.name} />
            ) : (
              <>
                <DropHereMessage>{placeHolderText}</DropHereMessage>
                <SecondaryButtonSmall onClick={(e) => e.preventDefault()}>
                  Click to select file
                </SecondaryButtonSmall>
              </>
            )}
          </>
        )}
      </UploadWrapper>
    </>
  );
};
