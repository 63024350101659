import { useTranslation } from "react-i18next";
import { Row } from "../../../../../components/Layout/Layout";
import { H3, H6 } from "../../../../../components/Typography/Typography";
import { SettingsCard } from "../../../../../layout/portalPageLayout";
import { SearchFilterCaption } from "../PortfolioControlsTab/PorfolioControlsTab";
import { useEffect, useState } from "react";
import {
  CancelButtonSmall,
  EditButton,
  PrimaryButtonMedium,
} from "../../../../../components/Buttons/Buttons";
import type { StorefrontMetaData } from "../../../../../types/types";
import { ToggleWithController } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import type { AxiosError } from "axios";
import axios from "axios";
import { endpoints } from "../../../../../endpoints";
import { useFormWrapper, useStoreState } from "../../../../../util/util";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import { Form } from "../../../../../layout/FormLayout";
import { Controller } from "react-hook-form";

export const LeadTransactionSettings = ({
  storefrontMetadata,
  mutateMetadata,
}: {
  storefrontMetadata: StorefrontMetaData | undefined;
  mutateMetadata: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { notifySuccess, notifyError } = useNotifications();

  const methodsOfUseForm = useFormWrapper({
    defaultValues: {
      enable_transaction_lead_quote:
        storefrontMetadata?.enable_transaction_lead_quote ?? false,
      enable_transaction_lead_sample:
        storefrontMetadata?.enable_transaction_lead_sample ?? false,
    },
  });

  const { control, setValue, handleSubmit } = methodsOfUseForm;

  const handleSubmitChanges = async (values: {
    enable_transaction_lead_sample: boolean;
    enable_transaction_lead_quote: boolean;
  }) => {
    setLoading(true);
    try {
      await axios.patch(
        endpoints.v1_storefronts_id_metadata(storefront_id),
        values
      );
      await mutateMetadata();
      setEditMode(false);
      notifySuccess(t("Changes saved successfully"));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not update settings. Please try again later."),
        {
          error,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const onCancel = async () => {
    setValue(
      "enable_transaction_lead_quote",
      storefrontMetadata?.enable_transaction_lead_quote
    );
    setValue(
      "enable_transaction_lead_sample",
      storefrontMetadata?.enable_transaction_lead_sample
    );
    setEditMode(false);
  };

  useEffect(() => {
    if (storefrontMetadata?.enable_transaction_lead_quote) {
      setValue(
        "enable_transaction_lead_quote",
        storefrontMetadata.enable_transaction_lead_quote
      );
    }
    if (storefrontMetadata?.enable_transaction_lead_sample) {
      setValue(
        "enable_transaction_lead_sample",
        storefrontMetadata.enable_transaction_lead_sample
      );
    }
  }, [
    setValue,
    storefrontMetadata?.enable_transaction_lead_quote,
    storefrontMetadata?.enable_transaction_lead_sample,
  ]);

  return (
    <SettingsCard>
      <Row>
        <div>
          <H3 style={{ marginBottom: 0 }}>
            {t("Lead Transaction Requests Configuration")}
          </H3>
          <SearchFilterCaption>
            {t("Configure transaction data on lead requests")}
          </SearchFilterCaption>
        </div>
        {!editMode && (
          <EditButton
            testid={"edit-lead-transaction"}
            onClick={() => setEditMode(true)}
          />
        )}
        {editMode && <CancelButtonSmall onClick={onCancel} />}
      </Row>
      <Form onSubmit={handleSubmit(handleSubmitChanges)}>
        <H6>{t("Sample Requests")}</H6>
        <Controller
          control={control}
          name="enable_transaction_lead_sample"
          render={({ value, name, ref }) => (
            <ToggleWithController
              label={t(
                "Enable / Disable Transaction Data on Lead Sample Requests"
              )}
              name={name}
              disabled={!editMode}
              onChange={(e) => {
                setValue("enable_transaction_lead_sample", e.target.checked);
              }}
              checked={value}
              inputRef={ref}
            />
          )}
        />
        <H6>{t("Quote Requests")}</H6>
        <Controller
          control={control}
          name="enable_transaction_lead_quote"
          render={({ value, name, ref }) => (
            <ToggleWithController
              label={t(
                "Enable / Disable Transaction Data on Lead Quote Requests"
              )}
              name={name}
              disabled={!editMode}
              onChange={(e) => {
                setValue("enable_transaction_lead_quote", e.target.checked);
              }}
              checked={value}
              inputRef={ref}
            />
          )}
        />
        {editMode && (
          <PrimaryButtonMedium type="submit" loading={loading}>
            {t("Save your changes")}
          </PrimaryButtonMedium>
        )}
      </Form>
    </SettingsCard>
  );
};
