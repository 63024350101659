import { SettingsCard } from "../../../../layout/portalPageLayout";
import React, { useState } from "react";
import { H3 } from "../../../../components/Typography/Typography";
import {
  EditButton,
  CancelButtonSmall,
} from "../../../../components/Buttons/Buttons";
import {
  InfoBlockSmall,
  InfoBlockRow,
} from "../../../../components/InfoBlocks/InfoBlocks";
import {
  AddressBlock,
  LocationsList,
} from "../../../../components/LocationsList/LocationsList";
import { LocationIcon, UserIcon } from "../../../../components/Icons/Icons";
import { EditGeneralInfo } from "../../OrganizationPage/CompanyInfo/Edit/EditGeneralInfo";
import { EditAddressForm } from "../../OrganizationPage/CompanyInfo/Edit/EditAddressForm";
import { EditLocations } from "../../OrganizationPage/CompanyInfo/Edit/EditLocations";
import styled from "styled-components/macro";
import type { Tenant, IAddress, DataMutate } from "../../../../types/types";
import { formateInternationalPhoneNumber } from "../../../../util/phone";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../../../components/Form/Form";
import { EditHQAddressForm } from "../../OrganizationPage/CompanyInfo/Edit/EditHQAddressForm";
import { useStoreState } from "../../../../util/util";
import { Row } from "../../../../components/Layout/Layout";
import { useAuthContext } from "../../../../components/Auth";

const MultiLineBlock = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  display: inline-block;
`;

const InfoBlockInnerRow = styled.div`
  margin-bottom: 36px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

function ContactBlock({ address }: { address: IAddress }) {
  return (
    <MultiLineBlock>
      <div>{address.contact_name ?? "--"}</div>
      {address.email_address && <div>{address.email_address}</div>}
      {address.phone_number && (
        <div>
          <a href={`tel:${address.phone_number}`}>
            {formateInternationalPhoneNumber(address.phone_number)}
          </a>
        </div>
      )}
    </MultiLineBlock>
  );
}

function PrimaryContact({ tenant }: { tenant: Tenant }) {
  return (
    <MultiLineBlock>
      <div>
        {tenant.primary_contact &&
          `${tenant.primary_contact?.first_name} ${tenant.primary_contact?.last_name}`}
      </div>
      <div>{tenant.primary_contact?.email_address}</div>
      <div>
        <a href={`tel:${tenant.primary_contact.phone_number}`}>
          {formateInternationalPhoneNumber(tenant.primary_contact.phone_number)}
        </a>
      </div>
    </MultiLineBlock>
  );
}

// function ServicedByBlock() {
//   const Tabs = ["Self", "Auto Routing"];
//   const [activeTab, setActiveTab] = useState("Quote Requests");

//   const handleTabChange = (e: BaseSyntheticEvent) => {
//     setActiveTab(e.currentTarget.dataset.name);
//   };
//   return (
//     <>
//       <ToggleGroup
//         names={Tabs}
//         activeButton={activeTab}
//         clickHandler={handleTabChange}
//       />
//     </>
//   );
// }

export const CompanyInfo = ({
  buyerTenant,
  mutateBuyerTenant,
}: {
  buyerTenant: Tenant;
  mutateBuyerTenant: DataMutate<Tenant>;
}) => {
  const [editGeneralSection, setEditGeneralSection] = useState<boolean>(false);
  const [editBilling, setEditBilling] = useState(false);
  const [editLocations, setEditLocations] = useState(false);
  const [editHQAddress, setEditHQAddress] = useState(false);
  const { t } = useTranslation();
  const {
    storefront_metadata: { edition },
  } = useStoreState();
  const { roleIsSomeKindOfBuyer } = useAuthContext();

  const locationsAddresses = buyerTenant.addresses.filter(
    (address) => address.type === "Warehouse"
  );

  const billingAddress = buyerTenant.addresses.find(
    (address) => address.type === "Sold To Address"
  );

  const hqAddress = buyerTenant.addresses.find(
    (address) => address.type === "Headquarters Address"
  );

  const closeEditGeneralInfo = () => {
    mutateBuyerTenant();
    setEditGeneralSection(false);
  };

  return (
    <>
      <SettingsCard>
        <Row>
          <H3>{t("General")}</H3>
          {!editGeneralSection && (
            <EditButton
              testid={"edit-general-company-info"}
              onClick={() => setEditGeneralSection(true)}
              title="Edit General Company Info"
            />
          )}
          {editGeneralSection && (
            <CancelButtonSmall onClick={() => setEditGeneralSection(false)} />
          )}
        </Row>
        {!editGeneralSection && (
          <>
            <InfoBlockRow>
              <div>
                <InfoBlockInnerRow>
                  <InfoBlockSmall
                    header={t("Company Name")}
                    content={buyerTenant.name ?? "--"}
                    icon={<LocationIcon width={16} />}
                  />
                </InfoBlockInnerRow>{" "}
                <InfoBlockInnerRow>
                  <InfoBlockSmall
                    header={t("Doing Business As")}
                    content={buyerTenant.doing_business_as ?? "--"}
                  />
                </InfoBlockInnerRow>
              </div>
              <div>
                {!roleIsSomeKindOfBuyer &&
                  buyerTenant.crm_id &&
                  buyerTenant.crm_id !== "" && (
                    <InfoBlockInnerRow>
                      <InfoBlockSmall
                        header={t("CRM ID")}
                        content={buyerTenant.crm_id ?? "--"}
                      />
                    </InfoBlockInnerRow>
                  )}
                <InfoBlockSmall
                  header={t("Primary Contact")}
                  content={<PrimaryContact tenant={buyerTenant} />}
                  icon={<UserIcon width={16} />}
                />
              </div>
            </InfoBlockRow>
          </>
        )}
        {editGeneralSection && (
          <EditGeneralInfo
            tenant={buyerTenant}
            is_buyer_activated={true}
            closeEditGeneralSection={() => closeEditGeneralInfo()}
          />
        )}
      </SettingsCard>
      <SettingsCard>
        <Row>
          <H3>{t("Headquarter")}</H3>
          {!editHQAddress && (
            <EditButton
              testid={"edit-headquarter-address"}
              onClick={() => setEditHQAddress(true)}
              title={t("Edit HeadQuarter")}
            />
          )}
          {editHQAddress && (
            <CancelButtonSmall onClick={() => setEditHQAddress(false)} />
          )}
        </Row>
        {editHQAddress && hqAddress && (
          <EditHQAddressForm
            address={hqAddress}
            setEdit={setEditHQAddress}
            tenantId={buyerTenant.id}
            mutateTenant={mutateBuyerTenant}
          />
        )}
        <InfoBlockRow>
          {!editHQAddress && (
            <>
              <InfoBlockSmall
                header={t("Address")}
                content={<AddressBlock address={hqAddress} />}
                icon={<LocationIcon width={16} />}
              />
              {hqAddress && (
                <InfoBlockSmall
                  header={t("Point of Contact")}
                  content={<ContactBlock address={hqAddress} />}
                  icon={<UserIcon width={16} />}
                />
              )}
            </>
          )}
        </InfoBlockRow>
      </SettingsCard>

      {edition !== "pim" && (
        <>
          <SettingsCard>
            <Row>
              <H3>{t("Billing")}</H3>
              {!editBilling && (
                <EditButton
                  testid={"tenant-edit-billing-address"}
                  onClick={() => setEditBilling(true)}
                  title="Edit Billing"
                />
              )}
              {editBilling && (
                <CancelButtonSmall onClick={() => setEditBilling(false)} />
              )}
            </Row>
            {editBilling && billingAddress && (
              <EditAddressForm
                address={billingAddress}
                setEdit={setEditBilling}
                tenantId={buyerTenant.id}
                mutateTenant={mutateBuyerTenant}
                hqAddress={hqAddress}
              />
            )}
            <InfoBlockRow>
              {!editBilling && (
                <>
                  <InfoBlockSmall
                    header={t("Address")}
                    content={<AddressBlock address={billingAddress} />}
                    icon={<LocationIcon width={16} />}
                  />
                  {billingAddress && (
                    <InfoBlockSmall
                      header={t("Point of Contact")}
                      content={<ContactBlock address={billingAddress} />}
                      icon={<UserIcon width={16} />}
                    />
                  )}
                </>
              )}
            </InfoBlockRow>
          </SettingsCard>
          <SettingsCard>
            <Row>
              <H3>{t("Shipping Locations")}</H3>
              {!editLocations && (
                <EditButton
                  testid={"edit-locations"}
                  onClick={() => setEditLocations(true)}
                  title="Edit Locations"
                />
              )}

              {editLocations && (
                <CancelButtonSmall onClick={() => setEditLocations(false)} />
              )}
            </Row>
            {editLocations ? (
              <EditLocations
                addresses={locationsAddresses}
                tenantId={buyerTenant.id}
                onSaveChangesClick={() => setEditLocations(false)}
                mutateTenant={mutateBuyerTenant}
                hqAddress={hqAddress}
              />
            ) : (
              <>
                <SectionTitle>{t("Active Locations")}</SectionTitle>
                <LocationsList
                  addresses={locationsAddresses.filter(
                    (address) => address.is_active
                  )}
                />
                {locationsAddresses.filter((address) => !address.is_active)
                  .length > 0 && (
                  <>
                    <SectionTitle>{t("Inactive Locations")}</SectionTitle>
                    <LocationsList
                      addresses={locationsAddresses.filter(
                        (address) => !address.is_active
                      )}
                    />
                  </>
                )}
              </>
            )}
          </SettingsCard>
        </>
      )}
    </>
  );
};
