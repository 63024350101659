import theme from "../../theme";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
} from "../Buttons/Buttons";
import { EditIcon } from "../Icons/Icons";
import { Row } from "../Layout/Layout";
import type { QuoteRequestItem } from "../../types/types";
import React from "react";
import { getQuoteOrOrderItemValueString } from "../../util/QuotesAndOrders";
import {
  FurthestRightButtonContainer,
  TransactionItem,
} from "./TransactionItem";
import { TransactionItemInfoBlock } from "./TransactionItemInfoBlock";
import {
  makeNumberOfUnitsLabel,
  makePricePerUnitString,
  makeSkuLabel,
} from "./TransactionItemUtils";
import { TransactionItemTopLeft } from "./TransactionItemTopLeft";
import { TransactionItemInfoBlockQuantityRequested } from "./TransactionItemInfoBlockQuantity";
import { useTranslation } from "react-i18next";

/**
 * Transaction item card for buyer cart items.
 */
export const CartItemBuyer = ({
  item,
  index,
  editItem,
  removeItem,
}: {
  item: QuoteRequestItem;
  index: number;
  editItem: (item: QuoteRequestItem) => void;
  removeItem: (item: QuoteRequestItem) => void;
}) => {
  const { t } = useTranslation();

  const itemHasPriceTiers = !!item.price_per_unit;

  const topLeft = <TransactionItemTopLeft item={item} index={index} />;
  const topRightCol1 = (
    <TransactionItemInfoBlockQuantityRequested item={item} t={t} />
  );

  const topRightCol2 = (
    <TransactionItemInfoBlock
      alignRight={true}
      main={itemHasPriceTiers ? getQuoteOrOrderItemValueString(item) : "--"}
      lines={[
        itemHasPriceTiers
          ? makePricePerUnitString(
              item.price_per_unit,
              item.currency,
              item.sku?.packaging_unit?.name
            )
          : "--",
      ]}
    />
  );

  const heading = t("Requested Details");

  const bottomLeftCol1 =
    item.sku.kind === "No preference SKU" ? (
      <TransactionItemInfoBlock heading={heading} main={t("No Preference")} />
    ) : (
      <TransactionItemInfoBlock
        heading={heading}
        main={makeSkuLabel(item.sku)}
        lines={[makeNumberOfUnitsLabel(item.no_of_units, t)]}
        warningLine={
          item.sku.kind === "Buyer SKU" ? t("Custom SKU") : undefined
        }
      />
    );

  const bottomRight = (
    <Row>
      <ButtonWithConfirmDialog
        Button={DeleteButton}
        testid={"delete-quote-cart-item-buyer"}
        handleConfirm={() => removeItem(item)}
        confirmMessage={t("Are you sure you want to remove this item?")}
      />
      <FurthestRightButtonContainer>
        <EditButton
          onClick={() => editItem(item)}
          testid={`buyer-cart-item-edit-button-${index}`}
        >
          <EditIcon fill={theme.primaryButtonBG} />
        </EditButton>
      </FurthestRightButtonContainer>
    </Row>
  );

  return (
    <TransactionItem
      topLeft={topLeft}
      topRightCol1={topRightCol1}
      topRightCol2={topRightCol2}
      bottomLeftCol1={bottomLeftCol1}
      bottomRight={bottomRight}
      productId={item.product_id}
    />
  );
};
