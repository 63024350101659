import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import type {
  MethodsOfUseForm,
  OptionType,
  ProductApplicationBrokenId_DO_NOT_USE,
} from "../../types/types";
import { Controller } from "react-hook-form";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import {
  convertApplicationToOption,
  fixProductApplication,
} from "../../util/util";
import { TextField } from "../TextFields/TextFields";
import * as yup from "yup";
import { strings } from "../../util/strings";

export const productApplicationSchema = (t: TFunction) => ({
  application: yup.object().nullable().notRequired(),
  custom_application: yup.string().when("application", {
    is: (application?: OptionType<string>) => application?.label === t("Other"),
    then: () => yup.string().required(strings(t).thisIsARequiredField),
  }),
});

export const ProductApplicationSelect = ({
  methodsOfUseForm,
  applications,
  defaultApplcations,
  defaultCustomApplication,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  applications: ProductApplicationBrokenId_DO_NOT_USE[];
  defaultApplcations?: OptionType;
  defaultCustomApplication?: string;
}) => {
  const { control, errors, formState, register, watch } = methodsOfUseForm;
  const { t } = useTranslation();

  const applicationValue: OptionType<string | number> = watch("application");

  const otherOption: OptionType<number> = {
    // a falsy value that is not null is important here,
    // This will let the placeholder code on the see it as an actual value
    // but on form submission, it reads as null.
    value: -0,
    label: t("Other"),
  };

  const options = applications.map<OptionType<string>>((application) =>
    convertApplicationToOption(fixProductApplication(application))
  );

  const optionsWithUnspecified = [...options, otherOption];

  return (
    <>
      <Controller
        as={SelectBoxV2}
        control={control}
        name="application"
        isClearable
        placeholder={t("Application (Optional)")}
        options={optionsWithUnspecified}
        rules={{ required: false }}
        errors={errors}
        formState={formState}
        defaultValue={defaultApplcations}
      />
      {applicationValue && applicationValue.label === t("Other") ? (
        <TextField
          label={t("Please specify")}
          name="custom_application"
          defaultValue={defaultCustomApplication}
          theref={register({
            required:
              applicationValue && applicationValue.label === t("Other")
                ? strings(t).thisIsARequiredField
                : false,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
      ) : (
        <></>
      )}
    </>
  );
};
