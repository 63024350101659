import { InfoBlockExtraSmall } from "../../components/InfoBlocks/InfoBlocks";
import type { IShipmentAdvice, UUID } from "../../types/types";
import React from "react";
import { formatDate, useStoreState } from "../../util/util";
import styled from "styled-components/macro";
import Axios from "axios";
import type { AxiosError } from "axios";
import { DeleteButton, EditButton } from "../Buttons/Buttons";
import { useNotifications } from "../Notifications/NotificationsContext";
import { t } from "i18next";
import { DownloadDocumentIcon } from "../Icons/Icons";
import ReactTooltip from "react-tooltip";
import { WhiteInnerCard } from "../../layout/portalPageLayout";

const FlexRow = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

/**
 * Custom type because documents are store in the top level `documents` array on
 * purchase orders but we need only the shipping_document for this component.
 */

/**
 * display formatted shipment advice for both buyer and seller views.
 * The add button only exists in the seller view.
 */
export function ShipmentAdviceSection({
  shipment_advice,
  canEdit,
  handleEditShipmentAdvice,
  mutateData,
  orderId,
  isSampleRequest,
}: {
  shipment_advice: IShipmentAdvice;
  canEdit?: boolean;
  mutateData?: () => void;
  orderId?: UUID;
  handleEditShipmentAdvice?: () => void;
  isSampleRequest?: boolean;
}) {
  const { storefront_id } = useStoreState();
  const { notifyError, notifySuccess } = useNotifications();
  const deleteShipmentAdvice = async () => {
    try {
      await Axios.delete(
        isSampleRequest
          ? `/v1/storefronts/${storefront_id}/sample-requests/${orderId}/shipment-advice/${shipment_advice.id}`
          : `/v1/storefronts/${storefront_id}/orders/${orderId}/shipment-advice/${shipment_advice.id}`
      );
      if (mutateData) {
        mutateData();
      }
      notifySuccess(t("Shipment Advice has been removed successfully."));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error deleting the Shipment Advice"),
        {
          error,
        }
      );
    }
  };

  return (
    <WhiteInnerCard style={{ marginBottom: "25px" }}>
      <FlexRow>
        <InfoBlockExtraSmall
          header={t("Mode of Shipment")}
          content={shipment_advice.shipment_advice_type || "--"}
        />

        <InfoBlockExtraSmall
          header={t("ETD")}
          content={
            shipment_advice.estimated_time_of_dispatch
              ? formatDate(shipment_advice.estimated_time_of_dispatch)
              : "--"
          }
        />

        <InfoBlockExtraSmall
          header={t("ETA")}
          content={
            shipment_advice.estimated_time_of_arrival
              ? formatDate(shipment_advice.estimated_time_of_arrival)
              : "--"
          }
        />

        <InfoBlockExtraSmall
          header={t("Carrier")}
          content={shipment_advice.carrier || "--"}
        />

        <InfoBlockExtraSmall
          header={t("Carrier Pro Number")}
          content={shipment_advice.carrier_number?.toString() || "--"}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "135px",
            justifyContent: "flex-end",
          }}
        >
          {shipment_advice.shipment_advice_document?.signed_url && (
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={shipment_advice.shipment_advice_document.signed_url}
                data-for={shipment_advice.id}
                data-tip={shipment_advice.shipment_advice_document.name}
                style={{ margin: "0 15px 0 10px" }}
              >
                <DownloadDocumentIcon />
              </a>
              <ReactTooltip id={shipment_advice.id} />
            </>
          )}
          {canEdit && handleEditShipmentAdvice && (
            <>
              <EditButton
                testid="editShipmentAdvice"
                onClick={() => handleEditShipmentAdvice()} //setShowShipmentAdviceForm(true)}
              />
              <DeleteButton onClick={() => deleteShipmentAdvice()} />
            </>
          )}
        </div>
      </FlexRow>

      {shipment_advice.additional_comments && (
        <FlexRow style={{ marginTop: "10px" }}>
          <InfoBlockExtraSmall
            header={t("Additional Comments")}
            content={shipment_advice.additional_comments}
          />
        </FlexRow>
      )}
    </WhiteInnerCard>
  );
}
