import { useEffect, useState } from "react";
import { GenericDialogBody } from "../../../../../components/ConfirmDialog/ConfirmDialog";
import { H3, H5 } from "../../../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import { DialogWrapper, RadioButtonContainer } from "./utils";
import { ConfirmOrCancelButtonContainer } from "../../../../../components/Buttons/Buttons";
import type { AxiosError } from "axios";
import axios from "axios";
import { endpoints } from "../../../../../endpoints";
import { useStoreState } from "../../../../../util/util";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";

export const StorefrontConnectionUpdate = ({
  show,
  onComplete,
  onCancel,
  product_ids,
}: {
  show: boolean;
  product_ids: string[];
  onComplete: () => void;
  onCancel: () => void;
}) => {
  const [showDialog, setShowDialog] = useState(show);
  const [selectedOption, setSelectedOption] =
    useState<"activate" | "deactivate">();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { tenant_id, storefront_id } = useStoreState();
  const { notifyError, notifySuccess } = useNotifications();
  const onContinue = async () => {
    setLoading(true);
    try {
      await axios.patch(
        endpoints.v2_tenants_tenant_id_pim_products_connections(tenant_id),
        {
          product_ids,
          storefront_id_or_slug: storefront_id,
          activate: selectedOption === "activate",
        }
      );
      notifySuccess(
        selectedOption === "activate"
          ? t(
              product_ids.length === 1
                ? "1 product was activated on storefront."
                : "{{numberOfProducts}} products were activated on storefront.",
              {
                numberOfProducts: product_ids.length,
              }
            )
          : t(
              product_ids.length === 1
                ? "1 product was deactivated on storefront."
                : "{{numberOfProducts}} products were deactivated on storefront.",
              {
                numberOfProducts: product_ids.length,
              }
            )
      );
      setShowDialog(false);
      setSelectedOption(undefined);
      onComplete();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t(
              "Could not update storefront connection. Please try again later."
            ),
        {
          error,
        }
      );
    } finally {
      setLoading(false);
    }
  };
  const closeDialog = () => {
    setShowDialog(false);
    onCancel();
    setSelectedOption(undefined);
  };
  useEffect(() => setShowDialog(show), [show]);
  return (
    <GenericDialogBody show={showDialog} closeDialog={closeDialog}>
      <DialogWrapper>
        <H3 style={{ margin: 0 }}>{t("Activate/Deactivate on storefront")}</H3>
        <H5 style={{ marginTop: "8px" }}>{t("Choose an action from below")}</H5>
        <RadioButtonContainer>
          <RadioButton
            name={"connection"}
            value={"activate"}
            checked={selectedOption === "activate"}
            optionTitle={t("Activate")}
            handleChange={() => setSelectedOption("activate")}
          />
          <RadioButton
            name={"connection"}
            value={"deactivate"}
            checked={selectedOption === "deactivate"}
            optionTitle={t("Deactivate")}
            handleChange={() => setSelectedOption("deactivate")}
          />
        </RadioButtonContainer>
        <ConfirmOrCancelButtonContainer
          onCancel={closeDialog}
          onConfirm={onContinue}
          isConfirmDisabled={!selectedOption}
          isConfirmLoading={loading}
          extraStyle={{ marginTop: "32px" }}
        />
      </DialogWrapper>
    </GenericDialogBody>
  );
};
