import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

export const i18nFn = ({ tenant_id }: { tenant_id?: string }) => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
      backend: {
        // for all available options read the backend's repository readme file
        loadPath: tenant_id
          ? `/api/v2/tenants/${tenant_id}/translation/{{lng}}`
          : "/api/v1/translation/{{lng}}",
        parse: (data: any) => {
          // TODO: How to display a meaningful error if this fails?
          // json validator?
          return JSON.parse(data).data;
        },
      },

      // lng: "en", // if you're using a language detector, do not define the lng option
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      react: {
        useSuspense: true,
        bindI18n: "loaded languageChanged",
        bindI18nStore: "added",
      },
    });
  return i18n;
};
