import styled, { useTheme } from "styled-components/macro";
import React from "react";
import { EditIcon } from "../Icons/Icons";

// This file was originally in storybook package and has some positioning
// that makes it slightly different from the EditTopRight component.
// They should probably be combined at some point to de-duplicate.

export const TopRight = styled.div`
  position: absolute;
  top: 12px;
  right: 20px;
  cursor: pointer;
`;

type EditTopRightProps = {
  onClick: () => void;
};

export const EditTopRightWithPositioning = ({ onClick }: EditTopRightProps) => {
  const theme = useTheme();
  return (
    <TopRight onClick={onClick}>
      <EditIcon fill={theme.primaryLinkColor} />
    </TopRight>
  );
};
