import { rgba } from "polished";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components/macro";
import { Flex } from "../../layout/FormLayout";
import { PrimaryCancelButton, SecondaryButtonMedium } from "../Buttons/Buttons";
import { DeleteIcon } from "../Icons/Icons";
import { Modal } from "../Modal/Modal";
import { H3 } from "../Typography/Typography";

const ModalWrapper = styled.div`
  width: 100%;
  height: 162px;
  margin-bottom: 32px;
  border-radius: 4px;
  padding: 4px;
  border: solid 1px ${({ theme }) => theme.errorColor};
  background-color: ${({ theme }) => rgba(theme.errorColor, 0.03)};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EditDeleteModal = ({
  title,
  show,
  onClose,
  onConfirm,
  body,
  confirmBtnLabel,
  isLoading,
  hideIcon = false,
}: {
  title: string;
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  body: string;
  confirmBtnLabel: string;
  isLoading: boolean;
  hideIcon?: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Modal overlay show={show} closeModal={onClose}>
        <H3>{title}</H3>
        <ModalWrapper>
          {!hideIcon && (
            <DeleteIcon fill={theme.errorColor} width={40} height={40} />
          )}
          <div>{body}</div>
        </ModalWrapper>
        <Flex
          style={{
            columnGap: "16px",
          }}
        >
          <SecondaryButtonMedium style={{ width: "100%" }} onClick={onClose}>
            {t("Cancel")}
          </SecondaryButtonMedium>
          <PrimaryCancelButton
            loading={isLoading}
            style={{ width: "100%" }}
            onClick={onConfirm}
          >
            {confirmBtnLabel}
          </PrimaryCancelButton>
        </Flex>
      </Modal>
    </>
  );
};
