import type { AxiosError } from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import useSWR from "swr";
import { StringParam, useQueryParams } from "use-query-params";
import { PrimaryButtonWithPlusIcon } from "../../../../../components/Buttons/Buttons";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { LinkUnstyled } from "../../../../../components/LinkUnstyled/LinkUnstyled";
import { ListingCardGrid } from "../../../../../components/ListingGrid/ListingCardGrid";
import { ListingGridCard } from "../../../../../components/ListingGrid/ListingGridCard";
import { Modal } from "../../../../../components/Modal/Modal";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { NoData } from "../../../../../components/Table/Table";
import type { UUID, WithPagination } from "../../../../../types/types";
import { PageTitle, PageWrapper } from "../../../../../layout/portalPageLayout";
import type { AttributeTemplateSummarySchema } from "../../../../../types/types.PIM";
import { useDebounce } from "../../../../../util/hooks";
import { useRoutePath } from "../../../../../util/Routing";
import { TEMPORARY_HIGH_LIMIT, useStoreState } from "../../../../../util/util";
import { CreateNewTemplateForm } from "./CreateNewTemplateForm";
import { useAuthContext } from "../../../../../components/Auth";

//TODO this is temporary do not commit this, create shared file for "Table Page components"
const SearchBarAndFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const RightSideButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-end;
  margin-left: 10px;
`;

const GreyLineFullBleed = styled.div`
  background-color: ${({ theme }) => theme.primaryBorder};
  height: 1px;
  width: 100%;
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const constructQuery = ({
  baseUrl,
  query,
}: {
  baseUrl: string;
  query: string;
}) => {
  const params = new URLSearchParams();
  if (query) params.append("q", query);
  return baseUrl + params;
};

export function SellerAdminTemplateListingPage() {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
  });
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [showCreateTemplateForm, setShowCreateTemplateForm] = useState(false);
  const history = useHistory();
  const { adminPath } = useRoutePath();
  const { hasPermission } = useAuthContext();

  const {
    data: templates,
    error: templatesError,
    mutate: mutateTemplates,
  } = useSWR<
    WithPagination<{ data: AttributeTemplateSummarySchema[] }>,
    AxiosError
  >(
    constructQuery({
      baseUrl: `/v2/tenants/${tenant_id}/pim/templates/summary?limit=${TEMPORARY_HIGH_LIMIT}&`,
      query: debouncedSearchQuery,
    })
  );

  const isLoadingTemplates = !templates && !templatesError;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setQuery({ q: e.target.value });
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
  };

  if (isLoadingTemplates) {
    return <DelayedSpinner />;
  }

  if (templatesError) {
    return (
      <ErrorPlaceholder
        message={t("There was an error loading the templates")}
      />
    );
  }

  return (
    <PageWrapper>
      <PageTitle css={{ margin: "0px" }}>{t("Templates")}</PageTitle>
      <GreyLineFullBleed />
      <SearchBarAndFilters style={{ marginBottom: "50px" }}>
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search by template")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        <RightSideButtonContainer>
          {hasPermission("modify_templates") && (
            <PrimaryButtonWithPlusIcon
              onClick={() => setShowCreateTemplateForm(true)}
            >
              {t("Create")}
            </PrimaryButtonWithPlusIcon>
          )}
          <Modal
            overlay
            show={showCreateTemplateForm}
            closeModal={() => setShowCreateTemplateForm(false)}
          >
            <CreateNewTemplateForm
              templates={templates?.data as AttributeTemplateSummarySchema[]}
              onSuccess={(id: UUID) => {
                setShowCreateTemplateForm(false);
                mutateTemplates();
                history.push(`${adminPath}/pim/templates/${id}/groups`);
              }}
            />
          </Modal>
        </RightSideButtonContainer>
      </SearchBarAndFilters>
      {searchQuery && templates && templates.data.length === 0 && (
        <NoData>{t("No results found")}</NoData>
      )}
      {templates && (
        <ListingCardGrid>
          {templates.data.map((template) => {
            return (
              <LinkUnstyled
                key={template.template_id}
                to={`${adminPath}/pim/templates/${template.template_id}/groups`}
              >
                <ListingGridCard
                  key={template.template_id}
                  firstLineBold={template.template_name}
                />
              </LinkUnstyled>
            );
          })}
        </ListingCardGrid>
      )}
    </PageWrapper>
  );
}
