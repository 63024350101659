import styled from "styled-components/macro";

/**
 * for usage with the tabs and filters components on table pages
 */
export const TabsAndFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
