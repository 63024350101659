import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import type { Dispatch, SetStateAction } from "react";
import { CancelButtonSmall, InvisibleButton } from "../Buttons/Buttons";
import { EditIcon } from "../Icons/Icons";
import type { IAccordionCardProps } from "./AccordionCard";
import { AccordionCard } from "./AccordionCard";

export const AccordionCardWithEditFeatures = (
  props: Omit<IAccordionCardProps, "actionChildren"> & {
    handleEdit?: () => void;
    handleCancel?: () => void;
    editing?: boolean;
  }
) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const actionChildren = (
    setShowContent: Dispatch<SetStateAction<boolean | undefined>>
  ) => {
    const { handleEdit, editing, handleCancel } = props;
    return (
      <>
        {handleEdit && !editing && (
          <InvisibleButton
            onClick={() => {
              handleEdit();
              setShowContent(true);
            }}
          >
            <EditIcon height={16} width={16} fill={theme.secondaryTextColor} />{" "}
            {t("Edit")}
          </InvisibleButton>
        )}
        {editing && handleCancel && (
          <CancelButtonSmall onClick={handleCancel} />
        )}
      </>
    );
  };
  return (
    <AccordionCard {...props} actionChildren={actionChildren}>
      {props.children}
    </AccordionCard>
  );
};
