import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import type {
  OptionType,
  ProductSKU,
  SampleRequestPaginatedOutput,
} from "../../../types/types";
import { SmallSectionHeader } from "../../../components/Typography/Typography";
import { Form } from "../../../layout/FormLayout";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../components/TextFields/TextFields";
import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import styled from "styled-components/macro";
import { positiveNumberRegex } from "../../../util/regexes";
import {
  useStoreState,
  useFormWrapper,
  makeUrlWithParams,
} from "../../../util/util";
import Axios from "axios";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import useSWR from "swr";
import {
  packagingTypeOrUnitToOption,
  usePackagingUnits,
} from "../../../util/SkuUtils";
import { endpoints } from "../../../endpoints";

import {
  ApplicationsSectionContainer,
  SAMPLE_REQUEST_PURPOSES,
} from "../../public/AddToSampleRequestForm/AddToSampleRequestForm";
import { ProductApplicationSelect } from "../../../components/ProductApplicationSelect/ProductApplicationSelect";
import { useTranslation } from "react-i18next";
import { StatusBox } from "../../../components/Form/Form";
import { strings } from "../../../util/strings";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const StatusBoxInnerContainer = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 15px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  // Add min-height to prevent layout shift when drop down menu appears.
  min-height: 58px;
  & > div {
    flex-grow: 1;
    margin-right: 9px;
    width: 49%;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

type SampleRequestItemForm = {
  product_name: string;
  purpose: OptionType<string>;
  product_sku: OptionType<ProductSKU>;
  number_of_units: string;
  total_quantity: string;
  sample_quantity: string;
  total_quantity_unit: OptionType<string>;
  sample_quantity_unit: OptionType<string>;
  application?: OptionType<string> | OptionType<null>;
  custom_application?: string;
};

type SampleRequestCreationArgs = {
  product_id: string | null;
  unlisted_product_name?: string;
  purpose: string;
  total_quantity: string;
  total_quantity_packaging_unit_id: string;
  sample_quantity?: string;
  sample_quantity_packaging_unit_id?: string;
  applications?: string[];
  custom_application?: string;
  no_of_units: string | null;
};

export function BuyerAddUnlistedProductToSampleRequestForm({
  closeSlideout,
  sampleItems = [],
}: {
  closeSlideout: () => void;
  cartID: string | undefined;
  sampleItems?: string[];
}) {
  const { notifyError, notifySuccess } = useContext(Notifications);
  const { t } = useTranslation();

  const { storefront_id } = useStoreState();

  // Fetch the existing data so we know whether a sample request cart exists
  // yet or not (because our submit request is different if it does or not).
  const { data: sampleRequestResponse, mutate: mutateSampleRequest } =
    useSWR<SampleRequestPaginatedOutput>(
      makeUrlWithParams(
        endpoints.v1_storefronts_id_sampleRequests(storefront_id),
        {
          source: "logged_in",
          status: "new",
          limit: "1",
        }
      ),
      { revalidateOnFocus: false }
    );

  const sampleRequestId = sampleRequestResponse?.data[0]?.id;

  const unlistedSampleProduct = yup.object().shape({
    product_name: yup.string().required(strings(t).thisIsARequiredField),
    sample_quantity_unit: yup
      .object()
      .nullable()
      .required(strings(t).thisIsARequiredField),
    sample_quantity: yup.string().required(strings(t).thisIsARequiredField),
    total_quantity_unit: yup
      .object()
      .nullable()
      .required(strings(t).thisIsARequiredField),
    total_quantity: yup.string().required(strings(t).thisIsARequiredField),
    purpose: yup.object().nullable().required(strings(t).thisIsARequiredField),
  });

  const methodsOfUseForm = useFormWrapper<SampleRequestItemForm>({
    resolver: yupResolver(unlistedSampleProduct),
  });

  const {
    register,
    handleSubmit,
    formState,
    errors,
    control,
    setError,
    clearErrors,
  } = methodsOfUseForm;

  const { packagingUnits, packagingUnitsError } = usePackagingUnits();

  const packagingUnitOptions =
    packagingUnits?.map(packagingTypeOrUnitToOption) || [];

  const packagingUnitsErrorMessage = "Error fetching packaging units";

  // Check to see if the error has already been set to avoid a render loop.
  if (packagingUnitsError && !errors["total_quantity_unit"]) {
    setError("total_quantity_unit", {
      message: packagingUnitsErrorMessage,
    });
  }
  if (
    !packagingUnitsError &&
    errors["total_quantity_unit"] &&
    // TODO: figure out why TypeScript has the wrong type for the error object.
    // @ts-ignore
    errors["total_quantity_unit"].message === packagingUnitsErrorMessage
  ) {
    clearErrors("total_quantity_unit");
  }

  const onFormSubmit = async (data: SampleRequestItemForm) => {
    try {
      const request: SampleRequestCreationArgs = {
        product_id: null,
        ...(data.product_name
          ? { unlisted_product_name: data.product_name }
          : {}),
        purpose: data.purpose.value,
        total_quantity: data.total_quantity,
        no_of_units: null,
        sample_quantity: data.sample_quantity,
        total_quantity_packaging_unit_id: data.total_quantity_unit.value,
        sample_quantity_packaging_unit_id: data.sample_quantity_unit.value,
        ...(data.custom_application
          ? { custom_application: data.custom_application }
          : {}),
      };

      if (data.application?.value) {
        request.applications = [data.application.value];
      }

      const endpoint = sampleRequestId
        ? endpoints.v1_storefronts_id_sampleRequests_id_items(
            storefront_id,
            sampleRequestId
          )
        : endpoints.v1_storefronts_id_sampleRequests(storefront_id);

      await Axios.post(
        makeUrlWithParams(endpoint, { source: "logged_in" }),
        request
      );

      notifySuccess("Item added to sample request");
      mutateSampleRequest();
      closeSlideout();
    } catch (error) {
      notifyError("There was an error creating the sample request", { error });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <StatusBox>
        <StatusBoxInnerContainer style={{ marginTop: "15px" }}>
          <TextField
            label="Product Name"
            name="product_name"
            theref={register({
              required: true,
            })}
            type="text"
            errors={errors}
            formState={formState}
          />
          <FlexRow>
            <div>
              <TextField
                name={"sample_quantity"}
                label={t("Sample Quantity")}
                theref={register({
                  required: true,
                  pattern: {
                    value: positiveNumberRegex,
                    message: t("Must be a valid numeric value"),
                  },
                })}
                errors={errors}
                formState={formState}
                type="number"
              />
            </div>
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="sample_quantity_unit"
                placeholder={t("Unit of Measure")}
                id="sample_quantity_unit"
                options={packagingUnitOptions}
                defaultValue={null}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
              />
            </div>
          </FlexRow>
          <SmallSectionHeader>{t("Potential Annual Usage")}</SmallSectionHeader>
          <FlexRow>
            <div>
              <TextField
                name={"total_quantity"}
                label={t("Total Annual Quantity")}
                theref={register({
                  required: true,
                  pattern: {
                    value: positiveNumberRegex,
                    message: t("Must be a valid numeric value"),
                  },
                })}
                errors={errors}
                formState={formState}
                type="number"
              />
            </div>
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="total_quantity_unit"
                placeholder={t("Unit of Measure")}
                id="total_quantity_unit"
                options={packagingUnitOptions}
                defaultValue={null}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
              />
            </div>
          </FlexRow>
        </StatusBoxInnerContainer>
      </StatusBox>
      <ApplicationsSectionContainer>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="purpose"
          placeholder={t("Purpose")}
          rules={{ required: true }}
          options={SAMPLE_REQUEST_PURPOSES}
          errors={errors}
          formState={formState}
        />
        <ProductApplicationSelect
          methodsOfUseForm={methodsOfUseForm}
          applications={[]}
        />
      </ApplicationsSectionContainer>
      <PrimaryButtonFitContainer loading={formState.isSubmitting}>
        {t("Add to Sample Request")}
      </PrimaryButtonFitContainer>
    </Form>
  );
}
