import React, { useContext, useState } from "react";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { useSaveHomePage } from "../../pages/public/HomePage/useSaveHomePage";
import { Notifications } from "../Notifications/NotificationsContext";
import { useFormWrapper } from "../../util/util";
import type { IAboutUsData } from "../AboutUs/AboutUs";
import { Title } from "../Typography/Typography";
import { Form } from "../../layout/FormLayout";
import { TextArea } from "../TextArea/TextArea";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import styled from "styled-components/macro";

interface IEditIntroductionParagraphProps {
  homePageData: IHomePageData;
  endEditMode: () => void;
}

const TextAreaNoResizeX = styled.div`
  textarea {
    resize: vertical;
  }
`;

export const EditIntroduction = ({
  homePageData,
  endEditMode,
}: IEditIntroductionParagraphProps) => {
  const { saveHomePage } = useSaveHomePage();
  const [loading, setLoading] = useState(false);
  const { notifyError } = useContext(Notifications);

  const component = homePageData.config.components.find(
    (component) => component.name === "introduction"
  );
  const data = component?.data;
  const description = data?.desc;

  const { register, handleSubmit, formState } = useFormWrapper({
    defaultValues: {
      desc: description,
    },
  });

  const onSubmit = async (input: IAboutUsData) => {
    setLoading(true);
    const component = homePageData.config.components.find(
      (component) => component.name === "introduction"
    );

    if (component) {
      component.data = input;
    }
    try {
      const success = await saveHomePage(homePageData);
      if (success) {
        setLoading(false);
        endEditMode();
      }
    } catch (error) {
      setLoading(false);
      notifyError("There was an error updating the homepage");
    }
  };

  return (
    <>
      <Title>Edit Intro Section</Title>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextAreaNoResizeX>
          <TextArea
            name={"desc"}
            label="Description"
            theref={register({ required: true })}
            formState={formState}
            required={false}
          />
        </TextAreaNoResizeX>
        <PrimaryButtonFitContainer loading={loading}>
          Save Changes
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
