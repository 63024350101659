import React from "react";
import { SettingsIcon } from "../../../components/Icons/Icons";
import { providePrivatePageProps } from "../../../util/Routing";
import type { IOrganizationPageTab } from "../../SharedPages/OrganizationPage/OrganizationPage";
import { OrganizationPage } from "../../SharedPages/OrganizationPage/OrganizationPage";
import { BuyerSettings } from "../../SharedPages/OrganizationPage/BuyerSettings/BuyerSettings";
import { useTranslation } from "react-i18next";

/**
 * Page component for the Standard Buyer "Organization" page.
 */
export const BuyerOrganization = providePrivatePageProps(({ user }) => {
  const { t } = useTranslation();

  const tabs: IOrganizationPageTab[] = [
    {
      label: t("Settings"),
      icon: SettingsIcon,
      component: <BuyerSettings />,
    },
  ];

  return <OrganizationPage tabs={tabs} />;
});
