import { PrimaryButtonMedium } from "../../../components/Buttons/Buttons";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  SubmitButtonContainer,
} from "../../../layout/FormLayout";
import { TextField } from "../../../components/TextFields/TextFields";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { SectionTitle } from "../../../components/Form/Form";
import React, { useContext, useEffect, useState } from "react";
import {
  isAxiosError,
  useStoreState,
  useFormWrapper,
} from "../../../util/util";
import { PageTitle } from "../../../components/Layout/Layout";
import styled from "styled-components/macro";
import useSWR from "swr";
import { Redirect, useParams } from "react-router-dom";
import type {
  ITenantInviteArgs,
  OptionType,
  PasswordResetTokenSchema,
} from "../../../types/types";
import type { AxiosError } from "axios";
import Axios from "axios";
import { ErrorPlaceholder } from "../../../components/Error";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import * as yup from "yup";
import { strings } from "../../../util/strings";
import { yupResolver } from "@hookform/resolvers/yup";
import { countryCodeMap, getPhoneCodesOptions } from "../../../util/phone";
import { useCountriesList, useStatesList } from "../../../util/Locations";
import {
  getAddressOneInputLabel,
  getAddressTwoInputLabel,
  getStatesInputLabel,
  getZipCodeInputLabel,
} from "../../../util/location";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

// TODO: get this to type check without the `any`. Maybe use zod instead?
const SetUpCompanySchema = (t: TFunction) =>
  yup.lazy((formValues: any) => {
    return yup.object().shape({
      company_name: yup.string().required(strings(t).thisIsARequiredField),
      contact_firstname: yup.string().required(strings(t).thisIsARequiredField),
      contact_lastname: yup.string().required(strings(t).thisIsARequiredField),
      contact_email: yup
        .string()
        .email()
        .required(strings(t).thisIsARequiredField),
      contact_phone: yup
        .string()
        .phone(
          countryCodeMap.get(formValues.country_code.value),
          false,
          strings(t).phoneNumberMustBeValid
        )
        .required(strings(t).thisIsARequiredField),
      country_code: yup
        .object()
        .shape({
          label: yup.string().required(strings(t).thisIsARequiredField),
          value: yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      address1: yup.string().required(strings(t).thisIsARequiredField),
      address2: yup.string().notRequired(),
      country: yup
        .object()
        .nullable()
        .required(strings(t).thisIsARequiredField),
      county: yup.string().nullable().notRequired(),
      state: yup
        .object()
        .nullable()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(strings(t).thisIsARequiredField),
      city: yup.string().required(strings(t).thisIsARequiredField),
      postal_code: yup.string().required(strings(t).thisIsARequiredField),
    });
  });

type CompanyFormValues = {
  company_name: string;
  doing_business_as: string;
  contact_firstname: string;
  contact_lastname: string;
  contact_email: string;
  contact_phone: number;
  country_code: OptionType;
  address1: string;
  address2: string;
  county: string;
  country: OptionType;
  state: OptionType;
  postal_code: string;
  city: string;
};

export function SetUpCompany() {
  const { storefront_id, slug: storefrontSlug } = useStoreState();
  const { invite_id } = useParams<{ invite_id: string }>();
  const [successToken, setSuccessToken] = useState<string | undefined>();

  const { data, error } = useSWR<ITenantInviteArgs, AxiosError>(
    `/v1/storefronts/${storefront_id}/invite/${invite_id}`
  );

  if (error && isAxiosError(error)) {
    if (error.response?.status === 410) {
      return (
        <ErrorPlaceholder
          message={"This invite has either expired or has already been used."}
        />
      );
    }
    if (error.response?.status === 404) {
      return <ErrorPlaceholder message={"This invite doesn't exist."} />;
    }
    return (
      <ErrorPlaceholder message={"There was an error loading the invite."} />
    );
  }

  if (successToken) {
    return (
      <Redirect
        to={`/store/${storefrontSlug}/activate-account/${successToken}`}
      />
    );
  }

  if (data) {
    return (
      <SetUpCompanyForm
        invite={data}
        storefrontID={storefront_id}
        onSuccess={(token) => setSuccessToken(token)}
      />
    );
  }

  return null;
}

function SetUpCompanyForm({
  invite,
  storefrontID,
  onSuccess,
}: {
  invite: ITenantInviteArgs;
  storefrontID: string;
  onSuccess: (token: string) => void;
}) {
  const { t } = useTranslation();
  const countries = useCountriesList();

  const {
    handleSubmit,
    register,
    formState,
    control,
    errors,
    watch,
    setValue,
  } = useFormWrapper({
    resolver: yupResolver(SetUpCompanySchema(t)),
    defaultValues: {
      company_name: invite.company_name || "",
      contact_firstname: invite?.contact_firstname || "",
      contact_lastname: invite?.contact_lastname || "",
      contact_email: invite?.contact_email || "",
      country: { value: "US", label: "United States" },
      state: null,
      country_code: { value: "", label: "" },
    },
  });

  const selectedCountry: OptionType = watch("country");
  const states = useStatesList(selectedCountry?.value);
  const [storedCountry, setStoredCountry] = useState("");

  useEffect(() => {
    if (selectedCountry.value !== storedCountry) {
      setStoredCountry(selectedCountry.value);
      setValue("address1", undefined);
      setValue("address2", undefined);
      setValue("city", undefined);
      setValue("county", undefined);
      setValue("postal_code", undefined);
      setValue("state", null);
    }
  }, [selectedCountry, storedCountry, setValue]);

  const { notifySuccess, notifyError } = useContext(Notifications);

  const onSubmit = async (values: CompanyFormValues) => {
    const tenant = {
      type: "Distributor",
      company_name: values.company_name,
      contact_firstname: values.contact_firstname,
      contact_lastname: values.contact_lastname,
      contact_email: values.contact_email,
      doing_business_as: values.doing_business_as,
      contact_phone: `${values.country_code.value}${values.contact_phone}`,
      address1: values.address1,
      address2: values.address2,
      county: values.county,
      country: values.country.value,
      state: values.state.value,
      city: values.city,
      postal_code: values.postal_code,
    };

    try {
      const response = await Axios.post<PasswordResetTokenSchema>(
        `/v1/storefronts/${storefrontID}/invite/${invite.id}`,
        tenant
      );
      // The endpoint returns the ID/token needed to set up the user's login
      // and password. We use it to redirect to login and password setup page.
      const token = response.data.id;

      if (response.status === 201 && token) {
        notifySuccess("The company has been set up");
        onSuccess(token);
      }
    } catch (error) {
      notifyError(
        "There was an error creating the company, please try again.",
        { error }
      );
    }
  };

  return (
    <Container>
      <div>
        <PageTitle>Set up your company</PageTitle>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name="company_name"
            label="Company Name"
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="doing_business_as"
            label="Doing business as"
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />

          <SectionTitle>Primary Contact</SectionTitle>
          <TextField
            name="contact_firstname"
            label="First Name"
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="contact_lastname"
            label="Last Name"
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="contact_email"
            label="Email"
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="email"
          />
          <Flex>
            <Flex1>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="country_code"
                autoComplete="countryCode"
                placeholder={"Country Code"}
                id="countryCodeSelectBox"
                options={getPhoneCodesOptions()}
                rules={{
                  required: true,
                }}
                errors={errors}
                formState={formState}
              />
            </Flex1>
            <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
              <TextField
                name="contact_phone"
                label="Phone Number"
                theref={register({
                  required: true,
                })}
                formState={formState}
                errors={errors}
                type="tel"
              />
            </Flex2>
          </Flex>

          <SectionTitle>HQ Address</SectionTitle>
          <Controller
            as={SelectBoxV2}
            control={control}
            name="country"
            autoComplete={"country-name"}
            placeholder="Country"
            options={countries}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
          />
          {selectedCountry && (
            <>
              <TextField
                name="address1"
                autoComplete="address-line1"
                label={getAddressOneInputLabel(selectedCountry?.value)}
                theref={register({
                  required: true,
                })}
                formState={formState}
                errors={errors}
                type="text"
              />
              <TextField
                name="address2"
                autoComplete="address-line2"
                label={getAddressTwoInputLabel(selectedCountry.value)}
                theref={register({
                  required: false,
                })}
                formState={formState}
                errors={errors}
                type="text"
              />
              <Flex>
                <Flex2
                  style={{
                    marginRight: selectedCountry.value === "IN" ? "14px" : "0",
                  }}
                >
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="state"
                    autoComplete="address-level1"
                    placeholder={getStatesInputLabel(selectedCountry.value)}
                    id="portSelectBox"
                    options={states}
                    rules={{
                      required: true,
                    }}
                    errors={errors}
                    formState={formState}
                  />
                </Flex2>
                {selectedCountry.value === "IN" && (
                  <Flex1>
                    <TextField
                      name="county"
                      label="Taluka"
                      theref={register({
                        required: false,
                      })}
                      formState={formState}
                      errors={errors}
                      type="text"
                    />
                  </Flex1>
                )}
              </Flex>
              <Flex>
                <Flex2>
                  <TextField
                    name="city"
                    label="City"
                    theref={register({
                      required: true,
                    })}
                    formState={formState}
                    errors={errors}
                    type="text"
                  />
                </Flex2>
                <Flex1>
                  <TextField
                    name="postal_code"
                    autoComplete="postal-code"
                    label={getZipCodeInputLabel(selectedCountry.value)}
                    theref={register({
                      required: true,
                    })}
                    formState={formState}
                    errors={errors}
                    type="text"
                  />
                </Flex1>
              </Flex>
            </>
          )}
          <SubmitButtonContainer>
            <PrimaryButtonMedium>Create Profile</PrimaryButtonMedium>
          </SubmitButtonContainer>
        </Form>
      </div>
    </Container>
  );
}
