import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { H3, H6 } from "../../../components/Typography/Typography";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import type { MethodsOfUseForm, OptionType } from "../../../types/types";
import { TextField } from "../../../components/TextFields/TextFields";
import { Flex, Flex1, Flex2 } from "../../../layout/FormLayout";
import { useCountriesList, useStatesList } from "../../../util/Locations";
import {
  getAddressOneInputLabel,
  getAddressTwoInputLabel,
  getStatesInputLabel,
  getZipCodeInputLabel,
} from "../../../util/location";
import type { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { strings } from "../../../util/strings";
import styled from "styled-components";
import { ToggleSwitch } from "../../../components/ToggleSwitch/ToggleSwitch";

const ToggleContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px 0;
`;

export type RequestAddressSchemaType = {
  external_id?: string;
  company_name?: string;
  country: { label: string; value: string };
  county: string;
  address1: string;
  address2: string;
  state: { label: string; value: string };
  city: string;
  postal_code: string;
};

export type RequestAddressWithBillingAddressSchemaType =
  RequestAddressSchemaType & {
    billing_country: { label: string; value: string };
    billing_county: string;
    billing_address1: string;
    billing_address2: string;
    billing_state: { label: string; value: string };
    billing_city: string;
    billing_postal_code: string;
  };

export const getRequestAddressSchema = (
  t: TFunction,
  hideBillingAddress: boolean
) => ({
  external_id: Yup.string().nullable().notRequired(),
  company_name: Yup.string().nullable().notRequired(),
  tax_id: Yup.string().nullable().notRequired(),
  country: Yup.object()
    .shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
    .nullable()
    .required(strings(t).thisIsARequiredField),
  county: Yup.string().nullable().notRequired(),
  address1: Yup.string().required(strings(t).thisIsARequiredField),
  address2: Yup.string().notRequired(),
  state: Yup.object()
    .shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
    .nullable()
    .required(strings(t).thisIsARequiredField),
  city: Yup.string().required(strings(t).thisIsARequiredField),
  postal_code: Yup.string().required(strings(t).thisIsARequiredField),
  ...(hideBillingAddress
    ? {}
    : {
        billing_tax_id: Yup.string().nullable().notRequired(),
        billing_country: Yup.object()
          .shape({
            value: Yup.string().required(),
            label: Yup.string().required(),
          })
          .nullable()
          .required(strings(t).thisIsARequiredField),
        billing_county: Yup.string().nullable().notRequired(),
        billing_address1: Yup.string().required(
          strings(t).thisIsARequiredField
        ),
        billing_address2: Yup.string().notRequired(),
        billing_state: Yup.object()
          .shape({
            value: Yup.string().required(),
            label: Yup.string().required(),
          })
          .nullable()
          .required(strings(t).thisIsARequiredField),
        billing_city: Yup.string().required(strings(t).thisIsARequiredField),
        billing_postal_code: Yup.string().required(
          strings(t).thisIsARequiredField
        ),
      }),
});

/*
 * Component for Address used in request forms
    The form object has these properties:
    {
        external_id?: string;
        company_name?: string;
        tax_id?: string;
        country: string;
        address1: string;
        address2?: string;
        state: string;
        county?: string; // only shows if selected country is India
        city: string;
        postal_code: string;
        ...{hide_billing_address ? {} : {
          billing_tax_id?: string;
          billing_country: string;
          billing_address1: string;
          billing_address2?: string;
          billing_state: string;
          billing_county?: string; // only shows if selected country is India
          billing_city: string;
          billing_postal_code: string;
        }}        
    }
 * @param param0 
 * @returns 
 */
export const RequestAddress = ({
  methodsOfUseForm,
  hideBillingAddress,
  setHideBillingAddress,
  showBillingAddressOption,
  showLocationId,
  show_tax_id = false,
  formTitle,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  hideBillingAddress: boolean;
  showBillingAddressOption: boolean;
  showLocationId: boolean;
  show_tax_id?: boolean;
  formTitle?: string;
  setHideBillingAddress?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { control, formState, errors, watch, register, setValue } =
    methodsOfUseForm;

  const countries = useCountriesList();
  const selectedCountry: OptionType<string> = watch("country");
  const selectedBillingCountry: OptionType<string> = watch("billing_country");
  const states = useStatesList(selectedCountry?.value);
  const billingStates = useStatesList(selectedBillingCountry?.value);

  const toggleHideBillingAddress = () => {
    if (setHideBillingAddress) {
      setHideBillingAddress((prev) => !prev);
    }
  };

  useEffect(() => {
    if (selectedCountry?.value) {
      setValue("address1", undefined);
      setValue("address2", undefined);
      setValue("city", undefined);
      setValue("county", undefined);
      setValue("postal_code", undefined);
      setValue("state", null);
    }
  }, [selectedCountry, setValue]);

  useEffect(() => {
    if (selectedBillingCountry?.value) {
      setValue("billing_address1", undefined);
      setValue("billing_address2", undefined);
      setValue("billing_city", undefined);
      setValue("billing_county", undefined);
      setValue("billing_postal_code", undefined);
      setValue("billing_state", null);
    }
  }, [selectedBillingCountry, setValue]);

  useEffect(() => {
    setValue("billing_country", null);
  }, [hideBillingAddress, setValue]);

  return (
    <>
      <H3>{formTitle ?? t("Shipping Address")}</H3>
      {showLocationId ? (
        <Flex>
          <Flex2 style={{ marginRight: "14px" }}>
            <TextField
              name="company_name"
              label={t("Company Name")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
          </Flex2>
          <Flex1>
            <TextField
              name="external_id"
              label={t("Location ID")}
              theref={register({
                required: false,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
          </Flex1>
        </Flex>
      ) : (
        <></>
      )}
      {show_tax_id && (
        <TextField
          name="tax_id"
          label={t("Tax ID")}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
      )}
      <Controller
        as={SelectBoxV2}
        control={control}
        name="country"
        autoComplete={"country-name"}
        placeholder={t("Country")}
        options={countries}
        rules={{
          required: true,
        }}
        errors={errors}
        formState={formState}
      />
      {selectedCountry && (
        <>
          <TextField
            name="address1"
            autoComplete="address-line1"
            label={getAddressOneInputLabel(selectedCountry?.value)}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="address2"
            autoComplete="address-line2"
            label={getAddressTwoInputLabel(selectedCountry.value)}
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <Flex>
            <Flex2
              style={{
                marginRight: selectedCountry.value === "IN" ? "14px" : "0",
              }}
            >
              <Controller
                as={SelectBoxV2}
                control={control}
                name="state"
                autoComplete="state"
                placeholder={getStatesInputLabel(selectedCountry.value)}
                options={states}
                rules={{
                  required: true,
                }}
                errors={errors}
                formState={formState}
              />
            </Flex2>
            {selectedCountry.value === "IN" && (
              <Flex1>
                <TextField
                  name="county"
                  autoComplete="county"
                  label={t("Taluka")}
                  theref={register({
                    required: false,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
              </Flex1>
            )}
          </Flex>
          <Flex>
            <Flex2>
              <TextField
                name="city"
                label={t("City")}
                autoComplete="city"
                theref={register({
                  required: true,
                })}
                formState={formState}
                errors={errors}
                type="text"
              />
            </Flex2>
            <Flex1>
              <TextField
                name="postal_code"
                autoComplete="postal-code"
                label={getZipCodeInputLabel(selectedCountry.value)}
                theref={register({
                  required: true,
                })}
                formState={formState}
                errors={errors}
                type="text"
              />
            </Flex1>
          </Flex>
        </>
      )}
      {showBillingAddressOption ? (
        <ToggleContainer>
          <ToggleSwitch
            isChecked={hideBillingAddress}
            onClick={toggleHideBillingAddress}
          />
          <H6>{t("Billing Address is the same as Shipping Address")}</H6>
        </ToggleContainer>
      ) : (
        <></>
      )}
      {!hideBillingAddress && (
        <>
          <H3>{t("Billing Address")}</H3>
          {show_tax_id && (
            <TextField
              name="tax_id"
              label={t("Tax ID")}
              theref={register({
                required: false,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
          )}
          <Controller
            as={SelectBoxV2}
            control={control}
            name="billing_country"
            autoComplete={"billing-country-name"}
            placeholder={t("Country")}
            options={countries}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
          />
          {selectedBillingCountry && (
            <>
              <TextField
                name="billing_address1"
                autoComplete="billing-address-line1"
                label={getAddressOneInputLabel(selectedBillingCountry?.value)}
                theref={register({
                  required: true,
                })}
                formState={formState}
                errors={errors}
                type="text"
              />
              <TextField
                name="billing_address2"
                autoComplete="billing-address-line2"
                label={getAddressTwoInputLabel(selectedBillingCountry.value)}
                theref={register({
                  required: false,
                })}
                formState={formState}
                errors={errors}
                type="text"
              />
              <Flex>
                <Flex2
                  style={{
                    marginRight:
                      selectedBillingCountry.value === "IN" ? "14px" : "0",
                  }}
                >
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="billing_state"
                    autoComplete="billing-state"
                    placeholder={getStatesInputLabel(
                      selectedBillingCountry.value
                    )}
                    options={billingStates}
                    rules={{
                      required: true,
                    }}
                    errors={errors}
                    formState={formState}
                  />
                </Flex2>
                {selectedBillingCountry.value === "IN" && (
                  <Flex1>
                    <TextField
                      name="billing_county"
                      label={t("Taluka")}
                      theref={register({
                        required: false,
                      })}
                      formState={formState}
                      errors={errors}
                      type="text"
                    />
                  </Flex1>
                )}
              </Flex>
              <Flex>
                <Flex2>
                  <TextField
                    name="billing_city"
                    label={t("City")}
                    theref={register({
                      required: true,
                    })}
                    formState={formState}
                    errors={errors}
                    type="text"
                  />
                </Flex2>
                <Flex1>
                  <TextField
                    name="billing_postal_code"
                    autoComplete="billing-postal-code"
                    label={getZipCodeInputLabel(selectedBillingCountry.value)}
                    theref={register({
                      required: true,
                    })}
                    formState={formState}
                    errors={errors}
                    type="text"
                  />
                </Flex1>
              </Flex>
            </>
          )}
        </>
      )}
    </>
  );
};
