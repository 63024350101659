import React from "react";
import { useCookies } from "react-cookie";
import { PrimaryButtonMedium, SecondaryButtonMedium } from "../Buttons/Buttons";
import { BottomModal } from "../Modal/Modal";
import { H3 } from "../Typography/Typography";
import { Trans, useTranslation } from "react-i18next";
import { PrivacyPolicyLink } from "../PrivacyPolicyLink/PrivacyPolicyLink";
import axios from "axios";
import styled from "styled-components/macro";
import { screenSize } from "../../theme";
import { initializeGA } from "../AnalyticsTracker/AnalyticsTracker";
import { useStoreState } from "../../util/util";

const CookiePolicyTextContainer = styled.div`
  max-width: 80%;
  @media ${screenSize.small} {
    max-width: 100%;
  }
`;

/**
 * An extra special route component that returns A modal on the bottom of the
 * screen or nothing. This is checked before every route component, because in
 * theory a user could hit any page without having seen this. The reason its
 * rendered from inside `MapAllowedRoutes is so we can load both the route and
 * this on top of it, to prevent a wild card route that loads only the
 * modal/overlay and waits until OK has been clicked to display the actual component.
 *
 * Also placing it at that level is highest level where we are guaranteed to
 * have translations loaded.
 */
export function CookiePolicyModal({
  show,
  closeModal,
  withdrawMode = false,
}: {
  show: boolean;
  closeModal: () => void;
  withdrawMode?: boolean;
}) {
  const [, setCookie] = useCookies([
    "has-seen-cookie-policy",
    "has-accepted-cookie-policy",
  ]);

  const {
    storefront_metadata: { custom_domain, google_analytics_key },
    storeDispatch,
  } = useStoreState();

  const { t } = useTranslation();

  const handleCookieAcceptance = async () => {
    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 10);

    setCookie("has-seen-cookie-policy", true, { expires: cookieDate });
    setCookie("has-accepted-cookie-policy", true, { expires: cookieDate });

    try {
      await axios.post(`/v1/accept-cookie-policy`, {
        has_accepted_cookie_policy: true,
      });

      if (custom_domain && google_analytics_key) {
        initializeGA(google_analytics_key);
      }

      storeDispatch({
        type: "SET_ANALYTICS_ALLOWED",
        payload: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      closeModal();
    }
  };

  const handleCookieRejectionOrWithdrawl = async () => {
    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 10);
    setCookie("has-accepted-cookie-policy", false, { expires: cookieDate });

    setCookie("has-seen-cookie-policy", true, { expires: cookieDate });
    try {
      await axios.post(`/v1/accept-cookie-policy`, {
        has_accepted_cookie_policy: false,
      });
    } catch (error) {
      console.error(error);
    } finally {
      closeModal();
    }
  };

  if (!show) {
    return null;
  } else {
    return (
      <BottomModal bottomPosition overlay show={show} closeModal={() => {}}>
        <H3>{t("Cookie Policy")}</H3>
        <CookiePolicyTextContainer>
          <Trans
            defaults="We and selected third parties collect personal information as specified in the <PrivacyPolicyLink/> and use cookies for functionality, experience and measurement. Denying consent may make some features unavailable. You can freely give, deny, or withdraw your consent at any time."
            components={{
              PrivacyPolicyLink: <PrivacyPolicyLink agilisPolicy />,
            }}
          />
        </CookiePolicyTextContainer>
        <div style={{ marginTop: "32px" }}>
          <PrimaryButtonMedium
            onClick={handleCookieAcceptance}
            style={{ marginRight: "10px" }}
          >
            {t("Accept")}
          </PrimaryButtonMedium>
          <SecondaryButtonMedium onClick={handleCookieRejectionOrWithdrawl}>
            {withdrawMode ? t("Withdraw") : t("Reject")}
          </SecondaryButtonMedium>
        </div>
      </BottomModal>
    );
  }
}
