import { useTranslation } from "react-i18next";
import { Title } from "../../../components/Typography/Typography";
import { useContext, useEffect, useState } from "react";
import { Form } from "../../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import {
  makeUrlWithParams,
  useFormWrapper,
  useStoreState,
} from "../../../util/util";
import type {
  IAddress,
  IQuotesAPIResponse,
  OptionType,
  QuoteRequestItem,
  SampleRequestDetail,
  SampleRequestPaginatedOutput,
  User,
} from "../../../types/types";
import type { ISubmitQuoteItemForm } from "./UnlistedQuoteItemForm";
import { UnlistedQuoteItemForm } from "./UnlistedQuoteItemForm";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import useSWR, { mutate } from "swr";
import Axios from "axios";
import { Auth } from "../../../components/Auth";
import { endpoints } from "../../../endpoints";
import { BuyerAddUnlistedProductToSampleRequestForm } from "../BuyerAddToSampleRequestForm/BuyerAddUnlistedProductToSampleRequestForm";
import { Loader } from "../../../components/Loader/Loader";

interface FormInputs {
  request_type: OptionType<string>;
}

export const UnlistedProductForm = ({
  editingExistingItem,
  handleCloseFlyout,
}: {
  editingExistingItem?: boolean;
  handleCloseFlyout: () => void;
}) => {
  const { notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { user, roleIsSomeKindOfBuyer } = useContext(Auth);

  const { data: cartData, error: cartError } = useSWR<IQuotesAPIResponse>(
    `/v1/storefronts/${storefront_id}/quotes?status=new&limit=1`,
    {
      onError: (error) => {
        notifyError(t("Error fetching current cart data"), { error });
      },
      revalidateOnFocus: false,
    }
  );

  const cart = cartData?.data[0];

  const buyerQuoteCartExistsAndHasItems = cart && cart?.items.length > 0;

  const {
    data: buyerSampleRequestCartResponse,
    error: buyerSampleRequestCartError,
  } = useSWR<SampleRequestPaginatedOutput>(
    roleIsSomeKindOfBuyer
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_sampleRequests(storefront_id),
          {
            source: "logged_in",
            status: "new",
            limit: "1",
          }
        )
      : null,
    {
      revalidateOnFocus: false,
    }
  );

  const buyerSampleRequestCart = buyerSampleRequestCartResponse?.data[0];

  const buyerSampleRequestCartExistsAndHasItems =
    buyerSampleRequestCart && buyerSampleRequestCart.items?.length > 0;

  const methodsOfUseForm = useFormWrapper<FormInputs>({});

  const { control, formState, errors, watch, setValue, getValues } =
    methodsOfUseForm;
  const [warningMessage] = useState<string | null>(null);

  const requestType = watch("request_type");

  const isLoadingCart = !cartData && !cartError;
  const isLoadingSamples =
    !buyerSampleRequestCartResponse && !buyerSampleRequestCartError;

  const submitQuoteItemForm: ISubmitQuoteItemForm = async (unifiedCartArg) => {
    try {
      const endpoint = `/v1/storefronts/${storefront_id}/unified-cart`;
      await Axios.post(endpoint, unifiedCartArg);
      mutate(endpoint);
      handleCloseFlyout();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!getValues("request_type")) {
      setValue(
        "request_type",
        buyerQuoteCartExistsAndHasItems
          ? { label: t("Quote"), value: "quote" }
          : buyerSampleRequestCartExistsAndHasItems
          ? {
              label: t("Sample"),
              value: "sample",
            }
          : { label: undefined, value: undefined }
      );
    }
  }, [
    getValues,
    setValue,
    t,
    buyerQuoteCartExistsAndHasItems,
    buyerSampleRequestCartExistsAndHasItems,
  ]);

  if (isLoadingCart || isLoadingSamples) {
    return <Loader isLoading />;
  }
  return (
    <>
      <Title style={{ marginTop: "10px" }}>
        {editingExistingItem
          ? t("Edit item")
          : requestType?.value === "sample" ||
            buyerSampleRequestCartExistsAndHasItems
          ? t("Add to Sample Request")
          : t("Add to cart")}
      </Title>
      <div style={{ width: "100%" }}>
        <Form noValidate style={{ margin: "15px 0" }}>
          <Controller
            as={SelectBoxV2}
            control={control}
            name="request_type"
            isDisabled={
              buyerQuoteCartExistsAndHasItems ||
              buyerSampleRequestCartExistsAndHasItems
            }
            placeholder={t("Request Type")}
            options={[
              {
                label: t("Quote"),
                value: "quote",
              },
              {
                label: t("Sample"),
                value: "sample",
              },
            ]}
            defaultValue={
              buyerQuoteCartExistsAndHasItems
                ? { label: t("Quote"), value: "quote" }
                : buyerSampleRequestCartExistsAndHasItems
                ? {
                    label: t("Sample"),
                    value: "sample",
                  }
                : { label: undefined, value: undefined }
            }
            rules={{ required: true }}
            errors={errors}
            formState={formState}
          />
        </Form>
        {(requestType?.value === "quote" ||
          buyerQuoteCartExistsAndHasItems) && (
          <UnlistedQuoteItemForm
            warning={warningMessage}
            submitQuoteItemForm={submitQuoteItemForm}
            buyerUser={user as User}
            showProceedToCheckoutButton={true}
            allowEditingTransactionValues={!cart?.items}
            quoteItems={
              cart?.items.map((item: QuoteRequestItem) => item.product.name) ||
              []
            }
            shippingAddress={
              cart?.shipping_address === null
                ? ({
                    id: "new_address",
                    address1: t("New Address"),
                    state: "",
                    city: "",
                    country: "",
                    postal_code: "",
                  } as IAddress)
                : cart?.shipping_address
            }
            paymentTerm={cart?.payment_term}
            deliveryTerm={cart?.delivery_term}
            currencyCode={
              cart?.items[0]?.currency ||
              user?.settings?.preferred_currency ||
              "USD"
            }
          />
        )}
        {(requestType?.value === "sample" ||
          buyerSampleRequestCartExistsAndHasItems) &&
          user && (
            <BuyerAddUnlistedProductToSampleRequestForm
              cartID={cart?.id}
              closeSlideout={handleCloseFlyout}
              sampleItems={buyerSampleRequestCart?.items.map(
                (item: SampleRequestDetail) => item.product.name
              )}
            />
          )}
      </div>
    </>
  );
};
