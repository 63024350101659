import { PrimaryButtonWithPlusIcon } from "../../../../../components/Buttons/Buttons";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { Table } from "../../../../../components/Table/Table";
import { DropDown } from "../../../../../components/DropDown/DropDown";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import {
  ContentWrapper,
  HeaderBar,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../../layout/portalPageLayout";
import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { Modal } from "../../../../../components/Modal/Modal";
import { useHistory } from "react-router-dom";
import { useDebounce } from "../../../../../util/hooks";
import { useAuthContext } from "../../../../../components/Auth";
import {
  providePrivatePageProps,
  useRoutePath,
} from "../../../../../util/Routing";
import { Title } from "../../../../../components/Typography/Typography";
import { ToggleGroup } from "../../../../../components/ToggleGroups/ToggleGroups";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { makeUrlWithParams } from "../../../../../util/util";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";

export type ContactsTable = {
  name: string;
  email: string;
  id: string;
  phone_number: string;
  associated_company: string;
  contact_owner: string;
};

type ListsTable = {
  id: string;
  list_name: string;
  type: string;
  creator: string;
  size: string;
};

export type Contact = {
  contact_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  contact_owner: string;
  company_name: string;
  city: string;
  country_region: string;
  Industry: string;
};

export type List = {
  list_id: string;
  list_name: string;
  list_type: string;
  created_by: string;
  size: string;
};

const localStrings = (t: TFunction) => ({
  pageTitle: t("Prospects"),
  contactsSearchBarPlaceholder: t("Search by company name"),
  listsSearchBarPlaceholder: t("Search by list name"),
  contactName: t("Contact Name"),
  email: t("Email"),
  phoneNumber: t("Phone Number"),
  contactOwner: t("Contact Owner"),
  associatedCompany: t("Associated Company"),
  createNew: t("Create New"),
  listName: t("List Name"),
  size: t("Size"),
  type: t("Type"),
  lastUpdated: t("Last Updated"),
  creator: t("Creator"),
  folder: t("Folder"),
  usedIn: t("Used In"),
  perPage: t("Per Page"),
});
type TabLabel = string;

export const ProspectsListPage = providePrivatePageProps(({ user }) => {
  const { roleIsSomeKindOfSeller } = useAuthContext();
  const history = useHistory();
  const perPageItems = [10, 20, 50];
  const [showModal, setShowModal] = useState(false);
  const [contactsTableData, setContactsTableData] = useState<ContactsTable[]>(
    []
  );
  const [listsTableData, setListsTableData] = useState<ListsTable[]>([]);

  const { t } = useTranslation();

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  const [query, setQuery] = useQueryParams({
    view: StringParam,
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [perPage, setPerPage] = useState(query?.perPage ?? perPageItems[0]);
  const [pagination, setPagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const TAB_LABELS: TabLabel[] = [t("Contacts"), t("Lists")];
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [currentTab, setCurrentTab] = useState<string>(
    query.view || t("Contacts")
  );
  const currentTabIsContacts = currentTab === t("Contacts");
  const currentTabIsLists = currentTab === t("Lists");
  const { adminPath } = useRoutePath();

  const {
    data: contactsResponse,
    error: contactsError,
    mutate: mutateContacts,
  } = useSWR<Contact[] | undefined>("/v1/digital-marketing/all-contacts.json");

  const {
    data: listsResponse,
    error: listsError,
    mutate: mutateLists,
  } = useSWR<List[] | undefined>("/v1/digital-marketing/list-page.json");

  useEffect(() => {
    if (currentTabIsContacts && contactsResponse) {
      const contacts = contactsResponse.slice(offset, offset + perPage);
      setContactsTableData(
        contacts.map((contact: Contact) => {
          return {
            id: contact.contact_id,
            name: `${contact.first_name} ${contact.last_name}`,
            email: contact.email,
            phone_number: contact.phone_number,
            associated_company: contact.company_name,
            contact_owner: contact.contact_owner,
          };
        })
      );
      setPagination({
        perPage: perPage,
        pageCount: Math.ceil(contactsResponse.length / perPage),
        pageIndex: offset / perPage + 1,
      });
    } else if (currentTabIsLists && listsResponse) {
      const lists = listsResponse.slice(offset, offset + perPage);
      setListsTableData(
        lists.map((list: List) => {
          return {
            id: list.list_id,
            list_name: list.list_name,
            type: list.list_type,
            creator: list.created_by,
            size: list.size,
          };
        })
      );
      setPagination({
        perPage: perPage,
        pageCount: Math.ceil(listsResponse.length / perPage),
        pageIndex: offset / perPage + 1,
      });
    }
  }, [
    currentTab,
    listsResponse,
    contactsResponse,
    currentTabIsLists,
    currentTabIsContacts,
    query,
    perPage,
    offset,
  ]);

  useEffect(() => {
    setQuery({ offset });
  }, [offset, setQuery]);

  useEffect(() => {
    setQuery({ perPage });
  }, [perPage, setQuery]);

  const isLoading =
    (currentTabIsContacts && !contactsResponse && !contactsError) ||
    (currentTabIsLists && !listsResponse && !listsError);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setOffset(0);
    currentTabIsContacts ? mutateContacts() : mutateLists();
  };

  const handlePageClick = (offset: number) => {
    setOffset(offset);
  };

  const handleRowClick = (e: React.MouseEvent): void => {
    if (currentTab === t("Contacts")) {
      window.open(
        `https://app.hubspot.com/contacts/5943361/contact/${e.currentTarget.id}`,
        "_blank"
      );
    } else if (currentTab === t("Lists")) {
      history.push(
        makeUrlWithParams(`${adminPath}/prospects/list/${e.currentTarget.id}`, {
          view: query.view,
          q: query.q,
          offset,
          perPage,
        })
      );
    }
  };

  const changeTab = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tabLabel: TabLabel
  ) => {
    e.preventDefault();
    setCurrentTab(tabLabel);
    setOffset(0);
    setQuery({ view: tabLabel, q: "" });
  };

  useEffect(() => {
    // This useEffect handles keeping the query in sync with the URL.
    if (debouncedSearchQuery === "") {
      setQuery({ q: undefined });
    }
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
      if (currentTabIsContacts && contactsResponse) {
        const contacts = contactsResponse.filter((contact) => {
          const companyName = contact.company_name.toString().toLowerCase();
          return companyName.match(debouncedSearchQuery.toLowerCase());
        });
        const tableList = contacts.slice(0, perPage);
        setContactsTableData(
          tableList.map((contact: Contact) => {
            return {
              id: contact.contact_id,
              name: `${contact.first_name} ${contact.last_name}`,
              email: contact.email,
              phone_number: contact.phone_number,
              associated_company: contact.company_name,
              contact_owner: contact.contact_owner,
            };
          })
        );
        setPagination({
          perPage: perPage,
          pageCount: Math.ceil(contacts.length / perPage),
          pageIndex: 0 / perPage + 1,
        });
      } else if (currentTabIsLists && listsResponse) {
        const lists = listsResponse.filter((list) => {
          const listName = list.list_name.toString().toLowerCase();
          return listName.match(debouncedSearchQuery.toLowerCase());
        });
        const tableList = lists.slice(0, perPage);
        setListsTableData(
          tableList.map((list: List) => {
            return {
              id: list.list_id,
              list_name: list.list_name,
              type: list.list_type,
              creator: list.created_by,
              size: list.size,
            };
          })
        );
        setPagination({
          perPage: perPage,
          pageCount: Math.ceil(lists.length / perPage),
          pageIndex: 0 / perPage + 1,
        });
      }
    }
  }, [
    setQuery,
    query,
    debouncedSearchQuery,
    listsResponse,
    contactsResponse,
    currentTabIsLists,
    currentTabIsContacts,
    perPage,
  ]);

  const contactsColumns = useMemo(
    () => [
      {
        accessor: "name",
        Header: localStrings(t).contactName,
      },
      { accessor: "email", Header: localStrings(t).email },
      { accessor: "phone_number", Header: localStrings(t).phoneNumber },
      {
        accessor: "associated_company",
        Header: localStrings(t).associatedCompany,
      },
      {
        accessor: "contact_owner",
        Header: localStrings(t).contactOwner,
        align: "right",
      },
    ],
    [t]
  );

  const listsColumns = useMemo(
    () => [
      {
        accessor: "list_name",
        Header: localStrings(t).listName,
      },
      { accessor: "size", Header: localStrings(t).size },
      { accessor: "type", Header: localStrings(t).type },
      {
        accessor: "creator",
        Header: localStrings(t).creator,
        align: "right",
      },
    ],
    [t]
  );

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{localStrings(t).pageTitle}</PageTitle>
        <SearchBar
          query={searchQuery}
          placeHolder={
            currentTab === t("Contacts")
              ? localStrings(t).contactsSearchBarPlaceholder
              : localStrings(t).listsSearchBarPlaceholder
          }
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        {roleIsSomeKindOfSeller && (
          <PrimaryButtonWithPlusIcon
            onClick={openModal}
            data-alignright
            disabled
          >
            {localStrings(t).createNew}
          </PrimaryButtonWithPlusIcon>
        )}
        <Modal closeModal={closeModal} show={showModal}>
          <Title>{t("Create New Contact")}</Title>
        </Modal>
      </PageHeader>
      <HeaderBar>
        <ToggleGroup
          names={TAB_LABELS}
          activeButton={currentTab}
          clickHandler={changeTab}
          style={{ minWidth: 0 }}
        />
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={localStrings(t).perPage}
          direction={"left"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
      </HeaderBar>
      <ContentWrapper>
        {currentTab === t("Contacts") ? (
          <Table
            columns={contactsColumns}
            isLoading={isLoading}
            error={contactsError}
            data={contactsTableData}
            rowClick={handleRowClick}
          />
        ) : (
          <Table
            columns={listsColumns}
            isLoading={isLoading}
            error={listsError}
            data={listsTableData}
            rowClick={handleRowClick}
          />
        )}
        <Pagination
          pagination={pagination}
          offset={offset}
          handlePageClick={handlePageClick}
        />
      </ContentWrapper>
    </PageWrapper>
  );
});
