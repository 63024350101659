import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { TertiaryButtonMedium } from "../Buttons/Buttons";
import { FilterStates } from "../FilterModal/FilterModal";

const DropDownWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 10px;
  button {
    cursor: pointer;
    color: ${({ theme }) => theme.secondaryTextColor};
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;
const DropDownModalWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
`;

export type FiltersDropDownItem<T = string> = {
  color: string;
  text: string;
  value: T;
};

export const FiltersDropDown = (props: any) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [activeItems, setActiveItems] = useState<any>([]);
  const wrapperRef = useRef(document.createElement("div"));
  const { t } = useTranslation();
  useEffect(() => {
    // setActive(Number(activeItem) ? Number(activeItem) : activeItem);
    setShowDropDown(false);
    // props.clickHandler(Number(activeItem) ? Number(activeItem) : activeItem);
  }, []); // activeItem

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  const applyFilters = (items: any) => {
    setActiveItems(items);
    setShowDropDown(false);
    props.applyStatus(items);
  };

  const getStatusText = (): string => {
    if (activeItems.length === 1) {
      return `${t("Status is")} ${activeItems[0].text}`;
    }

    if (activeItems.length > 1) {
      return (
        `${t("Status is")} ${activeItems[0].text}` +
        (activeItems.length > 1 ? (
          ` + ${activeItems.length - 1} ${t("more")}`
        ) : (
          <span></span>
        ))
      );
    }
    return t(`Status is any`);
  };

  useEffect(() => {
    setActiveItems(props.activeItems);
    getStatusText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeItems, activeItems]);

  return (
    <DropDownWrapper ref={wrapperRef}>
      <TertiaryButtonMedium
        onClick={() => setShowDropDown(!showDropDown)}
        active={activeItems.length > 0}
      >
        {getStatusText()}
      </TertiaryButtonMedium>
      {showDropDown && (
        <DropDownModalWrapper>
          <FilterStates
            activeItems={activeItems}
            handleApply={applyFilters}
            list={props.list}
          />
        </DropDownModalWrapper>
      )}
    </DropDownWrapper>
  );
};
