import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import type {
  DashboardProductUpdate,
  WithPagination,
} from "../../../types/types";
import { ErrorPlaceholder } from "../../../components/Error";
import { Fragment } from "react";
import { useEffect } from "react";
import {
  DashboardUpdatesHeader,
  DashboardUpdatesWrapper,
  GotoUpdate,
  KeyValueWrapper,
  UpdateEmptyState,
  UpdateKeyValuePairs,
  UpdateUpperSection,
  UpdateWrapper,
  valueArrayToString,
} from "./dashboard.util";
import {
  H3DarkText,
  H4,
  SoftHeader,
  SoftHeaderMediumDarkText,
} from "../../../components/Typography/Typography";
import { LoaderContainer } from "../../public/pages.public.util";
import { Loader } from "../../../components/Loader/Loader";
import {
  StatusLeft,
  getProductStatusColor,
  getProductStatusText,
} from "../../../components/Status/Status";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useRoutePath } from "../../../util/Routing";
import type { AxiosError } from "axios";

export const DashboardProductUpdates = ({
  data,
  error,
  setSize,
  size,
}: {
  data: DashboardProductUpdate[];
  error: AxiosError<any>;
  setSize: (
    size: number | ((size: number) => number)
  ) => Promise<
    WithPagination<{ data: DashboardProductUpdate[] }>[] | undefined
  >;
  size: number;
}) => {
  const { t } = useTranslation();
  const { ref: inViewRef, inView } = useInView({ triggerOnce: true });

  const isLoadingInitialData = !data && !error;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");

  useEffect(() => {
    if (inView) {
      setSize((size) => size + 1);
    }
  }, [inView, setSize]);
  if (error) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error from fetching product updates. Please try again later"
        )}
      />
    );
  }
  return (
    <DashboardUpdatesWrapper>
      <DashboardUpdatesHeader>
        <H4>{t("Product Updates")}</H4>
      </DashboardUpdatesHeader>
      {data.length > 0 ? (
        data.map((update, index) => (
          <Fragment key={update.type_id}>
            <ProductUpdateComponent updateData={update} />
            {isLoadingMore && index === data.length - 1 && (
              <LoaderContainer>
                <Loader isLoading={!!isLoadingMore} omitContainer={true} />
              </LoaderContainer>
            )}
            {index === data.length - 4 && (
              <div key={update.type_id + "observer"} ref={inViewRef} />
            )}
          </Fragment>
        ))
      ) : (
        <UpdateEmptyState>
          <H3DarkText>{t("No recent updates")}</H3DarkText>
        </UpdateEmptyState>
      )}
    </DashboardUpdatesWrapper>
  );
};

function ProductUpdateComponent({
  updateData,
}: {
  updateData: DashboardProductUpdate;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { adminPath } = useRoutePath();
  const { update_data, modified_by } = updateData;
  const statusText =
    update_data.status === "draft"
      ? t("New Product Created")
      : t("Product Updated");

  const keyValuePairs = [
    { label: t("ID"), value: update_data.product_id ?? "--" },
    { label: t("Name"), value: update_data.name ?? "--" },
    {
      label: t("Updated"),
      value: valueArrayToString(update_data.updated_properties),
    },
  ];
  const time = moment(new Date(updateData.last_modified_date)).fromNow();

  const handleUpdateClick = () => {
    history.push(
      `${adminPath}/pim/products/${updateData.type_id}?from=dashboard`
    );
  };
  return (
    <UpdateWrapper onClick={handleUpdateClick}>
      <UpdateUpperSection>
        <KeyValueWrapper>
          <SoftHeaderMediumDarkText>{statusText}</SoftHeaderMediumDarkText>
          <SoftHeader>{`${modified_by.name} | ${modified_by.role}`}</SoftHeader>
        </KeyValueWrapper>
        <KeyValueWrapper style={{ alignItems: "flex-end" }}>
          <StatusLeft
            color={getProductStatusColor(update_data.status)}
            text={getProductStatusText(update_data.status, t)}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
          <SoftHeader>{time}</SoftHeader>
        </KeyValueWrapper>
      </UpdateUpperSection>
      <UpdateKeyValuePairs data={keyValuePairs} />
      <GotoUpdate />
    </UpdateWrapper>
  );
}
