import styled from "styled-components";

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  margin-bottom: 32px;
  gap: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.large};
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
