import React from "react";
import type {
  IOrderItem,
  QuoteRequestItem,
  IPriceTier,
  CurrencyCode,
  QuoteRequestOrOrderItem,
} from "../types/types";
import { formatPrice } from "./util-components";
import type { OrderItemToBeCreated } from "../pages/Seller/SellerCreateOrder/CreateOrderItemCard";

export const getNumberOfUnits = (
  item: QuoteRequestItem | IOrderItem
): string | null => {
  const number_of_units =
    ("no_of_units" in item && item.no_of_units) ||
    ("number_of_units" in item && item.number_of_units);

  return number_of_units || null;
};

export const getCasNumber = (
  item: QuoteRequestItem | IOrderItem | OrderItemToBeCreated
): string | null => {
  const product = item.product;

  const cas_number =
    ("product_identity" in item && item.product_identity.cas_number) ||
    ("identifiers" in product && product.identifiers?.cas_number);

  return cas_number || null;
};

/**
 * Calculates the total price for a single quote request item or order item.
 * Converts strings to numbers, and if conversion results in NaN, returns null
 * to force callers to handle that case.
 *
 * THIS IS ONLY EXPORTED BECAUSE THERE ARE TESTS ASSOCIATED WITH IT.
 * @see getQuoteOrOrderItemValueString for a safe implementation.
 *
 * @param item A quote request item or an order item.
 * @return A number or null.
 */
export const getQuoteOrOrderItemValue = (
  item: QuoteRequestItem | IOrderItem
): number | null => {
  const { price_per_unit, total_quantity } = item;

  if (price_per_unit !== null && total_quantity !== null) {
    const total = parseFloat(total_quantity) * parseFloat(price_per_unit);
    return isNaN(total) ? null : total;
  }
  return null;
};

/**
 * Calculates the 'subtotal' price for a quote request or order, which is the
 * sum of the prices of all the items (but does not include fees, taxes, etc.).
 * If the converstion of strings to numbers fails (NaN), returns null to force
 * callers to handle that case.
 */
export const getQuoteOrOrderSubTotalValue = (
  items: QuoteRequestItem[] | IOrderItem[]
): number | null => {
  let subtotal: number | null = 0;
  for (const item of items) {
    if (subtotal === null) {
      break;
    }
    const itemTotal = getQuoteOrOrderItemValue(item);
    subtotal = itemTotal === null ? null : itemTotal + subtotal;
  }
  return subtotal;
};

/**
 * Calculates the price for a single quote request item or order. If the
 * conversion of strings to numbers fails (NaN), returns a 'missing'
 * placeholder string.
 */
export const getQuoteOrOrderItemValueString = (
  item: QuoteRequestItem | IOrderItem
): string => {
  const total = getQuoteOrOrderItemValue(item);
  return total === null ? "-/-" : formatPrice(total, item.currency);
};

/**
 * Calculates the `subtotal` price for a quote request or order, which is the
 * sum of the prices of all the items (but does not include fees, taxes, etc.).
 * If the conversion of strings to numbers fails (NaN), returns a 'missing'
 * placeholder string.
 */
export const getQuoteOrOrderTotalValueString = (
  items: QuoteRequestItem[] | IOrderItem[]
): string => {
  const currencyCode = items[0]?.currency ?? ("USD" as CurrencyCode);

  const sameCurrencies = (items as QuoteRequestOrOrderItem[]).every(
    (item) => item.currency === currencyCode
  );

  if (sameCurrencies) {
    const subtotal = getQuoteOrOrderSubTotalValue(items);
    return subtotal === null ? "--" : formatPrice(subtotal, currencyCode);
  }
  // This should rarely happen, if ever.
  console.error("Cannot calculate subtotal when currencies are not the same.");
  return "--";
};

/**
 * Selects the price tier "minimum unit of measure quantity" to use for a given
 * number of units of a product.
 *
 * For example, given the tier minumum units of `[10, 20, 30]` and a unit count
 * of `15`, return `10`.
 *
 * Returns `null` if no valid tier is found. For example, if the number of
 * units is smaller than the smallest tier.
 *
 * @param tiersMinimumUnits - Minimum units for price tiers, e.g. `[10, 20, 30]`.
 * @param unitCount - A number of units.
 */
export const selectPriceTierQuantity = (
  tiersMinimumUnits: number[],
  unitCount: number
): number | null => {
  const validMinimumUnits = tiersMinimumUnits.filter(
    (minimumUnits) => minimumUnits <= unitCount
  );
  if (validMinimumUnits.length === 0) {
    return null;
  }
  validMinimumUnits.sort((a, b) => (a < b ? 1 : -1));
  return validMinimumUnits[0];
};

/**
 * Selects the price tier to use to calculate the price for a given number of
 * units of a product (SKUs). Returns null if no valid price tier can be found,
 * for example, if the number of units is lower than the lowest tier.
 */
export const selectPriceTier = (
  tiers: IPriceTier[],
  unitCount: number
): IPriceTier | null => {
  const tierMinimumUnits = tiers.map((tier) =>
    parseInt(tier.minimum_sku_quantity)
  );
  const bestTierUnits = selectPriceTierQuantity(tierMinimumUnits, unitCount);

  if (bestTierUnits === null) {
    return null;
  }

  const bestTierUnitsString = String(bestTierUnits);

  const bestTier = tiers.find(
    (tier) => tier.minimum_sku_quantity === bestTierUnitsString
  );

  // Should always find a tier and so never return null here.
  return bestTier ?? null;
};

/**
 * Calculates the 'price per unit of measure', for a given number of units (of
 * SKUs) for a product, based on the given price tiers.
 *
 * @param tiers - A group of price tiers.
 * @param unitCount - The number of units; used to select the correct tier.
 */
export const calculatePriceTiersPricePerUom = (
  tiers: IPriceTier[],
  unitCount: number | null
): number | null => {
  if (unitCount === null) return null;
  const priceTier = selectPriceTier(tiers, unitCount);
  if (!priceTier) {
    return null;
  }

  const pricePerUom = parseFloat(priceTier.price_per_uom);

  return isNaN(pricePerUom) ? null : pricePerUom;
};

/**
 * Multiplies a given quantity of product by a given "price per unit of measure"
 * to calculate a price for that quantity, like for an item in a quote request
 * or order. For example, 30 LB * $1.00 $/LB = $30.00
 *
 * Handles converting strings to numbers. Useful on seller quote detail page
 * where the backend supplies the price per UOM.
 */
export const calculatePriceForQuantity = (
  pricePerUom: string | number,
  quantity: string | number
): number | null => {
  const pricePerUomNumber =
    typeof pricePerUom === "number" ? pricePerUom : parseFloat(pricePerUom);

  if (isNaN(pricePerUomNumber)) {
    return null;
  }

  const quantityNumber =
    typeof quantity === "number" ? quantity : parseFloat(quantity);

  if (isNaN(quantityNumber)) {
    return null;
  }
  return pricePerUomNumber * quantityNumber;
};

export function getOrderItemsList(items: IOrderItem[]) {
  if (items && items.length > 1) {
    return (
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item.product.name}</li>
        ))}
      </ul>
    );
  }
  return null;
}
