import React, { useState } from "react";
import {
  SingleDatePicker,
  DateRangePicker,
  DayPickerRangeController,
} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
// TODO: fix this type check error from storybook migration. Looks related to
// different versions of react-hook-form
// @ts-ignore
import type { FieldError, NestDataObject } from "react-hook-form";
import styled from "styled-components/macro";
import { TextField } from "../TextFields/TextFields";
import type { Moment } from "moment";
import { FormInputErrorText } from "../Typography/Typography";

interface ICalProps
  extends Partial<React.ComponentProps<typeof SingleDatePicker>> {
  date: any;
  id: string;
  focused: boolean;
  label: string;
  handleDateChange: (date: Moment | null) => void;
  handleFocusChange: (arg: { focused: boolean | null }) => void;
  handleOutsideRange?: (date: any) => boolean;
  errors: NestDataObject<Record<string, any>, FieldError>;
  normalPlaceholder?: boolean;
  name: string;
}

interface IRangeCalProps
  extends Partial<React.ComponentProps<typeof DateRangePicker>> {
  startDate: any;
  endDate: any;
  startDateId: any;
  endDateId: any;
  label: string;
  handleDatesChange: (range: any) => void;
  errors?: NestDataObject<Record<string, any>, FieldError>;
  name: string;
}

interface IControlledRangeCalProps {
  startDate: any;
  endDate: any;
  handleDatesChange: (range: any) => void;
  handleFocusChange?: (focused: any) => void;
  errors?: NestDataObject<Record<string, any>, FieldError>;
  focusedInput: "startDate" | "endDate" | null;
  // Indexer
  [x: string]: any;
}

interface IStyledCalProps {
  hasError: boolean;
  disabled: boolean;
}

const FloatingLabel = styled.div<IStyledCalProps>`
  left: 13px;
  top: 7px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.fontWeights.small};
  color: ${({ hasError, theme }) =>
    hasError ? theme.errorColor : theme.secondaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  transition: 0.2s ease all;
  position: absolute;
`;

const StyledCalendar = styled.div<IStyledCalProps>`
  position: relative;
  > div {
    width: 100%;
  }

  .DateRangePicker {
    width: 100%;
    border: 0;
    > div {
      width: 100%;
      border: 0;
    }
    .DateRangePickerInput__withBorder {
      border-radius: 4px;
      border: ${(props) =>
        props.hasError
          ? `2px solid ${props.theme.errorColor}`
          : `1px solid ${props.theme.primaryBorder}`};
      &:focus-within {
        border: ${(props) =>
          props.hasError
            ? `2px solid ${props.theme.errorColor}`
            : `2px solid ${props.theme.tertiaryBorder}`};
        outline: none;
      }
    }
    .DateRangePickerInput {
      width: 100%;
      padding: 8px 0 8px;
      font-family: ${({ theme }) => theme.fontFamily};
      font-size: ${({ theme }) => theme.fontSizes.medium};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.disabledInput : theme.primaryBG};
      .DateRangePickerInput_calendarIcon {
        position: absolute;
        right: 0;
        top: 4px;
        svg {
          fill: ${(props) =>
            props.hasError
              ? `${props.theme.errorColor}`
              : props.disabled
              ? `${props.theme.secondaryBG}`
              : `${props.theme.tertiaryBG}`};
          height: 22px;
          width: 24px;
        }
      }
      > div:first-of-type {
        position: relative;
        left: 8px;
      }
      .DateInput {
        background: ${({ theme, disabled }) =>
          disabled ? theme.disabledInput : theme.primaryBG};
        width: fit-content;
        > .DateInput_input {
          font-family: ${({ theme }) => theme.fontFamily};
          font-size: ${({ theme }) => theme.fontSizes.medium};
          font-weight: ${({ theme }) => theme.fontWeights.medium};
          background-color: ${({ theme }) => theme.primaryBG};
          padding: 10px 0 0 0;
          cursor: pointer;
          border-bottom: 0;
          width: 87px;
          text-align: center;
          &:focus {
            border-bottom: ${(props) =>
              props.hasError
                ? `2px solid ${props.theme.errorColor}`
                : `2px solid ${props.theme.tertiaryBorder}`};
            outline: none;
          }
        }
      }
      > .DateRangePickerInput_arrow {
        position: relative;
        top: 4px;
        margin: 0 0 0 8px;
        .DateRangePickerInput_arrow_svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .DateRangePicker_picker,
  .DayPicker {
    .CalendarDay__default {
      border: 0;
      border-radius: 0px;
      font-size: 15px;
      padding: 10px;
    }
    .DayPickerNavigation_button__default {
      border-color: transparent;
      border-radius: 25px;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      padding: 3px;
      svg {
        display: none;
      }
      &:hover {
        border-color: ${({ theme }) => theme.secondaryBorder};
        background: ${({ theme, disabled }) =>
          disabled ? theme.disabledInput : theme.secondaryBG};
      }
      &.DayPickerNavigation_rightButton__horizontalDefault::after {
        content: " ";
        width: 24px;
        height: 24px;
        position: absolute;
        left: 5px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%230F1D2B' fill-rule='evenodd' d='M7.701 4.003c.465-.365 1.14-.335 1.57.091l7.132 7.073c.225.224.347.523.347.833 0 .31-.122.609-.347.833L9.27 19.906c-.463.459-1.211.459-1.673 0-.464-.46-.464-1.206 0-1.665L13.89 12l-6.29-6.24c-.465-.46-.465-1.206 0-1.666z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
      }
      &.DayPickerNavigation_leftButton__horizontalDefault::after {
        content: " ";
        width: 24px;
        height: 24px;
        position: absolute;
        left: 1px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%230F1D2B' fill-rule='evenodd' d='M14.729 4.094c.463-.459 1.211-.459 1.673 0 .464.46.464 1.206 0 1.665L10.11 12l6.29 6.24c.465.46.465 1.206 0 1.666l-.102.091c-.465.365-1.14.335-1.57-.091l-7.132-7.073c-.225-.224-.347-.523-.347-.833 0-.31.122-.609.347-.833z'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
      }
    }
    .DayPicker_weekHeader {
      small {
        font-size: ${({ theme }) => theme.fontSizes.small};
      }
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: ${({ theme }) => theme.secondaryButtonBG};
      color: ${({ theme }) => theme.secondaryLinkHover};
    }
    .CalendarDay__selected_span,
    .CalendarDay__hovered_span {
      background: ${({ theme }) => theme.secondaryBG};
      color: ${({ theme, disabled }) =>
        disabled ? theme.secondaryTextColor : theme.primaryTextColor};
    }
    .CalendarDay__selected_span:hover {
      background: ${({ theme }) => theme.secondaryButtonBG};
    }
    .CalendarDay__selected_start {
      border-radius: 20px 0 0 20px !important;
    }
    .CalendarDay__selected_end {
      border-radius: 0 20px 20px 0 !important;
    }
    .CalendarDay__default:hover {
      background: ${({ theme }) => theme.secondaryBG};
    }
    .DayPickerKeyboardShortcuts_showSpan {
      color: ${({ theme, disabled }) =>
        disabled ? theme.secondaryTextColor : theme.primaryTextColor};
    }
    .DayPickerKeyboardShortcuts_show__bottomRight::before {
      border-right-color: ${({ theme }) => theme.secondaryButtonBG};
    }
  }

  .SingleDatePickerInput {
    width: 100%;
    border: 0;
    font-family: ${({ theme }) => theme.fontFamily};
    .DateInput {
      width: 100%;
    }
    input {
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      border-radius: 4px;
      border: ${(props) =>
        props.hasError
          ? `2px solid ${props.theme.errorColor}`
          : `1px solid ${props.theme.primaryBorder}`};
      background-color: ${({ theme, disabled }) =>
        disabled ? theme.disabledInput : theme.primaryBG};
      font-family: ${({ theme }) => theme.fontFamily};
      font-size: ${({ theme }) => theme.fontSizes.medium};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      padding: 21px 13px 6px 12px;
      ::placeholder {
        color: ${({ hasError, theme }) =>
          (hasError && theme.errorColor) || theme.secondaryTextColor};
      }
      &:focus {
        border: ${(props) =>
          props.hasError
            ? `2px solid ${props.theme.errorColor}`
            : `2px solid ${props.theme.tertiaryBorder}`};
        outline: none;
      }
    }
    .SingleDatePickerInput_calendarIcon {
      position: absolute;
      right: 0;
      top: 4px;
      svg {
        fill: ${(props) =>
          props.hasError
            ? `${props.theme.errorColor}`
            : props.disabled
            ? `${props.theme.secondaryBG}`
            : `${props.theme.tertiaryBG}`};
        height: 22px;
        width: 24px;
      }
    }
    .SingleDatePicker_picker {
      box-shadow: 0 4px 8px 0 ${({ theme }) => theme.shadowColor};
      .CalendarDay__default {
        border: 0;
        border-radius: 20px;
        font-size: 15px;
        padding: 10px;
      }
      .DayPickerNavigation_button__default {
        border-color: transparent;
        border-radius: 25px;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        padding: 3px;
        svg {
          display: none;
        }
        &:hover {
          border-color: ${({ theme }) => theme.secondaryBorder};
          background: ${({ theme }) => theme.secondaryBG};
        }
        &.DayPickerNavigation_rightButton__horizontalDefault::after {
          content: " ";
          width: 24px;
          height: 24px;
          position: absolute;
          left: 5px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%230F1D2B' fill-rule='evenodd' d='M7.701 4.003c.465-.365 1.14-.335 1.57.091l7.132 7.073c.225.224.347.523.347.833 0 .31-.122.609-.347.833L9.27 19.906c-.463.459-1.211.459-1.673 0-.464-.46-.464-1.206 0-1.665L13.89 12l-6.29-6.24c-.465-.46-.465-1.206 0-1.666z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
        }
        &.DayPickerNavigation_leftButton__horizontalDefault::after {
          content: " ";
          width: 24px;
          height: 24px;
          position: absolute;
          left: 1px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%230F1D2B' fill-rule='evenodd' d='M14.729 4.094c.463-.459 1.211-.459 1.673 0 .464.46.464 1.206 0 1.665L10.11 12l6.29 6.24c.465.46.465 1.206 0 1.666l-.102.091c-.465.365-1.14.335-1.57-.091l-7.132-7.073c-.225-.224-.347-.523-.347-.833 0-.31.122-.609.347-.833z'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
        }
      }
      .DayPicker_weekHeader {
        small {
          font-size: 13px;
        }
      }
      .CalendarDay__selected,
      .CalendarDay__selected:active,
      .CalendarDay__selected:hover {
        background: ${({ theme }) => theme.secondaryButtonBG};
        color: ${({ theme, disabled }) =>
          disabled ? theme.secondaryTextColor : theme.primaryTextColor};
      }
      .CalendarDay__default:hover {
        background: ${({ theme }) => theme.secondaryBG};
      }
      .DayPickerKeyboardShortcuts_showSpan {
        color: ${({ theme, disabled }) =>
          disabled ? theme.secondaryTextColor : theme.primaryTextColor};
      }
      .DayPickerKeyboardShortcuts_show__bottomRight::before {
        border-right-color: ${({ theme }) => theme.secondaryButtonBG};
      }
    }
  }
`;

const StyledControlledRangeCalendar = styled(StyledCalendar)`
  .DayPicker__withBorder {
    box-shadow: none;
  }
`;

const CalendarInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: left;
  padding: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
  input:first-child {
    margin-right: 10px;
  }
`;
export const Calendar = ({
  handleDateChange,
  date,
  focused,
  handleFocusChange,
  handleOutsideRange,
  id,
  errors,
  name,
  label,
  normalPlaceholder,
  ...rest
}: ICalProps) => {
  const hasError = !!errors[name];
  const is_disabled = rest?.disabled ?? false;
  return (
    <StyledCalendar disabled={is_disabled} hasError={hasError}>
      <SingleDatePicker
        date={date}
        onDateChange={handleDateChange}
        focused={focused}
        onFocusChange={handleFocusChange}
        id={id}
        numberOfMonths={1}
        isOutsideRange={handleOutsideRange}
        showDefaultInputIcon={true}
        inputIconPosition="after"
        placeholder={normalPlaceholder ? label : ""}
        {...rest}
      />
      {!normalPlaceholder && (
        <FloatingLabel disabled={is_disabled} hasError={hasError}>
          {label}
        </FloatingLabel>
      )}
      {hasError && (
        <div
          style={{ position: "absolute", bottom: "-12px", minWidth: "250px" }}
        >
          <FormInputErrorText className="errorMessage">
            {errors[name]?.message}
          </FormInputErrorText>
        </div>
      )}
    </StyledCalendar>
  );
};

export const RangeCalendar = ({
  handleDatesChange,
  startDate,
  endDate,
  startDateId,
  endDateId,
  errors,
  name,
  label,
  ...rest
}: IRangeCalProps) => {
  const hasError = !!(errors && errors[name]);
  const is_disabled = Boolean(rest?.disabled) ?? false;
  const [focusedRange, setFocusedRange] =
    useState<"startDate" | "endDate" | null>(null);
  return (
    <StyledCalendar disabled={is_disabled} hasError={hasError}>
      <DateRangePicker
        {...rest}
        startDate={startDate}
        startDateId={startDateId}
        endDate={endDate}
        endDateId={endDateId}
        startDatePlaceholderText={"From"}
        numberOfMonths={1}
        endDatePlaceholderText={"To"}
        isOutsideRange={() => false}
        onDatesChange={({ startDate, endDate }) =>
          handleDatesChange({ startDate, endDate })
        }
        focusedInput={focusedRange}
        onFocusChange={(focusedInput) => setFocusedRange(focusedInput)}
      />
      <FloatingLabel disabled={is_disabled} hasError={hasError}>
        {label}
      </FloatingLabel>
    </StyledCalendar>
  );
};

export const ControlledRangeCalendar = ({
  handleDatesChange,
  startDate,
  endDate,
  handleFocusChange,
  errors,
  name,
  label,
  direction,
  ...rest
}: IControlledRangeCalProps) => {
  const hasError = !!(errors && errors[name]);
  const is_disabled = Boolean(rest?.disabled) ?? false;
  const [focusedRange, setFocusedRange] =
    useState<"startDate" | "endDate">("startDate");
  const [dateRange, setDateRange] = useState<any>({
    startDate: startDate,
    endDate: endDate,
  });
  return (
    <StyledControlledRangeCalendar disabled={is_disabled} hasError={hasError}>
      <CalendarInputWrapper>
        {/* TODO: fix this type checking error from storybook migration. */}
        {/* @ts-ignore */}
        <TextField
          label={"From"}
          name={"from"}
          defaultValue={dateRange?.startDate?.format("DD/MM/YY")}
          readOnly
          required={false}
        />
        {/* TODO: fix this type checking error from storybook migration. */}
        {/* @ts-ignore */}
        <TextField
          defaultValue={dateRange?.endDate?.format("DD/MM/YY")}
          label={"To"}
          name={"to"}
          readOnly
          required={false}
        />
      </CalendarInputWrapper>
      <DayPickerRangeController
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        numberOfMonths={2}
        hideKeyboardShortcutsPanel={true}
        onDatesChange={({ startDate, endDate }) => {
          setDateRange({ startDate, endDate });
          handleDatesChange({ startDate, endDate });
        }}
        focusedInput={focusedRange}
        onFocusChange={(focusedRange) => {
          setFocusedRange(!focusedRange ? "startDate" : focusedRange);
        }}
        disabled={rest.disabled}
      />
    </StyledControlledRangeCalendar>
  );
};
