import type { ChangeEventHandler, RefObject } from "react";
import React, { useEffect } from "react";
import type {
  DeepMap,
  FieldElement,
  FieldError,
  FormState,
  Ref,
  RegisterOptions,
} from "react-hook-form";
import styled from "styled-components/macro";
import type { FontType } from "../../types/types";
import { showErrors } from "../../util/util-components";
import { useTranslation } from "react-i18next";

const Input = styled.input.attrs({})<{
  invalid?: boolean;
  fontSize?: FontType;
  fontWeight?: FontType;
}>`
  height: 32px;
  width: fit-content;
  min-width: 100px;
  border: ${({ theme, invalid }) =>
    invalid
      ? `1px solid ${theme.errorColor}`
      : `1px solid ${theme.tertiaryBorder}`};
  padding: 4px;
  font-size: ${({ theme, fontSize }) => theme.fontSizes[fontSize ?? "large"]};
  font-weight: ${({ theme, fontWeight }) =>
    theme.fontWeights[fontWeight ?? "large"]};
  outline: none;
  margin: 0;
  &:focus {
    border-radius: 4px;
    border: ${({ theme, invalid }) =>
      invalid
        ? `2px solid ${theme.errorColor}`
        : `2px solid ${theme.tertiaryBorder}`};
  }
`;

export const EditModeTitle = ({
  theref,
  register,
  name,
  defaultValue,
  tabIndex,
  formState,
  errors,
  onChange,
  fontSize,
  fontWeight,
}: {
  theref?: RefObject<HTMLInputElement>;
  register: <TFieldElement extends FieldElement<any>>(
    ref: (TFieldElement & Ref) | null,
    rules?: RegisterOptions
  ) => void;
  name: string;
  defaultValue: string;
  tabIndex?: number;
  formState: FormState<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  fontSize?: FontType;
  fontWeight?: FontType;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (theref) {
      register(theref.current);
      theref.current?.focus();
    }
  }, [register, theref]);
  if (
    (formState.submitCount > 0 && errors[name]?.message) ||
    errors[name]?.type
  ) {
    return (
      <>
        <Input
          autoComplete="on"
          ref={theref}
          type="text"
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          invalid
          tabIndex={tabIndex}
          fontSize={fontSize}
          fontWeight={fontWeight}
        />
        {showErrors({ errors, name, t })}
      </>
    );
  }
  return (
    <Input
      type="text"
      ref={theref}
      name={name}
      required
      defaultValue={defaultValue}
      onChange={onChange}
      tabIndex={tabIndex}
      fontSize={fontSize}
      fontWeight={fontWeight}
    />
  );
};
