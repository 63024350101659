import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { v4 } from "uuid";
import ReactTooltip from "react-tooltip";
import { screenSize } from "../../theme";
import { DeleteButton, EditButton } from "../Buttons/Buttons";

const DataListTable = styled.table`
  /* 1% width lets table shrink to size of columns. */
  width: 1%;
  border-collapse: collapse;

  @media ${screenSize.small} {
    width: 100%;
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};

    th,
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 15px 10px 15px 15px;
      /* 1% width shrinks columns to the size of their content. */
      width: 1%;

      font-family: ${({ theme }) => theme.fontFamily};
      font-size: ${({ theme }) => theme.fontSizes.small};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }

    th {
      color: ${({ theme }) => theme.secondaryTextColor};
      text-align: left;
    }

    td {
      background-color: ${({ theme }) => theme.primaryBG};
      color: ${({ theme }) => theme.primaryTextColor};
      @media ${screenSize.small} {
        white-space: break-spaces;
      }
    }

    @media (max-width: ${screenSize.medium}) {
      td {
        width: unset;
        /* Let text wrap on smaller screens. */
        white-space: normal;
      }
    }

    td:nth-child(1) {
      border-left: 8px solid ${({ theme }) => theme.secondaryBorder};
    }
  }
`;

// Style the ReactTooltip component by wrapping it in a parent div. When the
// tooltip is visible it has the class "show", and targeting this class lets us
// avoid using `!important`.
const ReactTooltipParent = styled.div`
  & > div.show {
    background-color: ${({ theme }) => theme.tertiaryBG};
    border-radius: 2px;
    padding: 8px 8px 16px;
    opacity: 0.75;

    color: ${({ theme }) => theme.invertTextColor};
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSizes["extra-small"]};
    font-weight: ${({ theme }) => theme.fontWeights["extra-small"]};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: -0.07px;
    text-align: center;
  }

  & > div.show .multi-line {
    color: ${({ theme }) => theme.invertTextColor};
  }
`;

interface IDataListProps {
  rows: (string | React.ReactElement)[][];
  edit?: boolean;
  editFnc?: (arg: string) => void;
  deleteFnc?: (arg: string) => void;
  ids?: string[][];
  headers?: string[];
  isSKU?: boolean;
  className?: string;
}

/**
 * Used in the "Chemical Details" section. Note that the arrays representing
 * rows that are passed in must all be the same length.
 */
export const DataList = (props: IDataListProps): JSX.Element => {
  const handleEdit = (rowIndex: number) => {
    if (props.editFnc && props.ids) {
      const ids = props.ids;
      props.editFnc(ids[rowIndex][0]);
    }
  };

  const handleDelete = (rowIndex: number) => {
    if (props.deleteFnc && props.ids) {
      const ids = props.ids;
      props.deleteFnc(ids[rowIndex][0]);
    }
  };

  return (
    // Providing a `className` prop allows us to extend this component using
    // styled components, for example:
    // const MyDataList = styled(DataList)` /* ... */ `;
    // See: https://styled-components.com/docs/basics#styling-any-component
    <DataListTable className={props.className}>
      {props.headers && props.headers.length > 0 && (
        <thead>
          <tr>
            {props.headers.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
      )}
      <tbody>
        {props.rows.map((row, rowIndex) => {
          return (
            <tr key={rowIndex}>
              {row.map((cellContents, cellIndex) => {
                return typeof cellContents === "string" ? (
                  <TdWithTooltip
                    text={cellContents}
                    key={cellContents + cellIndex}
                  />
                ) : (
                  <td key={cellIndex}>{cellContents}</td>
                );
              })}
              {props.edit && props.deleteFnc && (
                <td>
                  <DeleteButton
                    testid={`delete-item-in-datalist`}
                    onClick={() => handleDelete(rowIndex)}
                  />
                </td>
              )}
              {props.edit && props.editFnc && (
                <td>
                  <EditButton
                    testid={"edit-item-in-datalist"}
                    onClick={() => handleEdit(rowIndex)}
                  />
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </DataListTable>
  );
};

interface ITdWithTooltipProps {
  text: string;
}

/**
 * For table cells with text that is too wide to fit, they are rendered with
 * partial text and "...", then a tooltip is shown with the full text.
 * To do this we use CSS `text-overflow: ellipsis` which works for any given
 * width. Then to detect when a tooltip is needed we have to compare the
 * `scrollWidth` with the `clientWidth` of the initially rendered <td> element
 * and re-render if needed to add the tooltip. Apparently the ReactTooltip
 * needs to be rendered here, along with the <td>, to work.
 */
export function TdWithTooltip({ text }: ITdWithTooltipProps): JSX.Element {
  const maxTooltipLineLength = 30;
  const ref = useRef(null);
  const [needsTooltip, setNeedsTooltip] = useState(false);
  const tooltipId = v4();

  useEffect(() => {
    const element = ref.current as unknown as HTMLElement;
    if (
      element?.scrollWidth &&
      element.clientWidth &&
      element.scrollWidth !== element.clientWidth
    ) {
      setNeedsTooltip(true);
    }
  }, [ref]);

  return needsTooltip ? (
    <td
      data-tip={addBreaksToString(text, maxTooltipLineLength)}
      data-for={tooltipId}
      data-offset="{'top': -16, 'right': 54}"
    >
      {text}
      <ReactTooltipParent>
        <ReactTooltip
          id={tooltipId}
          backgroundColor="#60676f"
          place="top"
          data-html={true}
          effect="solid"
          multiline={true}
        />
      </ReactTooltipParent>
    </td>
  ) : (
    <td ref={ref}>{text}</td>
  );
}

/**
 * Takes a string and adds `<br/>` to achieve a very basic form of word-wrap.
 * Word wrap is hard to do well, so this is only best effort.
 * Used to prepare text for use in tooltips.
 *
 * @param text - The text.
 * @param maxLength - Number of characters between `<br/>`s.
 * @return Text with `<br/>`s added.
 */
function addBreaksToString(text: string, maxLength: number): string {
  const rows = [""];
  const words = text.split(" ").map((word) => word.trim());

  for (const word of words) {
    const row = rows[rows.length - 1];

    if (row.length + word.length < maxLength) {
      rows.push(`${rows.pop()} ${word}`);
    } else {
      rows.push(word);
    }
  }
  return rows.join("<br/>");
}
