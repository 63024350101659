import styled from "styled-components/macro";
import { appendFiltersToUrl } from "../../SharedPages/OrganizationPage/ProductsList/ProductsList.util";

export const TooltipCell = styled.div`
  ul {
    padding-left: 10px;
    li {
      margin-bottom: 5px;
    }
  }
`;

type ConstructQuery = {
  baseUrl: string;
  query: string;
  offset: number;
  perPage: number;
  status: string[];
};

type ApplyFiltersToURL = {
  status: string[];
  params: URLSearchParams;
};

export const update_status_string_list = (status: string[]) => {
  const updated_status = [...status];
  const has_pending =
    updated_status.findIndex((item) => item === "pending") !== -1;
  const has_pending_activation =
    updated_status.findIndex((item) => item === "pending_activation") !== -1;
  if (
    (has_pending && !has_pending_activation) ||
    (!has_pending && has_pending_activation)
  ) {
    if (has_pending && !has_pending_activation) {
      updated_status.push("pending_activation");
    } else {
      updated_status.push("pending");
    }
  }
  return updated_status;
};

export function applyFiltersToURL({
  status,
  params,
}: ApplyFiltersToURL): URLSearchParams {
  if (status) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: status,
      key: "status",
    });
  }

  return params;
}

export function constructQuery({
  baseUrl,
  query,
  status,
  offset,
  perPage,
}: ConstructQuery) {
  const paramsWithOffset = new URLSearchParams(
    `offset=${offset}&limit=${perPage}`
  );
  if (query) paramsWithOffset.append("q", query);
  paramsWithOffset.append("order_by", "desc");
  return (
    baseUrl +
    applyFiltersToURL({
      status,
      params: paramsWithOffset,
    })
  );
}
