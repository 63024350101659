import type { ReactNode } from "react";
import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import { screenSize } from "../../theme";
import { DeleteButtonWithText, InvisibleButton } from "../Buttons/Buttons";
import { LinkIcon, WarningIcon } from "../Icons/Icons";
import { useTranslation } from "react-i18next";
import { SlideOut } from "../SlideOut/SlideOut";
import { LinkUnlistedProduct } from "../../pages/Buyer/RequestUnlistedProduct/LinkUnlistedProduct";
import { useAuthContext } from "../Auth";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import type { UUID } from "../../types/types";
import { Notifications } from "../Notifications/NotificationsContext";
import { useStoreState } from "../../util/util";
import Axios from "axios";
import { mutate } from "swr";

export const HeadingText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  white-space: nowrap;
`;

export const DetailsDark = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.xs};
  white-space: nowrap;
`;

export const DetailsLight = styled(DetailsDark)`
  color: ${({ theme }) => theme.secondaryTextColor};
`;

// TODO: ideally we wouldn't have to use negative margins for this.
export const FurthestRightButtonContainer = styled.div`
  margin-right: -6px;
  margin-left: 8px;
`;

type TransactionItemProps = {
  selectedBorder?: boolean;
};

const TransactionItemContainer = styled.div<TransactionItemProps>`
  background-color: ${({ theme }) => theme.primaryBG};
  border: solid
    ${({ selectedBorder, theme }) =>
      selectedBorder
        ? "2px " + theme.tertiaryBorder
        : "1px " + theme.secondaryBorder};
  margin: 0;
  padding: 20px 20px;
  min-height: 125px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const TopRowContainer = styled.div`
  display: grid;
  grid-template-columns: 2.6fr 2.2fr 0.5fr 0.5fr 0.5fr 0.5fr 2.6fr 2.6fr;
  grid-template-rows: 1fr;
  grid-template-areas: "top-left top-left top-left top-left top-left top-left top-right-col1 top-right-col2";
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
  margin-bottom: 20px;
  @media ${screenSize.medium} {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "top-left"
      "top-right-col1"
      "top-right-col2";
  }
`;

const TopRowContainerNoSeparatorLine = styled(TopRowContainer)`
  padding-bottom: 15px;
  border-bottom: 0px;
  margin-bottom: 15px;
`;

const BottomRowContainer = styled.div`
  display: grid;
  grid-template-columns: 2.6fr 2.2fr 0.5fr 0.5fr 0.5fr 0.5fr 2.6fr 2.6fr;
  grid-template-rows: 1fr;
  grid-template-areas: "bottom-left-col1 bottom-left-col2 bottom-right bottom-right bottom-right bottom-right bottom-right bottom-right";
  @media ${screenSize.medium} {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "bottom-left-col1"
      "bottom-left-col2"
      "bottom-right";
  }
`;

const TopLeftArea = styled.div`
  grid-area: top-left;
`;

const TopRightCol1 = styled.div`
  grid-area: top-right-col1;
`;

const TopRightCol2 = styled.div`
  grid-area: top-right-col2;
  justify-self: end;
`;

const BottomLeftCol1 = styled.div`
  grid-area: bottom-left-col1;
`;

const BottomLeftCol2 = styled.div`
  grid-area: bottom-left-col2;
`;

const BottomRight = styled.div`
  grid-area: bottom-right;
  justify-self: end;
  align-self: end;
`;

// In some cases we want the bottom right content to align to the top of its
// grid area, instead of the bottom (the default).
const BottomRightAlignTop = styled.div`
  grid-area: bottom-right;
`;

const UnlistedProductWarningContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -15px 0 10px;
`;
const UnlistedProductWarningMessage = styled.div`
  display: flex;
  align-items: center;
`;
/**
 * A card displaying an item in a transaction such as a sample request, quote,
 * or order. Used to show a list of these items e.g. on a transaction detail
 * page or in a cart.
 *
 * The props allow passing in content to be shown in different "slots" in
 * the structure. Here's the structure with typical content types:
 *
 *   +----------------------------------------------------------------------+
 *   | Top Left                     Top Right Col. 1       Top Right Col.2  |
 *   | product details              quantity details       price details    |
 *   | ____________________________________________________________________ |
 *   |                                                                      |
 *   | Bottom Left Col. 1    Bottom Left Col 2.                Bottom Right |
 *   | requested details     anything else                     buttons      |
 *   +----------------------------------------------------------------------+
 *
 * It has an optional horizontal line to separate the top and bottom rows.
 * The bottom right section is aligned to the bottom by default, but it is
 * possible to align it to the top by passing the content to the
 * bottomRightAlignTop prop instead.
 *
 * selectedBorder is used to denote that the user is currently acting on the
 * item, in cases where there are multiple items. This give the card a fat 2px
 * border according to the theme.
 */
export const TransactionItem = ({
  topLeft,
  topRightCol1,
  topRightCol2,
  bottomLeftCol1,
  bottomLeftCol2,
  bottomRight,
  bottomRightAlignTop,
  hideSeparatorLine,
  selectedBorder,
  requestId,
  itemId,
  requestType,
  productId,
  buyerId,
}: {
  topLeft: ReactNode;
  topRightCol1?: ReactNode;
  topRightCol2?: ReactNode;
  bottomLeftCol1?: ReactNode;
  bottomLeftCol2?: ReactNode;
  bottomRight?: ReactNode;
  bottomRightAlignTop?: ReactNode;
  hideSeparatorLine?: boolean;
  selectedBorder?: boolean;
  requestId?: UUID;
  itemId?: UUID;
  buyerId?: UUID;
  requestType?: "quote" | "sample" | "order";
  productId?: UUID;
}) => {
  const { t } = useTranslation();
  const [showSlideOut, setShowSlideOut] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { roleIsSellerAdmin, roleIsSellerStandard } = useAuthContext();
  const { storefront_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const TopRow = hideSeparatorLine
    ? TopRowContainerNoSeparatorLine
    : TopRowContainer;

  const transactionType =
    requestType === "quote"
      ? "quotes"
      : requestType === "sample"
      ? "sample-requests"
      : null;
  const handleDeleteItem = async () => {
    try {
      await Axios.delete(
        `/v1/storefronts/${storefront_id}/${transactionType}/${requestId}/items/${itemId}`
      );
      notifySuccess(t("Item successfully removed"));
      mutate(
        `/v1/storefronts/${storefront_id}/${transactionType}/${requestId}`
      );
      setShowConfirmDialog(false);
    } catch (error) {
      notifyError(t("An error occurred"), { error });
    }
  };
  return (
    <TransactionItemContainer selectedBorder={selectedBorder}>
      {(roleIsSellerAdmin || roleIsSellerStandard) && productId === null && (
        <UnlistedProductWarningContainer>
          <UnlistedProductWarningMessage>
            <WarningIcon height={18} />
            <span style={{ paddingLeft: "3px" }}>
              {t("This product is not listed on the storefront.")}
            </span>
          </UnlistedProductWarningMessage>
          <UnlistedProductWarningMessage>
            <InvisibleButton
              onClick={() => setShowSlideOut(true)}
              type="button"
            >
              <LinkIcon height={14} />
              {t("Link Product")}
            </InvisibleButton>
            <DeleteButtonWithText
              type="button"
              onClick={() => {
                setShowConfirmDialog(true);
              }}
              height={14}
              style={{ marginLeft: "10px", padding: 0, display: "flex" }}
            >
              <span style={{ fontSize: "13px" }}>{t("Remove")}</span>
            </DeleteButtonWithText>
          </UnlistedProductWarningMessage>
          <SlideOut
            closeFlyout={() => setShowSlideOut(false)}
            show={showSlideOut}
          >
            {requestType && buyerId && (
              <LinkUnlistedProduct
                handleCloseFlyout={() => setShowSlideOut(false)}
                requestId={requestId}
                itemId={itemId}
                requestType={requestType}
                buyerId={buyerId}
              />
            )}
          </SlideOut>
          <ConfirmDialog
            show={showConfirmDialog}
            closeDialog={() => setShowConfirmDialog(false)}
            confirmMessage={t("Are you sure you want to remove this item?")}
            handleConfirm={handleDeleteItem}
            handleCancel={() => {
              setShowConfirmDialog(false);
            }}
          />
        </UnlistedProductWarningContainer>
      )}
      <TopRow>
        <TopLeftArea>{topLeft}</TopLeftArea>
        {topRightCol1 && <TopRightCol1>{topRightCol1}</TopRightCol1>}
        {topRightCol2 && <TopRightCol2>{topRightCol2}</TopRightCol2>}
      </TopRow>
      <BottomRowContainer>
        {bottomLeftCol1 && <BottomLeftCol1>{bottomLeftCol1}</BottomLeftCol1>}
        {bottomLeftCol2 && <BottomLeftCol2>{bottomLeftCol2}</BottomLeftCol2>}
        {bottomRight && <BottomRight>{bottomRight}</BottomRight>}
        {bottomRightAlignTop && (
          <BottomRightAlignTop>{bottomRightAlignTop}</BottomRightAlignTop>
        )}
      </BottomRowContainer>
    </TransactionItemContainer>
  );
};
