import React, {
  Children,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { HamburgerIcon, XIcon } from "../components/Icons/Icons";
import ReactDOM from "react-dom";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../components/Auth";
import { useRoutePath } from "./Routing";
import { useMediaQueries, useStoreState } from "./util";

import type { ReactElement, ReactNode } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  InvisibleButton,
  PrimaryButtonFitContainer,
} from "../components/Buttons/Buttons";
import { screenSize } from "../theme";
import { useCookies } from "react-cookie";
import { Store } from "../Store";
import type { IStore } from "../Store";
import { t } from "i18next";

interface IHamburgerWrapperProps {
  isOpen: boolean;
}

interface ITopNavProps {
  isMediumScreen: boolean;
  isMenuVisible: boolean;
}

interface ITopNavItemProps {
  isVisible: boolean;
}

interface IDropDownMenu {
  menuX?: number;
  menuY?: number;
}

const TopNavWrapper = styled.div<ITopNavProps>`
  display: ${({ isMediumScreen }) => (isMediumScreen ? "none" : "flex")};
  overflow: hidden;
  width: 100%;
  min-width: 40px;
  margin: 3px 10px 3px;
  position: relative;
  justify-content: ${({ isMenuVisible }) =>
    isMenuVisible ? "left" : "center"};
  opacity: ${({ isMediumScreen }) => (isMediumScreen ? 0 : 1)};
  & > div > div {
    height: 100%;
  }
`;

const HamburgerWrapper = styled.div<IHamburgerWrapperProps>`
  order: 99;
  left: 100%;
  background-color: white;
  position: sticky;
  padding: ${({ isOpen }) => (isOpen ? "4px 0 2px" : "6px 2px 2px")};
  border: ${({ isOpen, theme }) =>
    isOpen ? `1px solid ${theme.primaryBorder}` : `1px solid transparent`};
  border-radius: 4px;
  margin-right: 15px;
  align-self: center;
  @media ${screenSize.medium} {
    margin-right: 5px;
  }
`;

const GuestUserWrapper = styled.div`
  display: flex;
  padding: 15px 0 10px;
  flex-direction: column;
  justify-content: space-evenly;
`;
const TopNavItem = styled.div<ITopNavItemProps>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;
const DropDown = styled.div<IDropDownMenu>`
  position: absolute;
  top: 50px;
  right: ${({ menuX }) => `${menuX}px` ?? "255px"};
  min-width: 180px;
  padding: 5px 15px;
  border: 1px solid #e7e8ea;
  background: #fff;
  box-shadow: 0px 6px 16px -6px rgba(0, 0, 0, 0.44);
  border-radius: 5px;
  z-index: 1;
  @media (max-width: 580px) {
    right: 0;
    left: 0;
  }
  button {
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 0;
    border: 1px solid #fff;
    cursor: pointer;
    padding: 10px 0;
    text-align: left;
    &:hover {
      background: #f5f7f8;
    }
  }
  a {
    padding: 8px 0 6px;
    border-bottom: 4px solid transparent;
  }
  a:hover {
    background: ${({ theme }) => `${theme.primaryBG}`};
  }
  a.active {
    border-right: 0;
    border-bottom: ${({ theme }) => `4px solid ${theme.brandColor}`};
    background: ${({ theme }) => `${theme.primaryBG}`};
  }
`;

const LoginButton = styled(Link)`
  background: ${({ theme }) => theme.secondaryBG};
  text-align: center;
  padding: 10px 20px;
  border-radius: 3px;
  margin: 10px 0 0;
  text-decoration: none;
  text-wrap: nowrap;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.primaryTextColor};
  @media ${screenSize.small} {
    padding: 10px;
  }
`;

const RegisterButton = styled(PrimaryButtonFitContainer)`
  text-align: center;
  border: 2px solid ${(props) => props.theme.selectedBorder} !important;
  border-radius: 4px;
  font-weight: ${(props) => props.theme.fontWeights.medium};
  padding: 10px 20px;
  box-shadow: 0px 0px 8px #00000040;
  text-decoration: none;
  @media ${screenSize.small} {
    padding: 5px;
  }
`;
export function IntersectionObserverWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const { storeState } = useContext<IStore>(Store);
  const [cookies] = useCookies([`preferred-language-${storeState.slug}`]);
  const preferred: string | undefined =
    cookies[`preferred-language-${storeState.slug}`];
  const navRef = useRef<HTMLDivElement>(null);
  const { isMediumScreen } = useMediaQueries();
  const {
    storefront_metadata: { custom_pages, route_configuration },
  } = useStoreState();
  const [visibilityMap, setVisibilityMap] = useState<{
    [key: string]: boolean;
  }>({});

  const orderedChildren = () => {
    const childrenArray = Children.toArray(children);

    if (
      (custom_pages && custom_pages?.length > 0) ||
      route_configuration?.length > 0
    ) {
      const orderMap = new Map<string, string>();

      custom_pages
        ?.sort((a, b) => a.priority - b.priority)
        .filter((e) => e.nav_link_text !== "")
        .forEach((e) => {
          const name =
            e.page_content.find((content) => content.language === preferred)
              ?.nav_link_text || t([e.nav_link_text as string]);
          orderMap.set(name?.toLowerCase() as string, name as string);
        });

      route_configuration
        ?.filter((r) => r.enabled)
        .forEach((r) => {
          const name = r.translations[preferred ?? "en"] || r.route;
          orderMap.set(r.route.toLowerCase(), name);
        });

      const orderList = new Set(orderMap.values());

      const orderChildren = [...orderList]
        .map((itemName) =>
          childrenArray.find(
            (item) => (item as ReactElement)?.props.name === itemName
          )
        )
        .filter(Boolean);

      return orderChildren;
    } else {
      return childrenArray;
    }
  };

  const handleIntersection = useCallback((entries: any[]) => {
    const updatedEntries: { [key: string]: boolean } = {};
    entries.forEach((entry) => {
      const targetid: string | undefined = entry?.target.dataset.targetid;
      // Check if element is visibile within container
      if (targetid) {
        if (entry.isIntersecting) {
          updatedEntries[targetid] = true;
        } else {
          updatedEntries[targetid] = false;
        }
      }
    });
    setVisibilityMap((prev) => ({
      ...prev,
      ...updatedEntries,
    }));
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: navRef.current,
      threshold: 0.85,
    });

    if (navRef.current) {
      Array.from(navRef.current.children).forEach((item) => {
        observer.observe(item);
      });
      return () => {
        setVisibilityMap({});
        observer.disconnect();
      };
    }
  }, [handleIntersection, children]);

  return (
    <>
      <TopNavWrapper
        ref={navRef}
        isMediumScreen={isMediumScreen}
        isMenuVisible={Object.values(visibilityMap).some((v) => v === false)}
      >
        {React.Children.map(orderedChildren(), (child) => {
          return (
            <TopNavItem
              data-targetid={(child as React.ReactElement)?.props["name"]}
              isVisible={
                visibilityMap[(child as React.ReactElement)?.props["name"]] ===
                true
              }
            >
              {child}
            </TopNavItem>
          );
        })}
      </TopNavWrapper>
      <OverflowMenu visibilityMap={visibilityMap}>
        {orderedChildren()}
      </OverflowMenu>
    </>
  );
}

function OverflowMenu({
  children,
  visibilityMap,
}: {
  children: ReactNode;
  visibilityMap: { [key: string]: boolean };
}) {
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const { storefront_metadata } = useStoreState();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { accountPath, storePath } = useRoutePath();
  const { isMediumScreen } = useMediaQueries();
  const theme = useContext(ThemeContext);
  const { route_configuration } = storefront_metadata;
  const registrationRoute = route_configuration.find(
    (route) => route.route === "registration"
  );
  const open = Boolean(anchorEl);
  const [menuX, setMenuX] = useState<number>();

  const location = useLocation();
  const { tenantSlug } = useParams<{ tenantSlug: string }>();

  const isProductDetailPage = (name: string) => {
    return (
      name === t("Portfolio") &&
      (location.pathname.indexOf(`${accountPath}/product/`) === 0 ||
        location.pathname.indexOf(`${storePath}/product/`) === 0)
    );
  };
  const isActive = (name: string, path: string) => {
    const tenantPath = path.replace(/:tenantSlug/, tenantSlug);
    return (
      isProductDetailPage(name) ||
      (location.pathname === tenantPath &&
        !isProductDetailPage(name) &&
        tenantPath !== storePath)
    );
  };

  const handleClick = (event: React.MouseEvent) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
      setMenuX(
        window.innerWidth -
          event.currentTarget.getBoundingClientRect().right -
          5
      );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (open && menuRef.current && !menuRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [open]);

  const shouldShowMenu = useMemo(
    () =>
      isMediumScreen || Object.values(visibilityMap).some((v) => v === false),
    [visibilityMap, isMediumScreen]
  );

  useEffect(() => {
    setMenuX(
      buttonRef.current?.getBoundingClientRect().right &&
        menuRef.current?.getBoundingClientRect().width
        ? window.innerWidth - buttonRef.current?.getBoundingClientRect().right
        : 250
    );
  }, [buttonRef, menuRef, shouldShowMenu]);

  useEffect(() => {
    window.addEventListener("resize", () =>
      setMenuX(
        buttonRef.current?.getBoundingClientRect().right &&
          menuRef.current?.getBoundingClientRect().width
          ? window.innerWidth - buttonRef.current?.getBoundingClientRect().right
          : 250
      )
    );
  }, []);

  const checkIsActive: () => boolean[] = () => {
    return React.Children.map(children as ReactElement, (child) => {
      if (
        visibilityMap[child?.props["name"]] === false &&
        isActive(child?.props.name, child.props.path)
      ) {
        return true;
      }
      return null;
    });
  };

  if (!shouldShowMenu) {
    return null;
  }
  return (
    <>
      <HamburgerWrapper ref={buttonRef} isOpen={open}>
        <InvisibleButton aria-label={t("more")} onClick={handleClick}>
          {open ? (
            <XIcon width={22} height={22} />
          ) : (
            <HamburgerIcon
              width={22}
              height={22}
              fill={
                checkIsActive().includes(true) ? theme.brandColor : undefined
              }
            />
          )}
        </InvisibleButton>
      </HamburgerWrapper>
      {open &&
        ReactDOM.createPortal(
          <DropDown ref={menuRef} menuX={menuX}>
            <>
              {React.Children.map(children as ReactElement, (child) => {
                if (visibilityMap[child?.props["name"]] === false) {
                  return (
                    <div onClick={handleClose}>{React.cloneElement(child)}</div>
                  );
                }
                return null;
              })}

              {!user && !registrationRoute?.enabled && isMediumScreen && (
                <GuestUserWrapper>
                  <LoginButton to={`${storePath}/login`}>
                    {t("Sign In")}
                  </LoginButton>
                </GuestUserWrapper>
              )}
              {!user && registrationRoute?.enabled && isMediumScreen && (
                <GuestUserWrapper>
                  <RegisterButton as="a" href={`${storePath}/register`}>
                    {t("Register")}
                  </RegisterButton>
                  <LoginButton to={`${storePath}/login`}>
                    {t("Sign In")}
                  </LoginButton>
                </GuestUserWrapper>
              )}
            </>
          </DropDown>,
          document.body
        )}
    </>
  );
}
