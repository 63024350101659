import type {
  CurrencyCode,
  ProductSKU,
  SkuNoPreference,
} from "../../types/types";
import { formatPrice } from "../../util/util-components";
import type { InlineDisplayCustomSKU } from "../../pages/Seller/SellerCreateOrder/AddOrEditProductForm";
import type { CustomSkuData } from "../../pages/SharedPages/SellerQuoteDetailPage/AddCustomSkuForm";

/**
 * Takes SKU data and returns a string/label for that SKU. For example:
 * "Rail Car (50 MT)"
 */
export const makeSkuLabel = (
  sku?: ProductSKU | SkuNoPreference | CustomSkuData | InlineDisplayCustomSKU
): string => {
  if (sku) {
    const name = sku.packaging_type?.name;
    const quantity = sku.package_volume;
    const unitName = sku.packaging_unit?.name;
    return `${name || "--"} (${quantity || "--"} ${unitName || "--"})`;
  }
  return "--";
};

/**
 * Returns a string/label for a number of units.
 */
export const makeNumberOfUnitsLabel = (
  numberOfUnits: string | null,
  t: (s: string) => string
) => {
  return numberOfUnits
    ? `${numberOfUnits} ${numberOfUnits === "1" ? t("unit") : t("units")}`
    : "--";
};

/**
 * Renders the typical "price per unit" string shown in a TransactionItem.
 * For example: "$20/LB"
 */
export const makePricePerUnitString = (
  pricePerUnit: string | null | undefined,
  currencyCode: CurrencyCode,
  uomName?: string
) => {
  /* TODO: designs show $0.123 (more than two decimal places) */
  return `${pricePerUnit ? formatPrice(pricePerUnit, currencyCode) : "--"}/${
    uomName || "UoM"
  }`;
};
