import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card } from "../../../../../layout/portalPageLayout";
import type { DefaultTheme } from "styled-components";
import styled, { ThemeContext } from "styled-components";
import { SoftHeader2 } from "../../../../../components/Typography/Typography";
import { Logo } from "../../../../../layout/portalLayout";
import type { IEmailNotificationTemplate } from "../../../../../types/types";
import { useStoreState } from "../../../../../util/util";

const TemplatePreview = styled(Card)`
  max-height: 100%;
  overflow: auto;
  margin: 20px 0 15px;
`;
const EmailWrapper = styled(Card)`
  background: #fff;
  padding: 20px 15px;
  margin: 10px 0;
`;

const LogoWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
`;

const CustomSoftHeader2 = styled(SoftHeader2)`
  margin-bottom: 5px;
`;

const Hr = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-bottom: 0;
  margin: 20px 0;
`;

export const NotificationTemplatePreviewer = ({
  data,
}: {
  data?: IEmailNotificationTemplate;
}) => {
  const defaultTemplate = data?.template;
  const theme: DefaultTheme = useContext(ThemeContext);
  const { api_metadata } = useStoreState();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const handleContainerRef = useCallback(
    (container: HTMLDivElement | null): void => {
      setContainer(container);
    },
    []
  );

  useEffect(() => {
    if (!defaultTemplate || !container) {
      return;
    }
    const customHeader: HTMLElement | null =
      container.querySelector("#custom_header_id");
    if (customHeader && data?.header !== undefined) {
      customHeader.innerHTML = `<div class="ql-editor">${data.header}</div>`;
    }
    const bodyContent: HTMLElement | null =
      container.querySelector("#email_body_id");
    if (bodyContent && data?.content !== undefined) {
      bodyContent.innerHTML = `<div class="ql-editor">${data.content}</div>`;
    }
  }, [container, defaultTemplate, data]);

  return (
    <TemplatePreview>
      {data && (
        <EmailWrapper>
          {api_metadata?.default_from_email && (
            <CustomSoftHeader2>
              From: {api_metadata.default_from_email}
            </CustomSoftHeader2>
          )}
          {data.recipients && (
            <CustomSoftHeader2>To: {data.recipients}</CustomSoftHeader2>
          )}
          {data?.subject !== undefined && (
            <CustomSoftHeader2>Subject: {data.subject}</CustomSoftHeader2>
          )}
          <Hr />
          <LogoWrapper>
            {theme.publicLogoUrl && <Logo src={theme.publicLogoUrl} />}
          </LogoWrapper>
          {defaultTemplate && (
            <div
              dangerouslySetInnerHTML={{ __html: defaultTemplate }}
              ref={handleContainerRef}
            ></div>
          )}
        </EmailWrapper>
      )}
      {!data && "Nothing to Preview!"}
    </TemplatePreview>
  );
};
