import { useTranslation } from "react-i18next";
import { H4Normal } from "../../../../../components/Typography/Typography";
import {
  Flex,
  Flex1,
  Flex2,
  RadioButtonContainer,
} from "../../../../../layout/FormLayout";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { getPhoneCodesOptions } from "../../../../../util/phone";
import type {
  MethodsOfUseForm,
  OptionType,
  User,
} from "../../../../../types/types";
import { SearchSelectInfiniteScroll } from "../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { convertUserToOption, useStoreState } from "../../../../../util/util";
import { endpoints } from "../../../../../endpoints";

export const PointOfContact = ({
  methodsOfUseForm,
  pointOfContactType: extPointOfContactType,
  setPointOfContactType: extSetPointOfContactType,
  defaultExistingUser,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  pointOfContactType: "manual" | "existing";
  defaultExistingUser: User;
  setPointOfContactType: Dispatch<SetStateAction<"manual" | "existing">>;
}) => {
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const [pointOfContactType, setPointOfContactType] = useState<
    "manual" | "existing"
  >(extPointOfContactType ?? "manual");

  const [selectedUser, setSelectedUser] = useState<OptionType<string>>();

  const { control, errors, formState, register, watch } = methodsOfUseForm;

  const pointOfContactValue = watch("point_of_contact_id");

  const changePointOfContact = (e: React.FormEvent<HTMLSelectElement>) => {
    const pocType = e.currentTarget.value as "manual" | "existing";
    if (e.currentTarget.value) {
      setPointOfContactType(pocType);
      extSetPointOfContactType(pocType);
    }
  };

  useEffect(() => {
    if (extPointOfContactType) {
      setPointOfContactType(extPointOfContactType);
    }
  }, [extPointOfContactType]);

  useEffect(() => {
    if (pointOfContactValue) {
      setSelectedUser(pointOfContactValue);
    }
  }, [pointOfContactValue]);

  return (
    <>
      <H4Normal style={{ marginTop: "35px" }}>{t("Point of Contact")}</H4Normal>
      <RadioButtonContainer>
        <RadioButton
          name={"existingUser"}
          value="existing"
          checked={pointOfContactType === "existing"}
          optionTitle="Existing User"
          handleChange={changePointOfContact}
        />
        <RadioButton
          name={"manualUser"}
          value="manual"
          checked={pointOfContactType === "manual"}
          optionTitle="Add Manually"
          handleChange={changePointOfContact}
        />
      </RadioButtonContainer>
      {pointOfContactType === "existing" && (
        <Controller
          as={SearchSelectInfiniteScroll}
          isClearable
          control={control}
          name="point_of_contact_id"
          autoComplete="point_of_contact_id"
          baseUrl={
            storefront_id
              ? endpoints.v1_storefronts_id_tenants_id_users(
                  storefront_id,
                  defaultExistingUser.tenant_id
                )
              : null
          }
          params={(() => {
            const params = new URLSearchParams();
            params.append("offset", "0");
            params.append("order_by", "asc");
            return params;
          })()}
          placeholder={t("Select User")}
          getOptions={(response: User[]) =>
            response.reduce(
              (prev: { label: string; value: string }[], user) =>
                user.is_active ? [...prev, convertUserToOption(user)] : prev,
              []
            )
          }
          testid="point_of_contact_id"
          defaultOptions
          defaultValue={
            selectedUser
              ? selectedUser
              : convertUserToOption(defaultExistingUser)
          }
          errors={errors}
          formState={formState}
        />
      )}
      {pointOfContactType === "manual" && (
        <>
          <TextField
            name="contact_first_name"
            label={t("First Name")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="contact_last_name"
            label={t("Last Name")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="email_address"
            autoComplete="email"
            label={t("Email")}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="email"
          />
          <Flex>
            <Flex1>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="country_code"
                autoComplete="countryCode"
                placeholder={t("Country Code")}
                id="countryCodeSelectBox"
                options={getPhoneCodesOptions()}
                rules={{
                  required: true,
                }}
                errors={errors}
                formState={formState}
              />
            </Flex1>
            <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
              <TextField
                name="phone_number"
                autoComplete="tel"
                label={t("Phone Number")}
                theref={register({
                  required: true,
                })}
                formState={formState}
                errors={errors}
                type="tel"
              />
            </Flex2>
          </Flex>
        </>
      )}
    </>
  );
};
