import { CartOutlineIcon } from "../Icons/Icons";
import React, { useContext } from "react";
import styled from "styled-components/macro";
import useSWR from "swr";
import type { Lead } from "./cartUtils";
import { CartContext } from "./CartContext";
import { useHistory, useLocation } from "react-router-dom";
import { Store } from "../../Store";
import { screenSize } from "../../theme";
import type {
  SampleRequestPaginatedOutput,
  SampleRequestStageOne,
  StorefrontUnifiedCart,
} from "../../types/types";
import type { AxiosError } from "axios";
import { Auth } from "../Auth";
import { useRoutePath } from "../../util/Routing";
import { InvisibleButton } from "../Buttons/Buttons";
import { makeUrlWithParams, useStoreState } from "../../util/util";
import { endpoints } from "../../endpoints";
import { useTranslation } from "react-i18next";

interface ICartWidgetContainer {
  filled: boolean;
}

const Wrapper = styled.div`
  margin-right: 6px;
  @media ${screenSize.medium} {
    margin-right: 0px;
    margin-left: 2px;
  }
  ${InvisibleButton} {
    background: none;
    border: 0 !important;
    border-radius: 0 !important;
  }
`;

const CartWidgetContainer = styled.div<ICartWidgetContainer>`
  padding: 5px 14px 5px 10px;
  border-radius: 4px;
  border: 1px solid
    ${({ theme, filled }) =>
      filled ? theme.primaryButtonBorder : theme.primaryBorder};
  background-color: ${({ theme, filled }) =>
    filled ? theme.primaryButtonActive : theme.primaryBG};
  display: flex;
  align-items: center;
  min-width: 68px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -0.07px;
  color: ${({ theme }) => theme.primaryTextColor};
  @media ${screenSize.medium} {
    padding: 3px;
    width: auto;
    min-width: 52px;
  }

  & > div {
    margin-left: 10px;
    white-space: nowrap;
    @media ${screenSize.medium} {
      margin-left: 5px;
    }
  }
`;

const CartWidgetButton = styled(InvisibleButton)`
  padding: 0;
  text-decoration: none;
`;

/**
 *
 * The widget that shows how many items are in the cart.  Clicking it opens the
 * cart detail page.  It works for both guests (logged out) and logged in
 * buyers. For logged in buyers the widget reflects either the count of the
 * items in the unified cart or the sample request cart but not both at the same
 * time.
 *
 * The product requirements say it should be impossible to have a quote and
 * order cart at the same time, but I have tested this and it is. Unless
 * this is fixed it comes to down to the frontend to prevent "impossible"
 * states. Right now the count of the items in the quote cart "wins", but
 * once all existing quote carts have been submitted or cancelled it should
 * no longer be possible that both quote and order endpoints return results.
 */
export function CartWidget() {
  const { cartId, sampleCartID } = useContext(CartContext);
  const location = useLocation();
  const { storeDispatch } = useContext(Store);
  const history = useHistory();
  const { storePath } = useRoutePath();
  const { roleIsGuest, roleIsSomeKindOfBuyer } = useContext(Auth);
  const { storefront_id } = useStoreState();
  const { t } = useTranslation();

  // For guest user (logged out) quote request cart.
  const { data: lead, error: leadError } = useSWR<Lead, AxiosError>(
    roleIsGuest && cartId ? `/v1/cart/${cartId}` : null
  );

  // handle the unified cart, this is inclusive of what used to be "quote" and
  // "reorder" cart.
  const { data: unifiedCart, error: unifiedCartError } = useSWR<
    StorefrontUnifiedCart,
    AxiosError
  >(
    roleIsSomeKindOfBuyer
      ? `/v1/storefronts/${storefront_id}/unified-cart`
      : null
  );

  // For guest user (logged out) sample request cart.
  const { data: guestSampleRequest } = useSWR<
    SampleRequestStageOne,
    AxiosError
  >(
    sampleCartID
      ? `/v1/storefronts/${storefront_id}/sample-requests/${sampleCartID}`
      : null
  );

  // For logged in buyer user sample request cart.
  const { data: buyerSampleRequestResponse } =
    useSWR<SampleRequestPaginatedOutput>(
      roleIsSomeKindOfBuyer
        ? makeUrlWithParams(
            endpoints.v1_storefronts_id_sampleRequests(storefront_id),
            {
              source: "logged_in",
              status: "new",
              limit: "1",
            }
          )
        : null
    );

  const buyerSampleRequest = buyerSampleRequestResponse?.data[0];

  const isLoading = roleIsSomeKindOfBuyer
    ? !unifiedCart && !unifiedCartError
    : cartId && !lead && !leadError;

  const cartItemsCount = (() => {
    if (roleIsGuest) {
      if (guestSampleRequest) {
        return guestSampleRequest.items.length;
      } else if (lead) {
        return lead.items.length;
      }
    } else if (roleIsSomeKindOfBuyer) {
      // We have to check for items.length because both unifiedCart and
      // buyerSampleRequest may be defined, but only one should have items at
      // a given time.
      if (unifiedCart?.quote?.items.length) {
        return unifiedCart?.quote?.items.length;
      } else if (buyerSampleRequest?.items.length) {
        return buyerSampleRequest.items.length;
      } else if (unifiedCart?.order_cart?.items.length) {
        return unifiedCart?.order_cart?.items.length;
      }
    }
    return 0;
  })();

  let text = t("No Items");
  if (leadError || unifiedCartError) {
    // TODO: better indication of error and loading state
    // TODO: xx might not a placeholder value in other languages.
    // TODO: potentially a use case for i18next pluralization instead of storing `Items` and `Item`.
    text = `xx`;
  } else if (isLoading) {
    text = `--`;
  } else {
    text = `${cartItemsCount}`;
  }

  const widget = (
    <CartWidgetContainer filled={cartItemsCount > 0}>
      <CartOutlineIcon />
      <div>{text}</div>
    </CartWidgetContainer>
  );

  const path = location.pathname.split("/");
  const alreadyOnCartPage = path[path.length - 1] === "cart";

  if (
    cartItemsCount === 0 ||
    cartItemsCount === undefined ||
    alreadyOnCartPage
  ) {
    // distinct test-id to get items in cart when the widget is not clickable
    return <Wrapper data-testid="cart-widget">{widget}</Wrapper>;
  }

  const onClick = (e: React.MouseEvent) => {
    // This function seems to be actually be necessary, I tried replacing it
    // with an a tag and it didn't work. Probably due to some redirect logic
    // on the portfolio page.
    e.preventDefault();
    const payload = `${location.pathname}${location.search}${location.hash}`;
    storeDispatch({ type: "SET_STORED_LOCATION", payload });

    const cartPageUrl = `${storePath}/cart`;
    history.push(cartPageUrl);

    // Returning false cancels the href navigation.
    return false;
  };

  return (
    <Wrapper>
      <CartWidgetButton onClick={onClick} data-testid="cart-widget-button">
        {widget}
      </CartWidgetButton>
    </Wrapper>
  );
}
