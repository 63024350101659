import React, { useState, useContext } from "react";
import type { FieldErrors, FormStateProxy } from "react-hook-form";
import styled, { ThemeContext } from "styled-components/macro";
import { showErrors } from "../../util/util-components";
import { useTranslation } from "react-i18next";

interface ITextAreaProps {
  label: string;
  name: string;
  theref: any;
  formState: FormStateProxy<Record<string, any>>;
  watch: any;
  errors: FieldErrors<any>;
}

interface IInputProps {
  invalid?: any;
  theme?: any;
}

const Input = styled.textarea.attrs({})<IInputProps>`
  box-sizing: border-box;
  height: 120px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  background-color: ${({ theme }) => theme.primaryBG};
  padding: 21px 13px 6px 12px;
  font-size: 14px;
  border: ${({ invalid, theme }: IInputProps) =>
    (invalid && `2px solid ${theme.errorColor}`) ||
    `1px solid ${theme.primaryBorder}`};
  &:focus {
    border: ${({ invalid, theme }: IInputProps) =>
      (invalid && `2px solid ${theme.errorColor}`) ||
      `2px solid ${theme.tertiaryBorder}`};
    outline: none;
  }
  resize: none;
`;

const FloatingLabel = styled.span<IInputProps>`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${(props: IInputProps) =>
    props.invalid ? props.theme.errorColor : props.theme.secondaryTextColor};
  position: absolute;
  pointer-events: none;
  left: 13px;
  top: 14px;
  transition: 0.2s ease all;
`;

const Counter = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.secondaryTextColor};
  top: 14px;
  /* left: 405px; */
  right: 13px;
  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
`;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
  /* prettier-ignore */
  & ${Input}:focus + ${FloatingLabel}, & ${Input}:not(:focus):valid + ${FloatingLabel} {
    outline: none;
    top: 7px;
    bottom: 10px;
    left: 13px;
    font-size: 11px;
    opacity: 1;
  }
  & ${Input}:focus ~ ${Counter}, & ${Input}:not(:focus):valid ~ ${Counter} {
    outline: none;
    top: 7px;
    right: 13px;
    font-size: 11px;
    opacity: 1;
  }
`;

const TextAreaWrapper = styled.div`
  .errorMessageWrapper {
    top: -8px !important;
    position: relative !important;
  }
`;

export const TextAreaCounter = ({
  label,
  name,
  theref,
  formState,
  watch,
  errors,
}: ITextAreaProps) => {
  const text = watch(name);
  const [charCount, setCharCount] = useState(text?.length ?? 0);
  // TODO: fix this type check error from storybook migration.
  // @ts-ignore
  const { submitCount } = formState;
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  function getRemainingCharacters() {
    setCharCount(text.length);
  }

  // eslint-disable-next-line no-mixed-operators
  if ((submitCount > 0 && errors[name]) || charCount > 200) {
    return (
      <TextAreaWrapper>
        <Wrapper>
          <Input
            onChange={getRemainingCharacters}
            ref={theref}
            required
            name={name}
            invalid
          ></Input>
          <FloatingLabel invalid>{label}</FloatingLabel>
          <Counter
            style={{
              color:
                charCount <= 200 ? theme.secondaryTextColor : theme.errorColor,
            }}
          >{`${charCount}/200`}</Counter>
        </Wrapper>
        {errors && showErrors({ errors, name, t })}
      </TextAreaWrapper>
    );
  } else {
    return (
      <TextAreaWrapper>
        <Wrapper>
          <Input
            onChange={getRemainingCharacters}
            placeholder={" "}
            required
            ref={theref}
            name={name}
          ></Input>
          <FloatingLabel>{label}</FloatingLabel>
          <Counter
            style={{
              color:
                charCount <= 200 ? theme.secondaryTextColor : theme.errorColor,
            }}
          >{`${charCount}/200`}</Counter>
        </Wrapper>
        {errors && showErrors({ errors, name, t })}
      </TextAreaWrapper>
    );
  }
};
