import { Controller } from "react-hook-form";
import React, { useState } from "react";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import type { MethodsOfUseForm, OptionType } from "../../types/types";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";

/**
 * A select box that can optionally show a dialog when its value is changed.
 * THE `defaultOption` PROP DOES NOT WORK
 * TODO: make the `defaultOption` prop work.
 */
export const SelectBoxWithConfirmDialog = ({
  methodsOfUseForm,
  name,
  placeholder,
  options,
  showDialogOnChange,
  confirmMessage,
  defaultOption,
  setDefaultTerm = false,
  handleConfirm,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  name: string;
  placeholder: string;
  options: OptionType[];
  showDialogOnChange: boolean;
  confirmMessage: string;
  defaultOption?: OptionType;
  setDefaultTerm?: boolean;
  handleConfirm?: (selection?: OptionType<unknown>) => void;
}) => {
  const { control, errors, formState, setValue } = methodsOfUseForm;

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentSelection, setCurrentSelection] =
    useState<OptionType<unknown>>();
  const [pendingSelection, setPendingSelection] =
    useState<OptionType<unknown>>();

  return (
    <>
      {/* We could probably just use a SelectBoxV2 here instead of Controller
        because we are using the `value` prop of SelectBoxV2 and
        react-hook-form's `setValue` (and not a ref?). But it's nice to have
        an example of how to use `render` on Controller to get `onChange` to
        work. */}
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={(props) => (
          <SelectBoxV2
            name={props.name}
            placeholder={placeholder}
            options={options}
            errors={errors}
            formState={formState}
            // This value prop is needed so the UI stays in sync with
            // react-hook-form's state.
            value={currentSelection || defaultOption}
            onChange={(value: OptionType<unknown>) => {
              if (showDialogOnChange) {
                setPendingSelection(value);
                setShowConfirmDialog(true);
              } else {
                setCurrentSelection(value);
                // This props.onChange call is needed so that react-hook-form
                // gets the change.
                props.onChange(value);
              }
            }}
            setDefaultTerm={setDefaultTerm}
          />
        )}
      />
      <ConfirmDialog
        show={showConfirmDialog}
        confirmMessage={confirmMessage}
        closeDialog={() => {
          setValue(name, currentSelection);
          setShowConfirmDialog(false);
        }}
        handleConfirm={() => {
          setValue(name, pendingSelection);
          setCurrentSelection(pendingSelection);
          if (handleConfirm) {
            handleConfirm(pendingSelection);
          }
          setShowConfirmDialog(false);
        }}
      />
    </>
  );
};
