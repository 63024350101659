import styled from "styled-components";
import type { ITableProps, TableRefProps } from "./utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Table } from "./Table";
import type { Row } from "react-table";
import { useTranslation } from "react-i18next";
import { SoftHeader2 } from "../Typography/Typography";
import { TextButton } from "../Buttons/Buttons";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BulkActionWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
`;

const ResetBtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TableWithBulkAction = forwardRef<
  TableRefProps,
  ITableProps<any> & {
    bulkActionElement: <
      ElementProps extends {
        rows: any[] | "all";
        resetSelectedRows: () => void;
      }
    >(
      props: ElementProps
    ) => JSX.Element;
    pageIndex: number;
  }
>(
  <TableData extends object>(
    props: ITableProps<TableData> & {
      bulkActionElement: <
        ElementProps extends {
          rows: TableData[] | "all";
          resetSelectedRows: () => void;
        }
      >(
        props: ElementProps
      ) => JSX.Element;
      pageIndex: number;
    },
    ref: React.ForwardedRef<TableRefProps>
  ) => {
    const { handleSelectRows, bulkActionElement, pageIndex } = props;
    const [rowsSelected, setRowsSelected] = useState<Row<TableData>[] | "all">(
      []
    );
    const resetSelectedRowsRef = useRef<TableRefProps>(null);

    const { t } = useTranslation();

    const onSelectRows = (rows: Record<string, Row<TableData>[]> | "all") => {
      setRowsSelected(rows === "all" ? "all" : Object.values(rows).flat());
      if (handleSelectRows) {
        handleSelectRows(rows);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        resetSelectedRows: () =>
          resetSelectedRowsRef?.current?.resetSelectedRows(),
        selectAllItems: () => resetSelectedRowsRef?.current?.selectAllItems(),
      }),
      []
    );

    return (
      <TableWrapper>
        {rowsSelected.length > 0 && !!resetSelectedRowsRef.current ? (
          <BulkActionWrapper>
            <ResetBtnWrapper>
              <SoftHeader2>
                {rowsSelected === "all"
                  ? t("All items selected")
                  : `${rowsSelected.length} ${t("selected")}`}
              </SoftHeader2>
              <TextButton
                onClick={resetSelectedRowsRef.current.resetSelectedRows}
              >
                <SoftHeader2>{t("Unselect All")}</SoftHeader2>
              </TextButton>
              {rowsSelected !== "all" && (
                <TextButton
                  onClick={resetSelectedRowsRef.current.selectAllItems}
                >
                  <SoftHeader2>{t("Select All")}</SoftHeader2>
                </TextButton>
              )}
            </ResetBtnWrapper>
            {bulkActionElement({
              rows:
                rowsSelected === "all"
                  ? "all"
                  : rowsSelected.map((row) => row.original),
              resetSelectedRows: resetSelectedRowsRef.current.resetSelectedRows,
            })}
          </BulkActionWrapper>
        ) : (
          <></>
        )}
        <Table
          {...props}
          handleSelectRows={onSelectRows}
          ref={resetSelectedRowsRef}
          pageIndex={pageIndex}
        />
      </TableWrapper>
    );
  }
);
