import type { AxiosError } from "axios";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CancelButtonSmall,
  EditButton,
} from "../../../../../components/Buttons/Buttons";
import { Row } from "../../../../../components/Layout/Layout";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { H3, H6 } from "../../../../../components/Typography/Typography";
import { endpoints } from "../../../../../endpoints";
import { Flex } from "../../../../../layout/FormLayout";
import { SettingsCard } from "../../../../../layout/portalPageLayout";
import type { IStore } from "../../../../../Store";
import { Store } from "../../../../../Store";
import type { StorefrontMetaData } from "../../../../../types/types";
import { SaveButtonPrimaryMedium } from "../shared";

export const AutoInvoiceGeneration = ({
  storefrontMetadata,
  mutateMetadata,
}: {
  storefrontMetadata: StorefrontMetaData | undefined;
  mutateMetadata: () => Promise<void>;
}) => {
  const [editGenerateInvoice, setEditGenerateInvoice] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [autoGenerateInvoice, setAutoGenerateInvoice] = useState<boolean>(
    storefrontMetadata?.enable_invoice_auto_generation ?? false
  );
  const { t } = useTranslation();
  const { notifyError, notifySuccess } = useContext(Notifications);

  const {
    storeState: { storefront_id },
  } = useContext<IStore>(Store);

  const cancelEditGenerateInvoice = () => {
    setAutoGenerateInvoice(
      storefrontMetadata?.enable_invoice_auto_generation ?? false
    );
    setEditGenerateInvoice(false);
  };

  const handleAutoGenerateInvoice = async () => {
    setLoadingSave(true);
    const patchBody = {
      enable_invoice_auto_generation: autoGenerateInvoice,
    };
    try {
      await axios.patch(
        endpoints.v1_storefronts_id_metadata(storefront_id),
        patchBody
      );
      await mutateMetadata();
      setLoadingSave(false);
      setEditGenerateInvoice(false);
      notifySuccess(t("Changes saved successfully"));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not update settings. Please try again later."),
        {
          error,
        }
      );
    }
  };

  useEffect(() => {
    setAutoGenerateInvoice(
      storefrontMetadata?.enable_invoice_auto_generation ?? false
    );
  }, [storefrontMetadata?.enable_invoice_auto_generation]);

  return (
    <SettingsCard>
      <Row>
        <div>
          <H3 style={{ marginBottom: 0 }}>
            {t("Automatic invoice generation")}
          </H3>
        </div>
        {!editGenerateInvoice && (
          <EditButton
            testid={"edit-auto-generate-invoice"}
            onClick={() => setEditGenerateInvoice(true)}
            title={t("Edit Auto generate invoice")}
          />
        )}
        {editGenerateInvoice && (
          <CancelButtonSmall onClick={cancelEditGenerateInvoice} />
        )}
      </Row>
      <Flex style={{ flexDirection: "column", gap: "8px", marginTop: "20px" }}>
        <Flex style={{ gap: "8px" }}>
          <ToggleSwitch
            disabled={!editGenerateInvoice}
            isChecked={autoGenerateInvoice}
            onClick={() => setAutoGenerateInvoice((prev) => !prev)}
          />
          <H6>{t("Auto generate transaction invoice")}</H6>
        </Flex>
      </Flex>
      {editGenerateInvoice && (
        <SaveButtonPrimaryMedium
          style={{ width: "166px" }}
          type="submit"
          onClick={handleAutoGenerateInvoice}
          loading={loadingSave}
        >
          {t("Save your changes")}
        </SaveButtonPrimaryMedium>
      )}
    </SettingsCard>
  );
};
