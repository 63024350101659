/* Theme Provider for styled-components.
  the default export 'theme' is considered legacy but is here for backwards
  compatibility reasons. This is accessed in all packages using context.

*/
import type {} from "styled-components/cssprop";

declare module "styled-components" {
  export interface DefaultTheme {
    /**
     * Do not use this colors object in new code.
     * @deprecated
     */
    colors: { [key: string]: string };
    fontFamily: string;
    fontSizes: { [key: string]: string };
    fontWeights: { [key: string]: string };
    brandColor: string;
    //Background Colors
    primaryBG: string;
    secondaryBG: string;
    tertiaryBG: string;
    navBG: string;
    primaryButtonBG: string;
    primaryButtonHover: string;
    primaryButtonActive: string;
    secondaryButtonBG: string; // Also used for select option hover
    secondaryButtonHover: string;
    secondaryButtonActive: string;
    tertiaryButtonBG: string;
    tertiaryButtonSelected: string;
    destructiveButtonBG: string;
    destructiveButtonHover: string;
    destructiveButtonActive: string;
    disabledButtonBG: string;
    disabledInput: string;
    toggleBG: string;
    activeToggleBG: string;
    badgeColor: string;
    successIconColor: string;
    badgeTextColor: string;
    footerBg: string;
    secondaryInputBG: string;
    tooltipBG: string;
    //Text Colors
    primaryTextColor: string;
    secondaryTextColor: string;
    tertiaryTextColor: string;
    invertTextColor: string;
    warningTextColor: string;
    destructiveTextColor: string;
    //Link Text Colors
    navLinkColor: string;
    navIconActive: string;
    navLinkActiveColor: string;
    primaryLinkColor: string;
    primaryLinkHover: string;
    secondaryLinkColor: string;
    secondaryLinkHover: string;
    tertiaryLinkColor: string;
    tertiaryLinkHover: string;
    disabledLinkColor: string;
    //Button Text colors
    primaryButtonTextColor: string;
    secondaryButtonTextColor: string;
    tertiaryButtonTextColor: string;
    destructiveButtonTextColor: string;
    disabledButtonTextColor: string;
    // Icon colors
    primaryIconColor: string;
    secondaryIconColor: string;
    tertiaryIconColor: string;
    primaryIconBackground: string;
    //Borders
    primaryBorder: string;
    secondaryBorder: string;
    tertiaryBorder: string;
    primaryButtonBorder: string;
    secondaryButtonBorder: string;
    secondaryButtonHoverBorder: string;
    selectedBorder: string;
    //common
    errorColor: string;
    shadowColor: string;
    overlayColor: string;
    //Public
    publicNavBorderActive: string;
    publicNavBorder: string;
    publicLogoBGColor: string;
    publicLogoUrl: string;
    //Portal
    portalLogoUrl: string;
    theme_name: string;
  }
}

//MediaQuery breakpoints.
export const screenSize = {
  xl: `(min-width: 1025px)`,
  large: `(max-width: 1024px)`, //For iPad Pro...
  mLarge: `(max-width: 991px)`, //For iPad air...
  medium: `(max-width: 768px)`, //For regular iPad, Tablets...
  small: `(max-width: 425px)`, //For Mobile
};

const themeDefaults = {
  fontFamily: "Inter",
  fontWeights: {
    large: "600",
    medium: "500",
    regular: "400",
    small: "300",
  },
  fontSizes: {
    // Unless a font-size has a suffix of -medium or -small it is shown all screens.
    "xl-4": "60px",
    "xl-3": "48px",
    "xl-2": "36px",
    xl: "28px",
    "xl-small": "23px",
    large: "19px",
    medium: "15px",
    regular: "14px",
    small: "13px",
    xs: "11px",
  },
  /**
   * Do not use this colors object for new code.
   * @deprecated
   */
  colors: {
    white: "#fff",
    black: "#000",
    primaryBlue: "#0090e3", // The Primary blue color in the app.
    secondaryBlue: "#ecf8ff", // Secondary Blue
    darkBlue: "#0f1d2b",
    secondaryLightGray: "#cfd1d4",
    errorRedBorder: "#cb2020", // Used for border color on form fields in error state
    activeGreen: "#1fb45a", // used on toggle switches in the on position
    borderBlue: "#99d2f3",
    grayText: "#6f777f",
    darkText: "#0f1d2b",
    darkBorder: "#0f1d2b",
    mediumGreen: "#1ba150",
    statusTextGreen: "#00a545",
    burntOrange: "#d44800",
    moreBurntOrange: "#c45113",
    productStatusOrange: "#DB5B16",
    productStatusYellow: "#F6B200",
    "orange-3": "#83360d",
    "orange-4": "#f6b200",
    "red-1": "#c70009",
    "red-2": "#b61c1c",
    "red-3": "#791313",
    "gray-1": "#9fa4aa",
    "gray-2": "#6d7780",
    "gray-3": "#f5f7f8",
    "gray-4": "#cfd1d4",
    "green-3": "#126c36",
    "blue-3": "#0073b5",
    "blue-4": "#005688",
    "blue-5": "#d9effc",
    "blue-6": "#c8e8fa",
  },
};
export const AG3Branding = {
  brandColor: "#0090e3",
  portalLogoUrl: "",
  publicLogoUrl: "",
  favIconUrl: "",
  customFontFamily: "",
};

export const AG3Theme = {
  brandColor: "#0090e3",
  //Background Colors
  primaryBG: "#ffffff",
  secondaryBG: "#f5f7f8",
  tertiaryBG: "#0f1d2b",
  navBG: "#0090e330",
  primaryButtonBG: "#0090e303",
  primaryButtonBorder: "#0090e3",
  primaryButtonHover: "#0090e305",
  primaryButtonActive: "#005688",
  secondaryButtonBG: "#F5F7F8", // Also used for select option hover
  secondaryButtonHover: "#F5F7F8",
  secondaryButtonActive: "#F5F7F8",
  tertiaryButtonBG: "#ffffff",
  tertiaryButtonSelected: "#ffffff",
  destructiveButtonBG: "transparent",
  destructiveButtonHover: "transparent",
  destructiveButtonActive: "transparent",
  disabledButtonBG: "#f2f2f2",
  disabledInput: "#f2f2f2",
  toggleBG: "#cfd1d4",
  activeToggleBG: "#1fb45a",
  badgeColor: "#1fb45a",
  successIconColor: "#1fb45a",
  errorIconColor: "#cb2020",
  badgeTextColor: "#ffffff",
  footerBg: "#f5f7f8",
  secondaryInputBG: "#9fa4aa",
  tooltipBG: "#60676f",
  //Text Colors
  primaryTextColor: "#0f1d2b",
  secondaryTextColor: "#6f777f",
  tertiaryTextColor: "#0090e3",
  invertTextColor: "#ffffff",
  warningTextColor: "#d44800",
  destructiveTextColor: "#cb2020",
  //Link Text Colors
  navLinkColor: "#3f4a55",
  navIconActive: "#0090e3",
  navLinkActiveColor: "#ffffff",
  primaryLinkColor: "#0f1d2b",
  primaryLinkHover: "#0090e3",
  secondaryLinkColor: "#0f1d2b",
  secondaryLinkHover: "#0090e3",
  tertiaryLinkColor: "#0f1d2b",
  tertiaryLinkHover: "#0090e3",
  disabledLinkColor: "#0f1d2b50",
  //Button Text colors
  primaryButtonTextColor: "#0f1d2b",
  secondaryButtonTextColor: "#0f1d2b",
  tertiaryButtonTextColor: "#0f1d2b",
  destructiveButtonTextColor: "#cb2020",
  disabledButtonTextColor: "#6f777f",
  // Icon colors
  primaryIconColor: "#0f1d2b",
  secondaryIconColor: "#b6c7d4",
  tertiaryIconColor: "#9fa4aa",
  primaryIconBackground: "#eff4f8",
  //Borders
  primaryBorder: "#cfd1d4",
  secondaryBorder: "#e7e8ea",
  tertiaryBorder: "#0f1d2b",
  selectedBorder: "#0090e3",
  PrimaryButtonBorder: "#0090e3",
  secondaryButtonBorder: "#F5F7F8",
  secondaryButtonHoverBorder: "#E7E8EA",
  //common
  errorColor: "#cb2020",
  shadowColor: "#00000040",
  overlayColor: "#00395a95",
  //Public
  publicNavBorderActive: "#0090e3",
  publicNavBorder: "#e7e8ea",
  publicLogoBGColor: "#0090e3",
  portalLogoUrl: "",
  publicLogoUrl: "",
  theme_name: "AG3Theme",
  ...themeDefaults,
};

const theme = AG3Theme;

// eslint-disable-next-line import/no-default-export
export default theme;
