import type { SampleRequest, User } from "../../../types/types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { Auth } from "../../../components/Auth";
import { ErrorPlaceholder } from "../../../components/Error";
import { endpoints } from "../../../endpoints";
import { useInAppNotifications, useStoreState } from "../../../util/util";
import { SampleRequestDetailPageContent } from "./SampleRequestDetailPageContent";

/**
 * Sample request detail pages. Used for seller, seller admin and buyer user accounts.
 */
export const SampleRequestDetailPage = () => {
  const { sample_request_id } = useParams<{ sample_request_id: string }>();
  const { storefront_id } = useStoreState();
  const { t } = useTranslation();
  const { user } = useContext(Auth);
  const { mutateNotifications } = useInAppNotifications(
    storefront_id,
    user as User
  );
  const {
    data: sampleRequestResponse,
    error: sampleRequestError,
    mutate: mutateSampleRequest,
  } = useSWR<SampleRequest>(
    endpoints.v1_storefronts_id_sampleRequests_id(
      storefront_id,
      sample_request_id
    )
  );

  if (sampleRequestResponse) {
    mutateNotifications();
  }

  if (sampleRequestError) {
    return (
      <ErrorPlaceholder
        message={t("There was an error loading the sample request.")}
      />
    );
  }
  if (!sampleRequestResponse) {
    // loading
    return null;
  }
  return (
    <SampleRequestDetailPageContent
      sampleRequest={sampleRequestResponse}
      mutateSampleRequest={mutateSampleRequest}
    />
  );
};
