import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components/macro";
import { TrimmedName } from "../../pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import { screenSize } from "../../theme";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  SecondaryButtonSmall,
} from "../Buttons/Buttons";
import { LoadingIcon } from "../Icons/Icons";

const FileWrapper = styled.div`
  padding: 10px 15px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 4px;
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: 14px;
  background: ${({ theme }) => theme.primaryBG};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

/**
 * This is designed to look like `PDFIcon, but its contents are generated
 * dynamically to support all file types.
 */
export const FileType = styled.span<{ error?: boolean }>`
  display: flex;
  align-items: center;
  padding: 1px 4px 1px 6px;
  margin-right: 10px;
  font-size: 10px;
  color: ${({ theme }) => theme.primaryBG};
  background: ${({ theme, error }) =>
    error ? theme.errorColor : theme.badgeColor};
  text-transform: uppercase;
  border-radius: 1px;
`;

const FileName = styled.div<{ error?: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-content: center;
  color: ${({ theme, error }) =>
    error ? theme.errorColor : theme.primaryTextColor};

  @media ${screenSize.xl} {
    max-width: 400px;
  }
  @media ${screenSize.large} {
    max-width: 400px;
  }
  @media ${screenSize.small} {
    max-width: 300px;
  }
`;

const FileActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  min-width: 100px;
  & > * {
    margin-left: 15px;
    cursor: pointer;
  }
`;

const FileError = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: 10px;
`;

const ViewButton = styled(SecondaryButtonSmall)`
  /* Set the width so it does not change when the loading spinner appears. */
  width: 84px;
`;

const UploadingWrapper = styled.div`
  margin-left: 10px;
`;

export interface IFileCardFile {
  name: string;
  id?: string;
  kind?: string;
  isLoading?: boolean;
  errorMessage?: string;
}

/**
 * Displays a file, showing the file type, name, a view button and a delete
 * button. The deletionConfirmationString is used in the confirmation dialog
 * that is shown before the file is deleted.
 */
export const FileCard = ({
  file,
  viewFile,
  deleteProps,
}: {
  file: IFileCardFile;
  viewFile: (id: string) => void;
  deleteProps?: {
    deleteFile: (id: string) => void;
    confirmMessage: string;
  };
}) => {
  const theme = useContext(ThemeContext);
  return (
    <FileWrapper>
      <FileTypeAndName fileName={file.name} />
      <FileActions>
        {file.errorMessage ? (
          <FileError>{file.errorMessage}</FileError>
        ) : (
          <>
            <ViewButton
              onClick={() => file.id && viewFile(file.id)}
              loading={file.isLoading}
              // TODO: ideally the secondary button components would
              // automatically have the right loadingIconFill color.
              loadingIconFill={theme.secondaryButtonTextColor}
              type="button"
            >
              View
            </ViewButton>
            {deleteProps && (
              <ButtonWithConfirmDialog
                Button={DeleteButton}
                testid={`delete-shipping-document-${file.id}`}
                handleConfirm={() => file.id && deleteProps.deleteFile(file.id)}
                confirmMessage={deleteProps.confirmMessage}
              />
            )}
          </>
        )}
      </FileActions>
    </FileWrapper>
  );
};

/**
 * Represents a file as it is in the process of being uploaded.
 */
export const FileCardUploading = ({ fileName }: { fileName: string }) => {
  return (
    <FileWrapper>
      <FileTypeAndName fileName={fileName} />
      <LoadingIcon width={22} height={22} />
      <UploadingWrapper>Uploading...</UploadingWrapper>
    </FileWrapper>
  );
};

/**
 * Displays a file's type followed by its name.
 */
export const FileTypeAndName = ({
  fileName,
  hasError = false,
}: {
  fileName: string;
  hasError?: boolean;
}) => {
  return <FileName error={hasError}>{fileName}</FileName>;
};

/**
 * Displays only file name without file type.
 */
export const UploadedFileName = ({ fileName }: { fileName: string }) => {
  return (
    <FileName>
      <TrimmedName text={fileName} />
    </FileName>
  );
};
