import type {
  QuoteRequestItem,
  IQuoteRequestPriceTier,
  QuoteRequest,
} from "../../types/types";
import useSWR from "swr";
import { endpoints } from "../../endpoints";
import React, { useContext } from "react";
import { TextButton } from "../Buttons/Buttons";
import { Notifications } from "../Notifications/NotificationsContext";
import { TransactionItem } from "./TransactionItem";
import { TransactionItemTopLeft } from "./TransactionItemTopLeft";
import { TransactionItemInfoBlockRequestedDetails } from "./TransactionItemInfoBlockRequestedDetails";
import { TransactionItemInfoBlockQuantity } from "./TransactionItemInfoBlockQuantity";
import { TransactionItemInfoBlockPrice } from "./TransactionItemInfoBlockPrice";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../util/util";

/**
 * Quote item card to display items to the seller after they have responded to
 * a quote request.
 */
export const QuoteItemCardSellerComplete = ({
  quote,
  item,
  index,
  viewPriceTiers,
}: {
  quote: QuoteRequest;
  item: QuoteRequestItem;
  index: number;
  viewPriceTiers: (tiersData: IQuoteRequestPriceTier) => void;
}) => {
  const { t } = useTranslation();
  const { notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const { data: priceTiersData } = useSWR<IQuoteRequestPriceTier>(
    endpoints.v1_storefronts_id_or_slug_quotes_id_items_id_priceTiers(
      storefront_id,
      quote.id,
      item.id
    ),
    {
      onError: (error) => {
        notifyError(t("There was an error checking for price tiers"), {
          error,
        });
      },
    }
  );

  const bottomRight =
    priceTiersData && priceTiersData.price_tiers.length > 0 ? (
      <TextButton
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          viewPriceTiers(priceTiersData);
        }}
      >
        {t("View Price Tiers")}
      </TextButton>
    ) : null;

  return (
    <TransactionItem
      topLeft={<TransactionItemTopLeft item={item} index={index} />}
      topRightCol1={<TransactionItemInfoBlockQuantity item={item} t={t} />}
      topRightCol2={<TransactionItemInfoBlockPrice item={item} />}
      bottomLeftCol1={<TransactionItemInfoBlockRequestedDetails item={item} />}
      bottomRight={bottomRight}
      requestType="quote"
      requestId={quote.id}
      itemId={item.id}
      productId={item.product_id}
    />
  );
};
