import React, { useState } from "react";
import styled from "styled-components/macro";
import type { IImageGridData } from "../ImageGrid/ImageGrid";
import { ImageGrid } from "../ImageGrid/ImageGrid";
import { H3 } from "../Typography/Typography";
import { SlideOut } from "../SlideOut/SlideOut";
import { EditPartners } from "./EditPartners";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { EditTopRight } from "../EditTopRight/EditTopRight";
import { HomePageTitle } from "../../layout/publicPageLayout";
export interface IPartnersData {
  title?: string;
  desc?: string;
  isEditable?: boolean;
  logos: IImageGridData[];
  homePageData: IHomePageData;
}

const Edit = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${({ theme }) => theme.primaryBG};
  border-radius: 4px;
  padding: 3px 0px 2px;
`;

const PartnersWrapper = styled.div`
  position: relative;
`;

const PartnerLogosContainer = styled.div`
  margin: 50px 0 20px;
`;

const CustomH3 = styled(H3)`
  font-weight: ${({ theme }) => theme.fontWeights.small};
  margin-top: 0;
`;

export const Partners = ({ homePageData, isEditable }: IPartnersData) => {
  const [editMode, setEditMode] = useState(false);
  const startEditMode = () => {
    setEditMode(true);
  };
  const endEditMode = () => {
    setEditMode(false);
  };

  const component = homePageData.config.components.find(
    (component) => component.name === "partners"
  );

  return (
    <PartnersWrapper>
      <HomePageTitle>
        {component?.data?.title ? component?.data?.title : "Partners"}
      </HomePageTitle>
      {component?.data?.desc && <CustomH3>{component?.data?.desc}</CustomH3>}
      <PartnerLogosContainer>
        <ImageGrid data={component?.data?.logos} maxImageWidth={250} />
      </PartnerLogosContainer>

      <SlideOut closeFlyout={endEditMode} show={editMode}>
        <EditPartners
          homePageData={homePageData}
          handleEditSubmit={endEditMode}
        />
      </SlideOut>
      {isEditable && !editMode && (
        <Edit>
          <EditTopRight onClick={startEditMode} />
        </Edit>
      )}
    </PartnersWrapper>
  );
};
