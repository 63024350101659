import {
  convertStringArrayToObj,
  formatAddressCityState,
  formatDate,
  formatDateTime,
  useInAppNotifications,
  useStoreState,
  rowHover,
} from "../../../util/util";
import type {
  IOrderItem,
  IOrdersAPIResponse,
  IPurchaseOrder,
  OrderStatus,
  StatusAndId,
  User,
} from "../../../types/types";
import { ArrayParam, NumberParam, useQueryParams } from "use-query-params";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { Modal } from "../../../components/Modal/Modal";
import type { FiltersDropDownItem } from "../../../components/FiltersDropDown/FiltersDropDown";
import { FiltersDropDown } from "../../../components/FiltersDropDown/FiltersDropDown";
import { getOrderItemsList } from "../../../util/QuotesAndOrders";
import { useHistory } from "react-router-dom";
import { IDMobile } from "../../../components/IDs/IDs";
import { Pagination } from "../../../components/Pagination/Pagination";
import { useRoutePath } from "../../../util/Routing";
import { Auth } from "../../../components/Auth";
import {
  HorizontalButtonsContainer,
  ContentWrapper,
  FiltersWrapper,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import { DropDown } from "../../../components/DropDown/DropDown";
import { StringParam } from "serialize-query-params";
import ReactTooltip from "react-tooltip";
import {
  getOrderProductsAsText,
  SellerOrderStatus,
  SellerOrderStatusFilters,
} from "../../../components/OrderStatus/OrderStatus";
import { Table } from "../../../components/Table/Table";
import useSWR from "swr";
import type { ChangeEvent } from "react";
import React, { useContext, useEffect, useState } from "react";
import { SellerCreateOrder } from "../../Seller/SellerCreateOrder/SellerCreateOrder";
import type { AxiosError } from "axios";
import { useDebounce } from "../../../util/hooks";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import {
  PrimaryButtonWithPlusIcon,
  SecondaryButtonMedium,
} from "../../../components/Buttons/Buttons";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { DownloadReport } from "./DownloadReport/DownloadReport";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { calculatePriceDetails } from "../../../components/PriceDetails/PriceDetails";
import {
  TooltipCell,
  constructQuery,
} from "../../Buyer/BuyerOrders/BuyerOrders.util";
import { TrimmedName } from "../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";

const localStrings = (t: TFunction) => ({
  pageTitle: t("Orders"),
  sellerSearchBarPlaceholder: t("Search by Product, Customers or Order ID"),
  buyerSearchBarPlaceholder: t("Search by Product or Order ID"),
  status: t("Status"),
  perPage: t(" Per Page"),
});

type OrdersTable = {
  id: string;
  products: { items: IOrderItem[]; id: string };
  from: string;
  total_value: string;
  last_activity: string;
  status: StatusAndId<OrderStatus>;
  unread: boolean;
};

/**
 * Shared page component that displays a list of orders as viewed by a seller
 * (as opposed to a buyer).  Used for seller and seller admin.
 */
export const SellerOrdersListPage = () => {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
  });
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [perPage, setPerPage] = useState(query?.perPage ?? 10);
  const perPageItems = [10, 20, 50, 100];
  const { user } = useContext(Auth);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [showCreateOrderModal, setShowCreateOrderModal] = useState(false);
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<FiltersDropDownItem[]>(
    []
  );
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    (query?.status?.filter((status) => !!status) as string[]) ?? []
  );
  const [downloadReportSlideOut, setDownloadReportSlideOut] = useState(false);
  const history = useHistory();
  const { accountPath } = useRoutePath();
  const { t } = useTranslation();

  const { storefront_id } = useStoreState();
  const { notifications } = useInAppNotifications(storefront_id, user as User);

  const getSearchPlaceholder = () => {
    if (user?.rbac_role === "seller_admin") {
      return localStrings(t).sellerSearchBarPlaceholder;
    }
    return localStrings(t).buyerSearchBarPlaceholder;
  };

  const {
    data: ordersResponse,
    error: ordersError,
    mutate: mutateOrdersList,
  } = useSWR<IOrdersAPIResponse, AxiosError<IOrdersAPIResponse>>(
    constructQuery({
      baseUrl: `/v1/storefronts/${storefront_id}/orders?`,
      query: debouncedSearchQuery,
      status: selectedStatusList,
      offset,
      perPage,
    })
  );

  const isLoading = !ordersResponse && !ordersError;
  const [tableData, setTableData] = useState<OrdersTable[]>([]);
  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Order ID"),
        accessor: "number",
        Cell: ({ value }: { value: string }) => (
          <IDMobile>{`${value}`}</IDMobile>
        ),
      },
      {
        Header: t("Product(s)"),
        accessor: "products",
        Cell: ({ value }: { value: { items: IOrderItem[]; id: string } }) => {
          return (
            <TooltipCell data-for={`${value.id}-tooltip`} data-tip={""}>
              <TrimmedName text={getOrderProductsAsText(value.items, t)} />
              <ReactTooltip
                id={`${value.id}-tooltip`}
                place="top"
                data-html={true}
                effect="solid"
                backgroundColor="#60676F"
                multiline={true}
              >
                {getOrderItemsList(value.items)}
              </ReactTooltip>
            </TooltipCell>
          );
        },
      },
      {
        Header: t("From"),
        accessor: "from",
      },
      {
        Header: t("Total Value"),
        accessor: "total_value",
      },
      {
        Header: t("Last Activity"),
        accessor: "last_activity",
        align: "right",
      },
      {
        Header: t("Order Placed"),
        accessor: "created_at",
        // make this look not so close to the last activity column
        align: "right",
      },
      { Header: t("Shipping Address"), accessor: "ship_to" },
      {
        Header: t("Status"),
        accessor: "status",
        width: 50,
        minWidth: 50,
        align: "right",
        Cell: ({ value }: { value: StatusAndId<OrderStatus> }) => (
          <SellerOrderStatus t={t} status={value.status} id={value.id} />
        ),
      },
    ],
    [t]
  );

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const applyStatus = (items: FiltersDropDownItem[]) => {
    setSelectedStatus(items);
    setSelectedStatusList(items.map((item) => item.value));
    changePage(0);
  };

  useEffect(() => {
    const getUnread = (order: IPurchaseOrder): boolean => {
      return notifications?.orders.ids
        ? notifications?.orders.ids.includes(order?.id)
        : false;
    };

    if (ordersResponse) {
      const handleOrdersData = ({ data, pagination }: IOrdersAPIResponse) => {
        setTableData(
          data.map((order: IPurchaseOrder) => ({
            id: order.id,
            number: order.number,
            products: { items: order.items, id: order.id },
            from: order.buyer_name || "--",
            total_value: (() => {
              const maybeInvoiceCharges =
                order.invoice && order.invoice.additional_charges
                  ? order.invoice.additional_charges
                  : [];

              const feesAndCharges = [...order.fees, ...maybeInvoiceCharges];

              const { totalString } = calculatePriceDetails({
                items: order.items,
                fees: feesAndCharges,
                currencyCode: order.currency,
              });

              return totalString;
            })(),
            last_activity: formatDateTime(order.modified_at) || "--",
            created_at: formatDate(order.created_at),
            ship_to: order.shipping_address
              ? formatAddressCityState(order.shipping_address)
              : // TODO add ErrorText
                "--",
            status: { status: order.status || "--", id: order.id },
            unread: getUnread(order),
          }))
        );
        setTablePagination({
          perPage: perPage,
          pageCount: Math.ceil(pagination.total / perPage),
          pageIndex: pagination.offset / perPage + 1,
        });
      };
      handleOrdersData(ordersResponse);
    }
  }, [ordersResponse, perPage, notifications]);

  useEffect(() => {
    setQuery({ offset, perPage, status: selectedStatusList });
    const statusObj = convertStringArrayToObj(selectedStatusList);
    setSelectedStatus(
      SellerOrderStatusFilters(t).filter((item) => !!statusObj[item.value])
    );
  }, [offset, perPage, selectedStatusList, setQuery, t]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const closeDownloadReportSlideout = () => setDownloadReportSlideOut(false);

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(offset));
    params.append("perPage", String(perPage));
    ((query?.status ?? []).filter((status) => !!status) as string[]).forEach(
      (status) => params.append("status", status)
    );
    history.push(`${accountPath}/orders/${e.currentTarget.id}?${params}`);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{localStrings(t).pageTitle}</PageTitle>
        <SearchBar
          query={searchQuery}
          placeHolder={getSearchPlaceholder()}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        <HorizontalButtonsContainer>
          <SecondaryButtonMedium
            onClick={() => setDownloadReportSlideOut(true)}
          >
            {t("Download Report")}
          </SecondaryButtonMedium>
          <PrimaryButtonWithPlusIcon
            onClick={() => setShowCreateOrderModal(true)}
          >
            {t("Create New")}
          </PrimaryButtonWithPlusIcon>
        </HorizontalButtonsContainer>
      </PageHeader>
      <Modal
        show={showCreateOrderModal}
        closeModal={() => setShowDialogBox(true)}
        modalWidth={"95%"}
      >
        <SellerCreateOrder
          onSuccess={() => {
            setShowCreateOrderModal(false);
            mutateOrdersList();
          }}
        />
      </Modal>

      <FiltersWrapper>
        <FiltersDropDown
          activeItems={selectedStatus}
          applyStatus={applyStatus}
          list={SellerOrderStatusFilters(t)}
        />
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          showIcon
          hideBorder
          textLeft={t("items") + ":"}
          textRight={localStrings(t).perPage}
          direction={"left"}
          clickHandler={changePerPage}
        />
      </FiltersWrapper>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={ordersError}
          rowClick={handleRowClick}
          rowHover={rowHover}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
        <SlideOut
          closeFlyout={closeDownloadReportSlideout}
          show={downloadReportSlideOut}
        >
          <DownloadReport
            closeSlideout={closeDownloadReportSlideout}
            transactionType={"order"}
          />
        </SlideOut>
        <ConfirmDialog
          show={showDialogBox}
          confirmMessage={t("Are you sure you want to cancel this order?")}
          closeDialog={() => setShowDialogBox(false)}
          handleConfirm={() => {
            setShowCreateOrderModal(false);
            setShowDialogBox(false);
          }}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};
