import React, { useState, useContext } from "react";
import { Form } from "../../../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../components/SelectBoxV2/SelectBoxV2";
import {
  useStoreState,
  convertCurrencyToOption,
  useFormWrapper,
} from "../../../../util/util";
import type {
  OptionType,
  Tenant,
  User,
  Currency,
  ITenantCustomerSettingsCreation,
  CurrencyCode,
} from "../../../../types/types";
import { PrimaryButtonMedium } from "../../../../components/Buttons/Buttons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import { endpoints } from "../../../../endpoints";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { strings } from "../../../../util/strings";
import { ErrorPlaceholder } from "../../../../components/Error";
import { FormContainer, SaveButtonContainer } from "./CustomerSettings";
import { useTranslation } from "react-i18next";

type FormData = {
  currency: OptionType<CurrencyCode> | null;
};

export const EditCurrencyForm = ({
  buyerTenant,
  loggedInUser,
  refreshSettingsData,
  doneEditing,
  currency,
}: {
  buyerTenant: Tenant;
  loggedInUser: User;
  refreshSettingsData: () => void;
  doneEditing: () => void;
  currency?: Currency;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { api_metadata, storefront_id } = useStoreState();
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formSchema = yup.object().shape({
    currency: yup.object().required(strings(t).thisIsARequiredField),
  });

  const { handleSubmit, control, errors, formState, watch } =
    useFormWrapper<FormData>({
      resolver: yupResolver(formSchema),
      defaultValues: {
        currency: currency ? convertCurrencyToOption(currency) : null,
      },
    });

  const currencies = api_metadata?.supported_currencies;
  const currencyOptions = currencies?.map(convertCurrencyToOption) ?? [];
  const watchedCurrency = watch("currency");

  const onSubmit = async (formData: FormData) => {
    const { currency } = formData;
    if (!currency) {
      // Should never happen because of disabled submit button.
      return;
    }
    setIsSubmitting(true);
    try {
      const requestBody: ITenantCustomerSettingsCreation = {
        preferred_currency: currency.value,
      };
      await Axios.patch(
        endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
          storefront_id,
          // Since this edit form is only used by sellers (not buyers), the
          // logged in user will belong to the seller tenant, so that's how we
          // get the seller tenant ID.
          loggedInUser.tenant_id,
          buyerTenant.id
        ),
        requestBody
      );
      notifySuccess("Changes saved");
      setIsSubmitting(false);
      refreshSettingsData();
      doneEditing();
    } catch (error) {
      notifyError("There was an error submitting the changes", { error });
      setIsSubmitting(false);
    }
  };

  if (!currencies || currencies.length === 0) {
    return <ErrorPlaceholder message="There was an error, please try again." />;
  }
  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="currency"
          placeholder="Currency"
          options={currencyOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        />
        <SaveButtonContainer>
          <PrimaryButtonMedium
            loading={isSubmitting}
            disabled={!watchedCurrency}
          >
            Save your changes
          </PrimaryButtonMedium>
        </SaveButtonContainer>
      </Form>
    </FormContainer>
  );
};
