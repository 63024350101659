import React from "react";
import { Card } from "../../../layout/portalPageLayout";
import { LeadNotesDisplayContainer } from "./LeadNotes";
import { useTranslation } from "react-i18next";
import { InfoBlockExtraSmall } from "../../../components/InfoBlocks/InfoBlocks";
import type { RejectionNote } from "../../../types/types";
import { H3 } from "../../../components/Typography/Typography";
import { formatDateTime } from "../../../util/util";

export type LeadRejectionCardProps = {
  rejection_note: RejectionNote;
};

/**
 * display lead rejection messages if they exist.
 */
export function LeadRejectionCard({
  rejection_note: {
    internal_message,
    message_for_buyer,
    modified_by,
    modified_at,
  },
}: LeadRejectionCardProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <H3>{t("Lead Rejected")}</H3>
      <LeadNotesDisplayContainer>
        {modified_by && (
          <InfoBlockExtraSmall
            header={t("Rejected By")}
            content={
              <div style={{ whiteSpace: "pre-wrap" }}>
                {modified_by} {t("at")} {formatDateTime(modified_at)}
              </div>
            }
          />
        )}
        {internal_message && (
          <InfoBlockExtraSmall
            header={t("Reason for rejection")}
            content={
              <div style={{ whiteSpace: "pre-wrap" }}>{internal_message}</div>
            }
          />
        )}
        {message_for_buyer && (
          <InfoBlockExtraSmall
            header={t("Message to buyer")}
            content={
              <div style={{ whiteSpace: "pre-wrap" }}>{message_for_buyer}</div>
            }
          />
        )}
      </LeadNotesDisplayContainer>
    </Card>
  );
}
