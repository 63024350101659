import { useTranslation } from "react-i18next";
import type {
  DataMutate,
  TranslationsSchema,
} from "../../../../../types/types";
import type { CollectionSchema } from "../../../../../types/types.PIM";
import {
  EditGroupOrCollectionTranslations,
  GroupOrCollectionTranslationsTable,
} from "./util";
import { TranslationsAccordionCard } from "../../../../../components/AccordionCard/TranslationsAccordionCard";

export const CollectionTranslations = ({
  collection,
  mutate,
}: {
  collection: CollectionSchema & { translations: TranslationsSchema[] };
  mutate: DataMutate<CollectionSchema & { translations: TranslationsSchema[] }>;
}) => {
  const { t } = useTranslation();
  const tableElement = () => (
    <GroupOrCollectionTranslationsTable
      attribute_display_name={collection.display_name}
      translations={collection.translations}
    />
  );
  return (
    <TranslationsAccordionCard
      tableElement={tableElement()}
      subtitle={t("Manage translations for this collection.")}
      editElement={(onClose) => (
        <EditGroupOrCollectionTranslations
          group_or_collection={{
            name: collection.name,
            display_name: collection.display_name
              ? t([collection.display_name])
              : "",
            id: collection.id,
            type: "collections",
          }}
          mutate={mutate}
          onClose={onClose}
          translations={collection.translations}
        />
      )}
    />
  );
};
