import React from "react";
import styled from "styled-components/macro";
import { H3 } from "../Typography/Typography";
import { HomePageTitle } from "../../layout/publicPageLayout";

interface ITitleWithParagraphProps {
  title?: string;
  desc?: string;
}

const CustomH3 = styled(H3)`
  font-weight: ${({ theme }) => theme.fontWeights.small};
`;

export const TitleWithParagraph = ({
  title,
  desc,
}: ITitleWithParagraphProps) => {
  return (
    <>
      {title && <HomePageTitle>{title}</HomePageTitle>}
      {desc && <CustomH3>{desc}</CustomH3>}
    </>
  );
};
