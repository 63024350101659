import { useMediaQueries } from "../../util/util";
import type { ReactNode } from "react";
import React from "react";
import styled from "styled-components/macro";
import { Title } from "../Typography/Typography";
import { screenSize } from "../../theme";

export const DesktopFormContainer = styled.div`
  max-width: 478px;
  form > * {
    margin-bottom: 15px;
  }
`;

export const DesktopFormContainerCart = (props: { children: ReactNode }) => {
  const { isMediumScreen, isLargeScreen } = useMediaQueries();
  return (
    <DesktopFormContainer
      style={
        isMediumScreen
          ? { maxWidth: "unset" }
          : isLargeScreen
          ? { maxWidth: "95%", width: "958px" }
          : { maxWidth: "100%", width: "958px" }
      }
    >
      {props.children}
    </DesktopFormContainer>
  );
};

export const CheckBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const PageTitle = styled(Title)`
  && {
    display: inline-block;
    margin-right: 10px;
  }
`;

export const HeaderRight = styled.div`
  font-weight: normal;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CustomerDistributorDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 10px -8px 5px 0px;
  flex-wrap: wrap;
  width: calc(100% - 150px);
  & > * {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  & > *[data-alignright] {
    margin-left: auto;
  }

  @media ${screenSize.mLarge} {
    width: calc(100% - 205px);
  }

  @media ${screenSize.medium} {
    margin: 10px 0 5px;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: scroll;
    & > * {
      margin-right: 5px;
      margin-bottom: 8px;
    }
    & > .rightAlign {
      margin-left: 0 !important;
    }
  }
`;

export const DropdownContainer = styled(Row)`
  flex-direction: row-reverse;
  margin-right: -12px;
`;

/**
 * To be used to contain information in small text below a page title. Typically
 * presented as key value pairs.
 */
export const SubheaderInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 3px;
`;

/**
 * To be used to contain the "Header Section" of a page. See
 * `ProductDetailPage.tsx` for a working example.
 */
export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  h1 {
    margin: 2px 5px 2px 0;
  }
`;
