import { useContext, useState } from "react";
import { Notifications } from "./NotificationsContext";

/**
 * React hook that shows an error notification only once if there is an error.
 * Typically used with `useSWR`, to avoid showing an error notification on
 * every render.
 */
export function useNotifyErrorOnce(message: string, error: any | undefined) {
  const { notifyError } = useContext(Notifications);
  const [notificationShown, setNotificationShown] = useState(false);

  if (error && !notificationShown) {
    notifyError(message, { error });
    setNotificationShown(true);
  }
}
