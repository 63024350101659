import type { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { GoBackButton } from "../../../components/Buttons/Buttons";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../components/Error";
import {
  SettingsIcon,
  SoldByIcon,
  UsersIcon,
} from "../../../components/Icons/Icons";
import { Tab, Tabs } from "../../../components/Tabs/Tabs";
import { SoftHeader2 } from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import {
  ContentWrapper,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import type { Tenant } from "../../../types/types";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import { makeUrlWithParams, useStoreState } from "../../../util/util";
import { CompanyInfo } from "../../SharedPages/CustomerDetailPage/CompanyInfo/CompanyInfo";
import { CustomerSettings } from "../../SharedPages/CustomerDetailPage/CustomerSettings/CustomerSettings";
import { UsersList } from "../../SharedPages/CustomerDetailPage/UsersList/UsersList";
import { PriceTiersTab } from "../../SharedPages/CustomerDetailPage/PriceTiersTab/PriceTiersTab";

export const DistributorDetailPage = providePrivatePageProps(({ user }) => {
  const {
    storefront_id,
    storefront_metadata: { edition },
  } = useStoreState();
  const { distributor_id: distributorTenantId } =
    useParams<{ distributor_id: string }>();
  const [query] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });
  const { adminPath } = useRoutePath();
  const { t } = useTranslation();

  const {
    data: distributorTenant,
    error: distributorTenantError,
    mutate: mutateBuyerTenant,
  } = useSWR<Tenant, AxiosError>(
    distributorTenantId
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_tenants_id(
            storefront_id,
            distributorTenantId
          ),
          {
            show_inactive_address: "true",
          }
        )
      : null
  );

  if (distributorTenantError) {
    return <ErrorPlaceholder message="Failed to fetch customer." />;
  }
  if (!distributorTenant) {
    // Loading.
    return <DelayedSpinner />;
  }

  return (
    <PageWrapper narrow>
      <Link
        to={makeUrlWithParams(`${adminPath}/distributors`, {
          q: query.q,
          offset: query.offset,
          perPage: query.perPage,
        })}
      >
        <GoBackButton text={t("Distributors")} />
      </Link>
      <PageHeader>
        <PageTitle>{distributorTenant.name}</PageTitle>
        {distributorTenant.external_identity?.external_id && (
          <SoftHeader2 data-alignright>
            {`${t("Distributor ID")}: ${
              distributorTenant.external_identity?.external_id
            }`}
          </SoftHeader2>
        )}
      </PageHeader>
      <ContentWrapper>
        <Tabs selectedIndex="Company Info">
          <Tab label={t("Company Info")} icon={SettingsIcon}>
            <CompanyInfo
              buyerTenant={distributorTenant}
              mutateBuyerTenant={mutateBuyerTenant}
            />
          </Tab>
          <Tab label={t("Users")} icon={UsersIcon}>
            <UsersList
              tenantID={distributorTenant.id}
              userType="Distributor Admin"
            />
          </Tab>
          <Tab label={t("Settings")} icon={SettingsIcon}>
            <CustomerSettings
              buyerTenant={distributorTenant}
              loggedInUser={user}
            />
          </Tab>
          {edition !== "pim" ? (
            <Tab label="Price Tiers" icon={SoldByIcon}>
              <PriceTiersTab buyerTenant={distributorTenant} user={user} />
            </Tab>
          ) : (
            <></>
          )}
        </Tabs>
      </ContentWrapper>
    </PageWrapper>
  );
});
