import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStoreState } from "../../util/util";
import { ErrorPlaceholder } from "../Error";
import type { AxiosError } from "axios";

type SelectorWizardProps = {
  setParams: React.Dispatch<React.SetStateAction<URLSearchParams | null>>;
  isFinal: boolean | undefined;
  showSubmit: boolean | undefined;
  filterKey: string;
  hasError: AxiosError | undefined;
  filterQuestion: string | undefined;
  currentFilters: string[] | null;
  queryParams: string | undefined;
};

const WIZARD_TITLE = "PRODUCT SELECTOR";
const FINAL_QUESTION = "Select your function";

/**
 * https://agilischemicals.atlassian.net/wiki/spaces/EN/pages/61931521/Product+Grade+Selector+Capabilities+on+a+store+Brenntag
 */
export function SelectorWizard({
  setParams,
  isFinal,
  showSubmit,
  filterKey,
  hasError,
  filterQuestion,
  currentFilters,
  queryParams,
}: SelectorWizardProps) {
  const history = useHistory();
  const { slug } = useStoreState();
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [question, setQuestion] = useState("");
  const [style, setStyle] = useState({});

  // Below exists to avoid "flashes" when switching between options on the final
  // question. This unfortunately makes this component non generic, and will
  // have to be updated if the final question changes.
  if (filterQuestion === FINAL_QUESTION) {
    if (question !== FINAL_QUESTION) {
      setQuestion(filterQuestion);
    }
  }

  const handleFilterButtonClick = ({
    currentTarget: { innerText: value },
  }: React.MouseEvent<HTMLButtonElement>): void => {
    // Some names are duplicates in different categories, so only set button to
    // selected if we're on final step
    setSelectedFilter(value);
    setStyle({ backgroundColor: "#006", color: "#fff" });

    setParams((params) => {
      // below must exist for the user to trigger this click handler.
      if (params) {
        return new URLSearchParams({
          ...Object.fromEntries(params),
          [filterKey]: value,
        });
      } else {
        return new URLSearchParams({
          [filterKey]: value,
        });
      }
    });
  };

  useEffect(() => {
    if (showSubmit) {
      history.push(`/store/${slug}/portfolio?${queryParams as string}`);
    }
  }, [showSubmit, queryParams, history, slug]);

  return (
    <>
      {hasError && (
        <ErrorPlaceholder message="There was an error loading the selector" />
      )}
      {!hasError && (
        <>
          <h3 className="agc-modal-title">{WIZARD_TITLE}</h3>
          {/* min height to prevent layout shift */}
          <div className="agc-w-modal-question" style={{ minHeight: "20px" }}>
            <p className="agc-body">{filterQuestion || question}</p>
          </div>
        </>
      )}
      {currentFilters && (
        <>
          <div>
            <div className="agc-w-modal-answer">
              <div className="agc-grid-answers">
                {currentFilters.map((option) => (
                  <button
                    className={"agc-cta-2 agc-modal"}
                    // Can't use psuedoclasses with inline styles, this
                    // matches the :hover styles for the above classes
                    type="button"
                    style={
                      selectedFilter === option
                        ? { ...style }
                        : { cursor: "pointer" }
                    }
                    onClick={handleFilterButtonClick}
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
