import { Tabs } from "@reach/tabs";
import React from "react";
import styled from "styled-components/macro";
import { Tab } from "../../../components/ReachTabs/Tab";
import { TabList } from "../../../components/ReachTabs/TabList";
import { PageHeader, PageTitle } from "../../portalPageLayout";

const NavPageHeader = styled.div`
  margin: 0 0 40px;
  ${PageHeader} {
    margin-bottom: 20px;
  }
`;

/**
 * This component create tabs that function as navbars.
 * This component can be used to compose such navbars across our application.
 * @param param0
 * @returns
 */
export const NavBarTabs = ({
  handleTabsChange,
  pageTitle,
  tabs,
  tabIndex,
}: {
  handleTabsChange: (index: number) => void;
  pageTitle: string;
  tabs: string[];
  tabIndex: number;
}) => {
  return (
    <NavPageHeader>
      <PageHeader>
        <PageTitle>{pageTitle}</PageTitle>
      </PageHeader>
      <div>
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList width={"100%"}>
            {tabs.map((tab) => (
              <Tab key={tab}>{tab}</Tab>
            ))}
          </TabList>
        </Tabs>
      </div>
    </NavPageHeader>
  );
};
