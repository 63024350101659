import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStoreState } from "../../util/util";

export const RedirectOnInvalidRoutes: React.FC = (props) => {
  const { pathname } = useLocation();
  const { api_metadata } = useStoreState();

  useEffect(() => {
    // default_redirect should be non nullable but if for some reason it is this is a no op. We should fall through to the react router 404 page.
    if (
      pathname === "/" ||
      pathname === "/store" ||
      pathname === "/storefront"
    ) {
      if (api_metadata && api_metadata.default_redirect) {
        window.location.href = api_metadata.default_redirect;
      }
    }
  }, [api_metadata, pathname]);

  return <>{props.children}</>;
};
