import type { DefaultTheme } from "styled-components";
import { AG3Theme } from "../theme";
import { mix } from "polished";
interface ThemeVariables {
  brandColor: string;
  portalLogoUrl: string;
  publicLogoUrl: string;
  customFontFamily: string;
  favIconUrl: string;
}

/**
 *
 * @param branding: ThemeVariables
 * @param theme_name: a name peculiar to a storefront, could be the slug or tenant_id
 * @returns
 */
export function makeNewTheme(
  branding: ThemeVariables,
  theme_name: string
): DefaultTheme {
  if (branding) {
    const { brandColor, portalLogoUrl, publicLogoUrl } = branding;
    const theme = { ...AG3Theme };
    theme.brandColor = brandColor;
    theme.navBG = mix(0.03, brandColor, "#fff");
    theme.primaryButtonBG = mix(0.03, brandColor, "#fff");
    theme.primaryButtonBorder = brandColor;
    theme.tertiaryTextColor = brandColor;
    theme.publicNavBorderActive = brandColor;
    theme.publicLogoBGColor = brandColor;
    theme.primaryLinkHover = brandColor;
    theme.secondaryLinkHover = brandColor;
    theme.tertiaryLinkHover = brandColor;
    theme.tertiaryButtonSelected = mix(0.03, brandColor, "#fff");
    theme.navIconActive = brandColor;
    theme.selectedBorder = brandColor;
    theme.primaryButtonHover = mix(0.08, brandColor, "#fff");
    theme.primaryButtonActive = mix(0.11, brandColor, "#fff");
    theme.portalLogoUrl = portalLogoUrl;
    theme.publicLogoUrl = publicLogoUrl;
    theme.theme_name = theme_name;
    return theme;
  }
  return AG3Theme;
}
