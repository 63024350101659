import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import {
  H3,
  H6Bold,
  SoftHeaderMediumWeight,
  SoftHeaderPrimaryTextColor,
} from "../../../../../../components/Typography/Typography";
import {
  Form,
  RadioButtonContainer,
} from "../../../../../../layout/FormLayout";
import { z } from "zod";
import {
  zodOptionalString,
  zodRequiredString,
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../../../util/zod.util";
import { useFormWrapper, useStoreState } from "../../../../../../util/util";
import { zodResolver } from "@hookform/resolvers/zod";
import { findNonEmptyDuplicateIndexes } from "../../../../../../util/form.utils";
import type {
  AttributeTemplateSchema,
  TDSGeneratedAssetRequestSchema,
  TdsGeneratedAssetSchema,
  TdsGeneratedAssetsSectionsSchema,
} from "../../../../../../types/types.PIM";
import type { ArrayField } from "react-hook-form";
import { Controller, useFieldArray } from "react-hook-form";
import { SelectBoxV2 } from "../../../../../../components/SelectBoxV2/SelectBoxV2";
import { MarginBottomH6 } from "../../SellerAdminPIMAttributes/CreateAttribute";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type {
  MethodsOfUseForm,
  OptionType,
  TDSDocument,
} from "../../../../../../types/types";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  SecondaryButtonWithPlusIcon,
} from "../../../../../../components/Buttons/Buttons";
import { useDrag, useDrop } from "react-dnd";
import { ReorderIcon } from "../../../../../../components/Icons/Icons";
import styled, { useTheme } from "styled-components";
import type { AxiosError } from "axios";
import axios from "axios";
import { endpoints } from "../../../../../../endpoints";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import { ErrorPlaceholder } from "../../../../../../components/Error";
import { FilePickerUncontrolled } from "../../../../../../components/FilePicker/FilePickerUncontrolled";
import type { SupportedFileType } from "../../../../../../components/FilePicker/FilePicker.constants";
import { SUPPORTED_IMG_TYPES } from "../../../../../../components/FilePicker/FilePicker.constants";
import { RadioButton } from "../../../../../../components/RadioButton/RadioButton";
import { RichEditor } from "../../../../../../components/RichEditor/RichEditor";

const ItemWrapper = styled.div<{
  isDragging: boolean;
  isLast: boolean;
}>`
  gap: 16px;
  opacity: ${({ isDragging }) => (isDragging ? 0.3 : 1)};
  display: flex;
  padding: 16px;
  align-items: center;
  flex-direction: row;
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-bottom: ${({ theme, isLast }) =>
    isLast ? `1px solid ${theme.secondaryBorder}` : "none"};
  > div:nth-of-type(2) {
    flex: 1;
  }
  margin-bottom: ${({ isLast }) => (isLast ? "15px" : 0)};
`;

const HeaderWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.secondaryBG};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  margin-bottom: 0;
`;

const H6BoldNoMargin = styled(H6Bold)`
  margin: 0;
`;

const RichEditorContainer = styled.div`
  .ql-container {
    height: fit-content;
  }
`;

const documentSchema = z.object({
  kind: z.string(),
  name: z.string(),
  size: z.number(),
  signed_url: z.string(),
  id: z.string(),
});

const schema = z.object({
  asset_type: zodSelectBoxType,
  asset_category: zodSelectBoxType,
  title: z.string(),
  letterhead_file: z.instanceof(File).or(documentSchema).nullable().optional(),
  language: zodSelectBoxType,
  footer_content: zodOptionalString,
  footer_image: z.instanceof(File).or(documentSchema).nullable().optional(),
  is_footer_image: z.boolean(),
  footer_on_last_page_only: z.string(),
  group_or_collection: z
    .object({
      item: zodSelectBoxType,
    })
    .array()
    .optional(),
});

const generateSchema = (t: TFunction) =>
  z
    .object({
      asset_type: zodSelectBoxDefault(t),
      asset_category: zodSelectBoxDefault(t),
      title: zodRequiredString(t),
      letterhead_file: z
        .instanceof(File)
        .or(documentSchema)
        .nullable()
        .optional(),
      language: zodSelectBoxDefault(t),
      footer_content: z.string().optional(),
      is_footer_image: z.boolean(),
      footer_on_last_page_only: z.string(),
      footer_image: z.instanceof(File).or(documentSchema).nullable().optional(),
      group_or_collection: z
        .object({
          item: zodSelectBoxDefault(t),
        })
        .array()
        .optional(),
    })
    .superRefine(({ group_or_collection }, ctx) => {
      const duplicateIdxs = group_or_collection
        ? findNonEmptyDuplicateIndexes([
            ...group_or_collection.map(({ item: { value } }) => value),
          ])
        : [];
      if (duplicateIdxs.length > 0) {
        duplicateIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Duplicate groups or collections not allowed"),
            path: [`group_or_collection[${idx}].item`],
          });
        });
      }
    });

type formOutput = z.infer<typeof schema>;

const convertGroupOrCollectionSchemaToOptionType = (
  section: TdsGeneratedAssetsSectionsSchema | undefined
) => {
  if (!section) return { label: "", value: "" };
  const item =
    section.group_type === "collection" ? section.collection : section.group;
  return { label: item.name, value: item.id };
};

export const ConfigureGeneratedAssets = ({
  template,
  generatedAsset,
  generatedAssetError,
  onSuccess,
}: {
  template: AttributeTemplateSchema;
  generatedAssetError?: AxiosError;
  generatedAsset?: TdsGeneratedAssetSchema;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const { tenant_id, storefront_id } = useStoreState();
  const { notifySuccess, notifyError } = useNotifications();

  const [groupsAndCollectionsOptions, setGroupsAndCollectionsOptions] =
    useState<OptionType<string>[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultValues = useMemo(() => {
    const defaultVal: formOutput = {
      language: { label: "English (EN)", value: "en" },
      asset_type: { label: t("Document"), value: "document" },
      asset_category: {
        label: "Technical Data Sheet (TDS)",
        value: "tds",
      },
      footer_on_last_page_only: generatedAsset?.footer_on_last_page_only
        ? "last_page"
        : "all_pages",
      is_footer_image: generatedAsset?.footer_document?.name ? true : false,
      footer_content: generatedAsset?.footer_content,
      footer_image: generatedAsset?.footer_document,
      letterhead_file: generatedAsset?.letterhead_document,
      title: generatedAsset?.title ?? "{{product_name}} - TDS",
    };
    const groupOrCollections =
      generatedAsset?.sections?.sort((a, b) => a.order - b.order) ?? [];
    if (groupOrCollections.length > 1) {
      defaultVal.group_or_collection = groupOrCollections.map((section) => ({
        item: convertGroupOrCollectionSchemaToOptionType(section),
      }));
    } else {
      defaultVal.group_or_collection = [{ item: { label: "", value: "" } }];
    }
    return defaultVal;
  }, [
    generatedAsset?.footer_content,
    generatedAsset?.footer_document,
    generatedAsset?.letterhead_document,
    generatedAsset?.sections,
    generatedAsset?.title,
    generatedAsset?.footer_on_last_page_only,
    t,
  ]);

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(generateSchema(t)),
    defaultValues,
    reValidateMode: "onChange",
  });

  const { handleSubmit, control, formState, errors, register } =
    methodsOfUseForm;

  const { fields, remove, append, swap } = useFieldArray({
    control,
    name: "group_or_collection",
  });

  const createFields = useCallback(
    () => (
      <>
        <HeaderWrapper>
          <SoftHeaderMediumWeight
            style={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            {t("Group / Collection")}
          </SoftHeaderMediumWeight>
        </HeaderWrapper>
        {fields.map((field, index, array) => (
          <GroupOrCollectionItem
            key={field.id}
            methodsOfUseForm={methodsOfUseForm}
            dropdownOptions={groupsAndCollectionsOptions}
            field={field}
            index={index}
            remove={remove}
            swap={swap}
            isLast={index === array.length - 1}
          />
        ))}
      </>
    ),
    [fields, groupsAndCollectionsOptions, methodsOfUseForm, remove, swap, t]
  );

  const onSubmit = async (values: formOutput) => {
    setIsSubmitting(true);
    const {
      asset_type,
      asset_category,
      language,
      group_or_collection,
      title,
      letterhead_file,
      footer_content,
      footer_image,
      is_footer_image,
      footer_on_last_page_only,
    } = values;

    const sections = [
      ...(group_or_collection?.map(({ item: { value } }, idx) => ({
        order: idx,
        group_or_collection_id: value,
        is_full_width: false,
      })) ?? []),
    ];

    let letterhead_file_id: string | undefined;
    let footer_image_id: string | undefined;

    if (letterhead_file || is_footer_image) {
      if (letterhead_file) {
        if (letterhead_file instanceof File) {
          const formData = new FormData();
          formData.append("file", letterhead_file);
          const { data } = await axios.post<TDSDocument>(
            `/v1/storefronts/${storefront_id}/images`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          letterhead_file_id = data.id;
        } else {
          letterhead_file_id = generatedAsset?.letterhead_document?.id;
        }
      }
      if (footer_image) {
        if (footer_image instanceof File) {
          const formData = new FormData();
          formData.append("file", footer_image);
          const { data } = await axios.post<TDSDocument>(
            `/v1/storefronts/${storefront_id}/images`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          footer_image_id = data.id;
        } else {
          footer_image_id = generatedAsset?.footer_document?.id;
        }
      }
    }

    const footerOnLastPageBoolean =
      footer_on_last_page_only === "last_page" ? true : false;

    const requestBody: TDSGeneratedAssetRequestSchema = {
      asset_type:
        asset_type.value as TDSGeneratedAssetRequestSchema["asset_type"],
      sections,
      asset_category:
        asset_category.value as TDSGeneratedAssetRequestSchema["asset_category"],
      language: language.value,
      footer_on_last_page_only: footerOnLastPageBoolean,
      is_active: generatedAsset?.is_active ?? false,
      title,
      footer_image_id: is_footer_image ? footer_image_id ?? null : null,
      footer_content: !is_footer_image ? footer_content ?? "" : "",
      letterhead_file_id: letterhead_file_id ?? null,
    };
    try {
      if (generatedAsset?.id) {
        // edit
        await axios.patch<TdsGeneratedAssetSchema>(
          endpoints.v2_tenants_tenant_id_templates_template_id_generated_assets_id(
            tenant_id,
            template.template_id,
            generatedAsset.id
          ),
          requestBody
        );
      } else {
        // add new generated asset template
        await axios.put<TdsGeneratedAssetSchema>(
          endpoints.v2_tenants_tenant_id_templates_template_id_generated_assets(
            tenant_id,
            template.template_id
          ),
          requestBody
        );
      }
      notifySuccess(
        t("Configuration for generated assets has been updated successfully.")
      );
      setIsSubmitting(false);
      onSuccess();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error configuring the generated assets"),
        {
          error,
        }
      );
      setIsSubmitting(false);
    }
  };

  const appendItem = () => {
    append({ item: { label: "", value: "" } });
  };

  useEffect(() => {
    const groupsAndCollections = [
      ...template.groups
        .filter((group) => group.name !== "SEO")
        .map(({ name, id }) => ({ label: name, value: id })),
      ...template.collections.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    ];
    setGroupsAndCollectionsOptions(groupsAndCollections);
  }, [template]);

  return generatedAssetError ? (
    <ErrorPlaceholder
      message={t("Could not load Generated Asset. Please try again later.")}
    />
  ) : (
    <>
      <H3>{t("Configure Generated Asset")}</H3>
      <Form
        id="configure_generated_assets_form_id"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          as={SelectBoxV2}
          control={control}
          name="asset_type"
          placeholder={t("Asset Type")}
          options={[]}
          isDisabled={true}
          errors={errors}
          formState={formState}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="asset_category"
          placeholder={t("Category")}
          options={[]}
          isDisabled={true}
          errors={errors}
          formState={formState}
        />
        <TextField
          name="title"
          label={t("Title")}
          theref={register()}
          formState={formState}
          errors={errors}
          type="text"
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="language"
          placeholder={t("Language")}
          options={[{ label: "English (EN)", value: "en" }]}
          errors={errors}
          formState={formState}
        />
        <MarginBottomH6 style={{ marginTop: "40px" }}>
          {t("Search for groups & collections")}
        </MarginBottomH6>
        {createFields()}
        <SecondaryButtonWithPlusIcon
          type="button"
          style={{ marginBottom: "40px", fontSize: "15px" }}
          onClick={appendItem}
        >
          {t("Add")}
        </SecondaryButtonWithPlusIcon>
        {/* Customize Header */}
        <H6BoldNoMargin>{t("Customize header & design")}</H6BoldNoMargin>
        <SoftHeaderPrimaryTextColor>
          {t("Customize your TDS header & design by uploading a header image")}
        </SoftHeaderPrimaryTextColor>
        <FilePickerUncontrolled
          methodsOfUseForm={methodsOfUseForm}
          placeHolderText={t("Drag and drop a header image or")}
          name="letterhead_file"
          accept={SUPPORTED_IMG_TYPES as SupportedFileType}
          description={t("Max file size: 50 mb / file")}
          defaultValueFileName={generatedAsset?.letterhead_document?.name}
          required={false}
        />
        {/* Customize footer */}
        <ConfigureFooter
          methodsOfUseForm={methodsOfUseForm}
          generatedAsset={generatedAsset}
        />
        <PrimaryButtonFitContainer loading={isSubmitting} type="submit">
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};

function GroupOrCollectionItem({
  methodsOfUseForm,
  dropdownOptions,
  field,
  index,
  isLast,
  remove,
  swap,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  dropdownOptions: OptionType<string>[];
  field: Partial<ArrayField<Record<string, OptionType<string>>, "id">>;
  index: number;
  remove: (index?: number[] | number | undefined) => void;
  swap: (indexA: number, indexB: number) => void;
  isLast: boolean;
}) {
  const { t } = useTranslation();
  const { control, formState, errors } = methodsOfUseForm;

  const [dragRef] = useState(useRef<HTMLDivElement>(null));
  const dropRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: "item",
    hover(
      item: {
        type: "item";
        index: number;
        field: Partial<ArrayField<Record<string, OptionType<string>>, "id">>;
      },
      monitor
    ) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset?.y
        ? clientOffset?.y - hoverBoundingRect.top
        : 0;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      swap(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "item",
    item: { type: "item", index, field },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleRemove = () => {
    remove(index);
  };

  useEffect(() => {
    preview(drop(dropRef));
    drag(dragRef);
  }, [drag, dragRef, drop, preview]);

  return (
    <ItemWrapper
      data-testid={field.id}
      id={field.id}
      ref={dropRef}
      isDragging={isDragging}
      isLast={isLast}
    >
      <div ref={dragRef} style={{ cursor: "pointer", maxWidth: "100px" }}>
        <ReorderIcon />
      </div>
      <Controller
        as={SelectBoxV2}
        control={control}
        defaultValue={{
          label: field?.item?.label ?? "",
          value: field?.item?.value ?? "",
        }}
        name={`group_or_collection[${index}].item`}
        placeholder={t("Group / Collection")}
        options={dropdownOptions}
        isSearchBar={true}
        errors={{
          [`group_or_collection[${index}].item`]:
            errors?.group_or_collection?.[index]?.item?.value ??
            errors?.group_or_collection?.[index]?.item ??
            undefined,
        }}
        formState={formState}
      />
      <DeleteButton
        testid={`delete-button-${index}`}
        onClick={handleRemove}
        type="button"
        height={20}
        width={20}
      />
    </ItemWrapper>
  );
}

function ConfigureFooter({
  methodsOfUseForm,
  generatedAsset,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  generatedAsset?: TdsGeneratedAssetSchema;
}) {
  const [isImage, setIsImage] = useState(
    generatedAsset ? Boolean(generatedAsset?.footer_document?.name) : false
  );
  const { t } = useTranslation();
  const { register, errors, formState, setValue, watch } = methodsOfUseForm;
  const theme = useTheme();

  const handleFooterTypeRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    if (value === "image" && checked) {
      setIsImage(true);
      setValue("is_footer_image", true);
    } else if (value === "rich_text" && checked) {
      setIsImage(false);
      setValue("is_footer_image", false);
    }
  };

  const handleFooterConfigRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("footer_on_last_page_only", event.target.value);
  };

  const footerOnLastPageOnlyString = watch("footer_on_last_page_only");

  useEffect(() => {
    register("is_footer_image");
    register("footer_on_last_page_only");
  }, [register]);

  return (
    <>
      <H6BoldNoMargin>{t("Customize footer")}</H6BoldNoMargin>
      <SoftHeaderPrimaryTextColor>
        {t("Customize your footer content with an image or rich text content")}
      </SoftHeaderPrimaryTextColor>
      <RadioButtonContainer>
        <RadioButton
          name="footer_on_last_page_only"
          value="all_pages"
          optionTitle={t("Display footer on all pages")}
          fontSize={theme.fontSizes.medium}
          width={14}
          height={14}
          color={
            footerOnLastPageOnlyString === "all_pages"
              ? theme.primaryTextColor
              : theme.secondaryTextColor
          }
          handleChange={handleFooterConfigRadioChange}
          checked={footerOnLastPageOnlyString === "all_pages"}
          marginRight={4}
        />
        <RadioButton
          name="footer_on_last_page_only"
          value="last_page"
          optionTitle={t("Last page only")}
          fontSize={theme.fontSizes.medium}
          width={14}
          height={14}
          color={
            footerOnLastPageOnlyString === "last_page"
              ? theme.primaryTextColor
              : theme.secondaryTextColor
          }
          handleChange={handleFooterConfigRadioChange}
          checked={footerOnLastPageOnlyString === "last_page"}
          marginRight={4}
        />
      </RadioButtonContainer>
      <RadioButtonContainer>
        <RadioButton
          name="is_footer_image"
          value="image"
          optionTitle={t("Image")}
          fontSize={theme.fontSizes.medium}
          width={14}
          height={14}
          color={isImage ? theme.primaryTextColor : theme.secondaryTextColor}
          handleChange={handleFooterTypeRadioChange}
          checked={isImage}
          marginRight={4}
        />
        <RadioButton
          name="is_footer_image"
          value="rich_text"
          optionTitle={t("Rich text")}
          fontSize={theme.fontSizes.medium}
          width={14}
          height={14}
          color={!isImage ? theme.primaryTextColor : theme.secondaryTextColor}
          handleChange={handleFooterTypeRadioChange}
          checked={!isImage}
          marginRight={4}
        />
      </RadioButtonContainer>
      {isImage ? (
        <FilePickerUncontrolled
          methodsOfUseForm={methodsOfUseForm}
          placeHolderText={t("Drag and drop an image or")}
          name="footer_image"
          accept={SUPPORTED_IMG_TYPES as SupportedFileType}
          description={t("Max file size: 50 mb / file")}
          defaultValueFileName={generatedAsset?.footer_document?.name}
          required={false}
        />
      ) : (
        <RichEditorContainer>
          <RichEditor
            value={generatedAsset?.footer_content}
            name={"footer_content"}
            label={t("Footer content")}
            useFormMethods={{ register, formState, setValue, errors }}
          />
        </RichEditorContainer>
      )}
    </>
  );
}
