import { TextField } from "../../../../../../components/TextFields/TextFields";
import { Form } from "../../../../../../layout/FormLayout";
import Axios from "axios";
import React, { useContext } from "react";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import { SaveButtonPrimaryMedium } from "../../shared";
import { endpoints } from "../../../../../../endpoints";
import { Store } from "../../../../../../Store";
import { useFormWrapper } from "../../../../../../util/util";

type FormValues = {
  googleAnalyticsKey: string;
};

export const EditAnalyticsSettings = ({
  googleAnalyticsKey,
  setIsEditingFalse,
}: {
  googleAnalyticsKey: string | null;
  setIsEditingFalse: () => void;
}) => {
  const { storeDispatch, storeState } = useContext(Store);
  const { storefront_id } = storeState;

  const { notifySuccess, notifyError } = useContext(Notifications);

  const { register, errors, formState, handleSubmit } =
    useFormWrapper<FormValues>({
      defaultValues: {
        googleAnalyticsKey: googleAnalyticsKey || "",
      },
    });

  const onSubmit = async (formValues: FormValues) => {
    try {
      const metadataResponse = await Axios.patch(
        endpoints.v1_storefronts_id_metadata(storefront_id),
        {
          google_analytics_key: formValues.googleAnalyticsKey || null,
        }
      );

      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: metadataResponse.data,
      });

      notifySuccess("Changes saved");
      setIsEditingFalse();
    } catch (error) {
      notifyError("There was an error, please try again", { error });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        label="Google Analytics Key"
        name="googleAnalyticsKey"
        theref={register({ required: false })}
        errors={errors}
        formState={formState}
        type="text"
      />
      <SaveButtonPrimaryMedium loading={formState.isSubmitting}>
        Save your changes
      </SaveButtonPrimaryMedium>
    </Form>
  );
};
