import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormWrapper, useStoreState } from "../../util/util";
import { PrimaryButtonSmall } from "../Buttons/Buttons";
import styled from "styled-components/macro";
import { SendIcon, StopIcon } from "../Icons/Icons";
import { TextArea } from "../TextArea/TextArea";
import axios from "axios";
import { Notifications } from "../Notifications/NotificationsContext";
import { match } from "ts-pattern";

const MessageInput = styled.div`
  flex-grow: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 12px;
`;

type RunStatus =
  | "queued"
  | "in_progress"
  | "completed"
  | "cancelled"
  | "failed";

export function SendMessageToChatBot({
  mutateMessages,
  handleMessageInProgress,
  handleSetThreadId,
  fetchLastMessageData,
  inProgress,
  introMessage,
}: {
  mutateMessages: (
    data?: any,
    shouldRevalidate?: boolean | undefined
  ) => Promise<any>;
  handleMessageInProgress: (inProgress: boolean, message?: string) => void;
  handleSetThreadId: React.Dispatch<React.SetStateAction<string | null>>;
  fetchLastMessageData: (threadId: string, runId: string) => Promise<void>;
  inProgress: boolean;
  introMessage: string;
}) {
  const { register, formState, handleSubmit, setValue, getValues, watch } =
    useFormWrapper({});

  const { storefront_id } = useStoreState();
  const [threadId, setThreadId] = useState(
    sessionStorage.getItem(`thread_${storefront_id}`)
  );
  const [inputHeight, setInputHeight] = useState(20);
  const [newMessageText, setNewMessageText] = useState<string>("");
  const [currentRunId, setCurrentRunId] = useState<string | null>(null);
  const inputEl = useRef<HTMLTextAreaElement | null>(null);

  const { notifyError } = useContext(Notifications);

  const messageInputChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setNewMessageText(e.currentTarget.value);
    setValue("message", e.currentTarget.value);
  };

  const onCancel = async () => {
    if (threadId && currentRunId) {
      try {
        await axios.post(
          `/v2/storefronts/${storefront_id}/chatbots/threads/${threadId}/runs/${currentRunId}/cancel`
        );
        handleMessageInProgress(false);
        setCurrentRunId(null);
      } catch (error) {
        notifyError("There was an error cancelling the message generation");
      }
    }
  };

  const pollRunStatus = async (
    threadId: string,
    runId: string
  ): Promise<void> => {
    const pollInterval = 1500;
    const maxAttempts = 20;
    let attempts = 0;

    const poll = async (): Promise<void> => {
      if (attempts >= maxAttempts) {
        notifyError("Message generation timed out");
        handleMessageInProgress(false);
        setCurrentRunId(null);
        return;
      }

      try {
        const response = await axios.get<{ status: RunStatus }>(
          `/v2/storefronts/${storefront_id}/chatbots/threads/${threadId}/runs/${runId}`
        );

        const result = match(response.data.status)
          .with("completed", () => {
            fetchLastMessageData(threadId, runId);
            setCurrentRunId(null);
            return "done";
          })
          .with("cancelled", "failed", () => {
            handleMessageInProgress(false);
            setCurrentRunId(null);
            return "done";
          })
          .with("queued", "in_progress", () => "continue")
          .exhaustive();

        if (result === "done") return;

        attempts++;
        setTimeout(poll, pollInterval);
      } catch (error) {
        notifyError("There was an error checking the message status");
        handleMessageInProgress(false);
        setCurrentRunId(null);
      }
    };

    await poll();
  };

  const onSubmit = async (data: { message: string }) => {
    handleMessageInProgress(true, data.message);
    setValue("message", undefined);
    setNewMessageText("");
    setInputHeight(20);
    if (threadId) {
      try {
        const response = await axios.post(
          `/v2/storefronts/${storefront_id}/chatbots/threads/${threadId}/messages`,
          { content: data.message }
        );
        if (response?.data?.run_id) {
          setCurrentRunId(response.data.run_id);
          pollRunStatus(threadId, response.data.run_id);
        }
      } catch (error) {
        notifyError("There was an error sending your message");
        handleMessageInProgress(false, undefined);
        setNewMessageText(data.message);
        setValue("message", data.message);
      }
    } else {
      try {
        const newThread = await axios.post(
          `/v2/storefronts/${storefront_id}/chatbots/threads`,
          {
            messages: [
              {
                content: introMessage,
                role: "assistant",
              },
              {
                content: data.message,
                role: "user",
              },
            ],
          }
        );
        setThreadId(newThread.data.thread_id);
        handleSetThreadId(newThread.data.thread_id);
        sessionStorage.setItem(
          `thread_${storefront_id}`,
          newThread.data.thread_id
        );
        if (newThread?.data?.run_id) {
          setCurrentRunId(newThread.data.run_id);
          pollRunStatus(newThread.data.thread_id, newThread.data.run_id);
        }
      } catch (error) {
        notifyError("There was an error sending your message");
        handleMessageInProgress(false, undefined);
        setNewMessageText(data.message);
        setValue("message", data.message);
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent): void => {
      // prevent sending empty message with newline when user press enter and there's no text
      if (
        document.activeElement === inputEl.current &&
        e.key === "Enter" &&
        !e.shiftKey &&
        inputEl.current?.value === ""
      ) {
        e.preventDefault();
        setNewMessageText("");
      }
      if (
        document.activeElement === inputEl.current &&
        e.key === "Enter" &&
        !e.shiftKey &&
        inProgress
      ) {
        e.preventDefault();
      }

      // send new message when user press Enter key and the focus is on the Input and there's an input value
      if (
        document.activeElement === inputEl.current &&
        inputEl.current?.value &&
        !inProgress
      ) {
        inputEl.current &&
          setInputHeight(
            inputEl.current?.scrollHeight < 110
              ? inputEl.current?.scrollHeight
              : 115
          );
        if (e.key === "Enter" && !e.shiftKey) {
          if (inputEl.current?.value !== "") {
            e.preventDefault();
            onSubmit({ message: getValues("message") });
          }
        }
        if (e.key === "Enter" && e.shiftKey) {
          setInputHeight(
            inputEl.current?.scrollHeight < 110
              ? inputEl.current?.scrollHeight
              : 115
          );
        }
      }
    };
    if (inputEl.current) {
      window.addEventListener("keydown", handleKeyPress, false);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyPress, false);
    };
  });

  useEffect(() => {
    register({ name: "message", required: true });
  }, [register]);

  const message = watch("message");
  const hasMessage = message?.length > 1;

  return (
    <>
      <MessageInput>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{
            position: "relative",
            display: "flex",
            width: "100%",
            alignItems: "center",
          }}
        >
          <TextArea
            name={"message"}
            label={"Write a message..."}
            theref={inputEl}
            min={1}
            formState={formState}
            onChange={messageInputChange}
            autoHeight={true}
            required={true}
            style={{
              height: `${inputHeight}px`,
              resize: "none",
              width: "100%",
            }}
            rows={1}
            value={newMessageText}
          />

          <ButtonContainer>
            {inProgress ? (
              <PrimaryButtonSmall
                style={{
                  width: "51px",
                  height: "51px",
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={onCancel}
                type="button"
                disabled={!currentRunId}
              >
                <StopIcon width={20} height={20} />
              </PrimaryButtonSmall>
            ) : (
              <PrimaryButtonSmall
                style={{
                  width: "51px",
                  height: "51px",
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleSubmit(onSubmit)}
                disabled={!hasMessage}
              >
                <SendIcon />
              </PrimaryButtonSmall>
            )}
          </ButtonContainer>
        </form>
      </MessageInput>
    </>
  );
}
