import type { ChangeEvent, MouseEvent } from "react";
import React, { useEffect, useMemo, useState } from "react";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { useDebounce } from "../../../../../../util/hooks";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { defaultHandleSort, useStoreState } from "../../../../../../util/util";
import { useRoutePath } from "../../../../../../util/Routing";
import type { SortingRule } from "react-table";
import useSWR from "swr";
import { endpoints } from "../../../../../../endpoints";
import {
  ContentWrapper,
  PageWrapper,
} from "../../../../../../layout/portalPageLayout";
import { AttributesNav } from "../../components/AttributesNav";
import { Flex, Flex1, Flex2 } from "../../../../../../layout/FormLayout";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../../../components/SlideOut/SlideOut";
import { DropDown } from "../../../../../../components/DropDown/DropDown";
import { Table } from "../../../../../../components/Table/Table";
import { Pagination } from "../../../../../../components/Pagination/Pagination";
import { DropdownContainer } from "../../../../../../components/Layout/Layout";
import type { CollectionPaginatedOutputSchema } from "../../../../../../types/types.PIM";
import { PrimaryButtonWithPlusIcon } from "../../../../../../components/Buttons/Buttons";
import { SellerAdminAttributesCollectionForm } from "./SellerAdminAttributesCollectionForm";
import type { AxiosError } from "axios";
import { useAuthContext } from "../../../../../../components/Auth";
import { NameWithSystemDefaultIcon } from "../../components/PIM.components.util";

export const SellerAdminAttributesCollectionsList = () => {
  const [tableData, setTableData] = useState<
    {
      id: string;
      name: { name: string; id: string; is_system: boolean };
      attribute_count: number;
      display_name: string;
    }[]
  >([]);
  const perPageItems = [10, 20, 50];
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });
  const [perPage, setPerPage] = useState(query?.perPage ?? 10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [showCreateSlideOut, setCreateSlideOut] = useState(false);
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });
  const history = useHistory();
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const { hasPermission } = useAuthContext();
  const tableColumns = useMemo(
    () => [
      {
        Header: t("Collection Name"),
        accessor: "name",
        Cell: ({
          value,
        }: {
          value: { name: string; is_system: boolean; id: string };
        }) => (
          <NameWithSystemDefaultIcon
            name={value.name}
            id={value.id}
            is_system={value.is_system}
          />
        ),
      },
      {
        Header: t("Display Name"),
        accessor: "display_name",
      },
      {
        Header: t("No. of Attributes"),
        accessor: "attribute_count",
      },
    ],
    [t]
  );

  const constructQuery = ({
    baseUrl,
    query,
  }: {
    baseUrl: string;
    query: string;
  }) => {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}`
    );
    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    return baseUrl + paramsWithOffset;
  };

  const {
    data: collectionsList,
    error: collectionsError,
    mutate,
  } = useSWR<CollectionPaginatedOutputSchema, AxiosError>(
    constructQuery({
      baseUrl: `${endpoints.v2_tenants_id_pim_collections(tenant_id)}?`,
      query: debouncedSearchQuery,
    })
  );

  const isLoading = !collectionsList && !collectionsError;

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const handleRowClick = (e: MouseEvent) => {
    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(offset));
    params.append("perPage", String(perPage));
    // TODO: update to the route
    history.push(
      `${adminPath}/pim/attributes/collections/${e.currentTarget.id}?${params}`
    );
  };

  const closeCreateSlideOut = () => setCreateSlideOut(false);

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const onCollectionCreationSuccess = () => {
    mutate();
    closeCreateSlideOut();
  };

  useEffect(() => {
    const handleData = ({
      data,
      pagination,
    }: CollectionPaginatedOutputSchema) => {
      setTableData(
        data.map(({ id, name, attribute_count, display_name, is_system }) => ({
          id,
          name: { name: name, id: id, is_system: is_system },
          attribute_count,
          display_name: display_name ? t([display_name]) : "",
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (collectionsList) {
      const { data: collections, pagination } = collectionsList;
      handleData({ data: collections, pagination });
    }
  }, [collectionsList, perPage, t]);

  useEffect(() => {
    setQuery({ q: debouncedSearchQuery || undefined });
  }, [setQuery, debouncedSearchQuery]);

  return (
    <PageWrapper>
      <AttributesNav tabName={"Collections"} />
      <Flex style={{ marginBottom: "24px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by Collection name or Attribute name")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
          />
        </Flex2>
        {hasPermission("modify_collections") && (
          <Flex1 style={{ textAlign: "right" }}>
            <PrimaryButtonWithPlusIcon
              onClick={() => setCreateSlideOut(true)}
              data-alignright
            >
              <span style={{ fontSize: "15px" }}>{t("Create")}</span>
            </PrimaryButtonWithPlusIcon>
          </Flex1>
        )}
      </Flex>
      <SlideOut closeFlyout={closeCreateSlideOut} show={showCreateSlideOut}>
        <SellerAdminAttributesCollectionForm
          onSuccess={onCollectionCreationSuccess}
        />
      </SlideOut>
      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t("Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
      </DropdownContainer>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          isLoading={isLoading}
          error={collectionsError}
          data={tableData}
          rowClick={handleRowClick}
          handleSort={handleSort}
          lastChildleftAlign
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};
