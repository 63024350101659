import type { FC } from "react";
import React, { useContext, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";
import useSWR from "swr";
import type { IStore } from "./Store";
import { Store } from "./Store";
import type { APIMetaData, StorefrontMetaData } from "./types/types";
import { NotFoundPage } from "./pages/Misc/NotFoundPage";
import type { AxiosError } from "axios";
import { makeNewTheme } from "./util/themeUtils";
import { AG3Theme } from "./theme";
import { Loader } from "./components/Loader/Loader";
import { Auth } from "./components/Auth";
import { endpoints } from "./endpoints";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const tenantIdIsDefined = (id: string): boolean =>
  typeof id === "string" && id.length > 0;

interface PageProviderProps {
  AllowedRoutes: () => JSX.Element;
}

export const PageProvider: FC<PageProviderProps> = ({ AllowedRoutes }) => {
  const { storeState, storeDispatch } = useContext<IStore>(Store);
  const { role } = useContext(Auth);

  // Should always be "store".
  const pathPart = document.location.pathname.split("/")[1];

  // useParams does not work outside of a <Route> so we useRouteMatch.
  const routeMatch = useRouteMatch<{ storefrontSlug: string }>(
    `/${pathPart}/:storefrontSlug/`
  );
  const storefrontSlug = routeMatch?.params.storefrontSlug;

  useEffect(() => {
    // Update the stored tenant slug if needed.
    if (storefrontSlug && storeState.slug !== storefrontSlug) {
      storeDispatch({ type: "SET_STOREFRONT_SLUG", payload: storefrontSlug });
    }
  }, [storeState.slug, storefrontSlug, storeDispatch]);

  const { data: apiMetadata } = useSWR<
    APIMetaData,
    AxiosError<StorefrontMetaData>
  >(`/v1/metadata`, {
    revalidateOnFocus: true, // Regular revalidation of metadata on focus is important to prevent stale metadata info
  });

  const { data: storefrontMetadata, error: storefrontMetadataError } = useSWR<
    StorefrontMetaData,
    AxiosError<StorefrontMetaData>
  >(
    storefrontSlug
      ? endpoints.v1_storefronts_id_metadata(storefrontSlug)
      : null,
    { revalidateOnFocus: true } // Regular revalidation of metadata on focus is important to prevent stale metadata info
  );

  useEffect(() => {
    // TODO: To do the following we need to handle invalidating the cached data.
    // if (storefrontMetadata && !storeState.storefront_metadata) {
    if (storefrontMetadata) {
      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: storefrontMetadata,
      });
    }
  }, [storeDispatch, storefrontMetadata]);

  useEffect(() => {
    if (apiMetadata) {
      storeDispatch({
        type: "SET_API_METADATA",
        payload: apiMetadata,
      });
    }
  }, [storeDispatch, apiMetadata]);

  useEffect(() => {
    if (storefrontMetadataError) {
      storeDispatch({
        type: "SET_THEME",
        payload: AG3Theme,
      });
    }
  }, [storeDispatch, storefrontMetadataError]);

  useEffect(() => {
    /* 
      Handle the case where the theme fails to load or loads very slowly. 
      During pageload for the first two seconds we show nothing (Full page
      spinner to later be added after 1s) and if the custom theme loads we 
      render the page with it. Otherwise we fall back to the agilis theme.

      In cases where the theme does eventually load after > 6s there will
      be a flash of the agilis theme before snapping back to the custom
      theme.

      This is a good balance to strike because it would be bad for
      the whole app to have to wait on this single API call.
    */
    const timer = setTimeout(() => {
      if (!storeState.theme) {
        storeDispatch({
          type: "SET_THEME",
          payload: AG3Theme,
        });
      }
    }, 6000);
    if (
      storefrontMetadata &&
      (!storeState.theme || storeState.theme.theme_name === AG3Theme.theme_name)
    ) {
      storeDispatch({
        type: "SET_THEME",
        payload: makeNewTheme(
          storefrontMetadata.theme_object.branding,
          storeState.slug ?? storeState.tenant_id
        ),
      });
    }

    return () => clearTimeout(timer);
  }, [
    storeDispatch,
    storeState.slug,
    storeState.tenant_id,
    storeState.theme,
    storefrontMetadata,
  ]);

  if (storefrontMetadataError?.response?.status === 404) {
    // This handles:
    // 1. when the tenant slug is not valid, causing an error when fetching the
    //    storefront metadata
    // 2. any other errors when fetching the storefront metadata
    return <NotFoundPage />;
  }

  if (!storeState.theme) return null;

  if (!tenantIdIsDefined(storeState.tenant_id)) {
    console.error(new Error("Tenant ID is not defined"));
    // TODO - show a more specific error page for this case
    return <NotFoundPage />;
  }

  return (
    <ThemeProvider theme={storeState.theme}>
      {!role ? (
        <Loader isLoading />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <AllowedRoutes />
        </DndProvider>
      )}
    </ThemeProvider>
  );
};
