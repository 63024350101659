import { Form, FormGrid2x2 } from "../../../../layout/FormLayout";
import { PageTitle } from "../../../../layout/portalPageLayout";
import React, { useContext, useEffect, useState } from "react";
import { HeaderLeft } from "../../../../components/Layout/Layout";
import {
  createDownloadLink,
  makeUrlWithParams,
  useFormWrapper,
  useStoreState,
} from "../../../../util/util";
import * as zod from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { PrimaryButtonFitContainer } from "../../../../components/Buttons/Buttons";
import { endpoints } from "../../../../endpoints";
import Axios from "axios";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import type { StorefrontPurchaseOrderReportField } from "../../../../types/types";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

type TransactionType =
  | "order"
  | "sample"
  | "quote"
  | "lead"
  | "customer"
  | "distributor";

export const DownloadReport = ({
  closeSlideout,
  transactionType,
}: {
  closeSlideout: () => void;
  transactionType: TransactionType;
}) => {
  const { storefront_id } = useStoreState();
  const { notifyError } = useContext(Notifications);

  const [submitting, setSubmitting] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [downloadLink, setDownloadLink] =
    useState<HTMLAnchorElement | undefined>(undefined);

  const FormSchema = zod.object({
    order_date_start: zod.string().optional(),
    order_date_end: zod.string().optional(),
    shipment_eta_start: zod.string().optional(),
    shipment_eta_end: zod.string().optional(),
  });
  type FormInputs = zod.infer<typeof FormSchema>;

  const methodsOfUseForm = useFormWrapper<FormInputs>({
    resolver: zodResolver(FormSchema),
  });

  const { handleSubmit, watch } = methodsOfUseForm;

  const order_date_start = watch("order_date_start");
  const shipment_eta_start = watch("shipment_eta_start");
  const order_date_end = watch("order_date_end");
  const shipment_eta_end = watch("shipment_eta_end");

  useEffect(() => {
    setIsSubmitDisabled(!order_date_start && !shipment_eta_start);
  }, [order_date_start, shipment_eta_start]);

  const { t } = useTranslation();

  const disableAllCalendarDays = (day: moment.Moment) =>
    day.isBefore(undefined) || day.isAfter(undefined);

  const getTransactionUrlFromType = (
    transactionType: TransactionType,
    inputs: FormInputs
  ): string =>
    match(transactionType)
      .with("sample", () =>
        makeUrlWithParams(
          endpoints.v1_storefronts_id_sample_reports_fileType(
            storefront_id,
            "csv"
          ),
          { ...inputs }
        )
      )
      .with("order", () =>
        makeUrlWithParams(
          endpoints.v1_storefronts_id_orders_reports_fileType(
            storefront_id,
            "csv"
          ),
          { ...inputs }
        )
      )
      .with("quote", () =>
        makeUrlWithParams(
          endpoints.v1_storefronts_id_quotes_reports_fileType(
            storefront_id,
            "csv"
          ),
          { ...inputs }
        )
      )
      .with("lead", () =>
        makeUrlWithParams(
          endpoints.v1_storefronts_id_lead_reports_fileType(
            storefront_id,
            "csv"
          ),
          { ...inputs }
        )
      )
      .with("customer", () =>
        makeUrlWithParams(
          endpoints.v1_storefronts_id_customer_reports_fileType(
            storefront_id,
            "csv"
          ),
          { ...inputs }
        )
      )
      .with("distributor", () =>
        makeUrlWithParams(
          endpoints.v1_storefronts_id_distributor_reports_fileType(
            storefront_id,
            "csv"
          ),
          { ...inputs }
        )
      )
      .exhaustive();

  const getPageTitleFromTransactionType = (
    transactionType: TransactionType
  ): string =>
    match(transactionType)
      .with("order", () => t("Order Report"))
      .with("sample", () => t("Sample Report"))
      .with("quote", () => t("Quote Report"))
      .with("lead", () => t("Lead Reports"))
      .with("customer", () => t("Customer Reports"))
      .with("distributor", () => t("Distributor Reports"))
      .exhaustive();

  const getFileNameFromTransactionType = (
    transactionType: TransactionType
  ): string =>
    match(transactionType)
      .with("order", () => "Order_Report.csv")
      .with("sample", () => "Sample_Report.csv")
      .with("quote", () => "Quote_Report.csv")
      .with("lead", () => "Lead_Report.csv")
      .with("customer", () => "Customer_Report.csv")
      .with("distributor", () => "Distributor_Report.csv")
      .exhaustive();

  const getDatePickerPlaceholderFromTransactionType = (
    transactionType: TransactionType
  ): string =>
    match(transactionType)
      .with("order", () => t("Order Placed"))
      .with("sample", () => t("Sample Placed"))
      .with("quote", () => t("Quote Placed"))
      .with("lead", () => t("Lead Placed"))
      .with("customer", () => t("Date Created"))
      .with("distributor", () => t("Date Created"))
      .exhaustive();

  const handleStartOutsideRange = (
    day: moment.Moment,
    endDate: string | undefined,
    exceedPresentDay = false
  ) =>
    endDate
      ? day.isAfter(endDate, "day") ||
        (exceedPresentDay ? false : day.isAfter(moment(), "day"))
      : exceedPresentDay
      ? false
      : day.isAfter(moment(), "day");

  const handleEndOutsideRange = (
    day: moment.Moment,
    startDate: string | undefined,
    exceedPresentDay = false
  ) =>
    startDate
      ? day.isBefore(startDate, "day") ||
        (exceedPresentDay ? false : day.isAfter(moment(), "day"))
      : disableAllCalendarDays(day);

  const setEndDate = (date: string | undefined): string | undefined => {
    if (date) {
      return moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS");
    }
    return undefined;
  };

  const formatFormDate = (
    input: FormInputs
  ): FormInputs & { timezone_iana_zoneinfo: string } => {
    return {
      order_date_start: input.order_date_start
        ? moment(input.order_date_start)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS")
        : undefined,
      order_date_end: input.order_date_end
        ? moment(input.order_date_end)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS")
        : setEndDate(input.order_date_start),
      shipment_eta_start: input.shipment_eta_start
        ? moment(input.shipment_eta_start)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS")
        : undefined,
      shipment_eta_end: input.shipment_eta_end
        ? moment(input.shipment_eta_end)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS")
        : undefined,
      timezone_iana_zoneinfo: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  };

  async function getStringDataFromTransactionType(
    transactionType: TransactionType
  ) {
    const endpoint: string = match(transactionType)
      .with("sample", () =>
        endpoints.v1_storefronts_id_sample_reports_fields(storefront_id)
      )
      .with("quote", () =>
        endpoints.v1_storefronts_id_quotes_reports_fields(storefront_id)
      )
      .with("order", () =>
        endpoints.v1_storefronts_id_orders_reports_fields(storefront_id)
      )
      .with("lead", () =>
        endpoints.v1_storefronts_id_lead_reports_fields(storefront_id)
      )
      .with("customer", () =>
        endpoints.v1_storefronts_id_customer_reports_fields(storefront_id)
      )
      .with("distributor", () =>
        endpoints.v1_storefronts_id_distributor_reports_fields(storefront_id)
      )
      .exhaustive();

    const { data: fieldResponse } = await Axios.get<
      StorefrontPurchaseOrderReportField[]
    >(endpoint);
    return fieldResponse.map((field) => field.display_name).join(",");
  }

  async function fetchDataAndGenerateReport(
    url: string,
    transactionType: TransactionType,
    fileName: string
  ) {
    try {
      const { data } = await Axios.get<string | object>(url, {
        responseType: "text",
      });
      let stringData = "";
      if (typeof data === "string") {
        stringData = data;
      } else {
        stringData = await getStringDataFromTransactionType(transactionType);
      }
      const link = createDownloadLink({
        name: fileName,
        type: "text/csv",
        data: stringData,
      });
      setDownloadLink(link);
      setSubmitting(false);
      closeSlideout();
    } catch (e) {
      notifyError(t("Could not fetch orders"));
      setSubmitting(false);
      closeSlideout();
    }
  }

  async function fetchDataAndGenerateLeadReport(
    url: string,
    transactionType: TransactionType,
    fileName: string
  ) {
    try {
      const { data } = await Axios.get<string | object>(url, {
        responseType: "text",
      });
      let stringData = "";
      if (typeof data === "string") {
        stringData = data;
      } else {
        stringData = await getStringDataFromTransactionType(transactionType);
      }
      const link = createDownloadLink({
        name: fileName,
        type: "text/csv",
        data: stringData,
      });
      setDownloadLink(link);
      // setSubmitting(false);
      // closeSlideout();
    } catch (e) {
      notifyError(t("Could not fetch orders"));
      setSubmitting(false);
      closeSlideout();
    }
  }

  const onSubmit = async (inputs: FormInputs) => {
    setSubmitting(true);
    inputs = formatFormDate(inputs);
    const url = getTransactionUrlFromType(transactionType, inputs);
    if (transactionType === "lead") {
      await fetchDataAndGenerateLeadReport(
        `${url}&lead_type=quote_request`,
        transactionType,
        "Quote_Lead_Report.csv"
      );
      await fetchDataAndGenerateLeadReport(
        `${url}&lead_type=sample_request`,
        transactionType,
        "Sample_Lead_Report.csv"
      );
      await fetchDataAndGenerateLeadReport(
        `${url}&lead_type=registration`,
        transactionType,
        "Registration_Lead_Report.csv"
      );
      await fetchDataAndGenerateLeadReport(
        `${url}&lead_type=contact_form`,
        transactionType,
        "Contact_Lead_Report.csv"
      );
      try {
        setSubmitting(false);
        closeSlideout();
      } catch (error) {
        notifyError(t("Could not fetch orders"));
        setSubmitting(false);
        closeSlideout();
      }
    } else {
      fetchDataAndGenerateReport(
        url,
        transactionType,
        getFileNameFromTransactionType(transactionType)
      );
    }
  };

  useEffect(() => {
    if (downloadLink) {
      // https://github.com/eligrey/FileSaver.js/blob/b5e61ec88969461ce0504658af07c2b56650ee8c/src/FileSaver.js#L58
      try {
        downloadLink.dispatchEvent(new MouseEvent("click"));
      } catch (_e) {
        // some browsers do not support a.click event
        const evt = document.createEvent("MouseEvents");
        // use a deprecated api to fix this
        //@ts-ignore
        evt.initMouseEvent(
          "click",
          true,
          true,
          window,
          0,
          0,
          0,
          80,
          20,
          false,
          false,
          false,
          false,
          0,
          null
        );
        downloadLink.dispatchEvent(evt);
      }
      const timeoutId = setTimeout(
        () => URL.revokeObjectURL(downloadLink.href),
        4e4
      );
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      return () => {};
    }
  }, [downloadLink]);

  return (
    <>
      <HeaderLeft>
        <PageTitle>
          {t(getPageTitleFromTransactionType(transactionType))}
        </PageTitle>
      </HeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormGrid2x2>
          <DatePicker
            label={t(
              getDatePickerPlaceholderFromTransactionType(transactionType)
            )}
            name={"order_date_start"}
            required={false}
            methodsOfUseForm={methodsOfUseForm}
            placeholder={t("Start Date")}
            isOutsideRange={(day) =>
              handleStartOutsideRange(day, order_date_end)
            }
          />
          <DatePicker
            label={t(
              getDatePickerPlaceholderFromTransactionType(transactionType)
            )}
            name={"order_date_end"}
            required={false}
            methodsOfUseForm={methodsOfUseForm}
            placeholder={t("End Date")}
            isOutsideRange={(day) =>
              handleEndOutsideRange(day, order_date_start)
            }
          />
          {(transactionType === "sample" || transactionType === "order") && (
            <>
              <DatePicker
                label={t("Shipment ETA")}
                name={"shipment_eta_start"}
                required={false}
                methodsOfUseForm={methodsOfUseForm}
                placeholder={t("Start Date")}
                isOutsideRange={(day) =>
                  handleStartOutsideRange(day, shipment_eta_end, true)
                }
              />
              <DatePicker
                label={t("Shipment ETA")}
                name={"shipment_eta_end"}
                required={false}
                methodsOfUseForm={methodsOfUseForm}
                placeholder={t("End Date")}
                isOutsideRange={(day) =>
                  handleEndOutsideRange(day, shipment_eta_start, true)
                }
              />
            </>
          )}
        </FormGrid2x2>
        <PrimaryButtonFitContainer
          type="submit"
          disabled={isSubmitDisabled}
          loading={submitting}
        >
          {t("Download Report")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
