import React from "react";
import { CenterContainer } from "../../layout/publicPageLayout";
import { H3, Title } from "../../components/Typography/Typography";
import styled from "styled-components";

/**
 * Shown for routes/pages that are not supported for this edition of the
 * storefront (e.g. "starter", "business", "enterprise").
 *
 * NOTE: Until we officially release the full starter edition, this is being
 * used for WIP routes/pages, like for buyer accounts.  Once we release the
 * full starter edition, we will adjust the contents accordingly.
 */

const AgilisEmail = styled.a`
  color: ${(props) => props.theme.primaryLinkColor};
  font-weight: bold;
  display: inline-block;
  margin-left: 5px;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export function NotSupportedForEdition() {
  return (
    <CenterContainer>
      <Title>Upgrade Your Account to Access This Page</Title>
      <H3>
        Contact us to upgrade your account to <b>Business Edition</b> to access
        this page.
      </H3>
      <AgilisEmail href="mailto:sales@agilischemicals.com">
        sales@agilischemicals.com
      </AgilisEmail>
    </CenterContainer>
  );
}
