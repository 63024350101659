import { TextButton, GoBackButton } from "../../../components/Buttons/Buttons";
import { Loader } from "../../../components/Loader/Loader";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { Title } from "../../../components/Typography/Typography";
import { useDebounce } from "../../../util/hooks";
import type {
  IGroupedPriceTier,
  Product,
  ProductSKU,
} from "../../../types/types";
import Axios from "axios";
import type { ChangeEvent } from "react";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import useSWR, { mutate } from "swr";
import { useQueryParams, StringParam, NumberParam } from "use-query-params";
import { ErrorPlaceholder } from "../../../components/Error";
import type { ISubmitQuoteItemForm } from "../../../components/quoteCart/BuyerQuoteItemForm";
import { BuyerQuoteItemFormForCart } from "../../../components/quoteCart/BuyerQuoteItemFormForCart";
import { endpoints } from "../../../endpoints";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import { makeUrlWithParams, useStoreState } from "../../../util/util";
import { ViewOrEditPriceTierGroup } from "../../SharedPages/CustomerDetailPage/PriceTiersTab/ViewOrEditPriceTierGroup/ViewOrEditPriceTierGroup";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { Notifications } from "../../../components/Notifications/NotificationsContext";

const NoData = styled.div`
  width: 100%;
  padding: 100px 10px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.secondaryTextColor};
  background: ${({ theme }) => theme.primaryBG};
`;

const SearchBarRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const localStrings = (t: TFunction) => ({
  searchBarPlaceholder: t("Search by Product or Destination"),
});

/**
 * A custom object with a grouped price tier plus some extra information need
 * for prefilling the fields in the cart form.
 */
export type PriceTierIntersection = IGroupedPriceTier & {
  product_sku: ProductSKU;
  minimum_uom_quantity: string;
};

export const BuyerMyProductsPriceTiers = providePrivatePageProps(({ user }) => {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
  });
  const { storefront_id } = useStoreState();
  const { product_id } = useParams<{ product_id: string }>();

  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [showForm, setShowForm] = useState(false);
  const history = useHistory();
  const { accountPath } = useRoutePath();
  const [selectedTier, setSelectedTier] = useState<PriceTierIntersection>();
  const { notifyError } = useContext(Notifications);
  const { t } = useTranslation();

  const {
    data: tiers,
    error: tiersError,
    mutate: mutateTiers,
  } = useSWR<IGroupedPriceTier[]>(
    makeUrlWithParams(endpoints.v1_priceTiers_grouped(), {
      seller_id: user.seller_id,
      buyer_id: user?.tenant_id,
      q: debouncedSearchQuery || null,
      product_id,
    })
  );

  const { data: product, error: productError } = useSWR<Product>(
    endpoints.v2_storefronts_id_pim_products_id(storefront_id, product_id)
  );

  const isLoadingProduct = !product && !productError;

  const handleCloseModal = () => {
    setShowForm(false);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
  };

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  if (tiersError) {
    return (
      <ErrorPlaceholder
        message={t("There was an error fetching the price tiers")}
      />
    );
  }

  const submitQuoteItemForm: ISubmitQuoteItemForm = async (unifiedCartArg) => {
    try {
      const endpoint = `/v1/storefronts/${storefront_id}/unified-cart`;
      await Axios.post(endpoint, unifiedCartArg);
      setShowForm(false);
      mutate(endpoint);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SlideOut show={showForm} closeFlyout={handleCloseModal}>
        {product && selectedTier && !productError ? (
          // If the user is on this page at all then there are no items in
          // the cart, so we allow them to make edits in the item form.
          <BuyerQuoteItemFormForCart
            product={product}
            submitQuoteItemForm={submitQuoteItemForm}
            buyerUser={user}
            showProceedToCheckoutButton={true}
            productSku={selectedTier.product_sku}
            shippingAddress={selectedTier.destination}
            paymentTerm={selectedTier.payment_term}
            deliveryTerm={selectedTier.delivery_term}
            allowEditingTransactionValues={true}
            currencyCode={selectedTier.currency}
          />
        ) : productError ? (
          <ErrorPlaceholder
            message={t("there was an error fetching the product")}
          />
        ) : (
          <Loader isLoading={isLoadingProduct} />
        )}
      </SlideOut>
      <TextButton
        onClick={() =>
          history.push(
            makeUrlWithParams(`${accountPath}/my-products`, {
              offset: query.offset,
              q: query.q,
            })
          )
        }
      >
        <GoBackButton text={t("My Products")} />
      </TextButton>
      {product && <Title>{product.name}</Title>}
      <SearchBarRow>
        <SearchBar
          query={searchQuery}
          placeHolder={localStrings(t).searchBarPlaceholder}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
      </SearchBarRow>
      {tiers &&
        tiers.length > 0 &&
        tiers.map((tierGroup, index) => {
          return (
            <ViewOrEditPriceTierGroup
              tierGroup={tierGroup}
              index={index}
              mutateTiers={mutateTiers}
              key={index}
              isEditable={false}
              addToCart={(row) => {
                const { status_code } = productError?.response?.data ?? {
                  status_code: undefined,
                };
                if (status_code && status_code === "404") {
                  notifyError(t("This product is no longer available"));
                } else {
                  setSelectedTier({ ...tierGroup, ...row });
                  setShowForm(true);
                }
              }}
            />
          );
        })}
      {tiers && tiers.length === 0 && (
        <NoData>{t("No Price Tiers found.")}</NoData>
      )}
    </>
  );
});
