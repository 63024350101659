import React from "react";
import styled from "styled-components/macro";
import { screenSize } from "../../theme";
import { useTranslation } from "react-i18next";

const Wizard = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0px;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px 50px 40px;
  box-sizing: border-box;
  @media ${screenSize.small} {
    padding: 10px 15px 40px;
  }
`;

const Point = styled.div`
  border-radius: 16.5px;
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.brandColor};
  border: solid 1px ${({ theme }) => theme.brandColor};
  position: relative;
  &::after {
    content: attr(data-label);
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 11px;
    color: ${({ theme }) => theme.primaryTextColor};
    text-align: center;
    display: block;
    min-width: 120px;
    @media ${screenSize.medium} {
      min-width: 50px;
    }
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
`;

const Step = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  &::before {
    content: " ";
    width: 100%;
    background: ${({ theme }) => theme.brandColor};
    position: absolute;
    left: 0;
    top: 8px;
    height: 2px;
  }
  &[data-current="true"],
  &[data-current="true"] ~ * {
    &::before {
      content: " ";
      width: 100%;
      background: ${({ theme }) => theme.primaryBorder};
    }
    & > div {
      background-color: ${({ theme }) => theme.primaryBG};
      border: solid 1px ${({ theme }) => theme.primaryBorder};
    }
  }
  &[data-current="true"] > div {
    background-color: ${({ theme }) => theme.secondaryButtonBG};
    border: solid 1px ${({ theme }) => theme.selectedBorder};
  }
  &[data-current="true"]:last-child > div {
    background-color: ${({ theme }) => theme.primaryButtonBG};
    border: solid 1px ${({ theme }) => theme.brandColor};
  }
  &:last-child {
    flex: auto;
    max-width: 20px;
    &::before {
      display: none;
    }
  }
`;

interface IWizardPath {
  labels: string[];
  currentPoint: number;
}

export function WizardPath({ labels, currentPoint }: IWizardPath) {
  const { t } = useTranslation();
  return (
    <Wizard>
      {labels.map((label, index) => (
        <Step key={index} data-current={currentPoint === index}>
          <Point title={t([label])} data-label={label} />
        </Step>
      ))}
    </Wizard>
  );
}
