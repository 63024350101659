import { Controller } from "react-hook-form";
import type { MethodsOfUseForm } from "../../types/types";
import { strings } from "../../util/strings";
import ReCAPTCHA from "react-google-recaptcha";
import { FormInputErrorText } from "../Typography/Typography";
import { useRef } from "react";
import { useStoreState } from "../../util/util";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import * as zod from "zod";

export const captcha_yup_validation = (t: TFunction) => ({
  captcha_token: yup.string().min(1, strings(t).thisIsARequiredField),
});

export const captcha_zod_validation = (t: TFunction) => ({
  captcha_token: zod.string().min(1, strings(t).thisIsARequiredField),
});

export const ControlledRecaptcha = ({
  methodsOfUseForm,
}: {
  methodsOfUseForm: MethodsOfUseForm;
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const {
    api_metadata: { captcha_site_key },
  } = useStoreState();
  const { t } = useTranslation();
  const { clearErrors, control, errors, setError, setValue } = methodsOfUseForm;

  const handleCaptchaChange = (token: string | null) => {
    setValue("captcha_token", token);
    if (token) {
      clearErrors("captcha_token");
    } else {
      setError("captcha_token", {
        type: "min",
        message: strings(t).thisIsARequiredField,
      });
    }
  };

  return (
    <Controller
      name="captcha_token"
      control={control}
      defaultValue={""}
      rules={{ required: strings(t).thisIsARequiredField }}
      render={() => (
        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={captcha_site_key}
            onChange={(token) => {
              handleCaptchaChange(token);
            }}
          />
          {errors.captcha_token && (
            <FormInputErrorText className="errorMessage">
              {errors.captcha_token.message}
            </FormInputErrorText>
          )}
        </div>
      )}
    />
  );
};
