import type { AxiosError } from "axios";
import type { Dispatch, FunctionComponent, SetStateAction } from "react";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR, { mutate } from "swr";
import { useAuthContext } from "../../../components/Auth";
import {
  PrimaryButtonLarge,
  SecondaryButtonLarge,
} from "../../../components/Buttons/Buttons";
import { ErrorPlaceholder } from "../../../components/Error";
import type { ISubmitQuoteItemForm } from "../../../components/quoteCart/BuyerQuoteItemForm";
import { BuyerQuoteItemFormForCart } from "../../../components/quoteCart/BuyerQuoteItemFormForCart";
import { CartContext } from "../../../components/quoteCart/CartContext";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { endpoints } from "../../../endpoints";
import type {
  IQuotesAPIResponse,
  Product,
  SampleRequestPaginatedOutput,
  UnifiedCartItemPatchArgs,
} from "../../../types/types";
import type { PIMProduct } from "../../../types/types.PIM";
import { makeUrlWithParams, useStoreState } from "../../../util/util";
import { BuyerAddToSampleRequestForm } from "../../Buyer/BuyerAddToSampleRequestForm/BuyerAddToSampleRequestForm";
import Axios from "axios";
import { AddToSampleRequestForm } from "../AddToSampleRequestForm/AddToSampleRequestForm";
import { GuestQuoteItemForm } from "../../../components/quoteCart/GuestQuoteItemForm";
import styled from "styled-components/macro";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";
import type { Lead } from "../../../components/quoteCart/cartUtils";
import ReactTooltip from "react-tooltip";

const TransactionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  button:first-child {
    margin-right: 15px;
  }
`;

export function ProductDetailsTransactionsSection({
  product,
  setbackToProductList,
}: {
  product: PIMProduct;
  setbackToProductList?: Dispatch<SetStateAction<boolean>>;
}) {
  const { user, roleIsSomeKindOfBuyer, roleIsGuest, roleIsSomeKindOfSeller } =
    useAuthContext();

  const {
    storefront_id,
    storefront_metadata: {
      quote_requests_visibility,
      sample_requests_visibility,
    },
  } = useStoreState();

  const [query, setQuery] = useQueryParams({
    quote: BooleanParam,
    ulp_id: StringParam,
  });

  const [showSampleRequestForm, setShowSampleRequestForm] = useState(false);
  const [getAQuoteActive, setGetAQuoteActive] = useState(!!query.quote);

  const { t } = useTranslation();

  const { cartId, ulpId, setUlpId, sampleCartID } = useContext(CartContext);
  const { data: cart, error: cartError } = useSWR<Lead, AxiosError>(
    roleIsGuest && cartId ? `/v1/cart/${cartId}` : null
  );

  // There should only ever be one `status=new` quote request for a given
  // user (the one for the items in the cart). Once the cart is submitted
  // the status changes.  If for some reason the database gets into a bad
  // state and there is more than one, we will get the most recent one,
  // which is the one we want.
  const QUOTES_ENDPOINT_STRING = `/v1/storefronts/${storefront_id}/quotes?status=new&limit=1`;

  const {
    data: quotesResponse,
    error: quotesResponseError,
    mutate: mutateQuotes,
  } = useSWR<IQuotesAPIResponse, AxiosError>(
    roleIsSomeKindOfBuyer ? QUOTES_ENDPOINT_STRING : null
  );

  const quoteRequest = quotesResponse?.data[0];

  const { data: buyerSampleRequestCartResponse } =
    useSWR<SampleRequestPaginatedOutput>(
      roleIsSomeKindOfBuyer
        ? makeUrlWithParams(
            endpoints.v1_storefronts_id_sampleRequests(storefront_id),
            {
              source: "logged_in",
              status: "new",
              limit: "1",
            }
          )
        : null
    );

  const showQuoteBtn =
    quote_requests_visibility === "enabled" ||
    quote_requests_visibility === "disabled";
  const showSampleBtn =
    sample_requests_visibility === "enabled" ||
    sample_requests_visibility === "disabled";

  // Only one type of cart is possible at once, and its down to the frontend to
  // prevent impossible states here because there is no user. Potentially in the
  // future we can associate the cart with ga uuid so that the backend can
  // create a check for this.
  const sampleCartInProgress = !!sampleCartID;
  const leadCartInProgress = !!cartId;

  const buyerSampleRequestCart = buyerSampleRequestCartResponse?.data[0];

  const buyerSampleRequestCartExistsAndHasItems =
    buyerSampleRequestCart && buyerSampleRequestCart.items?.length > 0;
  const buyerQuoteCartExistsAndHasItems =
    quoteRequest && quoteRequest.items.length > 0;

  const guestCartItem = cart?.items?.find(
    (item) => item.product.id === product.id
  );

  const userCartItem = quoteRequest?.items.find(
    (item) => item.product_id === product.id
  );

  const quoteButtonText =
    guestCartItem || userCartItem ? t("Edit Quote") : t("Get a Quote");

  const hasSampleSKUs = () => {
    const skusCollection = product.product_schema.collections.find(
      (collection) => collection.object_type === "skus"
    );

    const isASampleSKUsId = skusCollection?.columns.find(
      (column) => column.object_type === "is_a_sample"
    )?.id;

    const isSampleIdValues = skusCollection?.value_rows?.map(({ values }) => {
      const attributeValue = values.find(
        ({ attribute_id }) => attribute_id === isASampleSKUsId
      );
      return attributeValue ? (attributeValue.value as boolean) : false;
    });

    return isSampleIdValues?.some((isSampleSKUs) => isSampleSKUs) ?? false;
  };

  const requestQuoteButtonTooltip = (() => {
    if (
      (roleIsGuest && sampleCartInProgress) ||
      (roleIsSomeKindOfBuyer && buyerSampleRequestCartExistsAndHasItems)
    ) {
      return t("Finish sample request to create quote");
    } else if (roleIsSomeKindOfSeller) {
      return "Seller users cannot submit quote requests";
    } else if (quote_requests_visibility === "disabled" && roleIsGuest) {
      return t("Create an account or login to raise quote requests");
    }
  })();

  const requestSampleButtonTooltip = (() => {
    if (
      (roleIsGuest && leadCartInProgress) ||
      (roleIsSomeKindOfBuyer && buyerQuoteCartExistsAndHasItems)
    ) {
      return t("Finish quote request to create sample request");
    } else if (roleIsSomeKindOfBuyer && !hasSampleSKUs()) {
      return t("No sample SKUs are configured for this product");
    } else if (roleIsSomeKindOfSeller) {
      return "Seller users cannot request a sample";
    } else if (sample_requests_visibility === "disabled" && roleIsGuest) {
      return t("Create an account or login to raise sample requests");
    }
  })();

  const handleGetQuoteClick = () => {
    setGetAQuoteActive(true);
    setQuery({ quote: true });
  };

  const handleCloseFlyout = () => {
    setGetAQuoteActive(false);
    setQuery({ quote: undefined });
    if (setbackToProductList) {
      setbackToProductList(true);
    }
  };

  const SampleRequestItemForm = () => {
    if (roleIsSomeKindOfBuyer) {
      return (
        <BuyerAddToSampleRequestForm
          product={product as unknown as PIMProduct} /// update to PIM product
          cartID={sampleCartID}
          closeSlideout={() => {
            setShowSampleRequestForm(false);
            if (setbackToProductList) {
              setbackToProductList(true);
            }
          }}
        />
      );
    }
    if (roleIsGuest) {
      return (
        <AddToSampleRequestForm
          product={product as unknown as Product} /// update to PIM product
          cartID={sampleCartID}
          closeSlideout={() => setShowSampleRequestForm(false)}
        />
      );
    }
    return null;
  };

  const handleQuoteItemFormSuccess = () => {
    // Store the ULP transaction ID if there is one.
    if (query.ulp_id && !ulpId) {
      setUlpId(query.ulp_id);
    }
    setGetAQuoteActive(false);
    setQuery({ quote: undefined });
    if (setbackToProductList) {
      setbackToProductList(true);
    }
  };

  const QuoteItemForm: FunctionComponent = () => {
    if (cartError || quotesResponseError) {
      return (
        <ErrorPlaceholder message={"An error occurred, please try again."} />
      );
    }
    // The `user` should be defined if a buyer is logged in.
    if (roleIsSomeKindOfBuyer && user) {
      return (
        <BuyerQuoteItemFormForCart
          editingExistingItem={!!userCartItem}
          product={product as unknown as Product} /// update to PIM product
          product_application={userCartItem?.product_applications[0]}
          productSku={userCartItem?.sku}
          no_of_units={userCartItem?.no_of_units}
          total_quantity={userCartItem?.total_quantity}
          custom_packaging_quantity={userCartItem?.custom_packaging_quantity}
          submitQuoteItemForm={submitQuoteItemForm}
          allowEditingTransactionValues={true}
          buyerUser={user}
          showProceedToCheckoutButton={true}
          currencyCode={
            userCartItem?.currency || user.settings?.preferred_currency || "USD"
          }
        />
      );
    }
    if (roleIsGuest) {
      return (
        <GuestQuoteItemForm
          allDone={handleQuoteItemFormSuccess}
          product={product as unknown as Product} /// update to PIM product
          cartItem={guestCartItem}
        />
      );
    }
    return null;
  };

  /**
   * Form submission handler function for adding and editing quote cart items
   * from a product detail page. Handles two scenarios:
   *
   * 1. cart/quote-request doesn't exist yet, create it and add an item to it.
   *    or cart/quote-request exists, add a new item to it.
   * 2. cart/quote-request exists, modify an item in it.
   *
   * Any errors thrown are caught where this function is called.
   */
  const submitQuoteItemForm: ISubmitQuoteItemForm = async (unifiedCartArg) => {
    const {
      product_id,
      sku_id,
      no_of_units,
      applications,
      custom_application,
      total_quantity,
      packaging_unit_id,
      custom_packaging_type_id,
      custom_packaging_quantity,
    } = unifiedCartArg;

    // We do this GET request to see if a quote request already exists for
    // the cart.  Different endpoints/methods are used to submit the changes
    // depending on whether the quote request item exists yet or not
    // (POST/PATCH).
    const quotesResponse = await Axios.get<IQuotesAPIResponse>(
      QUOTES_ENDPOINT_STRING
    );
    const quoteRequest = quotesResponse.data.data[0];
    const itemInQuote = quoteRequest?.items.find(
      (item) => item.product_id === product_id
    );

    if (itemInQuote) {
      // Edit an item in existing quote request.
      const endpoint = `/v1/storefronts/${storefront_id}/unified-cart/items/${itemInQuote.id}`;
      const requestBody: UnifiedCartItemPatchArgs = {
        applications,
        ...(custom_application ? { custom_application } : {}),
        sku_id,
        no_of_units,
        total_quantity,
        packaging_unit_id,
        custom_packaging_type_id,
        custom_packaging_quantity,
      };
      await Axios.patch(endpoint, requestBody);

      // Call mutate so that if the user edits the quote cart item again,
      // the data will be up to date.
      mutateQuotes();
      mutate(endpoint);
    } else {
      // Item does not exist yet in the quote/cart, add it.
      const endpoint = `/v1/storefronts/${storefront_id}/unified-cart`;
      await Axios.post(endpoint, unifiedCartArg);

      // Call mutate to refresh the count in the cart widget.
      mutateQuotes();
      mutate(endpoint);
    }

    handleQuoteItemFormSuccess();
  };

  const getQuoteButtonDisabled =
    roleIsSomeKindOfSeller ||
    (roleIsGuest &&
      (sampleCartInProgress || quote_requests_visibility === "disabled")) ||
    (roleIsSomeKindOfBuyer && buyerSampleRequestCartExistsAndHasItems);

  const requestSampleDisabled =
    roleIsSomeKindOfSeller ||
    (roleIsGuest &&
      (leadCartInProgress || sample_requests_visibility === "disabled")) ||
    (roleIsSomeKindOfBuyer && (!hasSampleSKUs() || !!quoteRequest));

  return (
    <>
      {roleIsGuest ? (
        <TransactionButtonContainer>
          {showQuoteBtn && (
            <>
              <span
                data-for="quote-button"
                data-tip={requestQuoteButtonTooltip}
              >
                <PrimaryButtonLarge
                  disabled={getQuoteButtonDisabled}
                  onClick={handleGetQuoteClick}
                >
                  {quoteButtonText}
                </PrimaryButtonLarge>
              </span>
              <ReactTooltip id="quote-button" effect="solid" />
            </>
          )}

          <SlideOut closeFlyout={handleCloseFlyout} show={getAQuoteActive}>
            <QuoteItemForm />
          </SlideOut>
          {showSampleBtn &&
            (showQuoteBtn ? (
              <>
                <span
                  data-for="sample-button"
                  data-tip={requestSampleButtonTooltip}
                >
                  <SecondaryButtonLarge
                    onClick={() => setShowSampleRequestForm(true)}
                    disabled={requestSampleDisabled}
                  >
                    {t("Request a sample")}
                  </SecondaryButtonLarge>
                </span>

                <ReactTooltip id="sample-button" effect="solid" />
              </>
            ) : (
              <>
                <span
                  data-for="sample-button"
                  data-tip={requestSampleButtonTooltip}
                >
                  <PrimaryButtonLarge
                    onClick={() => setShowSampleRequestForm(true)}
                    disabled={requestSampleDisabled}
                  >
                    {t("Request a sample")}
                  </PrimaryButtonLarge>
                </span>
                <ReactTooltip id="sample-button" effect="solid" />
              </>
            ))}
          <SlideOut
            closeFlyout={() => setShowSampleRequestForm(false)}
            show={showSampleRequestForm}
          >
            <SampleRequestItemForm />
          </SlideOut>
        </TransactionButtonContainer>
      ) : (
        <TransactionButtonContainer>
          {showQuoteBtn && (
            <>
              <span
                data-for="quote-button"
                data-tip={requestQuoteButtonTooltip}
              >
                <PrimaryButtonLarge
                  disabled={getQuoteButtonDisabled}
                  onClick={handleGetQuoteClick}
                >
                  {quoteButtonText}
                </PrimaryButtonLarge>
              </span>
              <ReactTooltip id="quote-button" effect="solid" />
            </>
          )}

          <SlideOut closeFlyout={handleCloseFlyout} show={getAQuoteActive}>
            <QuoteItemForm />
          </SlideOut>
          {showSampleBtn &&
            (showQuoteBtn ? (
              <>
                <span
                  data-for="sample-button"
                  data-tip={requestSampleButtonTooltip}
                >
                  <SecondaryButtonLarge
                    onClick={() => setShowSampleRequestForm(true)}
                    disabled={requestSampleDisabled}
                  >
                    {t("Request a sample")}
                  </SecondaryButtonLarge>
                </span>
                <ReactTooltip id="sample-button" effect="solid" />
              </>
            ) : (
              <>
                <span
                  data-for="sample-button"
                  data-tip={requestSampleButtonTooltip}
                >
                  <PrimaryButtonLarge
                    onClick={() => setShowSampleRequestForm(true)}
                    disabled={requestSampleDisabled}
                  >
                    {t("Request a sample")}
                  </PrimaryButtonLarge>
                </span>
                <ReactTooltip id="sample-button" effect="solid" />
              </>
            ))}
          <SlideOut
            closeFlyout={() => setShowSampleRequestForm(false)}
            show={showSampleRequestForm}
          >
            <SampleRequestItemForm />
          </SlideOut>
        </TransactionButtonContainer>
      )}
    </>
  );
}
