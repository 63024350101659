import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import { endpoints } from "../../../../../endpoints";
import type {
  PaginatedListItemSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import { toTitleCase, useStoreState } from "../../../../../util/util";
import { ArchivedListWrapper } from "./ArchivedListItemDetails";
import { ItemDetailsWrapper } from "./ListDetails";
import { ListFooter, ListHeader } from "./ListHome";
import { ListItemBase, ListItemName } from "./ListItem";

export const ArchivedListSublist = ({
  item,
  pageHeight,
}: {
  item: ShortListSchema | undefined;
  pageHeight: string;
}) => {
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pagination, setPagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { data: listItemData, error: listItemError } =
    useSWR<PaginatedListItemSchema>(
      item
        ? `${endpoints.v2_tenants_id_or_slug_pim_lists_id_items(
            tenant_id,
            item.id
          )}?offset=${offset}&limit=${perPage}`
        : null,
      { revalidateOnFocus: false }
    );

  useEffect(() => {
    setPagination({
      perPage: perPage,
      pageCount: Math.ceil((listItemData?.pagination.total ?? 10) / perPage),
      pageIndex: (listItemData?.pagination.offset ?? 10) / perPage + 1,
    });
  }, [listItemData, perPage]);

  useEffect(() => setOffset(0), [item?.id]);

  const isLoading = !!item && !listItemData && !listItemError;

  if (isLoading) {
    return <DelayedSpinner />;
  }
  if (listItemError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error fetching archived list items. Please try again later."
        )}
      />
    );
  }

  return !!item ? (
    <ArchivedListWrapper height={pageHeight}>
      <ListHeader>{`Lists > Archived > ${toTitleCase(item.name)}`}</ListHeader>
      {listItemData &&
        listItemData.data.map(({ id, name }) => (
          <ItemDetailsWrapper key={id}>
            <ListItemName>{toTitleCase(name)}</ListItemName>
          </ItemDetailsWrapper>
        ))}
      {pagination.pageCount > 1 && (
        <ListItemBase style={{ height: "fit-content" }}>
          <Pagination
            pagination={pagination}
            offset={offset}
            handlePageClick={(offset) => setOffset(offset)}
          />
        </ListItemBase>
      )}
      <ListFooter />
    </ArchivedListWrapper>
  ) : (
    <></>
  );
};
