import { useEffect } from "react";
import styled from "styled-components/macro";
import { useFieldArray } from "react-hook-form";
import { PrimaryButtonSmall } from "../Buttons/Buttons";
import type { Fee, Currency, MethodsOfUseForm } from "../../types/types";
import { formatPrice } from "../../util/util-components";
import { EditablePriceList } from "../EditablePriceList/EditablePriceList";
import { useTranslation } from "react-i18next";
import { TaxExemptFee } from "../TaxExempt/TaxExemptFee";
import { Row } from "../Layout/Layout";

const Container = styled.div`
  max-width: 340px;
  background-color: ${({ theme }) => theme.secondaryBG};
  padding: 0 6x;
`;

const RowWithMargin = styled(Row)`
  div {
    margin-right: 10px;
  }
`;

const ItemText = styled.p`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const NumberText = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const TotalText = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const HorizontalSeparator = styled.ol`
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  padding-left: 0;
  list-style: none;
  display: flex;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const EditSubtotal = ({
  subtotal,
  total,
  setTotal,
  isTaxExempt,
  methodsOfUseForm,
  currency,
}: {
  subtotal: number;
  total: number;
  setTotal: (total: number) => void;
  isTaxExempt: boolean;
  methodsOfUseForm: MethodsOfUseForm;
  currency: Currency;
}): JSX.Element => {
  const { t } = useTranslation();
  const { watch, control } = methodsOfUseForm;

  const methodsOfUseFieldArray = useFieldArray({
    control,
    name: "feesList",
    keyName: "key",
  });
  const { fields, append } = methodsOfUseFieldArray;

  const calculateTotal = (subtotal: number, values: Fee[]) => {
    const totalFees: number = values.reduce(
      (a, b) => a + (Number(b["amount"]) || 0),
      0
    );
    setTotal(Number(subtotal) + Number(totalFees));
  };

  useEffect(() => {
    calculateTotal(subtotal, watch("feesList", fields));
  });

  return (
    <Container>
      <RowWithMargin>
        <ItemText>{t("Subtotal")}</ItemText>
        <NumberText data-testid={"subtotal"}>
          {formatPrice(subtotal, currency.code)}
        </NumberText>
      </RowWithMargin>
      <FormContainer>
        <EditablePriceList
          methodsOfUseForm={methodsOfUseForm}
          methodsOfUseFieldArray={methodsOfUseFieldArray}
          testIdPrefix={"quote-fee"}
          currencySymbol={currency.symbol}
          hasPossibleTaxFee={true}
        />
      </FormContainer>
      <RowWithMargin style={{ margin: "13px 0px" }}>
        <PrimaryButtonSmall
          type="button"
          onClick={() => append({ name: "", amount: 0 })}
        >
          {t("Add Fee")}
        </PrimaryButtonSmall>
      </RowWithMargin>

      <TaxExemptFee
        isTaxExempt={isTaxExempt}
        methodsOfUseFieldArray={methodsOfUseFieldArray}
        methodsOfUseForm={methodsOfUseForm}
      />
      <HorizontalSeparator />
      <RowWithMargin>
        <TotalText>{t("Total")}</TotalText>
        <TotalText data-testid={"quote-total-value"}>
          {formatPrice(total, currency.code)}
        </TotalText>
      </RowWithMargin>
    </Container>
  );
};
