import React from "react";
import { SearchInput } from "../../../components/SearchBar/SearchBar";
import styled from "styled-components/macro";
import { Form } from "../../../layout/FormLayout";
import { useFormWrapper } from "../../../util/util";
import { SearchIcon } from "../../../components/Icons/Icons";
import { useRoutePath } from "../../../util/Routing";

const SearchIconPositionWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  > svg {
    position: absolute;
    top: 10px;
    left: 13px;
    path {
      fill: ${({ theme }) => theme.secondaryInputBG};
    }
  }

  ${SearchInput} {
    width: 100%;
    padding: 13px 13px 13px 45px;
  }
`;

const SearchSection = styled.div`
  padding: 65px 0 45px;
  & > ${SearchIconPositionWrapper} {
    display: block;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
`;
export function HomePageSearch() {
  const { handleSubmit, register } = useFormWrapper();

  const { storePath } = useRoutePath();

  const onSubmit = ({ query }: { query: string }) => {
    window.location.href = `${storePath}/portfolio?q=${encodeURIComponent(
      query
    )}`;
  };

  return (
    <SearchSection>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <SearchIconPositionWrapper>
          <SearchInput
            name={"query"}
            ref={register({
              required: true,
            })}
            placeholder={"Search by Product name or CAS#"}
            required
            type={"search"}
          />
          <SearchIcon />
        </SearchIconPositionWrapper>
      </Form>
    </SearchSection>
  );
}
