import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import type { IHeroData } from "./HeroSection";
import { HeroSection } from "./HeroSection";
import { SlideOut } from "../SlideOut/SlideOut";
import { EditButton } from "../Buttons/Buttons";
import { EditHeroSection } from "./EditHeroSection";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { useRoutePath } from "../../util/Routing";
import { useHistory } from "react-router-dom";
import type { IStore } from "../../Store";
import { Store } from "../../Store";

const HeroWrapper = styled.div`
  position: relative;
`;

const Edit = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${({ theme }) => theme.primaryBG};
  border-radius: 4px;
  padding: 3px 0px 2px;
`;

interface IHeroComponentProps {
  isEditable: boolean;
  data: IHeroData;
  homePageData: IHomePageData;
}

export const HeroComponent = ({
  isEditable,
  data,
  homePageData,
}: IHeroComponentProps) => {
  const { storePath } = useRoutePath();
  const { storeState } = useContext<IStore>(Store);
  const { tenant_name } = storeState.storefront_metadata;
  const history = useHistory();
  const [editMode, setEditMode] = useState(false);
  const startEditMode = () => {
    data.editMode = true;
    setEditMode(true);
  };
  const endEditMode = () => {
    data.editMode = false;
    setEditMode(false);
  };

  const handleClickAction = () => {
    history.push(`${storePath}/portfolio`);
  };

  if (!data.title) {
    data.title = `Welcome to ${tenant_name}`;
  }
  return (
    <HeroWrapper>
      {data && <HeroSection data={data} clickAction={handleClickAction} />}

      <SlideOut closeFlyout={endEditMode} show={editMode}>
        {editMode && (
          <EditHeroSection
            data={data}
            homePageData={editMode && homePageData}
            handleEditSubmit={editMode && endEditMode}
          />
        )}
      </SlideOut>

      {isEditable && !editMode && (
        <Edit>
          <EditButton testid={"edit-hero-component"} onClick={startEditMode} />
        </Edit>
      )}
    </HeroWrapper>
  );
};
