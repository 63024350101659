import { PrimaryButtonFitContainer } from "../../components/Buttons/Buttons";
import { DesktopNotification } from "../../components/Notifications/Notifications";
import { PasswordToggleTextField } from "../../components/TextFields/TextFields";
import { Form } from "../../layout/FormLayout";
import { AuthContainer, AuthPage } from "../../layout/publicPageLayout";
import Axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import { Auth } from "../../components/Auth";
import { Notifications } from "../../components/Notifications/NotificationsContext";
import { H3DarkText } from "../../components/Typography/Typography";
import { useRoutePath } from "../../util/Routing";
import { useFormWrapper, useStoreState } from "../../util/util";
import { AuthLogoWrapper } from "./Login";

export type ResetPasswordRequestBody = {
  password: string;
};

interface ITokenResponse {
  user_id: string;
  token: string;
}

const NotificationWrapper = styled.div`
  & > div {
    grid-template-columns: 47px 270px;
  }
`;

export function ResetPassword() {
  const { handleSubmit, register, formState, errors, watch } = useFormWrapper(
    {}
  );
  const password = useRef({});
  password.current = watch("password", "");
  const [loading, setLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { storePath } = useRoutePath();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const { logout } = useContext(Auth);
  const [userID, setUserID] = useState<string>();
  const { t } = useTranslation();

  const { data: tokenResponse, error: tokenError } = useSWR<ITokenResponse>(
    token ? `/v1/users/activations/${token}` : null,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (tokenError) {
      if (tokenError.message?.includes("403")) {
        setTokenExpired(true);
      } else if (tokenError.message?.includes("404")) {
        notifyError(
          "Token is invalid, please request a password reset again.",
          {
            error: tokenError,
            logMessage: "Token not found. redirecting...",
          }
        );
        history.push(`${storePath}/login`);
      } else {
        notifyError("Something went wrong! please contact us for support.", {
          error: tokenError,
        });
        history.push(`${storePath}/login`);
      }
    }
  }, [tokenError, history, notifyError, storePath]);

  if (tokenResponse && !userID && tokenResponse.user_id) {
    setUserID(tokenResponse.user_id);
  }

  const onSubmit = async ({ password }: ResetPasswordRequestBody) => {
    setLoading(true);
    try {
      await Axios.patch(
        `/v1/storefronts/${storefront_id}/users/reset-password/tokens/${token}`,
        {
          password: password,
        }
      );

      notifySuccess("Your password has been updated successfully.");
      logout();
      history.push(`${storePath}/login`);
    } catch (error) {
      notifyError("Something went wrong, please try again.", {
        error,
        logMessage: "Submit failed",
      });
      setLoading(false);
    }
  };

  const closeExpiredTokenNotification = () => {
    history.push(storePath);
  };

  const TokenExpired = () => {
    return (
      <NotificationWrapper>
        <DesktopNotification
          notificationType="error"
          text="Link has expired, please request a password reset again."
          id={1}
          removeNotification={closeExpiredTokenNotification}
        />
      </NotificationWrapper>
    );
  };

  return (
    <AuthPage>
      <AuthContainer>
        <AuthLogoWrapper />
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <H3DarkText>{t("Reset Password")}</H3DarkText>
          {tokenExpired && <TokenExpired />}
          {!tokenExpired && (
            <>
              <PasswordToggleTextField
                name="password"
                autoComplete="new-password"
                label="Password"
                theref={register({
                  required: "You must specify a password",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
                formState={formState}
                errors={errors}
              />
              <PasswordToggleTextField
                name="confirmPassword"
                autoComplete="confirm-password"
                label="Confirm password"
                theref={register({
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
                formState={formState}
                errors={errors}
              />
              <PrimaryButtonFitContainer
                style={{ marginTop: 0 }}
                type="submit"
                loading={loading}
              >
                {t("Reset Password")}
              </PrimaryButtonFitContainer>
            </>
          )}
        </Form>
      </AuthContainer>
    </AuthPage>
  );
}
