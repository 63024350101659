import React from "react";
import type { IndicatorProps, MenuProps, OptionProps } from "react-select";
import { components } from "react-select";
import styled from "styled-components/macro";
import type { OptionType } from "../../types/types";
import { SearchIcon } from "../Icons/Icons";

/**
 * This file exists to prevent needless duplication between `SelectBoxV2` and `AsyncSelectBox`
 */

const { ValueContainer, Placeholder, Option } = components;

const InterDiv = styled.div`
  font-family: "Inter";
  margin-top: 6px;
`;

export const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <InterDiv>
        <Placeholder
          {...props}
          isFocused={props.isFocused}
          style={{ marginTop: "-5px" }}
        >
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </InterDiv>
    </ValueContainer>
  );
};

export const CustomOption = (props: OptionProps<OptionType, false>) => {
  const isDefault =
    props.selectProps.setDefaultTerm &&
    props.options[0].value === props.data.value;
  return (
    <>
      {isDefault && (
        <Option {...props}>
          <strong>Default</strong>&nbsp;-&nbsp;{props.label}
        </Option>
      )}
      {!isDefault && <Option {...props}></Option>}
    </>
  );
};

export const DropdownIndicator = (props: IndicatorProps<OptionType, false>) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    )
  );
};

export const Menu = (props: MenuProps<OptionType, false>) => (
  <InterDiv>
    <components.Menu {...props}>{props.children}</components.Menu>
  </InterDiv>
);
