import React, { useState } from "react";
import { useStoreState, makeCurrencyLabel } from "../../../../util/util";
import {
  PageWrapper,
  ContentWrapper,
  SettingsCard,
} from "../../../../layout/portalPageLayout";
import { H3 } from "../../../../components/Typography/Typography";
import {
  InfoBlockRow,
  InfoBlockSmall,
  InfoBlockExtraSmall,
  InfoBlockSpacer,
} from "../../../../components/InfoBlocks/InfoBlocks";
import { ContactBlockForUser } from "../../../../components/LocationsList/LocationsList";
import { UserIcon } from "../../../../components/Icons/Icons";
import styled from "styled-components/macro";
import { ErrorPlaceholder } from "../../../../components/Error";
import { useTranslation } from "react-i18next";
import {
  CancelButtonSmall,
  EditButton,
} from "../../../../components/Buttons/Buttons";
import { ToggleSwitch } from "../../../../components/ToggleSwitch/ToggleSwitch";
import { EditTaxExemptedForm } from "../../CustomerDetailPage/CustomerSettings/EditTaxExempted";
import { FileCard } from "../../../../components/FileCard/FileCard";
import { useAuthContext } from "../../../../components/Auth";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

/**
 * The Manage > Settings tab for buyer and buyer admin. Some of the settings are
 * read-only because they can only be edited by the seller for the buyer.
 */
export const BuyerSettings = () => {
  const {
    api_metadata,
    storefront_metadata: { edition },
    tenant_id,
  } = useStoreState();
  const [editCustomerTaxExempted, setEditCustomerTaxExempted] = useState(false);
  const { t } = useTranslation();
  const { user, updateUser } = useAuthContext();

  if (!user?.settings) {
    return (
      <ErrorPlaceholder message="There was an error while attempting to fetch the settings." />
    );
  }

  const {
    account_manager,
    customer_service_rep,
    preferred_currency,
    default_payment_term,
    default_payment_mode,
    default_delivery_term,
    is_tax_exempt,
    tax_exempt_document: taxDocument,
  } = user.settings;

  const currencies = api_metadata?.supported_currencies ?? [];
  const currency = currencies.find((curr) => curr.code === preferred_currency);
  const currencyLabel = currency ? makeCurrencyLabel(currency) : "--";

  const viewTaxDocument = async () => {
    if (taxDocument) {
      window.open(taxDocument.signed_url, "_blank");
    }
  };

  return (
    <PageWrapper narrow>
      <ContentWrapper>
        <SettingsCard>
          <Row>
            <H3>{t("Customer Relation")}</H3>
          </Row>
          <InfoBlockRow>
            <InfoBlockSmall
              header={t("Account Manager")}
              content={<ContactBlockForUser user={account_manager} />}
              icon={<UserIcon width={16} />}
            />
            <InfoBlockSmall
              header={t("Customer Success Manager")}
              content={<ContactBlockForUser user={customer_service_rep} />}
              icon={<UserIcon width={16} />}
            />
          </InfoBlockRow>
        </SettingsCard>

        <SettingsCard>
          <Row>
            <H3>{t("Tax Exempted")}</H3>
            {!editCustomerTaxExempted && (
              <EditButton
                testid={"edit-tax-exempted"}
                onClick={() => setEditCustomerTaxExempted(true)}
                title={t("Edit Tax Exempted")}
              />
            )}
            {editCustomerTaxExempted && (
              <CancelButtonSmall
                test-id={"cancel-edit-tax-exempted"}
                onClick={() => setEditCustomerTaxExempted(false)}
              />
            )}
          </Row>
          {!editCustomerTaxExempted && (
            <>
              <InfoBlockRow>
                <InfoBlockSmall
                  header=""
                  content={
                    <ToggleSwitch
                      label={t("Tax Exempted")}
                      disabled={true}
                      name="tax_exempted"
                      isChecked={is_tax_exempt}
                    />
                  }
                />
              </InfoBlockRow>
              {taxDocument && is_tax_exempt && (
                <FileCard file={taxDocument} viewFile={viewTaxDocument} />
              )}
            </>
          )}
          {editCustomerTaxExempted && user.seller_id && (
            <EditTaxExemptedForm
              tenantID={user.tenant_id}
              sellerID={tenant_id}
              refreshSettingsData={(isTaxExempted) =>
                updateUser({
                  ...user,
                  settings: { ...user.settings, is_tax_exempt: isTaxExempted },
                })
              }
              doneEditing={() => setEditCustomerTaxExempted(false)}
              taxExempted={is_tax_exempt}
              document={is_tax_exempt ? taxDocument : undefined}
            />
          )}
        </SettingsCard>

        {edition !== "pim" && (
          <>
            <SettingsCard>
              <Row>
                <H3>{t("Currency")}</H3>
              </Row>
              <InfoBlockSpacer>
                <InfoBlockExtraSmall
                  header={t("Currency")}
                  content={currencyLabel}
                />
              </InfoBlockSpacer>
            </SettingsCard>

            <SettingsCard>
              <Row>
                <H3>{t("Default Terms")}</H3>
              </Row>
              <InfoBlockSpacer>
                <InfoBlockExtraSmall
                  header={t("Payment Terms")}
                  content={default_payment_term?.name || "--"}
                />
              </InfoBlockSpacer>
              <InfoBlockSpacer>
                <InfoBlockExtraSmall
                  header={t("Payment Method")}
                  content={default_payment_mode?.name || "--"}
                />
              </InfoBlockSpacer>
              <InfoBlockSpacer>
                <InfoBlockExtraSmall
                  header={t("Shipping Terms")}
                  content={default_delivery_term?.name || "--"}
                />
              </InfoBlockSpacer>
            </SettingsCard>
          </>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};
