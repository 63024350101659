import { useTranslation } from "react-i18next";
import type {
  DataMutate,
  TranslationsSchema,
} from "../../../../../types/types";
import type { AttributeGroupSummary } from "../../../../../types/types.PIM";
import {
  EditGroupOrCollectionTranslations,
  GroupOrCollectionTranslationsTable,
} from "../SellerAdminPIMAttributesCollections/util";
import { TranslationsAccordionCard } from "../../../../../components/AccordionCard/TranslationsAccordionCard";

export const GroupTranslations = ({
  group,
  mutate,
}: {
  group: AttributeGroupSummary & { translations: TranslationsSchema[] };
  mutate: DataMutate<
    AttributeGroupSummary & { translations: TranslationsSchema[] }
  >;
}) => {
  const { t } = useTranslation();
  const tableElement = () => (
    <GroupOrCollectionTranslationsTable
      attribute_display_name={group.display_name}
      translations={group.translations}
    />
  );
  return (
    <TranslationsAccordionCard
      tableElement={tableElement()}
      subtitle={t("Manage translations for this group.")}
      editElement={(onClose) => (
        <EditGroupOrCollectionTranslations
          group_or_collection={{
            name: group.name,
            display_name: group.display_name ? t([group.display_name]) : "",
            id: group.id,
            type: "groups",
          }}
          mutate={mutate}
          onClose={onClose}
          translations={group.translations}
        />
      )}
    />
  );
};
