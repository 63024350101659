import * as z from "zod";
import {
  getDateTime,
  toTitleCase,
  useFormWrapper,
  useStoreState,
  useUpdateProductStatus,
} from "../../../../util/util";
import { stringIsNotOnlyWhiteSpaceRegex } from "../../../../util/regexes";
import type { AxiosError } from "axios";
import Axios from "axios";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { endpoints } from "../../../../endpoints";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  zodOptionalSelectBoxDefault,
  zodOptionalString,
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../util/zod.util";
import { H6 } from "../../../../components/Typography/Typography";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { Form } from "../../../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../components/SelectBoxV2/SelectBoxV2";
import { PrimaryButtonFitContainer } from "../../../../components/Buttons/Buttons";
import { TextField } from "../../../../components/TextFields/TextFields";
import type {
  DataMutate,
  Language,
  OptionType,
  SupportedLanguage,
} from "../../../../types/types";
import { FileUploader } from "../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/FileUploader";
import useSWR from "swr";
import type {
  AssetCategory,
  AssetType,
} from "../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import type {
  ListDetailsSchema,
  ListItemObjectSchema,
  PIMProduct,
  SupportedAssetCategoryType,
  SupportedAssetType,
} from "../../../../types/types.PIM";
import { DelayedSpinner } from "../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../components/Error";
import { strings } from "../../../../util/strings";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import { useCountriesList, useRegionsList } from "../../../../util/Locations";

type ParamsType = {
  asset_type: string;
  category_id: string;
  language?: string;
  document_type?: string | null;
  issue_date?: string | null;
  expiration_date?: string | null;
  renewal_date?: string | null;
  country?: string | null;
  region?: string | null;
};

export interface IAssetPayload {
  name: string;
  asset_type: string;
  category_id: string;
  language?: string;
  product_ids?: string;
  document_type?: string | null;
  issue_date?: string | null;
  expiration_date?: string | null;
  renewal_date?: string | null;
  country?: string | null;
  region?: string | null;
}

const AddAssetSchema = z.object({
  asset_name: z.string(),
  file_type: z.instanceof(File),
  input_asset_type: zodSelectBoxType,
  input_category_type: zodSelectBoxType,
  input_lang_type: zodSelectBoxType,
  input_document_type: zodSelectBoxType.optional().nullable(),
  issue_date: zodOptionalString,
  expiration_date: zodOptionalString,
  renewal_date: zodOptionalString,
  country: zodSelectBoxType.optional().nullable(),
  region: zodSelectBoxType.optional().nullable(),
  product_id: zodSelectBoxType.optional(),
  custom_category_name: zodOptionalString,
});

type FormValues = z.infer<typeof AddAssetSchema>;

const AddAssetSchemaFn = (t: TFunction) =>
  z
    .object({
      asset_name: z
        .string()
        .regex(stringIsNotOnlyWhiteSpaceRegex, t("This is a required field")),
      input_asset_type: zodSelectBoxDefault(t),
      file_type: z.instanceof(File),
      input_category_type: zodSelectBoxDefault(t),
      input_lang_type: zodSelectBoxDefault(t),
      product_id: zodOptionalSelectBoxDefault.optional(),
      issue_date: zodOptionalString,
      expiration_date: zodOptionalString,
      renewal_date: zodOptionalString,
      input_document_type: zodOptionalSelectBoxDefault.optional().nullable(),
      country: zodOptionalSelectBoxDefault.optional().nullable(),
      region: zodOptionalSelectBoxDefault.optional().nullable(),
      custom_category_name: z
        .string()
        .refine((val) => Boolean(val.trim()), {
          message: strings(t).thisIsARequiredField,
        })
        .nullish(),
    })
    .superRefine(({ input_asset_type, input_category_type }, ctx) => {
      if (!input_asset_type?.value || !input_category_type?.value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("This is a required field"),
          path: ["input_asset_type"],
        });
      }
    });

export const NewAssetForm = <ParentData,>({
  onComplete,
  product,
  products,
  refreshParentData,
  defaultAssetType,
  onAddAssetData,
  default_asset_types,
  default_categories,
}: {
  defaultAssetType?: "Documents" | "Digital Media";
  onComplete?: (productId?: string) => void;
  product?: PIMProduct;
  products?: OptionType<string>[];
  refreshParentData: DataMutate<ParentData>;
  onAddAssetData?: (data: FormValues) => Promise<void>;
  default_asset_types?: OptionType<SupportedAssetType>[];
  default_categories?: OptionType<SupportedAssetCategoryType>[];
}) => {
  const { t } = useTranslation();
  const countries = useCountriesList();
  const regions = useRegionsList();
  const [submitting, setSubmitting] = useState(false);
  const [assetTypes, setAssetTypes] = useState<
    OptionType<SupportedAssetType>[]
  >([]);
  const [assetCategories, setAssetCategories] = useState<
    OptionType<SupportedAssetCategoryType>[]
  >([]);

  const [documentTypesFilters, setDocumentTypesFilters] = useState<
    OptionType<string>[]
  >([]);

  const [assetLanguages, setAssetLanguages] = useState<
    OptionType<SupportedLanguage>[]
  >([]);

  const { notifySuccess, notifyError } = useContext(Notifications);
  const { tenant_id, storefront_id } = useStoreState();

  const updateProductStatus = useUpdateProductStatus({
    product: product ?? ({ status: "archived" } as PIMProduct),
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const assetTypeDefault = useMemo(
    () =>
      defaultAssetType === "Documents"
        ? { label: t("Document"), value: "document" }
        : null,
    [defaultAssetType, t]
  );

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(AddAssetSchemaFn(t)),
    defaultValues: {
      input_asset_type: assetTypeDefault ?? {
        label: undefined,
        value: undefined,
      },
      custom_category_name: null,
      input_category_type: { label: undefined, value: undefined },
      input_lang_type:
        assetLanguages.length === 1
          ? assetLanguages[0]
          : { label: undefined, value: undefined },
      input_document_type: undefined,
      product_id:
        products?.length === 1
          ? products[0]
          : { label: undefined, value: undefined },
      file_type: undefined,
      asset_name: undefined,
    },
  });

  const {
    handleSubmit,
    control,
    errors,
    formState,
    register,
    setValue,
    watch,
  } = methodsOfUseForm;

  const selectedAssetType: string = watch("input_asset_type")?.value;

  const selectedCategoryType: string = watch("input_category_type")?.value;

  const { data: assetTypeResponse, error: assetTypeError } = useSWR<AssetType>(
    !default_asset_types ? endpoints.v2_storefronts_id_pim_asset_types() : null
  );

  const { error: categoryTypeError } = useSWR<AssetCategory>(
    selectedAssetType && !default_categories
      ? `${endpoints.v2_tenants_id_pim_assets_categories(
          tenant_id
        )}?asset_type=${assetTypeDefault?.value ?? selectedAssetType}`
      : null,
    {
      onSuccess: ({ asset_categories }) =>
        setAssetCategories(
          asset_categories.map((category) => ({
            label: category.name,
            value: category.id,
          }))
        ),
    }
  );

  const { data: documentTyoesResponse, error: documentTypeError } = useSWR<{
    data: ListDetailsSchema;
  }>(
    endpoints.v2_tenants_id_pim_get_list_by_name(tenant_id, "Document Types"),
    {
      onError: (error) => {
        console.error("There was an error fetching the Document types list.", {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: true,
      onSuccess: (data) => {
        setDocumentTypesFilters(
          data.data.items.map((type: ListItemObjectSchema) => {
            return { label: type.name, value: type.name };
          })
        );
      },
    }
  );

  const { data: languagesResponse, error: languageError } = useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onSuccess: ({ languages }) => {
      setAssetLanguages(
        languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
    },
    onError: () =>
      notifyError(t("could not load asset languages, Something went wrong.")),
  });

  useEffect(() => {
    if (languagesResponse) {
      setAssetLanguages(
        languagesResponse.languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
    }
  }, [languagesResponse]);

  useEffect(() => {
    if (documentTyoesResponse) {
      setDocumentTypesFilters(
        documentTyoesResponse.data.items.map((type: ListItemObjectSchema) => {
          return { label: type.name, value: type.name };
        })
      );
    }
  }, [documentTyoesResponse]);

  function constructQuery(baseUrl: string, params: ParamsType) {
    const urlParams = new URLSearchParams();

    Object.keys(params).forEach((param) => {
      urlParams.append(param, (params as any)[param]);
    });

    return baseUrl + "?" + urlParams;
  }

  const default_submit = async (formValues: FormValues) => {
    const {
      asset_name,
      input_asset_type,
      input_category_type,
      issue_date,
      expiration_date,
      renewal_date,
      country,
      region,
      input_lang_type,
      input_document_type,
      file_type,
    } = formValues;
    const formData = new FormData();
    formData.append("file", file_type);

    let updatedProduct = null;
    if (product?.id) {
      const { data } = await updateProductStatus();
      updatedProduct = data;
    }

    const paramsObj: IAssetPayload = {
      name: asset_name,
      asset_type: input_asset_type?.value,
      category_id: input_category_type?.value,
      language: input_lang_type?.value,
    };

    if (product?.id) {
      paramsObj["product_ids"] = updatedProduct!.id;
    }

    if (issue_date) {
      paramsObj["issue_date"] = getDateTime(issue_date, true).dateFullYear;
    }
    if (expiration_date) {
      paramsObj["expiration_date"] = getDateTime(
        expiration_date,
        true
      ).dateFullYear;
    }
    if (renewal_date) {
      paramsObj["renewal_date"] = getDateTime(renewal_date, true).dateFullYear;
    }
    if (input_document_type?.value) {
      paramsObj["document_type"] = input_document_type?.value;
    }
    if (country?.value) {
      paramsObj["country"] = country?.value;
    }
    if (region?.value) {
      paramsObj["region"] = region?.value;
    }

    const baseURL = endpoints.v2_storefronts_id_pim_assets(tenant_id);

    setSubmitting(true);

    try {
      await Axios.post(constructQuery(baseURL, paramsObj), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      await refreshParentData();
      notifySuccess(t("Asset successfully added"));
      if (onComplete) {
        onComplete(
          updatedProduct?.primary_staged_product_id ?? updatedProduct?.id
        );
      }
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not add asset. Something went wrong."),
        {
          error,
        }
      );
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmit = async (formValues: FormValues): Promise<void> => {
    if (onAddAssetData) {
      setSubmitting(true);
      await onAddAssetData(formValues);
      setSubmitting(false);
    } else {
      default_submit(formValues);
    }
  };

  useEffect(() => {
    setValue("input_category_type", { label: undefined, value: undefined });
    setValue("file_type", undefined);
    setValue("asset_name", undefined);
    setSelectedFile(null);
  }, [selectedAssetType, setValue]);

  useEffect(() => {
    if (assetTypeResponse) {
      const { asset_types } = assetTypeResponse;
      setAssetTypes(
        product?.id
          ? !assetTypeDefault
            ? asset_types.reduce<OptionType<SupportedAssetType>[]>(
                (acc, cur) =>
                  cur === "document"
                    ? acc
                    : [
                        ...acc,
                        {
                          label: toTitleCase(cur),
                          value: cur,
                        },
                      ],
                []
              )
            : []
          : asset_types.map((type) => ({
              label: toTitleCase(type),
              value: type,
            }))
      );
    }
  }, [assetTypeDefault, assetTypeResponse, product?.id]);

  const isLoadingAssetTypes =
    !assetTypeError && !assetTypeResponse && !default_asset_types;

  if (isLoadingAssetTypes) {
    return <DelayedSpinner />;
  }

  if (assetTypeError || categoryTypeError) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error loading asset types and categories. Please try again later"
        )}
      />
    );
  }

  return (
    <>
      {
        product?.id ? (
          <div style={{ margin: "24px 0px 16px 0px" }}>
            <H6>{t("Asset details")}</H6>
          </div>
        ) : // TODO externalize titles
        null
        // <MarginBottomHeaderLeft>
        //   <SectionTitle>{t("Add Asset")}</SectionTitle>
        // </MarginBottomHeaderLeft>
      }
      <Form id="new_asset_form_id" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_asset_type"
          autoComplete={"input_asset_type"}
          placeholder={t("Asset Type")}
          rules={{
            required: true,
          }}
          isDisabled={product?.id && !!assetTypeDefault}
          errors={errors}
          formState={formState}
          defaultValue={
            assetTypeDefault ?? { label: undefined, value: undefined }
          }
          options={default_asset_types ?? assetTypes}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="input_category_type"
          autoComplete={"input_category_type"}
          placeholder={t("Category")}
          options={default_categories ?? assetCategories}
          rules={{
            required: true,
          }}
          errors={errors}
          isDisabled={!selectedAssetType}
          formState={formState}
        />
        {selectedCategoryType && selectedCategoryType === "other" ? (
          <TextField
            name="custom_category_name"
            label={t("Description")}
            autoComplete={"custom_category_name"}
            theref={register()}
            formState={formState}
            errors={errors}
            type="text"
          />
        ) : (
          <></>
        )}
        <FileUploader
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setValue={setValue}
          selectedAssetType={selectedAssetType}
          control={control}
          errors={errors}
          formState={formState}
        />
        <TextField
          name="asset_name"
          label={t("Asset Name")}
          autoComplete={"asset_name"}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        {!languageError && (
          <Controller
            as={SelectBoxV2}
            control={control}
            options={assetLanguages}
            name="input_lang_type"
            autoComplete={"input_lang_type"}
            placeholder={t("Language")}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
          />
        )}
        {!documentTypeError && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="input_document_type"
            autoComplete={"input_document_type"}
            isClearable={true}
            placeholder={t("Document Type")}
            options={documentTypesFilters}
            errors={errors}
            formState={formState}
          />
        )}
        <DatePicker
          label={t("Issue Date")}
          name={"issue_date"}
          required={false}
          methodsOfUseForm={methodsOfUseForm}
          placeholder={t("MM/DD/YYYY")}
          isOutsideRange={(day) => false}
        />
        <DatePicker
          label={t("Expiration Date")}
          name={"expiration_date"}
          required={false}
          methodsOfUseForm={methodsOfUseForm}
          placeholder={t("MM/DD/YYYY")}
          isOutsideRange={(day) => false}
        />
        <DatePicker
          label={t("Renewal Date")}
          name={"renewal_date"}
          required={false}
          methodsOfUseForm={methodsOfUseForm}
          placeholder={t("MM/DD/YYYY")}
          isOutsideRange={(day) => false}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="country"
          autoComplete={"country"}
          placeholder={t("Country")}
          isClearable={true}
          options={countries}
          rules={{
            required: false,
          }}
          errors={errors}
          formState={formState}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="region"
          autoComplete={"region"}
          isClearable={true}
          placeholder={t("Region")}
          options={regions}
          rules={{
            required: false,
          }}
          errors={errors}
          formState={formState}
        />
        {Boolean(products) && products!.length > 0 && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="product_id"
            autoComplete={"products"}
            placeholder={t("Assign to product")}
            options={products}
            rules={{
              required: false,
            }}
            errors={errors}
            formState={formState}
          />
        )}
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={submitting}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
