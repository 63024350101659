import React, { useState, useContext } from "react";
import { Form } from "../../../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../components/SelectBoxV2/SelectBoxV2";
import {
  useStoreState,
  convertPaymentTermToOption,
  convertDeliveryTermToOption,
  convertPaymentModeToOption,
  useFormWrapper,
  makeDeliveryTermsGetEndpoint,
  makePaymentTermsGetEndpoint,
  makePaymentModesGetEndpoint,
  markDefaultTerm,
} from "../../../../util/util";
import type {
  OptionType,
  Tenant,
  ITenantCustomerSettingsCreation,
  IPaymentTerm,
  IDeliveryTerm,
  IPaymentMode,
  DeliveryTermPaginatedOutput,
  PaymentTermPaginatedOutput,
  PaymentModePaginatedOutput,
  User,
} from "../../../../types/types";
import { PrimaryButtonMedium } from "../../../../components/Buttons/Buttons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import { endpoints } from "../../../../endpoints";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorPlaceholder } from "../../../../components/Error";
import useSWR from "swr";
import { FormContainer, SaveButtonContainer } from "./CustomerSettings";

const formSchema = yup.object().shape({
  payment_term: yup.object().nullable().notRequired(),
  payment_mode: yup.object().nullable().notRequired(),
  delivery_term: yup.object().nullable().notRequired(),
});

type FormData = {
  payment_term: OptionType<string> | null;
  payment_mode: OptionType<string> | null;
  delivery_term: OptionType<string> | null;
};

export const EditDefaultTermsForm = ({
  buyerTenant,
  loggedInUser,
  refreshSettingsData,
  doneEditing,
  defaultPaymentTerm,
  defaultPaymentMode,
  defaultDeliveryTerm,
}: {
  buyerTenant: Tenant;
  loggedInUser: User;
  refreshSettingsData: () => void;
  doneEditing: () => void;
  defaultPaymentTerm: IPaymentTerm | undefined;
  defaultPaymentMode: IPaymentMode | undefined;
  defaultDeliveryTerm: IDeliveryTerm | undefined;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: fetchedDeliveryTerms, error: deliveryTermsError } =
    useSWR<DeliveryTermPaginatedOutput>(
      makeDeliveryTermsGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError("There was an error fetching the shipping terms.", {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const { data: fetchedPaymentTerms, error: paymentTermsError } =
    useSWR<PaymentTermPaginatedOutput>(
      makePaymentTermsGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError("There was an error fetching the payment terms.", {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const { data: fetchedPaymentModes, error: paymentModesError } =
    useSWR<PaymentModePaginatedOutput>(
      makePaymentModesGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError("There was an error fetching the payment methods.", {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const deliveryTermsOptions = markDefaultTerm(
    fetchedDeliveryTerms?.data.map(convertDeliveryTermToOption) ?? [],
    defaultDeliveryTerm
      ? convertDeliveryTermToOption(defaultDeliveryTerm)
      : undefined
  );

  const paymentTermsOptions = markDefaultTerm(
    fetchedPaymentTerms?.data.map(convertPaymentTermToOption) ?? [],
    defaultPaymentTerm
      ? convertPaymentTermToOption(defaultPaymentTerm)
      : undefined
  );

  const paymentModesOptions = markDefaultTerm(
    fetchedPaymentModes?.data.map(convertPaymentModeToOption) ?? [],
    defaultPaymentMode
      ? convertPaymentModeToOption(defaultPaymentMode)
      : undefined
  );

  const { handleSubmit, control, errors, formState } = useFormWrapper<FormData>(
    {
      resolver: yupResolver(formSchema),
      defaultValues: {
        payment_term: defaultPaymentTerm
          ? convertPaymentTermToOption(defaultPaymentTerm)
          : null,
        payment_mode: defaultPaymentMode
          ? convertPaymentModeToOption(defaultPaymentMode)
          : null,
        delivery_term: defaultDeliveryTerm
          ? convertDeliveryTermToOption(defaultDeliveryTerm)
          : null,
      },
    }
  );

  const onSubmit = async (formData: FormData) => {
    setIsSubmitting(true);
    try {
      const { payment_term, payment_mode, delivery_term } = formData;

      let requestBody: ITenantCustomerSettingsCreation = {};
      if (payment_term?.value) {
        requestBody.default_payment_term_id = payment_term.value;
      }
      if (payment_mode?.value) {
        requestBody.default_payment_mode_id = payment_mode.value;
      }
      if (delivery_term?.value) {
        requestBody.default_delivery_term_id = delivery_term.value;
      }

      await Axios.patch(
        endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
          storefront_id,
          // Since this edit form is only used by sellers (not buyers), the
          // logged in user will belong to the seller tenant, so that's how we
          // get the seller tenant ID.
          loggedInUser.tenant_id,
          buyerTenant.id
        ),
        requestBody
      );
      notifySuccess("Changes saved");
      setIsSubmitting(false);
      refreshSettingsData();
      doneEditing();
    } catch (error) {
      notifyError("There was an error submitting the changes", { error });
      setIsSubmitting(false);
    }
  };

  // For error handling, if one of the GET requests fails, we show an error
  // notification, but carry on since they may want to edit one of the other
  // options.
  if (paymentTermsError && paymentModesError && deliveryTermsError) {
    // If everything errors out, don't even let them try.
    return <ErrorPlaceholder message="There was an error, please try again." />;
  }
  if (
    !fetchedPaymentTerms &&
    !paymentTermsError &&
    !fetchedPaymentModes &&
    !paymentModesError &&
    !fetchedDeliveryTerms &&
    !deliveryTermsError
  ) {
    // Everything is still loading.
    return null;
  }
  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="payment_term"
          placeholder="Payment Terms"
          options={paymentTermsOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
          setDefaultTerm={!!defaultPaymentTerm}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="payment_mode"
          placeholder="Payment Method"
          options={paymentModesOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
          setDefaultTerm={!!defaultPaymentMode}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="delivery_term"
          placeholder="Shipping Terms"
          options={deliveryTermsOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
          setDefaultTerm={!!defaultDeliveryTerm}
        />
        <SaveButtonContainer>
          <PrimaryButtonMedium loading={isSubmitting}>
            Save your changes
          </PrimaryButtonMedium>
        </SaveButtonContainer>
      </Form>
    </FormContainer>
  );
};
