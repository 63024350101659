import type { AxiosError } from "axios";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { ConfirmDialog } from "../../../../components/ConfirmDialog/ConfirmDialog";
import { RadioButton } from "../../../../components/RadioButton/RadioButton";
import {
  H3,
  SmallSectionHeaderRegular,
} from "../../../../components/Typography/Typography";
import { endpoints } from "../../../../endpoints";
import { RadioButtonContainer } from "../../../../layout/FormLayout";
import type { PIMProduct } from "../../../../types/types.PIM";
import { useStoreState } from "../../../../util/util";
import type { ProductMutate } from "../ProductDetailPage";
import { CustomersList } from "./CustomersList";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import { SellerAdminTeamsListByProduct } from "../../../admin/SellerAdmin/SellerAdminTeams/SellerAdminTeamsByProduct";
import { useAuthContext } from "../../../../components/Auth";

const AccessHomePageWrapper = styled.div`
  margin-top: 60px;
`;

const HeaderSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;
`;

const RadioSection = styled(RadioButtonContainer)`
  margin-left: -5px;
`;

export const AccessHomePage = ({
  product,
  mutateProduct,
}: {
  product: PIMProduct;
  mutateProduct: ProductMutate;
}) => {
  const {
    id: productId,
    is_accessible_by_all_customers_and_distributors: all_customers,
    is_globally_viewable,
  } = product;

  const [accessMode, setAccessMode] = useState<
    "all_customers" | "selected_customers" | undefined
  >(
    all_customers === true
      ? "all_customers"
      : all_customers === false
      ? "selected_customers"
      : undefined
  );
  const [teamVisibility, setTeamVisibility] = useState<
    "everyone" | "selected_teams"
  >(is_globally_viewable ? "everyone" : "selected_teams");
  const [teamConfirmDialogMessage, setTeamConfirmDialogMessage] =
    useState<string>("");
  const [showTeamDialog, setShowTeamDialog] = useState(false);
  const [transientTeamVisibility, setTransientTeamVisibility] =
    useState<"everyone" | "selected_teams">();

  const [showAccessDialog, setShowAccessDialog] = useState(false);
  const [accessConfirmDialogMessage, setAccessConfirmDialogMessage] =
    useState<string>("");
  const [transientAccessMode, setTransientAccessMode] =
    useState<"all_customers" | "selected_customers">();
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotifications();
  const { roleIsSellerAdmin } = useAuthContext();

  const confirmAccessMode = async (
    mode: "all_customers" | "selected_customers"
  ) => {
    try {
      await axios.patch<PIMProduct>(
        endpoints.v2_tenants_id_products_id_privacy(tenant_id, productId),
        {
          is_accessible_by_all_customers_and_distributors:
            mode === "all_customers",
        }
      );
      await mutateProduct();
      notifySuccess(t("Access updated successfully"));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not update access control. Please try again later."),
        {
          error,
        }
      );
    }
  };

  const confirmTeamVisibility = async (mode: "everyone" | "selected_teams") => {
    try {
      if (mode === "everyone") {
        await axios.patch(
          `/v2/tenants/${tenant_id}/pim/products/${product.id}/teams/visibility`,
          {
            visibility: "global",
          }
        );
        notifySuccess(t("Product is now available to all teams"));
        await mutateProduct();
      } else {
        await axios.patch(
          `/v2/tenants/${tenant_id}/pim/products/${product.id}/teams/visibility`,
          {
            visibility: "team_specific",
            team_ids: [],
          }
        );
        notifySuccess(t("Product is now available to selected teams"));
        await mutateProduct();
      }
    } catch (error) {
      notifyError(t("There was an error setting team visibility"), { error });
    }
  };

  return (
    <AccessHomePageWrapper>
      <>
        <HeaderSection>
          <H3 style={{ margin: 0 }}>{t("Teams Management")}</H3>
          <SmallSectionHeaderRegular style={{ margin: 0 }}>
            {t(
              "Control accessibility of your product information for each team"
            )}
          </SmallSectionHeaderRegular>
        </HeaderSection>
        <RadioSection>
          <RadioButton
            disabled={!(product.is_editable && roleIsSellerAdmin)}
            name={"everyone"}
            value="everyone"
            checked={teamVisibility === "everyone"}
            optionTitle={t("Everyone")}
            handleChange={() => {
              setTeamConfirmDialogMessage(
                t(
                  "Are you sure you want any user with edit permission to be able to edit this product?"
                )
              );
              setTransientTeamVisibility("everyone");
              setShowTeamDialog(true);
            }}
          />
          <RadioButton
            disabled={!(product.is_editable && roleIsSellerAdmin)}
            name={"selectedTeams"}
            value="selected_teams"
            checked={teamVisibility === "selected_teams"}
            optionTitle={t("Selected Teams")}
            handleChange={() => {
              setTeamConfirmDialogMessage(
                t(
                  `Are you sure you want to change the visiblity from all teams to selected teams?
                
                This will only take effect if teams are selected`
                )
              );
              setTransientTeamVisibility("selected_teams");
              setShowTeamDialog(true);
            }}
          />
        </RadioSection>
        {teamVisibility === "everyone" ? (
          <H3 style={{ marginTop: "28px" }}>
            {t(
              "Every user with edit permission can edit this product information"
            )}
          </H3>
        ) : (
          <div style={{ marginBottom: "28px" }}>
            <SellerAdminTeamsListByProduct
              product={product}
              mutateProduct={mutateProduct}
            />
          </div>
        )}
      </>

      <HeaderSection>
        <H3 style={{ margin: 0 }}>{t("Access Management")}</H3>
        <SmallSectionHeaderRegular style={{ margin: 0 }}>
          {t("Control accessibility of the product information for your teams")}
        </SmallSectionHeaderRegular>
      </HeaderSection>
      <RadioSection>
        <RadioButton
          name={"allCustomers"}
          value="all_customers"
          disabled={product.status === "archived" || !product.is_editable}
          checked={accessMode === "all_customers"}
          optionTitle={t("All Customers and Distributors")}
          handleChange={() => {
            setAccessConfirmDialogMessage(
              t(
                "Are you sure you want to give access to this product information to all customers and distributors?"
              )
            );
            setTransientAccessMode("all_customers");
            setShowAccessDialog(true);
          }}
        />

        <RadioButton
          name={"selectedCustomers"}
          value="selected_customers"
          disabled={product.status === "archived" || !product.is_editable}
          checked={accessMode === "selected_customers"}
          optionTitle={t("Selected Customers and Distributors")}
          handleChange={() => {
            setAccessConfirmDialogMessage(
              t(
                "Are you sure you want to change the access from all customers and distributors to selected customers and distributors?"
              )
            );
            setTransientAccessMode("selected_customers");
            setShowAccessDialog(true);
          }}
        />
      </RadioSection>
      {accessMode === "all_customers" && (
        <H3 style={{ marginTop: "28px" }}>
          {t(
            "All of your customers & distributors have access to this product information"
          )}
        </H3>
      )}
      {accessMode === "selected_customers" && (
        <CustomersList product={product} />
      )}
      {/* confirm dialog for access */}
      <ConfirmDialog
        show={showAccessDialog}
        closeDialog={() => {
          setShowAccessDialog(false);
          setTransientAccessMode(undefined);
        }}
        confirmMessage={accessConfirmDialogMessage}
        handleConfirm={() => {
          setAccessMode(transientAccessMode!);
          setShowAccessDialog(false);
          confirmAccessMode(transientAccessMode!);
        }}
      />
      <ConfirmDialog
        show={showTeamDialog}
        closeDialog={() => {
          setShowTeamDialog(false);
          setTransientTeamVisibility(undefined);
        }}
        confirmMessage={teamConfirmDialogMessage}
        handleConfirm={() => {
          setTeamVisibility(transientTeamVisibility!);
          setShowTeamDialog(false);
          confirmTeamVisibility(transientTeamVisibility!);
        }}
      />
    </AccessHomePageWrapper>
  );
};
