import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled, { useTheme } from "styled-components";
import useSWR from "swr";
import { CheckBoxNoLabel } from "../../../../components/CheckBoxes/CheckBoxes";
import { InfoIcon, LoadingIcon } from "../../../../components/Icons/Icons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { Table } from "../../../../components/Table/Table";
import {
  H3,
  SmallSectionHeaderRegular,
} from "../../../../components/Typography/Typography";
import { endpoints } from "../../../../endpoints";
import type { StorefrontSummarySchema, UUID } from "../../../../types/types";
import type { PIMProduct } from "../../../../types/types.PIM";
import { useStoreState } from "../../../../util/util";
import { useAuthContext } from "../../../../components/Auth";

type TableProductConfig = {
  connection: string;
  enabled: boolean;
};
interface IProductVisibilityData {
  visible_tenants: {
    id: UUID;
    name: string;
    slug: string;
  }[];
  visible_storefronts: StorefrontSummarySchema[];
}

const EnableCell = styled.span`
  svg {
    vertical-align: text-bottom;
    margin-left: 3px;
  }
`;

export function ProductConnections({ product }: { product: PIMProduct }) {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const [storeLoading, setStoreLoading] = useState<boolean>(false);
  const { storefront_id, tenant_id, slug } = useStoreState();
  const [tableData, setTableData] = useState<TableProductConfig[]>([]);
  const theme = useTheme();
  const { hasPermission } = useAuthContext();
  const {
    data: productVisibilityData,
    error: productVisibilityError,
    mutate: mutateProductVisibility,
  } = useSWR<IProductVisibilityData, AxiosError>(
    product.id
      ? endpoints.v2_tenants_id_pim_products_id_visibility(
          tenant_id,
          product.id
        )
      : null
  );
  const isLoading = !productVisibilityData && !productVisibilityError;

  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Connection"),
        accessor: "connection",
      },
      {
        Header: () => {
          return (
            <EnableCell>
              {t("Enable/Disable")}
              <span
                data-tip={t(
                  "Unchecking the box will hide the product information from the storefront"
                )}
              >
                <InfoIcon
                  fill={theme.secondaryIconColor}
                  width={14}
                  height={14}
                />
              </span>
              <ReactTooltip delayHide={500} clickable effect="solid" />
            </EnableCell>
          );
        },
        accessor: "enabled",
        Cell: ({ value }: { value: boolean }) => {
          const handleCheckBoxChange = async (
            e: React.ChangeEvent<HTMLInputElement>
          ) => {
            const isChecked = e.target.checked;
            setStoreLoading(true);
            if (isChecked) {
              try {
                await Axios.post(
                  endpoints.v2_tenants_id_pim_products_id_visibility(
                    tenant_id,
                    product.id
                  ),
                  { storefront_id_or_slug: storefront_id }
                );
                mutateProductVisibility();
                notifySuccess("Product connections have been  enabled");
              } catch (error) {
                notifyError(
                  "Cannot enable product connections. Please try again later.",
                  {
                    error,
                  }
                );
                setStoreLoading(false);
              }
            } else {
              try {
                await Axios.delete(
                  endpoints.v2_tenants_id_pim_products_id_visibility(
                    tenant_id,
                    product.id
                  ),
                  {
                    data: {
                      storefront_id_or_slug: storefront_id,
                    },
                  }
                );
                notifySuccess("Product connections have been disabled");
                mutateProductVisibility();
              } catch (error) {
                notifyError(
                  "Cannot disable product connections. Please try again later.",
                  {
                    error,
                  }
                );
                setStoreLoading(false);
              }
            }
          };
          return (
            <>
              {storeLoading ? (
                <span style={{ margin: "3px 15px 3px 0" }}>
                  <LoadingIcon width={16} height={18} />
                </span>
              ) : (
                <>
                  <CheckBoxNoLabel
                    name="enabled"
                    disabled={
                      !hasPermission("modify_products") || !product.is_editable
                    }
                    checked={value}
                    onChange={handleCheckBoxChange}
                    data-tip={
                      !product.is_editable
                        ? t(
                            "This product is assigned to 1 or more teams and cannot be edited"
                          )
                        : ""
                    }
                    data-for="checkbox"
                  />
                  <ReactTooltip id="checkbox" />
                </>
              )}
            </>
          );
        },
      },
    ],
    [
      t,
      storeLoading,
      theme,
      mutateProductVisibility,
      notifyError,
      notifySuccess,
      product,
      storefront_id,
      tenant_id,
      hasPermission,
    ]
  );

  useEffect(() => {
    if (productVisibilityData) {
      setTableData([
        {
          connection: t("Storefront"),
          enabled: productVisibilityData.visible_storefronts.some(
            ({ slug: storefrontSlug }) => storefrontSlug === slug
          ),
        },
      ]);
      setStoreLoading(false);
    }
  }, [productVisibilityData, t, slug]);

  return (
    <>
      <H3 style={{ margin: "35px 0 5px" }}>{t("External Connections")}</H3>
      <SmallSectionHeaderRegular>
        {t(
          "Control accessibility of your product information for your external connections"
        )}
      </SmallSectionHeaderRegular>
      <Table
        columns={tableColumns}
        data={tableData}
        isLoading={isLoading}
        error={productVisibilityError}
      />
    </>
  );
}
