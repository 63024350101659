import React, { forwardRef, useRef, useEffect } from "react";
import { CheckBoxNoLabel } from "../CheckBoxes/CheckBoxes";

export type ICheckboxProps = {
  indeterminate: boolean;
};

export const TableCheckBox = forwardRef<HTMLInputElement, ICheckboxProps>(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef<HTMLInputElement>();
    const resolvedRef: any = ref || defaultRef;
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <CheckBoxNoLabel
          ref={resolvedRef}
          {...rest}
          onClick={(e) => e.stopPropagation()}
        />
      </>
    );
  }
);
