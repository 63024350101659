import React, { useContext, useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import styled from "styled-components/macro";
import * as Yup from "yup";
import "yup-phone";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import { BuyerCart } from "../../../components/Cart/Cart";
import {
  GoBackButton,
  PrimaryButtonLarge,
  TextButton,
} from "../../../components/Buttons/Buttons";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
} from "../../../components/Form/Form";
import { DesktopFormContainerCart } from "../../../components/Layout/Layout";
import useSWR from "swr";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import type { AxiosError } from "axios";
import Axios from "axios";
import { H3, Title } from "../../../components/Typography/Typography";
import { ColoredTextOnError } from "../../../util/util-components";
import { Form } from "../../../layout/FormLayout";
import type {
  POCManualFormSchemaType,
  POCFormSchemaType,
} from "../../../util/util";
import {
  addressToOption,
  convertPaymentTermToOption,
  convertDeliveryTermToOption,
  convertPaymentModeToOption,
  useFormWrapper,
  makeDeliveryTermsGetEndpoint,
  makePaymentTermsGetEndpoint,
  makePaymentModesGetEndpoint,
  useStoreState,
  markDefaultTerm,
  usePolicyDocuments,
  POCManualFormSchema,
  POCFormSchema,
} from "../../../util/util";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { useHistory } from "react-router-dom";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import type {
  OptionType,
  Product,
  IOrderItem,
  Tenant,
  DeliveryTermPaginatedOutput,
  PaymentTermPaginatedOutput,
  PaymentModePaginatedOutput,
  QuoteRequestItem,
  QuoteRequestPatchArgs,
  User,
  IQuoteRequest,
  UnifiedCartItemPatchArgs,
  StorefrontUnifiedCart,
  IAddress,
} from "../../../types/types";
import type { ISubmitQuoteItemForm } from "../../../components/quoteCart/BuyerQuoteItemForm";
import { strings } from "../../../util/strings";
import { BuyerQuoteStatus } from "../../../components/QuoteStatus";
import { useRoutePath } from "../../../util/Routing";
import { endpoints } from "../../../endpoints";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import type { SetRequired } from "type-fest";
import { SelectBoxWithConfirmDialog } from "../../../components/SelectBoxWithConfirmDialog/SelectBoxWithConfirmDialog";
import noop from "lodash/noop";
import { BuyerQuoteItemFormForCart } from "../../../components/quoteCart/BuyerQuoteItemFormForCart";
import { PrivacyPolicyLink } from "../../../components/PrivacyPolicyLink/PrivacyPolicyLink";
import { TermsOfServiceLink } from "../../../components/TermsOfServiceLink/TermsOfServiceLink";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import type { PIMProduct } from "../../../types/types.PIM";
import { newShippingAddress } from "../../../components/quoteCart/cartUtils";
import type { RequestAddressSchemaType } from "../../public/RequestAddress/RequestAddress";
import {
  getRequestAddressSchema,
  RequestAddress,
} from "../../public/RequestAddress/RequestAddress";
import { PointOfContact } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/PointOfContactForm";
import { useAddLocation } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/EditLocations";
import type { LocationInputs } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/LocationForm";
import {
  configured_checkboxes_schema_yup,
  create_configured_checkboxes_fields,
} from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";
import { UnlistedQuoteItemForm } from "../RequestUnlistedProduct/UnlistedQuoteItemForm";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.secondaryBG};
  border: solid 1px ${({ theme }) => theme.primaryBorder};
  border-radius: 6px;
  padding: 24px 24px 36px;
  margin: 15px 0px;
`;

const FormWrapper = styled.div`
  max-width: 500px;
`;

const ConfirmationSpacer = styled.div`
  margin: 15px 0 10px;
`;

const FlexRow = styled.div`
  display: flex;
  & > div {
    flex-grow: 1.5;
    margin-right: 9px;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type CartSubmissionKeys =
  | "delivery_term_id"
  | "payment_term_id"
  | "payment_mode_id"
  | "required_eta"
  | "shipping_address_id";

type ICartSubmission = SetRequired<
  Pick<QuoteRequestPatchArgs, CartSubmissionKeys>,
  CartSubmissionKeys
>;

interface ICartForm extends RequestAddressSchemaType {
  delivery_term: OptionType<string>;
  payment_term: OptionType<string>;
  payment_mode: OptionType<string>;
  shipping_address: OptionType<string>;
  required_eta: string;
  confirmation_tos: boolean;
  configured_checkboxes?: { value: boolean; label: string }[];
}

interface ICartFormPOCManual extends ICartForm, POCManualFormSchemaType {}

interface ICartPOCForm extends ICartForm, POCFormSchemaType {}

const schema = (t: TFunction, pocType: "manual" | "existing") =>
  Yup.lazy((formValues: any) =>
    Yup.object().shape({
      delivery_term: Yup.object().required(strings(t).thisIsARequiredField),
      payment_term: Yup.object().required(strings(t).thisIsARequiredField),
      payment_mode: Yup.object().required(strings(t).thisIsARequiredField),
      // The shipping_address is not submitted with the cart form because it should
      // already be set.  We still validate it because if for some reason it is not
      // set, we don't want to submit the cart without a shipping_address.
      shipping_address: Yup.object().required(strings(t).thisIsARequiredField),
      required_eta: Yup.string().required(strings(t).thisIsARequiredField),
      confirmation_tos: Yup.boolean().oneOf([true]),
      configured_checkboxes: configured_checkboxes_schema_yup({
        formValues,
        t,
      }),
      ...(formValues.shipping_address?.value === "new_address"
        ? {
            ...getRequestAddressSchema(t, true),
            ...(pocType === "manual"
              ? POCManualFormSchema(t, formValues)
              : POCFormSchema(t)),
          }
        : {}),
    })
  );

/**
 * Quote and reorder cart for logged in buyer. This page should only be
 * accessible if there are items in either cart. If there are no items the
 * user will be redirected away.
 */
export const BuyerQuoteOrReorderCart = ({
  user,
  goToParentPage,
  quoteRequest,
  mutateQuoteRequest,
}: {
  user: User;
  goToParentPage: () => void;
  quoteRequest?: IQuoteRequest;
  mutateQuoteRequest: () => void;
}) => {
  const {
    storefront_id,
    tenant_id,
    storefront_metadata: { configured_checkboxes },
  } = useStoreState();
  const { t } = useTranslation();
  const { privacyPolicy, termsOfSalePolicy } = usePolicyDocuments();
  const history = useHistory();
  const { notifySuccess, notifyError } = useContext(Notifications);

  const addLocation = useAddLocation({ buyer_tenant_id: user.tenant_id });

  const [pointOfContactType, setPointOfContactType] =
    useState<"manual" | "existing">("existing");

  const quoteRequestItems = quoteRequest?.items ?? [];
  const cartItems = quoteRequest?.items;

  const { accountPath } = useRoutePath();

  const methodsOfUseForm = useFormWrapper<ICartForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldFocusError: false,
    resolver: yupResolver(schema(t, pointOfContactType)),
  });

  const {
    register,
    handleSubmit,
    formState,
    errors,
    control,
    watch,
    getValues,
    setValue,
  } = methodsOfUseForm;

  const [editCartItemSlider, setEditCartItemSlider] = useState(false);
  const [product, setProduct] = useState<Product>();
  const [loading, setLoading] = useState(false);

  const { fields, append } = useFieldArray({
    control,
    name: "configured_checkboxes",
  });

  const quoteCheckboxes = configured_checkboxes.filter((item) => item.quote);
  useEffect(() => {
    if (quoteCheckboxes.length > 0 && fields?.length < 1) {
      quoteCheckboxes.forEach((element) => {
        append({ value: false, label: element.name, id: element.id });
      });
    }
  }, [quoteCheckboxes, append, fields]);

  const onFormSubmit = async <T extends ICartForm>(data: T) => {
    if (!loading) {
      setLoading(true);
      const validatedData =
        pointOfContactType === "manual"
          ? ((await schema(t, pointOfContactType).validate(
              data
            )) as ICartFormPOCManual)
          : ((await schema(t, pointOfContactType).validate(
              data
            )) as ICartPOCForm);
      let newAddress: OptionType<string> | null = null;
      if (data.shipping_address.value === "new_address") {
        const inputs: LocationInputs = {
          company_name: validatedData?.company_name ?? "",
          external_id: validatedData?.external_id ?? "",
          address1: validatedData.address1,
          address2: validatedData.address2,
          country: validatedData.country,
          county: validatedData.county,
          state: validatedData.state,
          city: validatedData.city,
          postal_code: validatedData.postal_code,
          is_active: true,
          contact_first_name: (validatedData as ICartFormPOCManual)
            .contact_first_name,
          contact_last_name: (validatedData as ICartFormPOCManual)
            .contact_last_name,
          email_address: (validatedData as ICartFormPOCManual).email_address,
          phone_number: (validatedData as ICartFormPOCManual).phone_number,
          country_code: (validatedData as ICartFormPOCManual).country_code,
          point_of_contact_id: (validatedData as ICartPOCForm)
            .point_of_contact_id,
        };
        const newLocation = await addLocation({
          inputs,
          pocType: pointOfContactType,
        });
        if (newLocation) {
          newAddress = addressToOption(newLocation);
        }
      }
      const hasAddress =
        data.shipping_address.value === "new_address"
          ? Boolean(newAddress)
          : Boolean(data.shipping_address.value);
      if (cartItems?.length && quoteRequest && hasAddress) {
        try {
          const cartSubmission: ICartSubmission = {
            delivery_term_id: data.delivery_term.value,
            payment_term_id: data.payment_term.value,
            payment_mode_id: data.payment_mode.value,
            required_eta: data.required_eta,
            shipping_address_id: newAddress
              ? newAddress.value
              : data.shipping_address.value,
          };
          // First add the form data to the quote request.
          const { data: transaction } = await Axios.post(
            `/v1/storefronts/${storefront_id}/unified-cart/submit`,
            cartSubmission
          );

          // relying on backend guarantees that one will always exist with a
          // success response.
          if (transaction.order) {
            history.push(`${accountPath}/orders`);
          } else if (transaction.quote) {
            history.push(`${accountPath}/quotes`);
          }
          notifySuccess(t("Cart submitted successfully"));
        } catch (error) {
          if (newAddress) {
            try {
              await Axios.delete(
                `/v1/tenants/${user.tenant_id}/addresses/${newAddress.value}`
              );
            } catch (_) {
              notifyError(t("There was an error cleaning up the address."));
            }
          }
          notifyError(
            t("There was an error submitting the cart, please try again."),
            { error }
          );
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const fetchProduct = async (productId: string) => {
    const product = await Axios.get<PIMProduct>(
      endpoints.v2_tenants_id_pim_products_id(tenant_id, productId)
    );
    setProduct(product.data);
  };
  const [cartItemToEdit, setCartItemToEdit] =
    useState<QuoteRequestItem | null>(null);

  const editCartItem = async (itemToEdit: QuoteRequestItem) => {
    if (itemToEdit.product_id) {
      await fetchProduct(itemToEdit.product_id);
    }
    setCartItemToEdit(itemToEdit);
    setEditCartItemSlider(true);
  };

  /**
   * Returns a function that will be passed to the quote item form component
   * where it is called to submit the form. The returned function captures the
   * item ID (via closure) so the ID can be used to submit the form.
   */
  const makeSubmitQuoteItemFormFunction = (
    itemId: string
  ): ISubmitQuoteItemForm => {
    const submitQuoteItemForm: ISubmitQuoteItemForm = async (
      unifiedCartArg
    ) => {
      if (quoteRequest) {
        // Any errors thrown are caught where this function is called.
        const {
          sku_id,
          no_of_units,
          applications,
          custom_application,
          total_quantity,
          packaging_unit_id,
          custom_packaging_type_id,
          custom_packaging_quantity,
          unlisted_product_name,
        } = unifiedCartArg;

        const requestBody: UnifiedCartItemPatchArgs = {
          applications,
          ...(custom_application ? { custom_application } : {}),
          ...(unlisted_product_name ? { unlisted_product_name } : {}),
          sku_id,
          no_of_units,
          total_quantity,
          packaging_unit_id,
          custom_packaging_type_id,
          custom_packaging_quantity,
        };
        const endpoint = `/v1/storefronts/${storefront_id}/unified-cart/items/${itemId}`;
        await Axios.patch<StorefrontUnifiedCart>(endpoint, requestBody);

        setEditCartItemSlider(false);
        setCartItemToEdit(null);
        await mutateQuoteRequest();
      }
    };
    return submitQuoteItemForm;
  };

  const removeCartItem = async (
    itemToRemove: QuoteRequestItem | IOrderItem
  ) => {
    const errorMessage = t(
      "There was an error removing the item, please try again"
    );

    try {
      await Axios.delete(
        `/v1/storefronts/${storefront_id}/quotes/${quoteRequest?.id}/items/${itemToRemove.id}`
      );

      notifySuccess(
        // product_brand_name left in place to avoid churn in the translation file.
        t(`{{product_brand_name}} successfully removed`, {
          product_brand_name: itemToRemove.product.name,
        })
      );
      await mutateQuoteRequest();
    } catch (error) {
      notifyError(errorMessage, { error });
    }
  };

  const handleEditCartItem = (state: boolean) => {
    if (!state) {
      setProduct(undefined);
    }
    setEditCartItemSlider(state);
  };
  // TODO: discuss whether we can get rid of this.
  // NOTE: The following should (almost) always already exist in the cart data:
  //   - shipping_address
  //   - delivery_term
  //   - payment_term
  // That's because they are now set when first adding an item to the cart.
  // However, they won't exist yet in the migration case: carts that were
  // created before we made the change to require those things to be set when
  // adding items to the cart rather than when submitting the cart.
  //
  // Later we could remove the following code that handles this migration case
  // where these things have not been set yet. However, it may be worth keeping
  // this code around, so that we handle both the defined-already and
  // the not-defined-yet cases, since things may change.

  /**
   * Possibly fetch tenant data to get the shipping address.
   * Only needed for quote requests, not needed for re-orders.
   * (There is no migration case here, because re-orders have always had the
   * shipping_address set when adding an item to the re-order cart.)
   */
  const { data: tenantData } = useSWR<Tenant, AxiosError>(
    quoteRequest
      ? endpoints.v1_storefronts_id_tenants_id(storefront_id, user.tenant_id)
      : null,
    {
      onError: (error) => {
        notifyError(t("There was an error fetching your shipping addresses"), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { data: fetchedDeliveryTerms } = useSWR<DeliveryTermPaginatedOutput>(
    makeDeliveryTermsGetEndpoint(storefront_id),
    {
      onError: (error) => {
        notifyError(t("There was an error fetching the shipping terms."), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { data: fetchedPaymentTerms } = useSWR<PaymentTermPaginatedOutput>(
    makePaymentTermsGetEndpoint(storefront_id),
    {
      onError: (error) => {
        notifyError(t("There was an error fetching the payment terms."), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { data: fetchedPaymentModes } = useSWR<PaymentModePaginatedOutput>(
    makePaymentModesGetEndpoint(storefront_id),
    {
      onError: (error) => {
        notifyError(t("There was an error fetching the payment methods."), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  // Re-orders get their shipping address from the order they are redoing.
  const shippingAddresses = tenantData
    ? tenantData.addresses.filter((addr) => addr.type === "Warehouse")
    : [];

  const deliveryTerms = fetchedDeliveryTerms ? fetchedDeliveryTerms.data : [];
  const paymentTerms = fetchedPaymentTerms ? fetchedPaymentTerms.data : [];
  const paymentModes = fetchedPaymentModes ? fetchedPaymentModes.data : [];

  const shippingAddressOptions = [
    ...shippingAddresses.map(addressToOption),
    newShippingAddress(t),
  ];
  const deliveryTermsOptions = deliveryTerms.map(convertDeliveryTermToOption);
  const paymentTermsOptions = paymentTerms.map(convertPaymentTermToOption);
  const paymentModesOptions = paymentModes.map(convertPaymentModeToOption);

  const shippingAddressDefaultValue =
    quoteRequest && quoteRequest.shipping_address
      ? addressToOption(quoteRequest.shipping_address)
      : newShippingAddress(t);

  if (shippingAddressDefaultValue && !getValues("shipping_address")) {
    setValue("shipping_address", shippingAddressDefaultValue);
  }
  const deliveryTermsDefaultValue =
    quoteRequest && quoteRequest.delivery_term
      ? convertDeliveryTermToOption(quoteRequest.delivery_term)
      : undefined;
  const userDeliveryTermsDefaultValue = user.settings?.default_delivery_term
    ? convertDeliveryTermToOption(user.settings.default_delivery_term)
    : undefined;

  if (deliveryTermsDefaultValue && !getValues("delivery_term")) {
    setValue("delivery_term", deliveryTermsDefaultValue);
  }

  const paymentTermsDefaultValue =
    quoteRequest && quoteRequest.payment_term
      ? convertPaymentTermToOption(quoteRequest.payment_term)
      : undefined;
  const userPaymentTermsDefaultValue = user.settings?.default_payment_term
    ? convertPaymentTermToOption(user.settings.default_payment_term)
    : undefined;

  if (paymentTermsDefaultValue && !getValues("payment_term")) {
    setValue("payment_term", paymentTermsDefaultValue);
  }

  const paymentModeDefaultValue = user.settings?.default_payment_mode
    ? convertPaymentModeToOption(user.settings.default_payment_mode)
    : undefined;
  const userPaymentModeDefaultValue = user.settings?.default_payment_mode
    ? convertPaymentModeToOption(user.settings.default_payment_mode)
    : undefined;

  // TS doesn't know it but these can be undefined. Maybe the form type could
  // be altered to avoid having to do the declarations here?
  const watchedShippingAddress = watch("shipping_address") as
    | OptionType<string>
    | undefined;

  const watchedDeliveryTerm = watch("delivery_term") as
    | OptionType<string>
    | undefined;

  const watchedPaymentTerm = watch("payment_term") as
    | OptionType<string>
    | undefined;

  // These are needed for the useEffect dependency array below (sigh...).
  const watchedShippingAddressValue = watchedShippingAddress?.value;
  const watchedDeliveryTermValue = watchedDeliveryTerm?.value;
  const watchedPaymentTermValue = watchedPaymentTerm?.value;

  const shippingAddressChanged =
    shippingAddressDefaultValue &&
    watchedShippingAddressValue &&
    shippingAddressDefaultValue.value !== watchedShippingAddressValue;

  const deliveryTermsChanged =
    deliveryTermsDefaultValue &&
    watchedDeliveryTermValue &&
    deliveryTermsDefaultValue.value !== watchedDeliveryTermValue;

  const paymentTermsChanged =
    paymentTermsDefaultValue &&
    watchedPaymentTermValue &&
    paymentTermsDefaultValue.value !== watchedPaymentTermValue;

  const allItemsHavePrice = quoteRequest?.items.every(
    (item) => !!item.price_per_unit
  );

  const submitButtonText = allItemsHavePrice
    ? t("Submit Order")
    : t("Submit Quote Request");

  const showDialogue = !!cartItems?.some((item) => item.price_per_unit);
  const CONFIRMATION_STRING = t(
    "Changing Terms will affect pricing for products that have tiered pricing. Are you sure you want to continue?"
  );

  useEffect(() => {
    // If the user changed something that affects price tiers, patch the cart
    // and refresh the data, so the changed price tiers reality will appear.
    if (
      // quoteRequest should always be defined here.
      quoteRequest &&
      (shippingAddressChanged || deliveryTermsChanged || paymentTermsChanged) &&
      watchedShippingAddressValue !== "new_address"
    ) {
      (async () => {
        try {
          const requestBody: QuoteRequestPatchArgs = {
            shipping_address_id: watchedShippingAddressValue,
            delivery_term_id: watchedDeliveryTermValue,
            payment_term_id: watchedPaymentTermValue,
          };
          await Axios.patch(
            `/v1/storefronts/${storefront_id}/quotes/${quoteRequest.id}`,
            requestBody
          );

          // TODO: unfortunately, to get the price_per_uom to update on the cart
          // items, we have to do a bogus no-op PATCH for each of them.
          const promises = quoteRequest.items.map((item) => {
            return Axios.patch(
              `/v1/storefronts/${storefront_id}/quotes/${quoteRequest.id}/items/${item.id}`,
              {
                // no_of_units: item.no_of_units,
                // TODO: commenting out `no_of_units` here is a quick fix for
                // https://app.asana.com/0/1200568265088077/1201194678268727
                // but there's more to do to get this fully working.
                // Currently the price tiers are not being updated on the
                // cart items on the cart page even with no_of_units being
                // patched here, so that needs fixing. (We need to patch
                // something so the patch succeeds, and product_id is harmless.)
                product_id: item.product_id,
              }
            );
          });
          await Promise.all(promises);
          await mutateQuoteRequest();
        } catch (error) {
          notifyError(t("There was an error updating the price tier details"), {
            error,
          });
        }
      })();
    }
  }, [
    quoteRequest,
    shippingAddressChanged,
    deliveryTermsChanged,
    paymentTermsChanged,
    watchedShippingAddressValue,
    watchedDeliveryTermValue,
    watchedPaymentTermValue,
    mutateQuoteRequest,
    notifyError,
    t,
    storefront_id,
  ]);

  const titleString = t("Cart");
  const backButtonString = t("Back");

  if (!cartItems || cartItems.length === 0) {
    // The cart will become empty on successful submit and also if the user
    // removes all of the cart items.
    return null;
  }

  const termsOfSaleLink = () => {
    return !!termsOfSalePolicy ? (
      !!privacyPolicy ? (
        <>
          {t("I agree to the ")}
          <TermsOfServiceLink />
          {t(" & ")}
        </>
      ) : (
        <>
          {t("I accept the ")}
          <TermsOfServiceLink />
          {"."}
        </>
      )
    ) : null;
  };

  const privacyPolicyLink = () => {
    return !!privacyPolicy ? (
      !!termsOfSalePolicy ? (
        <>
          <PrivacyPolicyLink customPolicy />
          {"."}
        </>
      ) : (
        <>
          {t("I accept the ")}
          <PrivacyPolicyLink customPolicy />
          {"."}
        </>
      )
    ) : null;
  };

  return (
    <Wrapper>
      <DesktopFormContainerCart>
        <TextButton onClick={goToParentPage}>
          <GoBackButton text={backButtonString} />
        </TextButton>
        <Row>
          <Title style={{ fontWeight: 600 }}>{titleString}</Title>
          {!!quoteRequest && (
            <BuyerQuoteStatus
              status={quoteRequest.status}
              position={"right"}
              t={t}
            />
          )}
        </Row>
        <BuyerCart
          cartItems={quoteRequestItems}
          edit={editCartItem}
          remove={removeCartItem}
        />
        <Container>
          <>
            <H3>{t("Send your request")}</H3>
            <FormWrapper>
              <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                <FlexRow>
                  <DatePicker
                    label={t("Requested ETA")}
                    name={"required_eta"}
                    methodsOfUseForm={methodsOfUseForm}
                    required={true}
                  />
                </FlexRow>
                <FlexRow>
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="payment_mode"
                    placeholder={t("Payment Method")}
                    options={markDefaultTerm(
                      paymentModesOptions,
                      userPaymentModeDefaultValue
                    )}
                    defaultValue={paymentModeDefaultValue}
                    rules={{ required: true }}
                    errors={errors}
                    formState={formState}
                    setDefaultTerm={!!userPaymentModeDefaultValue}
                  />
                </FlexRow>
                <FlexRow>
                  <SelectBoxWithConfirmDialog
                    name="shipping_address"
                    placeholder={t("Shipping Address")}
                    options={shippingAddressOptions}
                    defaultOption={shippingAddressDefaultValue}
                    methodsOfUseForm={methodsOfUseForm}
                    showDialogOnChange={showDialogue}
                    handleConfirm={noop}
                    confirmMessage={CONFIRMATION_STRING}
                  />
                </FlexRow>
                <FlexRow>
                  <SelectBoxWithConfirmDialog
                    name="delivery_term"
                    placeholder={t("Shipping Terms")}
                    options={markDefaultTerm(
                      deliveryTermsOptions,
                      userDeliveryTermsDefaultValue
                    )}
                    defaultOption={deliveryTermsDefaultValue}
                    methodsOfUseForm={methodsOfUseForm}
                    showDialogOnChange={showDialogue}
                    handleConfirm={noop}
                    confirmMessage={CONFIRMATION_STRING}
                    setDefaultTerm={!!userDeliveryTermsDefaultValue}
                  />
                </FlexRow>
                <FlexRow>
                  <SelectBoxWithConfirmDialog
                    name="payment_term"
                    placeholder={t("Requested Payment Terms")}
                    options={markDefaultTerm(
                      paymentTermsOptions,
                      userPaymentTermsDefaultValue
                    )}
                    defaultOption={paymentTermsDefaultValue}
                    methodsOfUseForm={methodsOfUseForm}
                    showDialogOnChange={showDialogue}
                    handleConfirm={noop}
                    confirmMessage={CONFIRMATION_STRING}
                    setDefaultTerm={!!userPaymentTermsDefaultValue}
                  />
                </FlexRow>
                {watchedShippingAddressValue &&
                watchedShippingAddressValue === "new_address" ? (
                  <>
                    <RequestAddress
                      methodsOfUseForm={methodsOfUseForm}
                      hideBillingAddress
                      showBillingAddressOption={false}
                      showLocationId={true}
                      formTitle={t("Add Address")}
                    />
                    <PointOfContact
                      methodsOfUseForm={methodsOfUseForm}
                      pointOfContactType={pointOfContactType}
                      setPointOfContactType={setPointOfContactType}
                      defaultExistingUser={user}
                    />
                  </>
                ) : (
                  <></>
                )}
                <ConfirmationSpacer>
                  {(!!privacyPolicy || !!termsOfSalePolicy) && (
                    <CheckBoxContainer>
                      <div style={{ width: "22px", marginRight: "15px" }}>
                        <CheckBoxNoLabel
                          name="confirmation_tos"
                          id="confirmation_tos"
                          ref={register({ required: true })}
                          error={!!errors.confirmation_tos?.message}
                        />
                      </div>
                      <CheckBoxFinePrintLabel htmlFor="confirmation_tos">
                        <ColoredTextOnError
                          isError={!!errors.confirmation_tos?.message}
                        >
                          <>
                            {termsOfSaleLink()}
                            {privacyPolicyLink()}
                          </>
                        </ColoredTextOnError>
                      </CheckBoxFinePrintLabel>
                    </CheckBoxContainer>
                  )}
                </ConfirmationSpacer>
                {fields.length > 0 && (
                  <>
                    {create_configured_checkboxes_fields({
                      fields,
                      methodsOfUseForm,
                      t,
                    })}
                  </>
                )}
                <PrimaryButtonLarge
                  style={{ minWidth: "242px" }}
                  loading={loading}
                >
                  {submitButtonText}
                </PrimaryButtonLarge>
              </Form>
            </FormWrapper>
          </>
        </Container>
      </DesktopFormContainerCart>

      <SlideOut
        closeFlyout={() => handleEditCartItem(false)}
        show={editCartItemSlider}
      >
        {!!product && !!cartItemToEdit && (
          <BuyerQuoteItemFormForCart
            editingExistingItem={true}
            product={product}
            submitQuoteItemForm={makeSubmitQuoteItemFormFunction(
              cartItemToEdit.id
            )}
            buyerUser={user}
            product_application={cartItemToEdit.product_applications[0]}
            productSku={cartItemToEdit.sku}
            no_of_units={cartItemToEdit.no_of_units}
            total_quantity={cartItemToEdit.total_quantity}
            custom_packaging_quantity={cartItemToEdit.custom_packaging_quantity}
            // TODO: how can quoteRequest not be defined here?
            shippingAddress={
              quoteRequest?.shipping_address ??
              ({
                id: "new_address",
                address1: t("New Address"),
                state: "",
                city: "",
                country: "",
                postal_code: "",
              } as IAddress)
            }
            paymentTerm={quoteRequest?.payment_term}
            deliveryTerm={quoteRequest?.delivery_term}
            allowEditingTransactionValues={false}
            currencyCode={cartItemToEdit.currency}
          />
        )}
        {!product && !!cartItemToEdit && (
          <UnlistedQuoteItemForm
            disableProductName={false}
            editingExistingItem={true}
            submitQuoteItemForm={makeSubmitQuoteItemFormFunction(
              cartItemToEdit.id
            )}
            buyerUser={user}
            product_application={cartItemToEdit.product_applications[0]}
            product_name={cartItemToEdit.product.name}
            productSku={cartItemToEdit.sku}
            no_of_units={cartItemToEdit.no_of_units}
            total_quantity={cartItemToEdit.total_quantity}
            quoteItems={quoteRequest?.items.map((item) => item.product.name)}
            customApplication={cartItemToEdit.custom_application}
            custom_packaging_quantity={cartItemToEdit.custom_packaging_quantity}
            // TODO: how can quoteRequest not be defined here?
            shippingAddress={
              quoteRequest?.shipping_address ??
              ({
                id: "new_address",
                address1: t("New Address"),
                state: "",
                city: "",
                country: "",
                postal_code: "",
              } as IAddress)
            }
            paymentTerm={quoteRequest?.payment_term}
            deliveryTerm={quoteRequest?.delivery_term}
            allowEditingTransactionValues={false}
            currencyCode={cartItemToEdit.currency}
          />
        )}
      </SlideOut>
    </Wrapper>
  );
};
