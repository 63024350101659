import styled from "styled-components/macro";
import type { Assets } from "../../../../../../types/types.PIM";
import { DelayedSpinner } from "../../../../../../components/DelayedSpinner/DelayedSpinner";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { get_content_type, GetDocumentIcon } from "../util/AssetsUtil";
import { useEffect, useState } from "react";

const Container = styled.div<{ is_preview: boolean }>`
  width: 100%;
  height: 100%;
  max-height: ${({ is_preview }) => (is_preview ? "100%" : "234px")};
  cursor: pointer;
  > img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  iframe {
    height: 100%;
    width: 100%;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const VideoUnavailableContainer = styled.div<{ mode: "thumbnail" | "preview" }>`
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  height: 100%;
  > span {
    text-align: center;
    font-size: ${({ mode }) => (mode === "thumbnail" ? "1rem" : "1.5rem")};
  }
`;

type AssetPreviewUrlSchema = {
  url: string;
  thumbnail_url?: string;
};

export const AssetDocViewer = ({
  asset,
  mode,
}: {
  asset: Assets;
  mode: "thumbnail" | "preview";
}) => {
  const { asset_type, id } = asset;
  const { t } = useTranslation();
  const [is_supported_video_format, set_is_supported_video_format] =
    useState(true);
  const [updated_uri, set_updated_uri] = useState(asset.signed_url);
  const [thumbnail_url, set_thumbnail_url] = useState<string | null>(null);

  const { data, error } = useSWR<AssetPreviewUrlSchema>(
    asset.signed_url.includes("https")
      ? null
      : asset.preview_url.replace("/api", ""),
    {
      refreshInterval: 60_000,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    }
  );

  useEffect(() => {
    set_updated_uri(data?.url || asset.signed_url);
    set_thumbnail_url(data?.thumbnail_url || null);
    set_is_supported_video_format(true);
  }, [asset.signed_url, data?.thumbnail_url, data?.url]);

  const is_loading = !data && !error && !asset.signed_url.includes("https");
  const use_thumbnail_url = thumbnail_url !== null && mode === "thumbnail";

  if (error) {
    return (
      <Container is_preview={mode === "preview"}>
        <ErrorContainer>{t("Failed to load the asset")}</ErrorContainer>
      </Container>
    );
  }

  return is_loading ? (
    <DelayedSpinner />
  ) : (
    <Container is_preview={mode === "preview"} id={`doc-preview-${id}`}>
      {asset_type === "document" && (
        <>
          {use_thumbnail_url ? (
            <img
              id={`asset-doc-img-${id}`}
              src={thumbnail_url}
              alt={asset.name}
              title={asset.name}
            />
          ) : (
            <iframe
              src={updated_uri}
              id={`asset-doc-iframe-${id}`}
              title={asset.name}
              loading="lazy"
            />
          )}
        </>
      )}
      {asset_type === "image" && (
        <img
          id={`asset-img-preview-${id}`}
          src={updated_uri}
          alt={asset.name}
          title={asset.name}
        />
      )}
      {asset_type === "video" && (
        <>
          {is_supported_video_format ? (
            <video
              id={`asset-vid-preview-${id}`}
              src={updated_uri}
              controls
              onError={() => set_is_supported_video_format(false)}
            />
          ) : (
            <VideoUnavailableContainer mode={mode}>
              <GetDocumentIcon
                asset_type={asset.asset_type}
                file_type={get_content_type(
                  asset.content_type ?? "application/pdf"
                )}
                width={mode === "thumbnail" ? 34 : 50}
                height={mode === "thumbnail" ? 34 : 50}
              />
              <span>
                {t(
                  "Your browser does not support the {{video_format}} video format.",
                  {
                    video_format: get_content_type(
                      asset.content_type ?? "application/pdf"
                    ),
                  }
                )}
              </span>
            </VideoUnavailableContainer>
          )}
        </>
      )}
    </Container>
  );
};
