import type identity from "lodash/identity";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

const PaginationWrapper = styled.div`
  width: 100%;
`;
type PaginationButtonProps = {
  miniPagination: boolean;
};
const PaginationButton = styled.button<PaginationButtonProps>`
  padding: ${({ miniPagination }) => (miniPagination ? "5px 7px" : "8px 10px")};
  margin: ${({ miniPagination }) => (miniPagination ? "10px 4px" : "24px 4px")};
  cursor: pointer;
  background: ${({ theme }) => theme.tertiaryButtonBG};
  border: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-radius: 4px;
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  min-width: ${({ miniPagination }) => (miniPagination ? "30px" : "35px")};
  &:disabled,
  &:hover {
    background: ${({ theme }) => theme.secondaryBG};
    border: 1px solid ${({ theme }) => theme.secondaryBG};
    color: ${({ theme }) => theme.primaryTextColor};
  }
  &:disabled {
    cursor: default;
  }
`;
const PageBtnWrapper = styled.div`
  display: inline;
  &:first-child ${PaginationButton} {
    margin-left: 0;
  }
`;

export interface IPaginationProps {
  pagination: {
    pageCount: number;
    pageIndex: number;
    perPage: number;
  };
  offset: number;
  handlePageClick: (offset: number) => void;
  t?: (val: string) => string | typeof identity;
  miniPagination?: boolean;
}

export const Pagination = (props: IPaginationProps) => {
  const { perPage, pageIndex, pageCount } = props.pagination;
  const offset = props.offset;
  const handlePageClick = props.handlePageClick;
  const { t } = useTranslation();

  useEffect(() => {
    setVisiblePages(getVisiblePages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageCount, perPage, offset]);

  const filterPages = (visiblePages: Array<number>) => {
    return visiblePages.filter((page) => page <= pageCount);
  };

  const getVisiblePages = () => {
    if (pageCount < 7) {
      return filterPages([1, 2, 3, 4, 5, 6]);
    } else {
      if (pageIndex % 5 >= 0 && pageIndex > 4 && pageIndex + 2 < pageCount) {
        return [
          1,
          pageIndex - 2,
          pageIndex - 1,
          pageIndex,
          pageIndex + 1,
          pageIndex + 2,
          pageCount,
        ];
      } else if (
        pageIndex % 5 >= 0 &&
        pageIndex > 4 &&
        pageIndex + 2 >= pageCount
      ) {
        return [1, pageCount - 3, pageCount - 2, pageCount - 1, pageCount];
      } else {
        return [1, 2, 3, 4, 5, pageCount];
      }
    }
  };

  const [visiblePages, setVisiblePages] = useState(getVisiblePages);

  const changePage = (page: number) => {
    const offset = (page - 1) * perPage || 0;
    handlePageClick(offset);
  };

  return pageCount > 1 ? (
    <PaginationWrapper>
      {pageIndex > 1 && (
        <PaginationButton
          onClick={() => changePage(pageIndex - 1)}
          miniPagination={props.miniPagination ?? false}
        >
          {props.miniPagination ? " < " : t("Previous Page")}
        </PaginationButton>
      )}
      {visiblePages.map((page, index, array) => {
        return (
          <PageBtnWrapper key={page}>
            {array[index - 1] + 2 < page && ` ... `}
            <PaginationButton
              onClick={() => changePage(page)}
              disabled={page === pageIndex}
              miniPagination={props.miniPagination ?? false}
            >
              {page}
            </PaginationButton>
          </PageBtnWrapper>
        );
      })}
      {pageCount > pageIndex && (
        <PaginationButton
          onClick={() => changePage(pageIndex + 1)}
          miniPagination={props.miniPagination ?? false}
        >
          {props.miniPagination ? ">" : t("Next Page")}
        </PaginationButton>
      )}
    </PaginationWrapper>
  ) : (
    <></>
  );
};
