import React from "react";
import styled from "styled-components/macro";
import { Form, SubmitButtonContainer } from "../../../layout/FormLayout";
import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import {
  packagingTypeOrUnitToOption,
  useStorefrontPackagingTypes,
  usePackagingUnits,
} from "../../../util/SkuUtils";
import type {
  IPackagingType,
  IPackagingUnit,
  OptionType,
} from "../../../types/types";
import { useFormWrapper } from "../../../util/util";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorPlaceholder } from "../../../components/Error";
import {
  getSkuFormInputsSchema,
  SkuFormInputs,
} from "../../../components/Skus/SkuFormInputs";
import { useTranslation } from "react-i18next";

interface AddCustomSkuFormValues {
  erp_system_id: string;
  packaging_type: OptionType<string>;
  packaging_unit: OptionType<string>;
  package_volume: number;
  package_description: string;
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  padding: 24px 24px 36px;
  & > * {
    margin-bottom: 20px;
  }
`;

const InnerTitle = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding: 0px 24px;
`;

export interface CustomSkuData {
  erp_system_id: string;
  packaging_type: IPackagingType;
  packaging_unit: IPackagingUnit;
  package_volume: number;
  package_description: string;
}

/**
 * Form for adding a custom SKU to a quote request in response to a buyer's
 * request for a custom SKU.
 */
export const AddCustomSkuForm = ({
  customSkuData,
  setCustomSkuData,
  closeForm,
}: {
  customSkuData?: CustomSkuData;
  setCustomSkuData: (customSkuData: CustomSkuData) => void;
  closeForm: () => void;
}) => {
  const { packagingTypes, packagingTypesError } = useStorefrontPackagingTypes();
  const { packagingUnits, packagingUnitsError } = usePackagingUnits();

  if (packagingTypesError || packagingUnitsError) {
    return (
      <ErrorPlaceholder
        message={"There was an error getting the packaging options."}
      />
    );
  }
  if (packagingTypes && packagingUnits) {
    return (
      <AddCustomSkuFormContent
        customSkuData={customSkuData}
        closeForm={closeForm}
        setCustomSkuData={setCustomSkuData}
        packagingTypes={packagingTypes}
        packagingUnits={packagingUnits}
      />
    );
  }
  // loading
  return null;
};

/**
 * The actual content of the Add Custom SKU form.
 * One reason this is a separate component is so that the packaging options
 * (type and unit) are already available on the first render of the component
 * so that pre-filling those values works correctly.
 */
const AddCustomSkuFormContent = ({
  customSkuData,
  setCustomSkuData,
  closeForm,
  packagingTypes,
  packagingUnits,
}: {
  customSkuData?: CustomSkuData;
  setCustomSkuData: (customSkuData: CustomSkuData) => void;
  closeForm: () => void;
  packagingTypes: IPackagingType[];
  packagingUnits: IPackagingUnit[];
}) => {
  const { t } = useTranslation();
  const packagingTypeOptions = packagingTypes.map(packagingTypeOrUnitToOption);
  const packagingUnitOptions = packagingUnits.map(packagingTypeOrUnitToOption);

  const editSkuSchema = yup.object().shape({
    ...getSkuFormInputsSchema(t),
  });

  const methodsOfUseForm = useFormWrapper<AddCustomSkuFormValues>({
    resolver: yupResolver(editSkuSchema),
    defaultValues: {
      erp_system_id: customSkuData?.erp_system_id,
      packaging_unit: packagingUnitOptions.find(
        (option) => option.value === customSkuData?.packaging_unit.id
      ),
      packaging_type: packagingTypeOptions.find(
        (option) => option.value === customSkuData?.packaging_type.id
      ),
      package_description: customSkuData?.package_description,
      package_volume: customSkuData?.package_volume,
    },
  });

  const { handleSubmit } = methodsOfUseForm;

  const onSubmit = async (formValues: AddCustomSkuFormValues) => {
    const {
      erp_system_id,
      packaging_type,
      packaging_unit,
      package_volume,
      package_description,
    } = formValues;

    const packagingType = packagingTypes.find(
      (type) => type.id === packaging_type.value
    );
    const packagingUnit = packagingUnits.find(
      (unit) => unit.id === packaging_unit.value
    );

    if (packagingType && packagingUnit) {
      const customSkuData: CustomSkuData = {
        erp_system_id,
        packaging_type: packagingType,
        packaging_unit: packagingUnit,
        package_volume: package_volume,
        package_description: package_description,
      };

      setCustomSkuData(customSkuData);
      closeForm();
    } else {
      // Should never happen.
      console.error(
        "Undefined packaging type or packaging unit",
        packagingType,
        packagingUnit
      );
    }
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <InnerTitle>Add Custom SKU</InnerTitle>
        <InputWrapper>
          <SkuFormInputs
            methodsOfUseForm={methodsOfUseForm}
            packagingTypeOptions={packagingTypeOptions}
            packagingUnitOptions={packagingUnitOptions}
          />
          <SubmitButtonContainer>
            <PrimaryButtonFitContainer>
              Add Custom SKU
            </PrimaryButtonFitContainer>
          </SubmitButtonContainer>
        </InputWrapper>
      </Form>
    </>
  );
};
