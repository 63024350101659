import { useContext, useState } from "react";
import type { AxiosError } from "axios";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PrimaryButtonFitContainer } from "../../../../../components/Buttons/Buttons";
import {
  CheckBoxContainer,
  SectionTitle,
  CheckBoxFinePrintLabel,
} from "../../../../../components/Form/Form";
import { HeaderLeft } from "../../../../../components/Layout/Layout";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { endpoints } from "../../../../../endpoints";
import { Form } from "../../../../../layout/FormLayout";
import {
  isAxiosError,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import type {
  AssetsExternalLinks,
  PIMProduct,
} from "../../../../../types/types.PIM";
import type { DataMutate } from "../../../../../types/types";
import { useParams } from "react-router-dom";
import { CheckBoxNoLabel } from "../../../../../components/CheckBoxes/CheckBoxes";

const MarginBottomHeaderLeft = styled(HeaderLeft)`
  margin-bottom: 28px;
`;

export const AddandEditLink = ({
  formType,
  editData,
  refreshAssetsList,
  onSuccess,
}: {
  formType: string;
  editData?: AssetsExternalLinks;
  onSuccess: () => void;
  refreshAssetsList: DataMutate<PIMProduct>;
}) => {
  const { t } = useTranslation();

  const isAddForm = formType === "add";

  const defaultURL = editData?.url;
  const defaultName = editData?.name;
  const defaultIsVisible = editData?.is_visible;
  const linkId = editData?.id;

  const methodsOfUseForm = useFormWrapper();
  const { tenant_id } = useStoreState();

  const { product_id } = useParams<{ product_id: string }>();

  const [submitting, setSubmitting] = useState(false);
  const [isVisible, setIsVisible] =
    useState<boolean | undefined>(defaultIsVisible);
  const { notifySuccess, notifyError } = useContext(Notifications);

  const { handleSubmit, errors, formState, register } = methodsOfUseForm;

  const onSubmit = async (values: any) => {
    const formData = {
      ...values,
      is_visible: isVisible ?? false,
      product_ids: [product_id],
    };

    setSubmitting(true);

    const postBaseUrl =
      endpoints.v2_storefronts_id_pim_assets_external_links(tenant_id);

    const patchBaseUrl =
      endpoints.v2_storefronts_id_pim_assets_external_links_id(
        tenant_id,
        linkId
      );

    try {
      if (linkId) {
        await Axios.patch(patchBaseUrl, formData);
      } else {
        await Axios.post(postBaseUrl, formData);
      }
      await refreshAssetsList();
      if (isAddForm) {
        notifySuccess(t("External Link successfully added"));
      } else {
        notifySuccess(t("External Link successfully edited"));
      }
      onSuccess();
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("Could not edit link. Something went wrong."));
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>
          {isAddForm ? t("Add external Link") : t("Edit external Link")}
        </SectionTitle>
      </MarginBottomHeaderLeft>

      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          name={"url"}
          label={"URL"}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          type="url"
          defaultValue={defaultURL}
        />
        <TextField
          name={"name"}
          label={"Link Name"}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          type="text"
          defaultValue={defaultName}
        />
        {!isAddForm && (
          <CheckBoxContainer>
            <CheckBoxNoLabel
              name="is_visible"
              id="is_visible"
              defaultChecked={isVisible}
              ref={register({ required: false })}
              onChange={() => setIsVisible(!isVisible)}
            />
            <CheckBoxFinePrintLabel htmlFor="is_visible">
              {t("Link is accessible by external users")}
            </CheckBoxFinePrintLabel>
          </CheckBoxContainer>
        )}
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={submitting}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
