import type {
  QuoteRequestItem,
  IQuoteRequestPriceTier,
} from "../../types/types";
import useSWR from "swr";
import { endpoints } from "../../endpoints";
import React, { useContext } from "react";
import {
  TextButton,
  DeleteButton,
  ButtonWithConfirmDialog,
} from "../Buttons/Buttons";
import { Notifications } from "../Notifications/NotificationsContext";
import {
  FurthestRightButtonContainer,
  TransactionItem,
} from "./TransactionItem";
import { TransactionItemInfoBlockPrice } from "./TransactionItemInfoBlockPrice";
import { TransactionItemInfoBlockRequestedDetails } from "./TransactionItemInfoBlockRequestedDetails";
import { TransactionItemTopLeft } from "./TransactionItemTopLeft";
import { TransactionItemInfoBlockQuantity } from "./TransactionItemInfoBlockQuantity";
import { Row } from "../Layout/Layout";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../util/util";
import { useHistory, useLocation } from "react-router-dom";
import type { AxiosError } from "axios";

/**
 * Quote item card to display items to the buyer after the seller has responded
 * to the quote request.
 */
export const QuoteItemCardBuyerHasResponse = ({
  quoteId,
  item,
  index,
  quoteIsCancelledOrDeclined,
  removeCartItem,
  viewPriceTiers,
}: {
  quoteId: string;
  item: QuoteRequestItem;
  index: number;
  quoteIsCancelledOrDeclined: boolean;
  removeCartItem: (item: QuoteRequestItem) => void;
  viewPriceTiers: (tiersData: IQuoteRequestPriceTier) => void;
}) => {
  const { t } = useTranslation();
  const { notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const location = useLocation();
  const history = useHistory();
  const { data: priceTiersData } = useSWR<IQuoteRequestPriceTier>(
    endpoints.v1_storefronts_id_or_slug_quotes_id_items_id_priceTiers(
      storefront_id,
      quoteId,
      item.id
    ),
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === 400) {
          const searchParams = new URLSearchParams(location.search);
          if (!searchParams.has("refreshed")) {
            searchParams.set("refreshed", "true");
            history.replace({ search: searchParams.toString() });
            window.location.reload();
          }
        }
        notifyError(t("There was an error checking for price offers"), {
          error,
        });
      },
    }
  );

  const bottomRight = quoteIsCancelledOrDeclined ? null : (
    <Row>
      {priceTiersData && priceTiersData.price_tiers.length > 0 && (
        <TextButton
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            viewPriceTiers(priceTiersData);
          }}
        >
          {t("See Offers")}
        </TextButton>
      )}
      {/* Margin adjustment is so the delete button has better vertical
          alignment with the "See Offers" text button. This is needed because
          the svg in the delete button somehow has extra space on the bottom. */}
      <FurthestRightButtonContainer style={{ marginBottom: "-2px" }}>
        <ButtonWithConfirmDialog
          Button={DeleteButton}
          testid={"quote-item-delete-button"}
          handleConfirm={() => removeCartItem(item)}
          confirmMessage={t("Are you sure you want to remove this item?")}
        />
      </FurthestRightButtonContainer>
    </Row>
  );

  return (
    <TransactionItem
      topLeft={<TransactionItemTopLeft item={item} index={index} />}
      topRightCol1={<TransactionItemInfoBlockQuantity item={item} t={t} />}
      topRightCol2={<TransactionItemInfoBlockPrice item={item} />}
      bottomLeftCol1={<TransactionItemInfoBlockRequestedDetails item={item} />}
      bottomRight={bottomRight}
      requestType={"quote"}
      requestId={quoteId}
      itemId={item.id}
      productId={item.product_id}
    />
  );
};
