import type { IAddress, IQuotesAPIResponse } from "../../types/types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { useStoreState } from "../../util/util";
import { Notifications } from "../Notifications/NotificationsContext";
import type { BuyerQuoteItemFormProps } from "./BuyerQuoteItemForm";
import { BuyerQuoteItemForm } from "./BuyerQuoteItemForm";
import { DelayedSpinner } from "../DelayedSpinner/DelayedSpinner";

/**
 * Used to show the buyer quote/cart item form when the item is being added to
 * the cart or already is in the cart.
 *
 * It checks the cart status and passes props to `BuyerQuoteItemForm` based on
 * the status of the cart. For example, when some properties are already
 * defined on the cart those properties are used in the form. Also it disables
 * editing those props ("transaction values") if the cart exists (and thus
 * those values are already set).
 */
export const BuyerQuoteItemFormForCart = (props: BuyerQuoteItemFormProps) => {
  const { notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  // This still works with the unified cart endpoint because unifiedCart.quote
  // is technically still a quote of status "new"
  const { data: cartData, error: cartError } = useSWR<IQuotesAPIResponse>(
    `/v1/storefronts/${storefront_id}/quotes?status=new&limit=1`,
    {
      onError: (error) => {
        notifyError(t("Error fetching current cart data"), { error });
      },
      revalidateOnFocus: false,
    }
  );

  const isLoadingCart = !cartData && !cartError;

  const cart = cartData?.data[0];

  if (isLoadingCart) {
    return <DelayedSpinner />;
  }
  if (!cart) {
    return <BuyerQuoteItemForm {...props} />;
  }
  return (
    <BuyerQuoteItemForm
      {...props}
      // When the following props are already defined for the cart, as they
      // should be if there is a cart at all, then the cart version takes
      // precedence.
      shippingAddress={
        cart.shipping_address ||
        props.shippingAddress ||
        ({
          id: "new_address",
          address1: t("New Address"),
          state: "",
          city: "",
          country: "",
          postal_code: "",
        } as IAddress)
      }
      paymentTerm={cart.payment_term || props.paymentTerm}
      deliveryTerm={cart.delivery_term || props.deliveryTerm}
      currencyCode={
        // TODO: this should work, but doesn't for some reason:
        // cart.currency ||
        cart.items[0]?.currency || props.currencyCode
      }
      allowEditingTransactionValues={false}
    />
  );
};
