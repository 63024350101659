import Axios from "axios";
import type { Dispatch, SetStateAction } from "react";
import React, { useContext, useState } from "react";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Auth } from "../../components/Auth";
import {
  CancelButtonSmall,
  EditButton,
  PrimaryButtonMedium,
} from "../../components/Buttons/Buttons";
import { Row } from "../../components/Layout/Layout";
import { useNotifications } from "../../components/Notifications/NotificationsContext";
import { ToggleSwitch } from "../../components/ToggleSwitch/ToggleSwitch";
import { H3 } from "../../components/Typography/Typography";
import { endpoints } from "../../endpoints";
import type { User } from "../../types/types";
import { useStoreState } from "../../util/util";
import { GenericDialogBody } from "../../components/ConfirmDialog/ConfirmDialog";
import { ConfirmQuoteWarningText } from "../SharedPages/SellerQuoteDetailPage/SellerQuoteDetailPageContent";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
} from "../../components/Form/Form";
import { CheckBox } from "../../components/CheckBoxes/CheckBoxes";

const ChatPrimaryButtonMedium = styled(PrimaryButtonMedium)`
  display: block;
  margin-top: 15px;
`;

const genericConfirmDialog = ({
  show,
  setShow,
  handleConfirm,
  t,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  handleConfirm: () => void;
  t: TFunction;
}) => (
  <GenericDialogBody show={show} closeDialog={() => setShow(false)}>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ConfirmQuoteWarningText>
        {t(
          "Disabling this option can result in critical updates about transactions not being sent to you."
        )}
      </ConfirmQuoteWarningText>
      <ConfirmQuoteWarningText>
        {t("Are you sure you want to proceed?")}
      </ConfirmQuoteWarningText>
      <CheckBoxContainer>
        <CheckBox
          name="user_confirmation"
          id="user_confirmation"
          onChange={handleConfirm}
        />
        <CheckBoxFinePrintLabel
          htmlFor="user_confirmation"
          style={{ marginLeft: "10px", flex: 1 }}
        >
          {t(
            "I understand the implications of disabling this option, and confirm that Agilis Commerce will not be liable for any adverse business impact arising out of notifications not being sent to me."
          )}
        </CheckBoxFinePrintLabel>
      </CheckBoxContainer>
    </div>
  </GenericDialogBody>
);

export const SelfAndTeamActionEmailNotification = ({
  user,
}: {
  user: User;
}) => {
  const [edit, setEdit] = useState(false);
  const [showSelfActionDialog, setShowSelfActionDialog] = useState(false);
  const [showTeamActionDialog, setShowTeamActionDialog] = useState(false);
  const [resetSelfActionToggle, setResetSelfActionToggle] = useState("self");
  const [resetTeamActionToggle, setResetTeamActionToggle] = useState("team");
  const { storefront_id } = useStoreState();
  const { t } = useTranslation();
  const {
    self_actions_email_notification_enabled,
    team_actions_email_notification_enabled,
    tenant_id,
    id,
  } = user;
  const [emailSelfActionEnabled, setEmailSelfActionEnabled] = useState(
    self_actions_email_notification_enabled
  );
  const [emailTeamActionEnabled, setEmailTeamActionEnabled] = useState(
    team_actions_email_notification_enabled
  );
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useNotifications();
  const { updateUser } = useContext(Auth);
  const onClick = async () => {
    setLoading(true);
    try {
      const { data: userData } = await Axios.patch<User>(
        endpoints.v1_storefronts_id_tenants_id_users_id(
          storefront_id,
          tenant_id,
          id
        ),
        {
          self_actions_email_notification_enabled: emailSelfActionEnabled,
          team_actions_email_notification_enabled: emailTeamActionEnabled,
        }
      );
      updateUser({
        ...user,
        self_actions_email_notification_enabled:
          userData.self_actions_email_notification_enabled,
        team_actions_email_notification_enabled:
          userData.team_actions_email_notification_enabled,
      });
      notifySuccess(t("Email notifications settings updated successfully."));
    } catch (error) {
      notifyError(
        t("Could not update email notifications. Please try again later"),
        { error }
      );
    } finally {
      setLoading(false);
      setEdit(false);
    }
  };

  const handleSelfActionClick = () => {
    if (emailSelfActionEnabled) {
      setShowSelfActionDialog(true);
      setEmailSelfActionEnabled(true);
      setResetSelfActionToggle((prev) => prev + "1");
    }
    setEmailSelfActionEnabled(true);
  };

  const handlelTeamActionClick = () => {
    if (emailTeamActionEnabled) {
      setShowTeamActionDialog(true);
      setEmailTeamActionEnabled(true);
      setResetTeamActionToggle((prev) => prev + "1");
    }
    setEmailTeamActionEnabled(true);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Row>
        <H3>{t("Email Notifications for Self and Team Actions")}</H3>
        {!edit && (
          <EditButton
            testid={"edit-search-filter"}
            onClick={() => setEdit(true)}
            title={t("Edit email Notifications for self and team actions")}
          />
        )}
        {edit && (
          <CancelButtonSmall
            onClick={() => {
              setEmailSelfActionEnabled(
                self_actions_email_notification_enabled
              );
              setEmailTeamActionEnabled(
                team_actions_email_notification_enabled
              );
              setEdit(false);
            }}
          />
        )}
      </Row>
      <ToggleSwitch
        isChecked={emailSelfActionEnabled}
        onClick={handleSelfActionClick}
        disabled={!edit}
        key={resetSelfActionToggle}
        label={t("Allow email notification for self actions")}
      />
      <ToggleSwitch
        isChecked={emailTeamActionEnabled}
        onClick={handlelTeamActionClick}
        disabled={!edit}
        key={resetTeamActionToggle}
        label={t("Allow email notification for team actions")}
      />
      {genericConfirmDialog({
        show: showSelfActionDialog,
        setShow: setShowSelfActionDialog,
        handleConfirm: () => {
          setEmailSelfActionEnabled(false);
          setShowSelfActionDialog(false);
        },
        t,
      })}
      {genericConfirmDialog({
        show: showTeamActionDialog,
        setShow: setShowTeamActionDialog,
        handleConfirm: () => {
          setEmailTeamActionEnabled(false);
          setShowTeamActionDialog(false);
        },
        t,
      })}
      {edit && (
        <ChatPrimaryButtonMedium
          style={{ width: "fit-content" }}
          loading={loading}
          onClick={onClick}
          type="submit"
        >
          {t("Save your changes")}
        </ChatPrimaryButtonMedium>
      )}
    </div>
  );
};
