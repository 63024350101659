import React, { useState, useContext } from "react";
import type {
  IPaymentTerm,
  ITenantNameAndId,
} from "../../../../../../types/types";
import { useFieldArray } from "react-hook-form";
import {
  useStoreState,
  promiseAllSettledLogAndThrow,
  useFormWrapper,
} from "../../../../../../util/util";
import { Form, FormItems } from "../../../../../../layout/FormLayout";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import {
  SaveButtonPrimaryMedium,
  InputRow,
  ButtonContainer,
  FieldContainer,
} from "../../shared";
import {
  DeleteButton,
  InvisibleButton,
  ButtonWithConfirmDialog,
} from "../../../../../../components/Buttons/Buttons";
import { PlusIcon } from "../../../../../../components/Icons/Icons";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import type { AxiosResponse } from "axios";
import Axios from "axios";
import { endpoints } from "../../../../../../endpoints";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { strings } from "../../../../../../util/strings";
import { useTheme } from "styled-components/macro";
import { ConfirmDialog } from "../../../../../../components/ConfirmDialog/ConfirmDialog";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";

type FormValues = {
  paymentTermFields: IPaymentTerm[];
};

type ConfirmDeleteState = {
  message: string;
  formTerms: IPaymentTerm[];
  termsToDelete: IPaymentTerm[];
};

// TODO: to get the "This is a required field" message to appear on the UI,
// I think we need to not use `Yup.array()` and instead dynamically create a
// schema that includes each of the rows of fields in it.
const editPaymentTermsFormSchema = (t: TFunction) =>
  Yup.object()
    .shape({
      paymentTermFields: Yup.array(
        Yup.object()
          .shape({
            name: Yup.string().required(strings(t).thisIsARequiredField),
            id: Yup.string().notRequired(),
          })
          .defined()
      ).defined(),
    })
    .defined();

export const EditPaymentTerms = ({
  paymentTerms,
  mutatePaymentTerms,
  closeEditPaymentTerms,
}: {
  paymentTerms: IPaymentTerm[];
  mutatePaymentTerms: () => void;
  closeEditPaymentTerms: () => void;
}) => {
  const theme = useTheme();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  // When confirmDeleteState is not null, a dialog is shown.
  const [confirmDeleteState, setConfirmDeleteState] =
    useState<ConfirmDeleteState | null>(null);

  const { register, handleSubmit, formState, errors, control } = useFormWrapper(
    {
      defaultValues: {
        paymentTermFields:
          paymentTerms.length === 0 ? [{ name: "", id: "" }] : paymentTerms,
      },
      resolver: yupResolver(editPaymentTermsFormSchema(t)),
    }
  );

  const { fields, append, remove } = useFieldArray<IPaymentTerm, "key">({
    control,
    name: "paymentTermFields",
    keyName: "key",
  });

  const onSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    const formTerms = formValues.paymentTermFields;
    try {
      const termsToDelete = paymentTerms.filter((term) => {
        const formHasTerm = formTerms.find((ft) => ft.id === term.id);
        return !formHasTerm;
      });

      const defaultTermCheckPromises = termsToDelete.map((term) => {
        return Axios.get<ITenantNameAndId[]>(
          endpoints.v1_storefronts_id_tenant_defaults_paymentTerms_id(
            storefront_id,
            term.id
          )
        );
      });
      const termCheckResults = await promiseAllSettledLogAndThrow(
        defaultTermCheckPromises
      );

      const buyerNames = termCheckResults.reduce((names: string[], result) => {
        if ("value" in result) {
          // For TypeScript, should always be the case.
          const moreNames = result.value.data.map((record) => record.name);
          return [...names, ...moreNames];
        }
        return names;
      }, []);

      if (buyerNames.length > 0) {
        const buyerNamesString = buyerNames.join(", ");

        const confirmationMessage =
          `You are about to delete payment terms that are set as the default ` +
          `payment term for these customers: ${buyerNamesString}. If you ` +
          `proceed then their default payment term will be cleared, ` +
          `and you should re-set it.`;

        setConfirmDeleteState({
          message: confirmationMessage,
          formTerms,
          termsToDelete,
        });
      } else {
        continueSubmitting(formTerms, termsToDelete);
      }
    } catch (error) {
      notifyError("An error occurred, please try again", { error });
      setSubmitting(false);
    }
  };

  const continueSubmitting = async (
    formTerms: IPaymentTerm[],
    termsToDelete: IPaymentTerm[]
  ) => {
    try {
      const deletePromises = termsToDelete.map((term) => {
        // Term was deleted from the form -> delete.
        return Axios.delete(
          endpoints.v1_storefronts_id_paymentTerms_id(storefront_id, term.id)
        );
      });

      const patchPromises = formTerms.reduce(
        (promises: Promise<AxiosResponse<any>>[], formTerm) => {
          if (formTerm.id) {
            const oldTerm = paymentTerms.find((tm) => tm.id === formTerm.id);

            if (oldTerm && oldTerm.name !== formTerm.name) {
              // The term already exists and the name changed -> patch.
              return [
                ...promises,
                Axios.patch(
                  endpoints.v1_storefronts_id_paymentTerms_id(
                    storefront_id,
                    formTerm.id
                  ),
                  { name: formTerm.name }
                ),
              ];
            }
          }
          return promises;
        },
        []
      );

      // DELETE requests have to happen before POST requests, to handle the case
      // where the term was deleted and then re-introduced (new term with same
      // name) in the same editing pass. This is because on the backend if the
      // user creates a new term with the same name as a deleted term,
      // the deleted term is re-used (un-deleted, and description updated) that
      // way there is consistency in the IDs of the terms if they are deleted
      // and re-created.
      await promiseAllSettledLogAndThrow(deletePromises);

      const postPromises = formTerms.reduce(
        (promises: Promise<AxiosResponse<any>>[], formTerm) => {
          if (!formTerm.id) {
            // New term -> post.
            return [
              ...promises,
              Axios.post(
                endpoints.v1_storefronts_id_paymentTerms(storefront_id),
                { name: formTerm.name }
              ),
            ];
          }
          return promises;
        },
        []
      );
      await promiseAllSettledLogAndThrow(patchPromises);
      await promiseAllSettledLogAndThrow(postPromises);

      notifySuccess("Changes saved");
      mutatePaymentTerms();
      closeEditPaymentTerms();
      setSubmitting(false);
    } catch (error) {
      notifyError("There was an error saving the changes", { error });
      setSubmitting(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormItems>
          {fields.map((field, index) => {
            return (
              <InputRow key={field.key || "formRow" + index}>
                <FieldContainer>
                  <TextField
                    label={`Payment Term Name ${index + 1}`}
                    // The name property needs to be in this format.
                    name={`paymentTermFields[${index}].name`}
                    defaultValue={field.name}
                    theref={register({ required: true })}
                    errors={errors}
                    formState={formState}
                    type={"text"}
                  />
                  <input
                    name={`paymentTermFields[${index}].id`}
                    defaultValue={field.id}
                    ref={register({ required: false })}
                    type={"hidden"}
                  />
                </FieldContainer>
                <ButtonContainer>
                  <ButtonWithConfirmDialog
                    Button={DeleteButton}
                    confirmMessage={
                      "Are you sure you want to remove this item?"
                    }
                    handleConfirm={() => remove(index)}
                    // Adding `type="button"` prevents the default (form
                    // submission) so we don't need to use event.preventDefault().
                    type="button"
                    disabled={fields.length === 1}
                    testid={`delete-payment-term-field-${index}`}
                  />
                </ButtonContainer>
                {index === fields.length - 1 && (
                  <ButtonContainer>
                    <InvisibleButton
                      // Adding `type="button"` prevents the default (form
                      // submission) so we don't need to use event.preventDefault().
                      type="button"
                      onClick={() => {
                        append({ name: "", id: "" });
                      }}
                    >
                      <PlusIcon
                        width={16}
                        height={16}
                        fill={theme.primaryIconColor}
                      />
                    </InvisibleButton>
                  </ButtonContainer>
                )}
              </InputRow>
            );
          })}
        </FormItems>
        <SaveButtonPrimaryMedium loading={submitting}>
          Save your changes
        </SaveButtonPrimaryMedium>
      </Form>
      {confirmDeleteState && (
        <ConfirmDialog
          show={!!confirmDeleteState}
          closeDialog={() => {
            setSubmitting(false);
            setConfirmDeleteState(null);
          }}
          confirmMessage={confirmDeleteState?.message || ""}
          handleConfirm={() => {
            continueSubmitting(
              confirmDeleteState.formTerms,
              confirmDeleteState.termsToDelete
            );
            setConfirmDeleteState(null);
          }}
        />
      )}
    </>
  );
};
