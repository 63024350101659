import React from "react";
import { Redirect, matchPath } from "react-router-dom";
import { useAuthContext } from "../../../../../components/Auth";
import { useRoutePath } from "../../../../../util/Routing";
import { useStoreState } from "../../../../../util/util";
import { UnauthorizedPage } from "../../../../Misc/Unauthorized";
import { AttributesList } from "./AttributesList";

/**
 * the path `/pim/attributes` has a tabbed page that contains at most groups,
 * collections and attributes. If a user has permission to view any one of those
 * things the "Attributes" link will appear in the sidenav. This
 * component exists to handle the redirects should the user not have permission
 * for attributes.
 */
export function AttributesLandingPageRedirects(): JSX.Element {
  const { hasPermission } = useAuthContext();
  const { slug } = useStoreState();
  const { adminPath } = useRoutePath();

  const onAttributesPage = matchPath(`${adminPath}/pim/attributes`, {
    path: `${adminPath}/pim/attributes`,
    exact: true,
  });

  if (Boolean(onAttributesPage)) {
    if (hasPermission("view_attributes")) {
      // no need for a history push here, we're already on /attributes.
      return <AttributesList />;
    }

    // the navbar tabs in this component will show the correct tab selected.
    if (hasPermission("view_groups")) {
      return <Redirect to={`${adminPath}/pim/attributes/groups`} />;
    } else if (hasPermission("view_collections")) {
      return <Redirect to={`${adminPath}/pim/attributes/collections`} />;
    }
  }

  return <UnauthorizedPage tenantSlug={slug as string} />;
}
