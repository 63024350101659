import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import { TextField } from "../../../components/TextFields/TextFields";
import { Form } from "../../../layout/FormLayout";
import {
  AuthContainer,
  AuthForgotContainer,
  AuthPage,
} from "../../../layout/publicPageLayout";
import Axios from "axios";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LinkUnstyled } from "../../../components/LinkUnstyled/LinkUnstyled";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { H3DarkText } from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import { useRoutePath } from "../../../util/Routing";
import { useFormWrapper, useStoreState } from "../../../util/util";
import { AuthLogoWrapper } from "../../Misc/Login";

export function ForgotPassword() {
  const { storefront_id } = useStoreState();
  const { handleSubmit, register, formState, errors } = useFormWrapper({});
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storePath } = useRoutePath();
  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = async (values: { username: string }) => {
    setLoading(true);
    try {
      await Axios.post(
        endpoints.v1_storefronts_id_users_resetPassword_tokens(storefront_id),
        values
      );
      notifySuccess(
        "A password reset email has been sent to the provided email"
      );
      setLoading(false);
      history.push(storePath);
    } catch (error) {
      notifyError("There was an error trying to reset the password", { error });
      setLoading(false);
    }
  };

  return (
    <AuthPage>
      <AuthContainer>
        <AuthLogoWrapper />
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <H3DarkText>{t("Forgot Password")}</H3DarkText>
          <TextField
            name="username"
            label="User Name"
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
            autoComplete={"username"}
          />
          <PrimaryButtonFitContainer
            style={{ marginTop: 0 }}
            type="submit"
            loading={loading}
          >
            {t("Submit")}
          </PrimaryButtonFitContainer>
          <AuthForgotContainer>
            <LinkUnstyled to={`${storePath}/forgot-username`}>
              <span>{t("Forgot User Name?")}</span>
            </LinkUnstyled>
          </AuthForgotContainer>
        </Form>
      </AuthContainer>
    </AuthPage>
  );
}
