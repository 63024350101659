import React, { useState, useEffect } from "react";
import { TextField } from "../TextFields/TextFields";
import { Title } from "../Typography/Typography";
import { Form, SubmitButtonContainer } from "../../layout/FormLayout";
import styled from "styled-components/macro";
import type { FilterGroup, Filters, OptionType } from "../../types/types";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { SearchSelect } from "../SearchSelect/SearchSelect";
import { EditableListItem } from "../EditableListItem/EditableListItem";
import { TextArea } from "../TextArea/TextArea";
import type { IMarketSegment } from "./index";
import { useFormWrapper } from "../../util/util";

export type IEditTagSubmitProp = {
  title: string;
  market_segments: IMarketSegment[];
};
type IEditTagProps = {
  title: string;
  data: IMarketSegment[];
  handleEditSubmit: (
    data: IEditTagSubmitProp & { [x: string]: string }
  ) => Promise<void>;
  endEditMode: () => void;
  filters: Filters;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
`;

const CustomTextArea = styled(TextArea)`
  max-height: 50px;
`;

const convertToOptionType = (
  data: FilterGroup[]
): OptionType<FilterGroup>[] => {
  return data.map((item) => ({ label: item.name, value: item }));
};

export const EditMarketsWeServe = ({
  title,
  data,
  handleEditSubmit,
  endEditMode,
  filters,
}: IEditTagProps) => {
  const { handleSubmit, register, formState, setValue, errors } =
    useFormWrapper({});

  const [loading, setLoading] = useState(false);
  const [marketSegmentChips, setMarketSegmentChips] = useState<
    IMarketSegment[]
  >([...data]);
  const [marketSegmentOptions, setMarketSegmentOptions] = useState<
    OptionType[]
  >([]);

  const createFields = (): JSX.Element[] => {
    return marketSegmentChips.map(
      ({ id, name, description }: IMarketSegment) => {
        return (
          <EditableListItem
            key={id}
            title={name || ""}
            deleteFunction={() => deleteChip(id)}
          >
            <CustomTextArea
              name={id.toString()}
              label="description"
              required={false}
              formState={formState}
              theref={register({
                required: false,
              })}
              defaultValue={description}
            />
          </EditableListItem>
        );
      }
    );
  };

  const onSubmit = async (
    data: IEditTagSubmitProp & { [x: string]: string }
  ) => {
    setLoading(true);
    await handleEditSubmit(data);
    setLoading(false);
    endEditMode();
  };

  const handleMarketSegmentChange = (data: OptionType) => {
    setMarketSegmentChips((previous) => [...previous, data.value]);
    setValue("market_segments", marketSegmentChips);

    setMarketSegmentOptions((previous) =>
      previous.filter((item) => item.label !== data.label)
    );
  };

  const deleteChip = (id: number | string) => {
    setMarketSegmentChips(marketSegmentChips.filter((item) => item.id !== id));
  };

  useEffect(() => {
    if (filters) {
      const marketSegmentNames = marketSegmentChips.map((item) => item.name);

      setValue("market_segments", marketSegmentChips);

      setMarketSegmentOptions(
        convertToOptionType(
          filters.market_segment.filter(
            (item) => marketSegmentNames?.indexOf(item.name) < 0
          )
        )
      );
    }
  }, [filters, marketSegmentChips, setValue]);

  useEffect(() => {
    register({ name: "market_segments", required: false });
  }, [register]);

  return (
    <Container>
      <Title>Edit Markets We Serve</Title>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="title"
          label="Title"
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
          defaultValue={title}
        />

        <SearchSelect
          name={"market_segments"}
          options={marketSegmentOptions}
          errors={errors}
          formState={formState}
          placeholder={"Market segments"}
          // This prevents a value from ever appearing in the input,
          // instead the value is added to the chips.
          value={null}
          onChange={handleMarketSegmentChange}
        />
        {createFields()}

        <SubmitButtonContainer>
          <PrimaryButtonFitContainer loading={loading}>
            Save Changes
          </PrimaryButtonFitContainer>
        </SubmitButtonContainer>
      </Form>
    </Container>
  );
};
