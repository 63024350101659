import type {
  IOrderItem,
  ProductDocument,
  QuoteRequestItem,
  SampleRequestDetail,
  TransactionDocument,
} from "../../types/types";
import React from "react";
import { TertiaryButtonSmall } from "../Buttons/Buttons";
import styled from "styled-components/macro";
import { H5, H6 } from "../Typography/Typography";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { TrimmedName } from "../../pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";

const filterCompareFn = (kindKey: string, compareKey: string) =>
  // the regex replaces possible multiple spaces with a single space
  kindKey.replace(/\s+/g, " ").trim() === compareKey;

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin-right: 32px;
  }
  a {
    text-decoration: none;
  }
`;

export const IndividualDocument = styled.div`
  margin: 10px 20px 0 0;
  a {
    margin-right: 8px;
    display: inline-block;
    padding: 9px 12px;
    max-width: 350px;
  }
`;

type DocumentViewItem = QuoteRequestItem | IOrderItem | SampleRequestDetail;

export const get_all_documents = ({
  items,
  seller_or_buyer_documents,
  t,
  language,
}: {
  items: DocumentViewItem[];
  seller_or_buyer_documents: TransactionDocument[];
  t: TFunction;
  language?: string;
}) => {
  const all_docs: {
    [product_name: string]: { [document_kind: string]: ProductDocument[] };
  } = {};
  items.forEach((item) => {
    const tds_docs = item.documents.filter(
      (doc) =>
        filterCompareFn(
          doc.kind,
          "Technical Data Sheet (TDS)" || doc.kind === "tds"
        ) &&
        (language ? (doc.language ? doc.language === language : true) : true)
    );
    const sds_docs = item.documents.filter(
      (doc) =>
        filterCompareFn(
          doc.kind,
          "Safety Data Sheet (SDS)" || doc.kind === "sds"
        ) &&
        (language ? (doc.language ? doc.language === language : true) : true)
    );
    if (tds_docs.length > 0 || sds_docs.length > 0) {
      all_docs[item.product.name] = {
        ...(tds_docs.length > 0
          ? { "Technical Data Sheet (TDS)": tds_docs }
          : {}),
        ...(sds_docs.length > 0 ? { "Safety Data Sheet (SDS)": sds_docs } : {}),
      };
    }
  });
  const all_products_documents: { [document_kind: string]: ProductDocument[] } =
    {};
  seller_or_buyer_documents?.forEach((transaction_doc) => {
    const { kind, language: doc_language } = transaction_doc;
    if (!language || doc_language === language) {
      if (transaction_doc.is_all_product) {
        all_products_documents[kind] =
          all_products_documents[kind] &&
          all_products_documents[kind].length > 0
            ? [...all_products_documents[kind], transaction_doc]
            : [transaction_doc];
      } else {
        all_docs[transaction_doc.product_name] =
          all_docs[transaction_doc.product_name] || {};
        all_docs[transaction_doc.product_name][kind] =
          all_docs[transaction_doc.product_name][kind] &&
          all_docs[transaction_doc.product_name][kind].length > 0
            ? [...all_docs[transaction_doc.product_name][kind], transaction_doc]
            : [transaction_doc];
      }
    }
  });
  if (Object.keys(all_products_documents).length > 0) {
    all_docs[t("All Products")] = all_products_documents;
  }
  return all_docs;
};

/**
 * Displays documents on quote and order detail pages.
 * Currently supports just SDS and TDS document types.
 */
export function DocumentView({
  items,
  seller_or_buyer_documents,
  language,
}: {
  items: DocumentViewItem[];
  seller_or_buyer_documents: TransactionDocument[];
  language?: string;
}) {
  const { t } = useTranslation();
  const all_documents = get_all_documents({
    items,
    seller_or_buyer_documents,
    t,
    language,
  });
  return (
    <DocumentContainer>
      {Object.entries(all_documents).map(([product_name, obj]) => {
        const has_docs = Object.entries(obj).some(
          ([_, docs]) => docs.length > 0
        );
        return (
          <div key={product_name} style={{ marginBottom: "10px" }}>
            {has_docs && (
              <H5
                data-testid={`product_name_${product_name}`}
                style={{ marginBottom: "15px" }}
              >
                {product_name}
              </H5>
            )}
            {Object.entries(obj).map(([kind, docs]) => {
              if (docs.length > 0) {
                return (
                  <div key={kind} style={{ marginBottom: "15px" }}>
                    <H6 data-testid={`kind_${kind}`}>{kind}</H6>
                    {docs.map((doc) => {
                      return (
                        <IndividualDocument
                          data-testid={`doc_${doc.id}`}
                          key={doc.id}
                        >
                          <TertiaryButtonSmall
                            as={"a"}
                            href={doc.signed_url}
                            target={"_blank"}
                            key={doc.id}
                          >
                            <TrimmedName text={doc.name} />
                          </TertiaryButtonSmall>
                        </IndividualDocument>
                      );
                    })}
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        );
      })}
    </DocumentContainer>
  );
}
