import type { Permission } from "../../types/types";
import { useAuthContext } from "../Auth";

export function WithPermission({
  children,
  permission,
}: {
  children: JSX.Element;
  permission: Permission;
}) {
  const { hasPermission } = useAuthContext();

  if (hasPermission(permission)) {
    return children;
  } else {
    return null;
  }
}
