import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useAuthContext } from "../../../../components/Auth";
import { LoadingIcon } from "../../../../components/Icons/Icons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { ToggleSwitch } from "../../../../components/ToggleSwitch/ToggleSwitch";
import { H3 } from "../../../../components/Typography/Typography";
import { endpoints } from "../../../../endpoints";
import type { DataMutate } from "../../../../types/types";
import type { PIMProduct } from "../../../../types/types.PIM";
import { useStoreState } from "../../../../util/util";

const CustomSKUConfigContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const CustomSKUConfig = ({
  allow_custom_sku,
  updateProductStatus,
  replaceProductId,
  fetchProductData,
  productIsEditable,
}: {
  allow_custom_sku: boolean;
  updateProductStatus: () => Promise<AxiosResponse<PIMProduct>>;
  replaceProductId: (id: string) => void;
  fetchProductData: DataMutate<PIMProduct>;
  productIsEditable: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [allowCustomSKU, setAllowCustomSKU] = useState(allow_custom_sku);
  const { storefront_id } = useStoreState();
  const { hasPermission } = useAuthContext();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { t } = useTranslation();

  const handleAllowCustomSKU = async () => {
    setLoading(true);
    try {
      const { data: updatedProduct } = await updateProductStatus();
      await axios.patch(
        endpoints.v2_storefronts_id_or_slug_pim_products_id_or_slug_allowCustomSKU(
          storefront_id,
          updatedProduct.id
        ),
        { allow_custom_sku: !allowCustomSKU }
      );
      setAllowCustomSKU(!allowCustomSKU);
      setLoading(false);
      notifySuccess(t("Allow Custom SKU setting updated for this product"));
      if (
        updatedProduct.display_status === "staged" ||
        updatedProduct.display_status === "unpublished_staged"
      ) {
        const product_id =
          updatedProduct.product_number ??
          updatedProduct.primary_staged_product_id ??
          updatedProduct.id;
        replaceProductId(product_id);
      } else {
        await fetchProductData();
      }
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t(
              "There was an error updating the custom SKU setting for this product"
            ),
        {
          error,
        }
      );
      setLoading(false);
    }
  };

  const canUpdateCustomSKU =
    hasPermission("modify_products") && productIsEditable;

  useEffect(() => setAllowCustomSKU(allow_custom_sku), [allow_custom_sku]);

  return (
    <div style={{ margin: "12px 0" }}>
      <H3>{t("SKUs Configuration")}</H3>
      <CustomSKUConfigContainer>
        {loading ? (
          <span>
            <LoadingIcon width={16} height={18} />
          </span>
        ) : (
          <ToggleSwitch
            name="custom_sku"
            label={t(
              "Buyers can request a custom SKU when requesting a quote for this product"
            )}
            isChecked={allowCustomSKU}
            disabled={!canUpdateCustomSKU}
            tooltip={
              canUpdateCustomSKU
                ? undefined
                : t(
                    "You do not have required permission to update custom sku settings"
                  )
            }
            onClick={handleAllowCustomSKU}
          />
        )}
      </CustomSKUConfigContainer>
    </div>
  );
};
