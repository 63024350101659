import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { endpoints } from "../../../../endpoints";
import { makeUrlWithParams } from "../../../../util/util";
import type { Tenant, IGroupedPriceTier, User } from "../../../../types/types";
import { ErrorPlaceholder } from "../../../../components/Error";
import styled from "styled-components/macro";
import { StringParam, useQueryParams } from "use-query-params";
import { useDebounce } from "../../../../util/hooks";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import { ViewOrEditPriceTierGroup } from "./ViewOrEditPriceTierGroup/ViewOrEditPriceTierGroup";
import { ModalWide } from "../../../../components/Modal/Modal";
import { CreatePriceTierGroup } from "./CreatePriceTierGroup/CreatePriceTierGroup";
import { useTranslation } from "react-i18next";
import { PrimaryButtonWithPlusIcon } from "../../../../components/Buttons/Buttons";

const NoData = styled.div`
  width: 100%;
  padding: 100px 10px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.secondaryTextColor};
  background: ${({ theme }) => theme.primaryBG};
`;

const SearchBarRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

export const PriceTiersTab = ({
  buyerTenant,
  user,
}: {
  buyerTenant: Tenant;
  user: User;
}) => {
  const [query, setQuery] = useQueryParams({
    tierQuery: StringParam,
  });
  // Use the user's tenant ID so it will be correct for producers
  const sellerId = user.tenant_id;
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);

  const [isCreatingTier, setIsCreatingTier] = useState(false);

  const { t } = useTranslation();

  const localStrings = {
    searchBarPlaceholder: t("Search by Product or Destination"),
  };

  const {
    data: tiers,
    error: tiersError,
    mutate: mutateTiers,
  } = useSWR<IGroupedPriceTier[]>(
    makeUrlWithParams(endpoints.v1_priceTiers_grouped(), {
      seller_id: sellerId,
      buyer_id: buyerTenant.id,
      q: debouncedSearchQuery || null,
    })
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ tierQuery: undefined });
  };

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ tierQuery: undefined });
    if (debouncedSearchQuery) {
      setQuery({ tierQuery: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  if (tiersError) {
    return (
      <ErrorPlaceholder
        message={t("There was an error fetching the price tiers")}
      />
    );
  }
  if (isCreatingTier) {
    return (
      <ModalWide show={true} closeModal={() => setIsCreatingTier(false)}>
        <CreatePriceTierGroup
          buyerTenantId={buyerTenant.id}
          sellerId={sellerId}
          onTierGroupCreated={() => {
            mutateTiers();
            setIsCreatingTier(false);
          }}
        />
      </ModalWide>
    );
  }
  return (
    <>
      <SearchBarRow>
        <SearchBar
          query={searchQuery}
          placeHolder={localStrings.searchBarPlaceholder}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        <PrimaryButtonWithPlusIcon onClick={() => setIsCreatingTier(true)}>
          {t("Create New")}
        </PrimaryButtonWithPlusIcon>
      </SearchBarRow>
      {tiers &&
        tiers.length > 0 &&
        tiers.map((tierGroup, index) => {
          return (
            <ViewOrEditPriceTierGroup
              tierGroup={tierGroup}
              index={index}
              mutateTiers={mutateTiers}
              key={index}
            />
          );
        })}
      {tiers && tiers.length === 0 && (
        <NoData>{t("No Price Tiers found.")}</NoData>
      )}
    </>
  );
};
