import type { AxiosError } from "axios";
import axios from "axios";
import type { ChangeEvent } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SortingRule } from "react-table";
import styled from "styled-components/macro";
import useSWR from "swr";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  PrimaryButtonWithPlusIcon,
} from "../../../../components/Buttons/Buttons";
import { DropDown } from "../../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../../components/Layout/Layout";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../components/Table/Table";
import { endpoints } from "../../../../endpoints";
import { Flex, Flex1, Flex2 } from "../../../../layout/FormLayout";
import { ContentWrapper } from "../../../../layout/portalPageLayout";
import type { Tenant, WithPagination } from "../../../../types/types";
import type { PIMProduct } from "../../../../types/types.PIM";
import { useDebounce } from "../../../../util/hooks";
import {
  defaultHandleSort,
  TablePlaceholder,
  toTitleCase,
  useStoreState,
} from "../../../../util/util";
import { AddCustomer } from "./AddCustomer";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";

const CustomersWrapper = styled.div`
  margin-top: 40px;
`;

type CompanyRow = {
  id: string;
  company_name: string;
  type: string;
  primary_contact: string;
  primary_contact_email: string;
};

export const CustomersList = ({ product }: { product: PIMProduct }) => {
  const [tableData, setTableData] = useState<CompanyRow[]>([]);
  const [perPage, setPerPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [offset, setOffset] = useState(0);
  const [showCreateSlideOut, setCreateSlideOut] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });

  const { id: productId } = product;

  const { tenant_id } = useStoreState();

  const { t } = useTranslation();

  const { notifySuccess, notifyError } = useNotifications();

  const perPageItems = [10, 20, 50];

  const {
    data: customers,
    error: customersListError,
    mutate: mutateCustomersList,
  } = useSWR<WithPagination<{ data: Tenant[] }>, AxiosError>([
    endpoints.v2_tenants_id_products_id_privacy(tenant_id, productId),
    useMemo(
      () => ({
        params: {
          offset,
          limit: perPage,
          q: debouncedSearchQuery,
          order_by: sortingRules.orderBy,
          sort_by: sortingRules.sortBy ?? "asc",
        },
      }),
      [debouncedSearchQuery, offset, perPage, sortingRules]
    ),
  ]);

  const isLoadingCustomers = !customers && !customersListError;

  const existingCustomers = customers?.data.map(({ id }) => id) ?? [];

  const handleRemoveCompany = useCallback(
    (companyId: string) => {
      const removeCompany = async () => {
        try {
          await axios.patch<PIMProduct>(
            endpoints.v2_tenants_id_products_id_privacy(tenant_id, productId),
            {
              is_accessible_by_all_customers_and_distributors: false,
              tenants_to_delete: [companyId],
            }
          );
          notifySuccess(t("Customer removed successfully."));
          await mutateCustomersList();
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? errorMessage
              : t("Could not remove customer. Please try again later."),
            {
              error,
            }
          );
        }
      };
      removeCompany();
    },
    [mutateCustomersList, notifySuccess, notifyError, productId, t, tenant_id]
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: t("Company Name"),
        accessor: "company_name",
        Cell: ({ value }: { value: string }) => <div>{toTitleCase(value)}</div>,
      },
      {
        Header: t("Type"),
        accessor: "type",
      },
      {
        Header: t("Primary Contact"),
        accessor: "primary_contact",
        Cell: ({ value }: { value: string }) => <div>{toTitleCase(value)}</div>,
      },
      {
        Header: t("Email ID"),
        accessor: "primary_contact_email",
      },
      {
        Header: "",
        accessor: " ",
        disableSortBy: true,
        Cell: ({ row: { original } }: { row: { original: CompanyRow } }) =>
          product.status !== "archived" && product.is_editable ? (
            <ButtonWithConfirmDialog
              Button={DeleteButton}
              testid={"remove-company-from-product-access"}
              handleConfirm={() => handleRemoveCompany(original.id)}
              confirmMessage={t(
                "Are you sure you want to remove this company?"
              )}
            />
          ) : (
            <></>
          ),
      },
    ],
    [handleRemoveCompany, t, product]
  );

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setOffset(0);
  };

  const closeCreateSlideOut = () => setCreateSlideOut(false);

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const onAddCustomerSuccess = async () => {
    notifySuccess(t("Customer added successfully"));
    closeCreateSlideOut();
    await mutateCustomersList();
  };

  useEffect(() => {
    const handleData = ({
      data,
      pagination,
    }: WithPagination<{ data: Tenant[] }>) => {
      setTableData(
        data.map((tenant) => ({
          id: tenant.id,
          company_name: tenant.name,
          type: tenant.type,
          primary_contact: tenant.primary_contact.full_name,
          primary_contact_email: tenant.primary_contact.email_address,
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };
    if (customers) {
      handleData(customers);
    }
  }, [perPage, customers]);

  return (
    <CustomersWrapper>
      <Flex style={{ marginBottom: "24px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t(
              "Search by company name or primary contact email id"
            )}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
          />
        </Flex2>
        <Flex1 style={{ textAlign: "right" }}>
          {product.status !== "archived" && product.is_editable ? (
            <PrimaryButtonWithPlusIcon
              onClick={() => setCreateSlideOut(true)}
              data-alignright
            >
              {t("Add")}
            </PrimaryButtonWithPlusIcon>
          ) : (
            <></>
          )}
        </Flex1>
      </Flex>
      <SlideOut closeFlyout={closeCreateSlideOut} show={showCreateSlideOut}>
        <AddCustomer
          product={product}
          onSuccess={onAddCustomerSuccess}
          existingCustomers={existingCustomers}
        />
      </SlideOut>
      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t(" Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
      </DropdownContainer>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          isLoading={isLoadingCustomers}
          error={customersListError}
          data={tableData}
          handleSort={handleSort}
          lastChildleftAlign
          Placeholder={
            <TablePlaceholder
              message={
                !!customersListError
                  ? t(
                      "There was an error fetching customers. Please try again later."
                    )
                  : t(
                      "No items to show. Please click the add button to add items."
                    )
              }
            />
          }
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </CustomersWrapper>
  );
};
