import React from "react";
import Helmet from "react-helmet";
import { useStoreState } from "../../../util/util";

export const DEFAULT_SEO_DESCRIPTION = (tenantName: string = "Agilis") =>
  `${tenantName} commerce portal. View Products, Request Quotes, get access to SDS & TDS.`;

export const HelmetDefault = () => {
  const { storefront_metadata } = useStoreState();

  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";
  const isQuadraStorefront = window.location.host === "quadra.agilis.site";

  if (isQuadraStorefront) {
    // these need to be in scope for the quadra script but ts won't let you add
    // properties to window by default.
    //@ts-ignore
    window.piHostname = "pardot.quadra.agilis.site";
    //@ts-ignore
    window.piAId = "905081";
    //@ts-ignore
    window.piCId = "";
  }
  return (
    <Helmet>
      {!isProduction && <meta name="robots" content="noindex" />}
      <title>{storefront_metadata.browser_title}</title>
      {isQuadraStorefront && (
        <script type="text/javascript">
          {/* disabling warning for type void is assignable to x */}
          {/* eslint-disable-next-line tsc/config    */}
          {(function () {
            function async_load() {
              const s = document.createElement("script");
              s.type = "text/javascript";
              s.src =
                ("https:" === document.location.protocol
                  ? "https://"
                  : "http://") +
                "pardot.quadra.agilis.site" +
                "/pd.js";
              const c = document.getElementsByTagName("script")[0];
              if (c.parentNode) {
                c?.parentNode.insertBefore(s, c);
              }
            }
            window.addEventListener("load", async_load, false);
          })()}
        </script>
      )}
      {storefront_metadata.tenant_name && (
        <meta
          name="description"
          content={`${DEFAULT_SEO_DESCRIPTION(
            storefront_metadata.tenant_name
          )}`}
        />
      )}
      {storefront_metadata && (
        <link
          rel="icon"
          type="image/x-icon"
          href={
            storefront_metadata.theme_object.branding.favIconUrl ||
            "https://cdn.agilischemicals.com/favicon.png"
          }
          sizes="16x16"
        />
      )}
    </Helmet>
  );
};
