import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Chips } from "../../../components/Chips/Chips";
import type { ChipType } from "../../../components/Chips/Chips";

import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import type {
  DecisionTimeline,
  OptionType,
  RequestableDocuments,
} from "../../../types/types";

import {
  ConfirmationSpacer,
  DECISION_CHIPS,
  DOCUMENT_CHIPS,
} from "../../public/SampleRequestCart/SampleRequestCart";
import { useEffect } from "react";
import { useStoreState } from "../../../util/util";
import { create_configured_checkboxes_fields } from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";

export type CreateSampleRequestBottomFormOutput = {
  is_decision_maker: OptionType<boolean>;
  documents: { id: RequestableDocuments; name: RequestableDocuments }[];
  decision_timeline: { name: DecisionTimeline };
};

export function CreateSampleRequestBottomForm() {
  const methodsOfUseForm = useFormContext();
  const { control, register, errors, formState, setValue } = methodsOfUseForm;
  const { t } = useTranslation();
  const {
    storefront_metadata: { configured_checkboxes },
  } = useStoreState();
  const { fields, append } = useFieldArray({
    control,
    name: "configured_checkboxes",
  });

  const sampleCheckboxes = configured_checkboxes.filter((item) => item.sample);
  useEffect(() => {
    if (sampleCheckboxes.length > 0 && fields?.length < 1) {
      sampleCheckboxes.forEach((element) => {
        append({ value: false, label: element.name, id: element.id });
      });
    }
  }, [sampleCheckboxes, append, fields]);

  useEffect(() => {
    register({ name: "documents", required: false });
    register({
      name: "decision_timeline",
      required: true,
    });
  }, [register, t]);

  const onDecisionTimelineClick = (chips: ChipType[]) => {
    setValue("decision_timeline", chips[0]);
  };

  const onDocumentsClick = (chips: ChipType[]) => {
    setValue("documents", chips);
  };

  return (
    <>
      <div style={{ maxWidth: "365px" }}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="is_decision_maker"
          placeholder={t("Is the buyer the decision maker?")}
          id="is_decision_maker"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        />
      </div>
      <Chips
        selectionMode={"single"}
        header={t("Decision Timeline")}
        chips={DECISION_CHIPS}
        handleClick={onDecisionTimelineClick}
        errors={errors}
        name={"decision_timeline"}
      />
      <Chips
        selectionMode={"many"}
        header={t("Select all you wish to receive")}
        chips={DOCUMENT_CHIPS}
        handleClick={onDocumentsClick}
        errors={errors}
        name="documents"
      />

      <ConfirmationSpacer>
        {fields && fields?.length > 0 && (
          <>
            {create_configured_checkboxes_fields({
              fields,
              methodsOfUseForm,
              t,
            })}
          </>
        )}
      </ConfirmationSpacer>
    </>
  );
}
