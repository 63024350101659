import React, { useEffect, useState } from "react";
import { Loader } from "../../../components/Loader/Loader";
import { Ag3Homepage } from "../../../layout/homepage/AG3Homepage";
import type { IPartnersData } from "../../../components/Partners";
import type { IAccordionData } from "../../../components/DisplayAndEditAccordion/DisplayAccordion";
import type { IHeroData } from "../../../components/HeroSection/HeroSection";
import type { ITagProps } from "../../../components/MarketsWeServe";
import useSWR from "swr";
import type { AxiosError } from "axios";
import { Redirect, useParams } from "react-router-dom";
import type { IFeaturedProductsData } from "../../../components/FeaturedProducts/FeaturedProducts";
import type { IAboutUsData } from "../../../components/AboutUs/AboutUs";
import type { IIintroductionParagraphData } from "../../../components/IntroductionParagraph/IntroductionParagraph";
import { CenterContainer } from "../../../layout/publicPageLayout";
import { Title } from "../../../components/Typography/Typography";
import { SomethingWentWrong } from "../../../components/SomethingWentWrong";
import type { SEODetail } from "../../../types/types";
import { endpoints } from "../../../endpoints";
import { SEOHelmet } from "../../../components/SEOHelmet/SEOHelmet";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../../util/util";
import { useRoutePath } from "../../../util/Routing";

export type IHomePageComponentName =
  | "HeroSection"
  | "featuredProducts"
  | "accordion"
  | "partners"
  | "tags"
  | "search"
  | "introduction"
  | "about_us";

export interface IHomePageComponent {
  name: IHomePageComponentName;
  order?: number;
  hide?: boolean;
  data?:
    | IHeroData
    | IFeaturedProductsData
    | IPartnersData
    | IAccordionData
    | ITagProps
    | IAboutUsData
    | IIintroductionParagraphData
    | any;
}

export interface IHomePageTemplateProps {
  homePageData: IHomePageData;
}

export interface IHomePageConfig {
  components: IHomePageComponent[];
}

export interface IHomePageData {
  template: string;
  config: IHomePageConfig;
}

export type IHomePageAPIResponse = IHomePageData;

const initialHomeData: IHomePageData = {
  template: "Ag3Homepage",
  config: {
    components: [
      {
        name: "HeroSection",
        data: {
          title: "",
          actionButtonText: "View Products",
        },
      },
      {
        name: "featuredProducts",
        data: {
          title: "Featured Products",
          productIds: [],
        },
      },
      {
        name: "partners",
        data: {
          title: "Partners",
          desc: "Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. Fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.",
          logos: [],
        },
      },
      {
        name: "accordion",
        data: {
          header: "Frequently Asked Questions",
          accordionContent: [],
        },
      },
      {
        name: "tags",
        data: {
          title: "Markets We Serve",
          content: [],
        },
      },
      {
        name: "introduction",
        data: {
          desc: "This is an introduction section. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
        },
      },
      {
        name: "about_us",
        data: {
          title: "About Us",
          desc: "Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. Fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
        },
      },
      { name: "search" },
    ],
  },
};

export const Homepage = () => {
  const [homePageData, setHomePageData] = useState<IHomePageData>();
  const { tenantSlug } = useParams<{ tenantSlug: string }>();
  const { t } = useTranslation();
  const { storePath } = useRoutePath();

  const {
    storefront_metadata: { route_configuration },
  } = useStoreState();

  const { data: homePageApiResponse, error: homePageDataError } = useSWR<
    IHomePageAPIResponse,
    AxiosError<IHomePageData>
  >(tenantSlug ? `/v1/storefronts/${tenantSlug}/homepage` : null, {
    revalidateOnFocus: false,
  });

  const { data: homePageSEO } = useSWR<SEODetail, AxiosError<SEODetail>>(
    endpoints.v1_storefronts_id_or_slug_seo_page(tenantSlug, "home_page")
  );

  useEffect(() => {
    if (homePageApiResponse) {
      if (homePageApiResponse.config) {
        setHomePageData(homePageApiResponse);
      } else {
        setHomePageData(initialHomeData);
      }
    }
  }, [homePageApiResponse]);

  useEffect(() => {
    if (homePageDataError) {
      console.error(homePageDataError);
    }
  }, [homePageDataError]);

  const homeRoute = route_configuration.find((route) => route.route === "home");

  if (homeRoute?.enabled === false) {
    return <Redirect to={`${storePath}/login`} />;
  }

  if (homePageData) {
    return (
      <>
        {/* Handle setting the SEO information every time it changes */}
        {homePageSEO && <SEOHelmet seo={homePageSEO} />}
        <Ag3Homepage homePageData={homePageData} />
      </>
    );
  } else if (homePageDataError) {
    if (homePageDataError.response?.status === 404) {
      return (
        <CenterContainer>
          <Title>{t("Storefront is not yet configured.")}</Title>
        </CenterContainer>
      );
    }
    return <SomethingWentWrong />;
  } else {
    return <Loader isLoading />;
  }
};
