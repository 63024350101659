import styled from "styled-components/macro";
import type {
  Assets,
  AssetsExternalLinks,
  PIMProduct,
} from "../../../../../types/types.PIM";
import { DocumentPortfolioAssets } from "./DocumentPortfolioAssets";
import { ExternalLinksPortfolioAssets } from "./ExternalLinksPortfolioAssets";
import type { DataMutate } from "../../../../../types/types";
import { DocumentsOrDigitalMediaTable } from "../DocumentsOrDigitalMediaTable/DocumentsOrDigitalMediaTable";

const PortfolioAssetsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PortfolioAssets = ({
  documentAssets,
  externalLinkAssets,
  digitalMediaAssets,
  product,
  fetchProductData,
}: {
  documentAssets: Assets[];
  externalLinkAssets: AssetsExternalLinks[];
  digitalMediaAssets: Assets[];
  product: PIMProduct;
  fetchProductData: DataMutate<PIMProduct>;
}) => {
  return (
    <PortfolioAssetsContainer>
      {documentAssets.length > 0 && (
        <DocumentPortfolioAssets assets={documentAssets} />
      )}
      {/* very confusing, but this actually only displays one or the other based on the title */}
      {digitalMediaAssets.length > 0 && (
        <DocumentsOrDigitalMediaTable
          assets={digitalMediaAssets}
          fetchProductData={fetchProductData}
          title={"Digital Media"}
          key={"Digital Media"}
          product={product}
          replaceProductId={() => Promise.resolve()}
          isOutsideLogin={true}
        />
      )}
      {externalLinkAssets.length > 0 && (
        <ExternalLinksPortfolioAssets links={externalLinkAssets} />
      )}
    </PortfolioAssetsContainer>
  );
};
