import type { QuoteRequestItem } from "../../types/types";
import type { FunctionComponent } from "react";
import React from "react";
import {
  H3,
  SoftHeader,
  SoftHeaderPrimaryTextColor,
} from "../Typography/Typography";
import { InfoBlockSmall } from "../InfoBlocks/InfoBlocks";
import { ManufacturedByIcon } from "../Icons/Icons";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import type { SetOptional } from "type-fest";
import { useRoutePath } from "../../util/Routing";
import { Link } from "react-router-dom";

const ProductGenericName = styled.span`
  margin-right: 21px;
`;

const FlexRow = styled.div`
  display: flex;
  & > div {
    flex-grow: 1;
    //margin-right: 9px;
    justify-content: space-between;
    width: 49%;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

type HeaderSectionProductForDisplay = SetOptional<
  QuoteRequestItem["product"],
  "produced_by"
>;
interface HeaderSectionProps {
  product: HeaderSectionProductForDisplay;
}

export const HeaderSection: FunctionComponent<HeaderSectionProps> = ({
  product,
}) => {
  const { t } = useTranslation();

  const { storePath } = useRoutePath();

  const chemicalName = product.identifiers?.chemical_name;
  const producedBy = product.produced_by;
  const productLinkPath = `${storePath}/product/${product.slug}`;

  return (
    <>
      <Link to={productLinkPath}>
        <H3>{product.name}</H3>
      </Link>
      <FlexRow>
        {chemicalName && (
          <ProductGenericName>
            <SoftHeaderPrimaryTextColor>
              {chemicalName}
            </SoftHeaderPrimaryTextColor>
          </ProductGenericName>
        )}
        {product.identifiers?.cas_number && (
          // TODO - When cas_number doesn't exist, fall back to INCI if
          // it exists, otherwise show any identifier. (At time of
          // writing INCI does not exist in product.identifiers.)
          <SoftHeader>
            {/* This is not translated because its a chemical industry term of art. do not translate this. */}
            CAS # {product.identifiers.cas_number}
          </SoftHeader>
        )}
      </FlexRow>
      <FlexRow>
        {/* TODO try to narrow this type */}
        {producedBy && (
          <InfoBlockSmall
            header={t("Produced by")}
            content={producedBy as string}
            icon={<ManufacturedByIcon width={16} height={16} />}
          />
        )}
      </FlexRow>
    </>
  );
};
