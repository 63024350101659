import type { ReactNode } from "react";
import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components/macro";
import { XIcon } from "../Icons/Icons";

type ModalProps = {
  show: boolean;
  closeModal: () => void;
  children: ReactNode;
  overlay?: boolean;
  modalWidth?: string;
  bottomPosition?: boolean;
};
interface IModalOverlayProps {
  overlay?: boolean;
  modalWidth?: string;
}

const ModalAnimation = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;
const CloseIconAnimation = keyframes`
 0% { opacity: 0; transform: rotate(270deg)}
 100% { opacity: 1; transform: rotate(0deg)}
 `;

const ModalOverlay = styled.div<IModalOverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme, overlay }) =>
    overlay ? "#0f1d2b75" : theme.primaryBG};
  animation-name: ${ModalAnimation};
  animation-duration: 0.3s;
  z-index: 900;
  overflow: auto;
  display: flex;
  align-items: ${({ overlay }) => (overlay ? "center" : "unset")};
`;

export const ModalContainer = styled.div<IModalOverlayProps>`
  width: ${({ overlay }) => (overlay ? "95%" : "100%")};
  background: ${({ theme, overlay }) =>
    overlay ? theme.primaryBG : "transparent"};
  max-width: ${({ overlay, modalWidth }) =>
    overlay ? modalWidth || "none" : modalWidth || "500px"};
  max-height: ${({ overlay }) => (overlay ? "85%" : "100%")};
  overflow: ${({ overlay }) => (overlay ? "auto" : "visible")};
  position: relative;
  margin: 0px auto;
  padding: 20px;
  border-radius: 8px;
`;

const BottomModalContainer = styled.div<IModalOverlayProps>`
  width: ${({ overlay }) => (overlay ? "95%" : "100%")};
  background: ${({ theme, overlay }) =>
    overlay ? theme.primaryBG : "transparent"};
  max-width: ${({ overlay, modalWidth }) =>
    overlay ? modalWidth || "none" : modalWidth || "500px"};
  overflow: ${({ overlay }) => (overlay ? "auto" : "visible")};
  position: relative;
  margin: 0px auto;
  padding: 20px;
  border-radius: 8px;
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const CloseModalButton = styled.button<IModalOverlayProps>`
  position: ${({ overlay }) => (overlay ? "absolute" : "fixed")};
  right: ${({ overlay }) => (overlay ? "20px" : "1rem")};
  top: ${({ overlay }) => (overlay ? "20px" : "1rem")};
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: transparent;
  animation-name: ${CloseIconAnimation};
  animation-duration: 0.4s;
`;

const ModalInternal = ({ ...props }: ModalProps & { modalWidth?: string }) => {
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        props.closeModal();
      }
    },
    [props]
  );

  useEffect(() => {
    document.addEventListener("keyup", escFunction);

    return () => {
      document.removeEventListener("keyup", escFunction);
    };
  }, [escFunction]);

  if (props.bottomPosition) {
    return ReactDOM.createPortal(
      <ModalOverlay overlay={props.overlay}>
        <BottomModalContainer
          overlay={props.overlay}
          modalWidth={props.modalWidth}
        >
          {props.children}
        </BottomModalContainer>
      </ModalOverlay>,
      document.body
    );
  } else {
    return ReactDOM.createPortal(
      <ModalOverlay overlay={props.overlay}>
        <ModalContainer overlay={props.overlay} modalWidth={props.modalWidth}>
          {props.children}
          <CloseModalButton overlay={props.overlay} onClick={props.closeModal}>
            <XIcon />
          </CloseModalButton>
        </ModalContainer>
      </ModalOverlay>,
      document.body
    );
  }
};

/**
 * Full screen modal
 * @param show boolean
 * @param closeModal Function
 */
export const Modal = ({
  show,
  closeModal,
  children,
  overlay,
  modalWidth,
}: ModalProps) => {
  if (show) {
    return (
      <ModalInternal
        show={show}
        closeModal={closeModal}
        modalWidth={modalWidth || "500px"}
        overlay={overlay}
      >
        {children}
      </ModalInternal>
    );
  }
  return null;
};

/**
 * full screen modal with wider content
 */
export const ModalWide = ({
  show,
  closeModal,
  children,
  overlay,
}: ModalProps) => {
  if (show) {
    return (
      <ModalInternal
        show={show}
        closeModal={closeModal}
        overlay={overlay}
        modalWidth="800px"
      >
        {children}
      </ModalInternal>
    );
  }
  return null;
};

/**
 * Modal "overlaid" on the screen, with gaps to view content on the sides.
 */
export const OverLayModal = ({
  show,
  closeModal,
  children,
  overlay,
}: ModalProps) => {
  if (show) {
    return (
      <ModalInternal
        show={show}
        closeModal={closeModal}
        overlay={overlay}
        // modalWidth="800px"
      >
        {children}
      </ModalInternal>
    );
  }
  return null;
};

export const BottomModal = (props: ModalProps) => {
  if (props.show) {
    return <ModalInternal {...props}>{props.children}</ModalInternal>;
  }
  return null;
};
