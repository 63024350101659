// Commonly used shared strings.

import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";

export const strings = (t: TFunction) => ({
  thisIsARequiredField: t("This is a required field"),
  phoneNumberMustBeValid: t("Phone number must be valid"),
  emailAddressMustBeValid: t("Email address must be valid"),
  emailValidationError: t("Email validation error, please try again"),
  submitError: t("There was an error, please try again"),
  packageVolumeError: t("Packaging Volume must be a number"),
  currencyMustBeValid: t("Currency value must be valid"),
  unspecified: t("(Unspecified)"),
  priceTierMatchError: t("Number of units must match a tier"),
});

export const useStrings = () => {
  const { t } = useTranslation();

  return { strings: strings(t) };
};
