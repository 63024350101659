import React, { useCallback, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { PrimaryButtonMedium } from "../Buttons/Buttons";
import { DropDownModal } from "../DropDownModal/DropDownModal";
import { CaretDownIcon, CaretUpIcon } from "../Icons/Icons";
import { match } from "ts-pattern";
import { useOnClickOutside } from "../../util/hooks";

type DropDownButtonProps = {
  items: (number | string)[];
  activeItem?: number | string;
  textLeft: string;
  clickHandler: (item: string) => void;
  className?: string;
};

const DropDownButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ButtonContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const DropDownModalWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  z-index: 1;
`;

export function DropDownButton({
  items,
  activeItem,
  textLeft,
  clickHandler,
  className,
}: DropDownButtonProps) {
  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useOnClickOutside(wrapperRef, () => setShowDropDown(false));

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const buttonText = (event.target as HTMLElement)?.textContent;
      clickHandler(buttonText ?? "");
      setShowDropDown(false);
    },
    [clickHandler]
  );

  const toggleDropDown = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setShowDropDown((prev) => !prev);
  }, []);

  const renderCaret = useMemo(
    () =>
      match(showDropDown)
        .with(true, () => (
          <CaretUpIcon
            fill={theme.primaryButtonTextColor}
            width={21}
            height={21}
          />
        ))
        .otherwise(() => (
          <CaretDownIcon
            fill={theme.primaryButtonTextColor}
            width={21}
            height={21}
          />
        )),
    [showDropDown, theme.primaryButtonTextColor]
  );

  return (
    <DropDownButtonWrapper ref={wrapperRef} className={className}>
      <PrimaryButtonMedium onClick={toggleDropDown}>
        <ButtonContent>
          <span style={{ marginRight: "8px" }}>{textLeft}</span>
          {renderCaret}
        </ButtonContent>
      </PrimaryButtonMedium>
      {showDropDown && (
        <DropDownModalWrapper>
          <DropDownModal
            items={items}
            activeItem={activeItem}
            clickHandler={handleClick}
          />
        </DropDownModalWrapper>
      )}
    </DropDownButtonWrapper>
  );
}
