import { H3 } from "../Typography/Typography";
import { PrimaryButtonLarge } from "../Buttons/Buttons";
import React from "react";
import { CenterContainer } from "../../layout/publicPageLayout";
import { useTranslation } from "react-i18next";

interface IErrorPlaceholder {
  message: string;
}

/**
 * Display a placeholder error page with custom message if a request fails
 * @param message: string
 */
export function ErrorPlaceholder({ message }: IErrorPlaceholder) {
  const handleClick = () => {
    window.location.reload();
  };

  const { t } = useTranslation();

  return (
    <>
      <CenterContainer>
        <H3>{message}</H3>
        <PrimaryButtonLarge onClick={handleClick}>
          {t("Reload Page")}
        </PrimaryButtonLarge>
      </CenterContainer>
    </>
  );
}
