import {
  Accordion as ReachAccordion,
  AccordionItem as ReachAccordionItem,
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel,
} from "@reach/accordion";
import "@reach/accordion/styles.css";
import styled from "styled-components/macro";
import { keyframes } from "styled-components";

export const AccordionButton = styled(ReachAccordionButton)`
  width: 100%;
  padding-left: 29px;
  padding-right: 29px;
  background: ${({ theme }) => theme.primaryBG};
  border: 0;
  color: ${(props) => props.theme.primaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.large};
  cursor: pointer;
  outline: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: -0.38px;
  font-weight: ${({ theme }) => theme.fontWeights.small};
  > svg {
    background-size: 70px;
    background-color: ${({ theme }) => theme.primaryIconBackground};
    border-radius: 50%;
    display: inline-block;
    width: 28px;
    height: 28px;
    overflow: visible;
  }
  &[aria-expanded="true"] {
    > svg {
      transition-duration: 0.8s;
      transition-property: transform;
      transform: rotate(180deg);
    }
  }
`;

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AccordionPanel = styled(ReachAccordionPanel)`
  padding: 20px;
  animation: ${slideDown} 0.2s ease;
`;

// These are unchanged from the stock reach elements, just re exporting from
// here for ease of use.
export const Accordion = ReachAccordion;
export const AccordionItem = styled(ReachAccordionItem)`
  border: 1px solid ${(props) => props.theme.primaryBorder};
  border-radius: 4px;
  margin-bottom: 20px;
`;
