import styled from "styled-components/macro";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 515px;
  padding: 15px 0px 15px;
  margin: 15px 0px;
  & > * {
    margin-bottom: 20px;
  }
`;

export type FormSource = "existing" | "new";
