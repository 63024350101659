import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import type { SortingRule } from "react-table";
import useSWR from "swr";
import { useQueryParams, StringParam, NumberParam } from "use-query-params";
import { useAuthContext } from "../../../../../components/Auth";
import { PrimaryButtonWithPlusIcon } from "../../../../../components/Buttons/Buttons";
import { DropDown } from "../../../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../../../components/Layout/Layout";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../../components/Table/Table";
import { Flex, Flex2, Flex1 } from "../../../../../layout/FormLayout";
import { PageWrapper } from "../../../../../layout/portalPageLayout";
import { ContentWrapper } from "../../../../../layout/publicPageLayout";
import type { UUID } from "../../../../../types/types";
import type { TenantAttributeGroupSummary } from "../../../../../types/types.PIM";
import { useDebounce } from "../../../../../util/hooks";
import { useRoutePath } from "../../../../../util/Routing";
import {
  useStoreState,
  rowHover,
  defaultHandleSort,
} from "../../../../../util/util";
import { AttributesNav } from "../components/AttributesNav";
import { NameWithSystemDefaultIcon } from "../components/PIM.components.util";
import { CreateNewAttributeGroup } from "./CreateNewAttributeGroup";

export function SellerAdminPIMAttributeGroups() {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [perPage, setPerPage] = useState(query?.perPage ?? 10);
  const perPageItems = [10, 20, 50];
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });
  const history = useHistory();
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const [showCreateGroupForm, setShowCreateGroupForm] = useState(false);

  const constructQuery = ({
    baseUrl,
    query,
  }: {
    baseUrl: string;
    query: string;
  }) => {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}`
    );
    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }

    return baseUrl + paramsWithOffset;
  };

  const {
    data: attributeGroupsResponse,
    error: attributesGroupsError,
    mutate: mutateAttributeGroups,
  } = useSWR<TenantAttributeGroupSummary>(
    constructQuery({
      baseUrl: `/v2/tenants/${tenant_id}/pim/groups?`,
      query: debouncedSearchQuery,
    })
  );

  const isLoading = !attributeGroupsResponse && !attributesGroupsError;
  const [tableData, setTableData] = useState<
    {
      attribute_count: number;
      name: { name: string; is_system: boolean; id: string };
      id: UUID;
      display_name: string;
    }[]
  >([]);
  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);
  const { hasPermission } = useAuthContext();

  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Group Name"),
        accessor: "name",
        Cell: ({
          value,
        }: {
          value: { name: string; is_system: boolean; id: string };
        }) => (
          <NameWithSystemDefaultIcon
            name={value.name}
            id={value.id}
            is_system={value.is_system}
          />
        ),
      },
      {
        Header: t("Display Name"),
        accessor: "display_name",
      },
      {
        // I feel like this wrapping in styled divs shoudln't be needed but this
        // is a way to make it look like the design.
        Header: t("No. of Attributes"),
        accessor: "attribute_count",
        Cell: ({ value }: { value: number }) => (
          <div style={{ textAlign: "left" }}>{value}</div>
        ),
      },
    ],
    [t]
  );

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  useEffect(() => {
    const handleGroupData = ({
      data,
      pagination,
    }: TenantAttributeGroupSummary) => {
      setTableData(
        data.map((group) => ({
          id: group.id,
          attribute_count: group.attribute_count,
          display_name: group.display_name ? t([group.display_name]) : "",
          name: { name: group.name, id: group.id, is_system: group.is_system },
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (attributeGroupsResponse) {
      handleGroupData(attributeGroupsResponse);
    }
  }, [attributeGroupsResponse, setTableData, perPage, t]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  const handleRowClick = (e: React.MouseEvent) => {
    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(offset));
    params.append("perPage", String(perPage));
    history.push(
      `${adminPath}/pim/attributes/groups/${e?.currentTarget?.id}?${params}`
    );
  };

  return (
    <PageWrapper>
      <AttributesNav tabName="Groups" />
      <Flex style={{ marginBottom: "24px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by Group name or Attribute name")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
          />
        </Flex2>
        {hasPermission("modify_groups") && (
          <Flex1 style={{ textAlign: "right" }}>
            <PrimaryButtonWithPlusIcon
              onClick={() => setShowCreateGroupForm(true)}
            >
              <span style={{ fontSize: "15px" }}>{t("Create")}</span>
            </PrimaryButtonWithPlusIcon>
          </Flex1>
        )}
      </Flex>
      <SlideOut
        show={showCreateGroupForm}
        closeFlyout={() => setShowCreateGroupForm(false)}
      >
        <CreateNewAttributeGroup
          onSuccess={() => {
            setShowCreateGroupForm(false);
            mutateAttributeGroups();
          }}
        />
      </SlideOut>
      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          hideBorder
          showIcon
          textLeft={t("items: ")}
          textRight={t("Per Page")}
          className={"per_Page"}
          direction={"left"}
          clickHandler={changePerPage}
        />
      </DropdownContainer>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={attributesGroupsError}
          rowClick={handleRowClick}
          rowHover={rowHover}
          handleSort={handleSort}
          lastChildleftAlign
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </PageWrapper>
  );
}
