import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

const FullPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
  text-align: center;
  h3 {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 500;
    color: #0f1d2b;
  }
`;

const Button = styled.button`
  border-radius: 4px;
  font-family: "Inter";
  :focus {
    outline: none;
  }
  border-width: 0px;
`;

const PrimaryButtonLarge = styled(Button)`
  background-color: #0090e3;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  padding: 17px 33px;
  font-size: 15px;

  :hover {
    background-color: #0073b5;
  }

  :active {
    background-color: #005688;
  }
`;

const handleClick = () => {
  window.location.reload();
};

/**
 * Display full page "something went wrong" message,
 * all colors are hardcoded to agilis theme here because when the app
 * crashses we will not be able to access the tenant theme.
 */
export function SomethingWentWrong() {
  const { t } = useTranslation();
  return (
    <>
      <FullPageContainer>
        <h3>{t("Something went wrong, our engineers are working on it.")}</h3>
        <PrimaryButtonLarge onClick={handleClick}>
          {t("Reload Page")}
        </PrimaryButtonLarge>
      </FullPageContainer>
    </>
  );
}
