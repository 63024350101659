import React from "react";
import styled from "styled-components/macro";
import { SecondaryButtonMedium, TextButton } from "../Buttons/Buttons";
import { LargeTitleRegularWeight } from "../Typography/Typography";
import { screenSize } from "../../theme";
import { NothingToDisplay } from "../NothingToDisplay/NothingToDisplay";
import { SvgRectangle } from "../Icons/Icons";

const imageWidth = 337;
const imageHeight = 300;

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  @media ${screenSize.large} {
    /* Prevents a grid with one row of 3 and one row of 1. */
    /* We want either one row of 4, two rows of 2, or four rows of 1. */
    max-width: 750px;
  }
`;

const CardContainer = styled.div`
  max-width: ${imageWidth}px;
  margin: 0 17px 40px 0;
  flex: 1;

  @media ${screenSize.xl} {
    :last-child {
      margin-right: 0;
    }
  }

  @media ${screenSize.large} {
    min-width: 250px;
  }

  @media ${screenSize.medium} {
    margin: 0px auto 30px;
  }

  @media ${screenSize.small} {
    border: 1px solid ${({ theme }) => theme.primaryBorder};
    border-radius: 4px;
    margin: 0px auto 20px;
    padding: 5px 32px 18px;
  }
`;

const ImageContainer = styled.div`
  overflow: hidden;
  @media ${screenSize.small} {
    display: none;
  }
`;

const ImageSizer = styled.div`
  // A CSS hack to enforce the aspect ratio (width / height) of the image.
  // The padding-top sets the height of the div, and then the image is
  // "position: absolute" so the padding-top doesn't affect it.
  // Needed because older browsers don't support "aspect-ratio".
  // 300 (height) divided by 337 (width) = 0.89
  padding-top: 89%;
  position: relative;
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  aspect-ratio: 337/300;
  object-position: bottom;
`;

const CardTitle = styled(LargeTitleRegularWeight)`
  margin: 29px 0 9px;
  max-width: 100%;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media ${screenSize.small} {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

const TitleContainer = styled.div`
  width: 98%;
`;

const CardLinkContainer = styled.div`
  min-height: 22px;
  margin: 0 0 16px;
`;

const CardLink = styled(TextButton)`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: normal;
  text-decoration: none;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${screenSize.small} {
    display: none;
  }
`;

const CardButton = styled(SecondaryButtonMedium)`
  margin: 0 18px 0 0;
`;

export interface CardGridItem {
  itemTitle?: string;
  imageUrl?: string | null;
  imageAlt?: string;
  link?: {
    text: string;
    url: string;
  };
  firstButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
  secondButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
}

/**
 * React component that displays a grid of products or other items, with an
 * image, a title, a link, and one or two buttons. It is used, for example,
 * to display a grid of featured products in storefront homepage templates.
 */
export const CardGrid = ({ items }: { items: CardGridItem[] }) => {
  if (!items || items.length === 0) {
    return <NothingToDisplay />;
  }
  return (
    <GridContainer>
      {items.map((item, index) => {
        const {
          itemTitle,
          imageUrl,
          imageAlt,
          link,
          firstButton,
          secondButton,
        } = item;

        return (
          <CardContainer key={index}>
            <ImageContainer>
              {imageUrl ? (
                <ImageSizer>
                  <Image src={imageUrl} alt={imageAlt || itemTitle} />
                </ImageSizer>
              ) : (
                <SvgRectangle height={imageHeight} width={imageWidth} />
              )}
            </ImageContainer>
            <TitleContainer>
              <CardTitle>{itemTitle || "--"}</CardTitle>
            </TitleContainer>
            <CardLinkContainer>
              {link && (
                <CardLink as="a" href={link.url}>
                  {link.text} &#8811;
                </CardLink>
              )}
            </CardLinkContainer>
            {(firstButton || secondButton) && (
              <ButtonsContainer>
                {firstButton && (
                  <CardButton
                    onClick={firstButton.onClick}
                    disabled={firstButton.disabled}
                  >
                    {firstButton.text}
                  </CardButton>
                )}
                {secondButton && (
                  <CardButton
                    onClick={secondButton.onClick}
                    disabled={secondButton.disabled}
                  >
                    {secondButton.text}
                  </CardButton>
                )}
              </ButtonsContainer>
            )}
          </CardContainer>
        );
      })}
    </GridContainer>
  );
};
