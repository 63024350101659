import { useState, useContext } from "react";
import { Form } from "../../../../layout/FormLayout";
import { useStoreState, useFormWrapper } from "../../../../util/util";
import { PrimaryButtonMedium } from "../../../../components/Buttons/Buttons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import { endpoints } from "../../../../endpoints";
import { FormContainer, SaveButtonContainer } from "./CustomerSettings";
import { ToggleSwitch } from "../../../../components/ToggleSwitch/ToggleSwitch";
import { useTranslation } from "react-i18next";

export const EditNotificationPreferencesForm = ({
  tenantID,
  sellerID,
  refreshSettingsData,
  doneEditing,
  quoteNotifications,
  orderNotifications,
  sampleRequestNotifications,
}: {
  tenantID: string;
  sellerID: string;
  refreshSettingsData?: (notificationPreferences: {
    quote_notifications: boolean;
    order_notifications: boolean;
    sample_request_notifications: boolean;
  }) => void;
  doneEditing: () => void;
  quoteNotifications: boolean;
  orderNotifications: boolean;
  sampleRequestNotifications: boolean;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quoteNotificationsState, setQuoteNotificationsState] =
    useState(quoteNotifications);
  const [orderNotificationsState, setOrderNotificationsState] =
    useState(orderNotifications);
  const [sampleRequestNotificationsState, setSampleRequestNotificationsState] =
    useState(sampleRequestNotifications);
  const { t } = useTranslation();
  const { handleSubmit } = useFormWrapper();

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const requestBody = {
        notification_preferences: {
          quote_notifications: quoteNotificationsState,
          order_notifications: orderNotificationsState,
          sample_request_notifications: sampleRequestNotificationsState,
        },
      };
      await Axios.patch(
        endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
          storefront_id,
          sellerID,
          tenantID
        ),
        requestBody
      );
      notifySuccess(t("Changes saved"));
      setIsSubmitting(false);
      if (refreshSettingsData) {
        refreshSettingsData({
          quote_notifications: quoteNotificationsState,
          order_notifications: orderNotificationsState,
          sample_request_notifications: sampleRequestNotificationsState,
        });
      }
      doneEditing();
    } catch (error) {
      notifyError(t("There was an error submitting the changes"), { error });
      setIsSubmitting(false);
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ToggleSwitch
          label={t(
            "Quote Notifications to Shipping Location's Point of Contact"
          )}
          name="quote_notifications"
          isChecked={quoteNotificationsState}
          onClick={() => setQuoteNotificationsState((prev) => !prev)}
        />
        <ToggleSwitch
          label={t(
            "Order Notifications to Shipping Location's Point of Contact"
          )}
          name="order_notifications"
          isChecked={orderNotificationsState}
          onClick={() => setOrderNotificationsState((prev) => !prev)}
        />
        <ToggleSwitch
          label={t(
            "Sample Notifications to Shipping Location's Point of Contact"
          )}
          name="sample_request_notifications"
          isChecked={sampleRequestNotificationsState}
          onClick={() => setSampleRequestNotificationsState((prev) => !prev)}
        />
        <SaveButtonContainer>
          <PrimaryButtonMedium loading={isSubmitting}>
            {t("Save your changes")}
          </PrimaryButtonMedium>
        </SaveButtonContainer>
      </Form>
    </FormContainer>
  );
};
