import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CancelButton,
  PrimaryButtonFitContainer,
  PrimaryButtonMedium,
} from "../Buttons/Buttons";
import type { ChipType } from "../Chips/Chips";
import { Chips } from "../Chips/Chips";
import { HeaderLeft } from "../Layout/Layout";
import { TextField } from "../TextFields/TextFields";
import type {
  IAgilisUserPatchArgs,
  OptionType,
  SupportedLanguage,
  User,
  UserType,
  UserTypeChip,
  UUID,
} from "../../types/types";
import { Flex, Flex1, Flex2, Form, FormGrid2x2 } from "../../layout/FormLayout";
import { Card, PageTitle } from "../../layout/portalPageLayout";
import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { ToggleSwitch } from "../ToggleSwitch/ToggleSwitch";
import { Notifications } from "../Notifications/NotificationsContext";
import { strings } from "../../util/strings";
import {
  useSupportedLanguages,
  useFormWrapper,
  useStoreState,
  isCustomerUserType,
} from "../../util/util";
import {
  countryCodeMap,
  getCountryCode,
  getPhoneCodeOption,
  getPhoneCodesOptions,
  getPhoneNumber,
} from "../../util/phone";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import { CheckBoxNoLabel } from "../CheckBoxes/CheckBoxes";
import {
  SectionTitle,
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
} from "../Form/Form";
import styled from "styled-components/macro";
import { endpoints } from "../../endpoints";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal/Modal";
import useSWR from "swr";
import type { RoleSummary } from "../../types/types.PIM";
import { roleSummaryToOption } from "../CreateNewUser/CreateNewUser";
import { useAuthContext } from "../Auth";

const ModalWrapper = styled.div`
  padding: 50px 90px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const WarningTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.warningTextColor};
  margin-bottom: 30px;
`;

const ToggleSwitchWrapper = styled.div`
  margin: 20px 0;
`;

// Note: user emails cannot be edited using this form, but we do want to
// show the email address because currently there's no other way to view it.
interface FormInputs {
  firstname: string;
  lastname: string;
  email_address: string;
  preferred_language: OptionType<SupportedLanguage>;
  phone_number: string;
  country_code: OptionType;
  crm_id?: string;
  role: OptionType<UUID>;
  user_type_chip?: UserTypeChip;
  is_active: boolean;
  quote_threshold_usd: number | null;
  order_threshold_usd: number | null;
  quote_threshold_local: number | null;
  order_threshold_local: number | null;
  quote_threshold_usd_unlimited: boolean;
  quote_threshold_default_unlimited: boolean;
  order_threshold_usd_unlimited: boolean;
  order_threshold_default_unlimited: boolean;
  leads_notification: boolean;
  leads_enable_quote_request: boolean;
  leads_enable_sample_request: boolean;
  leads_enable_registration: boolean;
  leads_enable_contact_us: boolean;
}

const CheckBoxAndTextField = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
`;

/**
 * Takes a user type and returns the corresponding chip if it's one of the user
 * type chips. Otherwise returns undefined. Used for determining
 * which chip should be selected when the form is rendered.
 */
const getSelectedChip = (
  userTypeChips: UserTypeChip[],
  user_type: UserType
): UserTypeChip | undefined => {
  const chip = userTypeChips.find((chip) => chip.id === user_type);
  if (!chip) {
    console.warn("User type is not one of the user type options:", user_type);
  }
  return chip;
};

const is_user_seller = (user_type: UserType) =>
  user_type === "Seller Admin" || user_type === "Seller Standard";

type EditUserProps = {
  user: User;
  userTypeChips: UserTypeChip[];
  onSuccess: () => void;
};

export function EditUser({ user, userTypeChips, onSuccess }: EditUserProps) {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const {
    roleIsSellerAdmin,
    roleIsSomeKindOfBuyer,
    updateUser: update_user_context,
    user: current_user,
  } = useAuthContext();
  const {
    storefront_id,
    storefront_metadata: { default_currency, edition },
  } = useStoreState();
  const [submitting, setSubmitting] = useState(false);
  const selectedChip = getSelectedChip(userTypeChips, user.user_type);
  const [isRequiredQuoteUSD, setIsRequiredQuoteUSD] = useState(false);
  const [isRequiredQuoteDefault, setIsRequiredQuoteDefault] = useState(false);
  const [isRequiredOrderUSD, setIsRequiredOrderUSD] = useState(false);
  const [isRequiredOrderDefault, setIsRequiredOrderDefault] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formInputs, setFormInputs] = useState<FormInputs>();
  const [userNotifications, setUserNotifications] = useState(
    user.email_notifications?.find(
      (notifications) => notifications.storefront_id === storefront_id
    ) || {
      leads: true,
      storefront_id: storefront_id,
      leads_enable_quote_request: true,
      leads_enable_sample_request: true,
      leads_enable_registration: true,
      leads_enable_contact_us: true,
    }
  );
  const { t } = useTranslation();
  const { getLanguageOption, supportedLanguageOptions } =
    useSupportedLanguages();
  /**
   * We don't require user_type_chip because if the user is not the type of one of
   * the chip options then requiring it would force a change in user type.
   */
  // TODO: get this to type check without the `any`. Maybe use zod instead?
  const yupSchema = yup.lazy((formValues: any) => {
    return yup.object().shape({
      firstname: yup.string().required(strings(t).thisIsARequiredField),
      lastname: yup.string().required(strings(t).thisIsARequiredField),
      crm_id: yup.string().nullable().notRequired(),
      phone_number: yup
        .string()
        .phone(
          countryCodeMap.get(formValues.country_code.value),
          false,
          strings(t).phoneNumberMustBeValid
        )
        .required(strings(t).thisIsARequiredField),
      preferred_language: yup
        .object()
        .shape({ value: yup.string(), label: yup.string() })
        .required(strings(t).thisIsARequiredField),
      country_code: yup
        .object()
        .shape({
          label: yup.string().required(strings(t).thisIsARequiredField),
          value: yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      is_active: yup.boolean().required(strings(t).thisIsARequiredField),

      quote_threshold_usd: isRequiredQuoteUSD
        ? yup
            .number()
            .typeError(strings(t).thisIsARequiredField)
            .min(0, t("Value cannot be negative"))
            .required(strings(t).thisIsARequiredField)
        : yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .notRequired(),

      order_threshold_usd: isRequiredOrderUSD
        ? yup
            .number()
            .typeError(strings(t).thisIsARequiredField)
            .min(0, t("Value cannot be negative"))
            .required(strings(t).thisIsARequiredField)
        : yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .notRequired(),

      quote_threshold_local: isRequiredQuoteDefault
        ? yup
            .number()
            .typeError(strings(t).thisIsARequiredField)
            .min(0, t("Value cannot be negative"))
            .required(strings(t).thisIsARequiredField)
        : yup
            .number()
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value))
            .notRequired(),

      order_threshold_local: isRequiredOrderDefault
        ? yup
            .number()
            .typeError(strings(t).thisIsARequiredField)
            .min(0, t("Value cannot be negative"))
            .required(strings(t).thisIsARequiredField)
        : yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .notRequired(),

      quote_threshold_usd_unlimited: yup.boolean().notRequired(),
      quote_threshold_default_unlimited: yup.boolean().notRequired(),
      order_threshold_usd_unlimited: yup.boolean().notRequired(),
      order_threshold_default_unlimited: yup.boolean().notRequired(),
    });
  });

  const {
    handleSubmit,
    register,
    formState,
    control,
    errors,
    setValue,
    watch,
    clearErrors,
  } = useFormWrapper<FormInputs>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      firstname: user.firstname,
      lastname: user.lastname,
      email_address: user.email_address,
      phone_number: getPhoneNumber(user.phone_number)?.toString(),
      crm_id: user?.crm_id,
      preferred_language: getLanguageOption(user.preferred_language) ?? {
        value: "en",
        label: "English",
      },
      country_code: getPhoneCodeOption(getCountryCode(user.phone_number)),
      user_type_chip: selectedChip,
      is_active: user.is_active,
      quote_threshold_usd_unlimited: user.quote_threshold_usd === null,
      quote_threshold_default_unlimited: user.quote_threshold_local === null,
      order_threshold_usd_unlimited: user.order_threshold_usd === null,
      order_threshold_default_unlimited: user.order_threshold_local === null,
      order_threshold_usd: user.order_threshold_usd,
      order_threshold_local: user.order_threshold_local,
      quote_threshold_usd: user.quote_threshold_usd,
      quote_threshold_local: user.quote_threshold_local,
      leads_notification: user.email_notifications?.find(
        (notifications) => notifications.storefront_id === storefront_id
      )?.leads,
    },
  });

  const { data, error: rolesError } = useSWR<{ data: RoleSummary }, AxiosError>(
    is_user_seller(user.user_type)
      ? `/v1/storefronts/${storefront_id}/roles`
      : null
  );

  const roles = data?.data;
  const roleOptions = roles && !rolesError ? roleSummaryToOption(roles) : [];

  useEffect(() => {
    // We don't require user_type_chip because if the user is not the type ofone of
    // the chip options then requiring it would force a change in user type.
    register({ name: "user_type_chip" });
    register({ name: "is_active", required: true });
  }, [register]);

  const watchedUserRole = watch(["role"]);

  const watchedValues = watch([
    "quote_threshold_usd_unlimited",
    "quote_threshold_default_unlimited",
    "order_threshold_usd_unlimited",
    "order_threshold_default_unlimited",
    "leads_notification",
    "leads_enable_quote_request",
    "leads_enable_sample_request",
    "leads_enable_registration",
    "leads_enable_contact_us",
  ]);

  const {
    quote_threshold_usd_unlimited,
    quote_threshold_default_unlimited,
    order_threshold_usd_unlimited,
    order_threshold_default_unlimited,
    leads_notification,
    leads_enable_quote_request,
    leads_enable_sample_request,
    leads_enable_registration,
    leads_enable_contact_us,
  } = watchedValues;

  useEffect(() => {
    // Accomplish two things:
    //  1. make the input required if the corresponding
    //  "unlimited" checkbox is unchecked
    //  2. clear the error if the user tries to submit the form with the textfield
    //  in a required state and later clicks the checkbox to make it unrequired.
    setIsRequiredQuoteUSD(
      watchedValues.quote_threshold_usd_unlimited === false &&
        watchedUserRole?.role?.label !== "Admin"
    );
    if (!isRequiredQuoteUSD && errors.quote_threshold_usd) {
      clearErrors("quote_threshold_usd");
    }

    setIsRequiredOrderUSD(
      watchedValues.order_threshold_usd_unlimited === false &&
        watchedUserRole?.role?.label !== "Admin"
    );
    if (!isRequiredOrderUSD && errors.order_threshold_usd) {
      clearErrors("order_threshold_usd");
    }

    setIsRequiredQuoteDefault(
      watchedValues.quote_threshold_default_unlimited === false
    );
    if (!isRequiredQuoteDefault && errors.quote_threshold_local) {
      clearErrors("quote_threshold_local");
    }

    setIsRequiredOrderDefault(
      watchedValues.order_threshold_default_unlimited === false
    );
    if (!isRequiredOrderDefault && errors.order_threshold_local) {
      clearErrors("order_threshold_local");
    }

    // Set textfields to null if their unlimited checkbox is checked.
    watchedValues.quote_threshold_usd_unlimited &&
      setValue("quote_threshold_usd", null);
    watchedValues.order_threshold_usd_unlimited &&
      setValue("order_threshold_usd", null);
    watchedValues.order_threshold_default_unlimited &&
      setValue("order_threshold_local", null);
    watchedValues.quote_threshold_default_unlimited &&
      setValue("quote_threshold_local", null);
    watchedUserRole?.role?.label === "Admin" &&
      setValue("order_threshold_usd", null);
    watchedUserRole?.role?.label === "Admin" &&
      setValue("quote_threshold_usd", null);
  }, [
    watchedValues,
    setValue,
    clearErrors,
    errors.order_threshold_local,
    errors.order_threshold_usd,
    errors.quote_threshold_local,
    errors.quote_threshold_usd,
    isRequiredOrderDefault,
    isRequiredOrderUSD,
    isRequiredQuoteDefault,
    isRequiredQuoteUSD,
    watchedUserRole,
  ]);

  const userIsSellerButIsNotAdmin =
    is_user_seller(user.user_type) &&
    user.role &&
    watchedUserRole?.role?.label !== "Admin";

  const displaySellerLimits = userIsSellerButIsNotAdmin && edition !== "pim";

  const showLocalCurrencyLimits = default_currency !== "USD";

  const onUserTypeToggle = (userTypeChipArray: ChipType[]) => {
    setValue("user_type_chip", userTypeChipArray[0]);
  };

  const watchIsActive = watch("is_active", user.is_active);

  const onSubmit = async (inputs: FormInputs) => {
    if (user?.is_primary_contact || user.is_point_of_contact) {
      setFormInputs(inputs);
      setShowConfirmationModal(true);
    } else {
      submitForm(inputs);
    }
  };

  /**
   * If the selected role is an admin, the user_type should change to Seller Admin (Changing roles only apply to sellers at this point.)
   * @param roleName
   * @param userType
   * @returns
   */
  const getUserType = (roleName: string | undefined, userType?: UserType) => {
    if (roleName) {
      return roleName === "Admin" ? "Seller Admin" : "Seller Standard";
    }
    return userType;
  };

  const submitForm = async (inputs: FormInputs) => {
    try {
      setSubmitting(true);

      let requestBody: IAgilisUserPatchArgs = {
        firstname: inputs.firstname,
        lastname: inputs.lastname,
        crm_id: inputs.crm_id,
        phone_number: `${inputs.country_code.value}${inputs.phone_number}`,
        preferred_language: inputs.preferred_language.value,
        user_type: getUserType(
          is_user_seller(user.user_type) ? inputs.role?.label : undefined,
          inputs.user_type_chip?.id
        ),
        is_active: inputs.is_active,
        ...(is_user_seller(user.user_type) && Boolean(inputs.role?.value)
          ? { role_id: inputs.role.value }
          : {}),
        quote_threshold_usd: inputs.quote_threshold_usd || null,
        order_threshold_usd: inputs.order_threshold_usd || null,
        quote_threshold_local: inputs.quote_threshold_local || null,
        order_threshold_local: inputs.quote_threshold_local || null,
        email_notifications:
          user.role && is_user_seller(user.user_type)
            ? {
                storefront_id: storefront_id,
                // leads
                leads: inputs.leads_notification,
                leads_enable_quote_request:
                  userNotifications.leads_enable_quote_request,
                leads_enable_sample_request:
                  userNotifications.leads_enable_sample_request,
                leads_enable_registration:
                  userNotifications.leads_enable_registration,
                leads_enable_contact_us:
                  userNotifications.leads_enable_contact_us,
              }
            : undefined,
      };
      // Not completely certain what will happen if we send these for non seller
      // standard users.
      if (
        inputs?.user_type_chip?.id === "Seller Standard" &&
        inputs.role?.label !== "Admin"
      ) {
        requestBody = {
          ...requestBody,
          quote_threshold_usd: inputs.quote_threshold_usd || null,
          order_threshold_usd: inputs.order_threshold_usd || null,
          quote_threshold_local: inputs.quote_threshold_local || null,
          order_threshold_local: inputs.quote_threshold_local || null,
        };
      }
      const { data: updated_user } = await Axios.patch<User>(
        endpoints.v1_storefronts_id_tenants_id_users_id(
          storefront_id,
          user.tenant_id,
          user.id
        ),
        requestBody
      );
      if (current_user && updated_user.id === current_user.id) {
        update_user_context({
          ...current_user,
          firstname: updated_user.firstname,
          lastname: updated_user.lastname,
          email_address: updated_user.email_address,
          phone_number: updated_user.phone_number,
          preferred_language: updated_user.preferred_language,
          user_type: updated_user.user_type,
          is_active: updated_user.is_active,
          role: updated_user.role,
          crm_id: updated_user.crm_id,
          quote_threshold_usd: updated_user.quote_threshold_usd,
          order_threshold_usd: updated_user.order_threshold_usd,
          quote_threshold_local: updated_user.quote_threshold_local,
          order_threshold_local: updated_user.order_threshold_local,
          email_notifications: updated_user.email_notifications,
        });
      }
      setSubmitting(false);
      notifySuccess(t("The user has been edited successfully"));
      onSuccess();
    } catch (error) {
      setSubmitting(false);
      notifyError(strings(t).submitError, { error });
    }
  };

  useEffect(() => {
    setUserNotifications((prev) => ({
      storefront_id: storefront_id,
      leads: leads_notification || true,
      leads_enable_quote_request:
        leads_enable_quote_request ?? prev.leads_enable_quote_request,
      leads_enable_sample_request:
        leads_enable_sample_request ?? prev.leads_enable_sample_request,
      leads_enable_registration:
        leads_enable_registration ?? prev.leads_enable_registration,
      leads_enable_contact_us:
        leads_enable_contact_us ?? prev.leads_enable_contact_us,
    }));
  }, [
    leads_enable_quote_request,
    leads_enable_sample_request,
    leads_enable_registration,
    leads_enable_contact_us,
    leads_notification,
    storefront_id,
  ]);

  return (
    <>
      <HeaderLeft>
        <PageTitle>{t("Edit user")}</PageTitle>
      </HeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="firstname"
          label={t("First Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="lastname"
          label={t("Last Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="email_address"
          label={t("Email")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="email"
          disabled={true}
        />
        <Flex>
          <Flex1>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="country_code"
              autoComplete="countryCode"
              placeholder={t("Country Code")}
              id="countryCodeSelectBox"
              options={getPhoneCodesOptions()}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
          </Flex1>
          <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
            <TextField
              name="phone_number"
              label={t("Phone Number")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="tel"
            />
          </Flex2>
        </Flex>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="preferred_language"
          placeholder={t("Preferred Language")}
          options={supportedLanguageOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        />
        <ToggleSwitch
          label={watchIsActive ? t("Active") : t("Inactive")}
          name="is_active"
          theref={register({ required: true })}
          isChecked={user.is_active}
        />

        {/* If only one user type chip is passed in, don't show the chips UI.
            For example, in the starter edition only admin accounts can be
            created and edited.

            The role supersedes the user type for sellers.
            */}
        {userTypeChips.length > 1 && !user.role && (
          <Chips
            name="user_type_chip"
            errors={errors}
            header={t("Account type")}
            chips={userTypeChips}
            handleClick={onUserTypeToggle}
            selectionMode={"single"}
            selectedChip={selectedChip?.name}
          />
        )}
        {user.role && is_user_seller(user.user_type) && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="role"
            placeholder={t("Role")}
            defaultValue={{ label: user.role.name, value: user.role.id }}
            options={roleOptions}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
          />
        )}
        {!roleIsSomeKindOfBuyer && (
          <TextField
            name="crm_id"
            label={t("CRM ID")}
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            type="text"
            readOnly={!roleIsSellerAdmin}
          />
        )}
        {displaySellerLimits && (
          <>
            <SectionTitle style={{ marginTop: "30px" }}>
              {t("Quote Limit")}
            </SectionTitle>
            <FormGrid2x2>
              <CheckBoxAndTextField>
                <CheckBoxContainer>
                  <div style={{ minWidth: "12px" }}>
                    <CheckBoxNoLabel
                      name="quote_threshold_usd_unlimited"
                      id="quote_threshold_usd_unlimited"
                      defaultChecked
                      ref={register({ required: false })}
                      style={{ marginRight: "5px" }}
                    />
                  </div>
                  <CheckBoxFinePrintLabel htmlFor="quote_threshold_usd_unlimited">
                    {t("No Limit")}
                  </CheckBoxFinePrintLabel>
                </CheckBoxContainer>

                <TextField
                  name="quote_threshold_usd"
                  label={t("USD Limit")}
                  theref={register()}
                  disabled={!!quote_threshold_usd_unlimited}
                  formState={formState}
                  errors={errors}
                  type="number"
                />
              </CheckBoxAndTextField>
              {showLocalCurrencyLimits && (
                <CheckBoxAndTextField>
                  <CheckBoxContainer>
                    <div style={{ minWidth: "12px" }}>
                      <CheckBoxNoLabel
                        name="quote_threshold_default_unlimited"
                        id="quote_threshold_default_unlimited"
                        defaultChecked
                        ref={register({ required: true })}
                        style={{ marginRight: "5px" }}
                      />
                    </div>
                    <CheckBoxFinePrintLabel htmlFor="quote_threshold_default_unlimited">
                      {t("No Limit")}
                    </CheckBoxFinePrintLabel>
                  </CheckBoxContainer>
                  <TextField
                    name="quote_threshold_local"
                    label={t(`{{default_currency}} Limit`, {
                      default_currency,
                    })}
                    theref={register()}
                    disabled={!!quote_threshold_default_unlimited}
                    formState={formState}
                    errors={errors}
                    type="number"
                  />
                </CheckBoxAndTextField>
              )}
            </FormGrid2x2>
            <SectionTitle style={{ marginTop: "30px" }}>
              {t("Order Limit")}
            </SectionTitle>
            <FormGrid2x2>
              <CheckBoxAndTextField>
                <CheckBoxContainer>
                  <div style={{ minWidth: "12px" }}>
                    <CheckBoxNoLabel
                      name="order_threshold_usd_unlimited"
                      id="order_threshold_usd_unlimited"
                      defaultChecked
                      ref={register({ required: true })}
                      style={{ marginRight: "5px" }}
                    />
                  </div>
                  <CheckBoxFinePrintLabel htmlFor="order_threshold_usd_unlimited">
                    {t("No Limit")}
                  </CheckBoxFinePrintLabel>
                </CheckBoxContainer>
                <TextField
                  name="order_threshold_usd"
                  label={t("USD Limit")}
                  theref={register()}
                  disabled={!!order_threshold_usd_unlimited}
                  formState={formState}
                  errors={errors}
                  type="number"
                />
              </CheckBoxAndTextField>
              {showLocalCurrencyLimits && (
                <CheckBoxAndTextField>
                  <CheckBoxContainer>
                    <div style={{ minWidth: "12px" }}>
                      <CheckBoxNoLabel
                        name="order_threshold_default_unlimited"
                        id="order_threshold_default_unlimited"
                        defaultChecked
                        ref={register({ required: true })}
                        style={{ marginRight: "5px" }}
                      />
                    </div>
                    <CheckBoxFinePrintLabel htmlFor="order_threshold_default_unlimited">
                      {t("No Limit")}
                    </CheckBoxFinePrintLabel>
                  </CheckBoxContainer>
                  <TextField
                    name="order_threshold_local"
                    label={t(`{{default_currency}} Limit`, {
                      default_currency,
                    })}
                    theref={register({
                      required: true,
                      min: 0,
                    })}
                    disabled={!!order_threshold_default_unlimited}
                    formState={formState}
                    errors={errors}
                    type="number"
                  />
                </CheckBoxAndTextField>
              )}
            </FormGrid2x2>
          </>
        )}
        {edition !== "pim" && !isCustomerUserType(user) && (
          <>
            <SectionTitle style={{ marginTop: "30px" }}>
              {t("Notification Preferences")}
            </SectionTitle>

            <ToggleSwitchWrapper style={{ margin: "20px 0 15px" }}>
              <ToggleSwitch
                label={t("Leads")}
                name={"leads_notification"}
                theref={register({ required: true })}
                isChecked={
                  user.email_notifications?.find(
                    (notifications) =>
                      notifications.storefront_id === storefront_id
                  )
                    ? user.email_notifications?.find(
                        (notifications) =>
                          notifications.storefront_id === storefront_id
                      )?.leads
                    : true
                }
              />
            </ToggleSwitchWrapper>

            {leads_notification && (
              <Card style={{ background: "#fff", padding: "5px 15px" }}>
                <ToggleSwitchWrapper>
                  <ToggleSwitch
                    label={t("Quote Request")}
                    name={"leads_enable_quote_request"}
                    theref={register({ required: true })}
                    isChecked={userNotifications.leads_enable_quote_request}
                  />
                </ToggleSwitchWrapper>
                <ToggleSwitchWrapper>
                  <ToggleSwitch
                    label={t("Sample Request")}
                    name={"leads_enable_sample_request"}
                    theref={register({ required: true })}
                    isChecked={userNotifications.leads_enable_sample_request}
                  />
                </ToggleSwitchWrapper>
                <ToggleSwitchWrapper>
                  <ToggleSwitch
                    label={t("Register")}
                    name={"leads_enable_registration"}
                    theref={register({ required: true })}
                    isChecked={userNotifications.leads_enable_registration}
                  />
                </ToggleSwitchWrapper>
                <ToggleSwitchWrapper>
                  <ToggleSwitch
                    label={t("Contact Us")}
                    name={"leads_enable_contact_us"}
                    theref={register({ required: true })}
                    isChecked={userNotifications.leads_enable_contact_us}
                  />
                </ToggleSwitchWrapper>
              </Card>
            )}
          </>
        )}
        <PrimaryButtonFitContainer
          type="submit"
          loading={submitting}
          style={{ marginBottom: "10px" }}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>

      <Modal
        overlay
        show={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        modalWidth={"680px"}
      >
        <ModalWrapper>
          <WarningTitle>
            {user.is_point_of_contact &&
              !user.is_primary_contact &&
              t(`This user is associated as Point of Contact`)}
            {!user.is_point_of_contact &&
              user.is_primary_contact &&
              t(`This user is associated as Primary Contact`)}

            {user.is_primary_contact &&
              user.is_point_of_contact &&
              t(
                `This user is associated as Primary Contact and Point of Contact`
              )}
          </WarningTitle>
          <div>{t("Are you sure you want to edit the user details?")}</div>
          <PrimaryButtonMedium
            onClick={() => (formInputs ? submitForm(formInputs) : null)}
            style={{ marginTop: "30px" }}
          >
            {t("Proceed")}
          </PrimaryButtonMedium>
          <br />
          <CancelButton
            onClick={() => setShowConfirmationModal(false)}
            style={{ padding: "20px 30px" }}
          >
            {t("Cancel")}
          </CancelButton>
        </ModalWrapper>
      </Modal>
    </>
  );
}
