import * as React from "react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Split from "react-split";
import useSWR, { useSWRInfinite } from "swr";
import { StringParam, useQueryParams } from "use-query-params";
import { ErrorPlaceholder } from "../../../components/Error";
import { FiltersArea } from "../../../components/Filters/Filters";
import { Loader } from "../../../components/Loader/Loader";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { SoftHeader2 } from "../../../components/Typography/Typography";
import { endpoints } from "../../../endpoints";
import {
  ContentWrapper,
  PageWrapper,
  PortfolioCaption,
  PortfolioContentLayout,
  PortfolioHeader,
  PortfolioSearch,
  PortfolioTitle,
  ProductContentWrapper,
  ProductListItemWrapper,
  ProductListMainWrapper,
  SelectedProductListItemWrapper,
} from "../../../layout/publicPageLayout";
import type {
  OptionType,
  PortfolioViewType,
  TagClassificationConfig,
} from "../../../types/types";
import type {
  PIMProduct,
  PIMProductPaginatedOutput,
} from "../../../types/types.PIM";
import { useDebounce } from "../../../util/hooks";
import {
  ConditionalWrapper,
  createStorefrontMetadataLocalization,
  updateCustomLabels,
  useMediaQueries,
  useStoreState,
  useSupportedLanguages,
} from "../../../util/util";
import { PIMProductDetailsPortfolio } from "../ProductDetailsPortfolio/PIMProductDetailsPortfolio";
import {
  CasNumber,
  CustomH3,
  CustomProductName,
  LoaderContainer,
  PAGE_SIZE,
  PRODUCT_SEARCH_POPUP,
  TotalProducts,
  construct_product_infinite_scroll_query,
  getBrandName,
  getProductName,
  usePortfolioUpdateHeight,
} from "../pages.public.util";
import { useForm } from "react-hook-form";
import { PortfoliolayoutOptions, groupPortfolioParamsByKey } from "..";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import styled from "styled-components";
import { PortfolioIcon } from "../../../components/Icons/Icons";
import { screenSize } from "../../../theme";
import { RequestUnlistedProduct } from "../../Buyer/RequestUnlistedProduct/RequestUnlistedProduct";
import { useAuthContext } from "../../../components/Auth";
import { ProductSearchPopup } from "../ProductDetailsPortfolio/ProductSearchPopup";
import axios from "axios";

type PaginationType = {
  offset: number;
  order_by: "desc" | "asc";
  total: number;
  limit: number;
};

type ProductsAPIResponse = {
  data: PIMProduct[];
  pagination: PaginationType;
};

const ClassicPageWrapper = styled(PageWrapper)`
  padding: 0 50px;
  @media ${screenSize.medium} {
    padding: 0 15px;
  }
`;
export const SelectPortfolioView = styled.div`
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  .select__value-container {
    margin-top: 0;
    width: 85px;

    position: relative;
  }
  .select__control,
  .select__control--is_focused {
    min-height: 25px;
    height: 35px;
    .select__indicator {
      padding: 3px;
    }
  }

  .select__value-container {
    padding: 0px 8px 0 30px;
  }
`;
export const ViewIconWrapper = styled.div`
  position: absolute;
  left: 5px;
  top: 7px;
  z-index: 1;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;

function replaceProductSlug({
  path,
  search,
  hash,
  slug,
}: {
  path: string;
  search: string;
  hash: string;
  slug: string;
}): string {
  const pathParts = path.split("/");

  if (pathParts[3] !== "product") {
    // page has been loaded with just the tenant slug and no product is
    // specified (usually with `/portfolio` instead of `/product` in URL).

    const newPathParts = pathParts.map((part, index) =>
      index === 3 ? `product/${slug}` : part
    );

    return newPathParts.join("/") + search + hash;
  } else {
    // initial page load has been completed or there was already a
    // product in the URL.
    const baseUrl = [...pathParts.slice(0, 4), slug].join("/");
    return baseUrl + search + hash;
  }
}

function resetPageURL({
  path,
  search,
  hash,
}: {
  path: string;
  search: string;
  hash: string;
}): string {
  const pathParts = path.split("/");
  const baseUrl = [...pathParts.slice(0, 3), "portfolio"].join("/");
  return baseUrl + search + hash;
}

/**
 * Requirements:
  1. individual products should be linkable ✓
  2. filters should be linkable, and should show up as applied on the UI ✓
  3. search queries should be linkable and show up prefilled in the search bar. ✓
  4. when going to the portfolio page from anywhere else the back button should work with one click, even though the URL changes to include the product. ✓
  5. Clicking back button when going through multiple products should update the selected product on the UI. ✓
  6. if a product is not selected in the URL, then the first product should be selected. ✓
  7. when applying filters or a search the first product of the result set should be selected. ✓
  8. based on #6 and #7 it should be impossible to link to a product that is not in the side scrolling section based on the currently applied filters/search ✓
  9. clearing filters or search should reset first product ✓
  10. clear filter for individual filters should not affect the general clear filter ✓
 */
export const PortfolioClassicView = ({
  setPortfolioLayout,
}: {
  products?: PIMProductPaginatedOutput;
  setPortfolioLayout: React.Dispatch<React.SetStateAction<PortfolioViewType>>;
}) => {
  const [product, setProduct] = useState<PIMProduct>();
  const [resetFirstProduct, setResetFirstProduct] = useState(false);
  const { roleIsBuyerAdmin, roleIsSomeKindOfSeller } = useAuthContext();
  const [show_enterprise_search, set_show_enterprise_search] = useState(false);

  let { productSlug } =
    useParams<{
      productSlug?: string;
    }>();

  const search_ref = React.useRef<HTMLDivElement>(null);

  const {
    slug: storefrontSlug,
    storefront_metadata,
    storefront_id,
  } = useStoreState();
  const [cookies] = useCookies([`preferred-language-${storefrontSlug}`]);
  const { errors, formState } = useForm();
  const preferred: string | undefined =
    cookies[`preferred-language-${storefrontSlug}`];
  const { header, sub_header, placeholder } =
    createStorefrontMetadataLocalization({
      storefront_metadata,
      cookie: preferred,
    });
  const { supportedLanguageOptions } = useSupportedLanguages();

  const {
    data: productFromURL,
    error: productFromURLError,
    mutate: mutateProduct,
  } = useSWR<PIMProduct>(
    productSlug
      ? endpoints.v2_storefronts_id_pim_products_id(storefront_id, productSlug)
      : null,
    {
      onSuccess: (product) => {
        setProduct(product);
        setResetFirstProduct(false);
      },
      revalidateOnFocus: false,
    }
  );

  const { data: customLabels, error: customLabelsError } = useSWR<
    TagClassificationConfig[]
  >(
    storefront_id
      ? endpoints.v2_storefronts_id_products_filters_customLabels(storefront_id)
      : null
  );

  updateCustomLabels(customLabels, supportedLanguageOptions);

  const [query, setQuery] = useQueryParams({
    q: StringParam,
  });

  const location = useLocation();
  const history = useHistory();

  const { search } = useLocation();

  useEffect(() => {
    setResetFirstProduct(true);
  }, [search]);

  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q ?? "");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebounce(
    searchQuery,
    1000
  );
  const { t } = useTranslation();

  const isQuoteSlideout = (search: string) => search.includes("quote=1");

  let filtersFromUrl: { [key: string]: string[] } = {};
  (() => {
    const params = new URLSearchParams(search.substring(1));
    filtersFromUrl = groupPortfolioParamsByKey(params);
    delete filtersFromUrl.q;
    delete filtersFromUrl.active_tag_view;
    delete filtersFromUrl.language;
    delete filtersFromUrl.quote;
  })();

  const {
    data: productsResponse,
    error: productsError,
    setSize,
    size,
  } = useSWRInfinite<PIMProductPaginatedOutput>(
    (index, previousPageData) =>
      storefront_id
        ? construct_product_infinite_scroll_query({
            baseUrl: endpoints.v2_storefronts_id_pim_products(storefront_id),
            query: debouncedSearchQuery,
            filtersFromUrl,
            previousPageData,
            index,
          })
        : null,
    // We don't revalidate here because when we did revalidate that was
    // causing the quote request item form to unmount, re-mount, and
    // lose any changes the user had made to the form (entering or changing
    // inputs).  See: https://app.asana.com/0/1200568265088077/1201211536022320/f
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (result) => {
        if (result.length === 1) {
          if (
            (location.search.length > 0 &&
              result?.[0].data?.length > 0 &&
              !isQuoteSlideout(location.search) &&
              !productFromURL) ||
            (resetFirstProduct &&
              result?.[0].data?.length > 0 &&
              !isQuoteSlideout(location.search))
          ) {
            setProduct(result?.[0].data?.[0]);
            const destination = replaceProductSlug({
              path: location.pathname,
              search: location.search,
              hash: location.hash,
              slug: result?.[0].data?.[0].slug,
            });
            setResetFirstProduct(false);
            history.replace(destination);
          }
          if (result?.[0].data?.length === 0) {
            const destination = resetPageURL({
              path: location.pathname,
              search: location.search,
              hash: location.hash,
            });
            history.replace(destination);
          }
        }
      },
    }
  );

  const products: ProductsAPIResponse[] = productsResponse
    ? [...productsResponse]
    : [];

  const hasProducts = !productsError && products;
  const isLoadingInitialData = !productsResponse && !productsError;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 &&
      productsResponse &&
      typeof productsResponse[size - 1] === "undefined");

  const { ref, inView } = useInView({ triggerOnce: true });

  const { productListHeight, setTriggerListHeightUpdate, heightRef } =
    usePortfolioUpdateHeight(product);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val === "") {
      handleClearSearch();
    } else {
      setSearchQuery(val);
      localStorage.removeItem(PRODUCT_SEARCH_POPUP);
    }
    setSize(1);
    setResetFirstProduct(true);
  };

  const handleViewChange = (option: OptionType<PortfolioViewType>) => {
    setPortfolioLayout(option.value);
    sessionStorage.setItem("portfolioView", option.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setDebouncedSearchQuery("");
    setQuery({ q: undefined });
    setResetFirstProduct(true);
  };

  const handleClearFilter = () => {
    if (product && products[0].data.length > 0) {
      setResetFirstProduct(true);
      const destination = replaceProductSlug({
        path: location.pathname,
        search: "",
        hash: location.hash,
        slug: product.slug,
      });
      history.replace(destination);
      setQuery({ q: query.q });
    }
  };

  /**
   * Store the new product in React state and update the URL with the new slug.
   */
  const handleProductClick = (clickedProduct: PIMProduct) => {
    if (product?.id !== clickedProduct.id) {
      const replace_tab_query = location.search.replace(/tab=[0-9]{1,2}/, "");
      setProduct(clickedProduct);
      const destination = replaceProductSlug({
        path: location.pathname,
        search: replace_tab_query,
        hash: location.hash,
        slug: clickedProduct.slug,
      });
      history.push(destination);
    }
  };

  const close_search_popup = () => {
    set_show_enterprise_search(false);
    localStorage.setItem(PRODUCT_SEARCH_POPUP, "closed");
  };

  const handle_popup_product_click = (product_id: string) => {
    const on_search = async () => {
      try {
        const { data: clicked_product } = await axios.get<PIMProduct>(
          endpoints.v2_storefronts_id_pim_products_id(storefront_id, product_id)
        );
        handleProductClick(clicked_product);
      } catch (error) {
        console.error(
          "***  Could not fetch product from search popup *** ",
          error
        );
      }
    };
    on_search();
  };

  useEffect(() => {
    // This useEffect handles "no product slug in the URL", as with
    // `/portfolio` and `/product` URLs. In this case we default to the first
    // product in the list. Set the product and update the URL so it contains
    // the product slug.
    // (Note that this will also run if there's an error fetching the product
    // using the slug in the URL.)
    if (
      !product &&
      productsResponse?.[0].data &&
      productsResponse?.[0].data?.length > 0
    ) {
      const firstProduct = productsResponse?.[0].data?.[0];

      if (firstProduct && !productsError && !productSlug) {
        setProduct(firstProduct);
        const destination = replaceProductSlug({
          path: location.pathname,
          search: location.search,
          hash: location.hash,
          slug: firstProduct.slug,
        });
        history.replace(destination);
      }
    }
  }, [
    product,
    productsResponse,
    productsError,
    productSlug,
    history,
    location.pathname,
    location.search,
    location.hash,
  ]);

  useEffect(() => {
    setQuery({ q: debouncedSearchQuery ? debouncedSearchQuery : undefined });
    const should_search_popup_be_closed = Boolean(
      localStorage.getItem(PRODUCT_SEARCH_POPUP)
    );
    set_show_enterprise_search(
      !should_search_popup_be_closed &&
        !!debouncedSearchQuery &&
        storefront_metadata.enable_vertex_ai_search
    );
    if (!debouncedSearchQuery) {
      localStorage.removeItem(PRODUCT_SEARCH_POPUP);
    }
  }, [
    debouncedSearchQuery,
    setQuery,
    storefront_metadata.enable_vertex_ai_search,
  ]);

  useEffect(() => {
    return history.listen((curLocation) => {
      if (history.action === "POP") {
        const searchTxt = new URLSearchParams(curLocation.search).get("q");
        setSearchQuery(searchTxt ?? "");
        setDebouncedSearchQuery(searchTxt ?? "");
      }
    });
    // This is where I go against the system.
    // The eslint disable line below is necessary because, I want this effect to trigger
    // ONLY when history changes.
    // the other dependency it's crying about, is the setDebouncedSearchQuery
    // which has no BAD effect when removed here.
    //eslint-disable-next-line
  }, [history]);

  useEffect(() => {
    // If the observer is the viewport update size of documents to fetched
    if (inView) {
      setSize((size) => size + 1);
    }
  }, [inView, setSize]);

  const { isSmallScreen, isLargeScreen } = useMediaQueries();

  const search_bar_left_position = search_ref.current?.getBoundingClientRect()
    .left
    ? Math.ceil(search_ref.current?.getBoundingClientRect().left)
    : 0;
  const search_bar_top_position = search_ref.current?.getBoundingClientRect()
    .top
    ? Math.ceil(search_ref.current?.getBoundingClientRect().top)
    : 0;

  return (
    <ClassicPageWrapper>
      <PortfolioHeader style={{ paddingLeft: 0 }}>
        {!isSmallScreen && (
          <>
            {/* Header and sub_header are not translated because these are user managed strings(t). */}
            {/* Because of Hierachy of Designs, it is OK that the portfolio title is bigger than other titles in other pages */}
            {/* To be more specific, the size of the product names on this page, already equals the font size of titles in other pages */}
            {/* Hence, Portfolio title should be bigger, to maintain that typography flow; from top to bottom.*/}
            <PortfolioTitle>{header}</PortfolioTitle>
            <PortfolioCaption>{sub_header}</PortfolioCaption>
          </>
        )}
        <PortfolioSearch>
          <SearchContainer>
            {" "}
            <SearchBar
              query={searchQuery}
              // placeholder string is managed by users via storefront_metadata
              placeHolder={placeholder}
              handleChange={handleSearch}
              handleClearInput={handleClearSearch}
              dataTestId="portfolio-products-search-bar"
              ref={search_ref}
            />
            {storefront_metadata.unlisted_product_requests_enabled &&
              searchQuery.length > 0 &&
              roleIsBuyerAdmin && <RequestUnlistedProduct />}
          </SearchContainer>
          <SelectPortfolioView>
            <ViewIconWrapper>
              <PortfolioIcon width={22} height={22} />
            </ViewIconWrapper>
            <SelectBoxV2
              name="view_type"
              placeholder=""
              isCreatable={false}
              isSearchable={false}
              defaultValue={{ value: "classic", label: t("Classic") }}
              onChange={handleViewChange}
              errors={errors}
              normalPlaceholder={false}
              formState={formState}
              options={PortfoliolayoutOptions}
            />
          </SelectPortfolioView>
        </PortfolioSearch>
      </PortfolioHeader>
      {!customLabelsError && (
        <FiltersArea
          customLabels={customLabels ?? []}
          clearFilter={handleClearFilter}
        />
      )}
      <ContentWrapper>
        <TotalProducts data-testid="portfolio-total-products">
          {!isLoadingInitialData &&
            products.length &&
            `${products[0].pagination.total} ${t("products")}`}
        </TotalProducts>
        <PortfolioContentLayout>
          <Split
            sizes={[25, 75]}
            minSize={220}
            expandToMin={false}
            gutterSize={7}
            gutterAlign="center"
            snapOffset={30}
            dragInterval={1}
            direction={"horizontal"}
            cursor="col-resize"
            style={{
              display: "flex",
              flexDirection: isLargeScreen ? "column" : "row",
              width: "100%",
            }}
          >
            <ProductListMainWrapper
              data-testid="product-list-container"
              style={{
                height: productListHeight,
              }}
            >
              {isLoadingInitialData && (
                <LoaderContainer>
                  <Loader
                    isLoading={isLoadingInitialData}
                    omitContainer={true}
                  />
                </LoaderContainer>
              )}
              {hasProducts &&
                products.map((nested: ProductsAPIResponse) => {
                  return nested.data.map(
                    (listProduct: PIMProduct, index: number) => {
                      return (
                        <React.Fragment key={listProduct.id}>
                          {/* Conditionally wrap the items in selected/unselected styled components */}
                          <ConditionalWrapper
                            condition={listProduct.id === product?.id}
                            wrapper={(children) => (
                              <SelectedProductListItemWrapper as="div">
                                {children}
                              </SelectedProductListItemWrapper>
                            )}
                            falseWrapper={(children) => (
                              <ProductListItemWrapper
                                onClick={() => handleProductClick(listProduct)}
                              >
                                {children}
                              </ProductListItemWrapper>
                            )}
                          >
                            <CustomH3
                              title={getProductName(listProduct) ?? undefined}
                            >
                              {getProductName(listProduct)}
                            </CustomH3>
                            {!!getBrandName(listProduct) && (
                              <SoftHeader2>
                                <CustomProductName
                                  title={getBrandName(listProduct) ?? undefined}
                                >
                                  {/* TODO check to see if this looks ok without the brand_name, it will likely de deprecated in the future */}
                                  {getBrandName(listProduct)}
                                </CustomProductName>
                              </SoftHeader2>
                            )}
                            {!!listProduct.identifiers.cas_number && (
                              <SoftHeader2>
                                <CasNumber>
                                  {`CAS # ${listProduct.identifiers.cas_number}`}
                                </CasNumber>
                              </SoftHeader2>
                            )}
                          </ConditionalWrapper>
                          {/* If we get to the end and the request hasn't finished yet display spinner */}
                          {!!isLoadingMore && index === nested.data.length - 1 && (
                            <LoaderContainer>
                              <Loader
                                isLoading={!!isLoadingMore}
                                omitContainer={true}
                              />
                            </LoaderContainer>
                          )}
                          {/* Display intersection observer ref when we get towards the end.
                          We fetch products in multiples of PAGE_SIZE so if the length of this array
                          is < PAGE_SIZE we don't need to show the observer ref.
                          This is set to PAGE_SIZE - 4 so that unless the user is scrolling quickly more 
                          products will load before hitting bottom
                        */}
                          {index === PAGE_SIZE - 4 && (
                            <div key={listProduct.id + "observer"} ref={ref} />
                          )}
                        </React.Fragment>
                      );
                    }
                  );
                })}
            </ProductListMainWrapper>
            <ProductContentWrapper ref={heightRef}>
              {products && products[0]?.data?.length === 0 && !productSlug && (
                <ErrorPlaceholder message={t("No products found")} />
              )}
              {!products && productsError && (
                <ErrorPlaceholder
                  message={t(
                    "There was an error fetching products for this storefront"
                  )}
                />
              )}
              {products && productSlug && productFromURLError && !product && (
                <ErrorPlaceholder message={t("Product not found")} />
              )}
              {product && (
                <PIMProductDetailsPortfolio
                  product={product}
                  mutateProduct={mutateProduct}
                  error={productFromURLError}
                  updateListHeight={setTriggerListHeightUpdate}
                />
              )}
            </ProductContentWrapper>
          </Split>
        </PortfolioContentLayout>
        {show_enterprise_search && roleIsSomeKindOfSeller && (
          <ProductSearchPopup
            search_bar_left_position={search_bar_left_position}
            search_bar_top_position={search_bar_top_position}
            search_query={searchQuery}
            debounced_search_query={debouncedSearchQuery}
            placeholder={placeholder}
            handle_parent_search={handleSearch}
            handle_parent_clear_search={handleClearSearch}
            custom_labels={customLabels}
            close_search_popup={close_search_popup}
            clear_filter={handleClearFilter}
            handle_product_click={handle_popup_product_click}
          />
        )}
      </ContentWrapper>
    </ClassicPageWrapper>
  );
};
