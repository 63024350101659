import { useContext, useEffect, useState } from "react";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  SubmitButtonContainer,
} from "../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { TextField } from "../TextFields/TextFields";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { HeaderLeft } from "../Layout/Layout";
import { PageTitle } from "../../layout/portalPageLayout";
import { SectionTitle } from "../Form/Form";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import Axios from "axios";
import type {
  ITenantCustomerSettingsCreation,
  OptionType,
} from "../../types/types";
import { Notifications } from "../Notifications/NotificationsContext";
import {
  convertCurrencyToOption,
  useStoreState,
  useFormWrapper,
  useSupportedLanguages,
  selectBoxDefaultValue,
} from "../../util/util";
import { endpoints } from "../../endpoints";
import {
  getCountryCode,
  getPhoneCodeOption,
  getPhoneCodesOptions,
  getPhoneNumber,
} from "../../util/phone";
import { useStatesList } from "../../util/Locations";
import {
  getAddressOneInputLabel,
  getAddressTwoInputLabel,
  getCountryOption,
  getStateOption,
  getStatesInputLabel,
  getZipCodeInputLabel,
} from "../../util/location";
import { Auth } from "../Auth";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateNewTenantSchema,
  CreatePIMEditionNewTenantSchema,
  getCorrectCountry,
  getCorrectPhoneNumber,
  getCorrectState,
} from "./CreateNewTenantForm.util";
import type {
  CreateNewTenantProps,
  CreateTenantFormValue,
  CreateTenantPOST,
  PimEditionCreateTenantPOST,
  PimEditionFormValue,
  patchTenantSettings,
} from "./CreateNewTenantForm.util";

type AccountType = "Buyer" | "Seller" | "Distributor";

const localStrings = (accountType: AccountType, t: TFunction) => {
  const updatedAccountType =
    accountType === "Buyer" ? "Customer" : (accountType as string);
  return {
    notifySettingSuccess: t("{{accountType}} settings have been saved.", {
      accountType: updatedAccountType,
    }),
    notifySettingError: t(
      "There was an error submitting the {{accountType}} settings",
      { accountType: updatedAccountType.toLowerCase() }
    ),
    notifyCreationSuccess: t("{{accountType}} successfully created", {
      accountType: updatedAccountType,
    }),
    notifyCreationError: t(
      "There was an error creating the {{accountType}}, please try again.",
      { accountType: updatedAccountType.toLowerCase() }
    ),
    pageTitle: t("Create {{accountType}}", { accountType: updatedAccountType }),
    externalId: t("{{accountType}} ID", { accountType: updatedAccountType }),
    accountRelation: t("Customer Relation"),
    successManager: t("Customer Success Manager"),
    createAccount: t("Create {{accountType}}", {
      accountType: updatedAccountType,
    }),
  };
};

/**
 * Form to create a new customer tenant.
 * Bug fix: 18th Jun: Add Default terms to mandatory fields
 * The `sellerTenantId` is the ID of the tenant that will be selling products
 * to the new tenant that is being created.  The seller tenant could be either
 * a producer, and typically it is the tenant of the user that
 * is currently logged in.
 */
export const CreateNewTenantForm = ({
  tenant: sellerTenant,
  lead,
  onSuccess,
  sampleRequestMode,
  countries,
  userOptions,
  deliveryTermsOptions,
  paymentTermsOptions,
  paymentModesOptions,
  originalStatesList,
  type = "Buyer",
}: CreateNewTenantProps) => {
  const { user } = useContext(Auth);
  const {
    api_metadata,
    storefront_id,
    storefront_metadata: {
      edition,
      default_currency,
      mandatory_customer_creation_form,
    },
  } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const { supportedLanguageOptions } = useSupportedLanguages();
  const currencies = api_metadata?.supported_currencies;
  const currencyOptions = currencies?.map(convertCurrencyToOption) ?? [];
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { dirtyFields },
    formState,
    control,
    errors,
    watch,
    setValue,
  } = useFormWrapper({
    resolver: zodResolver(
      edition === "pim"
        ? CreatePIMEditionNewTenantSchema(t, mandatory_customer_creation_form)
        : CreateNewTenantSchema(t, mandatory_customer_creation_form)
    ),
    defaultValues: {
      name: lead?.buyer_company_name,
      contact_first_name: lead?.buyer_first_name,
      contact_last_name: lead?.buyer_last_name,
      contact_email: lead?.buyer_email,
      preferred_language: { value: "en", label: "English" },
      country: getDefaultCountry(),
      address1: (() => {
        if (
          lead &&
          "billing_address" in lead &&
          lead.billing_address !== null
        ) {
          return lead.billing_address?.address1 ?? "";
        } else if (lead && "address" in lead && lead.address !== null) {
          return lead.address.address1;
        } else if (lead && "buyer_address" in lead) {
          return lead.buyer_address.address1;
        } else return "";
      })(),
      address2: (() => {
        if (
          lead &&
          "billing_address" in lead &&
          lead.billing_address !== null
        ) {
          return lead.billing_address?.address2 ?? "";
        } else if (lead && "address" in lead && lead.address !== null) {
          return lead.address.address2 ?? "";
        } else if (lead && "buyer_address" in lead) {
          return lead.buyer_address.address2 ?? "";
        } else return "";
      })(),
      state: (() => {
        const state = getCorrectState(lead);
        if (lead && state) {
          const stateOption = getStateOption(originalStatesList, state);
          return stateOption;
        } else return selectBoxDefaultValue;
      })(),
      city: (() => {
        if (
          lead &&
          "billing_address" in lead &&
          lead.billing_address !== null
        ) {
          return lead.billing_address?.city ?? "";
        } else if (lead && "address" in lead) {
          return lead.address?.city;
        } else if (lead && "buyer_address" in lead) {
          return lead.buyer_address.city;
        } else return "";
      })(),
      postal_code: (() => {
        // Only sample request have zip_codes.
        // The name of the form item isn't zip_code because the tenant creation
        // endpoint expects a `postal_code`

        if (
          lead &&
          "billing_address" in lead &&
          lead.billing_address !== null
        ) {
          return lead.billing_address?.postal_code ?? "";
        } else if (lead && "address" in lead && lead.address !== null) {
          return lead.address.postal_code;
        } else if (lead && "buyer_address" in lead) {
          return lead.buyer_address?.postal_code ?? "";
        } else return "";
      })(),
      county: (() => {
        if (
          lead &&
          "billing_address" in lead &&
          lead.billing_address !== null
        ) {
          return lead.billing_address?.county ?? "";
        } else if (lead && "address" in lead && lead.address !== null) {
          return lead.address.county;
        } else if (lead && "buyer_address" in lead) {
          return lead.buyer_address?.county ?? "";
        } else return "";
      })(),
      contact_phone:
        getPhoneNumber(
          // silly naming inconsistency here between sample requests and other leads.
          getCorrectPhoneNumber(lead)
        ) ?? "",
      country_code: getPhoneCodeOption(
        getCountryCode(getCorrectPhoneNumber(lead))
      ),
      account_manager: getPrimaryContactDetails() ?? selectBoxDefaultValue,
      customer_service_rep: getPrimaryContactDetails() ?? selectBoxDefaultValue,
      currency:
        currencyOptions.find(({ value }) => value === default_currency) ??
        selectBoxDefaultValue,
      payment_term: selectBoxDefaultValue,
      payment_mode: selectBoxDefaultValue,
      delivery_term: selectBoxDefaultValue,
    },
  });

  function getPrimaryContactDetails() {
    if (sellerTenant.primary_contact && sellerTenant.primary_contact_id) {
      const {
        primary_contact: { full_name },
        primary_contact_id,
      } = sellerTenant;
      return {
        label: full_name,
        value: primary_contact_id,
      };
    }
    return null;
  }

  function getDefaultCountry() {
    const country = getCorrectCountry(lead);
    if (country) {
      return getCountryOption(countries, country) ?? selectBoxDefaultValue;
    }
    return selectBoxDefaultValue;
  }

  const selectedCountry = watch("country")
    ? (watch("country") as OptionType).value
    : getDefaultCountry().value;
  const states = useStatesList(selectedCountry);

  const [storedCountry, setStoredCountry] = useState(
    getCorrectCountry(lead) ?? ""
  );

  useEffect(() => {
    // This form is different from others in the app, in thats its the only place
    // where the address is prefilled. Here we only want to clear the address
    // related fields if the user changes the country.
    if (selectedCountry && selectedCountry !== storedCountry) {
      setStoredCountry(selectedCountry);
      setValue("address1", undefined);
      setValue("address2", undefined);
      setValue("city", undefined);
      setValue("county", undefined);
      setValue("postal_code", undefined);
      setValue("state", null);
    }
  }, [selectedCountry, storedCountry, setValue, dirtyFields]);

  const patchCustomerSettings = async ({
    tenantSettings,
    tenantId,
  }: patchTenantSettings) => {
    if (user) {
      try {
        const requestBody: ITenantCustomerSettingsCreation = tenantSettings;
        await Axios.patch(
          endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
            storefront_id,
            user.tenant_id,
            tenantId
          ),
          requestBody
        );
        notifySuccess(localStrings(type, t).notifySettingSuccess);
        onSuccess(tenantId);
      } catch (error) {
        notifyError(localStrings(type, t).notifySettingError, {
          error,
        });
      }
    }
  };

  const onSubmit = async (values: CreateTenantFormValue) => {
    setLoading(true);
    const tenantSettings: ITenantCustomerSettingsCreation = {
      account_manager_id: values.account_manager.value,
      customer_service_rep_id: values.customer_service_rep.value,
      preferred_currency: values.currency?.value,
      default_payment_term_id: values.payment_term?.value,
      default_payment_mode_id: values.payment_mode?.value,
      default_delivery_term_id: values.delivery_term?.value,
    };
    const tenant: CreateTenantPOST = {
      type,
      name: values.name,
      contact_first_name: values.contact_first_name,
      contact_last_name: values.contact_last_name,
      contact_email: values.contact_email,
      doing_business_as: values.doing_business_as ?? null,
      external_id: values.external_id ?? null,
      contact_phone: `${values.country_code.value}${values.contact_phone}`,
      address1: values.address1 ?? null,
      address2: values.address2 ?? null,
      country: values.country?.value ?? null,
      county: values.county,
      state: values.state?.value ?? null,
      preferred_language: values.preferred_language?.value,
      city: values?.city ?? null,
      postal_code: values?.postal_code ?? null,
      settings: tenantSettings,
    };

    if (lead) {
      if (!sampleRequestMode) {
        tenant.lead_id = lead.id;
      } else {
        tenant.sample_request_id = lead.id;
      }
    }

    try {
      const response = await Axios.post(
        endpoints.v1_storefronts_id_tenants_id(storefront_id, sellerTenant.id),
        tenant
      );
      if (response.status === 201) {
        notifySuccess(localStrings(type, t).notifyCreationSuccess);
        const tenantId = response.data.id;
        patchCustomerSettings({ tenantSettings, tenantId });
      }
    } catch (error) {
      notifyError(localStrings(type, t).notifyCreationError, { error });
    } finally {
      setLoading(false);
    }
  };

  const onSubmitPimEdition = async (values: PimEditionFormValue) => {
    setLoading(true);

    const pimTenant: PimEditionCreateTenantPOST = {
      type,
      name: values.name,
      contact_first_name: values.contact_first_name,
      contact_last_name: values.contact_last_name,
      contact_email: values.contact_email,
      doing_business_as: values.doing_business_as ?? null,
      external_id: values.external_id ?? null,
      contact_phone: `${values.country_code.value}${values.contact_phone}`,
      preferred_language: values.preferred_language.value,
    };

    const tenantSettings: ITenantCustomerSettingsCreation = {
      account_manager_id: values?.account_manager?.value,
      customer_service_rep_id: values.customer_service_rep?.value,
    };

    if (lead) {
      if (!sampleRequestMode) {
        pimTenant.lead_id = lead.id;
      } else {
        pimTenant.sample_request_id = lead.id;
      }
    }
    try {
      const response = await Axios.post(
        endpoints.v2_storefronts_id_tenants_id(storefront_id, sellerTenant.id),
        pimTenant
      );
      if (response.status === 201) {
        notifySuccess(localStrings(type, t).notifyCreationSuccess);
        const tenantId = response.data.id;
        patchCustomerSettings({ tenantSettings, tenantId });
      }
    } catch (error) {
      notifyError(localStrings(type, t).notifyCreationError, { error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderLeft>
        <PageTitle>{localStrings(type, t).pageTitle}</PageTitle>
      </HeaderLeft>
      <Form
        noValidate
        onSubmit={
          edition === "pim"
            ? handleSubmit(onSubmitPimEdition)
            : handleSubmit(onSubmit)
        }
        style={{ paddingBottom: "30px" }}
      >
        <TextField
          name="name"
          label={t("Company Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="doing_business_as"
          label={t("Doing business as")}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="external_id"
          label={localStrings(type, t).externalId}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        {edition !== "pim" && (
          <>
            <SectionTitle>{t("HQ Address")}</SectionTitle>
            {countries.length > 0 && (
              <Controller
                as={SelectBoxV2}
                control={control}
                name="country"
                placeholder={t("Country")}
                options={countries}
                rules={{
                  required: false,
                }}
                errors={errors}
                formState={formState}
              />
            )}
            {selectedCountry && (
              <>
                <TextField
                  name="address1"
                  label={getAddressOneInputLabel(selectedCountry, t)}
                  theref={register({
                    required: false,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
                <TextField
                  name="address2"
                  label={getAddressTwoInputLabel(selectedCountry, t)}
                  theref={register({
                    required: false,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
                <Flex>
                  <Flex2
                    style={{
                      marginRight: selectedCountry === "IN" ? "14px" : "0",
                    }}
                  >
                    <Controller
                      as={SelectBoxV2}
                      control={control}
                      name="state"
                      placeholder={getStatesInputLabel(selectedCountry, t)}
                      id="portSelectBox"
                      options={states}
                      rules={{
                        required: false,
                      }}
                      errors={errors}
                      formState={formState}
                    />
                  </Flex2>
                  {selectedCountry === "IN" && (
                    <Flex1>
                      <TextField
                        name="county"
                        label={t("Taluka")}
                        theref={register({
                          required: false,
                        })}
                        formState={formState}
                        errors={errors}
                        type="text"
                      />
                    </Flex1>
                  )}
                </Flex>
                <Flex>
                  <Flex2>
                    <TextField
                      name="city"
                      label={t("City")}
                      theref={register({
                        required: false,
                      })}
                      formState={formState}
                      errors={errors}
                      type="text"
                    />
                  </Flex2>
                  <Flex1>
                    <TextField
                      name="postal_code"
                      label={getZipCodeInputLabel(selectedCountry, t)}
                      theref={register({
                        required: false,
                      })}
                      formState={formState}
                      errors={errors}
                      type="text"
                    />
                  </Flex1>
                </Flex>
              </>
            )}
          </>
        )}
        <SectionTitle>{t("Primary Contact")}</SectionTitle>
        <TextField
          name="contact_first_name"
          label={t("First Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="contact_last_name"
          label={t("Last Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="contact_email"
          label={t("Email")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="email"
        />
        <Flex>
          <Flex1>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="country_code"
              placeholder={t("Country Code")}
              id="countryCodeSelectBox"
              options={getPhoneCodesOptions()}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
          </Flex1>
          <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
            <TextField
              name="contact_phone"
              label={t("Phone Number")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="tel"
            />
          </Flex2>
        </Flex>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="preferred_language"
          placeholder={t("Preferred Language")}
          options={supportedLanguageOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        />
        {edition !== "pim" && (
          <>
            <SectionTitle>{t("Preferred Currency")}</SectionTitle>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="currency"
              placeholder={t("Currency")}
              options={currencyOptions}
              rules={{
                required: true,
              }}
              errors={errors}
              formState={formState}
            />
            <SectionTitle>{t("Default Terms")}</SectionTitle>
            <Controller
              as={SelectBoxV2}
              control={control}
              name="payment_term"
              placeholder={t("Payment Terms")}
              options={paymentTermsOptions}
              rules={{
                required: false,
              }}
              errors={errors}
              formState={formState}
            />
            <Controller
              as={SelectBoxV2}
              control={control}
              name="payment_mode"
              placeholder={t("Payment Method")}
              options={paymentModesOptions}
              rules={{
                required: false,
              }}
              errors={errors}
              formState={formState}
            />
            <Controller
              as={SelectBoxV2}
              control={control}
              name="delivery_term"
              placeholder={t("Shipping Terms")}
              options={deliveryTermsOptions}
              rules={{
                required: false,
              }}
              errors={errors}
              formState={formState}
            />
          </>
        )}
        <SectionTitle>{localStrings(type, t).accountRelation}</SectionTitle>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="account_manager"
          placeholder={t("Account Manager")}
          options={userOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="customer_service_rep"
          placeholder={localStrings(type, t).successManager}
          options={userOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        />
        <SubmitButtonContainer>
          <PrimaryButtonFitContainer loading={loading}>
            {localStrings(type, t).createAccount}
          </PrimaryButtonFitContainer>
        </SubmitButtonContainer>
      </Form>
    </>
  );
};
