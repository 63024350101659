import styled from "styled-components";

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
