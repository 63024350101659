import React, { useEffect, useState } from "react";
import { Calendar } from "../Calendar/Calendar";
import type { Moment } from "moment";
import moment from "moment";
import type { MethodsOfUseForm } from "../../types/types";

// These keys are required by Calendar, but are not required in the DatePickerProps, because the date picker defines them in
// its implementation, and assigns them to Calendar
// To fix this error, I omit them from the DatePickerProps
type HandledByDatePickerProps =
  | "id"
  | "focused"
  | "date"
  | "handleDateChange"
  | "handleFocusChange"
  | "errors";

interface DatePickerProps
  extends Omit<
    React.ComponentProps<typeof Calendar>,
    HandledByDatePickerProps
  > {
  methodsOfUseForm: MethodsOfUseForm;
  defaultValue?: string | null;
  handleOnChange?: () => void;
  normalPlaceholder?: boolean;
  shouldValidate?: boolean;
}

/**
 * Date picker input element. Wraps the <Calendar> element to encapsulate and
 * reduce repetitive boilerplate related to react-hook-form and react state.
 */
export const DatePicker = ({
  label,
  name,
  methodsOfUseForm: { register, setValue, errors },
  required,
  defaultValue,
  isOutsideRange,
  handleOnChange,
  normalPlaceholder,
  shouldValidate = false,
  ...rest
}: DatePickerProps) => {
  const [focused, setFocused] = useState(false);

  const [date, setDate] = useState<Moment | null>(
    defaultValue ? moment(defaultValue) : null
  );

  useEffect(() => {
    register({ name, required: !!required });
    const dateString: string | null = defaultValue
      ? moment(new Date(defaultValue)).toISOString()
      : "";
    setValue(name, dateString || "", { shouldValidate });
  }, [defaultValue, name, register, required, setValue, shouldValidate]);

  const handleDateChange = (date: Moment | null) => {
    setDate(date);
    // The return type of `.toISOString()` is inaccurate.  It can be `null`
    // if the `date` argument passed to `moment` is `null`.
    // We want `""` instead of `null` so PATCH requests to the backend succeed.
    const dateString: string | null = moment(date).toISOString();
    setValue(name, dateString || "", { shouldValidate: true });

    if (handleOnChange) {
      handleOnChange();
    }
  };

  const handleFocusChange = ({ focused }: { focused: boolean | null }) => {
    setFocused(!!focused);
  };

  return (
    <Calendar
      {...rest}
      label={label}
      date={date}
      focused={focused}
      name={name}
      id={name}
      handleDateChange={handleDateChange}
      handleFocusChange={handleFocusChange}
      handleOutsideRange={isOutsideRange}
      normalPlaceholder={normalPlaceholder}
      errors={errors}
    />
  );
};
