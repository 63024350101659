import { useTranslation } from "react-i18next";
import type {
  Assets,
  PIMProduct,
  ProductStatusType,
  TdsGeneratedAssetSchema,
} from "../../../../../types/types.PIM";
import {
  H3,
  RegularTextSmall,
} from "../../../../../components/Typography/Typography";
import { Form } from "../../../../../layout/FormLayout";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { Table } from "../../../../../components/Table/Table";
import { PrimaryButtonFitContainer } from "../../../../../components/Buttons/Buttons";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { GenerateDocument } from "./GenerateDocument";
import { TextField } from "../../../../../components/TextFields/TextFields";
import {
  TablePlaceholder,
  toTitleCase,
  useFormWrapper,
  useStoreState,
  useUpdateProductStatus,
} from "../../../../../util/util";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import type { AxiosError } from "axios";
import { endpoints } from "../../../../../endpoints";
import { strings } from "../../../../../util/strings";
import type { OptionType, SupportedLanguage } from "../../../../../types/types";
import axios from "axios";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";

const TableContainer = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.colors.secondaryLightGray};
`;

const ScrollArea = styled.div`
  max-height: 460px;
  overflow-y: auto;
  display: block;
`;

export const EditOrGenerateProductDocument = forwardRef<
  { clear_poll_timeout: () => void },
  {
    asset?: Assets;
    product?: PIMProduct;
    replaceProductId?: (id: string) => void;
    onSuccess: (productId?: string, shouldFetchData?: boolean) => void;
  }
>((props, ref) => {
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useNotifications();
  const [tableData, setTableData] = useState<
    { name: string; type: "group" | "collection" }[]
  >([]);
  const [asset, setAsset] = useState<Assets>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generatedAsset, setGeneratedAsset] =
    useState<TdsGeneratedAssetSchema>();
  const [productOptions, setProductOptions] = useState<OptionType<string>[]>();
  const [poll_timeout, set_poll_timeout] = useState<NodeJS.Timeout>();

  const updateProductStatus = useUpdateProductStatus({
    product: props.product
      ? props.product
      : ({ status: "archived" } as PIMProduct),
  });

  const defaultValues = useMemo(() => {
    return {
      category: {
        label: "Technical Data Sheet (TDS)",
        value: "tds",
      },
      asset_name: "",
      product_from_asset: { label: "", value: "" },
      product_name: props.product ? props.product.name : "",
      language: { label: "English (EN)", value: "en" },
    };
  }, [props]);

  const methodsOfUseForm = useFormWrapper({
    defaultValues,
  });

  const {
    control,
    errors,
    formState,
    handleSubmit,
    register,
    setValue,
    watch,
  } = methodsOfUseForm;

  const categoryValue = watch("category", {
    label: "Technical Data Sheet (TDS)",
    value: "tds",
  });

  const tableColumns = useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
      },
      {
        Header: t("Type"),
        accessor: "type",
        Cell: ({ value }: { value: string }) => <div>{toTitleCase(value)}</div>,
      },
    ],
    [t]
  );

  const onGenerateAsset = useCallback(
    (asset: Assets) => {
      setValue("language", { label: "English (EN)", value: "en" });
      setAsset(asset);
    },
    [setValue]
  );

  const update_timeout_id = (timeout_id: NodeJS.Timeout) => {
    set_poll_timeout(timeout_id);
  };

  const onSubmit = async ({
    asset_name,
    language,
  }: {
    asset_name: string;
    language: OptionType<SupportedLanguage>;
  }) => {
    if (asset) {
      setIsSubmitting(true);
      const requestBody = {
        name: asset_name,
        asset_type: asset.asset_type,
        category_id: asset.category.id,
        language: language.value,
        is_downloadable: asset.is_downloadable ?? false,
        is_cover_image: asset.is_cover_image ?? false,
        id: asset.id,
        unarchive: true,
      };
      try {
        let updatedProduct: PIMProduct | null = null;
        if (!props.asset) {
          const { data } = await updateProductStatus();
          updatedProduct = data;
        }
        const { data: updatedAsset } = await axios.patch<Assets>(
          endpoints.v2_storefronts_id_pim_assets_id(tenant_id, asset.id),
          {
            ...requestBody,
            ...(!props.asset
              ? { product_ids: [updatedProduct!.id] }
              : props.product
              ? { product_ids: [props.product.id] }
              : {}),
          }
        );
        notifySuccess(t("Asset successfully edited"));
        setAsset(updatedAsset);
        setIsSubmitting(false);
        props.onSuccess(
          !props.asset
            ? updatedProduct?.primary_staged_product_id ?? updatedProduct?.id
            : "",
          true
        );
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("There was an error editing the generated asset"),
          {
            error,
          }
        );
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (props.asset && props.asset.is_generated) {
      const existingAsset = props.asset;
      if (!asset) {
        onGenerateAsset(existingAsset);
      }
      setGeneratedAsset(existingAsset.tds_generated_asset);
      if (!tableData.length) {
        setTableData(
          existingAsset.tds_generated_asset.sections.map((section) => ({
            name:
              section.group_type === "group"
                ? section.group.display_name
                  ? t([section.group.display_name])
                  : section.group.name
                : section.collection.display_name
                ? t([section.collection.display_name])
                : section.collection.name,
            type: section.group_type,
          }))
        );
      }
      const getProducts = async () => {
        const products = (
          props.product
            ? [props.product.id]
            : props.asset
            ? props.asset.product_ids
            : []
        ).map((id) =>
          axios.get<PIMProduct>(
            endpoints.v2_tenants_id_pim_products_id(tenant_id, id),
            { params: { use_database: true, return_staged: true } }
          )
        );
        const prods = await Promise.all(products);
        const prodsHash: {
          [name: string]: (OptionType<string> & {
            status: ProductStatusType;
          })[];
        } = {};
        prods.forEach(({ data }) => {
          prodsHash[data.name] = prodsHash[data.name]
            ? [
                ...prodsHash[data.name],
                { label: data.name, value: data.id, status: data.status },
              ]
            : [{ label: data.name, value: data.id, status: data.status }];
        });
        const prodOptions: OptionType<string>[] = [];
        Object.values(prodsHash).forEach((prods) => {
          if (prods.length === 1) {
            prodOptions.push({ label: prods[0].label, value: prods[0].value });
          } else {
            const staged_prod = prods.find(({ status }) =>
              status.includes("staged")
            );
            if (staged_prod) {
              prodOptions.push({
                label: staged_prod.label,
                value: staged_prod.value,
              });
            }
          }
        });
        setProductOptions(prodOptions);
        if (prodOptions.length === 1) {
          setValue("product_from_asset", prodOptions[0]);
        }
      };
      if (!productOptions) {
        getProducts();
      }
    }
  }, [
    asset,
    onGenerateAsset,
    productOptions,
    props,
    setValue,
    t,
    tableData.length,
    tenant_id,
  ]);

  useEffect(() => {
    if (!props.asset && props.product) {
      const product = props.product;
      const generatedAssetFromProduct =
        product.product_schema.generated_assets.find(
          (genAsset) => genAsset.asset_category === categoryValue.value
        );
      if (generatedAssetFromProduct && generatedAssetFromProduct.is_active) {
        setGeneratedAsset(generatedAssetFromProduct);
        setTableData(
          generatedAssetFromProduct.sections.map((section) => ({
            name:
              section.group_type === "group"
                ? section.group.display_name
                  ? t([section.group.display_name])
                  : section.group.name
                : section.collection.display_name
                ? t([section.collection.display_name])
                : section.collection.name,
            type: section.group_type,
          }))
        );
      }
    }
  }, [categoryValue.value, props, t]);

  useEffect(() => {
    if (asset) {
      const assetNameArr = asset.name.split(".");
      const assetName =
        assetNameArr.length > 1
          ? assetNameArr.slice(0, -1).join("")
          : assetNameArr[0];
      setValue("asset_name", assetName);
    }
  }, [asset, setValue]);

  useImperativeHandle(
    ref,
    () => {
      return {
        clear_poll_timeout() {
          clearTimeout(poll_timeout);
        },
      };
    },
    [poll_timeout]
  );

  const title = asset ? t("Edit Generated Document") : t("Generate Document");
  return (
    <>
      <H3>{title}</H3>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="category"
          placeholder={t("Category")}
          options={[]}
          isDisabled={true}
          errors={errors}
          formState={formState}
        />
        {!props.asset && (
          <TextField
            name="product_name"
            label={t("Product Name")}
            theref={register()}
            formState={formState}
            disabled={true}
            errors={errors}
            type="text"
          />
        )}
        {props.asset && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="product_from_asset"
            placeholder={t("Select Product")}
            options={productOptions ?? []}
            rules={{
              validate: (val) =>
                !props.asset
                  ? true
                  : val?.value
                  ? true
                  : strings(t).thisIsARequiredField,
            }}
            errors={errors}
            isDisabled={productOptions?.length === 1}
            formState={formState}
          />
        )}
        <TableContainer>
          <RegularTextSmall>
            {t("Selected groups & collections")}
          </RegularTextSmall>
          <ScrollArea>
            <Table
              columns={tableColumns}
              data={tableData}
              isLoading={false}
              error={undefined}
              Placeholder={
                <TablePlaceholder message={t("No items to show.")} />
              }
            />
          </ScrollArea>
        </TableContainer>
        {generatedAsset && (
          <GenerateDocument
            asset={asset}
            product_id={
              props.product
                ? props.product.id
                : props.asset
                ? props.asset.product_ids[0]
                : ""
            }
            generate_id={generatedAsset.id}
            update_poll_timeout_id={update_timeout_id}
            onGenerate={(asset) => {
              onGenerateAsset(asset);
              notifySuccess(
                props.asset
                  ? t("Generated asset has been updated successfully")
                  : t("Asset has been successfully generated")
              );
            }}
          />
        )}
        <TextField
          name="asset_name"
          label={t("Asset Name")}
          theref={register({ required: strings(t).thisIsARequiredField })}
          formState={formState}
          errors={errors}
          readOnly={!asset}
          type="text"
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="language"
          placeholder={t("Language")}
          options={[{ label: "English (EN)", value: "en" }]}
          errors={errors}
          isDisabled={!asset}
          formState={formState}
        />
        <PrimaryButtonFitContainer
          style={{ marginTop: "32px" }}
          type="submit"
          loading={isSubmitting}
          disabled={!asset}
        >
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
});
