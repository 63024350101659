import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import styled from "styled-components/macro";
import type { IOrderItem, LotNumber } from "../../types/types";
import { useStoreState, useFormWrapper } from "../../util/util";
import { Notifications } from "../Notifications/NotificationsContext";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitButtonContainer } from "../../layout/FormLayout";
import {
  AddNewIconButton,
  DeleteButton,
  PrimaryButtonMedium,
} from "../Buttons/Buttons";
import { InfoBlockSmall } from "../InfoBlocks/InfoBlocks";
import { TextField } from "../TextFields/TextFields";
import {
  ProductName,
  ProductDetailsLight,
  RowJustifyStart,
} from "../TransactionItem/TransactionItemTopLeft";
import { ApplicationsContainer, Application } from "../Cart/Cart";
import { useTranslation } from "react-i18next";
import { TrimmedName } from "../../pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import { screenSize } from "../../theme";

interface ILotGridProps {
  editMode: boolean;
}

const LotNumberWrapper = styled.div`
  margin-top: 15px;
`;

const LotNumberRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
  @media ${screenSize.medium} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const LotNumberContainer = styled.div<ILotGridProps>`
  display: grid;
  min-width: 40%;
  grid-template-columns: ${({ editMode }) =>
    editMode ? "auto auto auto 40px 40px" : "auto auto auto 0 0"};
  grid-template-rows: 1fr max-content;
  margin: 0 0 12px 0;
  input {
    max-width: 125px;
  }
`;
const QuantityContainer = styled.div`
  background: ${({ theme }) => theme.disabledButtonBG};
  border: ${({ theme }) => theme.primaryBorder};
  margin: 0 12px;
  border-radius: 4px;
  padding: 4px 15px;
  min-width: 100px;
`;

const IconContainer = styled.div`
  margin: 15px 5px;
  width: 28px;
`;

export function DisplayOrEditLotNumbers({
  orderItems,
  orderId,
  editMode,
  handleSave,
}: {
  orderItems: IOrderItem[];
  orderId: string;
  editMode?: boolean;
  handleSave: () => void;
}) {
  const { storefront_id } = useStoreState();
  const [items] = useState(orderItems);
  const [loading, setLoading] = useState(false);
  const [lots, setLots] = useState<LotNumber[]>();
  const { notifyError, notifySuccess } = useContext(Notifications);
  const { t } = useTranslation();
  const editLotNumberSchema = Yup.object().shape({
    lot_numbers: Yup.array(
      Yup.object().shape({
        units: Yup.number()
          .integer(t("Units must be integer"))
          .nullable(true)
          .transform((value) => (isNaN(value) ? undefined : value))
          .positive(t("Units must be a positive number"))
          .typeError(t("Units must be a number"))

          .notRequired(),
        number: Yup.string().when("units", (units: number) => {
          if (units > 0) {
            return Yup.string().required(t("This is a required field"));
          }
        }),
        item_id: Yup.string().notRequired(),
      })
    ),
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState,
    errors,
    watch,
  } = useFormWrapper({
    defaultValues: {
      lot_numbers: lots,
    },
    reValidateMode: "onChange",
    resolver: yupResolver(editLotNumberSchema),
  });

  const lotValues = watch("lot_numbers");

  const { fields, remove, append } = useFieldArray({
    control,
    name: "lot_numbers",
  });

  // Submit Lot Number changes
  const onSubmit = async (values: { lot_numbers: LotNumber[] }) => {
    if (validateUnits()) {
      setLoading(true);
      const data = () => {
        return {
          lots: values.lot_numbers.filter(
            (lot) =>
              lot.units !== 0 &&
              lot.units !== null &&
              lot.units !== undefined &&
              (lot.units as unknown as String) !== ""
          ),
        };
      };

      try {
        await Axios.put(
          `/v1/storefronts/${storefront_id}/orders/${orderId}/lot-number`,
          data()
        );
        notifySuccess("Your changes have been saved successfully");
        handleSave();
        setLoading(false);
      } catch (error) {
        console.error(error);
        const message = (error as AxiosError)?.response?.data?.message
          ? t("{{lotNumberError}}", {
              lotNumberError: (error as AxiosError)?.response?.data?.message,
            })
          : t("There was an eror saving your changes");
        notifyError(message, { error });
        setLoading(false);
      }
    }
  };

  //Add new Lot Number for order item.
  const addNewLotNumber = (itemId: string) => {
    append({
      item_id: itemId,
      number: null,
      units: null,
    });
  };

  //remove Lot number row from UI, but actual BE delete will be after submitting the changes
  const removeLotNumber = (index: number) => {
    remove(index);
  };

  const getMaxItemUnits = (item: IOrderItem) => {
    const itemFields = lotValues?.filter((field) => field.item_id === item.id);
    var totalUnits = itemFields?.reduce(function (prev, cur) {
      return prev + Number(cur.units);
    }, 0);

    return Number(item.number_of_units) - Number(totalUnits);
  };

  const validateUnits = () => items.every((item) => getMaxItemUnits(item) > -1);

  //Create Item Lot Number row for both View and Edit
  const createFields = (item: IOrderItem) => {
    const itemFields = fields.filter((field) => field.item_id === item.id);
    if (itemFields.length === 0 && editMode) {
      addNewLotNumber(item.id);
    }
    if (itemFields.length > 0) {
      return itemFields.map((field, itemIndex) => {
        const index = fields?.findIndex((lot) => lot.id === field.id);
        return (
          <LotNumberContainer key={index} editMode={editMode || false}>
            <div style={{ minWidth: "80px" }}>
              {/* @ts-ignore */}
              {!editMode ? (
                <InfoBlockSmall
                  header={t("Units")}
                  content={String(field.units || "--")}
                />
              ) : (
                <div style={{ maxWidth: "78px" }}>
                  <TextField
                    name={`lot_numbers[${index}].units`}
                    key={field.id}
                    label={t("Units")}
                    theref={register({
                      required: true,
                    })}
                    formState={formState}
                    errors={errors}
                    error={
                      errors?.lot_numbers?.[itemIndex]?.units
                        ? {
                            message:
                              errors?.lot_numbers?.[itemIndex]?.units
                                ?.message || t("Something Wrong"),
                            type:
                              errors?.lot_numbers?.[itemIndex]?.units?.type ||
                              "error",
                          }
                        : getMaxItemUnits(item) < 0
                        ? {
                            message: t(
                              `You can not exceed the total item units`
                            ),
                            type: "max",
                          }
                        : null
                    }
                    type="number"
                    defaultValue={field.units}
                    // small={true}
                    extraSmall={true}
                  />
                </div>
              )}
            </div>
            <div style={{ minWidth: "90px" }}>
              {!editMode ? (
                <InfoBlockSmall
                  header={t("Quantity")}
                  content={
                    item.sku?.package_volume &&
                    lotValues &&
                    lotValues[index]?.units > 0
                      ? `${String(
                          lotValues[index]?.units *
                            Number(item.sku.package_volume)
                        )} ${item.sku.packaging_unit?.name ?? ""}`
                      : "--"
                  }
                />
              ) : (
                <QuantityContainer>
                  <InfoBlockSmall
                    header={t("Quantity")}
                    content={
                      item.sku?.package_volume &&
                      lotValues &&
                      lotValues[index]?.units > 0
                        ? `${String(
                            lotValues[index].units *
                              Number(item.sku.package_volume)
                          )} ${item.sku.packaging_unit?.name}`
                        : "--"
                    }
                  />
                </QuantityContainer>
              )}
            </div>
            <div style={{ minWidth: "80px" }}>
              {/* @ts-ignore */}
              {!editMode ? (
                <InfoBlockSmall
                  header={t("Lot Number")}
                  content={field.number || "--"}
                />
              ) : (
                <div style={{ maxWidth: "176px" }}>
                  <TextField
                    key={field.id}
                    name={`lot_numbers[${index}].number`}
                    label={t("Lot Number")}
                    theref={register({
                      required: false,
                    })}
                    defaultValue={field.number}
                    formState={formState}
                    errors={errors}
                    error={
                      errors?.lot_numbers?.[itemIndex]?.number
                        ? {
                            message:
                              errors?.lot_numbers?.[itemIndex]?.number
                                ?.message || "Something Wrong",
                            type:
                              errors?.lot_numbers?.[itemIndex]?.number?.type ||
                              "error",
                          }
                        : null
                    }
                    type="text"
                    // small={true}
                    extraSmall={true}
                  />
                  <input
                    type="hidden"
                    name={`lot_numbers[${index}].item_id`}
                    ref={register({ required: false })}
                    value={item.id}
                  />
                </div>
              )}
            </div>

            <div>
              {itemIndex === itemFields.length - 1 && editMode && (
                <IconContainer>
                  <AddNewIconButton
                    testid={"Add"}
                    type={"button"}
                    onClick={() => addNewLotNumber(item.id)}
                  />
                </IconContainer>
              )}
            </div>
            {editMode && (
              <div>
                <IconContainer>
                  <DeleteButton
                    testid={"delete"}
                    type={"button"}
                    onClick={() => removeLotNumber(index)}
                  />
                </IconContainer>
              </div>
            )}
          </LotNumberContainer>
        );
      });
    }
  };

  const getLotText = (item: IOrderItem) =>
    `${item.sku?.packaging_type?.name ?? ""} ${
      item.product.identifiers?.cas_number
        ? `${t("CAS #")} ${item.product.identifiers.cas_number}`
        : ""
    } ${
      item.sku?.erp_system_id ? `${t("ERP #")} ${item.sku.erp_system_id}` : ""
    }`;

  useEffect(() => {
    if (orderItems && !editMode) {
      let orderLots: LotNumber[] = [];
      orderItems.forEach((item) => {
        orderLots = [...orderLots, ...item.lot_numbers];
      });
      setLots(orderLots);
      setValue("lot_numbers", orderLots);
    }
  }, [editMode, orderItems, setValue]);

  return (
    <LotNumberWrapper>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        {fields.length === 0 && !editMode
          ? ""
          : items.map((item, index) => (
              <LotNumberRow key={index}>
                <div style={{ width: "100%", flex: "1" }}>
                  <TrimmedName text={item.product.name} Content={ProductName} />
                  <RowJustifyStart>
                    <TrimmedName
                      text={getLotText(item)}
                      Content={ProductDetailsLight}
                    />
                  </RowJustifyStart>
                  <ApplicationsContainer>
                    {item.product_applications.map((application, index) => (
                      <Application key={index}>{application.name}</Application>
                    ))}
                  </ApplicationsContainer>
                </div>
                <div style={{ justifySelf: "self-end", minWidth: "50%" }}>
                  {createFields(item)}
                </div>
              </LotNumberRow>
            ))}
        {editMode && (
          <SubmitButtonContainer>
            <PrimaryButtonMedium type={"submit"} loading={loading}>
              {t("Save your changes")}
            </PrimaryButtonMedium>
          </SubmitButtonContainer>
        )}
      </form>
    </LotNumberWrapper>
  );
}
