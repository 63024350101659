import { useTranslation } from "react-i18next";
import type { IAccordionCardProps } from "./AccordionCard";
import { AccordionCard } from "./AccordionCard";
import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";
import { InvisibleButton } from "../Buttons/Buttons";
import { SlideOut } from "../SlideOut/SlideOut";
import { TranslationsIcon } from "../Icons/Icons";
import styled from "styled-components/macro";
import { Row } from "../Layout/Layout";
import { SoftHeader, SoftHeaderMediumDarkText } from "../Typography/Typography";

export const TranslationsAccordionCard = (
  props: Omit<IAccordionCardProps, "title" | "actionChildren" | "children"> & {
    tableElement: JSX.Element;
    editElement: (onClose: () => void) => JSX.Element;
  }
) => {
  const [showSlideout, setShowSlideout] = useState(false);
  const { t } = useTranslation();
  const actionChildren = (
    setShowContent: Dispatch<SetStateAction<boolean | undefined>>
  ) => {
    return (
      <InvisibleButton
        onClick={() => {
          setShowSlideout(true);
          setShowContent(true);
        }}
      >
        <TranslationsIcon /> {t("Manage Translations")}
      </InvisibleButton>
    );
  };
  const onClose = () => {
    setShowSlideout(false);
  };
  return (
    <>
      <AccordionCard
        {...props}
        actionChildren={actionChildren}
        title={t("Translations")}
        subtitle={props.subtitle}
        expanded={true}
      >
        {props.tableElement}
      </AccordionCard>
      <SlideOut closeFlyout={onClose} show={showSlideout}>
        {props.editElement(onClose)}
      </SlideOut>
    </>
  );
};

export const TranslationsModifiedRow = styled(Row)`
  justify-content: flex-start;
  align-items: start;
  margin-bottom: 16px;
`;

const TranslationsPropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const LanguageTranslationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

export const TranslationsButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const TranslationsPropertyItem = ({
  property,
  value,
}: {
  property: string;
  value: string;
}) => {
  return (
    <TranslationsPropertyContainer>
      <SoftHeader>{property}</SoftHeader>
      <SoftHeaderMediumDarkText>{value}</SoftHeaderMediumDarkText>
    </TranslationsPropertyContainer>
  );
};
