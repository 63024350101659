import type { FC } from "react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import ReactDOM from "react-dom";
import { DesktopNotification, NotificationsWrapper } from "./Notifications";
import { Auth } from "../Auth";
import { ErrorHandler } from "../../ErrorHandler";
import debounce from "lodash/debounce";

type INotification = {
  message: string;
  type: "success" | "info" | "error" | "warning";
  error?: unknown;
};

const NOTIFICATION_POLL_TIMEOUT = 2000;
const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
const SHIFT_NOTIFICATIONS = "SHIFT_NOTIFICATIONS";
const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
type RemoveNotificationPayload = { message: string };

type NotificationState = INotification[];

type NotificationAction<T, P> = {
  type: T;
  payload: P;
};

type NotificationActions =
  | NotificationAction<typeof PUSH_NOTIFICATION, INotification>
  | NotificationAction<typeof SHIFT_NOTIFICATIONS, null>
  | NotificationAction<typeof REMOVE_NOTIFICATION, RemoveNotificationPayload>;

function notificationReducer(
  state: NotificationState,
  action: NotificationActions
): NotificationState {
  /* 
    If we don't destructure "type" from action at the top
    the compiler can infer the type of "payload" inside the case block.
    If we do destructure then it can't narrow any further than the "NotificationActions"
    union and we have to make an assertion.

    This is a gotcha of typescript that I wasn't aware of until now, but basically 
    the compiler doesn't track type narrowing across variables. So doing
    const { type } = action or const type = action.type both cause the variable to "reset" its 
    type back to the union type. Brutally non intuitive.
  */
  switch (action.type) {
    case "PUSH_NOTIFICATION":
      return [...state, action.payload];
    case "SHIFT_NOTIFICATIONS":
      const shiftedState = state.slice(1);
      return shiftedState;
    case "REMOVE_NOTIFICATION":
      const { message } = action.payload;
      return state.filter((notification) => notification.message !== message);
    default:
      return state;
  }
}

type NotifyErrorOptions = {
  error?: any;
  logMessage?: string;
};

export const Notifications = createContext({
  notify: (notification: INotification) => {},
  notifySuccess: (message: string) => {},
  notifyError: (message: string, options?: NotifyErrorOptions) => {},
  notifyWarning: (message: string) => {},
  notifyInfo: (message: string) => {},
  pollNotification: <
    PollResult extends {
      shouldPoll: boolean;
      message: string;
      [prop: string]: string | number | boolean | object;
    }
  >(_pollFns: {
    pollFn: () => Promise<PollResult>;
    onComplete: (pollResult: PollResult) => void;
    onError?: (error?: unknown) => void;
  }) => {},
});

export const NotificationsProvider: FC = ({ children }) => {
  const [notifications, dispatch] = useReducer(notificationReducer, []);
  const { user } = useContext(Auth);

  const notificationAlreadyInQueue = (
    msg: string,
    notifications: NotificationState
  ) => !!notifications.find(({ message }) => message === msg);

  // prevent notifications with the same message from being shown back to back
  const notify = useCallback(
    (notification: INotification) => {
      if (!notificationAlreadyInQueue(notification.message, notifications)) {
        dispatch({
          type: "PUSH_NOTIFICATION",
          payload: notification,
        });
      }
    },
    [notifications]
  );

  const debouncedNotify = useMemo(() => debounce(notify, 300), [notify]);

  const notifySuccess = (message: string) =>
    debouncedNotify({ type: "success", message });

  const notifyError = (message: string, options?: NotifyErrorOptions) => {
    debouncedNotify({ type: "error", message, error: options?.error });

    if (
      options?.error &&
      (typeof options.error === "string" || options.error instanceof Error)
    ) {
      ErrorHandler.setUser(user?.id ?? "");
      ErrorHandler.report(options.error);
    }
    // We console.error here so we don't have to do it at every call site.
    console.error(options?.logMessage ?? message, options?.error);
  };

  const notifyWarning = (message: string) =>
    debouncedNotify({ type: "warning", message });

  const notifyInfo = (message: string) =>
    debouncedNotify({ type: "info", message });

  const removeNotification = (message: string) => {
    dispatch({
      type: "REMOVE_NOTIFICATION",
      payload: { message: message },
    });
  };

  /**
   * A function that let's you consistently poll a notification API, until some set condition is met/failed, then runs a function when this happens.
   * @param {pollFn, onComplete}
   * @property pollFn is the polling function that calls the API
   * @property onComplete is a function that runs when polling completes.
   */
  const pollNotification = <
    PollResult extends {
      shouldPoll: boolean;
      message: string;
      [prop: string]: string | number | boolean | object;
    }
  >({
    pollFn,
    onComplete,
    onError,
  }: {
    pollFn: () => Promise<PollResult>;
    onComplete: (pollResult: PollResult) => void;
    onError?: (error?: unknown) => void;
  }) => {
    let intervalId = setTimeout(() => {
      const polling = async () => {
        try {
          const pollResult = await pollFn();
          if (pollResult.shouldPoll) {
            intervalId = setTimeout(polling, NOTIFICATION_POLL_TIMEOUT);
          } else {
            onComplete(pollResult);
            clearTimeout(intervalId);
          }
        } catch (error) {
          if (onError) {
            onError(error);
          }
          clearTimeout(intervalId);
        }
      };
      polling();
    }, NOTIFICATION_POLL_TIMEOUT);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (notifications.length > 0) {
        dispatch({ type: "SHIFT_NOTIFICATIONS", payload: null });
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [notifications]);

  const ShowNotifications = () => {
    if (notifications.length > 0) {
      return ReactDOM.createPortal(
        <NotificationsWrapper>
          {notifications.map((item: INotification, index: number) => (
            <DesktopNotification
              notificationType={item.type}
              text={item.message}
              key={item.message + index}
              removeNotification={removeNotification}
              id={index}
            />
          ))}
        </NotificationsWrapper>,
        document.body
      );
    } else return null;
  };

  return (
    <Notifications.Provider
      value={{
        notify,
        notifySuccess,
        notifyError,
        notifyWarning,
        notifyInfo,
        pollNotification,
      }}
    >
      <ShowNotifications />
      {children}
    </Notifications.Provider>
  );
};

export const useNotifications = () => useContext(Notifications);
