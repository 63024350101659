import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import type { MethodsOfUseForm } from "../../../types/types";
import { strings } from "../../../util/strings";
import * as Yup from "yup";
import styled from "styled-components/macro";

const FlexRow = styled.div`
  display: flex;
  // Add min-height to prevent layout shift when drop down menu appears.
  min-height: 58px;
  & > div {
    flex-grow: 1;
    margin-right: 9px;
    width: 49%;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

export const getPaymentRequestSchema = (t: TFunction) => ({
  required_eta: Yup.string().required(strings(t).thisIsARequiredField),
});

/*
 * Component for Transaction Payment Data used in Sample and Quote requests
  The form object has these properties:
  {
    required_eta: string;
  }
 * @param param0 
 * @returns 
 */
export const PaymentRequest = ({
  methodsOfUseForm,
}: {
  methodsOfUseForm: MethodsOfUseForm;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <FlexRow>
        <DatePicker
          label={t("Requested ETA")}
          name={"required_eta"}
          methodsOfUseForm={methodsOfUseForm}
          required={true}
        />
      </FlexRow>
    </>
  );
};
