import type { QuoteRequestItem } from "../../types/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatTotalQuantityString } from "../quoteCart/BuyerQuoteItemForm";
import { TransactionItemInfoBlock } from "./TransactionItemInfoBlock";
import { makeSkuLabel, makeNumberOfUnitsLabel } from "./TransactionItemUtils";

/**
 * Shows the requested details for a transaction item.
 */
export const TransactionItemInfoBlockRequestedDetails = ({
  item,
}: {
  item: QuoteRequestItem;
}) => {
  const { t } = useTranslation();

  const requestedSku = item.requested_sku;
  const requestedNumberOfUnits = item.requested_no_of_units;

  const requestedTotalQuantity = parseFloat(item.requested_total_quantity);

  const requestedTotalQuantityString = formatTotalQuantityString(
    requestedSku?.packaging_unit?.name,
    requestedTotalQuantity
  );

  const heading = t("Requested Details");

  return requestedSku.kind === "No preference SKU" ? (
    <TransactionItemInfoBlock
      heading={heading}
      main={requestedTotalQuantityString}
      lines={[t("No Preference")]}
    />
  ) : (
    <TransactionItemInfoBlock
      heading={heading}
      main={requestedTotalQuantityString}
      lines={[
        makeSkuLabel(requestedSku),
        makeNumberOfUnitsLabel(requestedNumberOfUnits, t),
      ]}
      warningLine={
        requestedSku.kind === "Buyer SKU" ? t("Custom SKU") : undefined
      }
    />
  );
};
