import type {
  IGroupedPriceTierSingleTier,
  ProductSKU,
} from "../../types/types";
import { useState } from "react";
import type { PriceTierRowData } from "./PriceTiersBigTable";
import type { EditableRowValues } from "./EditableRow";

export const tierToRowData = (
  tier: IGroupedPriceTierSingleTier
): PriceTierRowData => ({
  status: "unchanged",
  data: tier,
});

/**
 * A React hook to be used in the parent component of the PriceTiersBigTable,
 * so that the state of the table is accessible in the parent and can be
 * submitted along with other form data. This allows the table to be used as
 * part of different forms (e.g. for creating vs editing price tiers).
 *
 * @see PriceTiersBigTable
 */
export const usePriceTiersBigTable = (
  rowData: PriceTierRowData[],
  initialEditRowIndex?: number
) => {
  const [tableRowsData, setTableRowsData] =
    useState<PriceTierRowData[]>(rowData);

  const [editRowIndex, setEditRowIndex] = useState<number | null>(
    initialEditRowIndex ?? null
  );

  const resetTableRowsData = (rowData: PriceTierRowData[]) => {
    setTableRowsData(rowData);
  };

  const startEditingRow = (rowIndex: number) => {
    setEditRowIndex(rowIndex);
  };

  const cancelEditingRow = () => {
    setEditRowIndex(null);
    // If the user cancels on a newly added row, remove it from the table.
    setTableRowsData(
      tableRowsData.filter((rowData) => rowData.status !== "new")
    );
  };

  const finishEditingRow = (rowValues: EditableRowValues) => {
    // Store the saved row in React local state until the form is submitted.
    const newRowsData = tableRowsData
      .map((rowData, index): PriceTierRowData => {
        if (index !== editRowIndex) {
          return rowData;
        }
        return {
          status:
            rowData.status === "new" || rowData.status === "new-edited"
              ? "new-edited"
              : "edited",
          data: {
            tier_id: rowData.data.tier_id,
            product_sku: rowValues.product_sku.value,
            price_per_uom: rowValues.price_per_uom,
            minimum_uom_quantity: rowValues.minimum_uom_quantity,
            minimum_sku_quantity: rowValues.minimum_sku_quantity,
          },
        };
      })
      .sort((rowA, rowB) => {
        const rowASku = rowA.data.product_sku;
        const rowBSku = rowB.data.product_sku;
        if (rowASku.id === rowBSku.id) {
          const aMinSku = parseInt(rowA.data.minimum_sku_quantity);
          const bMinSku = parseInt(rowB.data.minimum_sku_quantity);
          return aMinSku > bMinSku ? 1 : -1;
        }
        const aSkuName = rowASku.packaging_type?.name;
        const bSkuName = rowBSku.packaging_type?.name;
        return aSkuName > bSkuName ? 1 : -1;
      });

    setTableRowsData(newRowsData);
    setEditRowIndex(null);
  };

  const deleteRow = (rowIndex: number) => {
    setTableRowsData(
      tableRowsData.reduce((prev: PriceTierRowData[], rowData, index) => {
        if (index === rowIndex) {
          if (!rowData.status.includes("new")) {
            prev.push({ ...rowData, status: "deleted" });
          }
        } else {
          prev.push(rowData);
        }
        return prev;
      }, [])
    );
  };

  const addNewRow = (productSku: ProductSKU) => {
    setTableRowsData([
      ...tableRowsData,
      {
        status: "new",
        data: {
          tier_id: "",
          product_sku: productSku,
          price_per_uom: "",
          minimum_uom_quantity: "",
          minimum_sku_quantity: "",
        },
      },
    ]);
    setEditRowIndex(tableRowsData.length);
  };

  return {
    tableRowsData,
    editRowIndex,
    addNewRow,
    startEditingRow,
    cancelEditingRow,
    finishEditingRow,
    deleteRow,
    resetTableRowsData,
  };
};
