import { yupResolver } from "@hookform/resolvers/yup";
import {
  PrimaryCancelButton,
  PrimaryButtonLarge,
  PrimaryButtonMedium,
  EditButton,
  DeleteButton,
} from "../../../../../../components/Buttons/Buttons";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import {
  H3,
  H5,
  SoftHeader,
} from "../../../../../../components/Typography/Typography";
import { Form } from "../../../../../../layout/FormLayout";
import { WhiteInnerCard } from "../../../../../../layout/portalPageLayout";
import { isAxiosError, useFormWrapper } from "../../../../../../util/util";
import * as yup from "yup";
import { strings } from "../../../../../../util/strings";
import { useTranslation } from "react-i18next";
import { ToggleSwitch } from "../../../../../../components/ToggleSwitch/ToggleSwitch";
import styled from "styled-components";
import { useCallback, useContext, useEffect, useState } from "react";
import type {
  ConfigureCheckboxItem,
  StorefrontMetaData,
} from "../../../../../../types/types";
import type { AxiosError } from "axios";
import Axios from "axios";
import { endpoints } from "../../../../../../endpoints";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import { Store } from "../../../../../../Store";
import useSWR from "swr";
import { ConfirmDialog } from "../../../../../../components/ConfirmDialog/ConfirmDialog";

const Wrapper = styled.div`
  margin-top: 10px;
  margin-right: 30px;
`;
const ToggleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
`;
const ToggleItem = styled.div`
  margin: 5px 40px 5px 0;
`;

const EditDeleteWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: -10px;
  display: flex;
`;

const CheckboxCard = ({
  canEdit,
  item,
  index,
  handleCancel,
  addNew,
  mutateMetadata,
}: {
  canEdit: boolean;
  index: number;
  item?: ConfigureCheckboxItem;
  handleCancel?: () => void;
  addNew?: boolean;
  mutateMetadata: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(addNew);
  const [quote, setQuote] = useState(item?.quote || false);
  const [sample, setSample] = useState(item?.sample || false);
  const [contact, setContact] = useState(item?.contact || false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [registration, setRegistration] = useState(item?.registration || false);
  const { notifySuccess, notifyError } = useContext(Notifications);
  const {
    storeState: { storefront_id },
  } = useContext(Store);
  const { t } = useTranslation();
  const formSchema = yup.object().shape({
    checkbox_name: yup.string().required(strings(t).thisIsARequiredField),
  });
  const { handleSubmit, register, formState, errors } = useFormWrapper({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (formData: { checkbox_name: string }) => {
    const requestData = {
      name: formData.checkbox_name,
      quote,
      sample,
      contact,
      registration,
    };
    try {
      setLoading(true);
      addNew
        ? await Axios.post(
            `/v1/storefronts/${storefront_id}/metadata/configured-checkboxes`,
            requestData
          )
        : await Axios.patch(
            `/v1/storefronts/${storefront_id}/metadata/configured-checkboxes/${item?.id}`,
            requestData
          );
      notifySuccess("Your changes have been saved successfully");
      setLoading(false);
      mutateMetadata();
      setEditMode(false);
    } catch (error) {
      console.error(error);
      const message = (error as AxiosError)?.response?.data?.message
        ? (error as AxiosError)?.response?.data?.message
        : t("There was an eror saving your changes");
      notifyError(message, { error });
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      await Axios.delete(
        `/v1/storefronts/${storefront_id}/metadata/configured-checkboxes/${item?.id}`
      );
      notifySuccess(t("Check-box successfully removed"));
      mutateMetadata();
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      }
    }
  };

  const onCancel = () => {
    if (handleCancel) {
      handleCancel();
    }
    reset_toggles();
    setEditMode(false);
  };

  const reset_toggles = useCallback(() => {
    if (item?.quote !== undefined) {
      setQuote(item.quote);
    }
    if (item?.sample !== undefined) {
      setSample(item.sample);
    }
    if (item?.contact !== undefined) {
      setContact(item.contact);
    }
    if (item?.registration !== undefined) {
      setRegistration(item.registration);
    }
  }, [item?.contact, item?.quote, item?.registration, item?.sample]);

  useEffect(() => {
    if (!canEdit) {
      reset_toggles();
      setEditMode(false);
    }
  }, [canEdit, reset_toggles]);

  useEffect(() => {
    reset_toggles();
  }, [reset_toggles]);

  return (
    <WhiteInnerCard>
      <div style={{ position: "relative" }}>
        {!editMode && canEdit && (
          <EditDeleteWrapper>
            <EditButton onClick={() => setEditMode(true)} />
            <DeleteButton onClick={() => setShowDeleteConfirmDialog(true)} />
          </EditDeleteWrapper>
        )}
        {editMode && canEdit ? (
          <H3>
            {t("Check-box")} {index}
          </H3>
        ) : (
          <SoftHeader>
            {t("Check-box")} {index}
          </SoftHeader>
        )}
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          {editMode ? (
            <TextField
              name="checkbox_name"
              label="Enter text"
              theref={register({
                required: false,
              })}
              type="text"
              defaultValue={item?.name}
              formState={formState}
              errors={errors}
            />
          ) : (
            <H5>{item?.name}</H5>
          )}
          <ToggleWrapper>
            <ToggleItem>
              <ToggleSwitch
                label={t("Quotes & Orders")}
                onClick={() => setQuote(!quote)}
                name="quote"
                isChecked={quote}
                disabled={!editMode}
              />
            </ToggleItem>
            <ToggleItem>
              <ToggleSwitch
                label={t("Sample Request")}
                onClick={() => setSample(!sample)}
                name="sample"
                isChecked={sample}
                disabled={!editMode}
              />
            </ToggleItem>
            <ToggleItem>
              <ToggleSwitch
                label={t("Contact Us")}
                onClick={() => setContact(!contact)}
                name="contact"
                isChecked={contact}
                disabled={!editMode}
              />
            </ToggleItem>
            <ToggleItem>
              <ToggleSwitch
                label={t("Registration")}
                onClick={() => setRegistration(!registration)}
                name="registration"
                isChecked={registration}
                disabled={!editMode}
              />
            </ToggleItem>
          </ToggleWrapper>
          {editMode && canEdit && (
            <>
              {!loading && (
                <PrimaryCancelButton
                  style={{ marginRight: "20px" }}
                  type="button"
                  onClick={onCancel}
                >
                  {t("Cancel")}
                </PrimaryCancelButton>
              )}
              <PrimaryButtonMedium loading={loading}>
                {t("Save")}
              </PrimaryButtonMedium>
            </>
          )}
        </Form>
      </div>
      <ConfirmDialog
        show={showDeleteConfirmDialog}
        confirmMessage={t("Are you sure you want to delete this check-box?")}
        closeDialog={() => {
          setShowDeleteConfirmDialog(false);
        }}
        handleConfirm={() => {
          onDelete();
          setShowDeleteConfirmDialog(false);
        }}
      />
    </WhiteInnerCard>
  );
};

export const ConfigureCheckboxsCard = ({ editMode }: { editMode: boolean }) => {
  const {
    storeState: { storefront_id },
    storeDispatch,
  } = useContext(Store);

  const [addNew, setAddNew] = useState(false);
  const { t } = useTranslation();

  const { data: storeFrontMetadataRes, mutate: storeFrontMetadataMutate } =
    useSWR<StorefrontMetaData, AxiosError<StorefrontMetaData>>(
      endpoints.v1_storefronts_id_metadata(storefront_id),
      {
        onSuccess: (data) => {
          storeDispatch({
            type: "SET_STOREFRONT_METADATA",
            payload: data,
          });
        },
      }
    );

  const mutateMetadata = async () => {
    setAddNew(false);
    await storeFrontMetadataMutate();
  };

  useEffect(() => {
    if (storeFrontMetadataRes?.configured_checkboxes) {
      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: storeFrontMetadataRes,
      });
    }
  }, [storeDispatch, storeFrontMetadataRes]);

  return (
    <Wrapper>
      {storeFrontMetadataRes?.configured_checkboxes?.map((item, index) => (
        <CheckboxCard
          canEdit={editMode}
          index={index + 1}
          item={item}
          mutateMetadata={mutateMetadata}
        />
      ))}
      {editMode && addNew && (
        <CheckboxCard
          canEdit={editMode}
          handleCancel={() => setAddNew(false)}
          index={
            storeFrontMetadataRes?.configured_checkboxes
              ? storeFrontMetadataRes?.configured_checkboxes.length + 1
              : 1
          }
          mutateMetadata={mutateMetadata}
          addNew
        />
      )}
      {editMode &&
        !addNew &&
        storeFrontMetadataRes?.configured_checkboxes &&
        storeFrontMetadataRes?.configured_checkboxes.length < 3 && (
          <PrimaryButtonLarge onClick={() => setAddNew(true)} type="button">
            {t("Add Check-box")}
          </PrimaryButtonLarge>
        )}
    </Wrapper>
  );
};
