import React from "react";
import {
  SuccessIcon,
  ErrorIcon,
  WarningIcon,
  InfoIcon,
  XIcon,
} from "../Icons/Icons";
import styled from "styled-components/macro";

type NotificationType = "success" | "info" | "warning" | "error";

interface INotificationProps {
  text: string;
  notificationType: NotificationType | string;
  id?: number;
  hideRemove?: boolean;
  removeNotification?: (message: string) => void;
}

export const NotificationsWrapper = styled.div`
  position: absolute;
  top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  left: 50%;
  margin: 0 0 0 -200px;
  z-index: 900;
`;

const DesktopBar = styled.div`
  display: flex;
  margin-bottom: 5px;
  & > div {
    min-height: 40px;
    padding: 10px 12px;
  }
`;

/* 
Q: Why are the font styles hardcoded?
A: because this is displayed using a 
   portal appended to document.body which is outside the the theme wrapper.

   There may be a fix for this, but given time constraints and the fact that
   we don't support custom fonts I think this is fine for now.

*/
const DesktopMessage = styled.div`
  color: white;
  font-family: "Inter";
  font-size: 13px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
`;

/* 
  I am hardcoding the colors here because I don't ever want 
  a notification to have a color that doesn't make sense for
  its content. If you are reading this in the future and believe
  that can never happen while using the theme feel free to change this.
*/

export const DesktopSuccessMessage = styled(DesktopMessage)`
  background-color: #1ba150;
`;

export const DesktopInfoMessage = styled(DesktopMessage)`
  background-color: #0073b5;
`;

export const DesktopErrorMessage = styled(DesktopMessage)`
  background-color: #b61c1c;
`;

export const DesktopWarningMessage = styled(DesktopMessage)`
  background-color: #c45113;
`;

const DesktopIconArea = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DesktopSuccessIconArea = styled(DesktopIconArea)`
  background-color: #126c36;
`;

const DesktopInfoIconArea = styled(DesktopIconArea)`
  background-color: #005688;
`;

const DesktopErrorIconArea = styled(DesktopIconArea)`
  background-color: #791313;
`;

const DesktopWarningIconArea = styled(DesktopIconArea)`
  background-color: #83360d;
`;

// const MobileBar = styled.div`
//   height: 52px;
//   width: 374px;
//   color: white;
//   display: flex;
//   align-items: center;
//   font-family: "Inter";
//   /* font-size: ${(props) => props.theme.fontSizes["small"]}; */
//   /* font-weight: ${(props) => props.theme.fontWeights["small"]}; */
//   position: relative;
// `;

// const MobileIconContainer = styled.div`
//   margin-left: 90px;
//   margin-right: 9px;
// `;

// const MobileMessage = styled.div`
//   margin-bottom: 4px;
// `;

// const CloseButtonMobile = styled.button`
//   width: 16px;
//   height: 16px;
//   border: none;
//   background: none;
//   cursor: pointer;

//   &:focus {
//     outline: none;
//   }

//   svg {
//     outline: none;
//   }

//   position: absolute;
//   top: 5px;
//   right: 10px;
// `;

const CloseButtonDesktop = styled.button`
  width: 16px;
  height: 16px;
  border: none;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  svg {
    outline: none;
  }
  align-self: flex-start;
  margin-right: 10px;
  margin-top: 5px;
`;

export const DesktopNotification = ({
  text,
  notificationType,
  id,
  hideRemove,
  removeNotification,
}: INotificationProps) => {
  switch (notificationType) {
    case "success":
      return (
        <DesktopBar>
          <DesktopSuccessIconArea>
            <SuccessIcon fill="#ffffff" />
          </DesktopSuccessIconArea>
          <DesktopSuccessMessage data-testid="notification-message-success">
            {text}
            {!hideRemove && removeNotification && (
              <CloseButtonDesktop onClick={() => removeNotification(text)}>
                <XIcon fill="#ffffff" width={16} height={16} />
              </CloseButtonDesktop>
            )}
          </DesktopSuccessMessage>
        </DesktopBar>
      );
    case "info":
      return (
        <DesktopBar>
          <DesktopInfoIconArea>
            <InfoIcon fill="#ffffff" />
          </DesktopInfoIconArea>
          <DesktopInfoMessage data-testid="notification-message-info">
            {text}
            {!hideRemove && removeNotification && (
              <CloseButtonDesktop onClick={() => removeNotification(text)}>
                <XIcon fill="#ffffff" width={16} height={16} />
              </CloseButtonDesktop>
            )}
          </DesktopInfoMessage>
        </DesktopBar>
      );
    case "error":
      return (
        <DesktopBar>
          <DesktopErrorIconArea>
            <ErrorIcon fill="#ffffff" />
          </DesktopErrorIconArea>
          <DesktopErrorMessage data-testid="notification-message-error">
            {text}
            {!hideRemove && removeNotification && (
              <CloseButtonDesktop onClick={() => removeNotification(text)}>
                <XIcon fill="#ffffff" width={16} height={16} />
              </CloseButtonDesktop>
            )}
          </DesktopErrorMessage>
        </DesktopBar>
      );
    case "warning":
      return (
        <DesktopBar>
          <DesktopWarningIconArea>
            <WarningIcon fill="#ffffff" />
          </DesktopWarningIconArea>
          <DesktopWarningMessage data-testid="notification-message-warning">
            {text}
            {!hideRemove && removeNotification && (
              <CloseButtonDesktop onClick={() => removeNotification(text)}>
                <XIcon fill="#ffffff" width={16} height={16} />
              </CloseButtonDesktop>
            )}
          </DesktopWarningMessage>
        </DesktopBar>
      );
    default:
      return null;
  }
};

// export const MobileNotification = ({
//   text,
//   notificationType,
// }: INotificationProps) => {
//   const theme = useContext(ThemeContext);

//   switch (notificationType) {
//     case "success":
//       return (
//         <MobileBar style={{ backgroundColor: theme.colors["mediumGreen"] }}>
//           <MobileIconContainer>
//             <SuccessIcon fill="#ffffff" />
//           </MobileIconContainer>
//           <MobileMessage>{text}</MobileMessage>
//           <CloseButtonMobile>
//             <XIcon fill="#ffffff" width={16} height={16} />
//           </CloseButtonMobile>
//         </MobileBar>
//       );
//     case "info":
//       return (
//         <MobileBar style={{ backgroundColor: theme.colors["blue-3"] }}>
//           <MobileIconContainer>
//             <InfoIcon fill="#ffffff" />
//           </MobileIconContainer>
//           <MobileMessage>{text}</MobileMessage>
//           <CloseButtonMobile>
//             <XIcon fill="#ffffff" width={16} height={16} />
//           </CloseButtonMobile>
//         </MobileBar>
//       );
//     case "error":
//       return (
//         <MobileBar style={{ backgroundColor: theme.colors["red-2"] }}>
//           <MobileIconContainer>
//             <ErrorIcon fill="#ffffff" />
//           </MobileIconContainer>
//           <MobileMessage>{text}</MobileMessage>
//           <CloseButtonMobile>
//             <XIcon fill="#ffffff" width={16} height={16} />
//           </CloseButtonMobile>
//         </MobileBar>
//       );
//     case "warning":
//       return (
//         <MobileBar style={{ backgroundColor: theme.colors["moreBurntOrange"] }}>
//           <MobileIconContainer>
//             <WarningIcon fill="#ffffff" />
//           </MobileIconContainer>
//           <MobileMessage>{text}</MobileMessage>
//           <CloseButtonMobile>
//             <XIcon fill="#ffffff" width={16} height={16} />
//           </CloseButtonMobile>
//         </MobileBar>
//       );
//     default:
//       return null;
//   }
// };
