import type { AxiosError } from "axios";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { endpoints } from "../../../../../endpoints";
import type { IStore } from "../../../../../Store";
import { Store } from "../../../../../Store";
import type { StorefrontMetaData } from "../../../../../types/types";
import { AutoInvoiceGeneration } from "./AutoInvoiceGeneration";
import { LeadTransactionSettings } from "./LeadTransactionSettings";

export const TransactionsTab = () => {
  const { t } = useTranslation();
  const {
    storeState: { storefront_id, edition },
    storeDispatch,
  } = useContext<IStore>(Store);

  const {
    data: storeFrontMetadataRes,
    error: storeFrontMetadataError,
    mutate: storeFrontMetadataMutate,
  } = useSWR<StorefrontMetaData, AxiosError<StorefrontMetaData>>(
    endpoints.v1_storefronts_id_metadata(storefront_id),
    {
      onSuccess: (data) => {
        storeDispatch({
          type: "SET_STOREFRONT_METADATA",
          payload: data,
        });
      },
    }
  );

  const mutateMetadata = async () => {
    await storeFrontMetadataMutate();
  };
  if (storeFrontMetadataError) {
    return <ErrorPlaceholder message={t("Failed to fetch settings data")} />;
  } else {
    return (
      <>
        <AutoInvoiceGeneration
          storefrontMetadata={storeFrontMetadataRes}
          mutateMetadata={mutateMetadata}
        />
        {edition !== "pim" && (
          <LeadTransactionSettings
            storefrontMetadata={storeFrontMetadataRes}
            mutateMetadata={mutateMetadata}
          />
        )}
      </>
    );
  }
};
