import { Fragment, useEffect } from "react";
import type {
  AssetDashboardUpdateData,
  AttributeDashboardUpdateData,
  CollectionDashboardUpdateData,
  DashboardOtherUpdate,
  GroupDashboardUpdateData,
  ListDashboardUpdateData,
  TemplateDashboardUpdateData,
  WithPagination,
} from "../../../types/types";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import {
  DashboardUpdatesHeader,
  DashboardUpdatesWrapper,
  GotoUpdate,
  KeyValueWrapper,
  UpdateEmptyState,
  UpdateKeyValuePairs,
  UpdateUpperSection,
  UpdateWrapper,
  valueArrayToString,
} from "./dashboard.util";
import { ErrorPlaceholder } from "../../../components/Error";
import {
  H3DarkText,
  H4,
  SoftHeader,
  SoftHeaderMediumDarkText,
} from "../../../components/Typography/Typography";
import { LoaderContainer } from "../../public/pages.public.util";
import { Loader } from "../../../components/Loader/Loader";
import { match } from "ts-pattern";
import moment from "moment";
import { useRoutePath } from "../../../util/Routing";
import { useHistory } from "react-router-dom";
import type { AxiosError } from "axios";
import { toTitleCase } from "../../../util/util";

export const DashboardOtherUpdates = ({
  data,
  error,
  setSize,
  size,
}: {
  data: DashboardOtherUpdate[];
  error: AxiosError<any>;
  setSize: (
    size: number | ((size: number) => number)
  ) => Promise<WithPagination<{ data: DashboardOtherUpdate[] }>[] | undefined>;
  size: number;
}) => {
  const { t } = useTranslation();
  const { ref: inViewRef, inView } = useInView({ triggerOnce: true });

  const isLoadingInitialData = !data && !error;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");

  useEffect(() => {
    if (inView) {
      setSize((size) => size + 1);
    }
  }, [inView, setSize]);
  if (error) {
    return (
      <ErrorPlaceholder
        message={t(
          "There was an error from fetching product updates. Please try again later"
        )}
      />
    );
  }
  return (
    <DashboardUpdatesWrapper>
      <DashboardUpdatesHeader>
        <H4>{t("Other Updates")}</H4>
      </DashboardUpdatesHeader>
      {data.length > 0 ? (
        data.map((update, index) => (
          <Fragment key={update.type_id + update.last_modified_date}>
            <OtherUpdateComponent updateData={update} />
            {isLoadingMore && index === data.length - 1 && (
              <LoaderContainer>
                <Loader isLoading={!!isLoadingMore} omitContainer={true} />
              </LoaderContainer>
            )}
            {index === data.length - 4 && (
              <div key={update.type_id + "observer"} ref={inViewRef} />
            )}
          </Fragment>
        ))
      ) : (
        <UpdateEmptyState>
          <H3DarkText>{t("No recent updates")}</H3DarkText>
        </UpdateEmptyState>
      )}
    </DashboardUpdatesWrapper>
  );
};

function OtherUpdateComponent({
  updateData,
}: {
  updateData: DashboardOtherUpdate;
}) {
  const { t } = useTranslation();
  const { update_data, modified_by } = updateData;
  const { adminPath } = useRoutePath();
  const history = useHistory();

  const statusTextAssignment = (
    status: "archived" | "deleted" | "created" | "updated",
    updateType: string
  ) =>
    status === "created"
      ? `${t("New")} ${updateType} ${t("created")}` // only way it makes sense to translate it here. Better translated individually than not.
      : status === "updated"
      ? `${updateType} ${t("updated")}`
      : `${updateType} ${t("archived")}`;

  const statusText = (() => {
    try {
      return match(updateData.type)
        .with("assets", () =>
          statusTextAssignment(update_data.status, t("Asset"))
        )
        .with("attribute", () =>
          statusTextAssignment(update_data.status, t("Attribute"))
        )
        .with("collection", () =>
          statusTextAssignment(update_data.status, t("Attribute Collection"))
        )
        .with("group", () =>
          statusTextAssignment(update_data.status, t("Attribute Group"))
        )
        .with("list", () =>
          statusTextAssignment(update_data.status, t("List Value"))
        )
        .with("product", () =>
          statusTextAssignment(update_data.status, t("Product"))
        )
        .with("template", () =>
          statusTextAssignment(update_data.status, t("Template"))
        )
        .exhaustive();
    } catch (error) {
      console.error(`Update type ${updateData.type} not expected`);
      return statusTextAssignment(
        update_data.status,
        toTitleCase(updateData.type)
      );
    }
  })();

  const keyValuePairs = (() => {
    try {
      return match(updateData.type)
        .with("assets", () => [
          { label: t("Asset Name"), value: update_data.name ?? "--" },
          {
            label: t("File Name"),
            value: (update_data as AssetDashboardUpdateData).file_name ?? "--",
          },
          {
            label: t("Asset Type"),
            value: (update_data as AssetDashboardUpdateData).asset_type ?? "--",
          },
          {
            label: t("Category"),
            value:
              (update_data as AssetDashboardUpdateData).asset_category ?? "--",
          },
          {
            label: t("Language"),
            value: (update_data as AssetDashboardUpdateData).language ?? "--",
          },
        ])
        .with("attribute", () => [
          {
            label: t("Name"),
            value: (update_data as AttributeDashboardUpdateData).name ?? "--",
          },
          {
            label: t("Display Name"),
            value: (update_data as AttributeDashboardUpdateData).display_name
              ? t([(update_data as AttributeDashboardUpdateData).display_name])
              : "--",
          },
          {
            label: t("Short Description"),
            value: (update_data as AttributeDashboardUpdateData).description
              ? t([(update_data as AttributeDashboardUpdateData).description])
              : "--",
          },
          {
            label: t("Type"),
            value: (update_data as AttributeDashboardUpdateData).type ?? "--",
          },
        ])
        .with("collection", () => {
          const updateData = update_data as CollectionDashboardUpdateData;
          const colArr = [
            { label: t("Name"), value: update_data.name ?? "--" },
            {
              label: t("Display Name"),
              value: (update_data as CollectionDashboardUpdateData).display_name
                ? t([
                    (update_data as CollectionDashboardUpdateData).display_name,
                  ])
                : "--",
            },
          ];
          if (
            updateData.attributes_added &&
            updateData.attributes_added.length > 0
          ) {
            colArr.push({
              label: t("Attributes Added"),
              value: valueArrayToString(
                (update_data as CollectionDashboardUpdateData).attributes_added!
              ),
            });
          }
          if (
            updateData.attributes_removed &&
            updateData.attributes_removed.length > 0
          ) {
            colArr.push({
              label: t("Attributes Removed"),
              value: valueArrayToString(
                (update_data as CollectionDashboardUpdateData)
                  .attributes_removed!
              ),
            });
          }
          return colArr;
        })
        .with("group", () => {
          const updateData = update_data as GroupDashboardUpdateData;
          const groupArr = [
            { label: t("Name"), value: update_data.name },
            {
              label: t("Display Name"),
              value: (update_data as GroupDashboardUpdateData).display_name
                ? t([(update_data as GroupDashboardUpdateData).display_name])
                : "--",
            },
          ];
          if (
            updateData.attributes_added &&
            updateData.attributes_added.length > 0
          ) {
            groupArr.push({
              label: t("Attributes Added"),
              value: valueArrayToString(
                (update_data as GroupDashboardUpdateData).attributes_added!
              ),
            });
          }
          if (
            updateData.attributes_removed &&
            updateData.attributes_removed.length > 0
          ) {
            groupArr.push({
              label: t("Attributes Removed"),
              value: valueArrayToString(
                (update_data as GroupDashboardUpdateData).attributes_removed!
              ),
            });
          }
          return groupArr;
        })
        .with("list", () => {
          const updateData = update_data as ListDashboardUpdateData;
          const listArr = [
            { label: t("Name"), value: update_data.name ?? "--" },
            {
              label: t("Display Name"),
              value:
                (update_data as ListDashboardUpdateData).display_name ?? "--",
            },
          ];
          if (updateData.values_added && updateData.values_added.length > 0) {
            listArr.push({
              label: t("Values Added"),
              value: valueArrayToString(
                (update_data as ListDashboardUpdateData).values_added!
              ),
            });
          }
          if (
            updateData.values_removed &&
            updateData.values_removed.length > 0
          ) {
            listArr.push({
              label: t("Values Removed"),
              value: valueArrayToString(
                (update_data as ListDashboardUpdateData).values_removed!
              ),
            });
          }
          return listArr;
        })
        .with("product", () => [])
        .with("template", () => [
          { label: t("Name"), value: update_data.name ?? "--" },
          {
            label: update_data.status === "created" ? t("Added") : t("Updated"),
            value: valueArrayToString(
              (update_data as TemplateDashboardUpdateData).updated
            ),
          },
        ])
        .exhaustive();
    } catch (error) {
      console.error(`Update type ${updateData.type} not expected`);
      return [];
    }
  })();

  const time = moment(new Date(updateData.last_modified_date)).fromNow();

  const handleUpdateClick = () => {
    const basePath = `${adminPath}/pim`;
    try {
      match(updateData.type)
        .with("assets", () => {
          history.push(`${basePath}/assets?from=dashboard`);
        })
        .with("attribute", () => {
          history.push(
            `${basePath}/attributes/${updateData.type_id}?from=dashboard`
          );
        })
        .with("collection", () => {
          history.push(
            `${basePath}/attributes/collections/${updateData.type_id}?from=dashboard`
          );
        })
        .with("group", () => {
          history.push(
            `${basePath}/attributes/groups/${updateData.type_id}?from=dashboard`
          );
        })
        .with("list", () => {
          const params = new URLSearchParams();
          if (updateData.update_data.status === "archived") {
            params.append("selectedArchivedListID", updateData.type_id);
          } else {
            params.append("selectedListID", updateData.type_id);
          }
          history.push(`${basePath}/lists?${params}`);
        })
        .with("product", () => {
          history.push(
            `${basePath}/products/${updateData.type_id}?from=dashboard`
          );
        })
        .with("template", () => {
          history.push(
            `${basePath}/templates/${updateData.type_id}/activity?from=dashboard`
          );
        })
        .exhaustive();
    } catch (error) {
      console.error(`Update type ${updateData.type} not expected`);
    }
  };
  return (
    <UpdateWrapper onClick={handleUpdateClick}>
      <UpdateUpperSection>
        <KeyValueWrapper>
          <SoftHeaderMediumDarkText>{statusText}</SoftHeaderMediumDarkText>
          <SoftHeader>{`${modified_by.name} | ${modified_by.role}`}</SoftHeader>
        </KeyValueWrapper>
        <KeyValueWrapper style={{ alignItems: "flex-end" }}>
          <SoftHeader>{time}</SoftHeader>
        </KeyValueWrapper>
      </UpdateUpperSection>
      <UpdateKeyValuePairs data={keyValuePairs} />
      <GotoUpdate />
    </UpdateWrapper>
  );
}
