import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components/macro";
import type { ArrayField, useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { H3 } from "../Typography/Typography";
import { TextField } from "../TextFields/TextFields";
import { TextArea } from "../TextArea/TextArea";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  SecondaryButtonMedium,
} from "../Buttons/Buttons";
import { Card } from "../../layout/publicPageLayout";
import { PlusIcon } from "../Icons/Icons";
import { useSaveHomePage } from "../../pages/public/HomePage/useSaveHomePage";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { isAxiosError, useFormWrapper, useStoreState } from "../../util/util";
import { Notifications } from "../Notifications/NotificationsContext";
import type { IProductImage } from "../../types/types";
import Axios from "axios";
import { ToggleGroup } from "../ToggleGroups/ToggleGroups";
import { FilePicker } from "../FilePicker/FilePicker";
import { FileCardUploading } from "../FileCard/FileCard";
import type { IPartnersData } from ".";
import { useTranslation } from "react-i18next";
import * as zod from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { zodRequiredString } from "../../util/zod.util";

export interface IPartnersEditData {
  homePageData: IHomePageData;
  handleEditSubmit: () => void;
}

interface IPartnerImageUploadProps {
  item: Partial<ArrayField<Record<string, any>, "id">>;
  index: number;
  useFormMethods: Pick<
    ReturnType<typeof useForm>,
    "register" | "errors" | "formState" | "setValue"
  >;
}

type ImageSource = "Upload Image" | "Insert Image URL";
const TOGGLE_IMAGE_SOURCE: ImageSource[] = ["Upload Image", "Insert Image URL"];

type LogoItem = {
  alt: string;
  imageSrc: string;
  id?: string;
  targetUrl: string;
};
type formInputs = {
  title: string;
  desc: string;
  id?: string;
  logos: LogoItem[];
};

const FormInputs = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 15px;
  }
  textarea {
    resize: vertical;
  }

  & > ${Card} {
    position: relative;
    & > div {
      margin: 15px 0 15px;
      &:first-child {
        margin-top: 25px;
      }
    }
  }
`;

const AddPartnerButton = styled(SecondaryButtonMedium)`
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

/**
 * Bug fixes as at 07 July, 2022
 * add text to partner images
 * Fix the following bugs on edit partner page:
 * on upload of image, previously filled fields are deleted.
 * on delete of a partner, it messes with the image url of other partners
 * @param param0
 * @returns
 */
export const EditPartners = ({
  homePageData,
  handleEditSubmit,
}: IPartnersEditData) => {
  const component = homePageData.config.components.find(
    (component) => component.name === "partners"
  );
  const { t } = useTranslation();
  const { saveHomePage } = useSaveHomePage();
  const [loading, setLoading] = useState(false);

  const EditPartnersSchema = zod.object({
    title: zod.string().optional(),
    desc: zod.string().optional(),
    id: zod.string().optional(),
    logos: zod.array(
      zod.object({
        alt: zodRequiredString(t),
        imageSrc: zod.string().url(t("This must match a valid url pattern")),
        targetUrl: zod.string().optional(),
      })
    ),
  });

  const { register, handleSubmit, control, formState, errors, setValue } =
    useFormWrapper({
      resolver: zodResolver(EditPartnersSchema),
      defaultValues: {
        logos: (component?.data as IPartnersData)?.logos,
      },
      shouldUnregister: false,
    });
  const theme = useContext(ThemeContext);
  const { fields, remove, append } = useFieldArray({
    control,
    name: "logos",
  });

  const createFields = (): JSX.Element[] => {
    return fields.map((item, index: number) => (
      <Card key={index} style={{ position: "relative" }}>
        <TextField
          key={`${item.id}name`}
          name={`logos[${index}].alt`}
          label={t("Partner Name")}
          type={"text"}
          theref={register()}
          defaultValue={item.alt}
          errors={errors}
          error={errors?.logos?.[index]?.alt}
          formState={formState}
        />
        <ImageFileUpload
          item={item}
          index={index}
          useFormMethods={{ register, formState, setValue, errors }}
        />

        <TextField
          key={`${item.id}url`}
          name={`logos[${index}].targetUrl`}
          label={t("Partner Link")}
          type={"text"}
          theref={register()}
          defaultValue={item.targetUrl}
          errors={errors}
          formState={formState}
        />
        <DeleteButton
          testid={`remove-logo-${item.targetUrl}`}
          type="button"
          onClick={() => remove(index)}
        />
      </Card>
    ));
  };

  const onSubmit = async (values: formInputs) => {
    setLoading(true);
    if (component) {
      component.data = values;
    } else {
      homePageData.config.components.push({
        name: "partners",
        data: values,
      });
    }

    const success = await saveHomePage(homePageData);
    setLoading(false);
    if (success) {
      handleEditSubmit();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <H3>{t("Edit Partners")}</H3>
        <FormInputs>
          <TextField
            label={t("Title")}
            name="title"
            theref={register({ required: false })}
            errors={errors}
            defaultValue={component?.data.title}
            formState={formState}
            type="text"
          />
          <TextArea
            label={t("Description")}
            name="desc"
            type="text"
            defaultValue={component?.data.desc}
            required={false}
            formState={formState}
            theref={register({
              required: false,
            })}
          />
          {createFields()}
          <AddPartnerButton
            onClick={() => append({ alt: "", imageSrc: "", targetUrl: "" })}
            type="button"
          >
            <PlusIcon width={12} fill={theme.secondaryButtonTextColor} />{" "}
            {t("Add Partner")}
          </AddPartnerButton>
          <PrimaryButtonFitContainer type="submit" loading={loading}>
            {t("Save your changes")}
          </PrimaryButtonFitContainer>
        </FormInputs>
      </form>
    </>
  );
};

const ImageFileUpload = ({
  item,
  index,
  useFormMethods: { errors, register, setValue, formState },
}: IPartnerImageUploadProps) => {
  const [uploadingFile, setUploadingFile] = useState<File>();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { tenant_id } = useStoreState();
  const [imageSource, setImageSource] = useState<ImageSource>("Upload Image");
  const changeImageSource = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    source: ImageSource
  ) => {
    event.preventDefault();
    setImageSource(source);
  };
  const handleFile = async (file: File, index: number) => {
    setUploadingFile(file);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await Axios.post<IProductImage>(
        `/v1/storefronts/${tenant_id}/images`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      setUploadingFile(undefined);
      setValue(`logos[${index}].imageSrc`, response.data.cdn_url);
      notifySuccess("Image uploaded successfully");
    } catch (error) {
      let notifyMessage = "Upload failed, something went wrong";

      if (isAxiosError(error)) {
        const message = error.response?.data.message;
        if (message) {
          notifyMessage = `Upload failed. ${message}`;
        }
      }

      notifyError(notifyMessage, { error });
      setUploadingFile(undefined);
    }
  };

  return (
    <>
      <ToggleGroup
        names={TOGGLE_IMAGE_SOURCE}
        activeButton={imageSource}
        clickHandler={changeImageSource}
        style={{ margin: "10px 0" }}
      />
      {!uploadingFile && (
        <TextField
          label={t("Logo URL")}
          key={`${item.id}image`}
          name={`logos[${index}].imageSrc`}
          theref={register()}
          defaultValue={item?.imageSrc ?? ""}
          errors={errors}
          error={errors?.logos?.[index]?.imageSrc}
          formState={formState}
          type="text"
          style={{ margin: "20px 0" }}
          readOnly={imageSource === "Upload Image"}
        />
      )}
      {imageSource === "Upload Image" && (
        <>
          {!uploadingFile && index >= 0 && (
            <FilePicker handleFile={(file) => handleFile(file, index)} />
          )}
          {uploadingFile && <FileCardUploading fileName={uploadingFile.name} />}
        </>
      )}
    </>
  );
};
