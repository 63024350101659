import React, { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../../../components/SelectBoxV2/SelectBoxV2";
import type {
  MethodsOfUseForm,
  OptionType,
  StorefrontCustomizableFormSchema,
} from "../../../../../../types/types";
import { strings } from "../../../../../../util/strings";
import { useTranslation } from "react-i18next";

export function OutsideLoginCustomFields({
  fields,
  methodsOfUseForm,
}: {
  fields: StorefrontCustomizableFormSchema;
  methodsOfUseForm: MethodsOfUseForm;
}) {
  const [firstChoice, setFirstChoice] = useState<OptionType | null>(null);
  const { formState, control, errors, setValue, watch } = methodsOfUseForm;

  const { t } = useTranslation();

  const formFields = watch();

  const firstFieldValue = watch(
    `arbitrary_fields[${fields.custom_fields[0].name}]`,
    {
      label: "",
      value: "",
    }
  );
  const secondFieldValue = fields.custom_fields?.[1]?.name
    ? watch(`arbitrary_fields[${fields.custom_fields[1].name}]`, {
        label: "",
        value: "",
      })
    : undefined;

  const fieldToOptionType = (obj: any): OptionType => {
    return { label: obj.choice_name, value: obj.choice_name };
  };

  const isDependent = useMemo(
    () => fields.dependent_choices && fields?.dependent_choices?.length > 0,
    [fields.dependent_choices]
  );

  const getFirstFieldOptions = () => {
    if (isDependent) {
      return fields
        .dependent_choices!.map(fieldToOptionType)
        .filter((obj) => obj.label !== "");
    } else return fields.custom_fields[0].choices.map(fieldToOptionType);
  };

  const getSecondFieldOptions = () => {
    const firstFieldValue =
      formFields?.arbitrary_fields?.[fields.custom_fields[0].name]?.label;

    if (firstFieldValue && isDependent) {
      const options: OptionType[] = fields.dependent_choices!.reduce(
        (acc, obj) => {
          if (obj.choice_name === firstFieldValue) {
            acc = [
              ...obj.choices
                .map(fieldToOptionType)
                .filter((obj) => obj.label !== ""),
            ];
          }
          return acc;
        },
        [] as OptionType[]
      );

      return options.length > 0 ? options : [];
    } else return fields.custom_fields[1].choices.map(fieldToOptionType);
  };

  const getThirdFieldOptions = () => {
    const firstFieldValue =
      formFields?.arbitrary_fields?.[fields.custom_fields[0].name]?.label;

    const secondFieldValue =
      formFields?.arbitrary_fields?.[fields.custom_fields[1].name]?.label;

    if (firstFieldValue && secondFieldValue && isDependent) {
      const secondLevel = fields.dependent_choices!.reduce(
        (acc, first_level_obj) => {
          first_level_obj.choices.forEach((second_level_obj) => {
            if (
              first_level_obj.choice_name === firstFieldValue &&
              second_level_obj.choice_name === secondFieldValue
            ) {
              acc = [...acc, second_level_obj];
            }
          });
          return acc;
        },
        [] as any
      );

      const options: OptionType[] = secondLevel.reduce((acc: any, obj: any) => {
        if (obj.choice_name === secondFieldValue) {
          acc = [
            ...obj.choices
              .map(fieldToOptionType)
              .filter((obj: any) => obj.label !== ""),
          ];
        }
        return acc;
      }, [] as OptionType[]);

      return options.length > 0 ? options : [];
    } else return fields.custom_fields[2].choices.map(fieldToOptionType);
  };

  useEffect(() => {
    if (firstFieldValue.value && isDependent) {
      if (fields.custom_fields?.[1]?.name) {
        setValue(`arbitrary_fields[${fields.custom_fields[1].name}]`, {
          label: "",
          value: "",
        });
      }
      if (fields.custom_fields?.[2]?.name) {
        setValue(`arbitrary_fields[${fields.custom_fields[2].name}]`, {
          label: "",
          value: "",
        });
      }
    }
  }, [fields.custom_fields, firstFieldValue, isDependent, setValue]);

  useEffect(() => {
    if (secondFieldValue.value && isDependent) {
      if (fields.custom_fields?.[2]?.name) {
        setValue(`arbitrary_fields[${fields.custom_fields[2].name}]`, {
          label: "",
          value: "",
        });
      }
    }
  }, [fields.custom_fields, isDependent, secondFieldValue, setValue]);

  return (
    <>
      {/* disabling this warning because this can only ever have a max of 3 items in it, and even if you give the type a fixed length this warning still comes up. */}
      {/* eslint-disable-next-line array-callback-return */}
      {fields.custom_fields.map((field, index) => {
        // react hook forms `required` prop is either a string or a boolean,
        // so we can't set it dynamically any other way
        const validate = (formValue?: { label: string; value: string }) =>
          field.is_required
            ? !!formValue?.value
              ? true
              : strings(t).thisIsARequiredField
            : true;

        if (index === 0) {
          return (
            <Controller
              key={index}
              as={SelectBoxV2}
              onInputChange={(value: OptionType) => setFirstChoice(value)}
              value={firstChoice}
              control={control}
              name={`arbitrary_fields[${field.name}]`}
              placeholder={field.name}
              options={getFirstFieldOptions()}
              rules={{
                validate,
              }}
              errors={errors}
              error={
                errors?.arbitrary_fields?.[field.name]
                  ? errors?.arbitrary_fields?.[field.name]
                  : null
              }
              formState={formState}
            />
          );
        }
        if (index === 1) {
          return (
            <Controller
              key={index}
              as={SelectBoxV2}
              control={control}
              name={`arbitrary_fields[${field.name}]`}
              defaultValue={{ label: "", value: "" }}
              placeholder={field.name}
              options={getSecondFieldOptions()}
              rules={{
                validate,
              }}
              errors={errors}
              error={
                errors?.arbitrary_fields?.[field.name]
                  ? errors?.arbitrary_fields?.[field.name]
                  : null
              }
              formState={formState}
              isDisabled={
                isDependent &&
                !formFields?.arbitrary_fields?.[
                  fields.custom_fields[index - 1].name
                ]?.value
              }
            />
          );
        }
        if (index === 2) {
          return (
            <Controller
              key={index}
              as={SelectBoxV2}
              control={control}
              name={`arbitrary_fields[${field.name}]`}
              defaultValue={{ label: "", value: "" }}
              placeholder={field.name}
              options={getThirdFieldOptions()}
              rules={{
                validate,
              }}
              errors={errors}
              error={
                errors?.arbitrary_fields?.[field.name]
                  ? errors?.arbitrary_fields?.[field.name]
                  : null
              }
              formState={formState}
              isDisabled={
                isDependent &&
                !formFields?.arbitrary_fields?.[
                  fields.custom_fields[index - 1].name
                ]?.value
              }
            />
          );
        }
      })}
    </>
  );
}
