import { useTranslation } from "react-i18next";
import { Card } from "../../layout/portalPageLayout";
import { Row } from "../Layout/Layout";
import { H3 } from "../Typography/Typography";
import { PrimaryButtonMedium } from "../Buttons/Buttons";
import { DocumentView } from "./DocumentView";
import type {
  IOrderItem,
  OrderStatus,
  QuoteRequestItem,
  QuoteStatus,
  SampleRequestDetail,
  SampleRequestStatus,
  TransactionDocument,
} from "../../types/types";
import { useAuthContext } from "../Auth";

type DocumentViewItem = QuoteRequestItem | IOrderItem | SampleRequestDetail;

export const TransactionsDocumentView = ({
  items,
  seller_documents,
  buyer_documents,
  onAddDocumentClick,
  status,
  buyer_role,
}: {
  items: DocumentViewItem[];
  seller_documents: TransactionDocument[];
  buyer_documents: TransactionDocument[];
  onAddDocumentClick: () => void;
  status: OrderStatus | QuoteStatus | SampleRequestStatus;
  buyer_role: "buyer" | "distributor";
}) => {
  const { t } = useTranslation();
  const { roleIsSomeKindOfBuyer, roleIsSomeKindOfSeller } = useAuthContext();
  return (
    <>
      <Card>
        <Row>
          <H3>{t("Seller-Uploaded Documents")}</H3>
          {status !== "completed" &&
            status !== "pending" &&
            roleIsSomeKindOfSeller && (
              <PrimaryButtonMedium onClick={onAddDocumentClick}>
                {t("Add")}
              </PrimaryButtonMedium>
            )}
        </Row>
        <DocumentView
          items={items}
          seller_or_buyer_documents={seller_documents}
        />
      </Card>
      <Card>
        <Row>
          <H3>
            {buyer_role === "distributor"
              ? t("Distributor-Uploaded Documents")
              : t("Buyer-Uploaded Documents")}
          </H3>
          {status !== "completed" &&
            status !== "pending" &&
            roleIsSomeKindOfBuyer && (
              <PrimaryButtonMedium onClick={onAddDocumentClick}>
                {t("Add")}
              </PrimaryButtonMedium>
            )}
        </Row>
        <DocumentView items={[]} seller_or_buyer_documents={buyer_documents} />
      </Card>
    </>
  );
};
