import type { AxiosError } from "axios";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";
import {
  GoBackButtonSmall,
  InvisibleButton,
} from "../../../components/Buttons/Buttons";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { endpoints } from "../../../endpoints";
import type { PIMProduct } from "../../../types/types.PIM";
import {
  ErrorWithStatus,
  useStoreState,
  makeUrlWithParams,
  PIM_PRODUCT_DETAILS_PORTFOLIO_TAB,
} from "../../../util/util";
import { PIMProductDetailsPortfolio } from "../../public/ProductDetailsPortfolio/PIMProductDetailsPortfolio";
import { PageWrapper } from "../../../layout/portalPageLayout";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRoutePath } from "../../../util/Routing";

export const BuyerProductDetailPortfolio = () => {
  const { productSlug: productId } = useParams<{ productSlug: string }>();
  const [backToProductList, setbackToProductList] = useState(false);
  const { adminPath } = useRoutePath();
  const history = useHistory();
  const { t } = useTranslation();

  const { tenant_id } = useStoreState();

  // We cant annotate the type inline while destucturing but "mutate"
  // here is type ProductMutate
  const {
    data: product,
    error: productError,
    mutate: mutateProduct,
  } = useSWR<PIMProduct, AxiosError>(
    productId
      ? makeUrlWithParams(
          endpoints.v2_tenants_id_pim_products_id(tenant_id, productId),
          { use_database: "true" }
        )
      : null,
    {
      revalidateOnFocus: false,
    }
  );

  const errorWithStatus = productError
    ? new ErrorWithStatus(
        Number(productError?.code ?? 0),
        productError?.message ?? ""
      )
    : undefined;

  const onBackClick = () => {
    localStorage.removeItem(PIM_PRODUCT_DETAILS_PORTFOLIO_TAB);
    if (backToProductList) {
      history.push(`${adminPath}/pim/products`);
    } else {
      history.goBack();
    }
  };

  return !product ? (
    <DelayedSpinner />
  ) : (
    <PageWrapper>
      <div style={{ marginBottom: "13px" }}>
        <InvisibleButton onClick={onBackClick}>
          <GoBackButtonSmall text={t("Back")} />
        </InvisibleButton>
      </div>
      <PIMProductDetailsPortfolio
        product={product}
        error={errorWithStatus}
        mutateProduct={mutateProduct}
        setbackToProductList={setbackToProductList}
        can_set_tab_query={false}
      />
    </PageWrapper>
  );
};
