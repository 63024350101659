import React, { useContext, useState } from "react";
import { Notifications } from "../Notifications/NotificationsContext";
import { useFieldArray } from "react-hook-form";
import { useFormWrapper } from "../../util/util";
import { Title } from "../Typography/Typography";
import { Form } from "../../layout/FormLayout";
import { TextField } from "../TextFields/TextFields";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  PrimaryButtonSmall,
} from "../Buttons/Buttons";
import styled from "styled-components/macro";
import { TextArea } from "../TextArea/TextArea";
import { Card } from "../../layout/portalPageLayout";
import { ToggleSwitch } from "../ToggleSwitch/ToggleSwitch";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { useSaveHomePage } from "../../pages/public/HomePage/useSaveHomePage";

const Row = styled.div`
  display: flex;
  flex-direction: row;

  :first-child input {
    width: 375px;
  }
  > * {
    flex: 0 0 auto;
  }
  div {
    margin-right: 10px;
  }
`;

const RightSide = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MarginBottom = styled.div`
  > * {
    margin-bottom: 15px;
  }
`;

type formInputs = {
  header: string;
  accordion: { title: string; text: string }[];
  multiple_sections_openable: boolean;
  all_sections_collapsible: boolean;
};

export function EditAccordion({
  components,
  endEditMode,
}: {
  components: IHomePageData;
  endEditMode: () => void;
}) {
  const { notifyError } = useContext(Notifications);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { saveHomePage } = useSaveHomePage();

  const component = components.config.components.find(
    (component) => component.name === "accordion"
  );

  const data = component?.data;
  const header = data?.header;
  const accordionContent = data?.accordionContent ?? [];

  const { register, handleSubmit, control, formState, errors } = useFormWrapper(
    {
      defaultValues: {
        accordion: accordionContent,
        ...(header
          ? // cannot use the shorthand or we get the error
            // "Spread types may only be created from object types."
            { header: header }
          : { header: "Frequently Asked Questions" }),
        all_sections_collapsible: data?.collapsible,
        multiple_sections_openable: data?.multiple,
      },
    }
  );

  const { fields, remove, append } = useFieldArray({
    control,
    name: "accordion",
  });

  const createFields = (): JSX.Element[] => {
    return fields.map((item, index: number) => (
      <Card key={item.id || index + "FormRow"} style={{ position: "relative" }}>
        <MarginBottom>
          <Row>
            <TextField
              key={item.id}
              name={`accordion[${index}].title`}
              label="Title"
              type={"text"}
              theref={register()}
              defaultValue={item.title}
              errors={errors}
              formState={formState}
            />
            <DeleteButton
              testid={`delete-item-${item.id}`}
              type="button"
              onClick={() => remove(index)}
            />
          </Row>
        </MarginBottom>
        <div />
        <TextArea
          key={item.id}
          name={`accordion[${index}].text`}
          label="Content"
          theref={register()}
          formState={formState}
          required
          defaultValue={item.text}
        />
      </Card>
    ));
  };

  const onSubmit = async (input: formInputs) => {
    const data = {
      header: input.header,
      accordionContent: input.accordion?.length > 0 ? [...input.accordion] : [],
      collapsible: input.all_sections_collapsible,
      multiple: input.multiple_sections_openable,
    };

    if (component) {
      component.data = data;
    }

    try {
      setIsSubmitting(true);
      const success = await saveHomePage(components);
      if (success) {
        setIsSubmitting(false);
        endEditMode();
      }
    } catch (error) {
      notifyError("There was an error updating the homepage", { error });
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      {/* Ye asked me to specifically call this FAQ instead of accordion.
      In most (all?) cases that is probably what it will be used for.*/}
      <Title>Edit FAQ</Title>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          name={"header"}
          label={"Header"}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <div>
          <ToggleSwitch
            label={"Allow multiple sections to be open simultaneously"}
            name="multiple_sections_openable"
            theref={register({ required: false })}
          />
        </div>
        <div>
          <ToggleSwitch
            label={"Allow all sections to be collapsed simultaneously"}
            name="all_sections_collapsible"
            theref={register({ required: false })}
          />
        </div>

        {createFields()}

        <RightSide style={{ margin: "13px 0px" }}>
          <PrimaryButtonSmall
            type="button"
            onClick={() => append({ title: "", text: "" })}
          >
            Add Section
          </PrimaryButtonSmall>
        </RightSide>
        <PrimaryButtonFitContainer loading={isSubmitting}>
          Save Changes
        </PrimaryButtonFitContainer>
      </Form>
    </div>
  );
}
