import React, { useContext, useEffect } from "react";
import type { StorefrontMetaData } from "../../../../../../types/types";
import type { AxiosError } from "axios";
import Axios from "axios";
import { StorefrontPolicyDocumentUpload } from "../../../../../../components/StorefrontPolicyDocumentUpload/StorefrontPolicyDocumentUpload";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import { endpoints } from "../../../../../../endpoints";
import { Store } from "../../../../../../Store";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { usePolicyDocuments } from "../../../../../../util/util";

export const EditTermsOfSale = () => {
  const {
    storeDispatch,
    storeState: { storefront_id },
  } = useContext(Store);
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const { termsOfSalePolicy } = usePolicyDocuments();

  const { data: metadataResponse, mutate: mutateStorefrontMetadata } = useSWR<
    StorefrontMetaData,
    AxiosError<StorefrontMetaData>
  >(endpoints.v1_storefronts_id_metadata(storefront_id));

  const handleMetadataChange = async () => {
    try {
      const metadataResponse = await Axios.patch<StorefrontMetaData>(
        endpoints.v1_storefronts_id_metadata(storefront_id),
        { use_custom_terms_of_sale: true }
      );

      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: metadataResponse.data,
      });
      await mutateStorefrontMetadata();
      notifySuccess(t("Your changes have been saved"));
    } catch (error) {
      const message = (error as AxiosError)?.response?.data?.message;
      notifyError(
        message
          ? t(
              "There was an error saving your changes. {{axios_error_message}}",
              { axios_error_message: message }
            )
          : t("There was an error saving your changes"),
        { error }
      );
    }
  };

  useEffect(() => {
    if (metadataResponse) {
      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: metadataResponse,
      });
    }
  }, [metadataResponse, storeDispatch]);

  return (
    <>
      <StorefrontPolicyDocumentUpload
        id={"termsUpload"}
        documents={!!termsOfSalePolicy ? [termsOfSalePolicy] : []}
        type={"terms_of_sale"}
        hideUploader={!!termsOfSalePolicy}
        handleMetadataChange={handleMetadataChange}
      />
    </>
  );
};
