import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import type { SortingRule } from "react-table";
import useSWR from "swr";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { useAuthContext } from "../../../components/Auth";
import {
  PrimaryButtonWithPlusIcon,
  SecondaryButtonMedium,
} from "../../../components/Buttons/Buttons";
import { CreateNewTenantFormContainer } from "../../../components/CreateNewTenantForm/CreateNewTenantFormContainer";
import { DropDown } from "../../../components/DropDown/DropDown";
import { CustomerDistributorDropdownContainer } from "../../../components/Layout/Layout";
import { Modal } from "../../../components/Modal/Modal";
import { Pagination } from "../../../components/Pagination/Pagination";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import { Table } from "../../../components/Table/Table";
import { endpoints } from "../../../endpoints";
import {
  ContentWrapper,
  HorizontalButtonsContainer,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../layout/portalPageLayout";
import type {
  ITenantPaginatedOutputResponse,
  Tenant,
} from "../../../types/types";
import { useDebounce } from "../../../util/hooks";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import {
  defaultHandleSort,
  formatAddressCityState,
  getRelativeTime,
  makeUrlWithParams,
  useStoreState,
} from "../../../util/util";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { DownloadReport } from "../../SharedPages/SellerOrdersListPage/DownloadReport/DownloadReport";

type DistributorsTable = {
  name: string;
  location: string;
  id: string;
};

export const DistributorsListPage = providePrivatePageProps(({ user }) => {
  const { roleIsSomeKindOfSeller } = useAuthContext();
  const { storefront_id } = useStoreState();

  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });
  const perPageItems = [10, 20, 50, 100];
  const [perPage, setPerPage] = useState(query?.perPage ?? perPageItems[0]);
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState<DistributorsTable[]>([]);
  const history = useHistory();
  const { adminPath } = useRoutePath();
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });

  const [offset, setOffset] = useState(query.offset ?? 0);
  const [pagination, setPagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });

  const [downloadReportSlideOut, setDownloadReportSlideOut] = useState(false);
  const closeDownloadReportSlideout = () => setDownloadReportSlideOut(false);

  const { data: tenantsResponse, error: tenantsError } = useSWR<
    ITenantPaginatedOutputResponse | undefined
  >(
    makeUrlWithParams(
      endpoints.v1_storefronts_id_tenants_id_tenants(
        storefront_id,
        user.tenant_id
      ),
      {
        offset: offset,
        limit: perPage,
        q: debouncedSearchQuery,
        // Don't let sort_by or order_by be empty strings.
        sort_by: sortingRules.sortBy || null,
        order_by: sortingRules.orderBy || "asc",
        tenant_type: "Distributor",
      }
    )
  );

  const handleSort = async (rules: SortingRule<object>[]) => {
    if (rules.length > 0) {
      const [rule, ...rest] = rules;
      const newRule = {
        id: rule.id === "location" ? "city" : rule.id,
        desc: rule.desc,
      };
      return defaultHandleSort(
        [newRule, ...rest],
        sortingRules,
        setSortingRules,
        setTableData
      );
    }
    return defaultHandleSort(
      rules,
      sortingRules,
      setSortingRules,
      setTableData
    );
  };

  useEffect(() => {
    if (tenantsResponse) {
      const { data: tenants, pagination } = tenantsResponse;

      setTableData(
        tenants.map((tenant: Tenant) => {
          const hqAddress = tenant.addresses.find(
            (address) => address.type === "Headquarters Address"
          );
          return {
            name: tenant.name,
            modified_at: getRelativeTime(tenant.modified_at),
            location: hqAddress ? formatAddressCityState(hqAddress) : "--",
            external_id: tenant.external_identity?.external_id || "--",
            id: tenant.id,
          };
        })
      );
      setPagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    }
  }, [tenantsResponse, setTableData, perPage]);

  const isLoading = !tenantsResponse && !tenantsError;

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const handlePageClick = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const handleRowClick = (e: React.MouseEvent): void => {
    history.push(
      makeUrlWithParams(`${adminPath}/distributors/${e.currentTarget.id}`, {
        q: query.q,
        offset,
        perPage,
      })
    );
  };

  useEffect(() => {
    // This useEffect handles keeping the query in sync with the URL.
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  useEffect(() => {
    setQuery({ offset, perPage });
  }, [offset, perPage, setQuery]);

  const columns = useMemo(
    () => [
      {
        accessor: "external_id",
        Header: t("Distributor ID"),
      },
      {
        accessor: "name",
        Header: t("Company Name"),
      },
      { accessor: "modified_at", Header: t("Last Activity") },
      {
        accessor: "location",
        Header: t("Location"),
        align: "right",
      },
      // TODO: these are commented out until the API supports it.
      // { accessor: "status", Header: strings.status, Cell: statusCellFn },
    ],
    []
  );

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{t("Distributors")}</PageTitle>
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search by company name")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        {roleIsSomeKindOfSeller && (
          <HorizontalButtonsContainer>
            <SecondaryButtonMedium
              onClick={() => setDownloadReportSlideOut(true)}
            >
              {t("Download Report")}
            </SecondaryButtonMedium>
            <PrimaryButtonWithPlusIcon onClick={openModal} data-alignright>
              {t("Create New")}
            </PrimaryButtonWithPlusIcon>
          </HorizontalButtonsContainer>
        )}
        <Modal closeModal={closeModal} show={showModal}>
          <CreateNewTenantFormContainer
            sellerTenantId={user.tenant_id}
            type="Distributor"
            onSuccess={(tenantID) => {
              history.push(`${adminPath}/distributors/${tenantID}`);
              closeModal();
            }}
          />
        </Modal>
      </PageHeader>
      <CustomerDistributorDropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          showIcon
          hideBorder
          textLeft={t("items") + ":"}
          textRight={t("Per Page")}
          direction={"left"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
      </CustomerDistributorDropdownContainer>
      <ContentWrapper>
        <Table
          columns={columns}
          isLoading={isLoading}
          error={tenantsError}
          data={tableData}
          rowClick={handleRowClick}
          handleSort={handleSort}
        />
        <Pagination
          pagination={pagination}
          offset={offset}
          handlePageClick={handlePageClick}
        />
        <SlideOut
          closeFlyout={closeDownloadReportSlideout}
          show={downloadReportSlideOut}
        >
          <DownloadReport
            closeSlideout={closeDownloadReportSlideout}
            transactionType={"distributor"}
          />
        </SlideOut>
      </ContentWrapper>
    </PageWrapper>
  );
});
