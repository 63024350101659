import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import type {
  OptionType,
  Product,
  ProductSKU,
  SampleRequestCreationArgs,
  SampleRequestPaginatedOutput,
} from "../../../types/types";
import {
  H3,
  SmallSectionHeader,
  Title,
} from "../../../components/Typography/Typography";
import { Form } from "../../../layout/FormLayout";
import { StatusBox } from "../../../components/Form/Form";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../components/TextFields/TextFields";
import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import styled from "styled-components/macro";
import { HeaderSection } from "../../../components/QuoteAndOrderFormShared/QuoteAndOrderFormShared";
import { positiveNumberRegex } from "../../../util/regexes";
import {
  useStoreState,
  useFormWrapper,
  convertProductSKUToOption,
  makeUrlWithParams,
} from "../../../util/util";
import Axios from "axios";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import useSWR from "swr";
import {
  packagingTypeOrUnitToOption,
  usePackagingUnits,
} from "../../../util/SkuUtils";
import { endpoints } from "../../../endpoints";
import { KeyValueDisplay } from "../../../components/KeyValueDisplay";
import {
  calculateTotalQuantity,
  formatTotalQuantityString,
} from "../../../components/quoteCart/BuyerQuoteItemForm";
import {
  ApplicationsSectionContainer,
  SAMPLE_REQUEST_PURPOSES,
} from "../../public/AddToSampleRequestForm/AddToSampleRequestForm";
import { ProductApplicationSelect } from "../../../components/ProductApplicationSelect/ProductApplicationSelect";
import { useTranslation } from "react-i18next";

const StatusBoxInnerContainer = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 15px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  // Add min-height to prevent layout shift when drop down menu appears.
  min-height: 58px;
  & > div {
    flex-grow: 1;
    margin-right: 9px;
    width: 49%;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

type SampleRequestItemForm = {
  purpose: OptionType<string>;
  product_sku: OptionType<ProductSKU>;
  number_of_units: string;
  total_quantity: string;
  total_quantity_unit: OptionType<string>;
  application?: OptionType<string> | OptionType<null>;
  custom_application?: string;
};

export function BuyerAddToSampleRequestForm({
  product,
  closeSlideout,
}: {
  product: Product;
  closeSlideout: () => void;
  cartID: string | undefined;
}) {
  const { notifyError, notifySuccess } = useContext(Notifications);
  const { t } = useTranslation();

  const { storefront_id } = useStoreState();

  // Fetch the existing data so we know whether a sample request cart exists
  // yet or not (because our submit request is different if it does or not).
  const { data: sampleRequestResponse, mutate: mutateSampleRequest } =
    useSWR<SampleRequestPaginatedOutput>(
      makeUrlWithParams(
        endpoints.v1_storefronts_id_sampleRequests(storefront_id),
        {
          source: "logged_in",
          status: "new",
          limit: "1",
        }
      )
    );

  const sampleRequestId = sampleRequestResponse?.data[0]?.id;

  const methodsOfUseForm = useFormWrapper<SampleRequestItemForm>();

  const {
    register,
    handleSubmit,
    formState,
    errors,
    control,
    setError,
    clearErrors,
    watch,
  } = methodsOfUseForm;

  const { packagingUnits, packagingUnitsError } = usePackagingUnits();

  const packagingUnitOptions =
    packagingUnits?.map(packagingTypeOrUnitToOption) || [];

  const packagingUnitsErrorMessage = "Error fetching packaging units";

  // Check to see if the error has already been set to avoid a render loop.
  if (packagingUnitsError && !errors["total_quantity_unit"]) {
    setError("total_quantity_unit", {
      message: packagingUnitsErrorMessage,
    });
  }
  if (
    !packagingUnitsError &&
    errors["total_quantity_unit"] &&
    // TODO: figure out why TypeScript has the wrong type for the error object.
    // @ts-ignore
    errors["total_quantity_unit"].message === packagingUnitsErrorMessage
  ) {
    clearErrors("total_quantity_unit");
  }

  const skuOptions = product.product_skus.reduce<OptionType<ProductSKU>[]>(
    (options, sku) => {
      if (sku.is_sample) {
        options.push(convertProductSKUToOption(sku));
      }
      return options;
    },
    []
  );

  const { number_of_units, product_sku } = watch([
    "number_of_units",
    "product_sku",
  ]);

  const totalQuantity = calculateTotalQuantity(
    product_sku?.value.package_volume,
    number_of_units
  );

  const totalQuantityString =
    totalQuantity === null
      ? "--"
      : formatTotalQuantityString(
          product_sku?.value.packaging_unit?.name,
          totalQuantity
        );

  const onFormSubmit = async (data: SampleRequestItemForm) => {
    try {
      const request: SampleRequestCreationArgs = {
        product_id: product.id,
        purpose: data.purpose.value,
        total_quantity: data.total_quantity,
        total_quantity_packaging_unit_id: data.total_quantity_unit.value,
        sku_id: data.product_sku.value.id,
        no_of_units: data.number_of_units,
        // TODO - sample_quantity_packaging_unit_id shouldn't be needed for
        // this form, but if we don't include it we get an error.
        sample_quantity_packaging_unit_id: data.total_quantity_unit.value,
        ...(data.custom_application
          ? { custom_application: data.custom_application }
          : {}),
      };

      if (data.application?.value) {
        request.applications = [data.application.value];
      }

      const endpoint = sampleRequestId
        ? endpoints.v1_storefronts_id_sampleRequests_id_items(
            storefront_id,
            sampleRequestId
          )
        : endpoints.v1_storefronts_id_sampleRequests(storefront_id);

      await Axios.post(
        makeUrlWithParams(endpoint, { source: "logged_in" }),
        request
      );

      notifySuccess("Item added to sample request");
      mutateSampleRequest();
      closeSlideout();
    } catch (error) {
      notifyError("There was an error creating the sample request", { error });
    }
  };

  return (
    <div>
      <Title style={{ marginTop: "10px" }}>{t("Add to Sample Request")}</Title>
      <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <StatusBox>
          <StatusBoxInnerContainer>
            <HeaderSection product={product} />
            <Controller
              as={SelectBoxV2}
              control={control}
              name="product_sku"
              placeholder={t("Product SKU")}
              id="product_sku"
              options={skuOptions}
              defaultValue={null}
              rules={{ required: true }}
              errors={errors}
              formState={formState}
            />
            <TextField
              name={"number_of_units"}
              label={t("Number of Units")}
              theref={register({ required: true })}
              errors={errors}
              formState={formState}
              type="number"
            />
            <KeyValueDisplay
              data={[
                {
                  key: t("Total Sample Quantity"),
                  value: totalQuantityString,
                },
              ]}
            />
            <SmallSectionHeader>
              {t("Potential Annual Usage")}
            </SmallSectionHeader>
            <FlexRow>
              <div>
                <TextField
                  name={"total_quantity"}
                  label={t("Total Annual Quantity")}
                  theref={register({
                    required: true,
                    pattern: {
                      value: positiveNumberRegex,
                      message: t("Must be a valid numeric value"),
                    },
                  })}
                  errors={errors}
                  formState={formState}
                  type="number"
                />
              </div>
              <div>
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="total_quantity_unit"
                  placeholder={t("Unit of Measure")}
                  id="total_quantity_unit"
                  options={packagingUnitOptions}
                  defaultValue={null}
                  rules={{ required: true }}
                  errors={errors}
                  formState={formState}
                />
              </div>
            </FlexRow>
          </StatusBoxInnerContainer>
        </StatusBox>
        <ApplicationsSectionContainer>
          <H3>{t("Product application")}</H3>
          <Controller
            as={SelectBoxV2}
            control={control}
            name="purpose"
            placeholder={t("Purpose")}
            rules={{ required: true }}
            options={SAMPLE_REQUEST_PURPOSES}
            errors={errors}
            formState={formState}
          />
          <ProductApplicationSelect
            methodsOfUseForm={methodsOfUseForm}
            applications={product?.product_applications ?? []}
          />
        </ApplicationsSectionContainer>
        <PrimaryButtonFitContainer loading={formState.isSubmitting}>
          {t("Add to Sample Request")}
        </PrimaryButtonFitContainer>
      </Form>
    </div>
  );
}
