import React, { useState } from "react";
import type { FileRejection, DropzoneOptions } from "react-dropzone";
import Dropzone from "react-dropzone";
import styled from "styled-components/macro";
import { DeleteButton, SecondaryButtonSmall } from "../Buttons/Buttons";
import type { MethodsOfUseForm } from "../../types/types";
import { FileTypeAndName } from "../FileCard/FileCard";
import { UploadWrapper } from "./FilePicker";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { SUPPORTED_FILE_TYPES } from "./FilePicker.constants";

const DropHereMessage = styled.div`
  margin-bottom: 10px;
`;

const UncontrolledUploadWrapper = styled(UploadWrapper)`
  position: relative;
`;

const FilePickerErrorText = styled.span`
  color: ${({ theme }) => theme.errorColor};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin-bottom: 10px;
`;

const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface FilePickerUncontrolledBaseProps
  extends Omit<DropzoneOptions, "accept"> {
  placeHolderText?: string;
  description?: string;
  methodsOfUseForm: MethodsOfUseForm;
  required: boolean;
  name: string;
  accept: string | readonly string[];
  defaultValueFileName?: string;
}

interface FilePickerSingleFile extends FilePickerUncontrolledBaseProps {
  handleFile?: (file: File) => void;
  multiple?: false;
}

interface FilePickerMultipleFiles extends FilePickerUncontrolledBaseProps {
  handleFile?: (file: File[]) => void;
  multiple?: true;
}

type FilePickerUncontrolledProps =
  | FilePickerSingleFile
  | FilePickerMultipleFiles;

/**
 * Supports all props of DropZone itself.
 * This is designed to work with react hook form, the onsubmit function will
 * receive a `File` object. If you want to do something with the file object
 * immediately you can `watch` the `name` of this component provided in the parent.
 *
 * When passing in an array of accepted file types through the `accept` prop it
 * won't let the user upload that file by clicking the button, but they can
 * still drag and drop it. This type of error is handled separately from the
 * react-hook-form errors, but is designed to look the same.
 *
 * @returns File | File[]
 * @link https://react-dropzone.js.org/#src
 */
export const FilePickerUncontrolled = ({
  placeHolderText,
  description,
  methodsOfUseForm,
  name,
  accept = SUPPORTED_FILE_TYPES,
  multiple = false,
  required,
  defaultValueFileName,
  ...props
}: FilePickerUncontrolledProps) => {
  const { t } = useTranslation();
  const [DropzoneError, setDropzoneError] = useState<FileRejection | null>();
  const [default_file_name, set_default_file_name] =
    useState(defaultValueFileName);

  const placeHolder = placeHolderText
    ? placeHolderText
    : t("Drag and drop a file here or");

  const { watch, setValue, control, errors: formErrors } = methodsOfUseForm;

  const formErrorMessage = formErrors[name]?.message;
  const shouldDisplayFormError = !!formErrorMessage && !Boolean(DropzoneError); // only display one error at a time

  const filesFromForm: File | File[] = watch(name);
  const hasFiles = Array.isArray(filesFromForm)
    ? filesFromForm.length > 0
    : Boolean(filesFromForm);

  const shouldDisplayRedBorder: boolean =
    shouldDisplayFormError || !!DropzoneError;

  const fileNames = (files: File[]) => {
    const len = files.length;
    const no_of_other_files = len - 1;
    return len > 1
      ? `${files[0].name} & ${no_of_other_files} ${
          no_of_other_files === 1 ? `file` : `files`
        }`
      : files[0].name;
  };

  const handleRemove = () => {
    setValue(name, null);
    set_default_file_name(undefined);
  };

  const getFileArray = () =>
    Array.isArray(filesFromForm) ? [...filesFromForm] : [filesFromForm];

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: { value: required, message: t("This field is required") },
      }}
      {...props}
      render={({ onChange, onBlur }) => (
        <Dropzone
          noClick
          multiple={multiple}
          // We're overrding the Dropzone type in the props of this component,
          // need to widen this here because we're not actually editing the
          // .d.ts file.
          accept={accept as string | string[]}
          {...props}
          onDrop={(acceptedFiles) => {
            // We're getting a FileList back here, but we just need the
            // first one. If we ever want to support adding multiple this is the
            // place to edit.
            setValue(name, multiple ? acceptedFiles : acceptedFiles[0], {
              shouldValidate: true,
            });
            setDropzoneError(null);
            if (multiple && props.handleFile) {
              props.handleFile(acceptedFiles as unknown as File & File[]);
            }
            if (!multiple && props.handleFile) {
              props.handleFile(acceptedFiles[0] as unknown as File & File[]);
            }
          }}
          onDropRejected={(err) => {
            setDropzoneError(err[0]);
          }}
        >
          {({ getRootProps, getInputProps, open, isDragActive }) => (
            <UncontrolledUploadWrapper
              {...getRootProps()}
              error={shouldDisplayRedBorder}
            >
              <input
                {...getInputProps({
                  onChange,
                  onBlur,
                })}
              />
              {shouldDisplayFormError ? (
                <FilePickerErrorText>{formErrorMessage}</FilePickerErrorText>
              ) : (
                <></>
              )}
              {DropzoneError ? (
                <>
                  <FilePickerErrorText>
                    {t(`Error uploading file, {{uploadErrorMessage}}`, {
                      uploadErrorMessage: DropzoneError.errors[0].message,
                    })}
                  </FilePickerErrorText>
                  <SecondaryButtonSmall
                    style={{ marginTop: "8px" }}
                    type="button"
                    onClick={open}
                    disabled={props.disabled}
                  >
                    {t("Click to replace file(s)")}
                  </SecondaryButtonSmall>
                </>
              ) : (
                <></>
              )}

              {hasFiles ? (
                <FileNameContainer>
                  <FileTypeAndName
                    // At this point dropzone has accepted the file but it
                    // likely failed validation on the server side. This error
                    // is probably set manually from the form component
                    hasError={shouldDisplayFormError}
                    fileName={
                      multiple
                        ? fileNames(getFileArray())
                        : getFileArray()[0].name
                    }
                  />
                  {!required ? (
                    <DeleteButton
                      testid={`delete-button-file-picker`}
                      onClick={handleRemove}
                      type="button"
                      height={16}
                      width={16}
                    />
                  ) : (
                    <></>
                  )}
                </FileNameContainer>
              ) : (
                <></>
              )}
              {/* fake edit UX, if there is an old file that has been uploaded before we show the name here and they can replace it. */}
              {default_file_name && !hasFiles && !DropzoneError ? (
                <FileNameContainer>
                  <FileTypeAndName
                    hasError={shouldDisplayFormError}
                    fileName={default_file_name}
                  />
                  {!required ? (
                    <DeleteButton
                      testid={`delete-button-file-picker`}
                      onClick={handleRemove}
                      type="button"
                      height={16}
                      width={16}
                    />
                  ) : (
                    <></>
                  )}
                </FileNameContainer>
              ) : (
                <></>
              )}
              {isDragActive && !DropzoneError ? (
                <DropHereMessage>
                  {getFileArray()?.[0]?.name
                    ? t("Replace file(s) by dropping here")
                    : t("Drop the file(s) here...")}
                </DropHereMessage>
              ) : (
                <></>
              )}

              {!isDragActive && !DropzoneError ? (
                <>
                  {!hasFiles ? (
                    <DropHereMessage>{placeHolder}</DropHereMessage>
                  ) : (
                    <></>
                  )}
                  <SecondaryButtonSmall
                    style={{ marginTop: "8px" }}
                    type="button"
                    onClick={open}
                    disabled={props.disabled}
                  >
                    {hasFiles || default_file_name
                      ? t("Click to replace file(s)")
                      : t("Click to select file(s)")}
                  </SecondaryButtonSmall>
                  {!hasFiles && description ? (
                    <DropHereMessage style={{ marginTop: "12px" }}>
                      {description}
                    </DropHereMessage>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </UncontrolledUploadWrapper>
          )}
        </Dropzone>
      )}
    />
  );
};
