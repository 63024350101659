import type { SEODetail } from "../../types/types";
import React from "react";
import { Helmet } from "react-helmet";
import { useStoreState } from "../../util/util";

/**
 * return a react helmet section to set SEO information.
 * @param param0
 */
export function SEOHelmet({
  seo,
  extraProps,
}: {
  seo: SEODetail;
  extraProps?: { slug: string; image_url: string | null };
}) {
  const {
    meta_description,
    meta_keywords,
    meta_title,
    page_title,
    og_title,
    og_type,
    og_description,
    og_locale,
    og_url,
  } = seo;

  const { slug, image_url } = extraProps
    ? extraProps
    : { slug: null, image_url: null };

  const {
    storefront_metadata: { host, slug: storefront_slug, theme_object },
  } = useStoreState();

  return (
    <Helmet>
      {page_title && <title>{seo.page_title}</title>}
      {meta_description && (
        <meta name="description" content={meta_description} />
      )}
      {meta_title && <meta name="title" content={meta_title} />}
      {meta_keywords && <meta name="keywords" content={meta_keywords} />}
      {og_type && <meta property="og:type" content={og_type} />}
      {og_title ? (
        <meta name="title" property="og:title" content={og_title} />
      ) : meta_title ? (
        <meta name="title" property="og:title" content={meta_title} />
      ) : (
        ""
      )}
      {og_description ? (
        <meta
          name="description"
          property="og:description"
          content={og_description}
        />
      ) : meta_description ? (
        <meta
          name="description"
          property="og:description"
          content={meta_description}
        />
      ) : meta_description ? (
        <meta
          name="description"
          property="og:description"
          content={meta_description}
        />
      ) : (
        ""
      )}
      {og_url ? (
        <meta name="url" property="og:url" content={og_url} />
      ) : slug ? (
        <meta
          name="url"
          property="og:url"
          content={`https://${host}/store/${storefront_slug}/product/${slug}`}
        />
      ) : null}

      {image_url ? (
        <meta name="image" property="og:image" content={image_url} />
      ) : theme_object.branding.publicLogoUrl ? (
        <meta
          name="image"
          property="og:image"
          content={theme_object.branding.publicLogoUrl}
        />
      ) : (
        ""
      )}
      {og_locale && (
        <meta name="locale" property="og:locale" content={og_locale} />
      )}
      {og_url && <meta name="url" property="og:url" content={og_url} />}
    </Helmet>
  );
}
