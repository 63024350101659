import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactTooltip from "react-tooltip";
import styled, { useTheme } from "styled-components/macro";
import type { FontType, MethodsOfUseForm } from "../../types/types";
import { EditButton, InvisibleButton } from "../Buttons/Buttons";
import { CheckIcon, XIcon } from "../Icons/Icons";
import {
  H3,
  H5,
  SoftHeader2,
  SoftHeaderRegular,
} from "../Typography/Typography";
import { EditModeTitle } from "./EditModeTitle";

interface IEditableTitleProps {
  title: string;
  formId?: string;
  name: string;
  methodsOfUseForm: MethodsOfUseForm;
  disabled?: boolean;
  testid?: string;
  onClose?: () => void;
  datatip?: string;
  fontSize?: FontType;
  fontWeight?: FontType;
}

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  * {
    padding: 0px;
  }
`;

export const EditableTitle = forwardRef<
  { setEditMode: (editMode: boolean) => void },
  IEditableTitleProps
>(
  (
    {
      title,
      formId,
      name,
      methodsOfUseForm: { errors, formState, register },
      disabled,
      testid,
      datatip,
      onClose,
      fontSize,
      fontWeight,
    }: IEditableTitleProps,
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(false);
    const theme = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(
      ref,
      () => {
        return {
          setEditMode(editMode: boolean) {
            setIsEditing(editMode);
          },
        };
      },
      [setIsEditing]
    );

    const handleOnClose = () => {
      setIsEditing(false);
      if (onClose) {
        onClose();
      }
    };

    const getHeading = () => {
      switch (fontSize) {
        case "large":
          return (
            <H3 style={{ margin: 0, color: theme.colors.darkText }}>{title}</H3>
          );
        case "medium":
          return (
            <H5 style={{ margin: 0, color: theme.colors.darkText }}>{title}</H5>
          );
        case "regular":
          return (
            <SoftHeaderRegular
              style={{ margin: 0, color: theme.colors.darkText }}
            >
              {title}
            </SoftHeaderRegular>
          );
        case "small":
          return (
            <SoftHeader2 style={{ margin: 0, color: theme.colors.darkText }}>
              {title}
            </SoftHeader2>
          );
        default:
          return (
            <H3 style={{ margin: 0, color: theme.colors.darkText }}>{title}</H3>
          );
      }
    };
    return (
      <div
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        {isEditing ? (
          <>
            <EditModeTitle
              name={name}
              register={register}
              theref={inputRef}
              formState={formState}
              errors={errors}
              tabIndex={0}
              defaultValue={title}
              fontSize={fontSize}
              fontWeight={fontWeight}
            />
            <ButtonGroup>
              <InvisibleButton form={formId} type="submit">
                <CheckIcon fill={theme.activeToggleBG} />
              </InvisibleButton>
              <InvisibleButton type="button" onClick={handleOnClose}>
                <XIcon fill={theme.destructiveTextColor} />
              </InvisibleButton>
            </ButtonGroup>
          </>
        ) : (
          <>
            {getHeading()}
            <EditButton
              testid={testid ?? "edit-title"}
              datafor="edit-title-tip"
              datatip={datatip}
              disabled={disabled}
              height={18}
              type="button"
              width={18}
              onClick={() => setIsEditing(true)}
            />
            <ReactTooltip id="edit-title-tip" />
          </>
        )}
      </div>
    );
  }
);
