import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "../Accordion/Accordion";
import { CaretDownIcon } from "../Icons/Icons";
import { useTheme } from "styled-components/macro";
import { HomePageTitle } from "../../layout/publicPageLayout";

export type AccordionContent = {
  title: string;
  text: string; // in the future potentially support displaying anything here.
  //  TODO: props for setting collapsible/multiple/other things
};

export interface IAccordionData {
  header: string;
  accordionContent: AccordionContent[];
  collapsible: boolean;
  multiple: boolean;
}

export function DisplayAccordion({
  header,
  accordionContent,
  collapsible,
  multiple,
}: IAccordionData) {
  const theme = useTheme();

  return (
    <>
      {header && <HomePageTitle>{header}</HomePageTitle>}
      <Accordion collapsible={collapsible} multiple={multiple}>
        {accordionContent.map(({ title, text }) => {
          return (
            <AccordionItem key={title}>
              <h3>
                <AccordionButton>
                  {title}
                  <CaretDownIcon
                    height={28}
                    width={28}
                    fill={theme.secondaryIconColor}
                  />
                </AccordionButton>
              </h3>
              <AccordionPanel>{text}</AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
}
