import { LocationIcon, UserIcon } from "../Icons/Icons";
import { InfoBlockExtraSmall } from "../InfoBlocks/InfoBlocks";
import type { AccountManagerContactInfo, IAddress } from "../../types/types";
import type { FC } from "react";
import React from "react";
import styled from "styled-components/macro";
import { formatPhoneNumber } from "../../util/phone";
import { ContactInfo } from "../ContactInfo/ContactInfo";
import { AddressBlock } from "../LocationsList/LocationsList";

const ContactSpacer = styled.div`
  margin-top: 13px;
`;

interface IContactInfoBlockSmallProps {
  address?: IAddress;
  accountManagerContactInfo?: AccountManagerContactInfo;
  header: string;
  testid?: string;
  is_transaction_address?: boolean;
}

/**
 * Displays an address and contact info (name, email, phone). For example,
 * this is used on the quote request detail pages.
 */
export const ContactInfoBlockSmall: FC<IContactInfoBlockSmallProps> = ({
  address,
  accountManagerContactInfo,
  header,
  testid,
  is_transaction_address = true,
}) => {
  const addressToUse = accountManagerContactInfo || address;

  return (
    <div>
      <InfoBlockExtraSmall
        icon={<LocationIcon height={13} width={13} />}
        header={header}
        content={
          address ? (
            <AddressBlock
              address={address}
              is_transaction={is_transaction_address}
            />
          ) : (
            "--"
          )
        }
      />
      {addressToUse && (
        <ContactSpacer>
          <InfoBlockExtraSmall
            icon={
              addressToUse.phone_number ? (
                <UserIcon height={13} width={13} />
              ) : null
            }
            header={""}
            content={
              <ContactInfo
                testid={`${testid}-contact-person`}
                strings={
                  addressToUse
                    ? [
                        addressToUse.contact_name,
                        addressToUse.email_address,
                        addressToUse.phone_number
                          ? formatPhoneNumber(
                              addressToUse.phone_number,
                              addressToUse.country
                            )
                          : "",
                      ]
                    : ["--", "--", "--"]
                }
              />
            }
          />
        </ContactSpacer>
      )}
    </div>
  );
};
