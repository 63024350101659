/**
 * This matches what the API currently supports, this may one day be configurable
 */
export const SUPPORTED_FILE_TYPES = [
  ".png",
  ".pdf",
  ".png",
  ".jpg",
  ".jpeg",
  ".doc",
  ".docx",
  ".txt",
  ".csv",
  ".json",
  ".svg",
  ".zip",
  ".ppt",
  ".pptx",
  ".rtf",
  ".webp",
] as const;

type SupportedFileTypeMutable =
  | typeof SUPPORTED_FILE_TYPES[number]
  | typeof SUPPORTED_FILE_TYPES[number][];
// can't figure out how to oneline this, above type not exported intentionally.
export type SupportedFileType = Readonly<SupportedFileTypeMutable>;

export const SUPPORTED_IMG_TYPES = [".png", ".jpg", ".jpeg", ".svg", ".webp"];
export const SUPPORTED_IMG_OR_ZIP_TYPES = [".zip", ...SUPPORTED_IMG_TYPES];

export const SUPPORTED_DOC_TYPES = [
  ".pdf",
  ".doc",
  ".docx",
  ".txt",
  ".csv",
  ".json",
  ".ppt",
  ".pptx",
  ".rtf",
];
export const SUPPORTED_DOC_OR_ZIP_TYPES = [".zip", ...SUPPORTED_DOC_TYPES];

export const SUPPORTED_SHIPMENT_ADVICE_DOC = [
  ".pdf",
  ".doc",
  ".docx",
  ".csv",
  ".txt",
  ".ppt",
  ".pptx",
  ...SUPPORTED_IMG_TYPES,
];

export const SUPPORTED_VIDEO_TYPES = [
  ".mp4",
  ".mkv",
  ".mov",
  ".flv",
  ".avi",
  ".wmv",
  ".webm",
];
export const SUPPORTED_VIDEO_OR_ZIP_TYPES = [".zip", ...SUPPORTED_VIDEO_TYPES];

/**
 * Maximum file size for drag and drop
 */

export const MAXIMUM_FILE_SIZE = 52428800; // 50 MB
