import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import type { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import type { ChipType } from "../Chips/Chips";
import { Chip, ChipContainer } from "../Chips/Chips";
import styled, { useTheme } from "styled-components";
import { InvisibleButton } from "../Buttons/Buttons";
import {
  CheckIcon,
  DeSelectAllIcon,
  SelectAllIcon,
  XIcon,
} from "../Icons/Icons";
import { SearchBar } from "../SearchBar/SearchBar";
import { SoftHeader2 } from "../Typography/Typography";
import ReactTooltip from "react-tooltip";

interface IPrefilledChipsProps {
  selectedChips: ChipType[];
  numSelectable?: 1 | "many";
  allChips: any;
  header: string;
  handleClick: any;
  withSelectAll?: boolean;
  ToggleLabel?: string;
  handleApply?: () => void;
  handleClear?: () => void;
  scroll?: boolean;
  // selectedChip?: any;
}

interface IScrollAreaProps {
  scroll: boolean;
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
`;

const ScrollArea = styled.div<IScrollAreaProps>`
  max-height: ${({ scroll }) => (scroll ? "240px" : "auto")};
  overflow-y: auto;
  display: block;
`;

export const PrefilledSelectAllChips = ({
  numSelectable,
  allChips,
  handleClick,
  withSelectAll,
  selectedChips,
  handleApply,
  handleClear,
  ToggleLabel = "regions",
  scroll = false,
}: IPrefilledChipsProps): JSX.Element => {
  const [activeChipArray, setActiveChipArray] =
    useState<ChipType[]>(selectedChips);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const theme = useTheme();

  const handleChange = (val: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(val.target.value);
  };

  const handleClearInput = () => {
    setSearchQuery("");
    if (withSelectAll) {
      setSelectAllChecked(
        selectedChips.length === allChips.length ? true : false
      );
    }
  };

  const handleMultiSelectClick = (e: any) => {
    if (
      // if clicked chip is inside activeChipArray
      activeChipArray.some(
        (obj: any) => obj.name === JSON.parse(e.currentTarget.dataset.chip).name
      )
    ) {
      setActiveChipArray(
        activeChipArray.filter(
          (obj: any) =>
            obj.name !== JSON.parse(e.currentTarget.dataset.chip).name
        )
      );
    } else {
      setActiveChipArray([
        ...activeChipArray,
        JSON.parse(e.currentTarget.dataset.chip),
      ]);
    }
  };
  useEffect(() => {
    if (numSelectable === "many" || withSelectAll) {
      handleClick(
        activeChipArray.map((item: any) => {
          return item;
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChipArray]);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  });

  const handleCheckBoxClick = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      if (searchQuery) {
        setActiveChipArray((prev) => [
          ...prev,
          ...allChips.filter(
            (chip: any) =>
              chip.name
                .toLocaleLowerCase()
                .includes(searchQuery.toLocaleLowerCase()) &&
              prev.indexOf(chip) < 0
          ),
        ]);
      } else {
        setActiveChipArray(allChips);
        handleClick(allChips);
      }
    } else {
      if (searchQuery) {
        setActiveChipArray((prev) =>
          prev.filter(
            (chip: any) =>
              !chip.name
                .toLocaleLowerCase()
                .includes(searchQuery.toLocaleLowerCase())
          )
        );
      } else {
        setActiveChipArray([]);
        handleClick([]);
      }
    }
  };

  const getChipName = (name: string) => {
    if (
      searchQuery.length > 0 &&
      name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    ) {
      const start = name.toLowerCase().indexOf(searchQuery.toLowerCase());
      const end =
        name.toLowerCase().indexOf(searchQuery.toLowerCase()) +
        searchQuery.length;
      return (
        <>
          {name.substring(0, start)}
          <b>{name.substring(start, end)}</b>
          {name.substring(end, name.length)}
        </>
      );
    }
    return name;
  };

  useEffect(() => {
    if (allChips.length > 0) {
      if (!searchQuery) {
        setSelectAllChecked(
          selectedChips.length === allChips.length ? true : false
        );
      } else {
        const allSearchResultsSelected = () => {
          const filteredChips = allChips
            .filter((chip: any) =>
              chip.name
                .toLocaleLowerCase()
                .includes(searchQuery.toLocaleLowerCase())
            )
            .map((item: any) => item.name);
          return filteredChips.every((chip: any) => {
            return activeChipArray.map((item: any) => item.name).includes(chip);
          });
        };

        setSelectAllChecked(allSearchResultsSelected() ? true : false);
      }
    }
  }, [allChips, selectedChips, searchQuery, activeChipArray]);

  return (
    <>
      <TopWrapper>
        <SearchBar
          placeHolder={`Search ${ToggleLabel}`}
          query={searchQuery}
          handleChange={handleChange}
          handleClearInput={handleClearInput}
        />
        <ButtonContainer>
          {withSelectAll &&
            allChips.filter((chip: any) =>
              chip.name
                .toLocaleLowerCase()
                .includes(searchQuery.toLocaleLowerCase())
            ).length !== 0 && (
              <div style={{ display: "flex" }}>
                <InvisibleButton onClick={handleCheckBoxClick}>
                  <span
                    data-for="select-all-tip"
                    data-tip={
                      selectAllChecked ? t("Deselect All") : t("Select All")
                    }
                  >
                    {selectAllChecked ? <DeSelectAllIcon /> : <SelectAllIcon />}
                  </span>
                </InvisibleButton>
                <ReactTooltip id="select-all-tip" effect="solid" />
              </div>
            )}
          {handleClear && (
            <InvisibleButton onClick={handleClear}>
              <XIcon
                width={24}
                height={24}
                fill={theme.destructiveButtonTextColor}
              />
            </InvisibleButton>
          )}
          {handleApply && (
            <InvisibleButton onClick={handleApply}>
              <CheckIcon width={24} height={24} fill={theme.activeToggleBG} />
            </InvisibleButton>
          )}
        </ButtonContainer>
      </TopWrapper>
      <ScrollArea scroll={scroll}>
        <ChipContainer>
          {allChips
            .filter((chip: any) =>
              chip.name
                .toLocaleLowerCase()
                .includes(searchQuery.toLocaleLowerCase())
            )
            .map((chip: any) => {
              if (activeChipArray.some((obj: any) => obj.name === chip.name)) {
                return (
                  <Chip
                    name={chip.name}
                    data-chip={JSON.stringify(chip)}
                    key={chip.name}
                    active={true}
                    onClick={handleMultiSelectClick}
                  >
                    {getChipName(chip.name)}
                  </Chip>
                );
              } else {
                return (
                  <Chip
                    name={chip.name}
                    data-chip={JSON.stringify(chip)}
                    key={chip.name}
                    active={false}
                    onClick={handleMultiSelectClick}
                  >
                    {getChipName(chip.name)}
                  </Chip>
                );
              }
            })}
          {allChips.filter((chip: any) =>
            chip.name
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase())
          ).length === 0 && (
            <SoftHeader2 style={{ margin: "30px 0", textAlign: "center" }}>
              {t("No Results found")}
            </SoftHeader2>
          )}
        </ChipContainer>
      </ScrollArea>
    </>
  );
};
