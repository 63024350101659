import React, { useCallback, useState, useEffect, useContext } from "react";
import type { AxiosError } from "axios";
import Axios from "axios";
import styled, { ThemeContext } from "styled-components/macro";
import { XIcon, LoadingIcon } from "../Icons/Icons";
import { useDropzone } from "react-dropzone";
import { SecondaryButtonSmall } from "../Buttons/Buttons";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import type { PolicyDocument, StorefrontMetaData } from "../../types/types";
import { Notifications } from "../Notifications/NotificationsContext";
import useSWR from "swr";
import { endpoints } from "../../endpoints";
import { Store } from "../../Store";
import { useStoreState } from "../../util/util";
import { screenSize } from "../../theme";
import { useTranslation } from "react-i18next";

const UploadWrapper = styled.div`
  padding: 18px 25px 20px;
  margin: 10px 0 30px !important;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: 13px;
  background: ${({ theme }) => theme.primaryBG};
  min-height: 115px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  @media ${screenSize.medium} {
    width: 100%;
  }
`;

const FileWrapper = styled.div`
  padding: 10px 15px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 4px;
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: 14px;
  background: ${({ theme }) => theme.primaryBG};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  @media ${screenSize.medium} {
    width: 100%;
  }
`;

const FileName = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.primaryTextColor};
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  min-width: 100px;
  & > * {
    margin-left: 15px;
    cursor: pointer;
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: 12px;
  margin-bottom: 20px !important;
`;

const FileError = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: 10px;
`;

interface IFileUpload {
  id: string;
  documents: PolicyDocument[];
  type: string;
  hideUploader?: boolean;
  handleMetadataChange: () => void;
}

export const StorefrontPolicyDocumentUpload = (props: IFileUpload) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_metadata } = useStoreState();
  const [documents, setDocuments] = useState<PolicyDocument[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<PolicyDocument[]>(
    []
  );
  const [selectedDocument, setSelectedDocument] = useState<any>({});
  const [docType] = useState<string>(props.type);
  const [showTypeError, setShowTypeError] = useState<boolean>(false);
  // Confirm Dialog
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const [is_uploading_file, set_is_uploading_file] = useState(false);
  const [confirmId, setConfirmId] = useState(0);
  //Theme Object
  const theme = useContext(ThemeContext);
  const {
    storeDispatch,
    storeState: { slug, storefront_id },
  } = useContext(Store);

  const { t } = useTranslation();

  const { data: metadataResponse, mutate: mutateStorefrontMetadata } = useSWR<
    StorefrontMetaData,
    AxiosError<StorefrontMetaData>
  >(endpoints.v1_storefronts_id_metadata(storefront_id));

  const closeDialog = () => {
    setShowConfirmDialog(false);
    setConfirmDialogMessage("");
  };

  const confirmDialog = (message: string, id: number) => {
    setConfirmDialogMessage(message);
    setConfirmId(id);
    setShowConfirmDialog(true);
  };

  const onDrop = useCallback((files: any) => {
    setSelectedDocument(files[0]);
  }, []);

  const deleteDocument = (id: number) => {
    confirmDialog(t(`Are you sure you want to delete this document?`), id);
  };

  const confirmDelete = (id: number) => {
    setShowConfirmDialog(false);
    Axios.delete(`/v1/storefronts/${slug}/policy-documents/${id}`).then(
      (response) => {
        if (response.status === 204) {
          setUploadedDocuments(
            uploadedDocuments.filter((doc: any) => doc.id !== id)
          );
          mutateStorefrontMetadata();
          props.handleMetadataChange();
          notifySuccess(t("Document has been removed successfully."));
        }
      }
    );
  };

  useEffect(() => {
    if (metadataResponse) {
      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: metadataResponse,
      });
    }
  }, [metadataResponse, storeDispatch]);

  const upload = useCallback(
    (file: any) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (docType === "" && file.name) {
        setShowTypeError(true);
      } else if (docType !== "" && selectedDocument.name) {
        set_is_uploading_file(true);
        setShowTypeError(false);
        setDocuments([file, ...documents]);
        setSelectedDocument({});
        const formData = new FormData();
        formData.append("file", file);
        Axios.post(
          `/v1/storefronts/${storefront_id}/documents/${props.type}`,
          formData,
          config
        )
          .then(async (response) => {
            await mutateStorefrontMetadata();
            props.handleMetadataChange();
            setUploadedDocuments([response.data, ...uploadedDocuments]);
            setDocuments(
              documents.filter(
                (doc: PolicyDocument) => doc.id !== response.data.id
              )
            );
          })
          .catch((error) => {
            setDocuments(
              documents.filter((doc: PolicyDocument) => doc.name !== file.name)
            );
            const failedFile = file;
            if (
              error.response.status === 400 &&
              error.response.data.error.indexOf("400") === 0
            ) {
              notifyError(t("Upload failed, document format not supported."));

              failedFile.error = "Not supported.";
              setUploadedDocuments([failedFile, ...uploadedDocuments]);
            } else if (
              error.response.status === 400 &&
              error.response.data.error.indexOf("413") === 0
            ) {
              notifyError(t("Upload failed, document is too large."));
              failedFile.error = "Document too large";
              setUploadedDocuments([failedFile, ...uploadedDocuments]);
            } else {
              notifyError(t("Upload failed, something went wrong."));
              failedFile.error = "Error!";
              setUploadedDocuments([failedFile, ...uploadedDocuments]);
            }
          })
          .finally(() => {
            set_is_uploading_file(false);
          });
      }
    },
    [
      docType,
      selectedDocument.name,
      documents,
      storefront_id,
      props,
      mutateStorefrontMetadata,
      uploadedDocuments,
      notifyError,
      t,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "application/pdf",
  });

  useEffect(() => {
    if (selectedDocument.name) {
      upload(selectedDocument);
    }
  }, [selectedDocument, docType, upload]);

  useEffect(() => {
    setUploadedDocuments(props.documents);
  }, [props.documents]);

  const viewDocument = async (id: string) => {
    const document = storefront_metadata.policy_documents.find(
      (doc) => doc.id === id
    );
    if (document) {
      window.open(document.signed_url, "_blank");
    } else {
      notifyError(t("Operation failed, something went wrong."));
    }
  };

  return (
    <>
      {showTypeError && (
        <ErrorMessage>*{t("Select document type to upload")}</ErrorMessage>
      )}

      {!props.hideUploader && !is_uploading_file && (
        <UploadWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>{t("Drop the document here")}...</p>
          ) : (
            <>
              {selectedDocument.name ? (
                <>
                  <FileName>{selectedDocument.name}</FileName>
                </>
              ) : (
                <>
                  {t("Drag and drop document here or")},
                  <br />
                  <SecondaryButtonSmall
                    onClick={(e) => e.preventDefault()}
                    style={{ marginTop: "10px" }}
                  >
                    {t("Click to select document")}
                  </SecondaryButtonSmall>{" "}
                </>
              )}
            </>
          )}
        </UploadWrapper>
      )}

      {is_uploading_file && (
        <FileWrapper>
          <LoadingIcon width={22} height={22}></LoadingIcon>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            {t("Uploading")}...
          </span>
        </FileWrapper>
      )}

      {uploadedDocuments.map((doc: any, index: number) => {
        return (
          <FileWrapper key={index}>
            <FileName>{doc.name}</FileName>
            <Actions>
              {doc.error ? (
                <FileError>{doc.error}</FileError>
              ) : (
                <>
                  <SecondaryButtonSmall onClick={() => viewDocument(doc.id)}>
                    {t("View")}
                  </SecondaryButtonSmall>
                  <span onClick={() => deleteDocument(doc.id)}>
                    <XIcon
                      width={20}
                      height={20}
                      fill={theme.colors.errorRedBorder}
                    ></XIcon>
                  </span>
                </>
              )}
            </Actions>
          </FileWrapper>
        );
      })}

      <ConfirmDialog
        show={showConfirmDialog}
        confirmMessage={confirmDialogMessage}
        handleConfirm={() => confirmDelete(confirmId)}
        closeDialog={closeDialog}
      />
    </>
  );
};
