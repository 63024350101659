import Axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  PrimaryButtonSmall,
} from "../../../../components/Buttons/Buttons";
import { PDFIcon } from "../../../../components/Icons/Icons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { H3 } from "../../../../components/Typography/Typography";
import type { IPurchaseOrder } from "../../../../types/types";
import { useStoreState } from "../../../../util/util";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FileNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 15px;
`;

const FileNameText = styled.div`
  margin: 0 12px;
`;

const PackingListButton = styled(PrimaryButtonSmall)`
  margin-right: 18px;
  &:last-child {
    margin-right: 0;
  }
`;

type PackingListSectionProps = {
  order: IPurchaseOrder;
  readOnly?: boolean;
  mutateOrderAndEvents: () => void;
};

/**
 * Renders the Packing list section on the order detail page for buyer and seller.
 * For buyers it is read-only, for sellers it is read/write.
 */
export const PackingListSection = (props: PackingListSectionProps) => {
  const { order, readOnly } = props;
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const [uploadingPackingList, setUploadingPackingList] = useState(false);
  const { t } = useTranslation();
  const packingListDoc = order.documents.find(
    (doc) => doc.kind === "packaging_list"
  );

  const addPackingList = async () => {
    try {
      setUploadingPackingList(true);
      await Axios.post(
        `/v1/storefronts/${storefront_id}/orders/${order.id}/packaging-list`
      );
      notifySuccess(t("Packing list generated successfully"));
      props.mutateOrderAndEvents();
      setUploadingPackingList(false);
    } catch (error) {
      notifyError(
        t(
          "Something went wrong: the Packing list was not generated, please try again"
        ),
        {
          error,
        }
      );
      setUploadingPackingList(false);
    }
  };

  const deletePackingList = async () => {
    try {
      if (packingListDoc?.id) {
        await Axios.delete(
          `/v1/storefronts/${storefront_id}/orders/${order.id}/documents/${packingListDoc.id}`
        );
        notifySuccess(t("Packing list deleted"));
        props.mutateOrderAndEvents();
      }
    } catch (error) {
      notifyError(t("Something went wrong: the Packing list was not deleted"), {
        error,
      });
    }
  };

  return (
    <>
      <Row>
        <H3>{t("Packing List")}</H3>

        {!readOnly && !packingListDoc && (
          <PackingListButton
            data-testid={"add-packingList"}
            onClick={addPackingList}
            loading={uploadingPackingList}
          >
            {t("Create")}
          </PackingListButton>
        )}
      </Row>

      {packingListDoc && (
        <FileNameContainer>
          <PDFIcon height={24} width={24} />
          <a href={packingListDoc.signed_url}>
            <FileNameText>{packingListDoc.name}</FileNameText>
          </a>
          {!readOnly && packingListDoc && (
            <ButtonWithConfirmDialog
              Button={DeleteButton}
              testid={"delete-PackingList"}
              handleConfirm={deletePackingList}
              buttonText={t("Delete")}
              confirmMessage={t(
                "Are you sure you want to delete the generated packing list?"
              )}
            />
          )}
        </FileNameContainer>
      )}
    </>
  );
};
