import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components/macro";
import { EditIcon } from "../Icons/Icons";
import type { ICartItem } from "../quoteCart/cartUtils";
import type {
  QuoteRequestItem,
  SampleRequestIndividualItem,
} from "../../types/types";
import { useTranslation } from "react-i18next";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  PrimaryButton,
} from "../Buttons/Buttons";
import { CartItemBuyer } from "../TransactionItem/CartItemBuyer";
import { SmallSectionHeader } from "../Typography/Typography";
import { SlideOut } from "../SlideOut/SlideOut";
import { UnlistedProductForm } from "../../pages/Buyer/RequestUnlistedProduct/UnlistedProductFrom";
import { Store } from "../../Store";

export const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.secondaryBG};
  border: solid 1px ${({ theme }) => theme.primaryBorder};
  border-radius: 6px;
  padding: 36px 24px 17px; // 17px is 32px minus 15px (margin-bottom of CartItem)
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.large};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 0;
`;

const CartItem = styled.div`
  background-color: ${({ theme }) => theme.primaryBG};
  border: solid 1px ${({ theme }) => theme.secondaryBorder};
  border-radius: 4px;
  margin: 0 0 15px;
  padding: 14px 22px 30px;
`;

const ProductName = styled.span`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-top: -6px;
`;
const Quantity = styled.span`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-top: 10px;
`;
const EditRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const EditButton = styled.span`
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DetailsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;

  & > p {
    margin-right: 25px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const DetailsDark = styled.span`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.xs};
  margin: 5px 10px;
`;

// DetailsLight was originally part of the transaction item card styles, and
// imported from there, before the redesign in February 2022.
const DetailsLight = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.secondaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.xs};
  white-space: nowrap;
`;

export const ApplicationsContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondaryTextColor};
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.xs};
`;

export const Application = styled.div`
  background-color: ${({ theme }) => theme.primaryBG};
  border: solid 1px ${({ theme }) => theme.primaryBorder};
  border-radius: 2px;
  padding: 8px 10px;
  margin: 8px 8px 0 0;
`;

interface ICartProps {
  cartItems: ICartItem[];
  edit: (itemToEdit: ICartItem) => void;
  remove: (itemToRemove: ICartItem) => void;
}

interface IBuyerCartProps {
  cartItems: QuoteRequestItem[];
  edit: (itemToEdit: QuoteRequestItem) => void;
  remove: (itemToRemove: QuoteRequestItem) => void;
}

type SampleRequestCartProps = {
  cartItems: SampleRequestIndividualItem[];
  remove?: (itemToRemove: SampleRequestIndividualItem) => void;
};

export const SampleRequestCartItems = ({
  cartItems,
  remove,
}: SampleRequestCartProps) => {
  const { t } = useTranslation();

  return (
    <Content>
      {cartItems.map((item) => {
        return (
          <CartItem key={item.product.name}>
            <Row>
              <ProductName>{item.product.name}</ProductName>
              <Quantity>
                {`${item.sample_quantity} ${item.sample_quantity_packaging_unit?.name}`}
              </Quantity>
            </Row>
            <Row>
              <DetailsContainer>
                <div>
                  <SmallSectionHeader>
                    {t("Potential Annual Usage")}
                  </SmallSectionHeader>
                  <DetailsLight>{`${item.total_quantity} ${item.total_quantity_packaging_unit?.name}`}</DetailsLight>
                </div>
              </DetailsContainer>
            </Row>
            <Row>
              <ApplicationsContainer>
                {item.custom_application ? (
                  <Application>{item.custom_application}</Application>
                ) : (
                  item.applications?.map((application) => {
                    return (
                      <Application key={application.id}>
                        {application.name}
                      </Application>
                    );
                  })
                )}
              </ApplicationsContainer>
              {remove && (
                <ButtonWithConfirmDialog
                  Button={DeleteButton}
                  testid={"delete-sample-cart-item"}
                  handleConfirm={() => remove(item)}
                  confirmMessage={"Are you sure you want to remove this item?"}
                />
              )}
            </Row>
          </CartItem>
        );
      })}
    </Content>
  );
};

/**
 * Quote cart component used for guest users (not logged in).
 */
export const GuestCart = ({ cartItems, edit, remove }: ICartProps) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  return (
    <CartContainer>
      <Title>{t("Receive a quote for")}</Title>
      <Content>
        {cartItems.map((item) => {
          return (
            <CartItem key={item.product.id}>
              <EditRow>
                <EditButton
                  onClick={() => edit(item)}
                  data-testid="guest-cart-item-edit-button"
                >
                  <EditIcon fill={theme.primaryButtonBG} />
                </EditButton>
              </EditRow>
              <Row>
                <ProductName>{item.product.name}</ProductName>
                <Quantity>
                  {item.total_volume} {item.packaging_unit?.name ?? ""}
                </Quantity>
              </Row>
              <Row>
                <DetailsContainer>
                  <DetailsDark>{item.packaging_type?.name ?? ""}</DetailsDark>
                  {/* TODO - Designs call for more details to be shown here. */}
                  {/* <DetailsLight></DetailsLight> */}
                  {/* <DetailsLight></DetailsLight> */}
                </DetailsContainer>
                {/* <DetailsLight></DetailsLight>  */}
              </Row>
              <Row>
                <ApplicationsContainer>
                  {/* backend change, need to get applications from attributes */}
                  {item.custom_application ? (
                    <Application>{item.custom_application}</Application>
                  ) : (
                    item.product_applications?.map((application) => {
                      return (
                        <Application key={application.id}>
                          {application.name}
                        </Application>
                      );
                    })
                  )}
                </ApplicationsContainer>
                <ButtonWithConfirmDialog
                  Button={DeleteButton}
                  testid={"delete-quote-cart-item-guest"}
                  handleConfirm={() => remove(item)}
                  confirmMessage={"Are you sure you want to remove this item?"}
                />
              </Row>
            </CartItem>
          );
        })}
      </Content>
    </CartContainer>
  );
};

/**
 * Quote cart component used for buyer users (logged in).
 */
export const BuyerCart = ({ cartItems, edit, remove }: IBuyerCartProps) => {
  const { t } = useTranslation();
  const [showSlideOut, setShowSlideOut] = useState(false);
  const {
    storeState: { storefront_metadata },
  } = useContext(Store);
  return (
    <CartContainer>
      <Title>{t("Items")}</Title>
      <Content>
        {cartItems.map((item, index) => {
          return (
            <CartItemBuyer
              item={item}
              index={index}
              editItem={edit}
              removeItem={remove}
              key={index}
            />
          );
        })}
      </Content>
      <div>
        {storefront_metadata.unlisted_product_requests_enabled && (
          <PrimaryButton
            onClick={() => setShowSlideOut(true)}
            style={{ padding: "5px 8px", margin: "10px 0" }}
          >
            {t("Add unlisted product")}
          </PrimaryButton>
        )}
        <SlideOut
          closeFlyout={() => setShowSlideOut(false)}
          show={showSlideOut}
        >
          <UnlistedProductForm
            handleCloseFlyout={() => setShowSlideOut(false)}
          />
        </SlideOut>
      </div>
    </CartContainer>
  );
};
