import styled from "styled-components/macro";
import type { OrderDocument } from "../../types/types";
import {
  FileName,
  FileType,
} from "../../pages/Buyer/BuyerOrderDetail/AddPO/Upload";

const ContentText = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

export const PoNumberContentRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const PoNumberAndDocumentLink = ({
  poNumber,
  poDocument,
}: {
  poNumber: string;
  poDocument?: OrderDocument;
}) => {
  return (
    <PoNumberContentRow>
      <ContentText>{poNumber}</ContentText>
      {poDocument && (
        <>
          <a href={poDocument.signed_url} style={{ margin: "2px 5px 0 10px" }}>
            <FileName>
              <FileType>{poDocument.name.split(".").pop()}</FileType>
              <>{poDocument.name}</>
            </FileName>
          </a>
        </>
      )}
    </PoNumberContentRow>
  );
};
