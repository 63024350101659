import { PrimaryButtonWithPlusIcon } from "../../../../../components/Buttons/Buttons";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { Table } from "../../../../../components/Table/Table";
import { DropDown } from "../../../../../components/DropDown/DropDown";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import {
  ContentWrapper,
  HeaderBar,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../../layout/portalPageLayout";
import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { Modal } from "../../../../../components/Modal/Modal";
import { useDebounce } from "../../../../../util/hooks";
import { useAuthContext } from "../../../../../components/Auth";
import { providePrivatePageProps } from "../../../../../util/Routing";
import { Title } from "../../../../../components/Typography/Typography";
import { ToggleGroup } from "../../../../../components/ToggleGroups/ToggleGroups";
import { StringParam, useQueryParams } from "use-query-params";
import { StatusLeft } from "../../../../../components/Status/Status";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";

type EmailsTable = {
  id: number;
  title: string;
  status: { status: string; id: number };
  open_rate: string;
  click_through_rate: string;
  bounce_rate: string;
  conversion_rate: string;
  url: string;
};

type CampaignsTable = {
  id: number;
  campaign: string;
  campaign_owner: string;
  date_created: string;
  campaign_budget: string;
  url: string;
};

export type Email = {
  campaign: string;
  click_rate: string;
  click_through_rate: string;
  clicked: string;
  delivered: string;
  delivery_rate: string;
  email_name: string;
  from_adress: string;
  from_name: string;
  hard_bounced: string;
  not_sent: string;
  open_rate: string;
  opened: string;
  replied: string;
  reply_rate: string;
  sent: string;
  soft_bounced: string;
  spam_rate: string;
  spam_reports: string;
  status: string;
  subject: string;
  type: string;
  unsubscribed: string;
  bounce_rate: string;
  conversion_rate: string;
  url: string;
};

type Campaign = {
  campaign: string;
  campaign_owner: string;
  date_created: string;
  campaign_budget: string;
  campaign_url: string;
};

type StatusProps = {
  status: string;
  id: string;
};

const localStrings = (t: TFunction) => ({
  pageTitle: t("Marketing"),
  emailsSearchBarPlaceholder: t("Search by email title"),
  campaignsSearchBarPlaceholder: t("Search by campaign name"),
  email: t("Email"),
  phoneNumber: t("Phone Number"),
  contactOwner: t("Contact Owner"),
  associatedCompany: t("Associated Company"),
  createNew: t("Create New"),
  listName: t("List Name"),
  status: t("Status"),
  clickThroughRate: t("Click Through Rate"),
  bounceRate: t("Bounce Rate"),
  conversionRate: t("Conversion Rate"),
  openRate: t("Open Rate"),
  title: t("Title"),
  dateCreated: t("Date Created"),
  campaignOwner: t("Campaign Owner"),
  campaignBudget: t("Campaign Budget"),
  lastUpdated: t("Last Updated"),
  creator: t("Creator"),
  usedIn: t("Used In"),
  perPage: t("Per Page"),
});
type TabLabel = string;

export const MarketingListPage = providePrivatePageProps(() => {
  const { roleIsSomeKindOfSeller } = useAuthContext();
  const perPageItems = [10, 20, 50, 100];
  const [perPage, setPerPage] = useState(perPageItems[0]);
  const [showModal, setShowModal] = useState(false);
  const [emailsTableData, setEmailsTableData] = useState<EmailsTable[]>([]);
  const [campaignsTableData, setCampaignsTableData] = useState<
    CampaignsTable[]
  >([]);
  const { t } = useTranslation();

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  const [offset, setOffset] = useState(0);
  const [pagination, setPagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const TAB_LABELS: TabLabel[] = [t("Emails"), t("Campaigns")];
  const [query, setQuery] = useQueryParams({
    view: StringParam,
    q: StringParam,
  });
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [currentTab, setCurrentTab] = useState<string>(
    query.view || t("Emails")
  );
  const currentTabIsEmails = currentTab === t("Emails");
  const currentTabIsCampaigns = currentTab === t("Campaigns");

  const {
    data: emailsResponse,
    error: emailsError,
    mutate: mutateEmails,
  } = useSWR<Email[]>("/v1/digital-marketing/emails.json");

  const {
    data: campaignsResponse,
    error: campaignsError,
    mutate: mutateCampaigns,
  } = useSWR<Campaign[]>("/v1/digital-marketing/campaign.json");

  useEffect(() => {
    if (currentTabIsEmails && emailsResponse) {
      const emails = emailsResponse.slice(offset, offset + perPage);
      setEmailsTableData(
        emails.map((email: Email, index: number) => {
          return {
            id: index,
            title: email.email_name,
            status: { status: email.status, id: index },
            open_rate: email.open_rate,
            click_through_rate: email.click_through_rate,
            bounce_rate: email.bounce_rate,
            conversion_rate: email.conversion_rate,
            url: email.url,
          };
        })
      );
      setPagination({
        perPage: perPage,
        pageCount: Math.ceil(emailsResponse.length / perPage),
        pageIndex: offset / perPage + 1,
      });
    } else if (currentTabIsCampaigns && campaignsResponse) {
      const campaigns = campaignsResponse.slice(offset, offset + perPage);
      setCampaignsTableData(
        campaigns.map((campaign: Campaign, index: number) => {
          return {
            id: index,
            campaign: campaign.campaign,
            campaign_owner: campaign.campaign_owner,
            date_created: campaign.date_created,
            campaign_budget: campaign.campaign_budget,
            url: campaign.campaign_url,
          };
        })
      );
      setPagination({
        perPage: perPage,
        pageCount: Math.ceil(campaignsResponse.length / perPage),
        pageIndex: offset / perPage + 1,
      });
    }
  }, [
    currentTab,
    emailsResponse,
    campaignsResponse,
    currentTabIsEmails,
    currentTabIsCampaigns,
    query,
    perPage,
    offset,
  ]);

  const isLoading = !emailsResponse && !campaignsError && !emailsError;

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    if (currentTabIsEmails) {
      mutateEmails();
    } else {
      mutateCampaigns();
    }
  };

  const handlePageClick = (offset: number) => {
    setOffset(offset);
  };

  const handleRowClick = (
    e: React.MouseEvent,
    type: "email" | "campaign"
  ): void => {
    const url =
      type === "email"
        ? `${emailsTableData[parseInt(e.currentTarget.id)].url}`
        : `${campaignsTableData[parseInt(e.currentTarget.id)].url}`;
    window.open(url, "_blank");
  };

  const changeTab = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tabLabel: TabLabel
  ) => {
    e.preventDefault();
    setCurrentTab(tabLabel);
    setOffset(0);
    setQuery({ view: tabLabel, q: "" });
  };

  const EmailStatus = ({ status, id }: StatusProps) => {
    switch (status) {
      case "PUBLISHED":
        return <StatusLeft color="green" text={status} id={id} />;
      case "AUTOMATED":
        return <StatusLeft color="fullBlue" text={status} id={id} />;
      default:
        return <StatusLeft color="gray" text={status} id={id} />;
    }
  };

  useEffect(() => {
    // This useEffect handles keeping the query in sync with the URL.
    if (debouncedSearchQuery === "") {
      setQuery({ q: undefined });
    }
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
      if (currentTabIsEmails && emailsResponse) {
        const emails = emailsResponse.filter((email) => {
          const emailTitle = email.email_name.toString().toLowerCase();
          return emailTitle.match(debouncedSearchQuery.toLowerCase());
        });
        const tableList = emails.slice(0, perPage);
        setEmailsTableData(
          tableList.map((email: Email, index: number) => {
            return {
              id: index,
              title: email.email_name,
              status: { status: email.status, id: index },
              open_rate: email.open_rate,
              click_through_rate: email.click_through_rate,
              bounce_rate: email.bounce_rate,
              conversion_rate: email.conversion_rate,
              url: email.url,
            };
          })
        );
        setPagination({
          perPage: perPage,
          pageCount: Math.ceil(emails.length / perPage),
          pageIndex: 0 / perPage + 1,
        });
      } else if (currentTabIsCampaigns && campaignsResponse) {
        const campaigns = campaignsResponse.filter((campaign) => {
          const campaignTitle = campaign.campaign.toString().toLowerCase();
          return campaignTitle.match(debouncedSearchQuery.toLowerCase());
        });
        const tableList = campaigns.slice(0, perPage);
        setCampaignsTableData(
          tableList.map((campaign: Campaign, index: number) => {
            return {
              id: index,
              campaign: campaign.campaign,
              campaign_owner: campaign.campaign_owner,
              date_created: campaign.date_created,
              campaign_budget: campaign.campaign_budget,
              url: campaign.campaign_url,
            };
          })
        );
        setPagination({
          perPage: perPage,
          pageCount: Math.ceil(campaigns.length / perPage),
          pageIndex: 0 / perPage + 1,
        });
      }
    }
  }, [
    setQuery,
    query,
    debouncedSearchQuery,
    emailsResponse,
    campaignsResponse,
    currentTabIsEmails,
    currentTabIsCampaigns,
    perPage,
  ]);

  const emailsColumns = useMemo(
    () => [
      {
        accessor: "title",
        Header: localStrings(t).title,
      },
      {
        accessor: "status",
        Header: localStrings(t).status,
        Cell: ({ value }: { value: StatusProps }) => (
          <EmailStatus status={value?.status} id={value?.id} />
        ),
      },
      {
        accessor: "open_rate",
        Header: localStrings(t).openRate,
      },
      {
        accessor: "click_through_rate",
        Header: localStrings(t).clickThroughRate,
      },
      {
        accessor: "bounce_rate",
        Header: localStrings(t).bounceRate,
      },
      {
        accessor: "conversion_rate",
        Header: localStrings(t).conversionRate,
        align: "right",
      },
    ],
    [t]
  );

  const campaignsColumns = useMemo(
    () => [
      {
        accessor: "campaign",
        Header: localStrings(t).title,
      },
      {
        accessor: "campaign_owner",
        Header: localStrings(t).campaignOwner,
      },
      {
        accessor: "date_created",
        Header: localStrings(t).dateCreated,
      },
      {
        accessor: "campaign_budget",
        Header: localStrings(t).campaignBudget,
        align: "right",
      },
    ],
    [t]
  );

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{localStrings(t).pageTitle}</PageTitle>
        <SearchBar
          query={searchQuery}
          placeHolder={
            currentTab === t("Emails")
              ? localStrings(t).emailsSearchBarPlaceholder
              : localStrings(t).campaignsSearchBarPlaceholder
          }
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
        {roleIsSomeKindOfSeller && (
          <PrimaryButtonWithPlusIcon
            onClick={openModal}
            data-alignright
            disabled
          >
            {localStrings(t).createNew}
          </PrimaryButtonWithPlusIcon>
        )}
        <Modal closeModal={closeModal} show={showModal}>
          <Title>{t("Create New Campaign")}</Title>
        </Modal>
      </PageHeader>
      <HeaderBar>
        <ToggleGroup
          names={TAB_LABELS}
          activeButton={currentTab}
          clickHandler={changeTab}
          style={{ minWidth: 0 }}
        />
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={localStrings(t).perPage}
          direction={"left"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
      </HeaderBar>
      <ContentWrapper>
        {currentTab === t("Emails") ? (
          <Table
            columns={emailsColumns}
            isLoading={isLoading}
            error={emailsError}
            data={emailsTableData}
            rowClick={(e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) =>
              handleRowClick(e, "email")
            }
          />
        ) : (
          <Table
            columns={campaignsColumns}
            isLoading={isLoading}
            error={campaignsError}
            data={campaignsTableData}
            rowClick={(e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) =>
              handleRowClick(e, "campaign")
            }
          />
        )}
        <Pagination
          pagination={pagination}
          offset={offset}
          handlePageClick={handlePageClick}
        />
      </ContentWrapper>
    </PageWrapper>
  );
});
