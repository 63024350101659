import React, { useState, useContext } from "react";
import { Title, H3 } from "../Typography/Typography";
import { Form } from "../../layout/FormLayout";
import { StatusBox } from "../Form/Form";
import { PriceTiersTable } from "../PriceTiersTable/PriceTiersTable";
import styled from "styled-components/macro";
import { TextField } from "../TextFields/TextFields";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { Notifications } from "../Notifications/NotificationsContext";
import Axios from "axios";
import { endpoints } from "../../endpoints";
import type {
  QuoteRequestItem,
  IQuoteRequestPriceTier,
} from "../../types/types";
import {
  calculatePriceTiersPricePerUom,
  calculatePriceForQuantity,
} from "../../util/QuotesAndOrders";
import { formatPrice } from "../../util/util-components";
import {
  useCurrencySymbol,
  useFormWrapper,
  useStoreState,
} from "../../util/util";
import { useTranslation } from "react-i18next";

const LocalStatusBox = styled(StatusBox)`
  flex-direction: column;
  & > * {
    margin-bottom: 15px;
  }
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const calculateTotalQuantity = (
  units: number,
  skuPackageVolume: number
): number | null => {
  const total = units * skuPackageVolume;
  return isNaN(total) ? null : total;
};

interface FormData {
  number_of_units: string;
}

export interface ViewPriceTiersFormProps {
  tiersData: IQuoteRequestPriceTier;
  quoteItem: QuoteRequestItem;
  quoteId: string;
  closeForm: () => void;
  // We don't care about the return value, so just use `Promise<any[]>`.
  mutateQuoteAndEvents: () => Promise<any[]>;
  isReadOnly: boolean;
  titleText: string;
}

/**
 * A form to display price tiers in a slide out. When used to show price tiers
 * to a buyer, it allows the buyer to change the quantity of their quote (in
 * response to the offered price tiers), before they place the order.
 * Also used to show price tiers to a seller as read-only information.
 */
export const ViewPriceTiersForm = ({
  tiersData: { price_tiers },
  quoteItem,
  quoteId,
  closeForm,
  mutateQuoteAndEvents,
  isReadOnly,
  titleText,
}: ViewPriceTiersFormProps) => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const itemCurrencySymbol = useCurrencySymbol(quoteItem.currency);

  const [submitting, setSubmitting] = useState(false);

  const { handleSubmit, register, errors, formState, watch } = useFormWrapper({
    defaultValues: {
      number_of_units: quoteItem.no_of_units,
    },
  });

  const watchedUnits = watch("number_of_units");
  const unitsEntered = parseFloat(watchedUnits);
  // The sku.package_volume should never be null here, but TypeScript doesn't
  // know that. (See SkuNoPreference type.)
  const packageVolume = parseFloat(quoteItem.sku.package_volume || "");
  const totalQuantity = calculateTotalQuantity(unitsEntered, packageVolume);

  const totalQuantityString =
    totalQuantity === null
      ? "--"
      : `${totalQuantity} ${quoteItem.sku.packaging_unit?.name ?? ""}`;

  const pricePerUom = calculatePriceTiersPricePerUom(price_tiers, unitsEntered);
  const pricePerUomString =
    pricePerUom === null ? "--" : formatPrice(pricePerUom, quoteItem.currency);

  const subtotal =
    totalQuantity === null || pricePerUom === null
      ? null
      : calculatePriceForQuantity(pricePerUom, totalQuantity);

  const subtotalString =
    subtotal === null ? "--" : formatPrice(subtotal, quoteItem.currency);

  // Don't let the buyer select a quantity that's below the lowest price tier,
  // or if something else went wrong.
  const disableFormSubmission = pricePerUom === null || totalQuantity === null;

  const onFormSubmit = async (formData: FormData) => {
    setSubmitting(true);
    try {
      await Axios.patch(
        endpoints.v1_storefronts_id_or_slug_quotes_id_items_id(
          storefront_id,
          quoteId,
          quoteItem.id
        ),
        {
          no_of_units: formData.number_of_units,
        }
      );
      notifySuccess("Number of units updated");
      mutateQuoteAndEvents();
      setSubmitting(false);
      closeForm();
    } catch (error) {
      notifyError("There was an error updating the number of units", { error });
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Title>{titleText}</Title>
      <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <LocalStatusBox>
          <div>
            <H3>{quoteItem.product.name}</H3>
          </div>
          <div>
            <PriceTiersTable tiers={price_tiers} />
          </div>
          {!isReadOnly && (
            <div>
              <TextField
                label={"Number of Units"}
                name={`number_of_units`}
                theref={register({ required: true })}
                errors={errors}
                formState={formState}
                type="number"
              />
            </div>
          )}
          <SummaryRow>
            <div>{t("Total Quantity:")}</div>
            <div>{totalQuantityString}</div>
          </SummaryRow>
          <SummaryRow>
            <div>{`${t("Price")} (${itemCurrencySymbol}${t("/UoM")}):`}</div>
            <div>{pricePerUomString}</div>
          </SummaryRow>
          <SummaryRow>
            <div>{t("Subtotal:")}</div>
            <div>{subtotalString}</div>
          </SummaryRow>
          {!isReadOnly && (
            <PrimaryButtonFitContainer
              loading={submitting}
              disabled={disableFormSubmission}
            >
              {t("Update Number of Units")}
            </PrimaryButtonFitContainer>
          )}
        </LocalStatusBox>
      </Form>
    </div>
  );
};
