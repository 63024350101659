import React, { Fragment } from "react";
import type { Column } from "react-table";
import { useTable } from "react-table";
import styled from "styled-components/macro";
import type {
  IGroupedPriceTierSingleTier,
  CurrencyCode,
  ProductSKU,
} from "../../types/types";
import { formatPrice } from "../../util/util-components";
import { useCurrencySymbol } from "../../util/util";
import { screenSize } from "../../theme";
import {
  DeleteButton,
  EditButton,
  InvisibleButton,
} from "../../components/Buttons/Buttons";
import type { EditableRowValues } from "./EditableRow";
import { EditableRow } from "./EditableRow";
import { TableRowButtonBox } from "./shared";
import { CartIcon } from "../../components/Icons/Icons";
import type { PriceTierIntersection } from "../../pages/Buyer/BuyerMyProductsPriceTiers/BuyerMyProductsPriceTiers";
import { useTranslation } from "react-i18next";

const StyledTable = styled.table`
  // Styles originally copy/pasted from ProductDetailsList.
  // TODO: establish separation of content and presentation for tables like this.
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};

    th,
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 15px 10px 15px 15px;
      /* 1% width shrinks columns to the size of their content. */
      width: 1%;

      font-family: ${({ theme }) => theme.fontFamily};
      font-size: ${({ theme }) => theme.fontSizes.small};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }

    th {
      color: ${({ theme }) => theme.secondaryTextColor};
      text-align: left;
    }

    td {
      background-color: ${({ theme }) => theme.primaryBG};
      color: ${({ theme }) => theme.primaryTextColor};
      @media ${screenSize.small} {
        white-space: break-spaces;
      }
    }

    td:first-child {
      min-width: 250px;
    }

    @media (max-width: ${screenSize.medium}) {
      td {
        width: unset;
        /* Let text wrap on smaller screens. */
        white-space: normal;
      }
    }

    td:nth-child(1) {
      border-left: 8px solid ${({ theme }) => theme.secondaryBorder};
    }
  }
`;

const TableRowTextBox = styled.div`
  min-height: 21px;
  display: flex;
  align-items: center;
`;

const priceTierToTableRow = (
  tier: IGroupedPriceTierSingleTier,
  currencyCode: CurrencyCode
) => {
  const {
    minimum_sku_quantity,
    product_sku,
    price_per_uom,
    minimum_uom_quantity,
  } = tier;

  const sku = `${product_sku?.packaging_type?.name ?? "--"} (${
    product_sku?.package_volume ?? "--"
  } ${product_sku?.packaging_unit?.name ?? "--"})`;

  const min_order_qty = `${minimum_uom_quantity} ${
    product_sku?.packaging_unit?.name ?? "--"
  }`;

  return {
    sku,
    minimum_sku_quantity,
    min_order_qty,
    price_per_uom: formatPrice(price_per_uom, currencyCode),
  };
};

export type PriceTierRowStatus =
  | "new"
  | "new-edited"
  | "edited"
  | "deleted"
  | "unchanged";

export interface PriceTierRowData {
  status: PriceTierRowStatus;
  data: IGroupedPriceTierSingleTier;
}

/**
 * A big table for displaying and editing price tiers. Used for example on:
 *   - price tiers tab on the seller admin customer detail page (view and edit)
 *
 * Intended to be used with the usePriceTiersBigTable hook. That hook handles
 * the state and actions related to this table. Putting the state and behavior
 * in a hook that is called from the parent component makes it accessible to
 * the parent where the state can be used (e.g. in forms) and actions can be
 * taken (e.g. adding a new row to the table).
 *
 * (If you squint, the hook is like the Model, handling the data, and this
 * table component is like the View presenting the data to the user.)
 *
 * @see usePriceTiersBigTable
 */
export const PriceTiersBigTable = ({
  currencyCode,
  tableRowsData,
  isInEditMode,
  editRowIndex,
  productSkus,
  startEditingRow,
  finishEditingRow,
  cancelEditingRow,
  deleteRow,
  addToCart,
}: {
  currencyCode: CurrencyCode;
  tableRowsData: PriceTierRowData[];
  isInEditMode?: boolean;
  editRowIndex: number | null;
  productSkus: ProductSKU[];
  startEditingRow: (rowIndex: number) => void;
  finishEditingRow: (rowValues: EditableRowValues) => void;
  cancelEditingRow: () => void;
  deleteRow: (rowIndex: number) => void;
  addToCart?: (
    row: Pick<PriceTierIntersection, "product_sku" | "minimum_uom_quantity">
  ) => void;
}) => {
  const tiersCurrencySymbol = useCurrencySymbol(currencyCode);

  const { t } = useTranslation();

  const columns: Column<{
    sku: string;
    minimum_sku_quantity: string;
    min_order_qty: string;
    price_per_uom: string;
  }>[] = React.useMemo(
    () => [
      {
        Header: t("SKU") as string,
        accessor: "sku",
      },
      {
        Header: t("Min. No. of Units") as string,
        accessor: "minimum_sku_quantity",
      },
      {
        Header: t("Min. Order Qty") as string,
        accessor: "min_order_qty",
      },
      {
        Header: t(`Price ({{currencySymbol}}/UoM)`, {
          currencySymbol: tiersCurrencySymbol,
        }) as string,
        accessor: "price_per_uom",
      },
    ],
    [tiersCurrencySymbol, t]
  );

  const rowData = tableRowsData.map((rowData) => {
    return priceTierToTableRow(rowData.data, currencyCode);
  });

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data: rowData,
    });

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, headerGroupIndex) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
            {headerGroup.headers.map((column, columnIndex) => (
              <th key={columnIndex}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          const priceTierData = tableRowsData[rowIndex];
          if (priceTierData.status === "deleted") {
            // For deleted tiers, we leave them in the data, but just don't
            // render any UI for them. That way we can rely on indexes being
            // accurate.
            return <Fragment key={rowIndex} />;
          }
          if (isInEditMode && rowIndex === editRowIndex) {
            return (
              <EditableRow
                reactTableRow={row}
                tableRowsData={tableRowsData}
                editRowIndex={editRowIndex}
                finishEditingRow={finishEditingRow}
                cancelEditingRow={cancelEditingRow}
                productSkus={productSkus}
                currencySymbol={tiersCurrencySymbol}
                currencyCode={currencyCode}
                key={rowIndex}
              />
            );
          }
          return (
            <tr {...row.getRowProps()} key={rowIndex}>
              {row.cells.map((cell, cellIndex) => {
                return (
                  <td {...cell.getCellProps()} key={cellIndex}>
                    <TableRowTextBox>{cell.render("Cell")}</TableRowTextBox>
                  </td>
                );
              })}
              {isInEditMode && (
                <>
                  <td>
                    <TableRowButtonBox>
                      {editRowIndex === null && (
                        <EditButton
                          testid={`edit-price-tiers-row-${rowIndex}`}
                          onClick={() => startEditingRow(rowIndex)}
                        />
                      )}
                    </TableRowButtonBox>
                  </td>
                  <td>
                    <TableRowButtonBox>
                      {editRowIndex === null && (
                        <DeleteButton
                          testid={`delete-price-tiers-row-${rowIndex}`}
                          onClick={() => deleteRow(rowIndex)}
                        />
                      )}
                    </TableRowButtonBox>
                  </td>
                </>
              )}

              {addToCart && (
                <>
                  <td>
                    <TableRowButtonBox>
                      {editRowIndex === null && (
                        <InvisibleButton
                          onClick={() => {
                            addToCart({
                              product_sku:
                                tableRowsData[rowIndex].data.product_sku,
                              minimum_uom_quantity:
                                tableRowsData[rowIndex].data
                                  .minimum_uom_quantity,
                            });
                          }}
                        >
                          <CartIcon />
                        </InvisibleButton>
                      )}
                    </TableRowButtonBox>
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};
