import React, { useContext, useEffect, useState } from "react";
import { SettingsCard } from "../../../../../layout/portalPageLayout";
import styled from "styled-components/macro";
import {
  EditButton,
  CancelButtonSmall,
} from "../../../../../components/Buttons/Buttons";
import useSWR from "swr";
import { getCustomLabel, useStoreState } from "../../../../../util/util";

import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { H3, H6 } from "../../../../../components/Typography/Typography";
import type {
  OptionType,
  ProductSelectorCSVDocument,
  StorefrontMetaData,
  TagClassificationConfig,
} from "../../../../../types/types";
import { useTranslation } from "react-i18next";
import { Flex } from "../../../../../layout/FormLayout";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import type { AxiosError } from "axios";
import { EditProductSelector } from "./EditProductSelector";
import {
  InfoBlockRow,
  InfoBlockSmall,
} from "../../../../../components/InfoBlocks/InfoBlocks";
import { endpoints } from "../../../../../endpoints";
import { useCookies } from "react-cookie";
import { FileCard } from "../../../../../components/FileCard/FileCard";
import { ProductSelectorGuide } from "./ProductSelectorGuide";
import type { PortfolioControlsSchema } from "../../../../../types/types.PIM";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProductSelectorTab = () => {
  const { storefront_id, storefront_metadata, slug } = useStoreState();
  const { t } = useTranslation();
  const { notifyError } = useContext(Notifications);
  const [editProductSelector, setEditProductSelector] = useState(false);
  const [selectedSelectors, setSelectedSelectors] = useState<OptionType[]>();
  const [cookies] = useCookies([`preferred-language-${slug}`]);
  const [isCSVEnabled, setIsCSVEnabled] = useState(
    storefront_metadata.product_selector_csv_upload_enabled
  );
  const [isProductSelectorEnabled, setIsProductSelectorEnabled] =
    useState(false);
  const [productSelectorCSV, setProductSelectorCSV] =
    useState<ProductSelectorCSVDocument | null>(
      storefront_metadata.product_selector_csv_document
    );

  const { data: filtersSettings } = useSWR<
    { data: PortfolioControlsSchema[] },
    Error
  >(`/v2/storefronts/${storefront_id}/portfolio-controls`);

  const { data: customLabels } = useSWR<TagClassificationConfig[]>(
    storefront_id
      ? endpoints.v2_storefronts_id_products_filters_customLabels(storefront_id)
      : null
  );

  const { data: filters, mutate: mutateFilters } = useSWR<
    { selectors: { name: string; order: string }[] },
    AxiosError
  >(endpoints.v2_storefronts_id_product_selectors(storefront_id));

  const viewProductSelectorCSV = () => {
    if (productSelectorCSV) {
      window.open(productSelectorCSV.signed_url, "_blank");
    } else {
      notifyError(t("Operation failed, something went wrong."));
    }
  };

  const { mutate: storeFrontMetadataMutate } = useSWR<
    StorefrontMetaData,
    AxiosError<StorefrontMetaData>
  >(endpoints.v1_storefronts_id_metadata(storefront_id));

  const handleSaveChanges = () => {
    storeFrontMetadataMutate();
    setEditProductSelector(false);
    mutateFilters();
  };

  useEffect(() => {
    if (storefront_metadata) {
      setIsProductSelectorEnabled(
        storefront_metadata.is_product_selector_enabled
      );
      setIsCSVEnabled(storefront_metadata.product_selector_csv_upload_enabled);

      setProductSelectorCSV(storefront_metadata.product_selector_csv_document);
    }
  }, [storefront_metadata]);

  useEffect(() => {
    if (filters && customLabels) {
      setSelectedSelectors(
        filters.selectors.map((selector) => {
          return {
            value: selector.name,
            label: getCustomLabel({
              filter_type: selector.name,
              tag_classification_configs: customLabels,
              preferred_language: cookies[`preferred-language-${slug}`],
            }),
          };
        })
      );
    }
  }, [filters, cookies, slug, customLabels]);

  return (
    <SettingsCard>
      <Row>
        <H3>{t("Product Selector")}</H3>
        {!editProductSelector && (
          <EditButton
            testid={"edit-product-selector"}
            onClick={() => {
              storeFrontMetadataMutate();
              mutateFilters();
              setEditProductSelector(true);
            }}
            title={t("Edit Product Selector")}
          />
        )}
        {editProductSelector && (
          <CancelButtonSmall
            cancelText={t("Close")}
            onClick={() => {
              storeFrontMetadataMutate();
              setEditProductSelector(false);
            }}
          />
        )}
      </Row>
      {!editProductSelector && (
        <>
          <Flex
            style={{ flexDirection: "column", gap: "8px", marginTop: "20px" }}
          >
            <Flex style={{ gap: "8px" }}>
              <ToggleSwitch
                disabled={!editProductSelector}
                isChecked={isProductSelectorEnabled}
                onClick={() => setIsProductSelectorEnabled((prev) => !prev)}
              />
              <H6>{t("Product Selector")}</H6>
            </Flex>
          </Flex>
          <ProductSelectorGuide />
          {isProductSelectorEnabled &&
            selectedSelectors &&
            selectedSelectors.length > 0 && (
              <div style={{ margin: "20px 0" }}>
                {selectedSelectors?.map((selector, index) => {
                  return (
                    <InfoBlockRow style={{ padding: "10px 0" }}>
                      <InfoBlockSmall
                        header={`Selector ${index + 1}`}
                        content={selector.label}
                      />
                    </InfoBlockRow>
                  );
                })}
              </div>
            )}
          {isProductSelectorEnabled && isCSVEnabled && productSelectorCSV && (
            <div style={{ maxWidth: "550px" }}>
              <FileCard
                file={productSelectorCSV}
                viewFile={viewProductSelectorCSV}
              />
            </div>
          )}
        </>
      )}
      {editProductSelector && (
        <EditProductSelector
          handleFormSubmit={handleSaveChanges}
          selectedOptions={selectedSelectors}
          filtersSettings={filtersSettings?.data}
          customLabels={customLabels}
        />
      )}
    </SettingsCard>
  );
};
