import React, { useEffect, useRef, useState } from "react";
import { Modal } from "../Modal/Modal";
import { PrivacyPolicy } from "../PrivacyPolicy/PrivacyPolicy";
import { useTranslation } from "react-i18next";
import { checkTextOverflow, usePolicyDocuments } from "../../util/util";
import { ClickableLink, ClickableLinkOverflow } from "../Typography/Typography";
import { ErrorHandler } from "../../ErrorHandler";
import ReactTooltip from "react-tooltip";

interface PrivacyPolicyLinkPropsBase {
  text?: string;
  tip_position?: "right" | "left" | "top" | "bottom";
  can_overflow?: boolean;
}

interface PrivacyPolicyLinkAgilisPolicy extends PrivacyPolicyLinkPropsBase {
  agilisPolicy: true;
  customPolicy?: never;
}

interface PrivacyPolicyLinkCustomPolicy extends PrivacyPolicyLinkPropsBase {
  agilisPolicy?: never;
  customPolicy: true;
}

type PrivacyPolicyLinkProps =
  | PrivacyPolicyLinkAgilisPolicy
  | PrivacyPolicyLinkCustomPolicy;

export const PrivacyPolicyLink = ({
  text,
  agilisPolicy,
  customPolicy,
  tip_position = "right",
  can_overflow = false,
}: PrivacyPolicyLinkProps) => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [text_overflow, set_text_overflow] = useState(false);
  const openPrivacyModal = () => setShowPrivacyModal(true);
  const closePrivacyModal = () => setShowPrivacyModal(false);
  const { t } = useTranslation();
  const { privacyPolicy } = usePolicyDocuments();

  const ref = useRef<HTMLAnchorElement>(null);

  const handlePrivacyPolicyLink = () => {
    if (customPolicy && privacyPolicy) {
      window.open(privacyPolicy!!.signed_url, "_blank");
    } else if (agilisPolicy) {
      openPrivacyModal();
    } else {
      openPrivacyModal();
      ErrorHandler.report(
        "attempted to open custom privacy policy but none was found"
      );
    }
  };

  useEffect(() => {
    const is_text_overflow = ref.current
      ? checkTextOverflow(ref.current).widthOverflow
      : false;
    set_text_overflow(is_text_overflow);
  }, []);

  const updated_text = text ? text : t("Privacy Policy");

  return can_overflow ? (
    <>
      <ClickableLinkOverflow
        data-for="privacy-policy-tooltip"
        data-tip={text_overflow ? updated_text : ""}
        onClick={handlePrivacyPolicyLink}
        href={"#"}
        ref={ref}
      >
        {updated_text}
      </ClickableLinkOverflow>
      <Modal closeModal={closePrivacyModal} show={showPrivacyModal}>
        <PrivacyPolicy />
      </Modal>
      <ReactTooltip
        id="privacy-policy-tooltip"
        place={tip_position}
        data-html={true}
        effect="solid"
        offset={{ left: 6 }}
      />
    </>
  ) : (
    <>
      <ClickableLink onClick={handlePrivacyPolicyLink} href={"#"}>
        {text ? text : t("Privacy Policy")}
      </ClickableLink>
      <Modal closeModal={closePrivacyModal} show={showPrivacyModal}>
        <PrivacyPolicy />
      </Modal>
    </>
  );
};
