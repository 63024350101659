import { Row } from "../Layout/Layout";
import type {
  IOrderItem,
  QuoteRequestItem,
  SampleRequestDetail,
  SampleRequestIndividualItem,
} from "../../types/types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import type { OrderItemToBeCreated } from "../../pages/Seller/SellerCreateOrder/CreateOrderItemCard";
import { getCasNumber } from "../../util/QuotesAndOrders";
import { DetailsDark, DetailsLight, HeadingText } from "./TransactionItem";
import { TrimmedName } from "../../pages/admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";

export const RowJustifyStart = styled(Row)`
  justify-content: flex-start;
  width: 100%;
`;

const productDetailsSpacing = "8px";

export const ProductName = styled(HeadingText)`
  margin-bottom: ${productDetailsSpacing};
`;

export const ProductDetailsLight = styled(DetailsLight)`
  margin-bottom: ${productDetailsSpacing};
  margin-left: 14px;
  &:first-child {
    margin-left: 0px;
  }
`;

/**
 * The typical top left content for a transaction item (quote, order, sample
 * request, etc.).  Contains details about the product and application.
 */
export const TransactionItemTopLeft = ({
  item,
  index,
}: {
  item: QuoteRequestItem | IOrderItem | OrderItemToBeCreated;
  index: number;
}) => {
  const { t } = useTranslation();
  const productName = item.product.name;
  const casNumber = getCasNumber(item) || undefined;
  const erpNumber = item.sku?.erp_system_id || undefined;
  const applicationName = item.product_applications[0]?.name
    ? `${t("Application: ")} ${item.product_applications[0]?.name}`
    : item.custom_application
    ? `${t("Application - Other: ")} ${item.custom_application}`
    : undefined;

  return (
    <TransactionItemTopLeftGenericProps
      productName={productName}
      casNumber={casNumber}
      erpNumber={erpNumber}
      applicationName={applicationName}
      index={index}
    />
  );
};

/**
 * The top left content for a transaction item, with generic (atomic) props.
 * Contains details about the product and application.
 */
const TransactionItemTopLeftGenericProps = ({
  productName,
  casNumber,
  erpNumber,
  applicationName,
  skuName,
  index,
}: {
  productName: string;
  casNumber?: string;
  erpNumber?: string;
  applicationName?: string;
  skuName?: string;
  index: number;
}) => {
  const { t } = useTranslation();

  const getText = () =>
    `${skuName ?? ""} ${casNumber ? `${t("CAS #")} ${casNumber}` : ""} ${
      erpNumber ? `${t("ERP #")} ${erpNumber}` : ""
    }`;
  return (
    <div>
      <TrimmedName text={productName} Content={ProductName} />
      <RowJustifyStart>
        <TrimmedName text={getText()} Content={ProductDetailsLight} />
      </RowJustifyStart>
      {applicationName && (
        <RowJustifyStart>
          <DetailsDark data-testid={`application-${index}`}>
            {applicationName}
          </DetailsDark>
        </RowJustifyStart>
      )}
    </div>
  );
};

/**
 * The top left content for a sample request transaction item. Contains details
 * about the product and first application.
 */
export const TransactionItemTopLeftSampleRequest = ({
  item,
  index,
}: {
  item: SampleRequestDetail | SampleRequestIndividualItem;
  index: number;
}) => {
  const { t } = useTranslation();
  let props: Parameters<typeof TransactionItemTopLeftGenericProps>[0] = {
    productName: item.product.name,
    index: index,
  };

  if (item.product.identifiers?.cas_number) {
    props.casNumber = item.product.identifiers.cas_number;
  }

  if ("sku" in item && item.sku !== null) {
    props.erpNumber = item.sku.erp_system_id;
    props.skuName = item.sku.packaging_type?.name;
  }

  if (
    "applications" in item &&
    item.applications !== undefined &&
    item.applications.length > 0
  ) {
    props.applicationName = `${"Application: "} ${item.applications[0].name}`;
  }

  if ("custom_application" in item && !!item.custom_application) {
    props.applicationName = `${t("Application - Other: ")} ${
      item.custom_application
    }`;
  }

  return <TransactionItemTopLeftGenericProps {...props} />;
};
