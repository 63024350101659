import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import "yup-phone";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CancelButton,
  PrimaryButtonMedium,
} from "../../../../../components/Buttons/Buttons";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import type {
  Tenant,
  OptionType,
  SupportedLanguage,
  AgilisUsersPaginatedOutput,
} from "../../../../../types/types";
import { TextField } from "../../../../../components/TextFields/TextFields";
import Axios from "axios";
import { Controller } from "react-hook-form";
import styled from "styled-components/macro";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  RadioButtonContainer,
  SubmitButtonContainer,
} from "../../../../../layout/FormLayout";
import { strings } from "../../../../../util/strings";
import { useValidateEmail } from "../../../../../util/useValidateEmail";
import {
  useStoreState,
  useFormWrapper,
  useSupportedLanguages,
  makeUrlWithParams,
  convertUserToOption,
} from "../../../../../util/util";
import { endpoints } from "../../../../../endpoints";
import {
  countryCodeMap,
  getCountryCode,
  getPhoneCodeOption,
  getPhoneCodesOptions,
  getPhoneNumber,
} from "../../../../../util/phone";
import { useAuthContext } from "../../../../../components/Auth";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import { Modal } from "../../../../../components/Modal/Modal";
import { H4Normal } from "../../../../../components/Typography/Typography";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import useSWR from "swr";
import { useDebounce } from "../../../../../util/hooks";
import { StringParam, useQueryParams } from "use-query-params";

const ModalWrapper = styled.div`
  padding: 50px 90px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const WarningTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.warningTextColor};
  margin-bottom: 30px;
`;

const EditWrapper = styled.div`
  max-width: 400px;
`;

const SaveButton = styled(PrimaryButtonMedium)`
  min-width: 180px;
`;

type EditGeneralInfoProps = {
  tenant: Tenant;
  closeEditGeneralSection: () => void;
  is_buyer_activated: boolean;
};
interface UpdateGeneralInfoForm {
  primary_contact_id: OptionType;
  name: string;
  doing_business_as: string;
  external_id: string;
  crm_id: string;
  country_code: OptionType;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  contact_phone: string;
  preferred_language: OptionType<SupportedLanguage>;
  postal_code: string;
}

interface PatchTenant {
  name: string;
  doing_business_as: string;
  external_id: string;
  crm_id: string;
  primary_contact_id?: string;
  primary_contact?: {
    firstname?: string;
    lastname?: string;
    email_address?: string;
    phone_number?: string;
    preferred_language: SupportedLanguage;
  };
}

// TODO: get this to type check without the `any`. Maybe use zod instead?
const CreateNewTenantSchema = (t: TFunction, is_buyer_activated: boolean) =>
  yup.lazy((formValues: any) => {
    return yup.object().shape({
      name: yup.string().required(strings(t).thisIsARequiredField),
      doing_business_as: is_buyer_activated
        ? yup.string().notRequired()
        : yup.string().required(strings(t).thisIsARequiredField),
      external_id: yup.string().notRequired(),
      crm_id: yup.string().nullable().notRequired(),
      contact_first_name: yup
        .string()
        .required(strings(t).thisIsARequiredField),
      contact_last_name: yup.string().required(strings(t).thisIsARequiredField),
      contact_email: yup
        .string()
        .email()
        .required(strings(t).thisIsARequiredField),
      contact_phone: yup
        .string()
        .phone(
          countryCodeMap.get(formValues.country_code.value),
          false,
          strings(t).phoneNumberMustBeValid
        )
        .required(strings(t).thisIsARequiredField),
      preferred_language: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required(strings(t).thisIsARequiredField),
      country_code: yup
        .object()
        .shape({
          label: yup.string().required(strings(t).thisIsARequiredField),
          value: yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
    });
  });

const CreateNewTenantPointOfContactSchema = (
  t: TFunction,
  is_buyer_activated: boolean
) =>
  yup.lazy((formValues: any) => {
    return yup.object().shape({
      name: yup.string().required(strings(t).thisIsARequiredField),
      doing_business_as: is_buyer_activated
        ? yup.string().notRequired()
        : yup.string().required(strings(t).thisIsARequiredField),
      external_id: yup.string().notRequired(),
      crm_id: yup.string().nullable().notRequired(),
      preferred_language: yup
        .object()
        .shape({
          value: yup.string(),
          label: yup.string(),
        })
        .required(strings(t).thisIsARequiredField),
      primary_contact_id: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(strings(t).thisIsARequiredField),
    });
  });

export function EditGeneralInfo({
  tenant,
  closeEditGeneralSection,
  is_buyer_activated,
}: EditGeneralInfoProps) {
  const { roleIsBuyerAdmin, roleIsDistributorAdmin } = useAuthContext();
  const { storefront_id, tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { validateEmail } = useValidateEmail();
  const [submitting, setSubmitting] = useState(false);
  const [formInputs] = useState<UpdateGeneralInfoForm>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { getLanguageLabel } = useSupportedLanguages();
  const { t } = useTranslation();
  const { roleIsSellerAdmin, roleIsSomeKindOfBuyer } = useAuthContext();

  const [pointOfContactType, setPointOfContactType] =
    useState<string>("manual");
  const [query, setQuery] = useQueryParams({
    q: StringParam,
  });
  const [userSearchQuery, setUserSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(userSearchQuery, 1000);

  const isBuyerOrDistributorAdmin = roleIsBuyerAdmin || roleIsDistributorAdmin;

  const getPointOfContactUserOption = () => {
    if (tenant.primary_contact_id) {
      const userOption = {
        value: tenant.primary_contact_id,
        label: `${tenant.primary_contact.first_name} ${tenant.primary_contact.last_name} - ${tenant.primary_contact.email_address}`,
      };
      return userOption;
    }
    return undefined;
  };

  const {
    handleSubmit,
    register,
    formState,
    control,
    setValue,
    errors,
    reset,
  } = useFormWrapper({
    shouldUnregister: false,
    resolver: yupResolver(
      pointOfContactType === "manual"
        ? CreateNewTenantSchema(t, is_buyer_activated)
        : CreateNewTenantPointOfContactSchema(t, is_buyer_activated)
    ),
    defaultValues: {
      name: tenant.name,
      type: { value: tenant.type, label: tenant.type },
      doing_business_as: tenant.doing_business_as,
      crm_id: tenant?.crm_id,
      external_id: tenant.external_identity?.external_id,
      contact_first_name: tenant.primary_contact?.first_name,
      contact_last_name: tenant.primary_contact?.last_name,
      contact_email: tenant.primary_contact?.email_address,
      contact_phone: getPhoneNumber(tenant.primary_contact?.phone_number),
      primary_contact_id: getPointOfContactUserOption(),
      country_code: getPhoneCodeOption(
        getCountryCode(tenant.primary_contact?.phone_number)
      ),
      preferred_language: {
        value: tenant.primary_contact?.preferred_language ?? "en",
        label:
          tenant?.primary_contact?.preferred_language ?? null
            ? getLanguageLabel(tenant.primary_contact.preferred_language)
            : "English",
      },
    },
  });

  const changePointOfContact = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value) {
      setPointOfContactType(e.currentTarget.value);
    }
  };

  const handleUserSearch = (value: string) => {
    if (value) {
      setUserSearchQuery(value);
    } else {
      handleClearSearch();
    }
  };

  const handleClearSearch = () => {
    setUserSearchQuery("");
    setQuery({ q: undefined });
  };

  const usersQuery =
    storefront_id && tenant
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_tenants_id_users(
            storefront_id,
            tenant.id
          ),
          {
            offset: 0,
            limit: 100,
            order_by: "asc",
            q: debouncedSearchQuery || null,
          }
        )
      : null;

  const { data: usersData } = useSWR<AgilisUsersPaginatedOutput>(usersQuery);

  const users = usersData?.data.filter((user) => user.is_active);
  const userOptions = users?.map(convertUserToOption) ?? [];

  const onSubmit = (values: UpdateGeneralInfoForm) => {
    submitForm(values);
  };

  const submitForm = async (values: UpdateGeneralInfoForm) => {
    setSubmitting(true);
    if (pointOfContactType === "manual") {
      const validEmail = await validateEmail({
        email: values.contact_email,
        source: "Manage Seller Edit company general info",
      });
      if (!validEmail) {
        setSubmitting(false);
        return;
      }

      const primaryContact: PatchTenant = {
        name: values.name,
        doing_business_as: values.doing_business_as,
        external_id: values.external_id,
        crm_id: values.crm_id,
        primary_contact: {
          firstname: values.contact_first_name,
          lastname: values.contact_last_name,
          email_address: values.contact_email,
          phone_number: `${values.country_code.value}${values.contact_phone}`,
          preferred_language: values.preferred_language.value,
        },
      };

      const patchPrimaryContact = Axios.patch(
        endpoints.v1_storefronts_id_tenants_id(storefront_id, tenant.id),
        primaryContact
      );

      try {
        await patchPrimaryContact;
        notifySuccess(t("General info has been updated successfully."));
        setSubmitting(false);
        closeEditGeneralSection();
      } catch (error) {
        notifyError(
          t("There was an error updating the general info, please try again."),
          {
            error,
          }
        );
        setSubmitting(false);
      }
    } else {
      const primaryContact: PatchTenant = {
        name: values.name,
        doing_business_as: values.doing_business_as,
        external_id: values.external_id,
        crm_id: values.crm_id,
        primary_contact_id: values.primary_contact_id.value,
      };

      const patchPrimaryContact = Axios.patch(
        endpoints.v1_storefronts_id_tenants_id(storefront_id, tenant.id),
        primaryContact
      );

      try {
        await patchPrimaryContact;
        notifySuccess(t("General info has been updated successfully."));
        setSubmitting(false);
        closeEditGeneralSection();
      } catch (error) {
        notifyError(
          t("There was an error updating the general info, please try again."),
          {
            error,
          }
        );
        setSubmitting(false);
      }
    }
  };

  const cancelEditAddress = () => {
    reset();
    setShowConfirmationModal(false);
  };

  const proceedAddressChanges = () => {
    if (formInputs) {
      setShowConfirmationModal(false);
      submitForm(formInputs);
    }
  };

  useEffect(() => {
    if (tenant.primary_contact_id) {
      setPointOfContactType("existing");
    }
  }, [tenant]);

  useEffect(() => {
    if (pointOfContactType === "manual" && tenant.primary_contact_id) {
      setValue("contact_first_name", undefined);
      setValue("contact_last_name", undefined);
      setValue("contact_email", undefined);
      setValue("contact_phone", undefined);
      setValue("country_code", { label: "", value: "" });
    }
  }, [pointOfContactType, tenant, setValue]);

  return (
    <EditWrapper>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="name"
          label={t("Company Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="doing_business_as"
          label={t("Doing business as")}
          theref={register({
            required: is_buyer_activated ? false : true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        {!roleIsSomeKindOfBuyer && (
          <TextField
            name="crm_id"
            label={t("CRM ID")}
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            readOnly={!roleIsSellerAdmin}
            type="text"
          />
        )}
        {tenant_id !== tenant.id && (
          <TextField
            name="external_id"
            label={t("Customer ID")}
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            type="text"
            disabled={isBuyerOrDistributorAdmin}
          />
        )}
        <H4Normal style={{ marginTop: "35px" }}>
          {t("Primary Contact")}
        </H4Normal>
        <RadioButtonContainer>
          <RadioButton
            name={"existingUser"}
            value="existing"
            checked={pointOfContactType === "existing"}
            optionTitle="Existing User"
            handleChange={changePointOfContact}
          />
          <RadioButton
            name={"manualUser"}
            value="manual"
            checked={pointOfContactType === "manual"}
            optionTitle="Add Manually"
            handleChange={changePointOfContact}
          />
        </RadioButtonContainer>
        {pointOfContactType === "existing" && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="primary_contact_id"
            onInputChange={handleUserSearch}
            placeholder="Select User"
            options={userOptions}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
          />
        )}
        {pointOfContactType === "manual" && (
          <>
            <TextField
              name="contact_first_name"
              label={t("First Name")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="contact_last_name"
              label={t("Last Name")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="contact_email"
              label={t("Email")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="email"
            />
            <Flex>
              <Flex1>
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="country_code"
                  autoComplete="countryCode"
                  placeholder={t("Country Code")}
                  id="countryCodeSelectBox"
                  options={getPhoneCodesOptions()}
                  rules={{
                    required: true,
                  }}
                  errors={errors}
                  formState={formState}
                />
              </Flex1>
              <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                <TextField
                  name="contact_phone"
                  label={t("Phone Number")}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  errors={errors}
                  type="tel"
                />
              </Flex2>
            </Flex>
          </>
        )}
        <SubmitButtonContainer>
          <SaveButton loading={submitting}>{t("Save your changes")}</SaveButton>
        </SubmitButtonContainer>
      </Form>

      <Modal
        overlay
        show={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        modalWidth={"680px"}
      >
        <ModalWrapper>
          <WarningTitle>
            {t(
              "This address is currently used in an active transaction or sample request"
            )}
          </WarningTitle>
          <div>
            {t(
              "Your changes made to this address will be saved, but will not be applied to any active transactions or sample requests. Please contact Support if you would like to update any active transactions or sample requests with these changes."
            )}
          </div>
          <div>{t("Would you like to proceed?")}</div>
          <PrimaryButtonMedium
            onClick={proceedAddressChanges}
            style={{ marginTop: "30px" }}
          >
            {t("Proceed")}
          </PrimaryButtonMedium>
          <br />
          <CancelButton
            onClick={cancelEditAddress}
            style={{ padding: "20px 30px" }}
          >
            {t("Cancel")}
          </CancelButton>
        </ModalWrapper>
      </Modal>
    </EditWrapper>
  );
}
