import moment from "moment";
import type {
  Fee,
  IMessageRequestJson,
  IPurchaseOrder,
  IQuoteRequest,
  ISampleRequest,
  TransactionEvent,
  User,
} from "../../types/types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Avatar from "react-avatar";
import type { DefaultTheme } from "styled-components/macro";
import styled, { ThemeContext } from "styled-components/macro";
import { Card } from "../../layout/portalPageLayout";
import { PrimaryButtonXSmall } from "../Buttons/Buttons";
import {
  DesktopErrorMessage,
  DesktopInfoMessage,
  DesktopNotification,
  DesktopSuccessMessage,
  DesktopWarningMessage,
} from "../Notifications/Notifications";
import type { IStatusProps } from "../Status/Status";
import { StatusLeft } from "../Status/Status";
import { H4, SoftHeader } from "../Typography/Typography";
import { screenSize } from "../../theme";
import { useTranslation } from "react-i18next";
import type { TFunction } from "i18next";

interface IMessageWrapper {
  self?: boolean;
}

interface ITimelineProps {
  messages: TransactionEvent[];
  title?: string;
  fetchingData?: boolean;
  loggedInUser: User;
  sendMessage: (message: string) => void;
  quote?: IQuoteRequest;
  order?: IPurchaseOrder;
  sampleRequest?: ISampleRequest;
}

type TimelineMessages = IMessage | IEventMessage;

interface IMessage {
  id: string;
  text?: string;
  status?: IStatusProps;
  type: "message";
  attachments?: IMessageAttachment[];
  detailsList?: {
    title: string;
    details: IMessageDetailItem[];
  };
  createdTime: string;
  creatorName: string;
  creatorId: string;
}

interface IEventMessage {
  id: string;
  text: string;
  type: "event";
  eventType: string;
  createdTime: string | Date;
  creatorId: string;
}

interface IMessageAttachment {
  text: string;
  url: string;
}

interface IMessageDetailItem {
  name: string;
  value: string;
}

const TimelineWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  min-width: 320px;
`;

const TimelineHeader = styled.div`
  padding: 20px 25px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
`;

const Conversation = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 20px;
  scroll-behavior: smooth;
  @media ${screenSize.medium} {
    padding-left: 0;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.secondaryTextColor};
  margin: 15px 25px 15px 15px;
  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
    margin: auto;
  }
  &:before {
    margin-right: 30px;
  }
  &:after {
    margin-left: 30px;
  }
`;

const MessageWrapper = styled.div<IMessageWrapper>`
  display: flex;
  width: 100%;
  flex-direction: ${({ self }) => (self ? "row-reverse" : "row")};

  padding: 10px;
`;

const AvatarWrapper = styled.div`
  width: 28px;
  height: 28px;
`;

const MessageDetails = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 10px;
`;

const MessageAttachments = styled.div`
  max-width: 100%;
  margin: 0 0 5px;
  a {
    font-size: ${({ theme }) => theme.fontSizes.small};
    line-height: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.primaryLinkColor};
    :hover {
      color: ${({ theme }) => theme.primaryLinkHover};
    }
  }
`;

const StatusWrapper = styled.div`
  margin-bottom: 5px;
  & span {
    font-size: ${({ theme }) => theme.fontSizes.small} !important;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
`;

const TextMessage = styled.div<IMessageWrapper>`
  display: flex;
  background: ${({ self, theme }) =>
    self ? theme.badgeColor : theme.colors.secondaryLightGray};
  padding: 9px 15px;
  color: ${({ self, theme }) =>
    self ? theme.primaryButtonTextColor : theme.primaryTextColor};
  border-radius: 3px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  width: fit-content;
  align-self: ${({ self }) => (self ? "flex-end" : "flex-start")};
  flex-direction: column;
`;

const EventMessage = styled.div`
  ${DesktopSuccessMessage} {
    width: 100%;
  }
  ${DesktopInfoMessage} {
    width: 100%;
  }
  ${DesktopWarningMessage} {
    width: 100%;
  }
  ${DesktopErrorMessage} {
    width: 100%;
  }
  width: 100%;
  padding: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const MessageTime = styled.div<IMessageWrapper>`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.secondaryTextColor};
  align-self: ${({ self }) => (self ? "flex-end" : "flex-start")};
  margin-top: 5px;
`;

const MessageTimeCenter = styled(MessageTime)`
  align-self: center;
`;

const DetailsList = styled(Card)`
  margin: 0px 0 10px;
`;

const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const SendMessageWrapper = styled.div`
  width: 100%;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  padding: 10px 5px 10px 5px;
  align-items: flex-end;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
`;

const SendMessageButton = styled(PrimaryButtonXSmall)`
  justify-self: flex-start;
  width: 50px;
  height: 38px;
  margin: 0 10px;
  padding: 5px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

const NewMessageWrapper = styled.div`
  border-radius: 25px;
  border: solid 0.6px ${(props) => props.theme.primaryBorder};
  background-color: ${(props) => props.theme.secondaryBG};
  width: 100%;
  padding: 7px 10px 5px;
`;

const NewMessageInput = styled.textarea`
  border: 0;
  background: transparent;
  width: 100%;
  outline: none;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  max-height: 100px;
  resize: none;
`;

function getCreatorName(creator: User): string {
  return `${creator.firstname} ${creator.lastname}`;
}

function convertTransactionToTimelineMessage({
  transactionEvent,
  order,
  t,
}: {
  transactionEvent: TransactionEvent;
  order: IPurchaseOrder;
  t: (s: string, options?: Object) => string;
}): IEventMessage {
  switch (transactionEvent.purchase_order_action) {
    case "accept":
      return {
        text: t(`Order {{orderNumber}} was accepted`, {
          orderNumber: order.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "success",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "progress":
      return {
        text: t(`Order {{orderNumber}} is in progress`, {
          orderNumber: order.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "info",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "complete":
      return {
        text: `Order ${order.number} was completed`,
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "success",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "invoice":
      return {
        text: t(`Invoice for order {{orderNumber}} was created`, {
          orderNumber: order.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "info",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "decline":
      return {
        text: t(`Order {{orderNumber}} was declined`, {
          orderNumber: order.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "error",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "cancel":
      return {
        text: t(`Order {{orderNumber}} was cancelled`, {
          orderNumber: order.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "error",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "pending_cancellation":
      return {
        text: t(
          `${transactionEvent.creator.firstname} ${transactionEvent.creator.lastname} requested to cancel Order {{orderNumber}} Reason for cancellation: ${transactionEvent.cancellation_reason}`,
          {
            orderNumber: order.number,
          }
        ),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "warning",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "resume_order":
      return {
        text: t(`Order {{orderNumber}} was resumed`, {
          orderNumber: order.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "info",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "ship_order":
      return {
        text: t(`Order {{orderNumber}} was shipped`, {
          orderNumber: order.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "info",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };

    default:
      return {
        createdTime: transactionEvent.created_at,
        creatorId: transactionEvent.creator_id,
        text: t("Error retrieving message"),
        type: "event",
        eventType: "error",
        id: transactionEvent.id,
      };
  }
}

function convertTransactionToQuoteTimelineMessage({
  transactionEvent,
  user,
  quote,
  t,
}: {
  transactionEvent: TransactionEvent;
  user: User;
  quote: IQuoteRequest;
  t: (s: string, options?: Object) => string;
}): IEventMessage | IMessage {
  switch (transactionEvent.quote_request_action) {
    case "accept":
      return {
        text: t(`Order {{number}} was placed`, { number: quote.number }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "success",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "respond":
      return {
        text: t(`Quote {{number}} was responded to`, { number: quote.number }),
        createdTime: transactionEvent.created_at,
        creatorId: transactionEvent.creator_id,
        type: "event",
        eventType: "info",
        id: transactionEvent.id,
      };
    case "request":
      return {
        text: t(`Quote {{number}} was requested`, { number: quote.number }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "info",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "cancel":
      return {
        text: t(`Quote {{number}} was cancelled`, { number: quote.number }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "error",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "decline":
      return {
        text: t(`Quote {{number}} was declined`, { number: quote.number }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "error",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    default:
      return {
        createdTime: transactionEvent.created_at,
        creatorId: transactionEvent.creator_id,
        text: t("Error retrieving message"),
        type: "event",
        eventType: "error",
        id: transactionEvent.id,
      };
  }
}

function convertTransactionToMessage({
  message,
  user,
}: {
  message: TransactionEvent;
  user: User;
}): IMessage {
  return {
    // TODO: function to try/catch this parse
    text: message.request_json?.message,
    id: message.id,
    createdTime: message.created_at,
    creatorId: message.creator_id,
    type: "message",
    creatorName: getCreatorName(message.creator),
  };
}

function convertTransactionToSampleTimelineMessage({
  transactionEvent,
  sampleRequest,
  t,
}: {
  transactionEvent: TransactionEvent;
  sampleRequest: ISampleRequest;
  t: (s: string, options?: Object) => string;
}): IEventMessage | IMessage {
  switch (transactionEvent.sample_request_action) {
    case "progress":
      return {
        text: t(`Sample request {{number}} is in progress`, {
          number: sampleRequest.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event",
        eventType: "info",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "accept":
      return {
        text: t(`Sample request {{number}} was accepted`, {
          number: sampleRequest.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event",
        eventType: "success",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "decline":
      return {
        text: t(`Sample request {{number}} was declined`, {
          number: sampleRequest.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event",
        eventType: "error",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "cancel":
      return {
        text: t(`Sample request {{number}} was cancelled`, {
          number: sampleRequest.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event",
        eventType: "error",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "reject":
      return {
        text: t(`Sample request {{number}} was rejected`, {
          number: sampleRequest.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event",
        eventType: "error",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "ship_sample":
      return {
        text: t(`Sample request {{number}} was shipped`, {
          number: sampleRequest.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event",
        eventType: "success",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    case "complete":
      return {
        text: t(`Sample request {{number}} was completed`, {
          number: sampleRequest.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event",
        eventType: "success",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
    // Add additional cases for other `sample_request_action` values as needed.
    default:
      // Handle unknown or unhandled actions
      return {
        text: t(`Sample Request {{number}} was created`, {
          number: sampleRequest.number,
        }),
        createdTime: transactionEvent.created_at,
        type: "event", // always "event"
        eventType: "info",
        creatorId: transactionEvent.creator_id,
        id: transactionEvent.id,
      };
  }
}

function convertDocumentUploadToMessage({
  message,
  order,
}: {
  message: TransactionEvent;
  // In reality whenever this is called the order should be defined, because
  // documents are only associated with orders.
  order?: IPurchaseOrder;
}): IEventMessage {
  if (message.order_document?.kind === "invoice") {
    return {
      text: "Invoice was uploaded",
      createdTime: message.created_at,
      type: "event", // always "event"
      eventType: "info",
      creatorId: message.creator_id,
      id: message.id,
    };
  } else if (message.order_document?.kind === "purchase_order") {
    return {
      text: "A PO document was uploaded",
      createdTime: message.created_at,
      type: "event", // always "event"
      eventType: "info",
      creatorId: message.creator_id,
      id: message.id,
    };
  } else {
    console.error(
      "This event should be more specific and not fall through to this."
    );
    return {
      text: "A document was uploaded",
      createdTime: message.created_at,
      type: "event", // always "event"
      eventType: "info",
      creatorId: message.creator_id,
      id: message.id,
    };
  }
}

function convertPONumberToEvent({
  message,
}: {
  message: TransactionEvent;
}): IEventMessage | undefined {
  try {
    const purchaseOrderNumber = message.request_json;
    if (purchaseOrderNumber?.alternative_po_number) {
      return {
        text: `PO number ${purchaseOrderNumber.alternative_po_number} was added`,
        createdTime: message.created_at,
        type: "event", // always "event"
        eventType: "info",
        creatorId: message.creator_id,
        id: message.id,
      };
    }
  } catch (error) {
    console.error(
      "This event should be more specific and not fall through to this."
    );
    return {
      text: "A PO number was added",
      createdTime: message.created_at,
      type: "event", // always "event"
      eventType: "error",
      creatorId: message.creator_id,
      id: message.id,
    };
  }
}

function convertTranactionSplitToEvent({
  message,
  default_message,
}: {
  message: TransactionEvent;
  default_message: string;
}): IEventMessage {
  return {
    text: message?.request_json?.message ?? default_message,
    createdTime: message.created_at,
    type: "event", // always "event"
    eventType: "success",
    creatorId: message.creator_id,
    id: message.id,
  };
}

function convertDocumentDeleteToEvent({
  message,
}: {
  message: TransactionEvent;
}): IEventMessage | undefined {
  try {
    const documentDeleted = message.request_json;
    if (documentDeleted?.kind === "invoice") {
      return {
        text: "Invoice was deleted",
        createdTime: message.created_at,
        type: "event", // always "event"
        eventType: "error",
        creatorId: message.creator_id,
        id: message.id,
      };
    } else if (documentDeleted?.kind === "purchase_order") {
      return {
        text: "PO document was deleted",
        createdTime: message.created_at,
        type: "event", // always "event"
        eventType: "error",
        creatorId: message.creator_id,
        id: message.id,
      };
    } else if (documentDeleted?.kind === "shipping_document") {
      return {
        text: "Shipping document was deleted",
        createdTime: message.created_at,
        type: "event", // always "event"
        eventType: "error",
        creatorId: message.creator_id,
        id: message.id,
      };
    }
  } catch (error) {
    console.error(
      "This event should be more specific and not fall through to this."
    );
    return {
      text: "A document was deleted",
      createdTime: message.created_at,
      type: "event", // always "event"
      eventType: "error",
      creatorId: message.creator_id,
      id: message.id,
    };
  }
}

function handleSellerEdit({
  message,
  user,
  quote,
}: {
  message: TransactionEvent;
  user: User;
  quote: IQuoteRequest;
}): IMessage | IEventMessage {
  let json: IMessageRequestJson | null = null;

  try {
    if (message.request_json !== null) {
      json = message.request_json;
    }
  } catch (error) {
    return {
      createdTime: message.created_at,
      creatorId: message.creator_id,
      text: "Error retrieving message",
      type: "event",
      eventType: "error",
      id: message.id,
    };
  }

  if (json === null) {
    return {
      createdTime: message.created_at,
      creatorId: message.creator_id,
      text: "Error retrieving message",
      type: "event",
      eventType: "error",
      id: message.id,
    };
  }

  const details = [
    ...(json?.is_tax_exempt
      ? [
          {
            name: "Is tax exempt",
            value: json.is_tax_exempt ? "Yes" : "No",
          },
        ]
      : []),
    ...(json?.required_eta
      ? [
          {
            name: "ETA",
            value: moment
              .utc(json.required_eta)
              .local()
              .format(`MMMM DD, YYYY`),
          },
        ]
      : []),
    ...(json?.valid_to_date
      ? [
          {
            name: "valid until",
            value: moment
              .utc(json.valid_to_date)
              .local()
              .format(`MMMM DD, YYYY`),
          },
        ]
      : []),
    ...(json?.fees_to_add
      ? json.fees_to_add.map((fee: Fee) => ({
          name: fee.name,
          value: fee.amount.toString(),
        }))
      : []),
    ...(json?.fees_to_overwrite
      ? json.fees_to_overwrite.map((fee: Fee) => ({
          name: fee.name,
          value: fee.amount.toString(),
        }))
      : []),
    ...(message.payment_mode
      ? [
          {
            name: "Payment mode",
            value: message.payment_mode.name,
          },
        ]
      : []),
    ...(message.payment_term
      ? [
          {
            name: "Payment term",
            value: message.payment_term.name,
          },
        ]
      : []),
    ...(json?.price_per_unit
      ? [
          {
            name: "Price per unit",
            value: json.price_per_unit,
          },
        ]
      : []),
  ];

  return {
    status: {
      text: `${
        user.id === message.creator_id ? "You" : getCreatorName(message.creator)
      } just responded to quote ${quote.number}`,
      color: "blue",
    },

    detailsList: {
      title: "",
      details: details,
    },
    createdTime: message.created_at,
    creatorId: message.creator_id,
    type: "message",
    id: message.id,
    creatorName: getCreatorName(message.creator),
  };
}

function handleBuyerEdit({
  message,
  user,
  quote,
}: {
  message: TransactionEvent;
  user: User;
  quote: IQuoteRequest;
}): IMessage | IEventMessage {
  let json;

  try {
    json = message.request_json;
  } catch (error) {
    console.error(error);
  }

  if (json !== null) {
    const details = [
      ...(json?.required_eta
        ? [
            {
              name: "ETA",
              value: moment
                .utc(json.required_eta)
                .local()
                .format(`MMMM DD, YYYY`),
            },
          ]
        : []),
      ...(message?.product
        ? [
            {
              name: "Product",
              value: message?.product.name,
            },
          ]
        : []),
      ...(message?.product_sku
        ? [
            {
              name: "SKU",
              value: message.product_sku.packaging_type?.name ?? "--",
            },
          ]
        : []),
      ...(json?.no_of_units
        ? [
            {
              name: "No. of units",
              value: json.no_of_units,
            },
          ]
        : []),
      ...(message.payment_mode
        ? [
            {
              name: "Payment mode",
              value: message.payment_mode.name,
            },
          ]
        : []),
      ...(message.payment_term
        ? [
            {
              name: "Payment term",
              value: message.payment_term.name,
            },
          ]
        : []),
      ...(message.shipping_address
        ? [
            {
              name: "Shipping address",
              value: `${message.shipping_address.address1} ${message.shipping_address.address2}`,
            },
          ]
        : []),
    ];

    return {
      detailsList: {
        title: "",
        details: details,
      },
      status: {
        text: `${
          message.creator_id === user.id
            ? "You"
            : getCreatorName(message.creator)
        } updated quote ${quote.number}`,
        color: "blue",
      },
      createdTime: message.created_at,
      creatorId: message.creator_id,
      type: "message",
      id: message.id,
      creatorName: getCreatorName(message.creator),
    };
  }

  return {
    text: "Error retrieving message",
    createdTime: message.created_at,
    type: "event", // always "event"
    eventType: "error",
    creatorId: message.creator_id,
    id: message.id,
  };
}

type ConvertMessagesProps = {
  quote?: IQuoteRequest;
  order?: IPurchaseOrder;
  sampleRequest?: ISampleRequest;
  messages: TransactionEvent[];
  user: User;
  t: TFunction;
};

function convertMessages({
  quote,
  order,
  sampleRequest,
  messages,
  user,
  t,
}: ConvertMessagesProps) {
  // eslint-disable-next-line array-callback-return
  return messages.map((message) => {
    switch (message.event_type) {
      case "order_message":
        return convertTransactionToMessage({ message, user });
      case "quote_message":
        return convertTransactionToMessage({ message, user });
      case "sample_message":
        return convertTransactionToMessage({ message, user });
      case "status_update":
        if (message.purchase_order_action && order) {
          // Order will always exist if we have order actions
          return convertTransactionToTimelineMessage({
            transactionEvent: message,
            order,
            t,
          });
        }
        // quote_request_action:
        if (quote && message.quote_request_action) {
          return convertTransactionToQuoteTimelineMessage({
            transactionEvent: message,
            user,
            quote,
            t,
          });
        }

        // Handle sample request-related messages
        if (sampleRequest && message.sample_request_action) {
          return convertTransactionToSampleTimelineMessage({
            transactionEvent: message,
            sampleRequest,
            t,
          });
        }
        break;
      case "document_upload":
        return convertDocumentUploadToMessage({ message, order });
      case "document_delete":
        return convertDocumentDeleteToEvent({ message });
      case "purchase_order_number":
        return convertPONumberToEvent({ message });
      case "transaction_split":
        let default_message = t("Transaction Split");
        return convertTranactionSplitToEvent({ message, default_message });
      case "buyer_edit":
        if (quote) {
          return handleBuyerEdit({ message, user, quote });
        }
        break;
      case "seller_edit":
        if (quote) {
          return handleSellerEdit({ message, user, quote });
        }
        break;
      case "shipment_advice":
        // disabling this for type checking reasons.
        // eslint-disable-next-line no-case-declarations
        const shipmentEvent: IEventMessage = {
          text: `Shipment advice was added`,
          createdTime: message.created_at,
          type: "event", // always "event"
          eventType: "info",
          creatorId: message.creator_id,
          id: message.id,
        };
        return shipmentEvent;

      case "shipment_advice_changed":
        const shipmentChangeEvent: IEventMessage = {
          text: `Shipment advice changed`,
          createdTime: message.created_at,
          type: "event", // always "event"
          eventType: "info",
          creatorId: message.creator_id,
          id: message.id,
        };
        return shipmentChangeEvent;

      case "shipment_advice_deleted":
        const shipmentDeletedEvent: IEventMessage = {
          text: `Shipment advice removed`,
          createdTime: message.created_at,
          type: "event", // always "event"
          eventType: "error",
          creatorId: message.creator_id,
          id: message.id,
        };
        return shipmentDeletedEvent;

      case "checkout_session_paid":
        const checkoutEventPaid: IEventMessage = {
          text: t(`Order {{orderNumber}} was paid`, {
            orderNumber: order?.number,
          }),
          createdTime: message.created_at,
          type: "event",
          eventType: "success",
          creatorId: message.creator_id,
          id: message.id,
        };
        return checkoutEventPaid;
      case "payment_link_created":
        // I'm not sure the buyer needs to see this.
        return undefined;

      default:
        return {
          text: "",
          id: message.id,
          createdTime: message.created_at,
          creatorId: message.creator_id,
          type: "message",
          creatorName: "",
        } as IMessage;
    }
  });
}

// TODO properly internationalize dates.
function formatMessages({
  index,
  message,
  allMessages,
}: {
  index: number;
  message: TimelineMessages | undefined;
  allMessages: (IMessage | IEventMessage | undefined)[];
}) {
  if (message === undefined) return null;

  return index === 0 ? (
    moment.utc(message?.createdTime).local().isSame(moment(), "day") ? (
      <DateWrapper key={message?.id}>Today</DateWrapper>
    ) : (
      <DateWrapper key={message?.id}>
        {moment.utc(message?.createdTime).local().format("D. MMM YYYY")}
      </DateWrapper>
    )
  ) : !moment.utc(message?.createdTime).local().isSame(moment(), "year") ? (
    <DateWrapper key={message?.id}>
      {moment.utc(message?.createdTime).local().format("D. MMM YYYY")}
    </DateWrapper>
  ) : !moment
      .utc(message?.createdTime)
      .local()
      .isSame(allMessages[index - 1]?.createdTime, "day") &&
    moment.utc(message?.createdTime).local().isSame(moment(), "day") ? (
    <DateWrapper key={message.id}>Today</DateWrapper>
  ) : !moment
      .utc(message?.createdTime)
      .local()
      .isSame(allMessages[index - 1]?.createdTime, "day") ? (
    !moment
      .utc(message?.createdTime)
      .local()
      .isSame(allMessages[index - 1]?.createdTime, "year") ? (
      <DateWrapper key={message.id}>
        {moment.utc(message?.createdTime).local().format("D. MMM YYYY")}
      </DateWrapper>
    ) : (
      <DateWrapper key={message?.id}>
        {moment.utc(message?.createdTime).local().format("D. MMM")}
      </DateWrapper>
    )
  ) : null;
}

type formatUserMessageProps = {
  index: number;
  message: IMessage;
  allMessages: (IMessage | IEventMessage | undefined)[];
  theme: DefaultTheme;
  loggedInUser: User;
};
function formatTextMessage({
  index,
  message,
  // allMessages,
  theme,
  loggedInUser,
}: formatUserMessageProps) {
  if (!message) return null;
  else {
    return (
      <MessageWrapper key={index} self={message.creatorId === loggedInUser.id}>
        <AvatarWrapper>
          <Avatar
            name={message.creatorName}
            size="28"
            color={
              message.creatorId === loggedInUser.id
                ? theme.primaryButtonBorder
                : undefined
            }
            round="20px"
          />
          {/* {index > 0 &&
            (message.creatorId !== allMessages[index - 1]?.creatorId ||
              !moment
                .utc(message?.createdTime)
                .local()
                .isSame(allMessages[index - 1]?.createdTime, "day")) && (
              <Avatar
                name={message.creatorName}
                size="28"
                color={theme.primaryButtonBG}
                round="20px"
              />
            )} */}
        </AvatarWrapper>
        <MessageDetails>
          {message.attachments && message.attachments.length > 0 && (
            <MessageAttachments>
              {message.attachments.map((attachment, index) => (
                <div key={index}>
                  <a href={attachment.url}>{attachment.text}</a>
                  {message.attachments &&
                    index < message.attachments.length - 1 &&
                    ", "}
                </div>
              ))}
            </MessageAttachments>
          )}
          {message?.status?.text && (
            <StatusWrapper>
              <StatusLeft
                text={message.status.text}
                color={message.status.color}
              />
            </StatusWrapper>
          )}
          {message.detailsList && message.detailsList.details.length > 0 && (
            <DetailsList>
              <SoftHeader>{message.detailsList.title}</SoftHeader>
              <dl>
                {message.detailsList.details.map((detail, index) => (
                  <DetailWrapper key={index}>
                    <dt>
                      <SoftHeader>{detail.name}</SoftHeader>
                    </dt>
                    <dd>{detail.value}</dd>
                  </DetailWrapper>
                ))}
              </dl>
            </DetailsList>
          )}
          {message.text && (
            <TextMessage self={message.creatorId === loggedInUser.id}>
              {message.text.split(/\n/g).map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </TextMessage>
          )}
          <MessageTime self={message.creatorId === loggedInUser.id}>
            {moment.utc(message.createdTime).local().format("hh:mma")}
          </MessageTime>
        </MessageDetails>
      </MessageWrapper>
    );
  }
}

export function Timeline({
  messages,
  quote,
  order,
  sampleRequest,
  title,
  fetchingData,
  loggedInUser,
  sendMessage,
}: ITimelineProps) {
  const [newMessageText, setNewMessageText] = useState<string>("");
  const { t } = useTranslation();
  // The inferred type of this useState is not what it seems to be in
  // practice because of how the component is called with or without a quote
  // object.

  const [timelineMessages, setTimelineMessages] = useState(
    convertMessages({
      messages,
      quote,
      order,
      sampleRequest,
      user: loggedInUser,
      t,
    })
  );
  const [inputHeight, setInputHeight] = useState(20);

  const inputEl = useRef<HTMLTextAreaElement | null>(null);
  const conversationRef = useRef<HTMLDivElement | null>(null);
  const theme: DefaultTheme = useContext(ThemeContext);

  const messageInputChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setNewMessageText(e.currentTarget.value);
  };

  const SendTimelineMessage = useCallback(() => {
    if (sendMessage) {
      sendMessage(newMessageText);
      setNewMessageText("");
      setInputHeight(20);
    }
  }, [sendMessage, setNewMessageText, setInputHeight, newMessageText]);

  useEffect(() => {
    setTimelineMessages(
      convertMessages({
        messages,
        quote,
        order,
        sampleRequest,
        user: loggedInUser,
        t,
      })
    );
  }, [loggedInUser, messages, order, sampleRequest, quote, t]);

  useEffect(() => {
    if (conversationRef?.current && messages) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [timelineMessages, messages]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent): void => {
      // prevent sending empty message with newline when user press enter and there's no text
      if (
        document.activeElement === inputEl.current &&
        e.key === "Enter" &&
        !e.shiftKey &&
        inputEl.current?.value === ""
      ) {
        e.preventDefault();
        setNewMessageText("");
      }

      // send new message when user press Enter key and the focus is on the Input and there's an input value
      if (
        document.activeElement === inputEl.current &&
        inputEl.current?.value
      ) {
        inputEl.current && setInputHeight(inputEl.current?.scrollHeight);
        if (e.key === "Enter" && !e.shiftKey) {
          if (inputEl.current?.value !== "") {
            e.preventDefault();
            SendTimelineMessage();
          }
        }
      }
    },
    [SendTimelineMessage]
  );

  useEffect(() => {
    if (inputEl.current) {
      window.addEventListener("keydown", handleKeyPress, false);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [messages, handleKeyPress]);
  if (fetchingData) return null;
  return (
    <TimelineWrapper>
      {title && (
        <TimelineHeader>
          <H4>{title}</H4>
        </TimelineHeader>
      )}
      <Conversation ref={conversationRef}>
        {timelineMessages.length > 0 &&
          timelineMessages.map((message, index) => {
            return (
              <div key={index}>
                {!message && <div />}
                {formatMessages({
                  index,
                  message,
                  allMessages: timelineMessages,
                })}

                {message?.type === "message" &&
                  formatTextMessage({
                    index,
                    message,
                    allMessages: timelineMessages,
                    theme,
                    loggedInUser,
                  })}

                {message?.type === "event" && (
                  <EventMessage>
                    <DesktopNotification
                      text={message.text}
                      notificationType={message.eventType}
                      id={index}
                    />
                    <MessageTimeCenter>
                      {moment.utc(message.createdTime).local().format("hh:mma")}
                    </MessageTimeCenter>
                  </EventMessage>
                )}
              </div>
            );
          })}
      </Conversation>

      <SendMessageWrapper>
        <NewMessageWrapper>
          <NewMessageInput
            value={newMessageText}
            onChange={messageInputChange}
            ref={inputEl}
            style={{ height: `${inputHeight}px` }}
            rows={1}
          />
        </NewMessageWrapper>
        <SendMessageButton
          onClick={SendTimelineMessage}
          data-testid={"quote-and-order-timeline-send-message-button"}
          disabled={!newMessageText || newMessageText === " "}
        >
          {t("Send")}
        </SendMessageButton>
      </SendMessageWrapper>
    </TimelineWrapper>
  );
}
