import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { GoBackButtonSmall } from "../../../../../components/Buttons/Buttons";
import { PageWrapper } from "../../../../../layout/portalPageLayout";
import { ContentWrapper } from "../../../../../layout/publicPageLayout";
import { useRoutePath } from "../../../../../util/Routing";
import { useStoreState } from "../../../../../util/util";
import { TemplatesNav } from "./TemplatesNav";
import type { AttributeTemplateSchema } from "../../../../../types/types.PIM";
import useSWR from "swr";
import type { AxiosError } from "axios";
import { Activity } from "../../../../SharedPages/ProductDetailPage/Activity/Activity";

export function SellerAdminTemplateActivityTable() {
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const { template_id } = useParams<{ template_id: string }>();
  const location = useLocation();

  const { data: template } = useSWR<AttributeTemplateSchema, AxiosError>(
    `/v2/tenants/${tenant_id}/pim/templates/${template_id}`
  );

  return (
    <PageWrapper>
      <div style={{ width: "fit-content" }}>
        <Link
          to={
            location.search.includes("from=dashboard")
              ? `${adminPath}/dashboard`
              : `${adminPath}/pim/templates`
          }
        >
          <GoBackButtonSmall text={"Back"} />
        </Link>
      </div>
      <TemplatesNav
        pageTitle={template?.template_name || t("Template")}
        tabIndex={3}
        templateID={template_id}
      />
      <ContentWrapper>
        <Activity
          target_id={template_id}
          mode="template"
          hiddenColumns={["version_number", "status"]}
        />
      </ContentWrapper>
    </PageWrapper>
  );
}
