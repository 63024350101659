import { useState } from "react";
import { CheckBoxContainer, CheckBoxFinePrintLabel } from "../Form/Form";
import type { MethodsOfUseForm } from "../../types/types";
import type { UseFieldArrayMethods } from "react-hook-form";
import { CheckBoxNoLabel } from "../CheckBoxes/CheckBoxes";
import { useTranslation } from "react-i18next";
import { Row } from "../Layout/Layout";
import styled from "styled-components/macro";

const RowWithMargin = styled(Row)`
  margin: 13px 0;
  div {
    margin-right: 10px;
  }
`;

export const TaxExemptFee = ({
  isTaxExempt,
  methodsOfUseForm,
  methodsOfUseFieldArray,
}: {
  isTaxExempt: boolean;
  methodsOfUseForm: MethodsOfUseForm;
  methodsOfUseFieldArray: UseFieldArrayMethods<Record<string, any>, "key">;
}) => {
  const [taxExemptValue, setTaxExemptValue] = useState(isTaxExempt);
  const { t } = useTranslation();
  const { fields, prepend, remove } = methodsOfUseFieldArray;
  const { register } = methodsOfUseForm;

  const hasTaxFee = () => fields.some((fee) => fee.key === "tax_fee");

  const updateTaxExempt = () => {
    const newTaxExemptVal = !taxExemptValue;
    if (!newTaxExemptVal && !hasTaxFee()) {
      prepend({ name: "Tax", amount: 0, key: "tax_fee" });
    } else {
      remove(fields.findIndex((fee) => fee.key === "tax_fee"));
    }
    setTaxExemptValue(newTaxExemptVal);
  };

  return (
    <RowWithMargin>
      <CheckBoxContainer>
        <CheckBoxNoLabel
          name="is_tax_exempt"
          ref={register}
          defaultChecked={taxExemptValue}
          onChange={updateTaxExempt}
          id="is_tax_exempt"
        />
        <CheckBoxFinePrintLabel htmlFor="is_tax_exempt">
          {t("Tax exempt")}
        </CheckBoxFinePrintLabel>
      </CheckBoxContainer>
    </RowWithMargin>
  );
};
