import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import {
  H3,
  H4,
  H5,
  H6Bold,
  SoftHeader2,
} from "../../../../../components/Typography/Typography";
import { InfoMessageBox } from "../../../../../components/Form/Form";
import { InfoIcon } from "../../../../../components/Icons/Icons";
import { Modal } from "../../../../../components/Modal/Modal";
import { TableWrapper } from "../../../../../components/Table/Table";

const GuideContainer = styled.div`
  width: 100%;
  padding: 32px 16px;
`;

const UL = styled.ul`
  padding-left: 20px;

  li {
    margin-bottom: 8px;
    &::marker {
      font-size: ${({ theme }) => theme.fontSizes.medium};
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  }
`;
/**
 * display Product Selector Guidlines modal
 */
export const ProductSelectorGuide = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showGuide, setShowGuide] = useState(false);
  const CSV_FORMAT_EXAMPLE_TABLE_DATA = [
    ["M", "I", "X,Y,Z"],
    ["M", "J", "X,Y,Z"],
    ["M", "K", "X,Y,Z"],
    ["N", "I", "X,Y,Z"],
    ["N", "J", "X,Y,Z"],
    ["N", "K", "X,Y,Z"],
    ["R", "I", "X,Y,Z"],
    ["R", "J", "X,Y,Z"],
    ["R", "K", "X,Y,Z"],
  ];
  return (
    <>
      <SoftHeader2 style={{ margin: "20px 0 5px" }}>
        {t("Need help getting started?")}
      </SoftHeader2>
      <div
        onClick={() => setShowGuide(true)}
        style={{
          display: "flex",
          gap: "4px",
          alignItems: "center",
          cursor: "pointer",
          marginBottom: "15px",
        }}
      >
        <InfoIcon />
        <H6Bold>{t("View Guide")}</H6Bold>
      </div>
      <Modal
        overlay
        modalWidth="600px"
        show={showGuide}
        closeModal={() => setShowGuide(false)}
      >
        <>
          <H3 style={{ margin: "0 0 28px" }}>{t("Guidlines")}</H3>
          <InfoMessageBox>
            <GuideContainer>
              <H4 style={{ margin: "0 0 28px" }}>{t("Guidlines")}</H4>
              <p>{t("The product selector can be configured in two ways")}:</p>

              <H5 style={{ margin: "25px 0" }}>{t("1. Without CSV Upload")}</H5>
              <UL>
                <li>
                  {t(
                    "Select the items to be configured for the product selector from the search bar"
                  )}
                </li>
                <li>
                  {t("A minimum of 1 and a maximum of 5 items can be selected")}
                </li>
                <li>{t("Save your changes")}</li>
              </UL>
              <p>
                <b>{t("Output")}</b> -
                {t(
                  "The selected items will be the product selectors on the storefront home page and the list values of the respective items will derive from the active products"
                )}
              </p>
              <H5 style={{ margin: "25px 0" }}>{t("2. Via CSV upload")}</H5>
              <UL>
                <li>
                  {t(
                    "Select the items to be configured for the product selector from the search bar"
                  )}
                </li>
                <li>
                  {t("A minimum of 1 and a maximum of 5 items can be selected")}
                </li>
                <li>
                  {t("Switch on the toggle to upload CSV")}
                  <br />
                  {t(
                    "The values under the items in the CSV should be a part of the corresponding list available in the active products"
                  )}
                </li>
                <li>{t("Upload the CSV in the given format")}</li>
                <li>{t("Save your changes")}</li>
              </UL>
              <p>
                <b>{t("Output")}</b> -
                {t(
                  "The selected items will be the product selectors on the storefront home page and the list values of the respective items will derive from the list values available in the CSV"
                )}
              </p>
              <H5 style={{ margin: "25px 0" }}>{t("CSV Format")}</H5>
              <p>
                {t("List down the column headers as per the item selected")}
              </p>
              <div style={{ marginBottom: "25px" }}>
                <div>{t("Product Selector 1 - Column Header 1")}</div>
                <div>{t("Product Selector 2 - Column Header 2")}</div>
                <div>{t("Product Selector 3 - Column Header 3")}</div>
                <div>{t("Product Selector 4 - Column Header 4")}</div>
                <div>{t("Product Selector 5 - Column Header 5")}</div>
              </div>
              <UL>
                <li>
                  {t(
                    "The values of the respective columns should map to the values of the succeeding column"
                  )}
                </li>
                <li>
                  {t(
                    "The single value of the first column can be mapped to multiple values of the second column and the single value of the second column can be mapped to multiple values of the third column and so on"
                  )}
                </li>
                <li>
                  {t(
                    "The last column can have multiple values in one row as there is no succeeding column"
                  )}
                </li>
              </UL>
              <p style={{ marginBottom: "25px" }}>
                <H5 style={{ margin: "25px 0" }}>{t("For Example")}:-</H5>
                <div>{t("Column 1 - Product Selector A")}</div>
                <div>{t("Column 2 - Product Selector B")}</div>
                <div>{t("Column 3 - Product Selector C")}</div>
                <div style={{ margin: "25px 0 0" }}>
                  {t("The values of A are M, N, R")}
                </div>
                <div>{t("The values of B are I, J, K")}</div>
                <div>{t("The values of C are X, Y, Z")}</div>
              </p>

              <TableWrapper lastChildleftAlign>
                <table>
                  <thead>
                    <th
                      style={{
                        borderLeft: `4px solid ${theme.secondaryBorder}`,
                      }}
                    >
                      A
                    </th>
                    <th>B</th>
                    <th>C</th>
                  </thead>
                  <tbody>
                    {CSV_FORMAT_EXAMPLE_TABLE_DATA.map((row, idx) => {
                      return (
                        <tr key={`${row[0]}_${idx}`}>
                          {row.map((value, idx) => (
                            <td key={`${value}_${idx}`}>{value}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </TableWrapper>
            </GuideContainer>
          </InfoMessageBox>
        </>
      </Modal>
    </>
  );
};
