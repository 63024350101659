import React from "react";
import { useTranslation } from "react-i18next";
import type {
  SampleRequest,
  SampleRequestDetail,
  SampleRequestIndividualItem,
} from "../../types/types";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
} from "../Buttons/Buttons";
import { TransactionItem } from "./TransactionItem";
import { TransactionItemInfoBlock } from "./TransactionItemInfoBlock";
import { TransactionItemTopLeftSampleRequest } from "./TransactionItemTopLeft";
import { Row } from "../Layout/Layout";

/**
 * Transaction item card for sample requests. It is the same everywhere, for
 * buyer and seller, except the delete button in the bottom right corner is not
 * always shown. To show the delete button, pass in the `handleConfirmDelete` callback.
 *
 *
 */
export const SampleRequestItem = ({
  item,
  index,
  handleConfirmDelete,
  handleEdit,
  sample,
}: {
  item: SampleRequestDetail | SampleRequestIndividualItem;
  index: number;
  handleConfirmDelete?: (index?: number) => void;
  handleEdit?: () => void;
  sample?: SampleRequest;
}) => {
  const { t } = useTranslation();

  const bottomRight =
    handleConfirmDelete || handleEdit ? (
      <Row>
        {handleConfirmDelete && (
          <ButtonWithConfirmDialog
            Button={DeleteButton}
            testid={"sample-request-item-delete-button"}
            handleConfirm={() => handleConfirmDelete(index)}
            confirmMessage={t("Are you sure you want to remove this item?")}
          />
        )}
        {handleEdit && <EditButton onClick={handleEdit} />}
      </Row>
    ) : undefined;

  const TopRightColumnTwo = (() => {
    if ("sku" in item && item.sku !== null) {
      return <TransactionItemInfoBlockSampleTotal item={item} />;
    } else {
      // we're dealing with a lead..
      return (
        <TransactionItemInfoBlockLeadSampleTotal
          item={item as SampleRequestIndividualItem}
        />
      );
    }
  })();

  return (
    <TransactionItem
      topLeft={
        <TransactionItemTopLeftSampleRequest item={item} index={index} />
      }
      topRightCol1={<TransactionItemInfoBlockAnnualUsage item={item} />}
      topRightCol2={TopRightColumnTwo}
      bottomRight={bottomRight}
      hideSeparatorLine={true}
      requestType={"sample"}
      itemId={item.id}
      productId={item.product_id}
      requestId={sample?.id}
      buyerId={sample?.buyer_id}
    />
  );
};

/**
 * The `topRightCol1` column of a sample request item, showing the
 * "Potential Annual Usage".
 */
const TransactionItemInfoBlockAnnualUsage = ({
  item,
}: {
  item: SampleRequestDetail | SampleRequestIndividualItem;
}) => {
  const { t } = useTranslation();

  const totalQuantityString = `${item.total_quantity || "--"} ${
    item.total_quantity_packaging_unit?.name || "--"
  }`;

  return (
    <TransactionItemInfoBlock
      main={totalQuantityString}
      lines={[t("Potential Annual Usage"), item.purpose]}
    />
  );
};

/**
 * The `topRightCol2` column of a non lead sample request item, showing the totals for
 * the sample that's being requested.
 */
const TransactionItemInfoBlockSampleTotal = ({
  item,
}: {
  item: SampleRequestDetail;
}) => {
  const packageVolume = item.sku?.package_volume;
  const packagingUnitName = item.sku?.packaging_unit?.name;
  const numberOfUnits = item.no_of_units;

  const rawTotalSampleQuantity = Number(packageVolume) * Number(numberOfUnits);
  const totalSampleQuantity = isNaN(rawTotalSampleQuantity)
    ? null
    : rawTotalSampleQuantity;

  return (
    <TransactionItemInfoBlock
      main={`${totalSampleQuantity || "--"} ${packagingUnitName || "--"}`}
      lines={[
        `${packageVolume || "--"} ${packagingUnitName || "--"} x ${
          numberOfUnits || "--"
        } units`,
      ]}
      alignRight={true}
    />
  );
};

const TransactionItemInfoBlockLeadSampleTotal = ({
  item,
}: {
  item: SampleRequestIndividualItem;
}) => {
  return (
    <TransactionItemInfoBlock
      main={`${item.sample_quantity ?? "--"} ${
        item.sample_quantity_packaging_unit?.name ?? "--"
      }`}
      alignRight={true}
    />
  );
};
