import React, { useEffect, useState } from "react";
import type { DeepMap, FieldError } from "react-hook-form";
import styled from "styled-components/macro";
import { ToggleSwitch } from "../ToggleSwitch/ToggleSwitch";
import { SoftHeader } from "../Typography/Typography";

interface IChipProps {
  active?: boolean;
  disabled?: boolean;
  theme?: any;
  onClick: any;
}

export type ChipType = {
  name: string;
  id?: number | string;
  description?: string | null;
  active?: boolean;
};

export interface IChipsProps {
  selectionMode: "single" | "many" | "manyWithSelectAll";
  chips: ChipType[];
  header: string;
  handleClick: (activeChips: ChipType[]) => void;
  selectedChip?: string;
  errors: DeepMap<Record<string, any>, FieldError>;
  name: string;
}

const ErrorRedHeader = styled(SoftHeader)`
  color: ${({ theme }) => theme.errorColor};
`;

export const Chip = styled.button.attrs({ type: "button" })<IChipProps>`
  display: inline-block;
  border-radius: 4px;
  border: solid 1px
    ${({ theme, active }) =>
      (active === true && theme.selectedBorder) || theme.secondaryBorder};
  background-color: ${(props) =>
    (props.active === true && props.theme.primaryButtonBG) ||
    (props.disabled && props.theme.disabledInput) ||
    props.theme.tertiaryButtonBG};
  padding: 4px 8px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${(props) =>
    props.active === false
      ? props.theme.secondaryTextColor
      : props.theme.primaryTextColor};

  &:hover,
  &:focus {
    border: ${(props) =>
      (!props.disabled &&
        props.active &&
        `solid 1px ${props.theme.selectedBorder}`) ||
      (!props.disabled &&
        !props.active &&
        `solid 1px ${props.theme.tertiaryBorder}`)};
    font-weight: ${(props) =>
      !props.disabled && props.theme.fontWeights.regular};
    color: ${(props) => !props.disabled && props.theme.primaryTextColor};
    ${({ disabled }) => !disabled && `cursor: pointer`}
  }
  outline: none;
`;

export const ChipContainer = styled.div`
  button {
    margin-right: 5px;
    margin-bottom: 8px;
  }
`;

export const Chips = ({
  selectionMode,
  chips,
  header,
  handleClick,
  selectedChip = "",
  errors,
  name,
}: IChipsProps) => {
  const [activeChip, setActiveChip] = useState<string>(selectedChip);
  const [activeChipArray, setActiveChipArray] = useState<ChipType[]>([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selectionMode === "single") {
      // This sets the first chip to selected in the parent component,
      //  allowing people to go with default without having to click on anything
      if (selectedChip !== "") {
        const chip = chips.find((chip) => chip.name === selectedChip);
        if (chip) {
          handleClick([chip]);
        }
      }
    }
    // Use an empty dependency array so this effect only runs on the first
    // render. (Otherwise handleClick is called twice when a chip is clicked.)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSingleSelectClick = (clickedChip: ChipType) => {
    setActiveChip(clickedChip.name);
    handleClick([clickedChip]);
  };

  const handleMultiSelectClick = (clickedChip: ChipType) => {
    const clickedChipIsActive = activeChipArray.some(
      (chip) => chip.name === clickedChip.name
    );

    const newActiveChipArray = clickedChipIsActive
      ? activeChipArray.filter((chip) => chip.name !== clickedChip.name)
      : [...activeChipArray, clickedChip];

    setActiveChipArray(newActiveChipArray);
  };

  useEffect(() => {
    if (selectionMode === "many" || selectionMode === "manyWithSelectAll") {
      handleClick(
        activeChipArray.map((item: any) => {
          return item;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChipArray]);

  const handleCheckBoxClick = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (selectionMode === "manyWithSelectAll") {
      if (checked === true) {
        setActiveChipArray(chips);
        handleClick(chips);
      } else if (checked === false && activeChipArray.length !== 0) {
        setActiveChipArray([]);
        handleClick([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const chipClickHandler =
    selectionMode === "single"
      ? handleSingleSelectClick
      : handleMultiSelectClick;

  const headerForChips = (() => {
    if (errors[name]) {
      return (
        <ErrorRedHeader style={{ marginBottom: "5px" }}>
          {header}
        </ErrorRedHeader>
      );
    } else {
      return <SoftHeader style={{ marginBottom: "5px" }}>{header}</SoftHeader>;
    }
  })();

  return (
    <>
      {headerForChips}
      {selectionMode === "manyWithSelectAll" && (
        <div style={{ marginBottom: "14px" }}>
          <ToggleSwitch
            onClick={handleCheckBoxClick}
            label={"Select all regions"}
          />
        </div>
      )}
      <ChipContainer>
        {chips.map((chip) => {
          const active =
            selectionMode === "single"
              ? activeChip === chip.name
              : activeChipArray.some((chip2) => chip2.name === chip.name);

          return (
            <Chip
              name={chip.name}
              data-chip={JSON.stringify(chip)}
              key={chip.name}
              active={active}
              onClick={() => chipClickHandler(chip)}
            >
              {chip.name}
            </Chip>
          );
        })}
      </ChipContainer>
    </>
  );
};
