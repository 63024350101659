import axios from "axios";
import type { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CancelButtonSmall,
  EditButton,
  PrimaryButtonMedium,
} from "../../../../../../components/Buttons/Buttons";
import { FilePickerUncontrolled } from "../../../../../../components/FilePicker/FilePickerUncontrolled";
import { InfoIcon } from "../../../../../../components/Icons/Icons";
import { InfoBlockExtraSmall } from "../../../../../../components/InfoBlocks/InfoBlocks";
import { Row } from "../../../../../../components/Layout/Layout";
import { useNotifications } from "../../../../../../components/Notifications/NotificationsContext";
import { ToggleWithController } from "../../../../../../components/ToggleSwitch/ToggleSwitch";
import { H3 } from "../../../../../../components/Typography/Typography";
import {
  Form,
  RadioButtonContainer,
} from "../../../../../../layout/FormLayout";
import { SettingsCard } from "../../../../../../layout/portalPageLayout";
import {
  isAxiosError,
  useFormWrapper,
  useStoreState,
} from "../../../../../../util/util";
import { HelpDialog } from "../../../../../SharedPages/OrganizationPage/ProductsList/CreatePimProductPage/CreateFromUploads/HelpDialog";
import type { TFunction } from "react-i18next";
import type { StorefrontCustomizableFormSchema } from "../../../../../../types/types";
import ReactTooltip from "react-tooltip";
import { RadioButtonWithController } from "../../../../../../components/RadioButton/RadioButton";
import { Controller } from "react-hook-form";

type CustomFieldsFormValues = {
  custom_fields_enabled_registration: boolean;
  custom_fields_enabled_contact_us: boolean;
  is_dependent_contact_us: "independent" | "dependent";
  is_dependent_registration: "independent" | "dependent";
  registration_csv: File;
  contact_us_csv: File;
};

type CustomFormFieldFormOnSubmit = CustomFieldsFormValues & {
  form_type: "contact-us" | "registration";
};

const overview = (t: TFunction) => [
  t(
    "Use the toggle to select if the custom fields will be independent or dependent of each other on each form"
  ),
  t("Add the field names in the first cell of each column of the CSV"),
  t("Use row 2 to specify whether each field is mandatory or optional"),
  t("Add your values for each field in rows 3 and below"),
];

const guidelines = (t: TFunction) => [
  t("Please do not add more than 3 unique field names"),
  t(
    "If a form contains dependent fields, all fields except the last one are mandatory. The last field can be optional or mandatory."
  ),
];

export function OutsideLoginCustomFieldsSettings({
  registrationConfig,
  contactUsConfig,
  onComplete,
}: {
  registrationConfig: StorefrontCustomizableFormSchema;
  contactUsConfig: StorefrontCustomizableFormSchema;
  onComplete: () => Promise<void>;
}) {
  const { t } = useTranslation();

  const {
    storefront_metadata: { route_configuration },
  } = useStoreState();

  const { storefront_id } = useStoreState();
  const [editMode, setEditMode] = useState(false);

  const { notifyError, notifySuccess } = useNotifications();
  const [shouldShowHelpInstructions, setShouldShowHelpInstructions] =
    useState(false);

  const [hasDependentContactUsChanged, setHasDependentContactUsChanged] =
    useState(false);

  const [hasDependentRegistrationChanged, setHasDependentRegistrationChanged] =
    useState(false);

  const is_dependent_registration_default_value = (() => {
    if (
      registrationConfig.dependent_choices &&
      registrationConfig?.dependent_choices?.length > 0
    ) {
      return "dependent";
    } else return "independent";
  })();

  const is_dependent_contact_us_default_value = (() => {
    if (
      contactUsConfig.dependent_choices &&
      contactUsConfig?.dependent_choices?.length > 0
    ) {
      return "dependent";
    } else return "independent";
  })();

  const methodsOfUseForm = useFormWrapper({
    defaultValues: {
      custom_fields_enabled_contact_us: contactUsConfig.is_active,
      custom_fields_enabled_registration: registrationConfig.is_active,
      is_dependent_contact_us: is_dependent_contact_us_default_value,
      is_dependent_registration: is_dependent_registration_default_value,
    },
    shouldUnregister: true,
  });

  const { handleSubmit, watch, reset, control, setValue } = methodsOfUseForm;

  const registrationUploadEnabled = watch("custom_fields_enabled_registration");

  const contactUsUploadEnabled = watch("custom_fields_enabled_contact_us");

  const updateDependentContactUs = (val: "dependent" | "independent") => {
    setHasDependentContactUsChanged(
      val !== is_dependent_contact_us_default_value
    );
  };

  const updateDependentRegistration = (val: "dependent" | "independent") => {
    setHasDependentRegistrationChanged(
      val !== is_dependent_registration_default_value
    );
  };

  const onFinished = async (canceled = false) => {
    setHasDependentContactUsChanged(false);
    setHasDependentRegistrationChanged(false);
    await onComplete();
    if (canceled) {
      // reset to default values
      setValue("custom_fields_enabled_contact_us", contactUsConfig.is_active);
      setValue(
        "custom_fields_enabled_registration",
        registrationConfig.is_active
      );
    }
  };

  const onCustomFieldsFormSubmit = async (
    values: CustomFormFieldFormOnSubmit
  ) => {
    try {
      if (values.contact_us_csv) {
        const formData = new FormData();
        formData.append("file", values.contact_us_csv);
        await axios.put(
          `/v2/storefronts/${storefront_id}/custom-forms/contact-us/csv/${values.is_dependent_contact_us}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        notifySuccess(t("Contact Us CSV Successfully uploaded"));
      }

      if (values.registration_csv) {
        const formData = new FormData();
        formData.append("file", values.registration_csv);
        await axios.put(
          `/v2/storefronts/${storefront_id}/custom-forms/registration/csv/${values.is_dependent_registration}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        notifySuccess(t("Registration CSV Successfully uploaded"));
      }

      await axios.patch(
        `/v2/storefronts/${storefront_id}/custom-forms/registration`,
        { is_active: values.custom_fields_enabled_registration }
      );

      await axios.patch(
        `/v2/storefronts/${storefront_id}/custom-forms/contact-us`,
        { is_active: values.custom_fields_enabled_contact_us }
      );

      if (!values.registration_csv && !values.contact_us_csv) {
        notifySuccess(t("Configuration updated"));
      }
      await onFinished();
      setEditMode(false);
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("There was an error modifying the custom fields"));
      }
      console.error(error);
    }
  };

  const contactUsRoute = route_configuration.find(
    (route) => route.route === "contact_us"
  );

  const registrationRoute = route_configuration.find(
    (route) => route.route === "registration"
  );

  return (
    <SettingsCard>
      <Row>
        <H3>{t("Custom Form Fields")}</H3>
        {!editMode && (
          <EditButton
            testid={"edit-custom-form-fields"}
            onClick={() => setEditMode(true)}
            title={t("Edit custom form fields")}
          />
        )}
        {editMode && (
          <CancelButtonSmall
            onClick={async () => {
              reset();
              await onFinished(true);
              setEditMode(false);
            }}
          />
        )}
      </Row>
      <div style={{ marginBottom: "38px" }}>
        {t(
          "Create & configure up to 3 custom fields on the Contact Us & Registration forms by uploading a csv"
        )}
      </div>
      <div
        onClick={() => setShouldShowHelpInstructions(true)}
        style={{ cursor: "pointer" }}
      >
        <InfoBlockExtraSmall
          header={t("Need help getting started?")}
          content={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <InfoIcon width={18} height={18} />{" "}
              <span style={{ marginLeft: "2px" }}>{t("View Guide")}</span>
            </div>
          }
        />
      </div>
      <HelpDialog
        show={shouldShowHelpInstructions}
        setShow={setShouldShowHelpInstructions}
        overview={overview}
        guidelines={guidelines}
      />

      <H3>{t("Contact Us form")}</H3>
      <Form
        style={{ maxWidth: "512px" }}
        onSubmit={handleSubmit(onCustomFieldsFormSubmit)}
      >
        <span
          data-tip={t("Enable the Contact Us form to change this setting")}
          data-for={"contact-us-fields"}
        >
          <Controller
            control={control}
            name="custom_fields_enabled_contact_us"
            render={({ value, name, ref }) => (
              <ToggleWithController
                label={t("Enable custom form fields")}
                name={name}
                disabled={!editMode || !contactUsRoute?.enabled}
                onChange={(e) => {
                  setValue(
                    "custom_fields_enabled_contact_us",
                    e.target.checked
                  );
                }}
                checked={value}
                inputRef={ref}
              />
            )}
          />
        </span>
        {!contactUsRoute?.enabled && (
          <ReactTooltip delayHide={500} id="contact-us-fields" effect="solid" />
        )}
        {editMode && (
          <>
            <RadioButtonContainer style={{ marginTop: "15px" }}>
              <Controller
                control={control}
                name={"is_dependent_contact_us"}
                render={({ onChange, ...props }) => (
                  <RadioButtonWithController
                    {...props}
                    defaultChecked={(() => {
                      if (
                        contactUsConfig.dependent_choices &&
                        contactUsConfig?.dependent_choices?.length > 0
                      ) {
                        return true;
                      } else return false;
                    })()}
                    onChange={(e) => {
                      setValue("is_dependent_contact_us", "dependent");
                      updateDependentContactUs("dependent");
                    }}
                    value="dependent"
                    optionTitle={t("Fields are dependent on each other")}
                  />
                )}
              />
              <Controller
                control={control}
                name={"is_dependent_contact_us"}
                render={({ onChange, ...props }) => (
                  <RadioButtonWithController
                    {...props}
                    defaultChecked={(() => {
                      if (
                        contactUsConfig.dependent_choices &&
                        contactUsConfig?.dependent_choices?.length > 0
                      ) {
                        return false;
                      } else return true;
                    })()}
                    onChange={(e) => {
                      setValue("is_dependent_contact_us", "independent");
                      updateDependentContactUs("independent");
                    }}
                    value={"independent"}
                    optionTitle={t("Fields are independent of each other")}
                  />
                )}
              />
            </RadioButtonContainer>

            <FilePickerUncontrolled
              methodsOfUseForm={methodsOfUseForm}
              placeHolderText={t("Drag and drop the CSV file in given format")}
              name={"contact_us_csv"}
              accept={".csv"}
              defaultValueFileName={
                hasDependentContactUsChanged
                  ? undefined
                  : contactUsConfig.csv_filename
              }
              // If there is no csv_filename then its the first time and this
              // is required
              required={
                contactUsUploadEnabled &&
                (hasDependentContactUsChanged || !contactUsConfig.csv_filename)
              }
              disabled={!contactUsUploadEnabled}
            />
          </>
        )}

        <H3>{t("Registration form")}</H3>

        <span
          data-tip={t("Enable the Registration form to change this setting")}
          data-for={"registration-fields"}
        >
          <Controller
            control={control}
            name="custom_fields_enabled_registration"
            render={({ value, name, ref }) => (
              <ToggleWithController
                label={t("Enable custom form fields")}
                name={name}
                disabled={!editMode || !registrationRoute?.enabled}
                onChange={(e) => {
                  setValue(
                    "custom_fields_enabled_registration",
                    e.target.checked
                  );
                }}
                checked={value}
                inputRef={ref}
              />
            )}
          />
        </span>
        {!registrationRoute?.enabled && (
          <ReactTooltip
            delayHide={500}
            id="registration-fields"
            effect="solid"
          />
        )}
        {editMode && (
          <>
            <RadioButtonContainer style={{ marginTop: "15px" }}>
              <Controller
                control={control}
                name={"is_dependent_registration"}
                render={({ onChange, ...props }) => (
                  <RadioButtonWithController
                    {...props}
                    defaultChecked={(() => {
                      if (
                        registrationConfig.dependent_choices &&
                        registrationConfig?.dependent_choices?.length > 0
                      ) {
                        return true;
                      } else return false;
                    })()}
                    onChange={(e) => {
                      setValue("is_dependent_registration", "dependent");
                      updateDependentRegistration("dependent");
                    }}
                    value="dependent"
                    optionTitle={t("Fields are dependent on each other")}
                  />
                )}
              />
              <Controller
                control={control}
                name={"is_dependent_registration"}
                render={({ onChange, ...props }) => (
                  <RadioButtonWithController
                    {...props}
                    defaultChecked={(() => {
                      if (
                        registrationConfig.dependent_choices &&
                        registrationConfig?.dependent_choices?.length > 0
                      ) {
                        return false;
                      } else return true;
                    })()}
                    onChange={(e) => {
                      setValue("is_dependent_registration", "independent");
                      updateDependentRegistration("independent");
                    }}
                    value={"independent"}
                    optionTitle={t("Fields are independent of each other")}
                  />
                )}
              />
            </RadioButtonContainer>

            <FilePickerUncontrolled
              methodsOfUseForm={methodsOfUseForm}
              placeHolderText={t("Drag and drop the CSV file in given format")}
              name={"registration_csv"}
              accept={".csv"}
              defaultValueFileName={
                hasDependentRegistrationChanged
                  ? undefined
                  : registrationConfig.csv_filename
              }
              required={
                // If there is no csv_filename then its the first time and this
                // is required
                registrationUploadEnabled &&
                (hasDependentRegistrationChanged ||
                  !registrationConfig.csv_filename)
              }
              disabled={!registrationUploadEnabled}
            />

            <PrimaryButtonMedium>{t("Save your changes")}</PrimaryButtonMedium>
          </>
        )}
      </Form>
    </SettingsCard>
  );
}
