import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useStoreState } from "../../../util/util";
import { useTranslation } from "react-i18next";
import { HelpIcon } from "../../../components/Icons/Icons";
import { screenSize } from "../../../theme";

const DocumentationWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  @media ${screenSize.small} {
    margin-right: 5px;
  }
`;

const DropDown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 180px;
  padding: 5px;
  border: 1px solid #e7e8ea;
  background: #fff;
  box-shadow: 0px 6px 16px -6px rgba(0, 0, 0, 0.44);
  z-index: 1;
  button {
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 0;
    border: 1px solid #fff;
    cursor: pointer;
    padding: 10px;
    text-align: left;
    &:hover {
      background: #f5f7f8;
    }
  }
`;

const UnstyledLinkWrapper = styled.div`
  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.brandColor};
  }
`;

export const DocumentationArea = () => {
  const [showDropDown, setShowDropDown] = useState(false);

  const { slug: storefrontSlug, edition: storefrontEdition } = useStoreState();
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const hostname = window.location.hostname;
  const docsPath = storefrontEdition === "pim" ? "pim-docs" : "docs";

  return (
    <DocumentationWrapper
      ref={wrapperRef}
      onClick={() => setShowDropDown(!showDropDown)}
    >
      {showDropDown && (
        <DropDown>
          <UnstyledLinkWrapper>
            <a
              href={`${window.location.origin}/${docsPath}?store=${storefrontSlug}&hostname=${hostname}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>{t("Documentation")}</button>
            </a>
          </UnstyledLinkWrapper>
        </DropDown>
      )}
      <HelpIcon />
    </DocumentationWrapper>
  );
};
