import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import { TextField } from "../../../components/TextFields/TextFields";
import { Form } from "../../../layout/FormLayout";
import { AuthContainer, AuthPage } from "../../../layout/publicPageLayout";
import Axios from "axios";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { H3DarkText } from "../../../components/Typography/Typography";
import { useRoutePath } from "../../../util/Routing";
import { useFormWrapper, useStoreState } from "../../../util/util";
import { AuthLogoWrapper } from "../../Misc/Login";

export function ForgotUserName() {
  const { handleSubmit, register, formState, errors } = useFormWrapper({});
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storePath } = useRoutePath();
  const { slug } = useStoreState();
  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = async (values: { email_address: string }) => {
    setLoading(true);
    try {
      // This appears to only work with the slug?
      await Axios.post(`/v1/storefronts/${slug}/forgot-username`, values);
      notifySuccess(
        "A password reset email has been sent to the provided email"
      );
      setLoading(false);
      history.push(storePath);
    } catch (error) {
      notifyError(
        "An password reset request has been sent to the provided email",
        { error }
      );
      setLoading(false);
    }
  };

  return (
    <AuthPage>
      <AuthContainer>
        <AuthLogoWrapper />
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <H3DarkText>{t("Forgot Username")}</H3DarkText>
          <TextField
            name="email_address"
            label="Email Address"
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="email"
            autoComplete={"email"}
          />
          <PrimaryButtonFitContainer
            style={{ marginTop: 0 }}
            type="submit"
            loading={loading}
          >
            {t("Submit")}
          </PrimaryButtonFitContainer>
        </Form>
      </AuthContainer>
    </AuthPage>
  );
}
