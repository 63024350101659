import type { AxiosResponse } from "axios";
import axios from "axios";
import type { WithPagination } from "../../../../types/types";
import type { ProductStatusType } from "../../../../types/types.PIM";

export type SellerTableProduct = {
  id: string;
  name: string;
  modified_at: { date: string; id: string };
  status: ProductStatusType;
  chemical_name: string;
  externalId: string;
  completion_score: string;
};

export type BuyerTableProduct = Omit<SellerTableProduct, "completion_score">;

type AppendToUrl = {
  existingParams: URLSearchParams;
  paramsToAppend: string[];
  key: string;
};

export function appendFiltersToUrl({
  existingParams,
  paramsToAppend,
  key,
}: AppendToUrl) {
  return paramsToAppend.forEach((param) => existingParams.append(key, param));
}

export type ApplyFiltersToURL = {
  active_tag_view?: string | null;
  q?: string;
  params: URLSearchParams;
  filtersFromUrl?: { [key: string]: string[] };
};

export const applyFiltersToURL = ({
  filtersFromUrl,
  params,
}: ApplyFiltersToURL): URLSearchParams => {
  if (filtersFromUrl) {
    Object.entries(filtersFromUrl)?.forEach((filterList) => {
      appendFiltersToUrl({
        existingParams: params,
        paramsToAppend: filterList[1],
        key: filterList[0],
      });
    });
  }
  return params;
};

export const get_all_condensed_products = async (
  tenant_id: string,
  params: URLSearchParams
) => {
  params.delete("offset");
  params.delete("limit");
  params.delete("perPage");
  const { data } = await axios.get<
    AxiosResponse<{ id: string; name: string }[]>
  >(`/v2/tenants/${tenant_id}/pim/products/condensed-summary?${params}`);
  return data.data;
};

export interface ConstructQuery<ProductType extends {}> {
  baseUrl: string;
  query: string;
  filtersFromUrl?: { [key: string]: string[] };
  previousPageData?: WithPagination<{ data: ProductType[] }> | null;
  index?: number;
  pageSize?: number;
}
