import styled from "styled-components/macro";

export const IDDesktop = styled.span`
  background-color: ${({ theme }) => theme.tertiaryBG};
  color: ${({ theme }) => theme.invertTextColor};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.large};
  border-radius: 4px;
  padding: 4px 8px;
`;

export const IDMobile = styled(IDDesktop)`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: ${({ theme }) => theme.fontWeights.small};
  // This width is based on the longest possible ID in terms of pixels: WWWWWWWWW
  width: 90px;
  display: flex;
  justify-content: center;
`;
