import React, { useState, useContext } from "react";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import { FormItems, Form } from "../../../../../../layout/FormLayout";
import { useFormWrapper } from "../../../../../../util/util";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import type { StorefrontMetaData } from "../../../../../../types/types";
import { endpoints } from "../../../../../../endpoints";
import { Store } from "../../../../../../Store";
import { SaveButtonPrimaryMedium } from "../../shared";
import { useTranslation } from "react-i18next";

interface FormValues {
  header: string;
  sub_header: string;
  placeholder: string;
  browser_title: string;
}

export const EditPortfolioPageContent = ({
  header,
  sub_header,
  placeholder,
  browser_title,
  langCode,
  closeEditPortfolioPageContent,
  mutateStorefrontMetadata,
}: {
  header?: string;
  sub_header?: string;
  placeholder?: string;
  browser_title?: string;
  langCode: string;
  closeEditPortfolioPageContent: () => void;
  mutateStorefrontMetadata: (
    data: StorefrontMetaData,
    shouldRevalidate: boolean
  ) => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);

  const { storeState, storeDispatch } = useContext(Store);
  const { storefront_id } = storeState;
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, formState, errors } = useFormWrapper({
    defaultValues: {
      header,
      sub_header,
      placeholder,
      browser_title,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    try {
      const patchBody: Pick<
        StorefrontMetaData,
        "header" | "sub_header" | "placeholder" | "browser_title"
      > = {
        header: formValues.header,
        sub_header: formValues.sub_header,
        placeholder: formValues.placeholder,
        browser_title: formValues.browser_title,
      };

      // English is handled differently.
      const endpoint =
        langCode === "en"
          ? endpoints.v1_storefronts_id_metadata(storefront_id)
          : endpoints.v1_storefronts_id_metadata_languageCode(
              storefront_id,
              langCode
            );

      await Axios.patch<StorefrontMetaData>(endpoint, patchBody);

      const { data: newStorefrontMetadata } = await Axios.get(
        endpoints.v1_storefronts_id_metadata(storefront_id)
      );

      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: newStorefrontMetadata,
      });

      notifySuccess("Changes saved");
      setSubmitting(false);
      mutateStorefrontMetadata(newStorefrontMetadata, false);
      closeEditPortfolioPageContent();
    } catch (error) {
      notifyError(t("There was an error saving the changes"), { error });
      setSubmitting(false);
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormItems>
        <TextField
          label={t("Header")}
          name="header"
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <TextField
          label={t("Sub Header")}
          name="sub_header"
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <TextField
          label={t("Search Placeholder")}
          name="placeholder"
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <TextField
          label={t("Browser Title")}
          name="browser_title"
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          type="text"
        />
      </FormItems>
      <SaveButtonPrimaryMedium loading={submitting}>
        {t("Save your changes")}
      </SaveButtonPrimaryMedium>
    </Form>
  );
};
