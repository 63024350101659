import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  CustomTermContainer,
  WarningMessage,
} from "../../../components/Form/Form";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import type { Fee, OptionType, UUID } from "../../../types/types";
import type {
  POCFormSchemaType,
  POCManualFormSchemaType,
} from "../../../util/util";
import {
  convertPaymentModeToOption,
  usePaymentMethods,
  useStoreState,
} from "../../../util/util";
import { ColoredTextOnError } from "../../../util/util-components";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { ArrayField } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ConfirmationSpacer } from "../../public/SampleRequestCart/SampleRequestCart";
import type { RequestAddressSchemaType } from "../../public/RequestAddress/RequestAddress";
import { create_configured_checkboxes_fields } from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";

export type CreateQuoteBottomFormOutput = RequestAddressSchemaType & {
  confirmation: boolean;
  is_tax_exempt: boolean;
  valid_to_date: string;
  required_eta: string;
  payment_method: OptionType<UUID>;
  feesList: Fee[];
};

export interface CreateQuoteBottomFormPOCManual
  extends CreateQuoteBottomFormOutput,
    POCManualFormSchemaType {}

export interface CreateQuoteBottomFormPOCForm
  extends CreateQuoteBottomFormOutput,
    POCFormSchemaType {}

export function CreateQuoteBottomForm({
  defaultPaymentMethod,
  isTaxExempt,
  updateTaxFee,
  configuredCheckboxFields,
}: {
  defaultPaymentMethod: OptionType<UUID> | null;
  isTaxExempt: boolean;
  updateTaxFee: (isTaxExempt: boolean) => void;
  configuredCheckboxFields: Partial<ArrayField<Record<string, any>, "id">>[];
}) {
  const methodsOfUseForm = useFormContext();
  const { control, register, errors, formState, setError, watch } =
    methodsOfUseForm;
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();

  const { data: fetchedPaymentMethods, error: paymentMethodsError } =
    usePaymentMethods(storefront_id);

  if (paymentMethodsError) {
    setError("payment_method", { message: "Failed to fetch payment methods" });
  }

  const watchedPaymentMethod = watch("payment_method");

  /**
   * This is compared by label because the ids are not stable between different objects.
   * @param defaultLabel
   * @param valueLabel
   * @returns
   */
  const isNotDefaultPaymentMethod = (
    defaultLabel: string | undefined,
    valueLabel: string
  ) => {
    if (!defaultLabel) {
      return false;
    } else if (valueLabel) {
      return !(defaultLabel === valueLabel);
    } else return false;
  };

  const paymentMethodsOptions =
    fetchedPaymentMethods?.data.map(convertPaymentModeToOption) ?? [];

  return (
    <>
      <DatePicker
        label={t("Shipment Schedule")}
        name={"required_eta"}
        methodsOfUseForm={methodsOfUseForm}
        required={true}
      />
      <CustomTermContainer
        highlight={isNotDefaultPaymentMethod(
          defaultPaymentMethod?.label,
          watchedPaymentMethod?.label
        )}
        has_error={!!errors.payment_method}
      >
        <Controller
          as={SelectBoxV2}
          control={control}
          name="payment_method"
          placeholder={t("Payment Method")}
          options={paymentMethodsOptions}
          defaultValue={
            defaultPaymentMethod ?? { value: undefined, label: undefined }
          }
          rules={{ required: true }}
          errors={errors}
          formState={formState}
        />
        {isNotDefaultPaymentMethod(
          defaultPaymentMethod?.label,
          watchedPaymentMethod?.label
        ) && (
          <WarningMessage>
            {t("Requested payment method is not the default")}
          </WarningMessage>
        )}
      </CustomTermContainer>
      <DatePicker
        label={t("Quote Valid Until")}
        name={"valid_to_date"}
        methodsOfUseForm={methodsOfUseForm}
        required={true}
      />
      <ConfirmationSpacer>
        <CheckBoxContainer style={{ marginBottom: "15px", width: "100%" }}>
          <div style={{ width: "22px", marginRight: "15px" }}>
            <CheckBoxNoLabel
              name="is_tax_exempt"
              id="is_tax_exempt"
              defaultChecked={isTaxExempt}
              onChange={(event) => updateTaxFee(event.currentTarget.checked)}
              ref={register({ required: false })}
            />
          </div>
          <CheckBoxFinePrintLabel htmlFor="is_tax_exempt">
            <ColoredTextOnError isError={!!errors.is_tax_exempt?.message}>
              {t("Tax Exempt")}
            </ColoredTextOnError>
          </CheckBoxFinePrintLabel>
        </CheckBoxContainer>
      </ConfirmationSpacer>
      <ConfirmationSpacer>
        {configuredCheckboxFields && configuredCheckboxFields?.length > 0 && (
          <>
            {create_configured_checkboxes_fields({
              fields: configuredCheckboxFields,
              methodsOfUseForm,
              t,
            })}
          </>
        )}
      </ConfirmationSpacer>
    </>
  );
}
