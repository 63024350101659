import React, { useContext } from "react";
import { Title } from "../../../../../components/Typography/Typography";
import useSWR from "swr";
import {
  useStoreState,
  convertDeliveryTermToOption,
  convertPaymentTermToOption,
  addressToOption,
  makeDeliveryTermsGetEndpoint,
  makePaymentTermsGetEndpoint,
} from "../../../../../util/util";
import { endpoints } from "../../../../../endpoints";
import type {
  Tenant,
  DeliveryTermPaginatedOutput,
  PaymentTermPaginatedOutput,
  ITenantCustomerSettings,
} from "../../../../../types/types";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { Loader } from "../../../../../components/Loader/Loader";
import { CenterContainer } from "../../../../../layout/publicPageLayout";
import { CreatePriceTierGroupStepOne } from "./CreatePriceTierGroupStepOne";
import { useTranslation } from "react-i18next";

/**
 * A form for seller admins to create price tiers from the customer detail page.
 * This workflow happens in two steps "because price tiers". If user changes
 * inputs in step one, that changes the existing price tiers out from under
 * them in step two.
 */
export const CreatePriceTierGroup = ({
  buyerTenantId,
  sellerId,
  onTierGroupCreated,
}: {
  buyerTenantId: string;
  sellerId: string;
  onTierGroupCreated: () => void;
}) => {
  const { storefront_id } = useStoreState();
  const { notifyError } = useContext(Notifications);
  const { t } = useTranslation();

  /**
   * Fetch the buyer tenant data to get shipping addresses.
   */
  const { data: buyerTenantData } = useSWR<Tenant>(
    endpoints.v1_storefronts_id_tenants_id(storefront_id, buyerTenantId),
    {
      onError: (error) => {
        notifyError("There was an error fetching the shipping addresses.", {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const shippingAddresses =
    buyerTenantData?.addresses.filter((add) => add.type === "Warehouse") ?? [];

  /**
   * Fetch delivery terms.
   */
  const { data: fetchedDeliveryTerms } = useSWR<DeliveryTermPaginatedOutput>(
    makeDeliveryTermsGetEndpoint(storefront_id),
    {
      onError: (error) => {
        notifyError("There was an error fetching the shipping terms.", {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  /**
   * Fetch payment terms.
   */
  const { data: fetchedPaymentTerms } = useSWR<PaymentTermPaginatedOutput>(
    makePaymentTermsGetEndpoint(storefront_id),
    {
      onError: (error) => {
        notifyError("There was an error fetching the payment terms", {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  /**
   * Get settings to pre-fill default terms etc.
   */
  const { data: settingsData } = useSWR<ITenantCustomerSettings>(
    endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
      storefront_id,
      sellerId,
      buyerTenantId
    ),
    {
      onError: (error) => {
        notifyError("There was an error fetching customer settings", { error });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const defaultDeliveryTerm = settingsData?.default_delivery_term;
  const defaultPaymentTerm = settingsData?.default_payment_term;

  const defaultDeliveryTermOption =
    defaultDeliveryTerm && convertDeliveryTermToOption(defaultDeliveryTerm);

  const defaultPaymentTermOption =
    defaultPaymentTerm && convertPaymentTermToOption(defaultPaymentTerm);

  const destinationOptions = shippingAddresses.map(addressToOption);

  const deliveryTermsOptions =
    fetchedDeliveryTerms?.data.map(convertDeliveryTermToOption) ?? [];

  const paymentTermsOptions =
    fetchedPaymentTerms?.data.map(convertPaymentTermToOption) ?? [];

  if (
    !buyerTenantData ||
    !fetchedDeliveryTerms ||
    !fetchedPaymentTerms ||
    !settingsData
  ) {
    // Loading.
    return (
      <>
        <Title>{t("Create Price Tiers")}</Title>
        <CenterContainer>
          <Loader isLoading={true} />
        </CenterContainer>
      </>
    );
  }
  return (
    <CreatePriceTierGroupStepOne
      sellerId={sellerId}
      buyerTenantId={buyerTenantId}
      destinationOptions={destinationOptions}
      deliveryTermsOptions={deliveryTermsOptions}
      paymentTermsOptions={paymentTermsOptions}
      defaultDeliveryTermOption={defaultDeliveryTermOption}
      defaultPaymentTermOption={defaultPaymentTermOption}
      currencyCode={settingsData.preferred_currency}
      onTierGroupCreated={onTierGroupCreated}
    />
  );
};
