import type { AxiosError } from "axios";
import axios from "axios";
import type { DataMutate, OptionType } from "../../../types/types";
import { useStoreState } from "../../../util/util";
import { NewAssetForm } from "../ProductDetailPage/Assets/NewAssetForm";
import { useNotifications } from "../../../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";

export const NewTransactionDocument = <ParentData,>({
  products,
  transaction_type,
  transaction_type_id,
  fetchData,
  onComplete,
}: {
  products: OptionType<string>[];
  transaction_type: "orders" | "quotes" | "sample-requests";
  transaction_type_id: string;
  fetchData: DataMutate<ParentData>;
  onComplete: () => void;
}) => {
  const { storefront_id } = useStoreState();
  const { notifyError, notifySuccess } = useNotifications();
  const { t } = useTranslation();
  const onAddAssetData = async (data: {
    input_lang_type?: OptionType<string>;
    product_id?: OptionType<string>;
    asset_name: string;
    custom_category_name?: string;
    file_type: File;
    input_asset_type: OptionType<string>;
    input_category_type: OptionType<string>;
  }) => {
    const formData = new FormData();
    formData.append("file", data.file_type);
    formData.append(
      "category_name",
      data.input_category_type.value === "other"
        ? data.custom_category_name ?? ""
        : data.input_category_type.label
    );
    formData.append("asset_name", data.asset_name);
    formData.append(
      "is_all_product",
      data.product_id?.value === "all_products" || !data.product_id?.value
        ? "true"
        : "false"
    );
    if (data.product_id?.value && data.product_id?.value !== "all_products") {
      formData.append("product_id", data.product_id?.value || "");
    }
    formData.append("language", data.input_lang_type?.value || "");
    try {
      await axios.put(
        `/v2/storefronts/${storefront_id}/${transaction_type}/${transaction_type_id}/documents`,
        formData
      );
      await fetchData();
      notifySuccess(t("Document added successfully."));
      onComplete();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not add document. Something went wrong."),
        {
          error,
        }
      );
    }
  };
  return (
    <NewAssetForm
      products={products}
      refreshParentData={fetchData}
      onAddAssetData={onAddAssetData}
      defaultAssetType="Documents"
      default_asset_types={[{ label: "Document", value: "document" }]}
      default_categories={[
        { label: "Technical Data Sheet (TDS)", value: "tds" },
        { label: "Safety Data Sheet (SDS)", value: "sds" },
        { label: "Other", value: "other" },
      ]}
    />
  );
};
