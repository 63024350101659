import React, { useContext } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  SecondaryButtonWithPlusIcon,
} from "../../../../../components/Buttons/Buttons";
import { SectionTitle } from "../../../../../components/Form/Form";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { Form } from "../../../../../layout/FormLayout";
import type { OptionType } from "../../../../../types/types";
import type {
  AttributeGroupArgsSchema,
  AttributeSchema,
} from "../../../../../types/types.PIM";
import {
  removeUnderscore,
  toTitleCase,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import type { AxiosError } from "axios";
import Axios from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { SelectWithDeleteButton } from "../../../../../layout/shared";
import { z } from "zod";
import {
  zodOptionalString,
  zodRequiredString,
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../../util/zod.util";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  MarginBottomH6,
  MarginBottomHeaderLeft,
  shouldUpdateDisplayName,
} from "../SellerAdminPIMAttributes/CreateAttribute";
import { findNonEmptyDuplicateIndexes } from "../../../../../util/form.utils";
import { AsyncSearchSelect } from "../../../../../components/AsyncSearchSelect/AsyncSearchSelect";
import { useHandleAttributesAsyncSearch } from "../SellerAdminPIMAttributesCollections/util";

const CreateAttributeGroupSchema = z.object({
  name: z.string(),
  display_name: z.string().optional(),
  first_attribute: zodSelectBoxType,
  group: z
    .object({
      attribute: zodSelectBoxType,
    })
    .array()
    .optional(),
});

const CreateAttributeGroupSchemaFn = (t: (s: string) => string) =>
  z
    .object({
      name: zodRequiredString(t),
      display_name: zodOptionalString,
      first_attribute: zodSelectBoxDefault(t),
      group: z
        .object({
          attribute: zodSelectBoxDefault(t),
        })
        .array()
        .optional(),
    })
    .superRefine(({ first_attribute, group }, ctx) => {
      const duplicateIdxs = group
        ? findNonEmptyDuplicateIndexes([
            first_attribute.value,
            ...group.map(({ attribute: { value } }) => value),
          ])
        : [];
      if (duplicateIdxs.length > 0) {
        duplicateIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Duplicate attributes not allowed"),
            path: [`group[${idx - 1}].attribute`],
          });
        });
      }
    });

/**
 * At least on attribute is required, and we can't delete the first one. For
 * that reason the first attribute is not part of the `group` array.
 */
type formOutput = z.infer<typeof CreateAttributeGroupSchema>;

/**
 * @param attribute AttributeSchema
 * @returns OptionType<"name (type)">
 */
export const attributeToOption = (
  attribute: AttributeSchema
): OptionType<string> => ({
  value: attribute.id,
  label: `${attribute.name} (${toTitleCase(
    removeUnderscore(attribute.input_type)
  )})`,
});

export function CreateNewAttributeGroup({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { handleAttributesSearch } = useHandleAttributesAsyncSearch();

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(CreateAttributeGroupSchemaFn(t)),
    defaultValues: {
      first_attribute: { label: undefined, value: undefined },
    },
    reValidateMode: "onChange",
  });
  const {
    handleSubmit,
    control,
    formState,
    errors,
    register,
    watch,
    setValue,
  } = methodsOfUseForm;
  const { notifyError, notifySuccess } = useContext(Notifications);

  const { fields, remove, append } = useFieldArray({
    control,
    name: "group",
  });

  const createFields = (): JSX.Element[] => {
    return fields.map((field, index: number) => (
      <SelectWithDeleteButton key={field.id}>
        <Controller
          key={field.id}
          as={AsyncSearchSelect}
          control={control}
          name={`group[${index}].attribute`}
          defaultValue={{ label: "", value: "" }}
          placeholder={t("Select Attribute")}
          searchFunction={handleAttributesSearch}
          errors={{
            [`group[${index}].attribute`]:
              errors?.group?.[index]?.attribute?.value ??
              errors?.group?.[index]?.attribute ??
              undefined,
          }}
          formState={formState}
          defaultOptions
        />
        <DeleteButton
          testid={`delete-button-${index}`}
          onClick={() => remove(index)}
          type="button"
          height={20}
          width={20}
        />
      </SelectWithDeleteButton>
    ));
  };

  const onSubmit = async (values: formOutput) => {
    const attrs: { id: string }[] | [] = values.group
      ? values.group.map((attr) => {
          return {
            id: attr.attribute.value,
          };
        })
      : [];

    const group: AttributeGroupArgsSchema = {
      name: values.name.trim(),
      display_name: values.display_name ? values.display_name.trim() : "",
      attributes: [{ id: values.first_attribute.value }, ...attrs],
    };

    try {
      await Axios.post(`/v2/tenants/${tenant_id}/pim/groups`, group);
      notifySuccess(t("Group created successfully"));
      onSuccess();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error creating the group"),
        {
          error,
        }
      );
    }
  };

  const { name: nameValue, display_name: displayNameValue } = watch([
    "name",
    "display_name",
  ]);
  if (
    shouldUpdateDisplayName(
      nameValue,
      displayNameValue,
      !!formState.dirtyFields["display_name"]
    )
  ) {
    setValue("display_name", nameValue);
  }
  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Create Group")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <MarginBottomH6>{t("Overview")}</MarginBottomH6>
        <TextField
          name="name"
          label={t("Group Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="display_name"
          label={t("Display Name")}
          theref={register()}
          formState={formState}
          errors={errors}
          type="text"
        />
        <MarginBottomH6>{t("Choose existing attributes")}</MarginBottomH6>
        <div style={{ width: "420px" }}>
          <Controller
            as={AsyncSearchSelect}
            control={control}
            name="first_attribute"
            placeholder={t("Select Attribute")}
            searchFunction={handleAttributesSearch}
            errors={errors}
            formState={formState}
            defaultOptions
          />
        </div>

        {createFields()}
        <SecondaryButtonWithPlusIcon
          type="button"
          style={{ marginBottom: "40px", fontSize: "15px" }}
          onClick={() => append({ name: "" })}
        >
          {t("Add attribute")}
        </SecondaryButtonWithPlusIcon>
        <PrimaryButtonFitContainer type="submit">
          {t("Create group")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
}
