import React from "react";
import { useTranslation } from "react-i18next";
import { ClickableLink } from "../Typography/Typography";
import { useStoreState } from "../../util/util";

interface ITermsOfServiceLinkProps {
  text?: string;
}
export const TermsOfServiceLink = ({ text }: ITermsOfServiceLinkProps) => {
  const { t } = useTranslation();
  const { storefront_metadata } = useStoreState();

  const termsOfSaleDocumentURL =
    storefront_metadata.policy_documents.find((doc) => {
      return doc.kind === "terms_of_sale";
    })?.signed_url ?? "/404";

  return (
    <>
      <ClickableLink href={termsOfSaleDocumentURL}>
        {text ? text : t("Terms of Sale")}
      </ClickableLink>
    </>
  );
};
