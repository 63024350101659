import React from "react";
import { useHistory } from "react-router-dom";
import { PrimaryButtonMedium } from "../../components/Buttons/Buttons";
import { H4, H6, Title } from "../../components/Typography/Typography";
import styled, { ThemeProvider } from "styled-components";
import { AG3Theme } from "../../theme";
import { CenterContainer, PortfolioTitle } from "../../layout/publicPageLayout";

const AgilisLink = styled.a`
  color: ${(props) => props.theme.primaryLinkColor};
  font-weight: bold;
  display: inline-block;
  margin-left: 5px;
`;
const BackButton = styled(PrimaryButtonMedium)`
  margin: 30px 0;
`;
export function NotFoundPage() {
  const history = useHistory();
  return (
    <ThemeProvider theme={AG3Theme}>
      <CenterContainer>
        <PortfolioTitle>404</PortfolioTitle>
        <Title>Page not found</Title>
        <H4>
          The page you are looking for doesn't exist or an error occurred.
        </H4>

        <BackButton onClick={() => history.goBack()}>Go Back</BackButton>
        <H6>
          Or head over to
          <AgilisLink
            style={{ textDecoration: "none" }}
            href="https://agilischemicals.com/"
          >
            Agilis Chemicals
          </AgilisLink>
        </H6>
      </CenterContainer>
    </ThemeProvider>
  );
}
