import { useTranslation } from "react-i18next";
import { Title } from "../../../components/Typography/Typography";
import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import { useContext, useEffect, useState } from "react";
import { useFormWrapper, useStoreState } from "../../../util/util";
import * as yup from "yup";
import { SearchSelectInfiniteScroll } from "../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { endpoints } from "../../../endpoints";
import type { PIMProductBase } from "../../../types/types.PIM";
import { Form } from "../../../layout/FormLayout";
import type { UUID } from "../../../types/types";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { strings } from "../../../util/strings";
import axios from "axios";
import { mutate } from "swr";
import { InfoBlockSmall } from "../../../components/InfoBlocks/InfoBlocks";
import { Controller } from "react-hook-form";

export const LinkUnlistedProduct = ({
  handleCloseFlyout,
  requestType,
  requestId,
  itemId,
  buyerId,
}: {
  handleCloseFlyout: () => void;
  requestType: "quote" | "sample" | "order";
  requestId?: UUID;
  itemId?: UUID;
  buyerId: UUID;
}) => {
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { tenant_id, storefront_id } = useStoreState();
  const [selectedProduct, setSelectedProduct] =
    useState<{ value: string; label: string; id: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const linkProduct = yup.object().shape({
    product: yup
      .object()
      .shape({ value: yup.string(), label: yup.string() })
      .required(strings(t).thisIsARequiredField),
  });
  const methodsOfUseForm = useFormWrapper({
    reValidateMode: "onChange",
    resolver: yupResolver(linkProduct),
  });

  const { handleSubmit, control, formState, errors, watch } = methodsOfUseForm;
  const productField = watch("product");
  const onSubmit = async () => {
    const transactionType =
      requestType === "quote"
        ? "quotes"
        : requestType === "sample"
        ? "sample-requests"
        : null;

    try {
      setIsSubmitting(true);
      const success = await axios.post(
        `/v1/storefronts/${storefront_id}/${
          requestType === "sample" ? "samples" : "quotes"
        }/${requestId}/items/${itemId}/link`,
        {
          product_id: selectedProduct?.value,
        }
      );
      if (success) {
        setIsSubmitting(false);
        mutate(
          `/v1/storefronts/${storefront_id}/${transactionType}/${requestId}`
        );
        notifySuccess(t("Product has been linked Successfully"));
        handleCloseFlyout();
      }
    } catch (error) {
      notifyError("There was an error linking the product, please try again", {
        error,
      });
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (productField) {
      setSelectedProduct(productField);
    }
  }, [productField]);

  return (
    <>
      <Title>{t("Link Product")}</Title>
      <Form>
        <Controller
          control={control}
          as={SearchSelectInfiniteScroll}
          name="product"
          errors={errors}
          formState={formState}
          placeholder={t("Select Product")}
          baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
          params={(() => {
            const params = new URLSearchParams();
            params.append("order_by", "asc");
            params.append("status", "published");
            params.append("show_inactive", "false");
            params.append("buyer_tenant_id", buyerId);
            return params;
          })()}
          getOptions={(response: PIMProductBase[]) =>
            response.map((product) => ({
              value: product.id,
              label: product.name,
              id: product.product_number,
            }))
          }
        />
        {selectedProduct?.id && (
          <InfoBlockSmall
            header={t("Product ID")}
            content={selectedProduct?.id}
          />
        )}
        <PrimaryButtonFitContainer
          disabled={!selectedProduct}
          loading={isSubmitting}
          type="button"
          onClick={handleSubmit(onSubmit)}
        >
          {t("Link Product")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
