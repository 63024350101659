import { PrimaryButtonSmall } from "../../../components/Buttons/Buttons";
import { EditablePriceList } from "../../../components/EditablePriceList/EditablePriceList";
import { SectionTitle } from "../../../components/Form/Form";
import { Row } from "../../../components/Layout/Layout";
import type { Currency, Fee, MethodsOfUseForm } from "../../../types/types";
import { formatPrice } from "../../../util/util-components";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const FeesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SectionTitleNoMargin = styled(SectionTitle)`
  margin: 0 0 14px;
`;

const calculateTotal = (subtotal: number, values: Fee[]) => {
  const totalFees: number = values.reduce(
    (a, b) => a + (Number(b["amount"]) || 0),
    0
  );
  return Number(subtotal) + Number(totalFees);
};

interface IQuoteFees {
  methodsOfUseForm: MethodsOfUseForm;
  currency: Currency;
  subtotal: number;
}

export const QuoteFees = forwardRef<
  { updateTaxFee: (isTaxExempt: boolean) => void },
  IQuoteFees
>(({ methodsOfUseForm, currency, subtotal }: IQuoteFees, ref) => {
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const { watch, control } = methodsOfUseForm;
  const methodsOfUseFieldArray = useFieldArray({
    control,
    name: "feesList",
    keyName: "key",
  });
  const { fields, append, prepend, remove } = methodsOfUseFieldArray;

  useEffect(() => {
    setTotal(calculateTotal(subtotal, watch("feesList", fields)));
  }, [setTotal, watch, subtotal, fields]);

  useImperativeHandle(
    ref,
    () => ({
      updateTaxFee(isTaxExempt: boolean) {
        const hasTaxFee = fields.some((fee) => fee.key === "tax_fee");
        if (!isTaxExempt && !hasTaxFee) {
          prepend({ name: "Tax", amount: 0, key: "tax_fee" });
        } else {
          remove(fields.findIndex((fee) => fee.key === "tax_fee"));
        }
      },
    }),
    [fields, prepend, remove]
  );

  return (
    <FeesContainer>
      <SectionTitleNoMargin>{t("Fees")}</SectionTitleNoMargin>
      <EditablePriceList
        methodsOfUseForm={methodsOfUseForm}
        methodsOfUseFieldArray={methodsOfUseFieldArray}
        testIdPrefix={"quote-fee"}
        currencySymbol={currency.symbol}
        hasPossibleTaxFee={true}
      />
      <Row style={{ margin: "13px 0px" }}>
        <PrimaryButtonSmall
          type="button"
          onClick={() => append({ name: "", amount: "" })}
        >
          {t("Add Fee")}
        </PrimaryButtonSmall>
      </Row>
      <Row>
        <SectionTitleNoMargin>{t("Total")}</SectionTitleNoMargin>
        <SectionTitleNoMargin>
          {" "}
          {formatPrice(total, currency.code)}
        </SectionTitleNoMargin>
      </Row>
    </FeesContainer>
  );
});
