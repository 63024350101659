import React, { useState } from "react";
import { DisplayAccordion } from "./DisplayAccordion";
import { EditTopRightWithPositioning } from "../EditTopRight/EditTopRightWithPositioning";
import { SlideOut } from "../SlideOut/SlideOut";
import { EditAccordion } from "./EditAccordion";
import { NothingToDisplay } from "../NothingToDisplay/NothingToDisplay";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { HomePageTitle } from "../../layout/publicPageLayout";

interface IEditableAccordionProps {
  homePageData: IHomePageData;
  isEditable: boolean;
}

export function DisplayAndEditAccordion({
  homePageData,
  isEditable,
}: IEditableAccordionProps) {
  const [editMode, setEditMode] = useState(false);
  const startEditMode = () => setEditMode(true);
  const endEditMode = () => setEditMode(false);

  const component = homePageData.config.components.find(
    (component) => component.name === "accordion"
  );

  if (component && component?.data?.accordionContent?.length === 0) {
    return (
      <>
        <SlideOut closeFlyout={endEditMode} show={editMode}>
          {/* need to give EditAccordion everything so it can make the PUT
           request */}
          <EditAccordion components={homePageData} endEditMode={endEditMode} />
        </SlideOut>
        {isEditable && <EditTopRightWithPositioning onClick={startEditMode} />}
        <HomePageTitle>
          {component?.data?.header || "Frequently Asked Questions"}
        </HomePageTitle>
        <NothingToDisplay />
      </>
    );
  } else {
    const data = component?.data;
    const header = data?.header ?? "Frequently Asked Question";
    const accordionContent = data?.accordionContent ?? [];

    // props to control behavior of accordion.
    const collapsible = data?.collapsible;
    const multiple = data?.multiple;

    return (
      <>
        <SlideOut closeFlyout={endEditMode} show={editMode}>
          <EditAccordion components={homePageData} endEditMode={endEditMode} />
        </SlideOut>
        {isEditable && <EditTopRightWithPositioning onClick={startEditMode} />}
        <DisplayAccordion
          collapsible={collapsible}
          multiple={multiple}
          header={header}
          accordionContent={accordionContent}
        />
      </>
    );
  }
}
