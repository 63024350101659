import { useState, useContext } from "react";
import { Form } from "../../../../layout/FormLayout";
import { useStoreState, useFormWrapper } from "../../../../util/util";
import type {
  ITenantCustomerSettingsCreation,
  TaxExemptionDocument,
  ProductDocument,
} from "../../../../types/types";
import { PrimaryButtonMedium } from "../../../../components/Buttons/Buttons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import type { AxiosError } from "axios";
import { endpoints } from "../../../../endpoints";
import { FormContainer, SaveButtonContainer } from "./CustomerSettings";
import { ToggleSwitch } from "../../../../components/ToggleSwitch/ToggleSwitch";
import { useTranslation } from "react-i18next";
import {
  FileCard,
  FileCardUploading,
} from "../../../../components/FileCard/FileCard";
import type { IFileCardFile } from "../../../../components/FileCard/FileCard";
import { H4 } from "../../../../components/Typography/Typography";
import { FilePickerUncontrolled } from "../../../../components/FilePicker/FilePickerUncontrolled";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";

export const EditTaxExemptedForm = ({
  tenantID,
  sellerID,
  refreshSettingsData,
  doneEditing,
  taxExempted,
  document,
}: {
  tenantID: string;
  sellerID: string;
  refreshSettingsData?: (isTaxExempted: boolean) => void;
  doneEditing: () => void;
  taxExempted?: boolean;
  document?: ProductDocument;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTaxExempted, setIsTaxExempted] = useState(taxExempted || false);
  const [uploadingFile, setUploadingFile] = useState<File>();
  const [taxExemptDocument, setTaxExemptDocument] =
    useState<
      | (IFileCardFile & {
          signed_url: string;
        })
      | undefined
    >(document);
  const { t } = useTranslation();
  const { handleSubmit } = useFormWrapper();
  const methodsOfUseForm = useForm();

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const requestBody: ITenantCustomerSettingsCreation = {
        is_tax_exempt: isTaxExempted,
      };
      await Axios.patch(
        endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
          storefront_id,
          sellerID,
          tenantID
        ),
        requestBody
      );
      notifySuccess(t("Changes saved"));
      setIsSubmitting(false);
      if (refreshSettingsData) {
        refreshSettingsData(isTaxExempted);
      }
      doneEditing();
    } catch (error) {
      notifyError(t("There was an error submitting the changes"), { error });
      setIsSubmitting(false);
    }
  };

  const viewDocument = async (id: string) => {
    if (taxExemptDocument) {
      window.open(taxExemptDocument.signed_url, "_blank");
    }
  };

  const handleFile = async (file: File) => {
    if (file) {
      setUploadingFile(file);
      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadedDoc = await Axios.post<TaxExemptionDocument>(
          `/v1/storefronts/${storefront_id}/tenants/${sellerID}/customers/${tenantID}/tax_exemption`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        notifySuccess(t("Document uploaded successfully"));
        setTaxExemptDocument(uploadedDoc.data);
        setUploadingFile(undefined);
        // mutate the SWR source data so we can see the new file with FileCard.
        if (refreshSettingsData) {
          refreshSettingsData(isTaxExempted);
        }
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("Upload failed, something went wrong"),
          {
            error,
          }
        );
        setUploadingFile(undefined);
      }
    }
  };

  const deleteDocument = async (documentId: string) => {
    try {
      await Axios.delete(
        `/v1/storefronts/${storefront_id}/customers/${tenantID}/documents/${documentId}`
      );
      if (refreshSettingsData) {
        refreshSettingsData(isTaxExempted);
      }
      setTaxExemptDocument(undefined);
      notifySuccess(t("Document has been removed successfully."));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error deleting the document"),
        {
          error,
        }
      );
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ToggleSwitch
          label={t("Tax Exempt")}
          name="tax_exempt"
          isChecked={isTaxExempted}
          onClick={() => setIsTaxExempted((prev) => !prev)}
        />
        {isTaxExempted && (
          <>
            {!taxExemptDocument && (
              <>
                <H4>{t("Upload Tax Exempt PDF Document")}</H4>
              </>
            )}
            {taxExemptDocument && (
              <>
                <H4>{t("Tax Exempt PDF Document")}</H4>
              </>
            )}
            {!uploadingFile && !taxExemptDocument && (
              <>
                <FilePickerUncontrolled
                  methodsOfUseForm={methodsOfUseForm}
                  placeHolderText={t("Drag and drop Tax Exempt PDF Document")}
                  name={"tax_exempt_upload"}
                  accept={".pdf"}
                  handleFile={handleFile}
                  required={false}
                />
              </>
            )}
            {uploadingFile && (
              <FileCardUploading fileName={uploadingFile.name} />
            )}
            {taxExemptDocument && (
              <FileCard
                file={taxExemptDocument}
                viewFile={viewDocument}
                deleteProps={{
                  deleteFile: deleteDocument,
                  confirmMessage: t(
                    "Are you sure you want to delete the Tax Exempt Document?"
                  ),
                }}
              />
            )}
          </>
        )}
        <SaveButtonContainer>
          <PrimaryButtonMedium loading={isSubmitting}>
            {t("Save your changes")}
          </PrimaryButtonMedium>
          <ReactTooltip id="save-button" />
        </SaveButtonContainer>
      </Form>
    </FormContainer>
  );
};
