import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components/macro";

import { Title } from "../../../components/Typography/Typography";
import { StatusBox, WarningMessageBox } from "../../../components/Form/Form";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { Form } from "../../../layout/FormLayout";
import { strings } from "../../../util/strings";
import {
  addressToOption,
  convertPaymentTermToOption,
  convertDeliveryTermToOption,
  useFormWrapper,
  makeDeliveryTermsGetEndpoint,
  makePaymentTermsGetEndpoint,
  isAxiosError,
  convertApplicationToOption,
  markDefaultTerm,
} from "../../../util/util";
import useSWR from "swr";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import type { AxiosError } from "axios";
import { useNotifyErrorOnce } from "../../../components/Notifications/useNotifyErrorOnce";
import { useRoutePath } from "../../../util/Routing";
import { useHistory, useLocation } from "react-router-dom";
import { Store } from "../../../Store";
import {
  QuantityFormPartProductSku,
  QuantityFormPartNoPackaging,
  QuantityFormPartCustomSku,
  customPackagingOption,
  noPackagingOption,
} from "../../../components/quoteCart/BuyerQuoteItemFormParts";
import {
  packagingTypeOrUnitToOption,
  useStorefrontPackagingTypes,
  usePackagingUnits,
} from "../../../util/SkuUtils";
import {
  productApplicationSchema,
  ProductApplicationSelect,
} from "../../../components/ProductApplicationSelect/ProductApplicationSelect";
import { useQuantityInputFieldClearer } from "../../../components/quoteCart/useQuantityInputFieldClearer";
import { useTranslation } from "react-i18next";
import { WarningIcon } from "../../../components/Icons/Icons";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { newShippingAddress } from "../../../components/quoteCart/cartUtils";
import { TextField } from "../../../components/TextFields/TextFields";
import {
  PrimaryButtonFitContainer,
  SecondaryButtonFitContainer,
} from "../../../components/Buttons/Buttons";
import { positiveIntegerRegex } from "../../../util/regexes";
import type {
  OptionType,
  ProductSKU,
  Tenant,
  PaymentTermPaginatedOutput,
  DeliveryTermPaginatedOutput,
  User,
  IAddress,
  IPaymentTerm,
  IDeliveryTerm,
  CurrencyCode,
  ProductApplication,
  SkuNoPreference,
  UnifiedCartArg,
  IPackagingUnit,
  IPackagingType,
} from "../../../types/types";

const StatusBoxInnerContainer = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 15px;
  }
`;

type QuoteItemFormDetailsProps = BuyerQuoteItemFormProps & {
  buyerTenantData?: Tenant;
  fetchedDeliveryTerms?: DeliveryTermPaginatedOutput;
  fetchedPaymentTerms?: PaymentTermPaginatedOutput;
  packagingUnits?: IPackagingUnit[];
  packagingTypes?: IPackagingType[];
  product_name?: string;
  quoteItems?: string[];
  disableProductName?: boolean;
};

export const PriceOffersHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.large};
  margin-top: 30px;
`;

/**
 * Calculate the "total" quantity based on the SKU and number of units.
 * In case of trouble, return `0` or null.
 */
export const calculateTotalQuantity = (
  skuPackageVolume: number | string | null | undefined,
  numberOfUnits: string | null | undefined
): number | null => {
  if (!skuPackageVolume || !numberOfUnits) {
    return null;
  }
  const packageVolume =
    typeof skuPackageVolume === "number"
      ? skuPackageVolume
      : parseFloat(skuPackageVolume); // package volume can be floats.

  const units = parseInt(numberOfUnits); // number of units should always be integer. (There's a validation that ensures that)
  if (isNaN(packageVolume) || isNaN(units)) {
    return null;
  }
  return Number((packageVolume * units).toFixed(2));
};

/**
 * Format the "total" string. For example, returns "160 US Gallon". In case of
 * null SKU, return just the total (e.g. "160").
 */
export const formatTotalQuantityString = (
  packagingUnitName: string | null | undefined,
  total: number
): string => {
  if (!packagingUnitName) {
    return String(total);
  }
  return `${total} ${packagingUnitName}`;
};

/**
 * Remove the "quote=1" param from a url search params string.
 */
const removeQuoteParam = (params: string): string => {
  const urlSearchParams = new URLSearchParams(params);
  urlSearchParams.delete("quote");
  const newParams = urlSearchParams.toString();

  return newParams.length > 0 ? `?${newParams}` : "";
};

export type PackagingOption =
  | OptionType<ProductSKU>
  | OptionType<"custom_packaging">
  | OptionType<"no_packaging">;

interface FormInputs {
  destination: OptionType<string>;
  product_name: string;
  delivery_term: OptionType<string>;
  payment_term: OptionType<string>;
  // If user does not select an application then it is undefined.
  application?: OptionType<string> | OptionType<null>;
  custom_application?: string;
  // The actual inputs in the form depend on what the user selects for packaging.
  // We put all of them here, some as optional, and then enforce things with
  // form validation. (I tried creating a stricter type but it led to too many
  // type checking errors related to react-hook-form, e.g. for default arguments.)
  packaging: PackagingOption;

  total_quantity: string;
  no_of_units?: string;
  unit_of_measure?: OptionType<string>;
  custom_packaging_type?: OptionType<string>;
  custom_packaging_quantity?: string;
}

export type ISubmitQuoteItemForm = (
  unifiedCartArg: UnifiedCartArg
) => Promise<void>;

export type BuyerQuoteItemFormProps = {
  editingExistingItem?: boolean;
  showProceedToCheckoutButton?: boolean;
  submitQuoteItemForm: ISubmitQuoteItemForm;
  buyerUser: User;
  product_name?: string;
  product_application?: ProductApplication;
  productSku?: ProductSKU | SkuNoPreference;
  no_of_units?: string;
  total_quantity?: string;
  custom_packaging_quantity?: string;
  shippingAddress?: IAddress;
  paymentTerm?: IPaymentTerm;
  deliveryTerm?: IDeliveryTerm;
  allowEditingTransactionValues: boolean;
  warning?: string | null;
  currencyCode: CurrencyCode;
  customApplication?: string;
  quoteItems: string[];
  disableProductName?: boolean;
};

/**
 * This form is for logged in buyers and lets them:
 * - add unlisted product to the unified cart, from the portfolio or my products page.
 * - edit existing unlisted product items that are already in the cart, from the portfolio
 *   or cart pages
 * - edit items in a quote request, from quote detail pages
 */
export const UnlistedQuoteItemForm = (props: BuyerQuoteItemFormProps) => {
  const {
    buyerUser,
    editingExistingItem,
    product_name,
    paymentTerm,
    deliveryTerm,
    product_application,
    allowEditingTransactionValues,
    customApplication,
    quoteItems,
    disableProductName,
  } = props;

  const { notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const {
    storeState: { storefront_id },
  } = useContext(Store);

  const { packagingUnits, isFetchingPackagingUnits } = usePackagingUnits();

  const { packagingTypes, isFetchingPackagingTypes } =
    useStorefrontPackagingTypes();

  const buyerTenantId = buyerUser.tenant_id;

  const deliveryTermsDisabled =
    !allowEditingTransactionValues && !!deliveryTerm;

  const paymentTermsDisabled = !allowEditingTransactionValues && !!paymentTerm;

  const {
    data: buyerTenantData,
    error: buyerTenantError,
    isValidating: isFetchingBuyerTenantData,
  } = useSWR<Tenant, AxiosError>(
    `/v1/storefronts/${storefront_id}/tenants/${buyerTenantId}`,
    { revalidateOnFocus: false }
  );

  useNotifyErrorOnce(t("Error fetching destinations"), buyerTenantError);

  const { data: fetchedDeliveryTerms, isValidating: isFetchingDeliveryTerms } =
    useSWR<DeliveryTermPaginatedOutput>(
      deliveryTermsDisabled
        ? null
        : makeDeliveryTermsGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError(t("There was an error fetching the shipping terms."), {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const { data: fetchedPaymentTerms, isValidating: isFetchingPaymentTerms } =
    useSWR<PaymentTermPaginatedOutput>(
      paymentTermsDisabled ? null : makePaymentTermsGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError(t("There was an error fetching the payment terms."), {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const isLoading =
    isFetchingBuyerTenantData ||
    isFetchingDeliveryTerms ||
    isFetchingPackagingTypes ||
    isFetchingPackagingUnits ||
    isFetchingPaymentTerms;

  return isLoading ? (
    <DelayedSpinner />
  ) : (
    <QuoteItemFormDetails
      {...props}
      buyerTenantData={buyerTenantData}
      editingExistingItem={editingExistingItem}
      fetchedDeliveryTerms={fetchedDeliveryTerms}
      fetchedPaymentTerms={fetchedPaymentTerms}
      packagingUnits={packagingUnits}
      product_name={product_name}
      packagingTypes={packagingTypes}
      customApplication={customApplication}
      product_application={product_application}
      quoteItems={quoteItems}
      disableProductName={disableProductName}
    />
  );
};

const QuoteItemFormDetails = ({
  editingExistingItem,
  showProceedToCheckoutButton,
  submitQuoteItemForm,
  buyerUser,
  product_application,
  no_of_units,
  productSku,
  total_quantity,
  custom_packaging_quantity,
  shippingAddress,
  paymentTerm,
  deliveryTerm,
  allowEditingTransactionValues,
  currencyCode,
  warning,
  buyerTenantData,
  fetchedDeliveryTerms,
  fetchedPaymentTerms,
  packagingUnits,
  packagingTypes,
  product_name,
  customApplication,
  quoteItems = [],
  disableProductName,
}: QuoteItemFormDetailsProps) => {
  const [updatedPackagingOption, setUpdatedPackagingOption] =
    useState<PackagingOption | null>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [isProceedingToCheckout, setIsProceedingToCheckout] = useState(false);

  const { notifySuccess, notifyError } = useContext(Notifications);

  const { storeDispatch } = useContext(Store);

  const { storePath } = useRoutePath();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const destinationDisabled =
    !allowEditingTransactionValues && !!shippingAddress;

  const deliveryTermsDisabled =
    !allowEditingTransactionValues && !!deliveryTerm;

  const paymentTermsDisabled = !allowEditingTransactionValues && !!paymentTerm;

  const allShippingAddresses =
    buyerTenantData?.addresses.filter(
      (a: IAddress) => a.type === "Warehouse"
    ) ?? [];

  const prefilledDestinationOption =
    shippingAddress && addressToOption(shippingAddress);

  const defaultDeliveryTerm =
    deliveryTerm ?? buyerUser.settings?.default_delivery_term;
  const userDefaultDeliveryTerm = buyerUser.settings?.default_delivery_term;

  const defaultPaymentTerm =
    paymentTerm ?? buyerUser.settings?.default_payment_term;
  const userDefaultPaymentTerm = buyerUser.settings?.default_payment_term;

  const prefilledDeliveryTermOption =
    defaultDeliveryTerm && convertDeliveryTermToOption(defaultDeliveryTerm);

  const prefilledPaymentTermOption =
    defaultPaymentTerm && convertPaymentTermToOption(defaultPaymentTerm);

  const prefilledApplicationOption = product_application
    ? convertApplicationToOption(product_application)
    : undefined;
  const destinationOptions =
    prefilledDestinationOption && destinationDisabled
      ? [prefilledDestinationOption]
      : [
          ...allShippingAddresses.map(addressToOption),
          newShippingAddress(t, t("New address on checkout")),
        ];

  const deliveryTermsOptions =
    prefilledDeliveryTermOption && deliveryTermsDisabled
      ? [prefilledDeliveryTermOption]
      : fetchedDeliveryTerms?.data.map(convertDeliveryTermToOption) ?? [];

  const paymentTermsOptions =
    prefilledPaymentTermOption && paymentTermsDisabled
      ? [prefilledPaymentTermOption]
      : fetchedPaymentTerms?.data.map(convertPaymentTermToOption) ?? [];

  const packagingOptions = [noPackagingOption, customPackagingOption];
  const prefilledPackagingOption = (() => {
    // Checking for the existence of a packaging_type ensures (for TypeScript)
    // that we have a full product or custom SKU, and not a "no preference" SKU.
    if (productSku && productSku.packaging_type) {
      if (productSku.buyer_id) {
        return customPackagingOption;
      }
    }
    return noPackagingOption;
  })();

  const packagingUnitOptions =
    packagingUnits?.map(packagingTypeOrUnitToOption) || [];

  const packagingTypeOptions =
    packagingTypes?.map(packagingTypeOrUnitToOption) || [];

  const prefilledUnitOfMeasure = (() => {
    const id = productSku?.packaging_unit?.id;
    if (id && packagingUnits && updatedPackagingOption) {
      const packagingUnit = packagingUnits.find((unit) => unit.id === id);
      if (packagingUnit) {
        return packagingTypeOrUnitToOption(packagingUnit);
      }
    }
    return undefined;
  })();

  const prefilledCustomPackagingType = (() => {
    if (productSku?.buyer_id) {
      const id = productSku?.packaging_type?.id;
      if (id && packagingTypes) {
        const packagingType = packagingTypes.find((type) => type.id === id);
        if (packagingType) {
          return packagingTypeOrUnitToOption(packagingType);
        }
      }
    }
    return undefined;
  })();

  const yupSharedSchema = {
    product_name: yup.string().required(strings(t).thisIsARequiredField),
    destination: yup
      .object()
      .nullable()
      .required(strings(t).thisIsARequiredField),
    delivery_term: yup
      .object()
      .nullable()
      .required(strings(t).thisIsARequiredField),
    payment_term: yup.object().required(strings(t).thisIsARequiredField),
    ...productApplicationSchema(t),
  };

  const mustBeIntegerErrorMessage = t("Must be an integer value");

  const yupSchemaNoPackaging = yup
    .object()
    .shape({
      ...yupSharedSchema,
      packaging: yup
        .object()
        .shape({ value: yup.string() })
        .required(strings(t).thisIsARequiredField),
      unit_of_measure: yup.object().required(strings(t).thisIsARequiredField),
      total_quantity: yup.string().required(strings(t).thisIsARequiredField),
    })
    .defined();

  const yupSchemaCustomPackaging = yup
    .object()
    .shape({
      ...yupSharedSchema,
      packaging: yup
        .object()
        .shape({ value: yup.string() })
        .required(strings(t).thisIsARequiredField),
      total_quantity: yup.string().required(strings(t).thisIsARequiredField),
      no_of_units: yup
        .string()
        .required(strings(t).thisIsARequiredField)
        // Use a regex to validate because it's a string.
        .matches(positiveIntegerRegex, mustBeIntegerErrorMessage),
      unit_of_measure: yup.object().required(strings(t).thisIsARequiredField),
      custom_packaging_type: yup
        .object()
        .required(strings(t).thisIsARequiredField),
      custom_packaging_quantity: yup
        .string()
        .required(strings(t).thisIsARequiredField),
    })
    .defined();

  type FormInputsNoPackaging = yup.InferType<typeof yupSchemaNoPackaging>;
  type FormInputsCustomPackaging = yup.InferType<
    typeof yupSchemaCustomPackaging
  >;

  type FormInputsFromSchemas =
    | FormInputsNoPackaging
    | FormInputsCustomPackaging;

  // We use yup.lazy to choose a schema to validate against based on what the
  // user selects for `packaging`. So dynamic!
  const lazyYupSchema = yup.lazy<FormInputsFromSchemas>((inputs) => {
    if (inputs.packaging.value === "custom_packaging") {
      return yupSchemaCustomPackaging;
    }
    return yupSchemaNoPackaging;
  });

  const methodsOfUseForm = useFormWrapper<FormInputs>({
    resolver: yupResolver(lazyYupSchema),
    defaultValues: {
      product_name: product_name,
      destination: prefilledDestinationOption,
      delivery_term: prefilledDeliveryTermOption,
      payment_term: prefilledPaymentTermOption,
      application: prefilledApplicationOption,
      packaging: prefilledPackagingOption,
      unit_of_measure: prefilledUnitOfMeasure,
      total_quantity: total_quantity || "",
      no_of_units: no_of_units || "",
      custom_packaging_type: prefilledCustomPackagingType,
      custom_packaging_quantity: custom_packaging_quantity || "",
    },
  });

  const { handleSubmit, register, control, formState, errors, watch } =
    methodsOfUseForm;

  const { packaging: packagingOption } = watch(["packaging"]);

  // If the user selects a product SKU then packagingSkuOption won't be null.
  const packagingSkuOption =
    packagingOption &&
    packagingOption.value !== "no_packaging" &&
    packagingOption.value !== "custom_packaging"
      ? packagingOption
      : null;

  useQuantityInputFieldClearer(methodsOfUseForm);

  useEffect(
    () => setUpdatedPackagingOption(packagingOption),
    [packagingOption]
  );

  // Because there are two buttons that can submit this form we use the
  // following click handlers to call the true `onSubmit` function below.

  const handleSaveClick = async (formInputs: FormInputs) => {
    if (!isSaving) {
      setIsSaving(true);
      await onSubmit(formInputs);
      setIsSaving(false);
    }
  };

  const handleProceedToCheckoutClick = async (formInputs: FormInputs) => {
    if (!isProceedingToCheckout) {
      setIsProceedingToCheckout(true);
      await onSubmit(formInputs);

      const params = removeQuoteParam(location.search);
      const payload = `${location.pathname}${params}${location.hash}`;

      storeDispatch({ type: "SET_STORED_LOCATION", payload });
      setIsProceedingToCheckout(false);
      history.push(`${storePath}/cart`);
    }
  };

  /**
   * Because this form is reused so frequently this handler actually calls the
   * true submit handler which is passed in as a prop. Thus the true submit
   * handler is defined in the parent component that renders this component.
   */
  const onSubmit = async (formInputs: FormInputs) => {
    try {
      const {
        packaging,
        application,
        custom_application,
        destination,
        delivery_term,
        payment_term,
        total_quantity,
        no_of_units,
        unit_of_measure,
        custom_packaging_type,
        custom_packaging_quantity,
        product_name,
      } = formInputs;

      if (!buyerUser.seller_id) {
        // Buyer seller_id should always be a string, but the type checker
        // doesn't know that.
        const message = "There was an error submitting the form";
        notifyError(message);
        console.error(message, "seller_id is null", buyerUser.seller_id);
        return;
      }

      const sharedInputs = {
        unlisted_product_name: product_name,
        product_id: null,
        applications: application?.value ? [application.value] : [],
        ...(custom_application ? { custom_application } : {}),
        currency: currencyCode,
        delivery_term_id: delivery_term.value,
        payment_term_id: payment_term.value,
        seller_id: buyerUser.seller_id,
        shipping_address_id:
          destination.value === "new_address" ? undefined : destination.value,
      };

      let unifiedCartArg: UnifiedCartArg | null = null;

      if (packaging.value === "no_packaging") {
        if (unit_of_measure?.value) {
          unifiedCartArg = {
            total_quantity,
            packaging_unit_id: unit_of_measure.value,
            ...sharedInputs,
          };
        }
      } else if (packaging.value === "custom_packaging") {
        if (
          no_of_units &&
          unit_of_measure?.value &&
          custom_packaging_type?.value &&
          custom_packaging_quantity
        ) {
          unifiedCartArg = {
            total_quantity,
            no_of_units,
            packaging_unit_id: unit_of_measure.value,
            custom_packaging_type_id: custom_packaging_type.value,
            custom_packaging_quantity,
            buyer_id: buyerUser.tenant_id,
            ...sharedInputs,
          };
        }
      } else {
        // Product SKU packaging.
        if (packagingSkuOption?.value.id && no_of_units) {
          unifiedCartArg = {
            total_quantity,
            sku_id: packagingSkuOption.value.id,
            no_of_units,
            ...sharedInputs,
          };
        }
      }

      if (!unifiedCartArg) {
        // Should never happen thanks to form validation.
        notifyError(
          t(`There was a problem with the form inputs, please try again`),
          { error: new Error("Form validation failed") }
        );
        return;
      }
      await submitQuoteItemForm(unifiedCartArg);

      notifySuccess(t("The cart has been updated"));
    } catch (error) {
      if (isAxiosError(error) && error?.response?.status === 409) {
        notifyError(t(`Cart is already in progress`));
        return;
      }
      notifyError(t("An error occurred while updating the cart"), {
        error,
      });
    }
  };

  const submitButtonText = editingExistingItem ? t("Save") : t("Add to cart");

  const proceedToCheckoutButtonText = editingExistingItem
    ? t("Save and Proceed to Checkout")
    : t("Proceed to Checkout");

  return (
    <div>
      {editingExistingItem && <Title>{t("Edit Item")}</Title>}

      <Form noValidate>
        {warning && (
          <WarningMessageBox>
            <div style={{ marginRight: "4px" }}>
              <WarningIcon width={18} />
            </div>
            {warning}
          </WarningMessageBox>
        )}
        <StatusBox>
          <StatusBoxInnerContainer style={{ marginTop: "15px" }}>
            <TextField
              label="Product Name"
              readOnly={disableProductName}
              name="product_name"
              theref={register({
                required: true,
              })}
              type="text"
              errors={errors}
              formState={formState}
            />
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="destination"
                placeholder={t("Shipping Address")}
                options={destinationOptions}
                defaultValue={prefilledDestinationOption}
                isDisabled={destinationDisabled}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
              />
            </div>
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="delivery_term"
                placeholder={t("Shipping Terms")}
                options={markDefaultTerm(
                  deliveryTermsOptions,
                  userDefaultDeliveryTerm
                    ? convertDeliveryTermToOption(userDefaultDeliveryTerm)
                    : undefined
                )}
                isDisabled={deliveryTermsDisabled}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
                setDefaultTerm={!!userDefaultDeliveryTerm}
              />
            </div>
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="payment_term"
                placeholder={t("Requested Payment Terms")}
                options={markDefaultTerm(
                  paymentTermsOptions,
                  userDefaultPaymentTerm
                    ? convertPaymentTermToOption(userDefaultPaymentTerm)
                    : undefined
                )}
                isDisabled={paymentTermsDisabled}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
                setDefaultTerm={!!userDefaultPaymentTerm}
              />
            </div>
            <div>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="packaging"
                placeholder={t("Preferred SKU")}
                options={packagingOptions}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
              />
            </div>
            {packagingOption?.value === "no_packaging" && (
              <>
                <QuantityFormPartNoPackaging
                  methodsOfUseForm={methodsOfUseForm}
                  unitOfMeasureOptions={packagingUnitOptions}
                  uomValue={prefilledUnitOfMeasure}
                />
              </>
            )}
            {packagingOption?.value === "custom_packaging" && (
              <>
                <QuantityFormPartCustomSku
                  methodsOfUseForm={methodsOfUseForm}
                  unitOfMeasureOptions={packagingUnitOptions}
                  packagingTypeOptions={packagingTypeOptions}
                  uomValue={prefilledUnitOfMeasure}
                />
              </>
            )}
            {packagingSkuOption && (
              <>
                <QuantityFormPartProductSku
                  methodsOfUseForm={methodsOfUseForm}
                  unitOfMeasure={packagingSkuOption.value.packaging_unit.name}
                  skuPackageVolume={packagingSkuOption.value.package_volume}
                />
              </>
            )}
          </StatusBoxInnerContainer>
        </StatusBox>
        <ProductApplicationSelect
          methodsOfUseForm={methodsOfUseForm}
          applications={[]}
          defaultApplcations={
            customApplication ? { value: -0, label: t("Other") } : undefined
          }
          defaultCustomApplication={customApplication}
        />
        <PrimaryButtonFitContainer
          onClick={handleSubmit(handleSaveClick)}
          loading={isSaving}
          disabled={isProceedingToCheckout}
        >
          {submitButtonText}
        </PrimaryButtonFitContainer>

        {showProceedToCheckoutButton && (
          <SecondaryButtonFitContainer
            onClick={handleSubmit(handleProceedToCheckoutClick)}
            loading={isProceedingToCheckout}
            disabled={isSaving}
          >
            {proceedToCheckoutButtonText}
          </SecondaryButtonFitContainer>
        )}
      </Form>
    </div>
  );
};
