import { PrimaryButtonFitContainer } from "../../../../../components/Buttons/Buttons";
import { LoadingIcon } from "../../../../../components/Icons/Icons";
import { TextField } from "../../../../../components/TextFields/TextFields";
import {
  H6,
  XLTitle,
  SoftHeader,
  SoftHeader2,
} from "../../../../../components/Typography/Typography";
import { DataList } from "../../../../../components/DataList/DataList";
import type {
  IEmailNotificationTemplate,
  IEmailTemplateEditorData,
  IStorefrontCustomTemplateCreate,
  IStorefrontCustomTemplatePatch,
  IStorefrontEmailTemplate,
  IStorefrontEmailTemplateBodyPreview,
  StorefrontMetaData,
} from "../../../../../types/types";
import { Form, FormItems } from "../../../../../layout/FormLayout";
import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import useSWR from "swr";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { endpoints } from "../../../../../endpoints";
import {
  useSupportedLanguages,
  isNetworkErrorWithMessage,
  makeUrlWithParams,
  replaceBrWithNewline,
  replaceNewlineWithBr,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import { NotificationTemplatePreviewer } from "./NotificationTemplatePreviewer";
import { RichEditor } from "../../../../../components/RichEditor/RichEditor";
import { useTranslation } from "react-i18next";
import { SaveButtonPrimaryMedium } from "../shared";
import { Store } from "../../../../../Store";

type formInputs = {
  subject: string;
  header: string;
  content: string;
  footer: string;
};

const NotificationsEditor = styled.div`
  padding: 30px 10px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const LoadingWrapper = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const NotificationsForm = styled.div`
  margin-right: 30px;
  min-width: 450px;
`;

const CustomH6 = styled(H6)`
  margin: 30px 0 15px;
`;

const TertiaryTextColor = styled.span`
  color: ${({ theme }) => theme.tertiaryTextColor};
`;

const RichEditorContainer = styled.div`
  .ql-container {
    height: fit-content;
  }
`;

interface FormValues {
  sender_name: string;
}

export const NotificationTemplateEditor = ({
  editorData,
  closeEditor,
}: {
  editorData: IEmailTemplateEditorData;
  closeEditor: () => void;
}) => {
  const [previewData, setPreviewData] = useState<IEmailNotificationTemplate>();
  const [isUpdatingTemplate, setIsUpdatingTemplate] = useState(false);
  const { storefront_id, api_metadata } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { templateData } = editorData;
  const { getLanguageLabel } = useSupportedLanguages();
  const { t } = useTranslation();
  const { data: renderedTemplate, error: defaultTemplateError } =
    useSWR<IStorefrontEmailTemplateBodyPreview>(
      // An `id` on `templateData` means we're editing an existing template.
      templateData?.id
        ? endpoints.v1_storefronts_id_emailTemplates_key_render_body(
            storefront_id,
            templateData.id
          )
        : makeUrlWithParams(
            endpoints.v1_emailTemplates_key_render_body(editorData.key),
            { language: editorData.language }
          ),
      { revalidateOnFocus: false }
    );

  const {
    data: formData,
    error: formDataError,
    mutate,
  } = useSWR<IStorefrontEmailTemplate>(
    // An `id` on `templateData` means we're editing an existing template.
    templateData?.id
      ? endpoints.v1_storefronts_id_emailTemplates_id(
          storefront_id,
          templateData.id
        )
      : makeUrlWithParams(endpoints.v1_emailTemplates_key(editorData.key), {
          language: editorData.language,
        }),
    { revalidateOnFocus: false }
  );

  const { register, handleSubmit, formState, watch, errors, setValue } =
    useFormWrapper({
      defaultValues: {
        subject: templateData?.template_subject,
        header: templateData?.custom_header,
        content: templateData?.email_body
          ? replaceBrWithNewline(templateData.email_body)
          : undefined,
        footer: templateData?.custom_footer,
      },
    });

  const subject = watch("subject");
  const header = watch("header");
  const content = watch("content");
  const footer = watch("footer");

  const onSubmit = async (inputs: formInputs) => {
    if (!formData) {
      // Should never happen because the UI won't render if we don't have a
      // successful formData response.
      notifyError("There was an error, please try again");
      return;
    }
    setIsUpdatingTemplate(true);

    const contentWithBrTags = replaceNewlineWithBr(inputs.content);

    if (templateData) {
      // Modify an existing custom template.
      const patchBody: IStorefrontCustomTemplatePatch = {
        template_subject: inputs.subject,
        custom_header: inputs.header,
        email_body: contentWithBrTags,
        language: editorData.language,
      };
      const url = endpoints.v1_storefronts_id_emailTemplates_id(
        storefront_id,
        templateData.id
      );
      try {
        await Axios.patch(url, patchBody);
        notifySuccess("Your changes have been saved successfully");
        setIsUpdatingTemplate(false);
        mutate();
        closeEditor();
      } catch (error) {
        const message = isNetworkErrorWithMessage(error)
          ? error.response.data.message
          : `There was an error saving the email template, please try again`;
        notifyError(message, { error });
        setIsUpdatingTemplate(false);
      }
    } else {
      // Create a new custom template (with modifications from the default).
      const postBody: IStorefrontCustomTemplateCreate = {
        template_key: editorData.key,
        // template_body needs to be in the POST request, and should be a copy
        // of what's in the default template (formData).
        template_body: formData.template_body,
        template_subject: inputs.subject,
        custom_header: inputs.header,
        email_body: contentWithBrTags,
        language: editorData.language,
      };
      const url = endpoints.v1_storefronts_id_emailTemplates(storefront_id);
      try {
        await Axios.post(url, postBody);
        notifySuccess("Your changes have been saved successfully");
        setIsUpdatingTemplate(false);
        mutate();
        closeEditor();
      } catch (error) {
        const message = isNetworkErrorWithMessage(error)
          ? error.response.data.message
          : `There was an error saving the email template, please try again`;
        notifyError(message, { error });
        setIsUpdatingTemplate(false);
        closeEditor();
      }
    }
  };

  const variablesList = formData?.template_variables || [];

  useEffect(() => {
    setPreviewData({
      subject: subject,
      header: header,
      content: replaceNewlineWithBr(content || ""),
      footer: footer,
      template: renderedTemplate || "",
      recipients: formData?.recipients,
    });
  }, [subject, header, content, footer, renderedTemplate, formData]);

  useEffect(() => {
    if (formData) {
      setValue("subject", formData.template_subject || formData.subject);
      setValue("header", formData.custom_header);
      setValue(
        "content",
        formData.email_body ? replaceBrWithNewline(formData.email_body) : ""
      );
    }
  }, [formData, setValue]);

  if (!formData && !formDataError) {
    // loading
    return null;
  }
  if (formDataError) {
    return (
      <ErrorPlaceholder
        message={"There was an error retrieving the template content."}
      />
    );
  }
  return (
    <NotificationsEditor>
      {!formDataError && formData && (
        <>
          <NotificationsForm>
            <XLTitle>
              {templateData || editorData.language === "en" ? "Modify" : "Add"}{" "}
              Email: <TertiaryTextColor>{editorData?.label}</TertiaryTextColor>
            </XLTitle>
            <CustomH6>
              Language:{" "}
              <TertiaryTextColor>
                {getLanguageLabel(editorData.language)}
              </TertiaryTextColor>
            </CustomH6>
            {api_metadata?.default_from_email && (
              <>
                <SoftHeader>From</SoftHeader>
                <CustomH6 style={{ marginTop: "3px", marginBottom: "20px" }}>
                  {api_metadata?.default_from_email}
                </CustomH6>
              </>
            )}
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <TextField
                name="subject"
                label="Subject"
                theref={register()}
                formState={formState}
                errors={errors}
                type="text"
              />
              <RichEditorContainer>
                <RichEditor
                  value={formData.custom_header}
                  name={"header"}
                  label={t("Header")}
                  useFormMethods={{ register, formState, setValue, errors }}
                  disableIndent={true}
                  disableList={true}
                />
              </RichEditorContainer>
              <RichEditorContainer>
                <RichEditor
                  value={formData.email_body || ""}
                  name={"content"}
                  label={t("Body Content")}
                  useFormMethods={{ register, formState, setValue, errors }}
                  disableIndent={true}
                  disableList={true}
                />
              </RichEditorContainer>
              <SoftHeader2>Available Variables</SoftHeader2>
              <DataList rows={variablesList} />
              <PrimaryButtonFitContainer loading={isUpdatingTemplate}>
                Save your changes
              </PrimaryButtonFitContainer>
            </Form>
          </NotificationsForm>
          {renderedTemplate && (
            <NotificationTemplatePreviewer data={previewData} />
          )}
          {defaultTemplateError && (
            <ErrorPlaceholder message="Something went wrong. Please try again!" />
          )}
        </>
      )}

      {formDataError && (
        <ErrorPlaceholder message="Something went wrong. Please try again!" />
      )}

      {!formData && !formDataError && (
        <LoadingWrapper>
          <LoadingIcon />
        </LoadingWrapper>
      )}
    </NotificationsEditor>
  );
};

export const EditSenderName = ({
  sender_name,
  closeEditSenderName,
}: {
  sender_name?: string;
  closeEditSenderName: () => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);

  const { storeState, storeDispatch } = useContext(Store);
  const { storefront_id } = storeState;
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, formState, errors } = useFormWrapper({
    defaultValues: {
      sender_name,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    try {
      const patchBody: Pick<StorefrontMetaData, "sender_name"> = {
        sender_name: formValues.sender_name,
      };

      const { data: returnedMetadata } = await Axios.patch<StorefrontMetaData>(
        endpoints.v1_storefronts_id_metadata(storefront_id),
        patchBody
      );
      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: returnedMetadata,
      });

      notifySuccess(t("Changes saved"));
      setSubmitting(false);
      closeEditSenderName();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error saving the changes"),
        { error }
      );
      setSubmitting(false);
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormItems>
        <TextField
          label={t("Sender Name")}
          name="sender_name"
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          type="text"
        />
      </FormItems>
      <SaveButtonPrimaryMedium loading={submitting}>
        {t("Save your changes")}
      </SaveButtonPrimaryMedium>
    </Form>
  );
};
