import { useDebounce } from "../../../util/hooks";
import pick from "lodash/pick";
import React, { useContext, useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Auth } from "../../../components/Auth";
import {
  CustomTermContainer,
  WarningMessage,
} from "../../../components/Form/Form";
import { InfoBlockSmall } from "../../../components/InfoBlocks/InfoBlocks";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { endpoints } from "../../../endpoints";
import type {
  OptionType,
  UUID,
  Tenant,
  ITenantCustomerSettings,
  DeliveryTermPaginatedOutput,
  PaymentTermPaginatedOutput,
} from "../../../types/types";
import {
  useStoreState,
  makeDeliveryTermsGetEndpoint,
  makePaymentTermsGetEndpoint,
  TEMPORARY_HIGH_LIMIT,
  convertUserToOptionTenantID,
  convertDeliveryTermToOption,
  convertPaymentTermToOption,
  addressToOption,
  makeUrlWithParams,
  markDefaultTerm,
} from "../../../util/util";
import { newShippingAddress } from "../../../components/quoteCart/cartUtils";

export type CustomerDetailsFormOutput = {
  buyer_id: OptionType<UUID>;
  order_place_via: OptionType<string>;
  shipping_address_id: OptionType<UUID>;
  delivery_term: OptionType<UUID>;
  payment_term: OptionType<UUID>;
};

type CustomerDetailsFormProps = {
  isDisabled: boolean;
  setSelectedBuyer: React.Dispatch<React.SetStateAction<Tenant | null>>;
  setCustomerSettings: React.Dispatch<
    React.SetStateAction<ITenantCustomerSettings | null>
  >;
  orderType?: "quote" | "order";
};

export function CustomerDetailsForm({
  isDisabled,
  setSelectedBuyer,
  setCustomerSettings,
  orderType = "order",
}: CustomerDetailsFormProps) {
  const { control, errors, formState, watch, setValue } =
    useFormContext<CustomerDetailsFormOutput>();
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { user } = useContext(Auth);
  const { notifyError } = useContext(Notifications);
  const [selectedBuyerID, setSelectedBuyerID] = useState<string | null>(null);
  const [buyerSearchQuery, setBuyerSearchQuery] =
    useState<string | undefined>();
  const [debouncedBuyerSearchQuery] = useDebounce(buyerSearchQuery, 1000);
  // const [buyerSettingsLocal, setBuyerSettingsLocal]
  const [defaultTerms, setDefaultTerms] =
    useState<Partial<ITenantCustomerSettings> | null>(null);

  const [isDefaultPaymentTerm, setIsDefaultPaymentTerm] = useState(true);
  const [isDefaultDeliveryTerm, setIsDefaultDeliveryTerm] = useState(true);

  /**
   * First we have to select the buyer name, from which we get the tenant ID. Then we
   * fetch the addreses based on that.
   */

  //  TODO: create useShippingTerms and usePaymentTerms hooks.

  const { data: fetchedShippingTerms } = useSWR<DeliveryTermPaginatedOutput>(
    //   TODO: do we use storefront_id of buyer or seller here?
    makeDeliveryTermsGetEndpoint(storefront_id),
    {
      onError: (error) => {
        notifyError(t("There was an error fetching the shipping terms."), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { data: fetchedPaymentTerms } = useSWR<PaymentTermPaginatedOutput>(
    makePaymentTermsGetEndpoint(storefront_id),
    {
      onError: (error) => {
        notifyError(t("There was an error fetching the payment terms."), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { data: fetchedBuyers } = useSWR(
    // TODO remove limit and make search only.
    makeUrlWithParams(endpoints.v1_storefronts_id_buyers(storefront_id), {
      limit: TEMPORARY_HIGH_LIMIT,
      q: debouncedBuyerSearchQuery,
    }),
    {
      onError: (error) => {
        notifyError(t("There was an error fetching the buyers"), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const buyersData = fetchedBuyers?.data;
  const buyersOptions = buyersData
    ? buyersData.map(convertUserToOptionTenantID)
    : [];

  const { buyer_id: buyerOption } = watch(["buyer_id"]);

  useEffect(() => {
    if (buyerOption && selectedBuyerID !== buyerOption.value) {
      setSelectedBuyerID(buyerOption.value);
      // Clear address because addresses are associated with specific buyers
      setValue("shipping_address_id", null);
    }
  }, [buyerOption, selectedBuyerID, setValue]);

  const { data: fetchedCustomer, error: customerError } = useSWR<Tenant>(
    selectedBuyerID
      ? endpoints.v1_storefronts_id_tenants_id(storefront_id, selectedBuyerID)
      : null,
    {
      onError: (error) => {
        notifyError(t("There was an error fetching the customer information"), {
          error,
        });
      },
      onSuccess: (buyer) => {
        setSelectedBuyer(buyer);
      },
      // TODO replace with useNotifyOnce hook?
      shouldRetryOnError: false,
    }
  );

  useSWR<ITenantCustomerSettings>(
    selectedBuyerID && user?.seller_id
      ? endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
          storefront_id,
          user.tenant_id,
          selectedBuyerID
        )
      : null,
    {
      onError: (error) => {
        notifyError("There was an error fetching buyer storefront settings", {
          error,
        });
      },
      onSuccess: (data) => {
        // we need this data on two levels. This is where something like zustand
        // would be nice.
        const obj = pick(data, [
          "default_delivery_term",
          "default_payment_term",
          "default_payment_mode",
        ]);
        setValue(
          "delivery_term",
          data.default_delivery_term
            ? convertDeliveryTermToOption(data.default_delivery_term)
            : null
        );
        setValue(
          "payment_term",
          data.default_payment_term
            ? convertPaymentTermToOption(data.default_payment_term)
            : null
        );
        setDefaultTerms(obj);
        setCustomerSettings(data);
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const watchedTerms = watch(["delivery_term", "payment_term"]);

  useEffect(() => {
    if (defaultTerms) {
      if (
        defaultTerms.default_delivery_term?.id ===
        watchedTerms?.delivery_term?.value
      ) {
        setIsDefaultDeliveryTerm(true);
      } else if (
        defaultTerms.default_delivery_term?.id !==
          watchedTerms?.delivery_term?.value &&
        defaultTerms.default_delivery_term !== null
      ) {
        setIsDefaultDeliveryTerm(false);
      }

      if (
        defaultTerms.default_payment_term?.id ===
        watchedTerms?.payment_term?.value
      ) {
        setIsDefaultPaymentTerm(true);
      } else if (
        defaultTerms.default_payment_term?.id !==
          watchedTerms.payment_term?.value &&
        defaultTerms.default_payment_term !== null
      ) {
        setIsDefaultPaymentTerm(false);
      }
    }
  }, [defaultTerms, watchedTerms]);

  const customer = fetchedCustomer;
  const customerName = customer?.name;
  const isLoadingCustomer = !fetchedCustomer && !customerError;

  const allShippingAddresses =
    customer?.addresses.filter((a) => a.type === "Warehouse") ?? [];

  const addressOptions = allShippingAddresses
    ? [...allShippingAddresses.map(addressToOption), newShippingAddress(t)]
    : [];

  // const prefilledDestinationOption = { value: "prefilled", label: "prefilled" };

  const OrderPlacedViaOptions = [
    { value: "Email", label: t("Email") },
    { value: "Fax", label: t("Fax") },
    { value: "Phone", label: t("Phone") },
    { value: "Text", label: t("Text") },
  ];

  const shippingTermsOptions =
    fetchedShippingTerms?.data.map(convertDeliveryTermToOption) ?? [];
  const paymentTermsOptions =
    fetchedPaymentTerms?.data.map(convertPaymentTermToOption) ?? [];

  const handleDeliveryTermChange = (value: any) => {
    if (value.value !== defaultTerms?.default_delivery_term?.id) {
    }
  };

  const handleSearchBuyerInput = (value: string) => {
    value === "" ? setBuyerSearchQuery(undefined) : setBuyerSearchQuery(value);
  };

  // First get buyer tenant id, to fetch shipping addresses.
  return (
    <>
      {/* TODO: use paginated select search bar. */}
      <Controller
        as={SelectBoxV2}
        isSearchBar
        control={control}
        name="buyer_id"
        placeholder={t("Buyer Name")}
        options={buyersOptions}
        onInputChange={handleSearchBuyerInput}
        defaultValue={null}
        isDisabled={isDisabled}
        rules={{ required: true }}
        errors={errors}
        formState={formState}
      />
      <InfoBlockSmall
        header={t("Company")}
        content={
          customerName
            ? customerName
            : !selectedBuyerID
            ? "--"
            : isLoadingCustomer
            ? // Avoid flash of "--" when switching buyers in input above.
              ""
            : // Shouldn't get here.
              "--"
        }
      />
      <Controller
        as={SelectBoxV2}
        control={control}
        name="order_place_via"
        placeholder={
          orderType === "quote"
            ? t("Quote Requested via")
            : t("Order placed via")
        }
        options={OrderPlacedViaOptions}
        defaultValue={null}
        isDisabled={isDisabled}
        rules={{ required: orderType === "order" }}
        errors={errors}
        formState={formState}
      />
      <Controller
        as={SelectBoxV2}
        control={control}
        name="shipping_address_id"
        placeholder={t("Shipping Address")}
        options={addressOptions}
        defaultValue={null}
        isDisabled={isDisabled}
        rules={{ required: true }}
        errors={errors}
        formState={formState}
      />
      {/* TODO turn these into components on their own  */}
      <CustomTermContainer
        highlight={!isDefaultDeliveryTerm}
        has_error={!!errors.delivery_term}
      >
        <Controller
          as={SelectBoxV2}
          control={control}
          name="delivery_term"
          placeholder={t("Shipping Terms")}
          onChange={handleDeliveryTermChange}
          options={
            defaultTerms?.default_delivery_term
              ? markDefaultTerm(
                  shippingTermsOptions,
                  convertDeliveryTermToOption(
                    defaultTerms.default_delivery_term
                  )
                )
              : shippingTermsOptions
          }
          isDisabled={isDisabled}
          rules={{ required: true }}
          errors={errors}
          formState={formState}
          setDefaultTerm={!!defaultTerms?.default_delivery_term}
        />
        {!isDefaultDeliveryTerm && (
          <WarningMessage>
            {t("Requested shipping terms are not the default terms")}
          </WarningMessage>
        )}
      </CustomTermContainer>

      <CustomTermContainer
        highlight={!isDefaultPaymentTerm}
        has_error={!!errors.payment_term}
      >
        <Controller
          as={SelectBoxV2}
          control={control}
          name="payment_term"
          placeholder={t("Payment Terms")}
          options={
            defaultTerms?.default_payment_term
              ? markDefaultTerm(
                  paymentTermsOptions,
                  convertPaymentTermToOption(defaultTerms.default_payment_term)
                )
              : paymentTermsOptions
          }
          isDisabled={isDisabled}
          rules={{ required: true }}
          errors={errors}
          formState={formState}
          setDefaultTerm={!!defaultTerms?.default_payment_term}
        />
        {!isDefaultPaymentTerm && (
          <WarningMessage>
            {t("Requested payment terms are not the default terms")}
          </WarningMessage>
        )}
      </CustomTermContainer>
    </>
  );
}
