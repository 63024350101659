import { zodResolver } from "@hookform/resolvers/zod";
import type { AxiosError } from "axios";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { z } from "zod";
import {
  DeleteButton,
  SecondaryButtonWithPlusIcon,
  PrimaryButtonFitContainer,
} from "../../../../../components/Buttons/Buttons";
import { ConfirmDialog } from "../../../../../components/ConfirmDialog/ConfirmDialog";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { SectionTitle } from "../../../../../components/Form/Form";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { Form } from "../../../../../layout/FormLayout";
import { SelectWithDeleteButton } from "../../../../../layout/shared";
import type { OptionType, UUID } from "../../../../../types/types";
import type {
  TenantAttributeGroupSummary,
  SingleAttributeGroupSummary,
} from "../../../../../types/types.PIM";
import { findNonEmptyDuplicateIndexes } from "../../../../../util/form.utils";

import {
  useStoreState,
  useFormWrapper,
  TEMPORARY_HIGH_LIMIT,
} from "../../../../../util/util";
import {
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../../util/zod.util";
import {
  MarginBottomHeaderLeft,
  MarginBottomH6,
} from "../SellerAdminPIMAttributes/CreateAttribute";

const AddGroupToTemplateSchema = z.object({
  first_group: zodSelectBoxType,
  groups: z
    .object({
      group: zodSelectBoxType,
    })
    .array()
    .optional(),
});

const AddGroupToTemplateSchemaFn = (t: (s: string) => string) =>
  z
    .object({
      first_group: zodSelectBoxDefault(t),
      groups: z
        .object({
          group: zodSelectBoxDefault(t),
        })
        .array()
        .optional(),
    })
    .superRefine(({ first_group, groups }, ctx) => {
      const duplicateIdxs = groups
        ? findNonEmptyDuplicateIndexes([
            first_group.value,
            ...groups.map(({ group: { value } }) => value),
          ])
        : [];
      if (duplicateIdxs.length > 0) {
        duplicateIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Duplicate groups not allowed"),
            path: [`groups[${idx - 1}].group`],
          });
        });
      }
    });

/**
 * At least one attribute is required, and we can't delete the first one. For
 * that reason the first attribute is not part of the `group` array.
 */
type formOutput = z.infer<typeof AddGroupToTemplateSchema>;

/**
 * @param attribute AttributeSchema
 * @returns OptionType<"name (type)">
 */
export const attributeToOption = (
  group: SingleAttributeGroupSummary
): OptionType<UUID> => ({
  value: group.id,
  label: group.name,
});

export function AddGroupToTemplateForm({
  onSuccess,
  templateID,
  isTemplateInUse = false,
}: {
  onSuccess: () => void;
  templateID: UUID;
  isTemplateInUse?: boolean;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [formValue, setFormValue] = useState<formOutput>();
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(AddGroupToTemplateSchemaFn(t)),
    defaultValues: {
      first_group: { label: undefined, value: undefined },
    },
  });
  const { handleSubmit, control, formState, errors } = methodsOfUseForm;
  const { notifyError, notifySuccess } = useContext(Notifications);

  const { fields, remove, append } = useFieldArray({
    control,
    name: "groups",
  });

  const createFields = (groupOptions: OptionType<string>[]): JSX.Element[] => {
    return fields.map((field, index: number) => (
      <SelectWithDeleteButton key={field.id}>
        <Controller
          key={field.id}
          as={SelectBoxV2}
          control={control}
          isSearchBar
          name={`groups[${index}].group`}
          defaultValue={{ label: "", value: "" }}
          placeholder={t("Select Group")}
          options={groupOptions}
          errors={{
            [`groups[${index}].group`]:
              errors?.groups?.[index]?.group?.value ??
              errors?.groups?.[index]?.group ??
              undefined,
          }}
          formState={formState}
        />
        <DeleteButton
          testid={`delete-button-${index}`}
          onClick={() => remove(index)}
          type="button"
          height={20}
          width={20}
        />
      </SelectWithDeleteButton>
    ));
  };

  const { data: groups, error: groupsError } =
    useSWR<TenantAttributeGroupSummary>(
      `/v2/tenants/${tenant_id}/pim/groups?limit=${TEMPORARY_HIGH_LIMIT}`
    );

  const processForm = async (values: formOutput) => {
    let dataToPatch = {
      groups: [
        { group_id: values.first_group.value },
        ...(values.groups && values?.groups?.length > 0
          ? values?.groups?.map((group) => {
              return { group_id: group.group.value };
            })
          : []),
      ],
    };

    try {
      await axios.patch(
        `/v2/tenants/${tenant_id}/pim/templates/${templateID}`,
        dataToPatch
      );
      notifySuccess(t("Group added successfully"));
      onSuccess();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("There was an error adding the group"),
        {
          error,
        }
      );
    }
  };

  const onSubmit = async (values: formOutput) => {
    if (isTemplateInUse) {
      setShowDialog(true);
      setFormValue(values);
    } else {
      await processForm(values);
    }
  };

  const isLoadingGroups = !groups && !groupsError;

  const groupOptions = groups?.data.map(attributeToOption);

  if (isLoadingGroups) {
    return <DelayedSpinner />;
  }

  if (groupsError) {
    return (
      <ErrorPlaceholder message={t("There was an error loading the groups")} />
    );
  }

  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Add Attribute Group")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        {groupOptions && (
          <>
            <MarginBottomH6>
              {t("Search from existing attribute groups")}
            </MarginBottomH6>
            <div style={{ width: "420px" }}>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="first_group"
                placeholder={t("Select Group")}
                isSearchBar
                rules={{ required: true }}
                options={groupOptions}
                errors={errors}
                formState={formState}
              />
            </div>

            {createFields(groupOptions)}
            <SecondaryButtonWithPlusIcon
              type="button"
              style={{ marginBottom: "40px" }}
              onClick={() => append({ name: "" })}
            >
              {t("Add")}
            </SecondaryButtonWithPlusIcon>
          </>
        )}
        <PrimaryButtonFitContainer type="submit">
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
      <ConfirmDialog
        show={showDialog}
        closeDialog={() => {
          setFormValue(undefined);
          setShowDialog(false);
        }}
        confirmMessage={t(
          "Any changes will be pushed down to all products using this template. Do you want to continue?"
        )}
        handleConfirm={async () => {
          await processForm(formValue!);
          setShowDialog(false);
        }}
      />
    </>
  );
}
