import { screenSize } from "../../../theme";
import styled from "styled-components/macro";

export const WideDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: fit-content;
  margin-bottom: 20px;
  & > div {
    width: 30%;
    &:nth-child(2) {
      margin: 0 5%;
    }
  }
  @media ${screenSize.small} {
    flex-direction: column;
    & > div {
      width: 100%;
      margin-bottom: 50px;
      &:nth-child(2) {
        margin: 0 0 50px;
      }
    }
  }
`;

export const QuoteTermsContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > div:first-child {
    flex: 1;
  }
  button {
    padding-left: 0;
  }
`;

export const KeyValueContainer = styled.div`
  width: 100%;
  @media ${screenSize.medium} {
    margin-left: 0;
    margin-top: 20px;
  }
`;

export const TermsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    max-width: 400px;
    margin-bottom: 8px;
    /* Set min-height to prevent layout shift when select menu opens.
       Without this minimum the height was 50px (and margin-bottom was 15px). */
    min-height: 56px;
  }
  &:first-child {
    margin-right: 15px;
  }
`;

export const DetailPageContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  @media (max-width: 1249px) {
    flex-direction: column;
  }
`;

export const QuoteOrderContent = styled.div`
  width: 70%;
  margin-top: 20px;
  margin-bottom: -25px;
  padding-bottom: 15px;
  padding-right: 30px;
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  @media (max-width: 1249px) {
    border-right: 0;
    padding-right: 0;
    width: 100%;
  }
`;

export const TimelineWrapper = styled.div`
  width: 30%;
  height: calc(100vh - 260px);
  max-width: 420px;
  margin: 20px 0px 10px 0;
  position: -webkit-sticky;
  position: sticky;
  top: -15px;
  @media (max-width: 1249px) {
    max-width: 100%;
  }
`;
