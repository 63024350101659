import styled from "styled-components/macro";

export const CheckBox = styled.input.attrs(() => ({
  type: "checkbox",
}))`
  --active: ${({ theme }) => theme.brandColor};
  --active-inner: ${({ theme }) => theme.primaryBG};
  --focus: 2px black;
  --border: #bbc1e1;
  --border-hover: black;
  --background: ${({ theme }) => theme.primaryBG};
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  width: 21px !important;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

  &:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  &:checked + label {
    color: black;
  }

  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + label {
      cursor: not-allowed;
      color: ${({ theme }) => theme.secondaryTextColor};
    }
  }

  &:hover,
  &:focus {
    &:not(:disabled) {
      --bc: var(--border-hover);
      & + label {
        color: ${({ theme }) => theme.primaryTextColor};
      }
    }
  }

  &:not(.switch) {
    width: 34px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }

  &:not(.switch) {
    border-radius: 2px;
    &:after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 6px;
      top: 2px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }

  &.switch {
    width: 38px;
    border-radius: 2px;

    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }

    &:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }

    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: 0.6;
        }
      }
    }
  }
`;

// This is similar to the above, but has no styles that change its siblings.
export const CheckBoxNoLabel = styled.input.attrs((props) => ({
  type: "checkbox",
}))<{ error?: boolean }>`
  --active: ${({ theme }) => theme.selectedBorder};
  --active-inner: ${({ theme }) => theme.primaryBorder};
  --focus: 2px black;
  --border: ${({ theme, error }) =>
    error ? theme.errorColor : theme.primaryBorder};
  --border-hover: black;
  --background: ${({ theme }) => theme.primaryBG};
  --disabled-inner: ${({ theme }) => theme.primaryBG};

  -webkit-appearance: none;
  -moz-appearance: none;
  height: 21px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  margin-right: 14px;

  &:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
  }

  &:hover {
    &:not(:disabled) {
      --bc: var(--border-hover);
    }
  }

  &:not(.switch) {
    width: 21px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }

  &:not(.switch) {
    border-radius: 2px;
    &:after {
      width: 5px;
      height: 9px;
      border: 2px solid ${({ disabled, theme }) =>
        disabled ? theme.primaryBorder : theme.primaryBG};
      border-top: 0;
      border-left: 0;
      left: 6px;
      top: 2px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }

  &.switch {
    width: 38px;
    border-radius: 2px;

    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }

    &:checked {
      --ab: var(--active-inner)
      --x: 17px;
    }

    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: 0.6;
        }
      }
    }
  }
`;
