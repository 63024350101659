import { Row } from "../Layout/Layout";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import { TextField } from "../TextFields/TextFields";
import type { MethodsOfUseForm, OptionType } from "../../types/types";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useQuantityInputFieldClearer } from "./useQuantityInputFieldClearer";

const InputRow = styled(Row)`
  /* Set a min-height so drop down menus don't cause layout shift. */
  min-height: 58px;
  /* Set align-items so the drop down menus don't move up and down. */
  align-items: start;
  & > * {
    flex-grow: 1;
    margin-left: 8px;
  }
  & > *:first-child {
    margin-left: 0px;
  }
`;

const CustomSkuInputRow = styled(InputRow)`
  & > *:first-child {
    flex-grow: 2;
    min-width: 160px;
  }
  & > *:last-child {
    flex-grow: 2;
    min-width: 110px;
  }
`;

export const QuantityFormPartNoPackaging = ({
  methodsOfUseForm,
  unitOfMeasureOptions,
  uomValue,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  unitOfMeasureOptions: OptionType<string>[];
  uomValue?: OptionType<string>;
}) => {
  const { t } = useTranslation();

  const { register, errors, formState, control, setValue, watch } =
    methodsOfUseForm;
  const { unit_of_measure } = watch(["unit_of_measure"]);

  useEffect(() => {
    if (uomValue && !unit_of_measure) {
      setValue("unit_of_measure", uomValue);
    }
  }, [uomValue, setValue, unit_of_measure]);

  return (
    <InputRow>
      <TextField
        name={"total_quantity"}
        label={t("Total Quantity")}
        theref={register({ required: true })}
        errors={errors}
        formState={formState}
        type="number"
      />
      <Controller
        as={SelectBoxV2}
        control={control}
        name="unit_of_measure"
        placeholder={t("UoM")}
        options={unitOfMeasureOptions}
        rules={{ required: true }}
        errors={errors}
        formState={formState}
      />
    </InputRow>
  );
};

export const QuantityFormPartProductSku = ({
  methodsOfUseForm,
  unitOfMeasure,
  skuPackageVolume,
  isDisabled = false,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  unitOfMeasure?: string;
  skuPackageVolume: string;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();

  // If trying to alter the values of the form with "reset" this will clobber it.
  useQuantityInputFieldClearer(methodsOfUseForm);

  const packagingQuantity = parseFloat(skuPackageVolume);

  const { register, errors, formState, setValue, trigger } = methodsOfUseForm;

  useEffect(() => {
    // Keep the disabled UOM field in sync with the UOM from the SKU.
    setValue("disabled_uom", unitOfMeasure);
  }, [unitOfMeasure, setValue]);

  const handleNumberOfUnitsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newNumberOfUnits = parseFloat(event.target.value);
    if (packagingQuantity && newNumberOfUnits) {
      setValue("total_quantity", packagingQuantity * newNumberOfUnits);
      trigger("total_quantity");
    }
    // Re-check validation when it changes to possibly clear error message
    // when it is fixed.
    trigger("no_of_units");
  };

  const handleTotalQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newTotalQuantity = parseFloat(event.target.value);
    if (packagingQuantity && newTotalQuantity) {
      setValue("no_of_units", newTotalQuantity / packagingQuantity);
      trigger("no_of_units");
    }
  };

  return (
    <>
      {/* Don't put this text field in the row so that it is full width. */}
      <TextField
        name={"no_of_units"}
        label={t("Number of Units")}
        theref={register({ required: true })}
        errors={errors}
        formState={formState}
        disabled={isDisabled}
        onChange={handleNumberOfUnitsChange}
        type="number"
      />
      <InputRow>
        <TextField
          name={"total_quantity"}
          label={t("Total Quantity")}
          theref={register({ required: true })}
          disabled={isDisabled}
          errors={errors}
          formState={formState}
          onChange={handleTotalQuantityChange}
          type="number"
        />
        {/* TODO can this be replaced with an infoblock? */}
        <TextField
          name={"disabled_uom"}
          label={t("UoM")}
          value={unitOfMeasure || "--"}
          defaultValue={unitOfMeasure || "--"}
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          disabled={true}
          type="text"
        />
      </InputRow>
    </>
  );
};

/**
 * Form fragment to handle the user entering custom packaging (SKU) for a
 * quote request item.
 *
 * There is some tricky logic for three dependent inputs, where a user changing
 * one input can trigger recalculation of one of the other two (replacing what
 * was there). These are the inputs:
 *   1. custom packaging quantity (the quantity per unit)
 *   2. number of units
 *   3. total quantity
 *
 * The formula:
 *   total quantity = custom packaging quantity * number of units
 *
 * Case 1: All three have a value and one of them changes:
 * - 'custom packaging quantity' changed -> update number of units
 * - 'number of units' changed -> update total quantity
 * - 'total quantity' changed -> update number of units
 *
 * Case 2: One does not have a value and the other two do have values:
 * - one with a value changes -> update the one that doesn't have a value
 *
 * Case 3: Only one of them has a value:
 * - the one with a value changes -> do nothing with the other two
 *
 * If the change in input results in an empty value ("", null, etc.), then do
 * nothing. The user will have to enter a value for all of them to get past
 * form validation.
 */
export const QuantityFormPartCustomSku = ({
  methodsOfUseForm,
  unitOfMeasureOptions,
  packagingTypeOptions,
  uomValue,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  unitOfMeasureOptions: OptionType<string>[];
  packagingTypeOptions: OptionType<string>[];
  uomValue?: OptionType<string>;
}) => {
  const { t } = useTranslation();

  const { register, errors, formState, control, watch, setValue, trigger } =
    methodsOfUseForm;

  const {
    unit_of_measure,
    custom_packaging_quantity,
    no_of_units,
    total_quantity,
  } = watch([
    "unit_of_measure",
    "custom_packaging_quantity",
    "no_of_units",
    "total_quantity",
  ]);

  const uomLabel = unit_of_measure?.label;
  useEffect(() => setValue("disabled_uom", uomLabel), [uomLabel, setValue]);
  useEffect(() => {
    if (uomValue && !unit_of_measure) {
      setValue("unit_of_measure", uomValue);
    }
  }, [uomValue, setValue, unit_of_measure]);

  const numberOfUnitsNum = parseFloat(no_of_units);
  const customPackagingQuantityNum = parseFloat(custom_packaging_quantity);
  const totalQuantityNum = parseFloat(total_quantity);

  const handleCustomPackagingQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCustomPackagingQuantity = parseFloat(event.target.value);

    if (newCustomPackagingQuantity) {
      if (!totalQuantityNum && numberOfUnitsNum) {
        setValue(
          "total_quantity",
          newCustomPackagingQuantity * numberOfUnitsNum
        );
        trigger("total_quantity");
      } else if (totalQuantityNum) {
        setValue("no_of_units", totalQuantityNum / newCustomPackagingQuantity);
        trigger("no_of_units");
      }
    }
  };

  const handleNumberOfUnitsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newNumberOfUnits = parseFloat(event.target.value);

    if (newNumberOfUnits) {
      if (!customPackagingQuantityNum && totalQuantityNum) {
        setValue(
          "custom_packaging_quantity",
          totalQuantityNum / newNumberOfUnits
        );
        trigger("custom_packaging_quantity");
      } else if (customPackagingQuantityNum) {
        setValue(
          "total_quantity",
          customPackagingQuantityNum * newNumberOfUnits
        );
        trigger("total_quantity");
      }
    }
    // Re-check validation when it changes to possibly clear error message
    // when it is fixed.
    trigger("no_of_units");
  };

  const handleTotalQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newTotalQuantity = parseFloat(event.target.value);

    if (newTotalQuantity) {
      if (!customPackagingQuantityNum && numberOfUnitsNum) {
        setValue(
          "custom_packaging_quantity",
          newTotalQuantity / numberOfUnitsNum
        );
        trigger("custom_packaging_quantity");
      } else if (customPackagingQuantityNum) {
        setValue("no_of_units", newTotalQuantity / customPackagingQuantityNum);
        trigger("no_of_units");
      }
    }
  };

  return (
    <>
      <CustomSkuInputRow>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="custom_packaging_type"
          placeholder={t("Packaging Type")}
          options={packagingTypeOptions}
          rules={{ required: true }}
          errors={errors}
          formState={formState}
        />
        <TextField
          name={"custom_packaging_quantity"}
          label={t("Volume")}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          onChange={handleCustomPackagingQuantityChange}
          type="number"
        />{" "}
        <Controller
          as={SelectBoxV2}
          control={control}
          name="unit_of_measure"
          placeholder={t("UoM")}
          options={unitOfMeasureOptions}
          rules={{ required: true }}
          errors={errors}
          defaultValue={uomValue}
          formState={formState}
        />
      </CustomSkuInputRow>
      {/* Don't put this text field in the row so that it is full width. */}
      <TextField
        name={"no_of_units"}
        label={t("Number of Units")}
        theref={register({ required: true })}
        errors={errors}
        formState={formState}
        onChange={handleNumberOfUnitsChange}
        type="number"
      />
      <InputRow>
        <TextField
          name={"total_quantity"}
          label={t("Total Quantity")}
          theref={register({ required: true })}
          errors={errors}
          formState={formState}
          onChange={handleTotalQuantityChange}
          type="number"
        />
        <TextField
          name={"disabled_uom"}
          label={t("UoM")}
          value={unit_of_measure?.label || "--"}
          defaultValue={unit_of_measure?.label || "--"}
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          disabled={true}
          type="text"
        />
      </InputRow>
    </>
  );
};

export const noPackagingOption: OptionType<"no_packaging"> = {
  label: "No Preference",
  value: "no_packaging",
};

export const customPackagingOption: OptionType<"custom_packaging"> = {
  label: "Request Custom SKU",
  value: "custom_packaging",
};
