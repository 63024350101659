import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ConfirmOrCancelButtonContainer,
  DeleteButton,
  SecondaryButtonWithPlusIcon,
} from "../../../../components/Buttons/Buttons";
import {
  ContentWrapper,
  FullWidthHorizontalSeparator,
  PageTitle,
  PageWrapper,
} from "../../../../layout/portalPageLayout";
import { useRoutePath } from "../../../../util/Routing";
import { useFormWrapper, useStoreState } from "../../../../util/util";
import { Controller, useFieldArray } from "react-hook-form";
import { TextField } from "../../../../components/TextFields/TextFields";
import { MarginBottomH6 } from "../PIM/SellerAdminPIMAttributes/CreateAttribute";
import { SelectWithDeleteButton } from "../../../../layout/shared";
import { z } from "zod";
import { findNonEmptyDuplicateIndexes } from "../../../../util/form.utils";
import {
  zodRequiredString,
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../util/zod.util";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "../../../../layout/FormLayout";
import { SearchSelectInfiniteScroll } from "../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { endpoints } from "../../../../endpoints";
import type { PIMProductBase } from "../../../../types/types.PIM";
import type {
  TeamCreateResponseSchema,
  OptionType,
  TeamCreateSchema,
  User,
} from "../../../../types/types";
import { useEffect, useState } from "react";
import type { AxiosError } from "axios";
import axios from "axios";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import styled from "styled-components/macro";

const CreateTeamSchemaBase = z.object({
  team_name: z.string(),
  first_user: zodSelectBoxType,
  first_product: zodSelectBoxType,
  users: z
    .object({
      user: zodSelectBoxType,
    })
    .array()
    .optional(),
  products: z.object({ product: zodSelectBoxType }).array().optional(),
});

export const FullWidthSelectWithDeleteButton = styled(SelectWithDeleteButton)`
  .basic-single {
    width: 470px;
  }
`;

const CreateTeamSchema = (t: (s: string) => string) =>
  z
    .object({
      team_name: zodRequiredString(t),
      first_user: zodSelectBoxDefault(t),
      first_product: zodSelectBoxDefault(t),
      users: z
        .object({
          user: zodSelectBoxDefault(t),
        })
        .array()
        .optional(),
      products: z
        .object({
          product: zodSelectBoxDefault(t),
        })
        .array()
        .optional(),
    })
    .superRefine(({ first_user, first_product, products, users }, ctx) => {
      const duplicateUserIdxs = users
        ? findNonEmptyDuplicateIndexes([
            first_user.value,
            ...users.map(({ user: { value } }) => value),
          ])
        : [];

      if (duplicateUserIdxs.length > 0) {
        duplicateUserIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Cannot add the same user twice"),
            path: [`users[${idx - 1}].user`],
          });
        });
      }

      const duplicateProductIdxs = products
        ? findNonEmptyDuplicateIndexes([
            first_product.value,
            ...products.map(({ product: { value } }) => value),
          ])
        : [];

      if (duplicateProductIdxs.length > 0) {
        duplicateProductIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Cannot add the same product twice"),
            path: [`products[${idx - 1}].product`],
          });
        });
      }
    });

type FormOutput = z.infer<typeof CreateTeamSchemaBase>;

export const SellerAdminCreateNewTeam = ({
  onCancel,
}: {
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const { adminPath } = useRoutePath();
  const history = useHistory();
  const { tenant_id, storefront_id } = useStoreState();
  const { notifySuccess, notifyError } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(CreateTeamSchema(t)),
    defaultValues: {
      first_user: { label: "", value: "" },
      first_product: { label: "", value: "" },
    },
    reValidateMode: "onChange",
  });
  const {
    handleSubmit,
    control,
    formState,
    errors,
    register,
    setValue,
    trigger,
  } = methodsOfUseForm;

  const {
    fields: userFields,
    remove: removeUserSelect,
    append: appendUserSelect,
  } = useFieldArray({
    control,
    name: "users",
  });

  const {
    fields: productFields,
    remove: removeProductSelect,
    append: appendProductSelect,
  } = useFieldArray({
    control,
    name: "products",
  });

  const createUserFields = (): JSX.Element[] => {
    return userFields.map((field, index: number) => (
      <FullWidthSelectWithDeleteButton key={field.id}>
        {/* This is intentionally wrapped in Controller,
         not getting the values in the onSubmit otherwise.
         Seems to be some interaction between setValue and the fieldArray,
          some github issues are adjacent to this but never the exact problem */}
        <Controller
          as={SearchSelectInfiniteScroll}
          control={control}
          name={`users[${index}].user`}
          key={field.id}
          defaultValue={{ label: "", value: "" }}
          errors={{
            [`users[${index}].user`]:
              errors?.users?.[index]?.user?.value ??
              errors?.users?.[index]?.user ??
              undefined,
          }}
          formState={formState}
          placeholder={t("Select User")}
          baseUrl={endpoints.v1_storefronts_id_tenants_id_users(
            storefront_id,
            tenant_id
          )}
          params={(() => {
            const params = new URLSearchParams();
            params.append("order_by", "asc");
            return params;
          })()}
          getOptions={(response: User[]) =>
            response.map((user) => ({
              value: user.id,
              label: `${user.firstname} ${user.lastname}`,
            }))
          }
        />
        <DeleteButton
          testid={`delete-button-${index}`}
          onClick={() => removeUserSelect(index)}
          type="button"
          height={20}
          width={20}
        />
      </FullWidthSelectWithDeleteButton>
    ));
  };

  const createProductFields = (): JSX.Element[] => {
    return productFields.map((field, index: number) => (
      <FullWidthSelectWithDeleteButton key={field.id}>
        <Controller
          control={control}
          as={SearchSelectInfiniteScroll}
          key={field.id}
          name={`products[${index}].product`}
          defaultValue={{ label: "", value: "" }}
          errors={{
            [`products[${index}].product`]:
              errors?.products?.[index]?.product?.value ??
              errors?.products?.[index]?.product ??
              undefined,
          }}
          formState={formState}
          placeholder={t("Select Product")}
          baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
          params={(() => {
            const params = new URLSearchParams();
            params.append("order_by", "asc");
            params.append("show_inactive", "true");
            return params;
          })()}
          getOptions={(response: PIMProductBase[]) =>
            response.map((product) => ({
              value: product.id,
              label: product.name,
            }))
          }
        />
        <DeleteButton
          testid={`delete-button-${index}`}
          onClick={() => removeProductSelect(index)}
          type="button"
          height={20}
          width={20}
        />
      </FullWidthSelectWithDeleteButton>
    ));
  };

  useEffect(() => {
    register("first_product");
    register("first_user");
  }, [register]);

  const onSubmit = async (values: FormOutput) => {
    setIsSubmitting(true);
    const additionalUsers =
      values.users && values.users.length > 0
        ? values.users.map(({ user }) => user.value)
        : [];
    const additionalProducts =
      values.products && values.products.length > 0
        ? values.products.map(({ product }) => product.value)
        : [];

    const createTeamSchemaValue: TeamCreateSchema = {
      team_name: values.team_name,
      user_ids: [values.first_user.value, ...(additionalUsers as string[])],
      product_ids: [
        values.first_product.value,
        ...(additionalProducts as string[]),
      ],
    };

    try {
      const {
        data: { team_id },
      } = await axios.post<TeamCreateResponseSchema>(
        `/v2/tenants/${tenant_id}/pim/teams`,
        createTeamSchemaValue
      );
      notifySuccess(t("Team created successfully"));
      setIsSubmitting(false);
      history.push(`${adminPath}/organization/teams/${team_id}`);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("There was an error creating the team"),
        {
          error,
        }
      );
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>{t("Create Team")}</PageTitle>

      <ContentWrapper>
        <PageWrapper>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FullWidthHorizontalSeparator style={{ marginBottom: "16px" }} />
            <MarginBottomH6 style={{ paddingTop: "16px" }}>
              {t("Team Details")}
            </MarginBottomH6>
            <TextField
              name="team_name"
              label={t("Team Name")}
              theref={register({ required: true })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <MarginBottomH6>{t("Users")}</MarginBottomH6>
            <SearchSelectInfiniteScroll
              name={`first_user`}
              errors={{ first_user: errors?.first_user?.value }}
              formState={formState}
              placeholder={t("Select User")}
              baseUrl={endpoints.v1_storefronts_id_tenants_id_users(
                storefront_id,
                tenant_id
              )}
              params={(() => {
                const params = new URLSearchParams();
                params.append("order_by", "asc");
                return params;
              })()}
              getOptions={(response: User[]) =>
                response.map((user) => ({
                  value: user.id,
                  label: `${user.firstname} ${user.lastname}`,
                }))
              }
              onChange={(data: OptionType<string>) => {
                setValue("first_user", data);
                trigger("first_user");
              }}
            />
            {createUserFields()}
            <SecondaryButtonWithPlusIcon
              type="button"
              style={{ fontSize: "15px" }}
              onClick={() => appendUserSelect({ name: "" })}
            >
              {t("Add user")}
            </SecondaryButtonWithPlusIcon>
            <MarginBottomH6>{t("Products")}</MarginBottomH6>
            <SearchSelectInfiniteScroll
              name={`first_product`}
              errors={{ first_product: errors?.first_product?.value }}
              formState={formState}
              placeholder={t("Search from your products")}
              baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
              params={(() => {
                const params = new URLSearchParams();
                params.append("order_by", "asc");
                params.append("show_inactive", "true");
                return params;
              })()}
              getOptions={(response: PIMProductBase[]) =>
                response.map((product) => ({
                  value: product.id,
                  label: product.name,
                }))
              }
              onChange={(data: OptionType<string>) => {
                setValue("first_product", data);
                trigger("first_product");
              }}
              testid={"existing-product-search"}
            />
            {createProductFields()}
            <SecondaryButtonWithPlusIcon
              type="button"
              style={{ marginBottom: "40px", fontSize: "15px" }}
              onClick={() => appendProductSelect({ value: "", label: "" })}
            >
              {t("Add Product")}
            </SecondaryButtonWithPlusIcon>
            <ConfirmOrCancelButtonContainer
              isConfirmLoading={isSubmitting}
              onCancel={onCancel}
              onConfirm={() => {}}
              confirmText={t("Submit")}
            />
          </Form>
        </PageWrapper>
      </ContentWrapper>
    </>
  );
};
