import React, { useState, useContext } from "react";
import { Form } from "../../../../../../layout/FormLayout";
import { useFormWrapper } from "../../../../../../util/util";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import type { StorefrontMetaData } from "../../../../../../types/types";
import { endpoints } from "../../../../../../endpoints";
import { Store } from "../../../../../../Store";
import { TwoColumnLayout } from "../../../../../../layout/portalPageLayout";
import { SoftHeader2 } from "../../../../../../components/Typography/Typography";
import {
  SaveButtonPrimaryMedium,
  ColorInputContainer,
  ColorInputColumn,
} from "../../shared";
import { makeNewTheme } from "../../../../../../util/themeUtils";
import { useTranslation } from "react-i18next";

interface FormValues {
  brandColor: string;
}

export const EditColorScheme = ({
  brandColor,
  closeEditColorScheme,
  mutateStorefrontMetadata,
}: {
  brandColor?: string;
  closeEditColorScheme: () => void;
  mutateStorefrontMetadata: (
    data: StorefrontMetaData,
    shouldRevalidate: boolean
  ) => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);

  const { storeState, storeDispatch } = useContext(Store);
  const { t } = useTranslation();
  const { storefront_id, storefront_metadata } = storeState;
  const branding = storefront_metadata.theme_object.branding;

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit } = useFormWrapper({
    defaultValues: {
      brandColor,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    try {
      const patchBody: Pick<StorefrontMetaData, "theme_object"> = {
        theme_object: {
          branding: {
            brandColor: formValues.brandColor,
            // Just pass through the existing values for other theme properties.
            publicLogoUrl: branding.publicLogoUrl,
            portalLogoUrl: branding.portalLogoUrl,
            favIconUrl: branding.favIconUrl,
            customFontFamily: branding.customFontFamily,
          },
        },
      };

      const { data: returnedMetadata } = await Axios.patch<StorefrontMetaData>(
        endpoints.v1_storefronts_id_metadata(storefront_id),
        patchBody
      );

      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: returnedMetadata,
      });

      storeDispatch({
        type: "SET_THEME",
        payload: makeNewTheme(
          patchBody.theme_object.branding,
          storeState.tenant_id
        ),
      });

      notifySuccess(t("Changes saved"));
      setSubmitting(false);
      mutateStorefrontMetadata(returnedMetadata, false);
      closeEditColorScheme();
    } catch (error) {
      notifyError(t("There was an error saving the changes"), { error });
      setSubmitting(false);
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TwoColumnLayout>
        <ColorInputColumn>
          <ColorInputContainer>
            <SoftHeader2>{t("Main Brand Color")}</SoftHeader2>
            <input
              type="color"
              id="brandColor"
              name="brandColor"
              ref={register({ required: false })}
            />
          </ColorInputContainer>
        </ColorInputColumn>
      </TwoColumnLayout>
      <SaveButtonPrimaryMedium loading={submitting}>
        {t("Save your changes")}
      </SaveButtonPrimaryMedium>
    </Form>
  );
};
