import React from "react";
import { LoadingIcon } from "../Icons/Icons";
import styled from "styled-components/macro";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 100px 10px;
  text-align: center;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

interface ILoader {
  isLoading: boolean;
  omitContainer?: boolean;
  width?: number;
}

export function Loader({ isLoading, omitContainer, width }: ILoader) {
  return (
    <>
      {isLoading && !omitContainer && (
        <Container>
          <LoadingIcon width={width} />
        </Container>
      )}
      {isLoading && omitContainer && <LoadingIcon />}
    </>
  );
}
