import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { H4, StyledP } from "../Typography/Typography";
import ReactTooltip from "react-tooltip";
import { checkTextOverflow } from "../../util/util";

const Border = styled.div`
  border-radius: 2px;
  border: solid 1px #e7e8ea;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  background: ${({ theme }) => theme.primaryBG};
`;

const Text = styled(StyledP)`
  font-family: Inter;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0f1d2b;
  margin: 10px 0;
  padding: 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  > div[class*="type-custom"] {
    max-width: 350px;
  }
`;

const Image = styled.img`
  max-height: 210px;
  object-fit: cover;
  max-width: 450px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 10px 15px;
  justify-content: flex-end;
  height: 100%;
`;

const Link = styled.a`
  color: #0090e3;
  font-size: 13px;
  text-decoration: none;
`;

interface ITagProps {
  id: number | string;
  name?: string;
  imageUrl?: string;
  description?: string;
  link?: string;
}

type IThumbnailWithText = {
  data: ITagProps;
};

export const ThumbnailWithText = ({ data }: IThumbnailWithText) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const [tooltip, setTooltip] = useState("");

  useEffect(() => {
    if (textRef.current) {
      const { heightOverflow } = checkTextOverflow(
        textRef.current as HTMLElement
      );
      setTooltip(heightOverflow ? data?.description ?? "" : "");
    }
  }, [textRef, data]);

  return (
    <>
      <Border>
        {data.imageUrl && <Image src={data.imageUrl} />}
        <InnerContainer>
          {data.name && <H4>{data.name}</H4>}
          <Text
            data-tip={tooltip}
            data-for={`tooltip-${data.id}`}
            ref={textRef}
          >
            {data.description}
            <ReactTooltip
              id={`tooltip-${data.id}`}
              place="top"
              data-html={true}
              data-class={"thumbnail-tooltip"}
              effect="solid"
              backgroundColor="#60676f"
              multiline={true}
            >
              {tooltip}
            </ReactTooltip>
          </Text>
          {data.link && <Link href={data.link}>VIEW ALL &#8811;</Link>}
        </InnerContainer>
      </Border>
    </>
  );
};
