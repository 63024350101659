import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import React, { useContext } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { PrimaryButtonMedium } from "../../../../../components/Buttons/Buttons";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { H3 } from "../../../../../components/Typography/Typography";
import { Form } from "../../../../../layout/FormLayout";
import type { OptionType, UUID } from "../../../../../types/types";
import type {
  AttributeTemplateSummarySchema,
  AttributeTemplateSchema,
} from "../../../../../types/types.PIM";
import { stringIsNotOnlyWhiteSpaceRegex } from "../../../../../util/regexes";
import {
  isAxiosError,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";

import * as z from "zod";
import { zodSelectBoxDefault } from "../../../../../util/zod.util";
import { strings } from "../../../../../util/strings";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function convertTemplateToOption(
  template: AttributeTemplateSummarySchema
): OptionType<UUID> {
  return {
    value: template.template_id,
    label: template.template_name,
  };
}

type formOutput = {
  template_name: string;
  copy_existing_template: boolean;
  template?: OptionType<UUID>;
};

export function CreateNewTemplateForm({
  templates,
  onSuccess,
}: {
  templates: AttributeTemplateSummarySchema[];
  onSuccess: (id: UUID) => void;
}) {
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const {
    register,
    handleSubmit,
    control,
    formState,
    errors,
    watch,
    setError,
  } = useFormWrapper({
    resolver: zodResolver(
      z
        .object({
          template_name: z
            .string()
            .regex(
              stringIsNotOnlyWhiteSpaceRegex,
              strings(t).thisIsARequiredField
            ),
          copy_existing_template: z.boolean(),
          template: zodSelectBoxDefault(t).optional(),
        })
        .superRefine((data, ctx) => {
          if (
            data.copy_existing_template === true &&
            data.template === undefined
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: strings(t).thisIsARequiredField,
              path: ["template"],
            });
          }
        })
    ),
  });

  const isCopyingTemplate = watch("copy_existing_template") ?? false;

  const onSubmit = async (values: formOutput) => {
    try {
      const { data: template }: { data: AttributeTemplateSchema } =
        await axios.post(`/v2/tenants/${tenant_id}/pim/templates`, {
          name: values.template_name,
          ...(values.template && !!values.copy_existing_template
            ? { template_id: values.template.value }
            : null),
        });

      notifySuccess(t("Template created successfully"));
      onSuccess(template.template_id);
    } catch (error) {
      if (isAxiosError(error)) {
        if (error?.response?.status === 409) {
          setError("template_name", {
            message: t("Template name already exists"),
          });
        }
      } else {
        notifyError(t("There was an error creating the template"));
      }
    }
  };

  const options = templates.map(convertTemplateToOption);

  return (
    <>
      <H3>{t("Create new template")}</H3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="template_name"
          label={t("Template name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <ToggleSwitch
          label={t("Copy existing template")}
          name="copy_existing_template"
          theref={register({ required: false })}
        />
        <div style={{ display: isCopyingTemplate ? "block" : "none" }}>
          <Controller
            as={SelectBoxV2}
            control={control}
            name="template"
            placeholder={t("Choose template")}
            options={options}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
            // make the select box menu sit above the modal, this is not
            // applied to the component itself because I'm not sure what might
            // break and this is a semi unique case.
            menuPosition={"fixed"}
          />
        </div>
        <ButtonContainer>
          <PrimaryButtonMedium type="submit">{t("Create")}</PrimaryButtonMedium>
        </ButtonContainer>
      </Form>
    </>
  );
}
