import styled from "styled-components/macro";
import { screenSize } from "../theme";

export const Form = styled.form.attrs({ noValidate: true })`
  > * {
    margin-bottom: 15px;
  }
`;

/**
 * for use when a form is not inside a container giving it max width.
 */
export const FormItems = styled.section`
  > * {
    margin-bottom: 15px;
  }
  max-width: 500px;
  /* @media ${screenSize.small} {
    width: 240px;
  } */
`;

export const SubmitButtonContainer = styled.div`
  margin-top: 22px;
`;

export const Flex = styled.div`
  display: flex;
  :first-child {
    flex: 2;
    margin-right: 14px;
  }
  :nth-child(1) {
    flex: 1;
  }
`;

/**
 * Used in conjunction with Flex1 to produce a 1/3rd 2/3rd layout
 */
export const Flex2 = styled.div`
  flex: 2;
  margin-right: 14px;
`;

/**
 * Used in conjunction with Flex2 to produce a 1/3rd 2/3rd layout
 */
export const Flex1 = styled.div`
  flex: 1;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  & label {
    margin-right: 25px;
  }
`;

export const FormGrid2x2 = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 14px;
  padding-right: 14px;
`;

export const FormFieldAndIcon = styled.div`
  max-width: 620px;
  display: grid;
  grid-template-columns: 500px repeat(auto-fill, minmax(18px, auto));
  gap: 16px;
`;
