import React, { useState, useContext, useEffect } from "react";
import { FormItems, Form } from "../../../../../../layout/FormLayout";
import { useFormWrapper } from "../../../../../../util/util";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import { endpoints } from "../../../../../../endpoints";
import { Store } from "../../../../../../Store";
import { SaveButtonPrimaryMedium } from "../../shared";
import { useTranslation } from "react-i18next";
import { SearchSelect } from "../../../../../../components/SearchSelect/SearchSelect";
import type {
  CreatedOption,
  Language,
  OptionType,
  StorefrontMetaData,
  SupportedLanguage,
} from "../../../../../../types/types";
import { DeletableChips } from "../../../../../../components/DeletableChips/DeletableChips";
import useSWR from "swr";
import type { ChipType } from "../../../../../../components/Chips/Chips";

interface FormValues {
  asset_languages: Language[];
}

export const EditAssetLanguages = ({
  closeEditAssetLanguagesSection,
  assetLanguageChips,
  mutateAssetLanguages,
}: {
  closeEditAssetLanguagesSection: () => void;
  assetLanguageChips: ChipType[];
  mutateAssetLanguages: () => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);

  const { storeState } = useContext(Store);
  const { storefront_id } = storeState;

  const [submitting, setSubmitting] = useState(false);

  const { register, handleSubmit, formState, errors } = useFormWrapper({
    defaultValues: {
      asset_languages: [],
    },
  });

  const { t } = useTranslation();

  const [languageChips, setLanguageChips] =
    useState<ChipType[]>(assetLanguageChips);
  const [languageOptions, setLanguageOptions] = useState<OptionType[]>([]);

  const { data: allLanguages } = useSWR<{
    languages: Language[];
  }>(endpoints.v1_all_languages(), {
    onSuccess: (languages) => {
      setLanguageOptions(
        languages.languages
          .filter(
            (lang) =>
              !languageChips.find((item: ChipType) => item.id === lang.alpha_2)
          )
          .map((item) => ({
            label: `${item.name} (${item.alpha_2.toUpperCase()})`,
            value: item.alpha_2,
          }))
      );
    },
    onError: () =>
      notifyError(t("could not load asset languages, Something went wrong.")),
  });

  const handleMultiSelectChange = (
    data: CreatedOption<SupportedLanguage> | null
  ) => {
    if (data) {
      setLanguageChips((previous) => [
        ...previous,
        { name: data.label, id: data.value },
      ]);
      setLanguageOptions(
        (previous) =>
          previous?.filter((item) => item.label !== data.label) ?? []
      );
    }
  };

  const onSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    try {
      const patchBody: { languages: Language[] } = {
        languages: languageChips
          ? languageChips.map(
              (chip) =>
                allLanguages?.languages?.find(
                  (lang) => lang.alpha_2 === chip.id
                ) as Language
            )
          : [],
      };

      await Axios.put<StorefrontMetaData>(
        `/v1/storefronts/${storefront_id}/languages`,
        patchBody
      );
      mutateAssetLanguages();
      notifySuccess(t("Changes saved"));
      setSubmitting(false);
      closeEditAssetLanguagesSection();
    } catch (error) {
      notifyError(t("There was an error saving the changes"), { error });
      setSubmitting(false);
    }
  };

  const handleChipClick = (name: string) => {
    setLanguageChips(
      (previous) => previous?.filter((item) => item.name !== name) ?? []
    );
    if (
      !languageOptions
        .map(({ value }) => `${value.name} (${value.alpha_2?.toUpperCase()})`)
        .includes(name)
    ) {
      setLanguageOptions([
        {
          value: allLanguages?.languages?.find(
            (lang) => `${lang.name} (${lang.alpha_2.toUpperCase()})` === name
          )?.alpha_2,
          label: name,
        },
        ...languageOptions,
      ]);
    }
    setLanguageChips(
      (previous) => previous?.filter((item) => item.name !== name) ?? []
    );
  };

  useEffect(() => {
    register({ name: "asset_languages", required: false });
  }, [register]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormItems>
        <SearchSelect
          name="asset_languages"
          options={languageOptions}
          errors={errors}
          formState={formState}
          placeholder={t(`Select Asset Languages`)}
          value={null}
          isCreatable={false}
          onChange={handleMultiSelectChange}
        />
      </FormItems>
      <DeletableChips
        chips={languageChips || []}
        noneSelectedName={"Languages"}
        handleClick={handleChipClick}
        invertBG={true}
      />
      <SaveButtonPrimaryMedium loading={submitting}>
        {t("Save your changes")}
      </SaveButtonPrimaryMedium>
    </Form>
  );
};
