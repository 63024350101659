import React from "react";
import styled from "styled-components/macro";
import ReactTooltip from "react-tooltip";

type ThreeLineBlockProps = {
  strings: [string, string, string];
  testid?: string;
};

const ContactInfoBlock = styled.div`
  max-width: 100%;
`;

const EmailLine = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
`;

export function ContactInfo({ strings, testid }: ThreeLineBlockProps) {
  return (
    <ContactInfoBlock data-testid={testid}>
      <div>{strings[0]}</div>
      <EmailLine data-tip={strings[1]}>{strings[1]}</EmailLine>
      <ReactTooltip delayHide={500} clickable effect="solid" />
      <div>{strings[2]}</div>
    </ContactInfoBlock>
  );
}
