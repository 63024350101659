import React, { useContext, useEffect, useState } from "react";
import type { PortfolioControlsSchema } from "../../../../../types/types.PIM";
import Axios from "axios";
import { H4 } from "../../../../../components/Typography/Typography";
import { Form, SubmitButtonContainer } from "../../../../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { AsyncSearchSelect } from "../../../../../components/AsyncSearchSelect/AsyncSearchSelect";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { PrimaryButtonFitContainer } from "../../../../../components/Buttons/Buttons";
import { useAttributesAsyncSearch } from "./util";
import { useFormWrapper, useStoreState } from "../../../../../util/util";
import { zodResolver } from "@hookform/resolvers/zod";
import { zodSelectBoxDefault } from "../../../../../util/zod.util";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { InfoBlockSmall } from "../../../../../components/InfoBlocks/InfoBlocks";
import type { OptionType } from "../../../../../types/types";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import ReactTooltip from "react-tooltip";

interface FormInputs {
  attribute: OptionType;
  is_searchable: true;
  is_filterable: true;
}

export const PortfolioControlForm = ({
  attribute,
  handleFormSubmit,
  portfolioControlsData,
  productSelectors,
}: {
  attribute?: PortfolioControlsSchema;
  handleFormSubmit: () => void;
  portfolioControlsData?: PortfolioControlsSchema[];
  productSelectors?: { name: string; order: string }[];
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { notifyError, notifySuccess } = useContext(Notifications);
  const {
    storefront_id,
    storefront_metadata: { default_language },
  } = useStoreState();
  const [attributeIsFilterable, setAttributeIsFilterable] = useState(
    attribute?.is_filterable
  );
  const [attributeIsSearchable, setAttributeIsSearchable] = useState(
    attribute?.is_searchable
  );
  const { handleAttributesSearch, attributeOptions } = useAttributesAsyncSearch(
    { portfolioControlsData }
  );
  const { t } = useTranslation();
  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(
      z.object({
        attribute: zodSelectBoxDefault(t),
      })
    ),
    defaultValues: {
      attribute: attribute
        ? {
            label: attribute?.display_name,
            value: attribute?.attribute?.id,
          }
        : { label: undefined, value: undefined },
    },
    reValidateMode: "onChange",
  });

  const { handleSubmit, control, formState, errors, watch } = methodsOfUseForm;

  const handleIsActiveClick = () => {
    setAttributeIsFilterable((prev) => !prev);
  };

  const handleIsSearchableClick = () => {
    setAttributeIsSearchable((prev) => !prev);
  };

  const onSubmit = async (inputs: FormInputs) => {
    const formData = {
      attribute_id: inputs.attribute.value,
      is_searchable: attributeIsSearchable,
      is_filterable: attributeIsFilterable,
    };
    setSubmitting(true);
    try {
      attribute && inputs.attribute.value === attribute.attribute.id
        ? await Axios.patch(
            `v2/storefronts/${storefront_id}/portfolio-controls/${attribute?.id}`,
            formData
          )
        : await Axios.post(
            `v2/storefronts/${storefront_id}/portfolio-controls`,
            formData
          );
      notifySuccess(t("Your changes have been saved successfully"));
      setSubmitting(false);
      handleFormSubmit();
    } catch (error) {
      notifyError(t("Something went wrong, please try again"), { error });
      setSubmitting(false);
    }
  };
  const selectedAttribute = watch("attribute");

  useEffect(() => {
    setAttributeIsFilterable(() =>
      portfolioControlsData?.length
        ? portfolioControlsData?.find(
            (attr) => attr.attribute?.id === selectedAttribute.value
          )?.is_filterable || false
        : false
    );
    setAttributeIsSearchable(() =>
      portfolioControlsData?.length
        ? portfolioControlsData?.find(
            (attr) => attr.attribute?.id === selectedAttribute.value
          )?.is_searchable || false
        : false
    );
  }, [selectedAttribute, portfolioControlsData]);

  return (
    <>
      <H4 style={{ marginBottom: 20 }}>
        {attribute ? t("Edit Attribute") : t("Add Attribute")}
      </H4>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div style={{ width: "420px" }}>
          <Controller
            as={AsyncSearchSelect}
            control={control}
            name="attribute"
            placeholder={t("Select Attribute")}
            searchFunction={handleAttributesSearch}
            errors={errors}
            formState={formState}
            defaultOptions
          />
        </div>
        {selectedAttribute.value && (
          <>
            <InfoBlockSmall
              header={t(`Display Name (${default_language.toUpperCase()})`)}
              content={
                attribute?.attribute.id === selectedAttribute.value
                  ? attribute.attribute.display_name
                  : attributeOptions?.find(
                      (attr) => attr?.value === selectedAttribute?.value
                    )?.display_name
              }
            />
            <ToggleSwitch
              name={`is_searchable`}
              label={t("Search")}
              onClick={handleIsSearchableClick}
              isChecked={attributeIsSearchable}
              // tooltip={
              //   !original.has_product
              //     ? t("No product is currently tagged by this filter type.")
              //     : undefined
              // }
            />
            {((attribute?.attribute.id === selectedAttribute.value &&
              attribute.is_filterable) ||
              attributeOptions?.find(
                (attr) => attr?.value === selectedAttribute?.value
              )?.is_filterable) && (
              <>
                <ToggleSwitch
                  name={`is_filterable`}
                  label={t("Filter")}
                  onClick={handleIsActiveClick}
                  isChecked={attributeIsFilterable}
                  disabled={
                    productSelectors
                      ?.map((selector) => selector.name)
                      .indexOf(
                        attribute?.attribute?.new_object_type?.toLocaleLowerCase() ||
                          ""
                      ) !== -1
                  }
                  tooltip={
                    productSelectors
                      ?.map((selector) => selector.name)
                      .indexOf(
                        attribute?.attribute?.new_object_type?.toLocaleLowerCase() ||
                          ""
                      ) !== -1
                      ? t(
                          "You cannot switch this attribute off as it's used as a product selector"
                        )
                      : undefined
                  }
                />
                <ReactTooltip id="delete-attribute-tooltip" place="top" />
              </>
            )}
          </>
        )}
        <SubmitButtonContainer>
          <PrimaryButtonFitContainer loading={submitting}>
            {t("Save")}
          </PrimaryButtonFitContainer>
        </SubmitButtonContainer>
      </Form>
    </>
  );
};
