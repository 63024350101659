import React, { useContext, useState } from "react";
import { TitleWithParagraph } from "../TitleWithParagraph/TitleWithParagraph";
import { EditAboutUs } from "./EditAboutUs";
import { EditTopRightWithPositioning } from "../EditTopRight/EditTopRightWithPositioning";
import { SlideOut } from "../SlideOut/SlideOut";
import styled from "styled-components/macro";
import { PrimaryButtonLarge } from "../Buttons/Buttons";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { Link } from "react-router-dom";
import { useRoutePath } from "../../util/Routing";
import { Auth } from "../Auth";
import { screenSize } from "../../theme";

export interface IAboutUsProps {
  isEditable?: boolean;
  homePageData: IHomePageData;
}

export interface IAboutUsData {
  title?: string;
  description: string;
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  h3 {
    margin-top: 0;
  }
`;

const ButtonContainer = styled.div`
  padding: 35px 0px;
  display: inline-block;
  button {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  @media ${screenSize.small} {
    a {
      display: block;
      margin-right: 0;
    }
    button,
    button:disabled {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
`;

export const AboutUs = ({ isEditable, homePageData }: IAboutUsProps) => {
  const [editMode, setEditMode] = useState(false);
  const startEditMode = () => setEditMode(true);
  const endEditMode = () => setEditMode(false);
  const { storePath } = useRoutePath();
  const { roleIsSomeKindOfSeller } = useContext(Auth);

  const component = homePageData.config.components.find(
    (component) => component.name === "about_us"
  );
  const data = component?.data;
  const title: string = data?.title;
  const description = data?.desc;

  return (
    <Container>
      <SlideOut closeFlyout={endEditMode} show={editMode}>
        <EditAboutUs endEditMode={endEditMode} homePageData={homePageData} />
      </SlideOut>
      {isEditable && <EditTopRightWithPositioning onClick={startEditMode} />}
      {(title || description) && (
        <TitleWithParagraph desc={description} title={title} />
      )}
      <ButtonContainer>
        {roleIsSomeKindOfSeller ? (
          <>
            <PrimaryButtonLarge disabled>Contact Us</PrimaryButtonLarge>
            <PrimaryButtonLarge disabled>Register Now</PrimaryButtonLarge>
          </>
        ) : (
          <>
            <Link to={`${storePath}/contact-us`}>
              <PrimaryButtonLarge>Contact Us</PrimaryButtonLarge>
            </Link>
            <Link to={`${storePath}/register`}>
              <PrimaryButtonLarge>Register Now</PrimaryButtonLarge>
            </Link>
          </>
        )}
      </ButtonContainer>
    </Container>
  );
};
