import React, { useContext } from "react";
import { Footer } from "./shared/Footer/Footer";
import type { DefaultTheme } from "styled-components/macro";
import { createGlobalStyle, ThemeContext } from "styled-components/macro";
import {
  AppWrapper,
  PublicHeader,
  Logo,
  MainWrapper,
  PageWrapper,
  UserAreaContainer,
  NavPanel,
  LogoWrapper,
} from "../layout/publicLayout";
import { UserArea } from "./shared/UserArea";
import { CartWidget } from "../components/quoteCart/CartWidget";
import { Nav } from "./shared/Nav";
import type { LayoutComponentProps } from "../types/types";
import { useStoreState } from "../util/util";
import { useRoutePath } from "../util/Routing";
import { useAuthContext } from "../components/Auth";
import { DocumentationArea } from "./shared/DocumentationArea/DocumentationArea";

/**
 * Provides the headers, footers, basic page layout, etc. for public pages.
 */
export const LayoutPublic = ({
  children,
  routes,
  omitUserArea,
  omitHeader,
  fullWidth,
  mxWidth,
}: LayoutComponentProps) => {
  const theme: DefaultTheme = useContext(ThemeContext);
  const navRoutes = routes.filter((route) => route.nav?.public);
  const { storefront_metadata } = useStoreState();
  const { roleIsSomeKindOfSeller } = useAuthContext();
  const { route_configuration } = storefront_metadata;
  const { storePath } = useRoutePath();
  const customGoogleFont =
    storefront_metadata.theme_object.branding.customFontFamily;
  const customFont = storefront_metadata.custom_font;
  const preferredFont = customGoogleFont
    ? customGoogleFont
    : customFont?.font_family_name ?? "";
  if (customFont) {
    const customFontFace = new FontFace(
      `${customFont.font_family_name}`,
      `url(${customFont.font_url})`
    );
    document.fonts.add(customFontFace);
  }
  // Ensures that custom fonts are applied to portals that display on public pages
  const GlobalCustomFontStyle = createGlobalStyle`
    body , *{
      font-family: ${() =>
        preferredFont ? `'${preferredFont}', 'Inter'` : "Inter"} !important;
    }
  `;

  // Check if orders and samples are disabled for Valtris
  const isCartDisabled = () => {
    const { sample_requests_visibility, quote_requests_visibility } =
      storefront_metadata;
    return (
      (sample_requests_visibility === "hidden" ||
        sample_requests_visibility === "disabled") &&
      (quote_requests_visibility === "hidden" ||
        quote_requests_visibility === "disabled")
    );
  };

  const portfolioRoute = route_configuration.find(
    (route) => route.route === "portfolio"
  );

  return (
    <>
      <GlobalCustomFontStyle />
      <AppWrapper center customFont={preferredFont}>
        {!omitHeader && (
          <PublicHeader mxWidth={mxWidth}>
            <LogoWrapper>
              {theme.publicLogoUrl && (
                <a href={storePath}>
                  <Logo mxWidth={mxWidth} src={theme.publicLogoUrl} />
                </a>
              )}
            </LogoWrapper>
            <NavPanel>
              <Nav navRoutes={navRoutes} navLayout="public" />
            </NavPanel>

            {!omitUserArea && (
              <UserAreaContainer mxWidth={mxWidth}>
                {roleIsSomeKindOfSeller && <DocumentationArea />}
                {!isCartDisabled() && portfolioRoute?.enabled && <CartWidget />}
                <UserArea />
              </UserAreaContainer>
            )}
          </PublicHeader>
        )}
        <MainWrapper mxWidth={mxWidth}>
          <PageWrapper fullWidth={fullWidth}>{children}</PageWrapper>
          <Footer />
        </MainWrapper>
      </AppWrapper>
    </>
  );
};
