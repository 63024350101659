import { InvisibleButton } from "../Buttons/Buttons";
import type { ProductFiltersMainSchema } from "../../types/types";
import { FiltersWrapper } from "../../layout/publicPageLayout";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import useSWR from "swr";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";
import type { ChipType } from "../Chips/Chips";
import { endpoints } from "../../endpoints";
import { useStoreState } from "../../util/util";
import noop from "lodash/noop";
import { useHistory, useLocation } from "react-router-dom";
import { CaretDownIcon, XIcon } from "../Icons/Icons";
import theme from "../../theme";
import { stringify } from "querystring";

export function chipsToStringArray(chips: ChipType[]) {
  return chips.map((chip) => chip.name);
}

interface ICustomFiltersWrapper {
  showAll: boolean;
}

const CustomFiltersWrapper = styled(FiltersWrapper)<ICustomFiltersWrapper>`
  background-image: none;
  background-position: left 6px;
  padding-left: 0;
  max-height: ${({ showAll }) => (showAll ? "auto" : "70px")};
  overflow: ${({ showAll }) => (showAll ? "auto" : "hidden")};
  background-size: 18px;
  margin: 5px 0 2px;
  transition: all 200ms ease;
  width: auto !important;
`;

const FiltersListArea = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
const EmptyFiltersPlaceholder = styled.div`
  width: 100%;
  height: 30px;
  margin: 0 0 35px;
`;

const FilterItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 3px 5px;
  align-items: center;
  margin: 0 10px 10px 0;
  button {
    padding: 2px;
    margin-left: 2px;
    display: flex;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  margin-top: 7px;
`;

type FilterAreaProps = {
  resetOffset?: React.Dispatch<React.SetStateAction<number>>;
  clearFilter: () => void;
  filtersList: string[];
  isPublic: boolean;
};

function resetPageURL({
  path,
  search,
  hash,
  isPublic,
}: {
  path: string;
  search: string;
  hash: string;
  isPublic: boolean;
}): string {
  const pathParts = path.split("/");
  const baseUrl = [
    ...pathParts.slice(0, 3),
    isPublic ? "portfolio" : "admin/pim/products",
  ].join("/");
  return baseUrl + search + hash;
}

export function SelectedFiltersArea({
  resetOffset = noop,
  clearFilter,
  filtersList,
  isPublic,
}: FilterAreaProps) {
  const { search, hash, pathname } = useLocation();

  const { storefront_id } = useStoreState();
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [listHeight, setListHeight] = useState(0);
  const history = useHistory();

  const wrapperRef = useRef(document.createElement("div"));

  const getFiltersFromURL = () => {
    var queryStart = search.indexOf("?") + 1,
      queryEnd = search.indexOf("#") + 1 || search.length + 1,
      query = search.slice(queryStart, queryEnd - 1),
      pairs = query.replace(/\+/g, " ").split("&"),
      parms = [],
      i,
      n: string,
      v,
      nv;

    if (query === search || query === "") return;

    for (i = 0; i < pairs.length; i++) {
      nv = pairs[i].split("=", 2);
      n = decodeURIComponent(nv[0]) as string;
      v = decodeURIComponent(nv[1]);
      // @ts-ignore
      if (filtersList?.indexOf(n) > -1) {
        parms.push({ type: n, label: v });
      }
    }
    return parms;
  };

  const queryObject = () => {
    const querylist: any = {
      active_tag_view: StringParam,
      q: StringParam,
    };
    const filter_labels = getFiltersFromURL();
    if (filter_labels) {
      const filtersList = filter_labels?.map((filter) => filter.type);
      const uniqueArrFiltersList = filtersList?.filter((element, index) => {
        return filtersList.indexOf(element) === index;
      });

      uniqueArrFiltersList?.forEach((filter) => {
        querylist[filter] = ArrayParam;
      });
    }
    return querylist;
  };
  const [query] = useQueryParams(queryObject());

  const {
    data: filters,
    error: filtersError,
    mutate: mutateFilters,
  } = useSWR<ProductFiltersMainSchema, Error>(
    endpoints.v2_storefronts_id_products_filters(storefront_id)
  );

  const filterList = (
    list: { type: string; label: string }[],
    item: string
  ) => {
    return (
      list
        ?.filter((filter) => filter.label !== item)
        .map((item) => item.label) || undefined
    );
  };

  const handleFilterClick = (filter: { type: string; label: string }) => {
    const updatedQuery = query;
    delete updatedQuery[filter.type];
    for (const [key, value] of Object.entries(updatedQuery)) {
      if (!value) {
        delete updatedQuery[key];
      }
    }
    const filter_labels = getFiltersFromURL();
    const filtersArray =
      filterList(
        // @ts-ignore
        filter_labels?.filter((item) => item.type === filter.type),
        filter.label
      )?.length > 0
        ? filterList(
            // @ts-ignore
            filter_labels?.filter((item) => item.type === filter.type),
            filter.label
          )
        : undefined;
    resetPage(
      `?${stringify({
        ...updatedQuery,
        ...(filtersArray && filtersArray?.length > 0
          ? { [filter.type]: filtersArray }
          : {}),
        active_tag_view: filter.type,
      })}`
    );
    resetOffset(0);
  };

  const resetPage = (currentSearch: string) => {
    const destination = resetPageURL({
      path: pathname,
      search: currentSearch,
      hash: hash,
      isPublic,
    });
    history.push(destination);
  };

  const handleClearFilters = () => {
    resetPage(
      `?${stringify({
        active_tag_view: query.active_tag_view,
        q: query.q,
      })}`
    );
    resetOffset(0);
    clearFilter();
  };

  useEffect(() => {
    mutateFilters();
  }, [query, mutateFilters]);

  useEffect(() => {
    setListHeight(wrapperRef?.current?.clientHeight);
  }, [query]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => setListHeight(wrapperRef?.current?.clientHeight),
      false
    );
  }, []);

  if (filtersError) {
    // Search still works if filters don't load.
    // This maintains the layout of the page in case of error.
    return <EmptyFiltersPlaceholder />;
  } else if (filters?.filters) {
    // Handle case that happened once on staging where all filters were empty arrays.
    // This might theoretically be possible if a tenant is activated and no filters
    // are added.
    const filter_labels = getFiltersFromURL();
    if (filter_labels === undefined) return null;
    else {
      return (
        <FiltersContainer>
          <CustomFiltersWrapper showAll={showAllFilters}>
            <FiltersListArea ref={wrapperRef}>
              {filter_labels?.map((filter) => (
                <FilterItemWrapper>
                  <span>{filter.label}</span>
                  <InvisibleButton onClick={() => handleFilterClick(filter)}>
                    <XIcon width={14} height={14} />
                  </InvisibleButton>
                </FilterItemWrapper>
              ))}
            </FiltersListArea>
          </CustomFiltersWrapper>
          <IconsContainer>
            {listHeight > 75 && (
              <InvisibleButton
                onClick={() => setShowAllFilters((prev) => !prev)}
                style={{
                  transform: showAllFilters ? "rotate(180deg)" : "none",
                }}
              >
                <CaretDownIcon width={16} height={16} />
              </InvisibleButton>
            )}

            {filter_labels?.[0]?.label && (
              <InvisibleButton
                onClick={() => {
                  handleClearFilters();
                }}
              >
                <XIcon
                  width={18}
                  height={18}
                  fill={theme.destructiveTextColor}
                />
              </InvisibleButton>
            )}
          </IconsContainer>
        </FiltersContainer>
      );
    }
  } else return null;
}
