import type {
  ProductSKU,
  OptionType,
  MethodsOfUseForm,
  SampleRequest,
  SampleRequestDetail,
  SampleRequestIndividualItem,
} from "../../types/types";
import type { PIMProduct } from "../../types/types.PIM";
import { Controller } from "react-hook-form";
import useSWR from "swr";
import { endpoints } from "../../endpoints";
import React from "react";
import { convertProductSKUToOption, useStoreState } from "../../util/util";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import { TextField } from "../TextFields/TextFields";
import {
  calculateTotalQuantity,
  formatTotalQuantityString,
} from "../quoteCart/BuyerQuoteItemForm";
import { TransactionItem } from "./TransactionItem";
import { TransactionItemTopLeftSampleRequest } from "./TransactionItemTopLeft";
import { TransactionItemInfoBlock } from "./TransactionItemInfoBlock";
import { makeNumberOfUnitsLabel, makeSkuLabel } from "./TransactionItemUtils";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

// A record mapping an item ID to details about that item, like its total price
// as a number, or null if there was an issue calculating the total.
export type ItemDetailRecord = Record<string, ItemDetail>;

type ItemDetail = {
  totalPrice: number | null;
  hasPriceTiers: boolean;
};

const BottomRightContainer = styled.div`
  display: flex;
`;

const InputContainer = styled.div`
  margin-left: 10px;
  width: 140px;
`;

const InputContainerSku = styled(InputContainer)`
  width: 200px;
`;

/**
 * Quote item card to display items to the seller when they are responding to
 * a quote request.
 */
export const SampleRequestItemCardSellerResponding = ({
  sample,
  item,
  index,
  methodsOfUseForm,
  inputIdSku,
  inputIdCustomSku,
  inputIdNumberOfUnits,
  inputIdPricePerUnit,
}: {
  sample: SampleRequest;
  item: SampleRequestDetail;
  index: number;
  methodsOfUseForm: MethodsOfUseForm;
  inputIdSku: string;
  inputIdCustomSku: string;
  inputIdNumberOfUnits: string;
  inputIdPricePerUnit: string;
}) => {
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();

  const { register, errors, formState, watch, control } = methodsOfUseForm;

  const skuOption: OptionType<ProductSKU> | undefined = watch(inputIdSku);
  const numberOfUnits: string = watch(inputIdNumberOfUnits);

  // Fetch the product to get the product SKUs.
  const { data: product } = useSWR<PIMProduct>(
    item.product_id
      ? endpoints.v2_storefronts_id_pim_products_id(
          storefront_id,
          item.product_id
        )
      : ""
  );
  const sku = skuOption?.value;

  const totalQuantity = calculateTotalQuantity(
    sku?.package_volume,
    numberOfUnits
  );
  const totalQuantityString =
    totalQuantity === null
      ? "--"
      : formatTotalQuantityString(sku?.packaging_unit?.name, totalQuantity);

  const topRightCol2 = (
    <TransactionItemInfoBlock
      main={totalQuantityString}
      lines={[makeSkuLabel(sku), makeNumberOfUnitsLabel(numberOfUnits, t)]}
    />
  );
  const requestedTotal =
    calculateTotalQuantity(item.sku?.package_volume, item.no_of_units) ?? 0;
  const requestedQuantity =
    formatTotalQuantityString(item.sku?.packaging_unit.name, requestedTotal) ??
    "--";

  const skuInput = (
    <InputContainerSku>
      <Controller
        as={SelectBoxV2}
        control={control}
        name={inputIdSku}
        placeholder={"SKU"}
        options={product?.product_skus.reduce<OptionType<ProductSKU>[]>(
          (acc, sku) => {
            if (sku.is_sample) {
              acc.push(convertProductSKUToOption(sku));
            }
            return acc;
          },
          []
        )}
        defaultValue={
          item.sku && item.sku.kind === "Product SKU"
            ? convertProductSKUToOption(item.sku)
            : undefined
        }
        rules={{ required: true }}
        errors={errors}
        formState={formState}
      />
    </InputContainerSku>
  );

  const numberOfUnitsInput = (
    <InputContainer>
      <TextField
        name={inputIdNumberOfUnits}
        label={t("No. of Units")}
        defaultValue={item.no_of_units ?? undefined}
        theref={register({ required: true })}
        errors={errors}
        formState={formState}
        min={1}
        type="number"
      />
    </InputContainer>
  );

  const topRightCol1 = (
    <BottomRightContainer>
      {skuInput}
      {numberOfUnitsInput}
    </BottomRightContainer>
  );

  /**
   * The `topRightCol1` column of a sample request item, showing the
   * "Potential Annual Usage".
   */
  const TransactionItemInfoBlockAnnualUsage = ({
    item,
  }: {
    item: SampleRequestDetail | SampleRequestIndividualItem;
  }) => {
    const { t } = useTranslation();

    const totalQuantityString = `${item.total_quantity || "--"} ${
      item.total_quantity_packaging_unit?.name || "--"
    }`;

    return (
      <TransactionItemInfoBlock
        heading={t("Potential Annual Usage")}
        main={totalQuantityString}
        lines={[item.purpose]}
      />
    );
  };

  return (
    <TransactionItem
      topLeft={
        <TransactionItemTopLeftSampleRequest item={item} index={index} />
      }
      topRightCol1={topRightCol1}
      topRightCol2={topRightCol2}
      bottomLeftCol1={
        <TransactionItemInfoBlock
          heading={t("Requested Details")}
          main={
            item.no_of_units
              ? requestedQuantity
              : formatTotalQuantityString(
                  item.sample_quantity_packaging_unit?.name,
                  item.sample_quantity
                )
          }
          lines={[
            ...(item.sku?.kind ? [makeSkuLabel(item.sku)] : []),
            ...(item.no_of_units
              ? [makeNumberOfUnitsLabel(item.no_of_units, t)]
              : []),
          ]}
          warningLine={item.sku?.kind ?? undefined}
        />
      }
      bottomLeftCol2={<TransactionItemInfoBlockAnnualUsage item={item} />}
      requestType="sample"
      requestId={sample.id}
      itemId={item.id}
      productId={item.product_id}
      buyerId={sample.buyer_id}
    />
  );
};
