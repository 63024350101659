import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CancelButtonSmall,
  EditButton,
  PrimaryButtonMedium,
} from "../../../../../components/Buttons/Buttons";
import { Row } from "../../../../../components/Layout/Layout";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import { H3, H6 } from "../../../../../components/Typography/Typography";
import { Form, RadioButtonContainer } from "../../../../../layout/FormLayout";
import { SettingsCard } from "../../../../../layout/portalPageLayout";
import { useFormWrapper } from "../../../../../util/util";
import { SearchFilterCaption } from "./PorfolioControlsTab";
import type { AxiosError } from "axios";
import { endpoints } from "../../../../../endpoints";
import axios from "axios";
import useSWR from "swr";
import type {
  LeadTransactionVisibility,
  StorefrontMetaData,
} from "../../../../../types/types";
import { Store } from "../../../../../Store";
import type { IStore } from "../../../../../Store";

type SampleAndQuoteRequestSettingsFormOutput = {
  sample_requests_visibility: LeadTransactionVisibility;
  quote_requests_visibility: LeadTransactionVisibility;
};

export const SampleAndQuoteRequestSettings = () => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const { notifyError, notifySuccess } = useContext(Notifications);

  const {
    storeState: { storefront_id, storefront_metadata },
    storeDispatch,
  } = useContext<IStore>(Store);

  const { sample_requests_visibility, quote_requests_visibility } =
    storefront_metadata;

  const { handleSubmit, register } = useFormWrapper({
    shouldUnregister: false,
    defaultValues: {
      sample_requests_visibility,
      quote_requests_visibility,
    },
  });

  const { mutate: storeFrontMetadataMutate } = useSWR<
    StorefrontMetaData,
    AxiosError<StorefrontMetaData>
  >(endpoints.v1_storefronts_id_metadata(storefront_id));

  const onCancel = () => setEditMode(false);

  const onSubmit = async (values: SampleAndQuoteRequestSettingsFormOutput) => {
    try {
      await axios.patch(
        endpoints.v1_storefronts_id_metadata(storefront_id),
        values
      );
      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: {
          ...storefront_metadata,
          quote_requests_visibility: values.quote_requests_visibility,
          sample_requests_visibility: values.sample_requests_visibility,
        },
      });

      notifySuccess(t("Changes saved successfully"));
      const metadata = await storeFrontMetadataMutate();
      if (metadata) {
        storeDispatch({
          type: "SET_STOREFRONT_METADATA",
          payload: metadata,
        });
      }
      setEditMode(false);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not update settings. Please try again later."),
        {
          error,
        }
      );
    }
  };

  const getHumanReadableTextFromUnion = new Map<
    LeadTransactionVisibility,
    string
  >([
    ["hidden", t("Hidden everywhere")],
    ["disabled", t("Disabled outside login")],
    ["enabled", t("Enabled everywhere")],
  ]);

  return (
    <SettingsCard>
      <Row>
        <div>
          <H3 style={{ marginBottom: 0 }}>
            {t("Sample & Quote Request Configuration")}
          </H3>
          <SearchFilterCaption>
            {t("Configure who can request quote & sample requests")}
          </SearchFilterCaption>
        </div>
        {!editMode && (
          <EditButton
            testid={"edit-lead-visibility"}
            onClick={() => setEditMode(true)}
          />
        )}
        {editMode && <CancelButtonSmall onClick={onCancel} />}
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <H6>{t("Sample Requests")}</H6>
        {editMode ? (
          <RadioButtonContainer>
            <RadioButton
              name={"sample_requests_visibility"}
              value="enabled"
              theref={register({ required: true })}
              optionTitle={t("Enabled everywhere")}
            />
            <RadioButton
              name={"sample_requests_visibility"}
              value="disabled"
              theref={register({ required: true })}
              optionTitle={t("Disabled outside login")}
            />
            <RadioButton
              name={"sample_requests_visibility"}
              value="hidden"
              theref={register({ required: true })}
              optionTitle={t("Hidden everywhere")}
            />
          </RadioButtonContainer>
        ) : (
          <ul>
            <li>
              {getHumanReadableTextFromUnion.get(sample_requests_visibility)}
            </li>
          </ul>
        )}
        <H6>{t("Quote Requests")}</H6>
        {editMode ? (
          <RadioButtonContainer>
            <RadioButton
              name={"quote_requests_visibility"}
              value="enabled"
              theref={register({ required: true })}
              optionTitle={t("Enabled everywhere")}
            />
            <RadioButton
              name={"quote_requests_visibility"}
              value="disabled"
              theref={register({ required: true })}
              optionTitle={t("Disabled outside login")}
            />
            <RadioButton
              name={"quote_requests_visibility"}
              value="hidden"
              theref={register({ required: true })}
              optionTitle={t("Hidden everywhere")}
            />
          </RadioButtonContainer>
        ) : (
          <ul style={{ margin: "0px" }}>
            <li>
              {getHumanReadableTextFromUnion.get(quote_requests_visibility)}
            </li>
          </ul>
        )}
        {editMode && (
          <PrimaryButtonMedium type="submit">
            {t("Save your changes")}
          </PrimaryButtonMedium>
        )}
      </Form>
    </SettingsCard>
  );
};
