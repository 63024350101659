import React, { forwardRef } from "react";
import styled, { useTheme } from "styled-components/macro";
import { SearchIcon, XIcon } from "../Icons/Icons";

interface ISearchBarProps {
  placeHolder: string;
  query: string;
  handleChange: (e: any) => void;
  handleClearInput: () => void;
  dataTestId?: string;
  maxWidth?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  id?: string;
}
interface ISearchBarUIProps {
  maxWidth?: string;
}

const SearchWrapper = styled.div<ISearchBarUIProps>`
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "695px")};
  > svg {
    position: absolute;
    top: 8px;
    left: 7px;
    path {
      fill: ${({ theme }) => theme.primaryIconColor};
    }
  }
`;

export const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  background-color: ${({ theme }) => theme.primaryBG};
  font-family: Inter;
  padding: 12px 13px 11px 32px;
  font-size: 13px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.tertiaryBorder};
    box-shadow: 0 0 1px 1px ${({ theme }) => theme.tertiaryBorder};
    outline: none;
  }
`;

const ClearInput = styled.span`
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  padding: 5px;
  line-height: 10px;
`;

export const SearchBar = forwardRef<HTMLDivElement, ISearchBarProps>(
  ({ ...props }: ISearchBarProps, ref) => {
    const theme = useTheme();
    return (
      <SearchWrapper ref={ref} maxWidth={props.maxWidth}>
        <SearchInput
          placeholder={props.placeHolder}
          value={props.query}
          onChange={props.handleChange}
          data-testid={props.dataTestId}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          id={props.id}
        />
        <SearchIcon fill={theme.primaryButtonTextColor} />
        {props.query && (
          <ClearInput onClick={props.handleClearInput}>
            <XIcon width={20} height={20} />
          </ClearInput>
        )}
      </SearchWrapper>
    );
  }
);
