import React, { useState, useContext } from "react";
import { Form } from "../../../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../../components/SelectBoxV2/SelectBoxV2";
import {
  makeUrlWithParams,
  useStoreState,
  convertUserToOption,
  useFormWrapper,
} from "../../../../util/util";
import type {
  OptionType,
  Tenant,
  AgilisUsersPaginatedOutput,
  User,
  ITenantCustomerSettingsCreation,
} from "../../../../types/types";
import useSWR from "swr";
import { PrimaryButtonMedium } from "../../../../components/Buttons/Buttons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import { endpoints } from "../../../../endpoints";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorPlaceholder } from "../../../../components/Error";
import { FormContainer, SaveButtonContainer } from "./CustomerSettings";

const formSchema = yup.object().shape({
  account_manager: yup.object().nullable().notRequired(),
  customer_service_rep: yup.object().nullable().notRequired(),
});

type FormData = {
  account_manager: OptionType<string> | null;
  customer_service_rep: OptionType<string> | null;
};

export const EditCustomerRelationForm = ({
  buyerTenant,
  loggedInUser,
  refreshSettingsData,
  doneEditing,
  accountManager,
  customerServiceRep,
}: {
  buyerTenant: Tenant;
  loggedInUser: User;
  refreshSettingsData: () => void;
  doneEditing: () => void;
  accountManager: User | undefined;
  customerServiceRep: User | undefined;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: usersData, error: usersError } =
    useSWR<AgilisUsersPaginatedOutput>(
      buyerTenant.id
        ? makeUrlWithParams(
            endpoints.v1_storefronts_id_tenants_id_users(
              storefront_id,
              loggedInUser.tenant_id
            ),
            {
              offset: 0,
              // TODO: this won't work if there are more than 100 users.
              limit: 100,
            }
          )
        : null
    );

  const users = usersData?.data.filter((user) => user.is_active);
  const userOptions = users?.map(convertUserToOption) ?? [];

  const { handleSubmit, control, errors, formState } = useFormWrapper<FormData>(
    {
      resolver: yupResolver(formSchema),
      defaultValues: {
        account_manager: accountManager
          ? convertUserToOption(accountManager)
          : null,
        customer_service_rep: customerServiceRep
          ? convertUserToOption(customerServiceRep)
          : null,
      },
    }
  );

  const onSubmit = async (formData: FormData) => {
    setIsSubmitting(true);
    try {
      const { account_manager, customer_service_rep } = formData;

      let requestBody: ITenantCustomerSettingsCreation = {};
      if (account_manager?.value) {
        requestBody.account_manager_id = account_manager.value;
      }
      if (customer_service_rep?.value) {
        requestBody.customer_service_rep_id = customer_service_rep.value;
      }

      await Axios.patch(
        endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
          storefront_id,
          // Since this edit form is only used by sellers (not buyers), the
          // logged in user will belong to the seller tenant, so that's how we
          // get the seller tenant ID.
          loggedInUser.tenant_id,
          buyerTenant.id
        ),
        requestBody
      );
      notifySuccess("Changes saved");
      setIsSubmitting(false);
      refreshSettingsData();
      doneEditing();
    } catch (error) {
      notifyError("There was an error submitting the changes", { error });
      setIsSubmitting(false);
    }
  };

  if (usersError) {
    return <ErrorPlaceholder message="There was an error, please try again." />;
  }
  if (!usersData && !usersError) {
    // Loading.
    return null;
  }
  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={SelectBoxV2}
          control={control}
          name="account_manager"
          placeholder="Account Manager"
          options={userOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="customer_service_rep"
          placeholder="Customer Success Manager"
          options={userOptions}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        />
        <SaveButtonContainer>
          <PrimaryButtonMedium loading={isSubmitting}>
            Save your changes
          </PrimaryButtonMedium>
        </SaveButtonContainer>
      </Form>
    </FormContainer>
  );
};
