import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { SectionTitle } from "../Form/Form";
import { HeaderLeft } from "../Layout/Layout";
import { TextField } from "../TextFields/TextFields";
import moment from "moment";
import {
  Form,
  FormGrid2x2,
  RadioButtonContainer,
  SubmitButtonContainer,
} from "../../layout/FormLayout";
import { PageTitle } from "../../layout/portalPageLayout";
import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import { DatePicker } from "../DatePicker/DatePicker";
import { RadioButton } from "../RadioButton/RadioButton";
import { TextArea } from "../TextArea/TextArea";
import type { IFileCardFile } from "../FileCard/FileCard";
import { FileCard, FileCardUploading } from "../FileCard/FileCard";
import type { IShipmentAdvice } from "../../types/types";
import { Notifications } from "../Notifications/NotificationsContext";
import { useFormWrapper } from "../../util/util";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { strings } from "../../util/strings";
import { ColoredTextOnError } from "../../util/util-components";
import { useTranslation } from "react-i18next";
import type { AxiosError } from "axios";
import { FilePickerUncontrolled } from "../FilePicker/FilePickerUncontrolled";
import { SUPPORTED_SHIPMENT_ADVICE_DOC } from "../FilePicker/FilePicker.constants";

const TextAreaNoResizeX = styled.div`
  textarea {
    resize: vertical;
  }
`;

const ModeOfShipmentContainer = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-right: 20px;
`;

type ShipmentAdviceForm = {
  shipment_advice_type?: string;
  carrier?: string;
  carrier_number?: string;
  additional_comments?: string;
  estimated_time_of_arrival?: string;
  estimated_time_of_dispatch?: string;
};

/**
 * Form to create shipment advice as seller, which is later displayed to the
 * buyer. Used for shipment advice on orders and sample requests.
 */
export function SellerCreateOrEditShipmentAdvice({
  shipmentAdvice,
  shippingDocument,
  mode,
  closeForm,
  mutateData,
  postShipmentAdvice,
  patchShipmentAdvice,
  deleteShipmentAdvice,
  patchShipmentAdviceDocument,
  postShipmentAdviceDocument,
}: {
  shipmentAdvice?: IShipmentAdvice;
  shippingDocument?: IFileCardFile & { signed_url: string };
  mode: "edit" | "create";
  closeForm: () => void;
  mutateData: () => void;
  postShipmentAdvice: (formData: FormData) => Promise<void>;
  patchShipmentAdvice: (formData: FormData) => Promise<void>;
  patchShipmentAdviceDocument: (
    formData: FormData
  ) => Promise<{ data: IFileCardFile & { signed_url: string } }>;
  postShipmentAdviceDocument?: (
    formData: FormData
  ) => Promise<{ data: IFileCardFile & { signed_url: string } }>;
  deleteShipmentAdvice: (documentId: string) => Promise<void>;
}) {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const [uploadingFile, setUploadingFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [uploadedDocument, setUploadedDocument] =
    useState<(IFileCardFile & { signed_url: string }) | undefined>(
      shippingDocument
    );

  const FormSchema = yup.object().shape({
    shipment_advice_type: yup
      .string()
      .required(strings(t).thisIsARequiredField),
  });

  const methodsOfUseForm = useFormWrapper<IShipmentAdvice>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      additional_comments: shipmentAdvice?.additional_comments,
      carrier: shipmentAdvice?.carrier,
      carrier_number: shipmentAdvice?.carrier_number,
      shipment_advice_type: shipmentAdvice?.shipment_advice_type || "Sea",
      estimated_time_of_arrival: shipmentAdvice?.estimated_time_of_arrival,
      estimated_time_of_dispatch: shipmentAdvice?.estimated_time_of_dispatch,
      shipment_advice_document: shipmentAdvice?.shipment_advice_document,
    },
  });

  const { handleSubmit, register, formState, errors, setValue, watch } =
    methodsOfUseForm;
  const etd = watch("estimated_time_of_dispatch");
  const eta = watch("estimated_time_of_arrival");

  const handleFile = async (file: File) => {
    setUploadingFile(file);
    const formData = new FormData();
    formData.append("file", file);

    try {
      if (mode === "create") {
        if (postShipmentAdviceDocument) {
          // When uploading a shipment advice for sample request in "create"
          // mode we use POST request, for all other cases we use Patch request
          // to upload a shipment advice document.
          const uploadedDocument = await postShipmentAdviceDocument(formData);
          setUploadedDocument(uploadedDocument.data);
        } else {
          const uploadedDocument = await patchShipmentAdviceDocument(formData);
          setUploadedDocument(uploadedDocument.data);
        }
      }
      if (mode === "edit" && patchShipmentAdviceDocument) {
        const uploadedDocument = await patchShipmentAdviceDocument(formData);
        setUploadedDocument(uploadedDocument.data);
      }
      notifySuccess("Document uploaded successfully");
      setUploadingFile(undefined);
      // mutate the SWR source data so we can see the new file with FileCard.
      mutateData();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("Upload failed, something went wrong"),
        {
          error,
        }
      );
      setUploadingFile(undefined);
    }
  };

  const onSubmit = async (values: ShipmentAdviceForm) => {
    setLoading(true);
    const formData = Object.entries(values).reduce((formData, entry) => {
      const [key, value] = entry;
      // Appeasing the compiler here. Value should be an empty string in
      // practice if the user doesn't fill in the input but TS believe is may
      // be undefined, which is not compatible with the type `String | Blob`
      if (value !== undefined) {
        formData.append(key, value);
      }
      return formData;
    }, new FormData());
    if (uploadedDocument?.id) {
      formData.append("document_id", uploadedDocument.id);
    }

    try {
      if (mode === "create") {
        await postShipmentAdvice(formData);
      }
      if (mode === "edit") {
        await patchShipmentAdvice(formData);
      }
      notifySuccess(
        mode === "edit"
          ? t("Shipment Advice Updated")
          : t("Shipment Advice Created")
      );
      setLoading(false);
      closeForm();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error adding advice, please try again."),
        {
          error,
        }
      );
      setLoading(false);
    }
  };

  const deleteDocument = async (documentId: string) => {
    try {
      await deleteShipmentAdvice(documentId);
      // Mutate SWR source data so user sees the file has been deleted.
      mutateData();
      notifySuccess(t("Document has been removed successfully."));
      setUploadedDocument(undefined);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error deleting the document"),
        {
          error,
        }
      );
    }
  };

  const viewDocument = async (id: string) => {
    if (uploadedDocument) {
      // This should definitely exist if the user can click on the button that
      // runs this.
      window.open(uploadedDocument.signed_url, "_blank");
    }
  };

  const handleETDChange = () => {
    if (etd && eta && moment(eta).isSameOrBefore(etd)) {
      setValue("estimated_time_of_arrival", "");
    }
  };

  const start_of_day = moment().startOf("day");

  return (
    <>
      <HeaderLeft>
        <PageTitle>{t("Shipment Advice")}</PageTitle>
      </HeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        {/* This is not specified but we're limiting to one document for simplicities sake. */}
        {!uploadingFile && !uploadedDocument && (
          <FilePickerUncontrolled
            methodsOfUseForm={methodsOfUseForm}
            name={"shipment_advice_doc"}
            required={false}
            handleFile={handleFile}
            accept={SUPPORTED_SHIPMENT_ADVICE_DOC}
          />
        )}
        {uploadingFile && <FileCardUploading fileName={uploadingFile.name} />}
        {uploadedDocument && (
          <FileCard
            file={uploadedDocument}
            viewFile={viewDocument}
            deleteProps={{
              deleteFile: deleteDocument,
              confirmMessage: t(
                "Are you sure you want to delete the shipment advice?"
              ),
            }}
          />
        )}

        <SectionTitle>{t("Summary")}</SectionTitle>
        <ModeOfShipmentContainer>
          <ColoredTextOnError isError={!!errors.shipment_advice_type}>
            {t("Mode of Shipment")}
          </ColoredTextOnError>
        </ModeOfShipmentContainer>
        <RadioButtonContainer>
          <RadioButton
            name={"shipment_advice_type"}
            value="Sea"
            theref={register({ required: false })}
            optionTitle={t("Sea")}
          />
          <RadioButton
            name={"shipment_advice_type"}
            value="Air"
            theref={register({ required: false })}
            optionTitle={t("Air")}
          />
          <RadioButton
            name={"shipment_advice_type"}
            value="Road"
            theref={register({ required: false })}
            optionTitle={t("Road")}
          />
        </RadioButtonContainer>
        <FormGrid2x2>
          <DatePicker
            label={"ETD"}
            name={"estimated_time_of_dispatch"}
            defaultValue={etd}
            required={false}
            methodsOfUseForm={methodsOfUseForm}
            handleOnChange={handleETDChange}
            isOutsideRange={(day) =>
              eta
                ? day.isBefore(start_of_day) || day.isSameOrAfter(eta)
                : day.isBefore(start_of_day)
            }
          />
          <DatePicker
            label={"ETA"}
            name={"estimated_time_of_arrival"}
            defaultValue={eta}
            required={false}
            methodsOfUseForm={methodsOfUseForm}
            isOutsideRange={(day) =>
              etd ? day.isSameOrBefore(etd) : day.isSameOrBefore(moment())
            }
          />
          <TextField
            name="carrier"
            label={t("Carrier")}
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          <TextField
            name="carrier_number"
            label={t("Carrier Pro Number")}
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
        </FormGrid2x2>
        <TextAreaNoResizeX>
          <TextArea
            name={"additional_comments"}
            label={t("Additional Comments")}
            theref={register({ required: false })}
            formState={formState}
            required={false}
          />
        </TextAreaNoResizeX>
        <SubmitButtonContainer>
          <PrimaryButtonFitContainer type="submit" loading={loading}>
            {t("Submit")}
          </PrimaryButtonFitContainer>
        </SubmitButtonContainer>
      </Form>
    </>
  );
}
