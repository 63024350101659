import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Auth } from "../../components/Auth";
import { GrayBox } from "../../components/Form/Form";
import { Notifications } from "../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../components/SelectBoxV2/SelectBoxV2";
import { endpoints } from "../../endpoints";
import type { OptionType, TwoFactorTypes } from "../../types/types";
import {
  countryCodeMap,
  getPhoneNumber,
  getPhoneCodeOption,
  getCountryCode,
  getPhoneCodesOptions,
} from "../../util/phone";
import { providePrivatePageProps } from "../../util/Routing";
import { strings } from "../../util/strings";
import {
  useStoreState,
  useFormWrapper,
  isAxiosError,
  useSupportedLanguages,
} from "../../util/util";
import { formatPrice } from "../../util/util-components";
import * as Yup from "yup";
import { H3, Title } from "../../components/Typography/Typography";
import { Form, Flex, Flex1, Flex2 } from "../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { PrimaryButtonMedium } from "../../components/Buttons/Buttons";
import {
  InfoBlockSpacer,
  InfoBlockExtraSmall,
} from "../../components/InfoBlocks/InfoBlocks";
import { DesktopFormContainer } from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import {
  PasswordToggleTextField,
  TextField,
} from "../../components/TextFields/TextFields";
import { ChatEmailNotification } from "./ChatEmailNotification";
import { SelfAndTeamActionEmailNotification } from "./SelfAndTeamActionEmailNotification";

const UserContainer = styled.div`
  max-width: 811px;
  margin-left: 10vw;
`;

const GrayBoxContainer = styled.div`
  width: 100%;
`;

const UserPreferencesGrayBox = styled(GrayBox)`
  padding-left: 29px;
  padding-right: 29px;
  padding-top: 31px;
  padding-bottom: 31px;
  margin-bottom: 31px;
`;

const TwoFactorOptions: OptionType<TwoFactorTypes | null>[] = [
  { label: "Authenticator App", value: "totp" },
  { label: "SMS", value: "sms" },
  { label: "Voice", value: "voice" },
];

const getTwoFactorOption = (preferred_2fa_type: string) => {
  return TwoFactorOptions.find((fact) => fact.value === preferred_2fa_type);
};

export const UserPreferences = providePrivatePageProps(({ user }) => {
  const {
    updateUser: updateUserInContext,
    roleIsSellerStandard,
    roleIsSellerAdmin,
    roleIsSomeKindOfBuyer,
  } = useContext(Auth);
  const {
    storefront_id,
    storefront_metadata: {
      default_currency,
      edition,
      email_preferences_enabled,
      totp_required,
    },
  } = useStoreState();
  const userTenantId = user?.tenant_id;
  const { notifySuccess, notifyError } = useContext(Notifications);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false);
  const [isUpdating2FA, setIsUpdating2FA] = useState(false);
  const { getLanguageOption, supportedLanguageOptions } =
    useSupportedLanguages();
  const userProfileSchema = Yup.lazy((formValues: any) => {
    return Yup.object().shape({
      phone_number: Yup.string().phone(
        countryCodeMap.get(formValues.country_code.value),
        false,
        strings(t).phoneNumberMustBeValid
      ),
      country_code: Yup.object()
        .shape({
          label: Yup.string().required(strings(t).thisIsARequiredField),
          value: Yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      firstname: Yup.string().required(strings(t).thisIsARequiredField),
      lastname: Yup.string().required(strings(t).thisIsARequiredField),
      crm_id: Yup.string().nullable().notRequired(),
    });
  });

  const { handleSubmit, register, formState, reset, errors, control } =
    useFormWrapper({
      defaultValues: {
        firstname: user.firstname,
        lastname: user.lastname,
        phone_number: getPhoneNumber(user.phone_number),
        crm_id: user.crm_id,
        country_code: getPhoneCodeOption(getCountryCode(user.phone_number)),
      },
      resolver: yupResolver(userProfileSchema),
    });

  const { t } = useTranslation();

  const {
    handleSubmit: handlePasswordSubmit,
    register: passwordRegister,
    reset: resetPassword,
  } = useFormWrapper({});

  const { handleSubmit: handleLanguageSubmit, control: controlLanguage } =
    useFormWrapper({});

  const { handleSubmit: handle2FASubmit, control: control2FA } = useFormWrapper(
    {}
  );

  type UserProfile = {
    phone_number: string;
    country_code: OptionType<string>;
    firstname: string;
    lastname: string;
    crm_id?: string;
  };

  const updateUser = async (userId: string, tenantIdOfUser: string) => {
    try {
      const response = await Axios.get(
        endpoints.v1_storefronts_id_users_login(storefront_id)
      );
      if (response.status === 200) {
        return response.data;
      }
      // TODO: A better way to handle non-200 success statuses?
      notifyError(t("Something unexpected happened when updating the profile"));
    } catch (error) {
      notifyError(t("There was an error updating the profile"), { error });
    }
  };

  const onProfileSubmit = async (values: UserProfile) => {
    if (!user || !user.id || !userTenantId) {
      // Should never happen, these things should always be defined.
      notifyError(t("Error: missing user, user ID, or user tenant ID"));
      return;
    }

    if (values.phone_number === "" && user.phone_number !== null) {
      notifyError(t("Phone Number is not deletable."));
      return;
    }

    const postValues = {
      phone_number: `${values.country_code.value}${values.phone_number}`,
      firstname: values.firstname,
      lastname: values.lastname,
      crm_id: values.crm_id,
    };

    try {
      const response = await Axios.patch(
        endpoints.v1_storefronts_id_tenants_id_users_id(
          storefront_id,
          userTenantId,
          user.id
        ),
        postValues
      );
      if (response.status === 200 || response.status === 201) {
        const userObject = await updateUser(user.id, userTenantId);
        if (userObject) {
          updateUserInContext(userObject);
          notifySuccess(t("Profile updated successfully"));
          reset({
            firstname: userObject.firstname,
            lastname: userObject.lastname,
            phone_number: getPhoneNumber(userObject.phone_number),
            country_code: getPhoneCodeOption(
              getCountryCode(userObject.phone_number)
            ),
            crm_id: userObject.crm_id,
          });
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        notifyError(error?.response?.data?.message);
      }
    }
  };

  type PasswordData = {
    old_password: string;
    new_password: string;
    confirm_new_password: string;
  };

  type PasswordReset = {
    password_data: PasswordData;
  };

  const onPasswordSubmit = async (values: PasswordData) => {
    if (values.new_password !== values.confirm_new_password) {
      notifyError(t("Passwords don't match"));
      return;
    }

    const passwordFormData: PasswordReset = {
      password_data: {
        ...values,
      },
    };

    try {
      setIsUpdatingPassword(true);
      if (user) {
        const response = await Axios.patch(
          `/v1/users/${user.id}`,
          passwordFormData
        );
        if (response.status === 201 || response.status === 200) {
          notifySuccess(t("Password updated successfully"));
          resetPassword({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
          setIsUpdatingPassword(false);
        }
      }
    } catch (error) {
      // error.response.data.message was an empty string for code 405
      if (isAxiosError(error)) {
        notifyError(error?.response?.data.message);
      }

      resetPassword({
        new_password: "",
        confirm_new_password: "",
      });

      setIsUpdatingPassword(false);
    }
  };

  const onLanguageSubmit = async (values: {
    notification_language: OptionType;
  }) => {
    if (!user || !user.id || !userTenantId) {
      // Should never happen, these things should always be defined.
      notifyError(t("Error: missing user, user ID, or user tenant ID"));
      return;
    }

    if (values?.notification_language?.value === null) {
      notifyError("Please select your preferred Language.");
      return;
    }

    try {
      setIsUpdatingLanguage(true);
      const preferredLanguage = {
        preferred_language: values.notification_language.value,
      };
      const response = await Axios.patch(
        endpoints.v1_storefronts_id_tenants_id_users_id(
          storefront_id,
          userTenantId,
          user.id
        ),
        preferredLanguage
      );
      if (response.status === 200 || response.status === 201) {
        const userObject = await updateUser(user.id, userTenantId);
        if (userObject) {
          updateUserInContext(userObject);
          notifySuccess(
            t("Preferred Language and 2FA type updated successfully")
          );
          setIsUpdatingLanguage(false);
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        notifyError(error?.response?.data?.message);
      }
      setIsUpdatingLanguage(false);
    }
  };

  const on2FASubmit = async (values: {
    preferred_2fa_type: OptionType<TwoFactorTypes | null>;
  }) => {
    if (!user || !user.id || !userTenantId) {
      // Should never happen, these things should always be defined.
      notifyError(t("Error: missing user, user ID, or user tenant ID"));
      return;
    }

    const preferred_2fa_type = values?.preferred_2fa_type?.value ?? null;
    const sms_2fa_phone_number = preferred_2fa_type
      ? user.sms_2fa_phone_number
      : null;

    try {
      setIsUpdating2FA(true);
      const response = await Axios.patch(
        endpoints.v1_storefronts_id_tenants_id_users_id(
          storefront_id,
          userTenantId,
          user.id
        ),
        {
          preferred_2fa_type,
          sms_2fa_phone_number,
        }
      );
      if (response.status === 200 || response.status === 201) {
        const userObject = await updateUser(user.id, userTenantId);
        if (userObject) {
          updateUserInContext(userObject);
          notifySuccess(t("Preferred 2FA type updated successfully"));
          setIsUpdating2FA(false);
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        notifyError(error?.response?.data?.message);
      }
      setIsUpdating2FA(false);
    }
  };

  return (
    <>
      <UserContainer>
        <div>
          <Title style={{ marginBottom: "35px", textTransform: "capitalize" }}>
            {user?.firstname && (
              <span>
                {t("Hello,  {{username}} ", { username: user.firstname })}
              </span>
            )}
          </Title>
          <GrayBoxContainer>
            <UserPreferencesGrayBox>
              <H3>{t("Profile")}</H3>
              <DesktopFormContainer>
                <Form noValidate onSubmit={handleSubmit(onProfileSubmit)}>
                  <TextField
                    name="firstname"
                    type="text"
                    theref={register({
                      required: true,
                    })}
                    label={t("First Name")}
                    formState={formState}
                    errors={errors}
                  />
                  <TextField
                    name="lastname"
                    type="text"
                    theref={register({
                      required: true,
                    })}
                    label={t("Last Name")}
                    formState={formState}
                    errors={errors}
                  />

                  <Flex>
                    <Flex1>
                      <Controller
                        as={SelectBoxV2}
                        control={control}
                        name="country_code"
                        autoComplete="countryCode"
                        placeholder={t("Country Code")}
                        id="countryCodeSelectBox"
                        options={getPhoneCodesOptions()}
                        rules={{
                          required: true,
                        }}
                        errors={errors}
                        formState={formState}
                      />
                    </Flex1>
                    <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                      <TextField
                        name="phone_number"
                        autoComplete="tel"
                        label={t("Phone Number")}
                        theref={register({
                          required: true,
                        })}
                        formState={formState}
                        errors={errors}
                        type="tel"
                      />
                    </Flex2>
                  </Flex>
                  {!roleIsSomeKindOfBuyer && (
                    <TextField
                      name="crm_id"
                      type="text"
                      theref={register({
                        required: false,
                      })}
                      label={t("CRM ID")}
                      formState={formState}
                      readOnly={!roleIsSellerAdmin}
                      errors={errors}
                    />
                  )}
                  <PrimaryButtonMedium type="submit">
                    {t("Save your Changes")}
                  </PrimaryButtonMedium>
                </Form>
              </DesktopFormContainer>
            </UserPreferencesGrayBox>
          </GrayBoxContainer>
        </div>
      </UserContainer>
      <UserContainer>
        <UserPreferencesGrayBox>
          <H3>{t("Change Password")}</H3>
          <DesktopFormContainer>
            <Form noValidate onSubmit={handlePasswordSubmit(onPasswordSubmit)}>
              <PasswordToggleTextField
                name={"current_password"}
                theref={passwordRegister({
                  required: true,
                })}
                label={t("Old Password")}
                formState={formState}
                errors={errors}
              />
              <PasswordToggleTextField
                name={"new_password"}
                theref={passwordRegister({
                  required: true,
                })}
                label={t("New Password")}
                formState={formState}
                errors={errors}
              />
              <PasswordToggleTextField
                name={"confirm_new_password"}
                theref={passwordRegister({
                  required: true,
                })}
                label={t("Confirm New Password")}
                formState={formState}
                errors={errors}
              />
              <PrimaryButtonMedium type="submit" loading={isUpdatingPassword}>
                {t("Update your password")}
              </PrimaryButtonMedium>
            </Form>
          </DesktopFormContainer>
        </UserPreferencesGrayBox>
      </UserContainer>
      <UserContainer>
        <UserPreferencesGrayBox>
          <H3>{t("Preferred Notification Language")}</H3>
          <DesktopFormContainer>
            <Form noValidate onSubmit={handleLanguageSubmit(onLanguageSubmit)}>
              <Controller
                as={SelectBoxV2}
                control={controlLanguage}
                name="notification_language"
                placeholder={t("Preferred Language")}
                options={supportedLanguageOptions}
                defaultValue={
                  user ? getLanguageOption(user.preferred_language) : null
                }
                rules={{
                  required: true,
                }}
                errors={errors}
                formState={formState}
              />
              <PrimaryButtonMedium type="submit" loading={isUpdatingLanguage}>
                {t("Save your changes")}
              </PrimaryButtonMedium>
            </Form>
          </DesktopFormContainer>
        </UserPreferencesGrayBox>
        {roleIsSellerStandard && (
          <UserPreferencesGrayBox>
            <H3>{t("Limits")}</H3>
            <InfoBlockSpacer>
              <InfoBlockExtraSmall
                header={t("Order Limit USD")}
                content={
                  user?.order_threshold_usd === null
                    ? t("Unlimited")
                    : formatPrice(Number(user?.order_threshold_usd), "USD")
                }
              />
            </InfoBlockSpacer>
            <InfoBlockSpacer>
              <InfoBlockExtraSmall
                header={t("Quote Limit USD")}
                content={
                  user?.quote_threshold_usd === null
                    ? t("Unlimited")
                    : formatPrice(Number(user?.quote_threshold_usd), "USD")
                }
              />
            </InfoBlockSpacer>
            {default_currency !== "USD" && (
              <>
                <InfoBlockSpacer>
                  <InfoBlockExtraSmall
                    header={t(`Quote Limit {{default_currency}}`, {
                      default_currency,
                    })}
                    content={
                      user?.quote_threshold_local === null
                        ? t("Unlimited")
                        : formatPrice(
                            Number(user?.quote_threshold_local),
                            default_currency
                          )
                    }
                  />
                </InfoBlockSpacer>
                <InfoBlockSpacer>
                  <InfoBlockExtraSmall
                    header={t(`Order Limit USD`)}
                    content={
                      user?.order_threshold_local === null
                        ? t("Unlimited")
                        : formatPrice(
                            Number(user?.order_threshold_local),
                            default_currency
                          )
                    }
                  />
                </InfoBlockSpacer>
              </>
            )}
          </UserPreferencesGrayBox>
        )}
      </UserContainer>
      {totp_required && (
        <UserContainer>
          <UserPreferencesGrayBox>
            <H3>{t("Preferred 2FA Type")}</H3>
            <DesktopFormContainer>
              <Form noValidate onSubmit={handle2FASubmit(on2FASubmit)}>
                <Controller
                  as={SelectBoxV2}
                  control={control2FA}
                  name="preferred_2fa_type"
                  label={t("Preferred 2FA Method")}
                  placeholder={t("Preferred 2FA Method")}
                  isClearable
                  defaultValue={
                    user.preferred_2fa_type
                      ? getTwoFactorOption(user.preferred_2fa_type)
                      : null
                  }
                  id="preferred2faTypeBox"
                  options={TwoFactorOptions}
                  formState={formState}
                  errors={errors}
                />
                <PrimaryButtonMedium type="submit" loading={isUpdating2FA}>
                  {t("Save your changes")}
                </PrimaryButtonMedium>
              </Form>
            </DesktopFormContainer>
          </UserPreferencesGrayBox>
        </UserContainer>
      )}
      {edition !== "pim" && (
        <UserContainer>
          <UserPreferencesGrayBox>
            <ChatEmailNotification user={user} />
          </UserPreferencesGrayBox>
        </UserContainer>
      )}
      {email_preferences_enabled && (
        <UserContainer>
          <UserPreferencesGrayBox>
            <SelfAndTeamActionEmailNotification user={user} />
          </UserPreferencesGrayBox>
        </UserContainer>
      )}
    </>
  );
});
