import type { ChangeEvent, MouseEvent } from "react";
import React, { useState, useEffect, useContext } from "react";
import useSWR from "swr";
import { Table } from "../../../components/Table/Table";
import type {
  Lead,
  LeadStatus,
  User,
  QuoteRequestLeadPaginatedOutput,
} from "../../../types/types";
import { Pagination } from "../../../components/Pagination/Pagination";
import { useHistory } from "react-router-dom";
import {
  makeUrlWithParams,
  useInAppNotifications,
  useStoreState,
  rowHover,
  convertStringArrayToObj,
  formatDate,
} from "../../../util/util";
import { formateInternationalPhoneNumber } from "../../../util/phone";
import { StatusRight } from "../../../components/Status/Status";
import { Auth } from "../../../components/Auth";
import { LeadStatusFilters, getLeadStatusDetails } from "./leadsUtilities";
import type { AxiosError } from "axios";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { useTranslation } from "react-i18next";
import { FiltersDropDown } from "../../../components/FiltersDropDown/FiltersDropDown";
import type { FiltersDropDownItem } from "../../../components/FiltersDropDown/FiltersDropDown";
import { useDebounce } from "../../../util/hooks";
import { FiltersWrapper } from "../../../layout/portalPageLayout";
import { SearchBar } from "../../../components/SearchBar/SearchBar";

type QuoteRequestsTableData = {
  id: string;
  companyName: string;
  fullName: string;
  email: string;
  phone: string;
  received: string;
};

/**
 * Shows a list of quote requests, as on the Leads > Quote Request page for
 * seller admins.
 */
export const QuoteRequestsList = ({ perPage }: { perPage: number }) => {
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    status: ArrayParam,
    q: StringParam,
  });

  const [offset, setOffset] = useState(query?.offset ?? 0);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const history = useHistory();

  const [selectedStatus, setSelectedStatus] = useState<FiltersDropDownItem[]>(
    []
  );
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [selectedStatusList, setSelectedStatusList] = useState<string[]>(
    (query?.status?.filter((status) => !!status) as string[]) ?? []
  );

  const { data: leadsResponse, error: leadsError } = useSWR<
    QuoteRequestLeadPaginatedOutput,
    AxiosError
  >(
    makeUrlWithParams(`/v1/storefronts/${storefront_id}/leads`, {
      offset: offset,
      limit: perPage,
      status: selectedStatusList,
      q: debouncedSearchQuery,
    })
  );

  const isLoading = !leadsResponse && !leadsError;
  const { user } = useContext(Auth);
  const { notifications } = useInAppNotifications(storefront_id, user as User);
  // Table
  const [tableData, setTableData] = useState<QuoteRequestsTableData[]>([]);

  // Check storefront settings
  const [showRegion, setShowRegion] = useState(false);
  useEffect(() => {
    if (leadsResponse) {
      // Check if "region" field is present in any of the sample requests
      const hasRegion = leadsResponse.data.some((lead) => "region" in lead);
      setShowRegion(hasRegion);
    }
  }, [leadsResponse]);

  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Customer Name"),
        accessor: "fullName",
      },
      {
        Header: t("Company Name"),
        accessor: "companyName",
      },
      {
        Header: t("Email"),
        accessor: "email",
      },
      ...(showRegion
        ? [
            {
              Header: t("Region"),
              accessor: "region",
            },
          ]
        : []),
      {
        Header: t("Date Received"),
        accessor: "received",
      },
      {
        Header: t("Assignee"),
        accessor: "assignee",
      },
      {
        Header: t("Status"),
        accessor: "status",
        align: "right",
        Cell: ({ value: status }: { value: LeadStatus }) => {
          const { text, color } = getLeadStatusDetails(status, t);
          return <StatusRight text={text} color={color} />;
        },
      },
    ],
    [t, showRegion]
  );

  useEffect(() => {
    setQuery({ offset, status: selectedStatusList });
    const statusObj = convertStringArrayToObj(selectedStatusList);
    setSelectedStatus(
      LeadStatusFilters(t).filter((item) => !!statusObj[item.value])
    );
  }, [offset, perPage, selectedStatusList, setQuery, t]);

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, query, debouncedSearchQuery]);

  useEffect(() => {
    const getUnread = (lead: Lead): boolean => {
      return notifications?.leads.quote_requests.ids
        ? notifications?.leads.quote_requests.ids.includes(lead?.id)
        : false;
    };
    const handleQuotesData = ({
      data,
      pagination,
    }: QuoteRequestLeadPaginatedOutput) => {
      setTableData(
        data.map((lead: Lead) => ({
          id: lead.id,
          companyName: lead.buyer_company_name || "--",
          fullName: `${lead.buyer_first_name} ${lead.buyer_last_name}` || "--",
          assignee: lead.assignee
            ? `${lead.assignee?.firstname} ${lead.assignee?.lastname}`
            : "--",
          email: lead.buyer_email || "--",
          phone: lead.buyer_phone_number
            ? formateInternationalPhoneNumber(lead.buyer_phone_number)
            : "--",
          received: formatDate(lead.created_at),
          status: lead.status,
          unread: getUnread(lead),
          region: lead?.region || "--",
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (leadsResponse) {
      const { data: leads, pagination } = leadsResponse;
      handleQuotesData({ data: leads, pagination });
    }
  }, [leadsResponse, setTableData, perPage, notifications, showRegion]);

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const applyStatus = (items: FiltersDropDownItem[]) => {
    setSelectedStatus(items);
    setSelectedStatusList(items.map((item) => item.value));
    changePage(0);
  };

  const handleRowClick = (e: MouseEvent) => {
    history.push(
      makeUrlWithParams(`leads/${e.currentTarget.id}`, { offset, perPage })
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  return (
    <>
      <FiltersWrapper>
        <FiltersDropDown
          activeItems={selectedStatus}
          applyStatus={applyStatus}
          list={LeadStatusFilters(t)}
        />
        <SearchBar
          query={searchQuery}
          placeHolder={t("Search by Company Name, Customer or Assignee")}
          handleChange={handleSearch}
          handleClearInput={handleClearSearch}
        />
      </FiltersWrapper>
      <Table
        columns={tableColumns}
        data={tableData}
        isLoading={isLoading}
        error={leadsError}
        rowClick={handleRowClick}
        rowHover={rowHover}
      />
      <Pagination
        pagination={tablePagination}
        offset={offset}
        handlePageClick={changePage}
      />
    </>
  );
};
