import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components/macro";
import { Form, SubmitButtonContainer } from "../../../layout/FormLayout";
import { TextField } from "../../../components/TextFields/TextFields";
import { TextArea } from "../../../components/TextArea/TextArea";
import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import type {
  DataMutate,
  SEODetail,
  UserConfigurableSEODetails,
} from "../../../types/types";
import Axios from "axios";
import { useStoreState, useFormWrapper } from "../../../util/util";
import { useTranslation } from "react-i18next";
import type { UserConfigurableSEO } from "./SEO";
import { getSEOUrl } from "./SEO";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  width: 50%;
  & > * {
    margin-bottom: 20px;
  }
`;

interface IEditSEO<T> {
  id?: string;
  seoDetail: SEODetail;
  setEdit: (args: boolean) => void;
  fetchData: DataMutate<T>;
  seoType: UserConfigurableSEO;
}

export const EditSEO = <T extends object>({
  id,
  seoDetail,
  setEdit,
  fetchData,
  seoType,
}: IEditSEO<T>) => {
  const { handleSubmit, register, formState, errors, setValue } =
    useFormWrapper({
      mode: "onSubmit",
      reValidateMode: "onChange",
    });
  const [loading, setLoading] = useState(false);
  const { storefront_id } = useStoreState();
  const { t } = useTranslation();
  const onSubmit = async (data: UserConfigurableSEODetails) => {
    setLoading(true);
    try {
      await Axios.put(getSEOUrl(seoType, storefront_id, id), data);
      setLoading(false);
      setEdit(false);
      fetchData();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const setFormValues = useCallback(() => {
    setValue("page_title", seoDetail?.page_title ?? "");
    setValue("meta_title", seoDetail?.meta_title ?? "");
    setValue("meta_keywords", seoDetail?.meta_keywords ?? "");
    setValue("meta_description", seoDetail?.meta_description ?? "");
  }, [seoDetail, setValue]);

  useEffect(() => {
    setFormValues();
  }, [setFormValues]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <TextField
          name="page_title"
          label={t("Page Title")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextField
          name="meta_title"
          label={t("Meta Title")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <TextArea
          name="meta_keywords"
          label={t("Meta Keywords")}
          type="text"
          required={false}
          formState={formState}
          theref={register({
            required: false,
          })}
        />
        <TextArea
          name="meta_description"
          label={t("Meta Description")}
          type="text"
          required={false}
          formState={formState}
          theref={register({
            required: false,
          })}
        />
        <SubmitButtonContainer>
          <PrimaryButtonFitContainer loading={loading}>
            {t("Save your changes")}
          </PrimaryButtonFitContainer>
        </SubmitButtonContainer>
      </InputWrapper>
    </Form>
  );
};
