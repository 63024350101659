import React from "react";
import { providePrivatePageProps } from "../../../util/Routing";
import { SellerQuoteDetailPage } from "../../SharedPages/SellerQuoteDetailPage/SellerQuoteDetailPage";

/**
 * The "Quote Detail" page showing a single quote request from a buyer user
 * (not from a guest user), accessible from the "Quotes" listing page.
 */
export const SellerQuoteDetail = providePrivatePageProps(({ user }) => {
  return <SellerQuoteDetailPage user={user} />;
});
