import { zodResolver } from "@hookform/resolvers/zod";
import type { AxiosError } from "axios";
import axios from "axios";
import debounce from "lodash/debounce";
import React, { useCallback, useContext, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { AsyncSearchSelect } from "../../../../components/AsyncSearchSelect/AsyncSearchSelect";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  SecondaryButtonWithPlusIcon,
} from "../../../../components/Buttons/Buttons";
import { SectionTitle } from "../../../../components/Form/Form";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { endpoints } from "../../../../endpoints";
import { Form } from "../../../../layout/FormLayout";
import { SelectWithDeleteButton } from "../../../../layout/shared";
import type { UUID, WithPagination } from "../../../../types/types";
import type { PIMProduct } from "../../../../types/types.PIM";
import { findNonEmptyDuplicateIndexes } from "../../../../util/form.utils";
import {
  TEMPORARY_HIGH_LIMIT,
  toTitleCase,
  useFormWrapper,
  useStoreState,
} from "../../../../util/util";
import {
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../util/zod.util";
import {
  MarginBottomH6,
  MarginBottomHeaderLeft,
} from "../../../admin/SellerAdmin/PIM/SellerAdminPIMAttributes/CreateAttribute";

const AddCustomerSchema = z.object({
  first_customer: zodSelectBoxType,
  collection: z
    .object({
      customer: zodSelectBoxType,
    })
    .array()
    .optional(),
});

const AddCustomerSchemaFn = (t: (s: string) => string, customers: string[]) =>
  z
    .object({
      first_customer: zodSelectBoxDefault(t).refine(
        ({ value }) =>
          findNonEmptyDuplicateIndexes([value, ...customers]).length === 0,
        t("Duplicate customers not allowed")
      ),
      collection: z
        .object({
          customer: zodSelectBoxDefault(t),
        })
        .array()
        .optional(),
    })
    .superRefine(({ first_customer, collection }, ctx) => {
      const duplicateIdxs = collection
        ? findNonEmptyDuplicateIndexes([
            first_customer.value,
            ...customers,
            ...collection.map(({ customer: { value } }) => value),
          ])
        : [];
      if (duplicateIdxs.length > 0) {
        duplicateIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Duplicate customers not allowed"),
            path: [`collection[${idx - (customers.length + 1)}].customer`],
          });
        });
      }
    });

type FormOutput = z.infer<typeof AddCustomerSchema>;

export const AddCustomer = ({
  product: { id: productId },
  onSuccess,
  existingCustomers,
}: {
  product: PIMProduct;
  onSuccess: () => Promise<void>;
  existingCustomers: string[];
}) => {
  const [loading, setLoading] = useState(false);

  const { tenant_id, storefront_id } = useStoreState();

  const { notifySuccess, notifyError } = useContext(Notifications);

  const { t } = useTranslation();

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(AddCustomerSchemaFn(t, existingCustomers)),
  });

  const { handleSubmit, control, formState, errors } = methodsOfUseForm;

  const { fields, remove, append } = useFieldArray({
    control,
    name: "collection",
  });

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const handleCustomerSearch = useCallback(
    debounce((query, setOptions) => {
      const getOptions = async (query: string) => {
        try {
          const {
            data: { data },
          } = await axios.get<
            WithPagination<{ data: { name: string; id: UUID }[] }>
          >(
            endpoints.v2_storefronts_id_tenants_id_tenants_basic(
              storefront_id,
              tenant_id
            ),
            {
              params: { limit: TEMPORARY_HIGH_LIMIT, q: query, order: "asc" },
            }
          );
          return data.map((tenant) => ({
            label: toTitleCase(tenant.name),
            value: tenant.id,
          }));
        } catch (error) {
          notifyError(
            t("Could not fetch customers. Something went wrong.", { error })
          );
          return [];
        }
      };
      getOptions(query).then((options) => setOptions(options));
    }, 1000),
    []
  );

  const createFields = (): JSX.Element[] => {
    return fields.map((field, index: number) => (
      <SelectWithDeleteButton key={field.id}>
        <Controller
          key={field.id}
          as={AsyncSearchSelect}
          control={control}
          name={`collection[${index}].customer`}
          defaultValue={{ label: "", value: "" }}
          placeholder={t("Search by company name or primary contact email id")}
          searchFunction={handleCustomerSearch}
          errors={{
            [`collection[${index}].customer`]:
              errors?.collection?.[index]?.customer?.value ??
              errors?.collection?.[index]?.customer ??
              undefined,
          }}
          formState={formState}
          defaultOptions
        />
        <DeleteButton
          testid={`delete-button-${index}`}
          onClick={() => remove(index)}
          type="button"
          height={20}
          width={20}
        />
      </SelectWithDeleteButton>
    ));
  };

  const onSubmit = async ({
    collection: otherCustomers,
    first_customer,
  }: FormOutput) => {
    const customers = otherCustomers
      ? [
          first_customer.value,
          ...otherCustomers.map(({ customer: { value } }) => value),
        ]
      : [first_customer.value];
    setLoading(true);
    try {
      await axios.patch<PIMProduct>(
        endpoints.v2_tenants_id_products_id_privacy(tenant_id, productId),
        {
          is_accessible_by_all_customers_and_distributors: false,
          tenants_to_add: customers,
        }
      );
      notifySuccess(t("Customer(s) added successfully"));
      await onSuccess();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error modifying the collection"),
        {
          error,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Add New")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <MarginBottomH6>{t("Search for your customers")}</MarginBottomH6>
        <div style={{ width: "420px" }}>
          <Controller
            as={AsyncSearchSelect}
            control={control}
            name="first_customer"
            placeholder={t(
              "Search by company name or primary contact email id"
            )}
            defaultValue={{ label: "", value: "" }}
            searchFunction={handleCustomerSearch}
            errors={errors}
            formState={formState}
            defaultOptions
          />
        </div>
        {createFields()}
        <SecondaryButtonWithPlusIcon
          type="button"
          style={{ marginBottom: "40px" }}
          onClick={() => append({ name: "" })}
        >
          {t("Add Company")}
        </SecondaryButtonWithPlusIcon>
        <PrimaryButtonFitContainer type="submit" loading={loading}>
          {t("Add")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
};
