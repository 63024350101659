import type { Dispatch, SetStateAction } from "react";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { GenericDialogBody } from "../../../../../../components/ConfirmDialog/ConfirmDialog";
import { H3 } from "../../../../../../components/Typography/Typography";
import { IDMobile } from "../../../../../../components/IDs/IDs";
import {
  ErrorMessageBox,
  InfoMessageBox,
  SuccessMessageBox,
} from "../../../../../../components/Form/Form";
import styled from "styled-components";
import { CheckIcon, InfoIcon } from "../../../../../../components/Icons/Icons";
import { PrimaryButtonMediumExtended } from "../../../../../../components/Buttons/Buttons";
import type { UploadNotification } from "../../../../../../types/types.PIM";
import { MessageContainer } from "../../../../../../components/ConfirmDialog/util";

const IDMobileInline = styled(IDMobile)`
  display: "inline";
  margin-left: "8px";
`;

const P = styled.p`
  text-align: center;
`;

const H3Flex = styled(H3)`
  display: flex;
  gap: 8px;
  margin: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const UploadInProgressDialog = ({
  uploadId,
  t,
  message,
  onComplete,
  show,
  title,
  hideCloseBtn = false,
  setShow,
}: {
  uploadId: string;
  t: TFunction;
  message: string | React.ReactNode;
  title?: string;
  onComplete: () => void;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  hideCloseBtn?: boolean;
}) => {
  const onCloseDialog = () => {
    onComplete();
    setShow(false);
  };
  return (
    <GenericDialogBody show={show} closeDialog={onCloseDialog}>
      <Container>
        <H3Flex>
          {title ?? t("Upload In Progress")}
          {uploadId && <IDMobileInline>{uploadId}</IDMobileInline>}
        </H3Flex>
        <InfoMessageBox>
          <MessageContainer>
            <InfoIcon />
            <P>{message}</P>
          </MessageContainer>
        </InfoMessageBox>
        {hideCloseBtn ? (
          <></>
        ) : (
          <PrimaryButtonMediumExtended
            style={{ marginTop: "8px" }}
            onClick={onCloseDialog}
          >
            {t("Close")}
          </PrimaryButtonMediumExtended>
        )}
      </Container>
    </GenericDialogBody>
  );
};

const UploadSuccessfulDialog = ({
  uploadId,
  t,
  message,
  show,
  title,
  onComplete,
  setShow,
}: {
  uploadId: string;
  t: TFunction;
  message: string | React.ReactNode;
  title?: string;
  onComplete: () => void;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const onCloseDialog = () => {
    onComplete();
    setShow(false);
  };
  return (
    <GenericDialogBody show={show} closeDialog={onCloseDialog}>
      <Container>
        <H3Flex>
          {title ?? t("Upload Successful")}
          <IDMobileInline style={{ display: "inline", marginLeft: "8px" }}>
            {uploadId}
          </IDMobileInline>
        </H3Flex>
        <SuccessMessageBox>
          <MessageContainer>
            <CheckIcon />
            <P>{message}</P>
          </MessageContainer>
        </SuccessMessageBox>
        <PrimaryButtonMediumExtended
          style={{ marginTop: "8px" }}
          onClick={onCloseDialog}
        >
          {t("Continue")}
        </PrimaryButtonMediumExtended>
      </Container>
    </GenericDialogBody>
  );
};

const UploadFailedDialog = ({
  uploadId,
  t,
  message,
  title,
  onComplete,
  show,
  setShow,
}: {
  uploadId: string;
  t: TFunction;
  title?: string;
  message: string | React.ReactNode;
  onComplete: () => void;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const onCloseDialog = () => {
    onComplete();
    setShow(false);
  };
  return (
    <GenericDialogBody show={show} closeDialog={onCloseDialog}>
      <Container>
        <H3Flex>
          {title ?? t("Upload Failed")}
          <IDMobileInline>{uploadId}</IDMobileInline>
        </H3Flex>
        <ErrorMessageBox>
          <MessageContainer>
            <InfoIcon />
            <P>{message}</P>
          </MessageContainer>
        </ErrorMessageBox>
        <PrimaryButtonMediumExtended
          style={{ marginTop: "8px" }}
          onClick={onCloseDialog}
        >
          {t("Continue")}
        </PrimaryButtonMediumExtended>
      </Container>
    </GenericDialogBody>
  );
};

export const UploadPopups = ({
  showDialog,
  onComplete,
  setShowDialog,
  message,
  title,
  status,
  uploadId,
  hideCloseBtn,
}: {
  showDialog: boolean;
  onComplete: () => void;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  title?: string;
  message: string | React.ReactNode;
  status: UploadNotification["status"];
  uploadId: string;
  hideCloseBtn?: boolean;
}) => {
  const { t } = useTranslation();
  const showUploadScreens = () => {
    switch (status) {
      case "in_progress":
        return (
          <UploadInProgressDialog
            show={showDialog}
            setShow={setShowDialog}
            message={message}
            title={title}
            onComplete={onComplete}
            uploadId={uploadId}
            hideCloseBtn={hideCloseBtn}
            t={t}
          />
        );
      case "successful":
        return (
          <UploadSuccessfulDialog
            show={showDialog}
            setShow={setShowDialog}
            message={message}
            title={title}
            uploadId={uploadId}
            onComplete={onComplete}
            t={t}
          />
        );
      case "failed":
        return (
          <UploadFailedDialog
            show={showDialog}
            setShow={setShowDialog}
            message={message}
            title={title}
            onComplete={onComplete}
            uploadId={uploadId}
            t={t}
          />
        );
    }
  };
  return <>{showUploadScreens()}</>;
};
