import styled from "styled-components/macro";

export const HomePageContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  white-space: pre-wrap;
`;

export const ShadedWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.secondaryBG};
`;
