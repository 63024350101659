import { InvisibleButton } from "../Buttons/Buttons";
import type {
  TagClassificationConfig,
  Filters,
  ProductFiltersMainSchema,
  SupportedLanguage,
} from "../../types/types";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import useSWR from "swr";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";
import type { ChipType } from "../Chips/Chips";
import { endpoints } from "../../endpoints";
import {
  convertToChipArray,
  getCustomLabel,
  useStoreState,
} from "../../util/util";
import { useCookies } from "react-cookie";
import noop from "lodash/noop";
import { FilterGroup } from "./FilterGroup";
import { CaretDownIcon, FiltersIcon } from "../Icons/Icons";
import { SearchBar } from "../SearchBar/SearchBar";
import {
  applyFiltersToURL,
  groupPortfolioParamsByKey,
} from "../../pages/public";
import { useLocation } from "react-router-dom";
import { screenSize } from "../../theme";
import { stringify } from "querystring";

export function chipsToStringArray(chips: ChipType[]) {
  return chips.map((chip) => chip.name);
}

interface IFiltersFlyBarWrapperProps {
  show: boolean;
}

interface IsearchFilterItem {
  type: string;
  label: string;
}

const FiltersFlyBarWrapper = styled.div<IFiltersFlyBarWrapperProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ show }) => (show ? "380px" : "30px")};
  @media ${screenSize.medium} {
    width: ${({ show }) => (show ? "330px" : "30px")};
    position: ${({ show }) => (show ? "sticky" : "absolute")};
    top: 1px;
    height: ${({ show }) => (show ? "100%" : "auto")};
    background: ${({ theme }) => theme.primaryBG};
    z-index: 3;
  }
`;

const FiltersHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  padding: 8px 10px 13px;
`;

const FiltersHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  justify-content: space-between;
  padding: 3px 0px 3px 0;
  align-items: center;
  button {
    display: flex;
    padding: 5px;
    margin-right: -3px;
    &:hover {
      transform: scale(1.2);
    }
  }
  svg {
    transform: rotate(90deg);
  }
`;

const SearchBarWrapper = styled.div`
  padding: 0 0px 0 0;
  position: relative;
  input {
    border-radius: 4px;
    background: ${({ theme }) => theme.primaryBG};
  }
  svg {
    left: 7px;
    path {
      fill: #000;
    }
  }
`;

const FiltersWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.primaryBorder};
  position: relative;
  margin-right: 20px;
`;

const FilterButtonWrapper = styled.div`
  transition: all 0.3s ease-in;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-right: 0;
  border-radius: 4px 0 0 4px;
  display: block;
  position: relative;
  padding: 5px 6px;
  left: -50px;
  top: 26px;
  width: 90px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  transform: rotate(-90deg);
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  svg {
    transform: rotate(90deg);
    margin: 0 6px;
  }
  @media ${screenSize.medium} {
    left: -25px;
  }
`;
const EmptyFiltersPlaceholder = styled.div`
  width: 100%;
  height: 30px;
  margin: 0 0 35px;
`;

const SearchResultsWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 0px;
  max-height: 300px;
  overflow: auto;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 4px;
  box-shadow: 2px 2px 2px #00000020;
  background: ${({ theme }) => theme.primaryBG};
  font-size: ${({ theme }) => theme.fontSizes.small};
  z-index: 2;
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 10px;
      list-style: none;
      font-size: ${({ theme }) => theme.fontSizes.small};
      transition: all 200ms ease;
      &:hover {
        background: ${({ theme }) => theme.primaryButtonHover};
        cursor: pointer;
      }
    }
  }
`;

type FilterFlyBarProps = {
  resetOffset?: React.Dispatch<React.SetStateAction<number>>;
  customLabels: TagClassificationConfig[];
  handleShowFilters: (show: boolean) => void;
  show: boolean;
  resetPage?: (search: string) => void;
};

export function FiltersFlyBar({
  resetOffset = noop,
  customLabels,
  handleShowFilters,
  show,
  resetPage,
}: FilterFlyBarProps) {
  // "ArrayParam" is how useQueryParams handles things like
  // ?market_segment=abc&market_segment=xyz
  // That URL will become ["abc", "xyz"]

  const [filtersFromUrl, setFiltersFromUrl] =
    useState<{ [key: string]: string[] }>();

  const { search } = useLocation();
  const [pageFilters, setPageFilters] = useState<Filters>();
  const [searchResults, setSearchResults] = useState<IsearchFilterItem[]>();
  const [showSearchResults, setShowSearchResults] = useState(false);

  const { storefront_id, slug: tenantSlug } = useStoreState();

  const [filtersSearchQuery, setFiltersSearchQuery] = useState("");

  const queryObject = () => {
    const querylist: any = {
      active_tag_view: StringParam,
      q: StringParam,
    };
    if (pageFilters) {
      for (const [key] of Object.entries(pageFilters)) {
        querylist[key] = ArrayParam;
      }
    }
    return querylist;
  };

  const [query, setQuery] = useQueryParams(queryObject());

  const { t } = useTranslation();

  const [cookies] = useCookies([`preferred-language-${tenantSlug}`]);

  const preferredLanguage: SupportedLanguage | undefined = cookies[
    `preferred-language-${tenantSlug}`
  ] as SupportedLanguage;

  useEffect(() => {
    const params = new URLSearchParams(search.substring(1));
    const filters = groupPortfolioParamsByKey(params);
    setFiltersFromUrl(filters);
  }, [search]);

  const { data, error: filtersError } = useSWR<ProductFiltersMainSchema, Error>(
    `${endpoints.v2_storefronts_id_products_filters(
      storefront_id
    )}?${applyFiltersToURL({
      filtersFromUrl,
      active_tag_view: query.active_tag_view,
      q: query.q ?? undefined,
      params: new URLSearchParams(),
    })}`
  );
  const filters = data?.filters;

  const handleFilter = ({
    values,
    filter,
  }: {
    values?: ChipType[];
    filter: string;
  }) => {
    if (values) {
      const filtersArray = chipsToStringArray(values);
      if (resetPage) {
        const updatedQuery = query;
        for (const [key, value] of Object.entries(updatedQuery)) {
          if (!value) {
            delete updatedQuery[key];
          }
        }
        resetPage(
          `?${stringify({
            active_tag_view: filter,
            ...updatedQuery,
            ...(filtersArray.length > 0 ? { [filter]: filtersArray } : {}),
          })}`
        );
      }
    } else {
      if (resetPage) {
        const updatedQuery = query;
        for (const [key, value] of Object.entries(updatedQuery)) {
          if (!value) {
            delete updatedQuery[key];
          }
        }
        delete updatedQuery[filter];
        if (!query.q) {
          delete updatedQuery.q;
        }
        resetPage(`?${stringify(updatedQuery)}`);
      }
    }
    resetOffset(0);
  };

  const handleChange = (val: React.ChangeEvent<HTMLInputElement>) => {
    if (val.target.value) {
      setShowSearchResults(true);
    }
    setFiltersSearchQuery(val.target.value);
  };

  const handleClearInput = () => setFiltersSearchQuery("");

  const getFilterName = (filter: IsearchFilterItem) => {
    if (
      filtersSearchQuery.length > 0 &&
      filter.label.toLowerCase().indexOf(filtersSearchQuery.toLowerCase()) > -1
    ) {
      const start = filter.label
        .toLowerCase()
        .indexOf(filtersSearchQuery.toLowerCase());
      const end =
        filter.label.toLowerCase().indexOf(filtersSearchQuery.toLowerCase()) +
        filtersSearchQuery.length;
      return (
        <>
          {filter.label.substring(0, start)}
          <b>{filter.label.substring(start, end)}</b>
          {filter.label.substring(end, filter.label.length)}
        </>
      );
    }
    return filter.label;
  };

  const handleFilterClick = (filter: { type: string; label: string }) => {
    // @ts-ignore
    if (!query[filter.type]?.includes(filter.label)) {
      setQuery({
        [filter.type]:
          filtersFromUrl && filtersFromUrl[filter.type]
            ? [...filtersFromUrl[filter.type], filter.label]
            : [filter.label],
      });
      resetOffset(0);
    }
  };

  const wrapperRef = useRef(document.createElement("div"));

  useEffect(() => {
    const handleClickOutsideSearchResult = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        showSearchResults
      ) {
        setShowSearchResults(false);
      }
    };
    document.addEventListener("click", handleClickOutsideSearchResult, false);
    return () => {
      document.removeEventListener(
        "click",
        handleClickOutsideSearchResult,
        false
      );
    };
  }, [showSearchResults]);

  useEffect(() => {
    if (filters) {
      const filters_by_custom_labels: Filters = {};
      customLabels.forEach(({ filter_type }) => {
        if (filters[filter_type] && filters[filter_type].length > 0) {
          filters_by_custom_labels[filter_type] = filters[filter_type];
        }
      });
      setPageFilters(filters_by_custom_labels);
    }
  }, [customLabels, filters]);

  useEffect(() => {
    let filtersList: IsearchFilterItem[] = [];
    if (pageFilters) {
      for (const [key, value] of Object.entries(pageFilters)) {
        value.forEach((item) => {
          if (!query[key]?.includes(item.name)) {
            filtersList.push({ type: key, label: item.name });
          }
        });
      }
      setSearchResults(
        filtersList.filter(
          (item) =>
            item.label
              .toLocaleLowerCase()
              .indexOf(filtersSearchQuery.toLocaleLowerCase()) > -1
        )
      );
    }
  }, [pageFilters, filtersSearchQuery, query]);

  // Could potentially display an error notification after multiple failed
  // attempts. That is not yet set up in this project.
  if (filtersError) {
    // Search still works if filters don't load.
    // This maintains the layout of the page in case of error.
    return <EmptyFiltersPlaceholder />;
  } else {
    // Handle case that happened once on staging where all filters were empty arrays.
    // This might theoretically be possible if a tenant is activated and no filters
    // are added.

    return (
      <FiltersFlyBarWrapper show={show}>
        {!show && pageFilters && Object.entries(pageFilters).length > 0 && (
          <FilterButtonWrapper>
            <InvisibleButton onClick={() => handleShowFilters(true)}>
              {t("Filters")}
              <FiltersIcon width={16} />
            </InvisibleButton>
          </FilterButtonWrapper>
        )}
        {show && (
          <FiltersWrapper>
            <FiltersHeaderWrapper>
              <FiltersHeader>
                <span>{t("Filters")}</span>
                <InvisibleButton onClick={() => handleShowFilters(false)}>
                  <CaretDownIcon width={16} height={16} />
                </InvisibleButton>
              </FiltersHeader>
              <SearchBarWrapper ref={wrapperRef}>
                <SearchBar
                  placeHolder={t("Search for filters")}
                  query={filtersSearchQuery}
                  handleChange={handleChange}
                  handleClearInput={handleClearInput}
                  onFocus={() => {
                    if (searchResults?.length && filtersSearchQuery.length) {
                      setShowSearchResults(true);
                    }
                  }}
                />
                {showSearchResults && filtersSearchQuery.length > 0 && (
                  <SearchResultsWrapper>
                    <ul>
                      {searchResults?.map((result) => (
                        <li
                          onClick={() => {
                            handleFilterClick(result);
                          }}
                        >
                          {getCustomLabel({
                            filter_type: result.type,
                            tag_classification_configs: customLabels,
                            preferred_language: preferredLanguage,
                          })}{" "}
                          {` > `}
                          {getFilterName(result)}
                        </li>
                      ))}
                    </ul>
                  </SearchResultsWrapper>
                )}
              </SearchBarWrapper>
            </FiltersHeaderWrapper>

            {pageFilters &&
              Object.entries(pageFilters).map((FilterItem, index) => {
                return (
                  <FilterGroup
                    setter={handleFilter}
                    options={FilterItem[1]}
                    filters={pageFilters}
                    customLabels={customLabels}
                    expanded={
                      (index === 0 && !query.active_tag_view) ||
                      query.active_tag_view === FilterItem[0]
                    }
                    filterType={FilterItem[0]}
                    preselectedFilters={
                      convertToChipArray(
                        filtersFromUrl ? filtersFromUrl[FilterItem[0]] : []
                      ) ?? []
                    }
                  />
                );
              })}
          </FiltersWrapper>
        )}
      </FiltersFlyBarWrapper>
    );
  }
}
