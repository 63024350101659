import { TabPanel, TabPanels, Tabs } from "@reach/tabs";
import { useQuery } from "../../../../util/hooks";
import {
  ContentWrapper,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../layout/portalPageLayout";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Tab } from "../../../../components/ReachTabs/Tab";
import { TabList } from "../../../../components/ReachTabs/TabList";
import { NotificationsTab } from "./Notifications/NotificationsTab";
import { PortfolioControlsTab } from "./PortfolioControlsTab/PorfolioControlsTab";
// import { ResourcesTab } from "./ResourcesTab/ResourcesTab";
import { TermsTab } from "./TermsTab/TermsTab";
import { ThemeAndContentTab } from "./ThemeAndContentTab/ThemeAndContentTab";
import { TransactionsTab } from "./Transactions/TransactionsTab";
import { ProductSelectorTab } from "./ProductSelectorTab/ProductSelectorTab";
import { useStoreState } from "../../../../util/util";

export function SellerAdminSettings() {
  const { t } = useTranslation();
  const {
    storefront_metadata: { edition },
  } = useStoreState();
  const history = useHistory();
  const query = useQuery();
  const {
    storefront_metadata: { enable_product_selector },
  } = useStoreState();
  const [tabIndex, setTabIndex] = React.useState(() => {
    const maybeTabIndex = query.get("tab");
    if (maybeTabIndex) {
      return parseInt(maybeTabIndex);
      // 0 is index of Theme & Content
    } else return 0;
  });

  const handleTabsChange = (index: number) => {
    history.replace({
      search: `?tab=${index}`,
    });
    setTabIndex(index);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{t("Storefront Settings")}</PageTitle>
      </PageHeader>
      <ContentWrapper>
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          <TabList>
            <Tab>
              {/* This one wraps weird with the ampersand on a separate line. */}
              <div style={{ display: "inline-block", whiteSpace: "nowrap" }}>
                {t("Theme & Content")}
              </div>
            </Tab>
            <Tab>{t("Terms")}</Tab>
            <Tab>{t("Notifications")}</Tab>
            {edition !== "pim" && <Tab>{t("Transactions")}</Tab>}
            <Tab>{t("Portfolio Controls")}</Tab>
            {enable_product_selector && <Tab>{t("Product Selector")}</Tab>}
            {/* <Tab> 
              <SoldByIcon />
              {t("Resources")}
            </Tab> */}
          </TabList>
          <TabPanels>
            <TabPanel>
              <ThemeAndContentTab />
            </TabPanel>
            <TabPanel>
              <TermsTab />
            </TabPanel>
            <TabPanel>
              <NotificationsTab />
            </TabPanel>
            {edition !== "pim" && (
              <TabPanel>
                <TransactionsTab />
              </TabPanel>
            )}
            <TabPanel>
              <PortfolioControlsTab />
            </TabPanel>
            {enable_product_selector && (
              <TabPanel>
                <ProductSelectorTab />
              </TabPanel>
            )}
            {/* <TabPanel>
              <ResourcesTab />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </ContentWrapper>
    </PageWrapper>
  );
}
