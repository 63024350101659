import styled from "styled-components/macro";
import { PrimaryButtonMedium } from "../../../../components/Buttons/Buttons";

// Some shared items used in SellerAdminSettings components.

export const SaveButtonPrimaryMedium = styled(PrimaryButtonMedium)`
  margin-top: 22px;
  min-width: 180px;
`;

// For grouping several ColorInputContainer elements into a column.
export const ColorInputColumn = styled.div`
  margin-right: 50px;
  &:last-child {
    margin-right: 0px;
  }
`;

export const ColorInputContainer = styled.div`
  margin-bottom: 20px;
  & input {
    margin-top: 5px;
    width: 80px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
    cursor: pointer;
  }
  & input:disabled {
    cursor: default;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

export const FieldContainer = styled.div`
  min-width: 436px;
  /* flex-grow: 1; */
`;

export const ButtonContainer = styled.div`
  width: 24px;
  /* background: tan; */
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
