import type { DataMutate, IAddress, Tenant } from "../../../../../types/types";
import { useTranslation } from "react-i18next";
import { EditAddressForm } from "./EditAddressForm";

interface EditHQAddressFormProps {
  address: IAddress;
  setEdit: (arg: boolean) => void;
  tenantId: string;
  mutateTenant: DataMutate<Tenant>;
}

export const EditHQAddressForm = ({
  address,
  setEdit,
  tenantId,
  mutateTenant,
}: EditHQAddressFormProps) => {
  const { t } = useTranslation();

  const submit_form_inputs = {
    email_source: "manage company info Headquarter address edit",
    success_msg: t("Headquarter address has been updated successfully"),
  };

  return (
    <EditAddressForm
      address={address}
      setEdit={setEdit}
      tenantId={tenantId}
      mutateTenant={mutateTenant}
      submit_form_inputs={submit_form_inputs}
    />
  );
};
