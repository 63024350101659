import React, { useEffect, useState } from "react";
import { Loader } from "../Loader/Loader";

export function DelayedSpinner() {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    // I'm tempted to add a prop for delay
    // but I think the app will have a more consistent feel
    // if the delay is always the same.
    const timer = setTimeout(() => setShowSpinner(true), 750);

    return () => clearTimeout(timer);
  });

  return showSpinner ? <Loader isLoading={showSpinner} /> : null;
}
