import React, { useState, useContext, useEffect } from "react";
import { TextField } from "../../../../../../components/TextFields/TextFields";
import { FormItems, Form } from "../../../../../../layout/FormLayout";
import { useFormWrapper } from "../../../../../../util/util";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import type {
  CustomFont,
  StorefrontMetaData,
} from "../../../../../../types/types";
import { endpoints } from "../../../../../../endpoints";
import { Store } from "../../../../../../Store";
import { SaveButtonPrimaryMedium } from "../../shared";
import { makeNewTheme } from "../../../../../../util/themeUtils";
import { useTranslation } from "react-i18next";

interface FormValues {
  portalLogoUrl: string;
  publicLogoUrl: string;
  customFontFamily: string;
  favIconUrl: string;
}

export const EditAssets = ({
  portalLogoUrl,
  publicLogoUrl,
  customFontFamily,
  customFont,
  favIconUrl,
  closeEditAssetsSection,
  mutateStorefrontMetadata,
}: {
  portalLogoUrl?: string;
  publicLogoUrl?: string;
  customFontFamily?: string;
  customFont?: CustomFont | undefined;
  favIconUrl?: string;
  closeEditAssetsSection: () => void;
  mutateStorefrontMetadata: (
    data: StorefrontMetaData,
    shouldRevalidate: boolean
  ) => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);

  const { storeState, storeDispatch } = useContext(Store);
  const { storefront_id, storefront_metadata } = storeState;
  const branding = storefront_metadata.theme_object.branding;

  const [submitting, setSubmitting] = useState(false);

  const [warn, setWarn] = useState<{ message: string } | null>(null);

  const { register, handleSubmit, formState, errors } = useFormWrapper({
    defaultValues: {
      portalLogoUrl,
      publicLogoUrl,
      customFontFamily,
      favIconUrl,
    },
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (customFont && !customFontFamily) {
      setWarn({
        message: t(
          "Adding a google font will override the custom font uploaded to this storefront."
        ),
      });
    }
  }, [customFont, customFontFamily, t]);

  const onSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    try {
      const patchBody: Pick<StorefrontMetaData, "theme_object"> = {
        theme_object: {
          branding: {
            publicLogoUrl: formValues.publicLogoUrl,
            portalLogoUrl: formValues.portalLogoUrl,
            favIconUrl: formValues.favIconUrl,
            customFontFamily: formValues.customFontFamily,
            // Just pass through the existing values for other theme properties.
            brandColor: branding.brandColor,
          },
        },
      };

      const { data: returnedMetadata } = await Axios.patch<StorefrontMetaData>(
        endpoints.v1_storefronts_id_metadata(storefront_id),
        patchBody
      );

      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: returnedMetadata,
      });

      storeDispatch({
        type: "SET_THEME",
        payload: makeNewTheme(
          patchBody.theme_object.branding,
          storeState.tenant_id
        ),
      });

      notifySuccess(t("Changes saved"));
      setSubmitting(false);
      mutateStorefrontMetadata(returnedMetadata, false);
      closeEditAssetsSection();
    } catch (error) {
      notifyError(t("There was an error saving the changes"), { error });
      setSubmitting(false);
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormItems>
        <TextField
          label={t("Public Logo URL")}
          name="publicLogoUrl"
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <TextField
          label={t("Portal / Admin Logo URL")}
          name="portalLogoUrl"
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <TextField
          label={t("Browser Tab Icon (Favicon)")}
          name="favIconUrl"
          theref={register({ required: false })}
          errors={errors}
          formState={formState}
          type="text"
        />
        <TextField
          label={t("Google Font Family Name")}
          name="customFontFamily"
          theref={register({ required: false })}
          errors={errors}
          warningText={warn}
          formState={formState}
          type="text"
        />
      </FormItems>
      <SaveButtonPrimaryMedium loading={submitting}>
        {t("Save your changes")}
      </SaveButtonPrimaryMedium>
    </Form>
  );
};
