import React, { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { InfoBlockSmall } from "../../../components/InfoBlocks/InfoBlocks";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { endpoints } from "../../../endpoints";
import type {
  OptionType,
  Tenant,
  TransactionPlacedVia,
  UUID,
} from "../../../types/types";
import { useDebounce } from "../../../util/hooks";
import type {
  POCFormSchemaType,
  POCManualFormSchemaType,
} from "../../../util/util";
import {
  TEMPORARY_HIGH_LIMIT,
  addressToOption,
  convertUserToOptionTenantID,
  convertUserToOptionUserID,
  makeUrlWithParams,
  useStoreState,
} from "../../../util/util";
import { TransactionPlacedViaOptions } from "../SellerCreation.constants";
import { newShippingAddress } from "../../../components/quoteCart/cartUtils";
import type { RequestAddressSchemaType } from "../../public/RequestAddress/RequestAddress";

export type CustomerDetailsFormOutput = RequestAddressSchemaType & {
  buyer_id: OptionType<UUID>;
  sample_request_via: OptionType<TransactionPlacedVia>;
  shipping_address_id: OptionType<UUID>;
};

export interface CustomerDetailsPOCManualForm
  extends CustomerDetailsFormOutput,
    POCManualFormSchemaType {}

export interface CustomerDetailsPOCForm
  extends CustomerDetailsFormOutput,
    POCFormSchemaType {}

type CustomerDetailsFormProps = {
  isDisabled: boolean;
  setSelectedBuyer: React.Dispatch<React.SetStateAction<Tenant | null>>;
  setSelectedUser: React.Dispatch<
    React.SetStateAction<OptionType<string> | null>
  >;
  orderType?: "quote" | "order";
};

export function CustomerDetailsForm({
  isDisabled,
  setSelectedBuyer,
  setSelectedUser,
}: CustomerDetailsFormProps) {
  const { control, errors, formState, watch, setValue } =
    useFormContext<CustomerDetailsFormOutput>();
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { notifyError } = useContext(Notifications);
  const [selectedBuyerID, setSelectedBuyerID] = useState<string | null>(null);
  const [buyerSearchQuery, setBuyerSearchQuery] =
    useState<string | undefined>();
  const [debouncedBuyerSearchQuery] = useDebounce(buyerSearchQuery, 1000);

  const { data: fetchedBuyers } = useSWR(
    // TODO remove limit and make search only.
    makeUrlWithParams(endpoints.v1_storefronts_id_buyers(storefront_id), {
      limit: TEMPORARY_HIGH_LIMIT,
      q: debouncedBuyerSearchQuery,
    }),
    {
      onError: (error) => {
        notifyError(t("There was an error fetching the buyers"), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const buyersData = fetchedBuyers?.data;
  const buyersOptions = buyersData
    ? buyersData.map(convertUserToOptionTenantID)
    : [];
  const userOptions = buyersData
    ? buyersData.map(convertUserToOptionUserID)
    : [];
  const { buyer_id: buyerOption } = watch(["buyer_id"]);

  useEffect(() => {
    if (buyerOption && selectedBuyerID !== buyerOption.value) {
      setSelectedBuyerID(buyerOption.value);
      // Clear address because addresses are associated with specific buyers
      setValue("shipping_address_id", null);
    }
  }, [buyerOption, selectedBuyerID, setValue]);

  const user: OptionType<string> = userOptions.find(
    (user: { label: string }) => user.label === buyerOption?.value
  );
  const { data: fetchedCustomer, error: customerError } = useSWR<Tenant>(
    selectedBuyerID
      ? endpoints.v1_storefronts_id_tenants_id(storefront_id, selectedBuyerID)
      : null,
    {
      onError: (error) => {
        notifyError(t("There was an error fetching the customer information"), {
          error,
        });
      },
      onSuccess: (buyer) => {
        setSelectedBuyer(buyer);
        setSelectedUser(user);
      },
      // TODO replace with useNotifyOnce hook?
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const customer = fetchedCustomer;
  const customerName = customer?.name;
  const isLoadingCustomer = !fetchedCustomer && !customerError;

  const allShippingAddresses =
    customer?.addresses.filter((a) => a.type === "Warehouse") ?? [];

  const addressOptions = allShippingAddresses
    ? [...allShippingAddresses.map(addressToOption), newShippingAddress(t)]
    : [];

  const handleSearchBuyerInput = (value: string) => {
    value === "" ? setBuyerSearchQuery(undefined) : setBuyerSearchQuery(value);
  };

  // First get buyer tenant id, to fetch shipping addresses.
  return (
    <>
      {/* TODO: use paginated select search bar. */}
      <Controller
        as={SelectBoxV2}
        isSearchBar
        control={control}
        name="buyer_id"
        placeholder={t("Buyer Name")}
        options={buyersOptions}
        onInputChange={handleSearchBuyerInput}
        defaultValue={null}
        isDisabled={isDisabled}
        rules={{ required: true }}
        errors={errors}
        formState={formState}
      />
      <InfoBlockSmall
        header={t("Company")}
        content={
          customerName
            ? customerName
            : !selectedBuyerID
            ? "--"
            : isLoadingCustomer
            ? // Avoid flash of "--" when switching buyers in input above.
              ""
            : // Shouldn't get here.
              "--"
        }
      />
      <Controller
        as={SelectBoxV2}
        control={control}
        name="sample_request_via"
        placeholder={t("Sample Requested Via")}
        options={TransactionPlacedViaOptions(t)}
        defaultValue={null}
        isDisabled={isDisabled}
        rules={{ required: false }}
        errors={errors}
        formState={formState}
      />
      <Controller
        as={SelectBoxV2}
        control={control}
        name="shipping_address_id"
        placeholder={t("Shipping Address")}
        options={addressOptions}
        defaultValue={null}
        isDisabled={isDisabled}
        rules={{ required: true }}
        errors={errors}
        formState={formState}
      />
    </>
  );
}
