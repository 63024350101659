import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import type { IHeroData } from "./HeroSection";
import { Title } from "../Typography/Typography";
import { TextField } from "../TextFields/TextFields";
import { PrimaryButtonFitContainer } from "../Buttons/Buttons";
import { useSaveHomePage } from "../../pages/public/HomePage/useSaveHomePage";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { RadioButton } from "../RadioButton/RadioButton";
import { Card } from "../../layout/publicPageLayout";
import { FlexRow } from "../../layout/portalPageLayout";
import { FileCardUploading } from "../FileCard/FileCard";
import { FilePicker } from "../FilePicker/FilePicker";
import type { IProductImage } from "../../types/types";
import Axios from "axios";
import { isAxiosError, useFormWrapper, useStoreState } from "../../util/util";
import { Notifications } from "../Notifications/NotificationsContext";
import { ToggleGroup } from "../ToggleGroups/ToggleGroups";
import { z } from "zod";
import { useTranslation } from "react-i18next";

interface IHeroSectionEditProps {
  data: IHeroData;
  homePageData: IHomePageData;
  handleEditSubmit: () => void;
}

interface HeroSectionEditData {
  title: string;
  caption: string;
  imageUrl: string;
  titleColor: string;
  captionColor: string;
  contentAlign: string;
  textAlign: string;
  height: string;
  actionButtonText: string;
  hideActionButton: boolean;
}

type HAlign = "left" | "center" | "right";

const FormInputs = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 15px;
  }

  textarea {
    resize: vertical;
  }
`;

const InputContainer = styled.div`
  margin-top: 20px;
  span {
    display: inline-block;
    margin-right: 10px;
    text-align: right;
    min-width: 90px;
  }
  & input[type="color"] {
    width: 32px;
    height: 32px;
    border: 0;
    outline: none;
    border-spacing: 0;
    padding: 0;
    vertical-align: middle;
    cursor: pointer;
  }

  & input[type="range"] {
    width: 55%;
    vertical-align: middle;
    margin: 5px 10px;
  }
`;

const RadioButtonContainer = styled.div`
  display: inline-block;
  & label {
    margin-right: 25px;
  }
`;

type ImageSource = "Upload Image" | "Insert Image URL";
const TOGGLE_IMAGE_SOURCE: ImageSource[] = ["Upload Image", "Insert Image URL"];

export const EditHeroSection = ({
  data,
  homePageData,
  handleEditSubmit,
}: IHeroSectionEditProps) => {
  const component = homePageData.config.components.find(
    (component) => component.name === "HeroSection"
  );
  const [heroData, setHeroData] = useState<IHeroData>(component?.data);
  const [uploadingFile, setUploadingFile] = useState<File>();
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const [imageSource, setImageSource] = useState<ImageSource>("Upload Image");
  const { saveHomePage } = useSaveHomePage();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    title,
    caption,
    imageUrl,
    titleColor,
    captionColor,
    height,
    contentAlign,
    textAlign,
    actionButtonText,
    hideActionButton,
  } = heroData;
  const [selectedHeight, setHeight] = useState<string>(height || "350");
  const [selectedTextAlign, setTextAlign] = useState<HAlign>(
    textAlign || "left"
  );
  const [selectedContentAlign, setContentAlign] = useState<HAlign>(
    contentAlign || "left"
  );

  const { register, handleSubmit, formState, errors, setValue } =
    useFormWrapper({
      mode: "onChange",
      reValidateMode: "onChange",
    });

  const TOGGLE_ALIGN: HAlign[] = ["left", "center", "right"];

  useEffect(() => {
    setHeroData({
      title,
      caption,
      imageUrl,
      titleColor,
      captionColor,
      height: selectedHeight,
      contentAlign: selectedContentAlign,
      textAlign: selectedTextAlign,
      actionButtonText,
      hideActionButton,
    });
  }, [
    title,
    caption,
    imageUrl,
    titleColor,
    captionColor,
    height,
    selectedContentAlign,
    selectedHeight,
    selectedTextAlign,
    actionButtonText,
    hideActionButton,
  ]);

  const changeTextAlign = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value && isHAlign(e.currentTarget.value)) {
      setTextAlign(e.currentTarget.value);
    }
  };

  const changeContentAlign = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value && isHAlign(e.currentTarget.value)) {
      setContentAlign(e.currentTarget.value);
    }
  };

  const isHAlign = (alignName: string): alignName is HAlign => {
    return TOGGLE_ALIGN.includes(alignName as HAlign);
  };

  const changeImageSource = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    source: ImageSource
  ) => {
    event.preventDefault();
    setImageSource(source);
    setValue("imageUrl", imageUrl);
  };

  const handleFile = async (file: File) => {
    setUploadingFile(file);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await Axios.post<IProductImage>(
        `/v1/storefronts/${tenant_id}/images`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      setUploadingFile(undefined);
      setValue("imageUrl", response.data.cdn_url);
      notifySuccess(t("Image updated successfully"));
    } catch (error) {
      let notifyMessage = t("Upload failed, something went wrong");

      if (isAxiosError(error)) {
        const message = error.response?.data.message;
        if (message) {
          notifyMessage = t(`Upload failed. {{upload_error_message}}`, {
            upload_error_message: message,
          });
        }
      }

      notifyError(notifyMessage, { error });
      setUploadingFile(undefined);
    }
  };

  const onSubmit = async (values: HeroSectionEditData) => {
    setLoading(true);
    const newData = { ...values, textAlign, contentAlign, hideActionButton };

    if (component) {
      component.data = newData;
    } else {
      homePageData.config.components.push({
        name: "HeroSection",
        data: newData,
      });
    }

    const success = await saveHomePage(homePageData);
    if (success) {
      handleEditSubmit();
    }
    setLoading(false);
  };

  useEffect(() => {
    setValue("title", title);
    setValue("caption", caption);
    setValue("imageUrl", imageUrl);
    setValue("titleColor", titleColor);
    setValue("height", height);
    setValue("captionColor", captionColor);
    setValue("contentAlign", contentAlign);
    setValue("textAlign", textAlign);
    setValue("actionButtonText", actionButtonText);
    setValue("hideActionButton", hideActionButton);
  }, [
    setValue,
    title,
    caption,
    imageUrl,
    titleColor,
    captionColor,
    height,
    contentAlign,
    textAlign,
    actionButtonText,
    hideActionButton,
    imageSource,
  ]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Title>{t("Edit Hero Section")}</Title>
        <FormInputs>
          <TextField
            label={t("Title")}
            name="title"
            theref={register({ required: false })}
            errors={errors}
            formState={formState}
            type="text"
          />
          <TextField
            label={t("Second Line Title")}
            name="caption"
            type="text"
            required={false}
            errors={errors}
            formState={formState}
            theref={register({
              required: false,
            })}
          />
          <TextField
            label={t("Action Button Text")}
            name="actionButtonText"
            theref={register({ required: false })}
            errors={errors}
            formState={formState}
            type="text"
          />
          <Card>
            <ToggleGroup
              names={TOGGLE_IMAGE_SOURCE}
              activeButton={imageSource}
              clickHandler={changeImageSource}
              style={{ margin: "10px 0" }}
            />
            {!uploadingFile && (
              <TextField
                label={t("Hero Image URL")}
                name="imageUrl"
                theref={register({
                  required: false,
                  validate: (val: string) => {
                    const { success } = z.string().url().safeParse(val);
                    return success || t("must be a valid url");
                  },
                })}
                errors={errors}
                formState={formState}
                type="url"
                style={{ margin: "20px 0" }}
                disabled={imageSource === "Upload Image"}
              />
            )}
            {imageSource === "Upload Image" && (
              <>
                {!uploadingFile && <FilePicker handleFile={handleFile} />}
                {uploadingFile && (
                  <FileCardUploading fileName={uploadingFile.name} />
                )}
              </>
            )}

            <InputContainer>
              <span>{t("Height") + ":"}</span>
              350
              <input
                type="range"
                id="heroHeight"
                name="height"
                min="350"
                max="600"
                defaultValue={height}
                required={false}
                {...(register("height", {
                  required: false,
                }) as unknown as object)}
                onChange={(e) => {
                  setHeight(e.currentTarget.value);
                }}
              />
              600
            </InputContainer>
          </Card>
          <Card>
            <FlexRow rowItems={2} itemMargin={"0px"}>
              <InputContainer>
                <span>{t("Title Color") + ":"}</span>
                <input
                  type="color"
                  id="titleColor"
                  name="titleColor"
                  ref={register({ required: false })}
                />
              </InputContainer>
              <InputContainer>
                <span>{t("Second Title Color") + ":"}</span>
                <input
                  type="color"
                  id="captionColor"
                  name="captionColor"
                  ref={register({ required: false })}
                />
              </InputContainer>
            </FlexRow>
            <InputContainer>
              <span>{t("Content Align") + ":"}</span>
              <RadioButtonContainer>
                <RadioButton
                  name={"contentAlign"}
                  value="left"
                  checked={selectedContentAlign === "left"}
                  optionTitle="Left"
                  handleChange={changeContentAlign}
                />
                <RadioButton
                  name={"contentAlign"}
                  value="center"
                  checked={selectedContentAlign === "center"}
                  optionTitle="Center"
                  handleChange={changeContentAlign}
                />
                <RadioButton
                  name={"contentAlign"}
                  value="right"
                  checked={selectedContentAlign === "right"}
                  optionTitle="Right"
                  handleChange={changeContentAlign}
                />
              </RadioButtonContainer>
            </InputContainer>
            <InputContainer>
              <span>{t("Text Align")}:</span>
              <RadioButtonContainer>
                <RadioButton
                  name={"textAlign"}
                  value="left"
                  checked={selectedTextAlign === "left"}
                  optionTitle="Left"
                  handleChange={changeTextAlign}
                />
                <RadioButton
                  name={"textAlign"}
                  value="center"
                  checked={selectedTextAlign === "center"}
                  optionTitle="Center"
                  handleChange={changeTextAlign}
                />
                <RadioButton
                  name={"textAlign"}
                  value="right"
                  checked={selectedTextAlign === "right"}
                  optionTitle="Right"
                  handleChange={changeTextAlign}
                />
              </RadioButtonContainer>
            </InputContainer>
          </Card>
          <PrimaryButtonFitContainer loading={loading}>
            {t("Save your changes")}
          </PrimaryButtonFitContainer>
        </FormInputs>
      </form>
    </>
  );
};
