import { Loader } from "../../components/Loader/Loader";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import type { IPartnersData } from "../../components/Partners";
import { Partners } from "../../components/Partners";
import type {
  IHomePageTemplateProps,
  IHomePageComponent,
  IHomePageComponentName,
} from "../../pages/public/HomePage/HomePage";
import type { IAccordionData } from "../../components/DisplayAndEditAccordion/DisplayAccordion";
import { DisplayAndEditAccordion } from "../../components/DisplayAndEditAccordion/DisplayAndEditAccordion";
import type { ITagProps } from "../../components/MarketsWeServe";
import { MarketsWeServe } from "../../components/MarketsWeServe";
import type { IntroductionParagraphProps } from "../../components/IntroductionParagraph/IntroductionParagraph";
import { IntroductionParagraph } from "../../components/IntroductionParagraph/IntroductionParagraph";
import type { IAboutUsProps } from "../../components/AboutUs/AboutUs";
import { AboutUs } from "../../components/AboutUs/AboutUs";
import { HomePageSearch } from "../../pages/public/HomePage/HomePageSearch";
import { HeroComponent } from "../../components/HeroSection/HeroComponent";
import type { IHeroData } from "../../components/HeroSection/HeroSection";
import type { IFeaturedProductsData } from "../../components/FeaturedProducts/FeaturedProducts";
import { FeaturedProducts } from "../../components/FeaturedProducts/FeaturedProducts";
import { Auth } from "../../components/Auth";
import { HomePageContentWrapper, ShadedWrapper } from "./homepage.utils";

const AgilisHomeContainer = styled.div`
  margin: 0;
`;

export const Ag3Homepage = ({ homePageData }: IHomePageTemplateProps) => {
  const [heroData, setHeroData] = useState<IHeroData>();
  const [featuredProductsData, setFeaturedProductsData] =
    useState<IFeaturedProductsData>();
  const [partnersData, setPartnersData] = useState<IPartnersData>();
  const [accordionData, setAccordionData] = useState<IAccordionData>();
  const [tagsData, setTagsData] = useState<ITagProps>();
  const [introData, setIntroData] = useState<IntroductionParagraphProps>();
  const [aboutUsData, setAboutUsData] = useState<IAboutUsProps>();

  useEffect(() => {
    if (homePageData?.config) {
      const getComponentData = (componentName: IHomePageComponentName) => {
        return homePageData.config.components.find(
          (component: IHomePageComponent) => component.name === componentName
        )?.data;
      };

      setHeroData(getComponentData("HeroSection"));
      setFeaturedProductsData(getComponentData("featuredProducts"));
      setPartnersData(getComponentData("partners"));
      setAccordionData(getComponentData("accordion"));
      setTagsData(getComponentData("tags"));
      setIntroData(getComponentData("introduction"));
      setAboutUsData(getComponentData("about_us"));
    }
  }, [homePageData]);

  const { user } = useContext(Auth);
  const isEditable = user?.rbac_role === "seller_admin";
  return homePageData.config ? (
    <AgilisHomeContainer>
      {heroData && (
        <HeroComponent
          isEditable={isEditable}
          data={heroData}
          homePageData={homePageData}
        />
      )}
      <HomePageContentWrapper>
        <HomePageSearch />
      </HomePageContentWrapper>
      {introData && (
        <ShadedWrapper>
          <HomePageContentWrapper>
            <IntroductionParagraph
              homePageData={homePageData}
              isEditable={isEditable}
            />
          </HomePageContentWrapper>
        </ShadedWrapper>
      )}
      {featuredProductsData && (
        <HomePageContentWrapper>
          <FeaturedProducts
            homePageData={homePageData}
            isEditable={isEditable}
          />
        </HomePageContentWrapper>
      )}
      {tagsData && (
        <MarketsWeServe
          title={tagsData.title}
          content={tagsData.content}
          isEditable={isEditable}
          homePageData={homePageData}
        />
      )}
      {partnersData && (
        <HomePageContentWrapper>
          <Partners
            homePageData={homePageData}
            title={partnersData.title}
            desc={partnersData.desc}
            logos={partnersData.logos}
            isEditable={isEditable}
          />
        </HomePageContentWrapper>
      )}
      {aboutUsData && (
        <ShadedWrapper>
          <HomePageContentWrapper>
            <AboutUs homePageData={homePageData} isEditable={isEditable} />
          </HomePageContentWrapper>
        </ShadedWrapper>
      )}
      {accordionData && (
        <HomePageContentWrapper>
          <DisplayAndEditAccordion
            homePageData={homePageData}
            isEditable={isEditable}
          />
        </HomePageContentWrapper>
      )}
    </AgilisHomeContainer>
  ) : (
    <Loader isLoading />
  );
};
