import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useSWR from "swr";
import type { AxiosError } from "axios";
import Axios from "axios";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
  PrimaryButton,
} from "../../../../../components/Buttons/Buttons";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { Row } from "../../../../../components/Layout/Layout";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { H3 } from "../../../../../components/Typography/Typography";
import { SettingsCard } from "../../../../../layout/portalPageLayout";
import type { StorefrontFilterSetting } from "../../../../../types/types";
import { TablePlaceholder, useStoreState } from "../../../../../util/util";
import { SampleAndQuoteRequestSettings } from "./SampleAndQuoteRequestSettings";
import { Table } from "../../../../../components/Table/Table";
import type { PortfolioControlsSchema } from "../../../../../types/types.PIM";
import { PlusIcon } from "../../../../../components/Icons/Icons";
import { screenSize } from "../../../../../theme";
import { ToggleSwitchV2 } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { PortfolioControlForm } from "./PortfolioControlForm";
import ReactTooltip from "react-tooltip";
import { endpoints } from "../../../../../endpoints";
import { EnterpriseSearchSuggestionsSettings } from "./EnterpriseSearchSuggestionsSettings";

export const SearchFilterCaption = styled.p`
  margin: 8px 0 30px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.primaryTextColor};
`;

const IconGroup = styled.div`
  display: flex;
  gap: 20px;
  @media ${screenSize.small} {
    gap: 10px;
  }
  align-items: center;
`;

/**
 * Only the user modifiable properties of `StorefrontFilterSettings`, this type
 * is used as state in this component and `SearchAndFilterSettingsItem`
 */
export type ModifiableFilterSettings = Pick<
  StorefrontFilterSetting,
  "is_active" | "is_searchable" | "is_visible"
>;

/**
 * Requirements Stage 2:
 * This is used in the storefront settings page.
 * It is a new tab with title; "Search & Filter".
 * The seller admin can select tags and classifications that will appear as filters on the portfolio.
 * Under the “Search & Filter“ tab in the settings tab, there is a list of all tags (industry, market segment, application, function, value proposition) and classifications (product group 1, 2 and 3).
 * We also have a custom filter & search on a portfolio called Producers.
 * For each of these 9 filters, seller admin should be able to:
 * 1. Toggle ON/OFF the Filter & Search Criteria.
 * If filter option is ON, the filter will appear on portfolio. If unchecked, the filter will NOT appear on portfolio.
 * If search option is ON, the buyer shall be able to perform search on portfolio by the respective filter.
 * If search option is OFF, the respective filter cannot be used as a search criteria on the portfolio.
 *
 * @returns
 */

export const PortfolioControlsTab = () => {
  const [tableData, setTableData] = useState<PortfolioControlsSchema[]>([]);
  const [showSlideOut, setSlideOut] = useState(false);
  const [editingAttribute, setEditingAttribute] =
    useState<PortfolioControlsSchema>();
  const { t } = useTranslation();
  const { notifyError, notifySuccess } = useContext(Notifications);
  const {
    storefront_id,
    storefront_metadata: { default_language, edition, enable_vertex_ai_search },
  } = useStoreState();

  const {
    data: portfolioControlsData,
    error: portfolioControlsError,
    mutate: mutateControls,
  } = useSWR<{ data: PortfolioControlsSchema[] }>(
    `v2/storefronts/${storefront_id}/portfolio-controls`,
    {
      onSuccess: ({ data: controlsData }) => {
        setTableData(controlsData);
      },
      revalidateOnFocus: true,
    }
  );

  const { data: productSelectors } = useSWR<
    { selectors: { name: string; order: string }[] },
    AxiosError
  >(endpoints.v2_storefronts_id_product_selectors(storefront_id));

  const tableColumns = useMemo(() => {
    const deleteAttribute = async (attribute: PortfolioControlsSchema) => {
      try {
        await Axios.delete(
          `v2/storefronts/${storefront_id}/portfolio-controls/${attribute.id}`
        );
        mutateControls();
        notifySuccess(t("Attribute deleted successfully"));
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("There was an error deleting Attribute"),
          {
            error,
          }
        );
      }
    };

    const editAttribute = (attribute: PortfolioControlsSchema) => {
      setEditingAttribute(attribute);
      setSlideOut(true);
    };

    return [
      {
        Header: t("Attribute Name"),
        Cell: ({
          row: { original },
        }: {
          row: {
            original: PortfolioControlsSchema;
          };
        }) => {
          return <>{original.attribute?.name || "--"}</>;
        },
      },
      {
        Header: t(`Display Name (${default_language.toUpperCase()})`),
        Cell: ({
          row: { original },
        }: {
          row: {
            original: PortfolioControlsSchema;
          };
        }) => {
          return (
            <>{original.display_name ? t([original.display_name]) : "--"}</>
          );
        },
      },
      {
        Header: t("Search"),
        accessor: "",
        Cell: ({
          row: { original },
        }: {
          row: {
            original: PortfolioControlsSchema;
          };
        }) => {
          return (
            <ToggleSwitchV2
              name={`${original.id}_is_searchable`}
              label=""
              defaultChecked={original.is_searchable}
              disabled={true}
            />
          );
        },
      },
      {
        Header: t("Filter"),
        accessor: "",
        Cell: ({
          row: { original },
        }: {
          row: {
            original: PortfolioControlsSchema;
          };
        }) => {
          return original?.attribute?.is_filterable ? (
            <ToggleSwitchV2
              name={`${original.id}_is_filterable`}
              label=""
              defaultChecked={original.is_filterable}
              disabled={true}
            />
          ) : (
            <></>
          );
        },
      },
      {
        Header: "",
        accessor: " ",
        overflow: "visible",
        Cell: ({
          row: { original },
        }: {
          row: {
            original: PortfolioControlsSchema;
          };
        }) => {
          return (
            <>
              <IconGroup style={{ justifyContent: "flex-end" }}>
                <EditButton onClick={() => editAttribute(original)} />
                <div
                  data-for="delete-portfolio-control-item"
                  data-tip={
                    productSelectors?.selectors
                      ?.map((selector) => selector.name)
                      .indexOf(
                        original.attribute.new_object_type.toLocaleLowerCase()
                      ) !== -1
                      ? t(
                          "You cannot delete this attribute as it's used as a product selector"
                        )
                      : ""
                  }
                >
                  <ButtonWithConfirmDialog
                    testid={"delete-portfolio-control-item"}
                    disabled={
                      productSelectors?.selectors
                        ?.map((selector) => selector.name)
                        .indexOf(
                          original.attribute.new_object_type.toLocaleLowerCase()
                        ) !== -1
                    }
                    Button={DeleteButton}
                    handleConfirm={() => deleteAttribute(original)}
                    confirmMessage={t(
                      "Are you sure you want to remove this item?"
                    )}
                  />
                </div>
                <ReactTooltip id="delete-portfolio-control-item" place="top" />
              </IconGroup>
            </>
          );
        },
      },
    ];
  }, [
    t,
    default_language,
    notifyError,
    productSelectors,
    notifySuccess,
    storefront_id,
    mutateControls,
  ]);

  const closeSlideOut = () => {
    mutateControls();
    setSlideOut(false);
    setEditingAttribute(undefined);
  };

  if (portfolioControlsError) {
    return <ErrorPlaceholder message={t("Failed to fetch filter settings")} />;
  } else {
    return (
      <>
        {edition !== "pim" && <SampleAndQuoteRequestSettings />}
        {enable_vertex_ai_search && <EnterpriseSearchSuggestionsSettings />}
        <SettingsCard>
          <Row>
            <div>
              <H3 style={{ marginBottom: 0 }}>{t("Portfolio Controls")}</H3>
              <SearchFilterCaption>
                {t(
                  "Control the tags & classifications visibility, search and filter capabilities on Portfolio."
                )}
              </SearchFilterCaption>
            </div>
          </Row>
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={!portfolioControlsError && !portfolioControlsData}
            error={undefined}
            Placeholder={<TablePlaceholder message={t("No items to show.")} />}
          />
          {portfolioControlsData && (
            <PrimaryButton
              onClick={() => setSlideOut(true)}
              data-for="max-tooltip"
              data-tip={
                portfolioControlsData?.data &&
                portfolioControlsData?.data?.length > 14
                  ? t(
                      "A maximum of 15 attributes as search & filter parameters can be added"
                    )
                  : ""
              }
              style={{ marginTop: "20px", padding: "8px 10px" }}
              disabled={
                portfolioControlsData?.data &&
                portfolioControlsData?.data?.length > 14
              }
            >
              {" "}
              <ReactTooltip id="max-tooltip" place="right" />
              <PlusIcon width={16} height={16} />
              {t("Add")}
            </PrimaryButton>
          )}
          <SlideOut closeFlyout={closeSlideOut} show={showSlideOut}>
            <PortfolioControlForm
              attribute={editingAttribute}
              handleFormSubmit={closeSlideOut}
              portfolioControlsData={portfolioControlsData?.data}
              productSelectors={productSelectors?.selectors}
            />
          </SlideOut>
        </SettingsCard>
      </>
    );
  }
};
