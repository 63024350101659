import type {
  IOrderItem,
  QuoteRequestItem,
  SampleRequestDetail,
  TransactionDocument,
} from "../../../types/types";

const filterCompareFn = (kindKey: string, compareKey: string) =>
  // the regex replaces possible multiple spaces with a single space
  kindKey.replace(/\s+/g, " ").trim() === compareKey;

export const get_existing_documents = ({
  items,
  seller_or_buyer_documents,
}: {
  items: (IOrderItem | QuoteRequestItem | SampleRequestDetail)[];
  seller_or_buyer_documents: TransactionDocument[];
}) => [
  ...items.flatMap(({ product: { name }, documents }) =>
    documents
      .filter(
        (doc) =>
          filterCompareFn(
            doc.kind,
            "Technical Data Sheet (TDS)" || doc.kind === "tds"
          ) ||
          filterCompareFn(
            doc.kind,
            "Safety Data Sheet (SDS)" || doc.kind === "sds"
          )
      )
      .map((document) => ({
        product_name: name,
        is_all_product: false,
        ...document,
        language: document.language || "",
        should_delete: false,
      }))
  ),
  ...(seller_or_buyer_documents?.length > 0 ? seller_or_buyer_documents : []),
];
