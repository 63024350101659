import { PrimaryButtonMedium } from "../../components/Buttons/Buttons";
import { H3, H6, Title } from "../../components/Typography/Typography";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CenterContainer, PortfolioTitle } from "../../layout/publicPageLayout";
import { Auth } from "../../components/Auth";
import styled from "styled-components";

const LogoutLink = styled.button`
  color: ${(props) => props.theme.primaryLinkColor};
  display: inline-block;
  margin: 30px 5px;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

interface IUnauthorizedPageProps {
  tenantSlug: string;
}

export function UnauthorizedPage({ tenantSlug }: IUnauthorizedPageProps) {
  const history = useHistory();
  const { logout } = useContext(Auth);
  const handleClick = () => {
    history.push(`/store/${tenantSlug}`);
  };

  return (
    <>
      <CenterContainer>
        <PortfolioTitle>401</PortfolioTitle>
        <Title>Not Authorized</Title>
        <H3>Sorry, you don't have permission to access this page</H3>

        <PrimaryButtonMedium onClick={handleClick}>
          Go to Homepage
        </PrimaryButtonMedium>
        <H6>
          Or{" "}
          <LogoutLink
            onClick={() =>
              logout().then(() =>
                window.location.replace(`/store/${tenantSlug}/login`)
              )
            }
          >
            Sign out and login with another account
          </LogoutLink>
        </H6>
      </CenterContainer>
    </>
  );
}
