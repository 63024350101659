import { useState } from "react";
import { SettingsCard } from "../../../../layout/portalPageLayout";
import { H3 } from "../../../../components/Typography/Typography";
import {
  EditButton,
  CancelButtonSmall,
} from "../../../../components/Buttons/Buttons";
import styled from "styled-components/macro";
import {
  InfoBlockSmall,
  InfoBlockExtraSmall,
  InfoBlockRow,
  InfoBlockSpacer,
  InfoBlockColumn,
} from "../../../../components/InfoBlocks/InfoBlocks";
import { UserIcon } from "../../../../components/Icons/Icons";
import type {
  Tenant,
  ITenantCustomerSettings,
  User,
} from "../../../../types/types";
import { EditCustomerRelationForm } from "./EditCustomerRelationForm";
import useSWR from "swr";
import { endpoints } from "../../../../endpoints";
import { useStoreState, makeCurrencyLabel } from "../../../../util/util";
import { ContactBlockForUser } from "../../../../components/LocationsList/LocationsList";
import { ErrorPlaceholder } from "../../../../components/Error";
import { EditDefaultTermsForm } from "./EditDefaultTermsForm";
import { EditCurrencyForm } from "./EditCurrencyForm";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { ToggleSwitch } from "../../../../components/ToggleSwitch/ToggleSwitch";
import { EditTaxExemptedForm } from "./EditTaxExempted";
import { FileCard } from "../../../../components/FileCard/FileCard";
import { Row } from "../../../../components/Layout/Layout";
import { EditAccountSubscribers } from "./EditAccountSubscribers";
import { EditNotificationPreferencesForm } from "./EditNotificationPreferencesForm";

// Used in the components for editing the settings.
export const FormContainer = styled.div`
  max-width: 480px;
`;

// Used in the components for editing the settings.
export const SaveButtonContainer = styled.div`
  margin-top: 38px;
`;

/**
 * Renders the "Settings" tab content on the customer (buyer) detail page that
 * is accessible to logged in seller admins.
 */
export const CustomerSettings = ({
  buyerTenant,
  loggedInUser,
}: {
  buyerTenant: Tenant;
  loggedInUser: User;
}) => {
  const [editCustomerRelation, setEditCustomerRelation] = useState(false);
  const [editCustomerTaxExempted, setEditCustomerTaxExempted] = useState(false);
  const [editCurrency, setEditCurrency] = useState(false);
  const [editDefaultTerms, setEditDefaultTerms] = useState(false);
  const [editAccountSubscribers, setEditAccountSubscribers] = useState(false);
  const [editNotificationPreferences, setEditNotificationPreferences] =
    useState(false);

  const {
    storefront_id,
    api_metadata,
    storefront_metadata: { accepts_payments, edition },
  } = useStoreState();

  const { t } = useTranslation();

  // The logged in user will either be a (producer) seller admin
  // The logged in user's tenant will
  // thus be the seller and their tenant's ID is the seller ID.
  const seller_id = loggedInUser.tenant_id;

  const viewTaxDocument = async () => {
    if (taxDocument) {
      window.open(taxDocument.signed_url, "_blank");
    }
  };

  const {
    data: settingsData,
    error: settingsError,
    mutate: mutateSettings,
  } = useSWR<ITenantCustomerSettings>(
    endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
      storefront_id,
      seller_id,
      buyerTenant.id
    )
  );

  if (settingsError) {
    return (
      <ErrorPlaceholder message="There was a loading error, please try again." />
    );
  }
  if (!settingsData) {
    // Loading.
    return null;
  }

  const {
    account_manager,
    customer_service_rep,
    default_delivery_term,
    default_payment_term,
    default_payment_mode,
    preferred_currency,
    is_tax_exempt,
    tax_exempt_document: taxDocument,
    subscribers,
    notification_preferences,
  } = settingsData;

  const {
    quote_notifications = false,
    order_notifications = false,
    sample_request_notifications = false,
  } = notification_preferences || {};

  const currencies = api_metadata?.supported_currencies ?? [];
  const currency = currencies.find((curr) => curr.code === preferred_currency);
  const currencyLabel = currency ? makeCurrencyLabel(currency) : "--";

  return (
    <>
      <SettingsCard>
        <Row>
          <H3>{t("Customer Relation")}</H3>
          {!editCustomerRelation && (
            <EditButton
              testid={"edit-customer-relation"}
              onClick={() => setEditCustomerRelation(true)}
              title={t("Edit Customer Relation")}
            />
          )}
          {editCustomerRelation && (
            <CancelButtonSmall
              test-id={"cancel-edit-customer-relation"}
              onClick={() => setEditCustomerRelation(false)}
            />
          )}
        </Row>
        {!editCustomerRelation && (
          <InfoBlockRow>
            <InfoBlockSmall
              header={t("Account Manager")}
              content={<ContactBlockForUser user={account_manager} />}
              icon={<UserIcon width={16} />}
            />
            <InfoBlockSmall
              header={t("Customer Success Manager")}
              content={<ContactBlockForUser user={customer_service_rep} />}
              icon={<UserIcon width={16} />}
            />
          </InfoBlockRow>
        )}
        {editCustomerRelation && (
          <EditCustomerRelationForm
            buyerTenant={buyerTenant}
            loggedInUser={loggedInUser}
            refreshSettingsData={() => mutateSettings()}
            doneEditing={() => setEditCustomerRelation(false)}
            accountManager={account_manager}
            customerServiceRep={customer_service_rep}
          />
        )}
      </SettingsCard>

      <SettingsCard>
        <Row>
          <H3>{t("Account Subscribers")}</H3>
          {!editAccountSubscribers && (
            <EditButton
              testid={"edit-account-subscribers"}
              onClick={() => setEditAccountSubscribers(true)}
              title={t("Edit Account Subscribers")}
            />
          )}
          {editAccountSubscribers && (
            <CancelButtonSmall
              test-id={"cancel-edit-account-subscribersms"}
              onClick={() => setEditAccountSubscribers(false)}
            />
          )}
        </Row>
        {!editAccountSubscribers && (
          <>
            {subscribers &&
              subscribers?.map((subscriber) => (
                <InfoBlockRow
                  style={{ paddingBottom: "8px", paddingTop: "8px" }}
                >
                  <InfoBlockSmall
                    header={t("User")}
                    content={
                      <>
                        <div>
                          {subscriber.firstname} {subscriber.lastname}
                        </div>
                        <div>{subscriber.email_address}</div>
                      </>
                    }
                  />
                </InfoBlockRow>
              ))}
          </>
        )}

        {editAccountSubscribers && (
          <EditAccountSubscribers
            buyerTenant={buyerTenant}
            loggedInUser={loggedInUser}
            refreshSettingsData={() => mutateSettings()}
            doneEditing={() => setEditAccountSubscribers(false)}
            accountManager={account_manager}
            customerServiceRep={customer_service_rep}
            subscribers={subscribers ?? []}
          />
        )}
      </SettingsCard>

      <SettingsCard>
        <Row>
          <H3>{t("Tax Exempted")}</H3>
          {!editCustomerTaxExempted && (
            <EditButton
              testid={"edit-tax-exempted"}
              onClick={() => setEditCustomerTaxExempted(true)}
              title={t("Edit Tax Exempted")}
            />
          )}
          {editCustomerTaxExempted && (
            <CancelButtonSmall
              test-id={"cancel-edit-tax-exempted"}
              onClick={() => setEditCustomerTaxExempted(false)}
            />
          )}
        </Row>
        {!editCustomerTaxExempted && (
          <>
            <InfoBlockRow>
              <InfoBlockSmall
                header=""
                content={
                  <ToggleSwitch
                    label={t("Tax Exempted")}
                    disabled={true}
                    name="tax_exempted"
                    isChecked={is_tax_exempt}
                  />
                }
              />
            </InfoBlockRow>
            {taxDocument && is_tax_exempt && (
              <FileCard file={taxDocument} viewFile={viewTaxDocument} />
            )}
          </>
        )}
        {editCustomerTaxExempted && (
          <EditTaxExemptedForm
            tenantID={buyerTenant.id}
            sellerID={loggedInUser.tenant_id}
            refreshSettingsData={() => mutateSettings()}
            doneEditing={() => setEditCustomerTaxExempted(false)}
            taxExempted={is_tax_exempt}
            document={is_tax_exempt ? taxDocument : undefined}
          />
        )}
      </SettingsCard>

      {edition !== "pim" && (
        <>
          <SettingsCard>
            <Row>
              <H3>Currency</H3>
              {!editCurrency && (
                <>
                  <div
                    data-for={"edit-preferred-currency"}
                    data-tip={
                      accepts_payments
                        ? t("With payments enabled only USD is supported")
                        : ""
                    }
                  >
                    <EditButton
                      testid={"edit-preferred-currency"}
                      disabled={accepts_payments}
                      onClick={() => setEditCurrency(true)}
                      title="Edit Preferred Currency"
                    />
                  </div>
                  <ReactTooltip effect="solid" id="edit-preferred-currency" />
                </>
              )}
              {editCurrency && (
                <CancelButtonSmall
                  test-id={"cancel-edit-customer-relation"}
                  onClick={() => setEditCurrency(false)}
                />
              )}
            </Row>
            {!editCurrency && (
              <InfoBlockSpacer>
                <InfoBlockExtraSmall
                  header="Currency"
                  content={currencyLabel}
                />
              </InfoBlockSpacer>
            )}
            {editCurrency && (
              <EditCurrencyForm
                buyerTenant={buyerTenant}
                loggedInUser={loggedInUser}
                refreshSettingsData={() => mutateSettings()}
                doneEditing={() => setEditCurrency(false)}
                currency={currency}
              />
            )}
          </SettingsCard>

          <SettingsCard>
            <Row>
              <H3>{t("Default Terms")}</H3>
              {!editDefaultTerms && (
                <EditButton
                  testid={"edit-default-terms"}
                  onClick={() => setEditDefaultTerms(true)}
                  title="Edit Default Terms"
                />
              )}
              {editDefaultTerms && (
                <CancelButtonSmall
                  test-id={"cancel-edit-default-terms"}
                  onClick={() => setEditDefaultTerms(false)}
                />
              )}
            </Row>
            {!editDefaultTerms && (
              <>
                <InfoBlockSpacer>
                  <InfoBlockExtraSmall
                    header="Payment Terms"
                    content={default_payment_term?.name || "--"}
                  />
                </InfoBlockSpacer>
                <InfoBlockSpacer>
                  <InfoBlockExtraSmall
                    header="Payment Method"
                    content={default_payment_mode?.name || "--"}
                  />
                </InfoBlockSpacer>
                <InfoBlockSpacer>
                  <InfoBlockExtraSmall
                    header="Shipping Terms"
                    content={default_delivery_term?.name || "--"}
                  />
                </InfoBlockSpacer>
              </>
            )}
            {editDefaultTerms && (
              <EditDefaultTermsForm
                buyerTenant={buyerTenant}
                loggedInUser={loggedInUser}
                refreshSettingsData={() => mutateSettings()}
                doneEditing={() => setEditDefaultTerms(false)}
                defaultPaymentTerm={default_payment_term}
                defaultPaymentMode={default_payment_mode}
                defaultDeliveryTerm={default_delivery_term}
              />
            )}
          </SettingsCard>
        </>
      )}

      <SettingsCard>
        <Row>
          <H3>{t("Notification Preferences")}</H3>
          {!editNotificationPreferences && (
            <EditButton
              testid={"edit-notification-preferences"}
              onClick={() => setEditNotificationPreferences(true)}
              title={t("Edit Notification Preferences")}
            />
          )}
          {editNotificationPreferences && (
            <CancelButtonSmall
              test-id={"cancel-edit-notification-preferences"}
              onClick={() => setEditNotificationPreferences(false)}
            />
          )}
        </Row>
        {!editNotificationPreferences && (
          <InfoBlockColumn>
            <InfoBlockSmall
              header=""
              content={
                <ToggleSwitch
                  label={t(
                    "Quote Notifications to Shipping Location's Point of Contact"
                  )}
                  disabled={true}
                  name="quote_notifications"
                  isChecked={quote_notifications}
                />
              }
            />
            <InfoBlockSmall
              header=""
              content={
                <ToggleSwitch
                  label={t(
                    "Order Notifications to Shipping Location's Point of Contact"
                  )}
                  disabled={true}
                  name="order_notifications"
                  isChecked={order_notifications}
                />
              }
            />
            <InfoBlockSmall
              header=""
              content={
                <ToggleSwitch
                  label={t(
                    "Sample Notifications to Shipping Location's Point of Contact"
                  )}
                  disabled={true}
                  name="sample_request_notifications"
                  isChecked={sample_request_notifications}
                />
              }
            />
          </InfoBlockColumn>
        )}
        {editNotificationPreferences && (
          <EditNotificationPreferencesForm
            tenantID={buyerTenant.id}
            sellerID={loggedInUser.tenant_id}
            refreshSettingsData={() => mutateSettings()}
            doneEditing={() => setEditNotificationPreferences(false)}
            quoteNotifications={quote_notifications}
            orderNotifications={order_notifications}
            sampleRequestNotifications={sample_request_notifications}
          />
        )}
      </SettingsCard>
    </>
  );
};
