import React from "react";
import { SellerOrderDetailPage } from "../../SharedPages/SellerOrderDetailPage/SellerOrderDetailPage";

/**
 * Page component for displaying details of a single order. Used for seller and
 * seller admin accounts.
 */
export const SellerOrderDetail = () => {
  return <SellerOrderDetailPage />;
};
