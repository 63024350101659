import React from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { SellerQuoteDetailPageContent } from "./SellerQuoteDetailPageContent";
import type { QuoteRequest, User } from "../../../types/types";
import type { AxiosError } from "axios";
import { ErrorPlaceholder } from "../../../components/Error";
import { useInAppNotifications, useStoreState } from "../../../util/util";

/**
 * Shared page component showing the details of a single quote request that
 * came from a buyer user with an account (not a guest user without an account).
 * Accessible from the "Quotes" list page.
 *
 * Used by sellers and seller admins.
 */
export function SellerQuoteDetailPage({ user }: { user: User }) {
  const { storefront_id } = useStoreState();
  const { mutateNotifications } = useInAppNotifications(storefront_id, user);
  const { quote_id } = useParams<{ quote_id: string }>();
  const {
    data: quote,
    error: quoteError,
    mutate: mutateQuote,
  } = useSWR<QuoteRequest, AxiosError>(
    `/v1/storefronts/${storefront_id}/quotes/${quote_id}`
  );
  const isLoadingQuote = !quote && !quoteError;

  if (quote) {
    mutateNotifications();
  }

  if (quoteError) {
    return (
      <ErrorPlaceholder message={"There was an error loading the quote."} />
    );
  }

  if (isLoadingQuote) {
    return null;
  }

  if (quote) {
    return (
      <SellerQuoteDetailPageContent
        quote={quote}
        user={user}
        mutateQuote={mutateQuote}
      />
    );
  } else return null;
}
