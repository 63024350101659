import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
  CustomTermContainer,
  WarningMessage,
} from "../../../components/Form/Form";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import type { OptionType, UUID } from "../../../types/types";
import type {
  POCFormSchemaType,
  POCManualFormSchemaType,
} from "../../../util/util";
import {
  convertPaymentModeToOption,
  usePaymentMethods,
  useStoreState,
} from "../../../util/util";
import { ColoredTextOnError } from "../../../util/util-components";
import { ConfirmationSpacer } from "../../public/SampleRequestCart/SampleRequestCart";
import type { RequestAddressSchemaType } from "../../public/RequestAddress/RequestAddress";

export type CreateOrderBottomFormOutput = RequestAddressSchemaType & {
  confirmation: true;
  required_eta: string;
  payment_method: OptionType<UUID>;
};

export interface CreateOrderBottomFormPOCManual
  extends CreateOrderBottomFormOutput,
    POCManualFormSchemaType {}

export interface CreateOrderBottomFormPOCForm
  extends CreateOrderBottomFormOutput,
    POCFormSchemaType {}

export function CreateOrderBottomForm({
  defaultPaymentMethod,
  showConfirmation = true,
}: {
  defaultPaymentMethod: OptionType<UUID> | null;
  showConfirmation?: boolean;
}) {
  const methodsOfUseForm = useFormContext();
  const { control, register, errors, formState, setError, watch } =
    methodsOfUseForm;
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();

  const { data: fetchedPaymentMethods, error: paymentMethodsError } =
    usePaymentMethods(storefront_id);

  if (paymentMethodsError) {
    setError("payment_method", { message: "Failed to fetch payment methods" });
  }

  const watchedPaymentMethod = watch("payment_method");

  /**
   * This is compared by label because the ids are not stable between different objects.
   * @param defaultLabel
   * @param valueLabel
   * @returns
   */
  const isNotDefaultPaymentMethod = (
    defaultLabel: string | undefined,
    valueLabel: string
  ) => {
    if (!defaultLabel) {
      return false;
    } else if (valueLabel) {
      return !(defaultLabel === valueLabel);
    } else return false;
  };

  const paymentMethodsOptions =
    fetchedPaymentMethods?.data.map(convertPaymentModeToOption) ?? [];

  return (
    <>
      <DatePicker
        label={t("Shipment ETA")}
        name={"required_eta"}
        methodsOfUseForm={methodsOfUseForm}
        required={true}
      />
      <CustomTermContainer
        highlight={isNotDefaultPaymentMethod(
          defaultPaymentMethod?.label,
          watchedPaymentMethod?.label
        )}
        has_error={!!errors.payment_method}
      >
        <Controller
          as={SelectBoxV2}
          control={control}
          name="payment_method"
          placeholder={t("Payment Method")}
          options={paymentMethodsOptions}
          defaultValue={defaultPaymentMethod}
          rules={{ required: true }}
          errors={errors}
          formState={formState}
        />
        {isNotDefaultPaymentMethod(
          defaultPaymentMethod?.label,
          watchedPaymentMethod?.label
        ) && (
          <WarningMessage>
            {t("Requested payment method is not the default")}
          </WarningMessage>
        )}
      </CustomTermContainer>
      {showConfirmation ? (
        <ConfirmationSpacer>
          <CheckBoxContainer style={{ marginBottom: "15px", width: "100%" }}>
            <div style={{ width: "22px", marginRight: "15px" }}>
              <CheckBoxNoLabel
                name="confirmation"
                id="confirmation"
                ref={register({ required: true })}
                error={!!errors.confirmation?.message}
              />
            </div>
            <CheckBoxFinePrintLabel htmlFor="confirmation">
              <ColoredTextOnError isError={!!errors.confirmation?.message}>
                {t("I confirm all the information I provided is accurate.")}
              </ColoredTextOnError>
            </CheckBoxFinePrintLabel>
          </CheckBoxContainer>
        </ConfirmationSpacer>
      ) : (
        <></>
      )}
    </>
  );
}
