import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import { TextField } from "../TextFields/TextFields";
import {
  CurrencyInput,
  makeYupCurrencyTestFn,
} from "../CurrencyInput/CurrencyInput";
import type {
  CurrencyCode,
  MethodsOfUseForm,
  OptionType,
} from "../../types/types";
import React from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components/macro";
import { useCurrencySymbol, useStoreState } from "../../util/util";
import * as yup from "yup";
import { strings } from "../../util/strings";
import type { TFunction } from "react-i18next";

const AltInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > * {
    margin-right: 5px;
    width: 100%;
  }
`;

/**
 * Return the schema to use to validate these SKU form inputs.
 * The list_price input is optional, but if it is being shown as an input,
 * then currencyCodeForListPrice should be passed as an argument so the input
 * can be validated correctly.
 */
export const getSkuFormInputsSchema = (
  t: TFunction,
  currencyCodeForListPrice?: CurrencyCode
) => {
  const schema = {
    erp_system_id: yup.string().notRequired(),
    packaging_type: yup
      .object()
      .shape({ label: yup.string().required(), value: yup.string().required() })
      .required(strings(t).thisIsARequiredField),
    package_description: yup.string().notRequired(),
    package_volume: yup
      .number()
      .positive()
      .integer(strings(t).packageVolumeError)
      .required(strings(t).thisIsARequiredField),
    packaging_unit: yup
      .object()
      .shape({ label: yup.string().required(), value: yup.string().required() })
      .required(strings(t).thisIsARequiredField),
    list_price: currencyCodeForListPrice
      ? yup
          .string()
          .test(
            "list_price",
            strings(t).currencyMustBeValid,
            makeYupCurrencyTestFn(currencyCodeForListPrice, [""])
          )
          .notRequired()
      : yup.string().notRequired(),
  };
  return schema;
};

export const SkuFormInputs = ({
  methodsOfUseForm,
  packagingTypeOptions,
  packagingUnitOptions,
  showListPrice,
  showPackageDescription,
  showErpSystemId,
}: {
  methodsOfUseForm: MethodsOfUseForm;
  packagingTypeOptions: OptionType<string>[];
  packagingUnitOptions: OptionType<string>[];
  showErpSystemId?: boolean;
  showListPrice?: boolean;
  showPackageDescription?: boolean;
}) => {
  const { storefront_metadata } = useStoreState();
  const storefrontCurrencySymbol = useCurrencySymbol(
    storefront_metadata.default_currency
  );
  const { register, control, errors, formState } = methodsOfUseForm;

  return (
    <>
      {showErpSystemId && (
        <TextField
          name="erp_system_id"
          label="ERP System ID"
          theref={register({ required: false })}
          formState={formState}
          errors={errors}
          type="text"
        />
      )}
      <AltInputWrapper>
        <Controller
          as={SelectBoxV2}
          control={control}
          name={"packaging_type"}
          placeholder="Packaging Type"
          options={packagingTypeOptions}
          rules={{ required: true }}
          errors={errors}
          formState={formState}
        />
        <TextField
          name="package_volume"
          label="Packaging Volume"
          theref={register({ required: false })}
          formState={formState}
          errors={errors}
          type="number"
        />
      </AltInputWrapper>
      <AltInputWrapper>
        <Controller
          as={SelectBoxV2}
          control={control}
          name={"packaging_unit"}
          placeholder="Unit"
          options={packagingUnitOptions}
          rules={{ required: true }}
          errors={errors}
          formState={formState}
        />
        {showPackageDescription && (
          <TextField
            name="package_description"
            label="Packaging Description"
            theref={register({ required: false })}
            formState={formState}
            errors={errors}
            type="text"
          />
        )}
      </AltInputWrapper>
      {showListPrice && (
        <CurrencyInput
          name="list_price"
          label={`List Price (${storefrontCurrencySymbol})`}
          theref={register({ required: false })}
          formState={formState}
          errors={errors}
          type="number"
        />
      )}
    </>
  );
};
