import type { DeepMap, FieldError } from "react-hook-form";
import styled from "styled-components";
import { FormInputErrorText } from "../components/Typography/Typography";
import { strings } from "./strings";
import type { CurrencyCode } from "../types/types";
import type { TFunction } from "react-i18next";

// This file was originally part of the separate storybook package.
// It may make sense to rename it or move its contents somewhere else.

type ShowErrors = {
  errors: DeepMap<Record<string, any>, FieldError>;
  error?: FieldError;
  name: string;
  t: TFunction;
  bottomPosition?: string;
};

export const showErrors = ({
  errors,
  error,
  name,
  bottomPosition = "-12px",
  t,
}: ShowErrors): JSX.Element | null => {
  if (error) {
    return (
      <div
        className="errorMessageWrapper"
        style={{ position: "absolute", bottom: `${bottomPosition}` }}
      >
        <FormInputErrorText className="errorMessage">
          {error.message === "Required"
            ? strings(t).thisIsARequiredField
            : error.message}
        </FormInputErrorText>
      </div>
    );
  } else if (errors[name]?.message) {
    return (
      <div
        className="errorMessageWrapper"
        style={{ position: "absolute", bottom: `${bottomPosition}` }}
      >
        <FormInputErrorText className="errorMessage">
          {errors[name].message}
        </FormInputErrorText>
      </div>
    );
  } else if (errors[name]) {
    return (
      <div
        className="errorMessageWrapper"
        style={{ position: "absolute", bottom: "-12px" }}
      >
        <FormInputErrorText className="errorMessage">
          {strings(t).thisIsARequiredField}
        </FormInputErrorText>
      </div>
    );
  } else return null;
};

export const displayFieldErrorMessage = ({
  error,
}: {
  error: FieldError;
}): JSX.Element | null => {
  if (error) {
    return (
      <div
        className="errorMessageWrapper"
        style={{ position: "absolute", bottom: `-12px` }}
      >
        <FormInputErrorText className="errorMessage">
          {error.message}
        </FormInputErrorText>
      </div>
    );
  } else return null;
};
const ErrorColorText = styled.span`
  color: ${({ theme }) => theme.errorColor};
`;

interface ColoredTextOnErrorProps {
  isError: boolean;
  children: JSX.Element | string;
}

/**
 * If there is an error make some text the error color. Useful for coloring
 * required checkbox labels.
 */
export const ColoredTextOnError = ({
  isError,
  children,
}: ColoredTextOnErrorProps) => {
  return isError ? (
    <ErrorColorText>{children}</ErrorColorText>
  ) : (
    <span>{children}</span>
  );
};

/**
 * Get the user account type to show in the UI for a given user type used
 * by the backend (user.user_type). Only newer "v3" types are mapped to a UI
 * type, older "v2" do not have a UI type, at least for now.  The v2 types
 * passed in are just returned as-is.
 */
export function getUserTypeForUi(userType: string): string {
  const map = new Map([
    ["Seller Admin", "Admin"],
    ["Buyer Admin", "Admin"],
    ["Seller Standard", "Standard"],
    ["Buyer Standard", "Standard"],
  ]);
  const uiUserType = map.get(userType);
  return uiUserType || userType;
}

/**
 * Formats a number or a string to a currency format based on the passed in
 * currency code.  For example 2500 will return $2,500.00 for "USD".
 */
export const formatPrice = (
  price: number | string,
  currencyCode: CurrencyCode = "USD"
) => {
  const priceNumber = typeof price === "string" ? parseFloat(price) : price;

  if (isNaN(priceNumber)) return "--";

  // There was a production bug on a Saturday where priceNumber was null here.
  if (priceNumber === null) return "--";

  return priceNumber.toLocaleString("en-US", {
    style: "currency",
    currency: currencyCode,
  });
};

/**
 * Get the ISO 639-1 code from the browser. There is not a native way to do this
 * because the browser returns something like `en-US` where we just want `en`. I
 * have confirmed that all language codes are two letters.
 */
export function getBrowserLanguage(): string {
  return window.navigator.language.slice(0, 2);
}
