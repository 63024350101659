import React, { Component } from "react";
import { ErrorHandler } from "../../ErrorHandler";
import { Auth } from "../Auth";
import { SomethingWentWrong } from "../SomethingWentWrong";

export class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  static contextType = Auth;

  static getDerivedStateFromError(error: unknown) {
    return { error: error };
  }

  componentDidCatch(error: string | Error) {
    ErrorHandler.setUser(this.context?.user?.id ?? "");
    ErrorHandler.report(error);
    console.error(
      "The following error has been sent to Google Errors: ",
      error
    );
  }

  render() {
    if (this.state.error) {
      return <SomethingWentWrong />;
    }
    return this.props.children;
  }
}
