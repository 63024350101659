import type { Dispatch, SetStateAction } from "react";
import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { CaretDownIcon } from "../Icons/Icons";
import { H3, SoftHeader2 } from "../Typography/Typography";

interface IStyleAccordionCardProps {
  showContent?: boolean;
}

const AccordionCardWrapper = styled.div<IStyleAccordionCardProps>`
  position: relative;
  width: 100%;
  color: #0f1d2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
  margin-bottom: 20px;
  margin-top: 5px;
`;

const AccordionCardHeader = styled.div<IStyleAccordionCardProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 5px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  CaretDownIcon {
    transform: ${({ showContent }) =>
      showContent ? "rotate(180deg)" : "rotate(0deg)"};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0px 0;
  padding: 15px 0 10px;
`;

const Actions = styled.div<IStyleAccordionCardProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }

  button {
    white-space: nowrap;
    margin-right: 5px;
    padding-right: 10px;
  }
`;
const CaretDown = styled.div<IStyleAccordionCardProps>`
  transform: ${({ showContent }) =>
    showContent ? "rotate(180deg)" : "rotate(0deg)"};
  transition: all 0.2s ease-out;
`;
const Content = styled.div<IStyleAccordionCardProps>`
  width: 100%;
  min-width: 180px;
  padding: 15px 0 30px;
  transition: all 0.2s ease-out;
  height: ${({ showContent }) => (showContent ? "auto" : "0")};
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  .ql-editor {
    padding: 0;
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }

  .ql-container .ql-editor {
    padding: 13px;
  }
`;

export interface IAccordionCardProps {
  children: React.ReactNode;
  actionChildren: (
    setShowContent: Dispatch<SetStateAction<boolean | undefined>>
  ) => JSX.Element;
  title: string;
  subtitle?: string;
  expanded?: boolean;
}

/**
 * edit button will not display if handleEdit callback is not passed in.
 * cancel button will not appear if handleCancel is not passed in.
 */
export const AccordionCard = ({
  children,
  actionChildren,
  title,
  subtitle,
  expanded,
}: IAccordionCardProps) => {
  const [showContent, setShowContent] = useState(expanded);
  const theme = useContext(ThemeContext);
  return (
    <AccordionCardWrapper showContent={showContent}>
      <AccordionCardHeader showContent={showContent}>
        <TitleContainer onClick={() => setShowContent(!showContent)}>
          <H3 style={{ margin: 0 }}>{title}</H3>
          {subtitle && <SoftHeader2>{subtitle}</SoftHeader2>}
        </TitleContainer>
        <Actions showContent={showContent}>
          {actionChildren(setShowContent)}
          <CaretDown
            showContent={showContent}
            onClick={() => setShowContent(!showContent)}
          >
            <CaretDownIcon
              height={16}
              width={16}
              fill={theme.secondaryTextColor}
            />
          </CaretDown>
        </Actions>
      </AccordionCardHeader>

      {showContent && <Content showContent={showContent}>{children}</Content>}
    </AccordionCardWrapper>
  );
};
