import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toTitleCase, useStoreState } from "../../../../../util/util";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import type { AxiosError } from "axios";
import axios from "axios";
import { endpoints } from "../../../../../endpoints";
import { GenericDialogBody } from "../../../../../components/ConfirmDialog/ConfirmDialog";
import { DialogWrapper, RadioButtonContainer } from "./utils";
import {
  H3,
  H5,
  RegularTextSmall,
} from "../../../../../components/Typography/Typography";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import {
  ConfirmOrCancelButtonContainer,
  DeleteButton,
} from "../../../../../components/Buttons/Buttons";
import styled from "styled-components";
import { SearchSelectInfiniteScroll } from "../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import type { OptionType } from "../../../../../types/types";

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const NameWithDeleteIcon = styled.div`
  display: flex;
  width: 100%;
  padding: 13.5px 16px 14.5px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
`;

export const AccessUpdate = ({
  show,
  product_ids,
  onComplete,
  onCancel,
}: {
  show: boolean;
  product_ids: string[];
  onComplete: () => void;
  onCancel: () => void;
}) => {
  const [showDialog, setShowDialog] = useState(show);
  const [accessType, setAccessType] = useState<"all" | "selected">();
  const [selectedCustomers, setSelectedCustomers] = useState<
    Map<string, OptionType<string>>
  >(new Map());
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { storefront_id, tenant_id } = useStoreState();
  const { notifyError, notifySuccess } = useNotifications();

  const resetStates = () => {
    setAccessType(undefined);
    setSelectedCustomers(new Map());
  };

  const onContinue = async () => {
    setLoading(true);
    try {
      const payload = {
        product_ids,
        ...(accessType === "selected"
          ? {
              tenants_to_add: Array.from(selectedCustomers.values()).map(
                ({ value }) => value
              ),
            }
          : { is_accessible_by_all_customers_and_distributors: true }),
      };
      await axios.patch(
        endpoints.v2_tenants_tenant_id_pim_products_access(tenant_id),
        payload
      );

      const message =
        accessType === "all"
          ? t(
              product_ids.length === 1
                ? "All customers and distributors were given access to the product."
                : "All customers and distributors were given access to the {{number_of_products}} products.",
              { number_of_products: product_ids.length }
            )
          : t(
              product_ids.length === 1
                ? "Selected customers were given access to the product."
                : "Selected customers were given access to the {{number_of_products}} products.",
              { number_of_products: product_ids.length }
            );
      notifySuccess(message);
      setShowDialog(false);
      onComplete();
      resetStates();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not update access to product(s). Please try again later."),
        {
          error,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const closeDialog = () => {
    setShowDialog(false);
    onCancel();
    resetStates();
  };

  const handleSelect = (selectedItem: OptionType<string>) => {
    const newMap = new Map(selectedCustomers);
    newMap.set(selectedItem.value, selectedItem);
    setSelectedCustomers(newMap);
  };

  const remove = (id: string) => {
    const newMap = new Map(selectedCustomers);
    newMap.delete(id);
    setSelectedCustomers(newMap);
  };

  const isContinueDisabled =
    !accessType || (accessType === "selected" && selectedCustomers.size === 0);

  useEffect(() => setShowDialog(show), [show]);

  useEffect(() => {
    if (accessType) {
      setSelectedCustomers(new Map());
    }
  }, [accessType]);

  return (
    <GenericDialogBody show={showDialog} closeDialog={closeDialog}>
      <DialogWrapper>
        <H3 style={{ margin: 0 }}>{t("Manage Access")}</H3>
        <H5 style={{ marginTop: "8px" }}>
          {t("How would you like to give access?")}
        </H5>
        <RadioButtonContainer>
          <RadioButton
            name={"access_type"}
            value={"all"}
            checked={accessType === "all"}
            optionTitle={t("All customers")}
            handleChange={() => setAccessType("all")}
          />
          <RadioButton
            name={"access_type"}
            value={"selected"}
            checked={accessType === "selected"}
            optionTitle={t("Selected customers")}
            handleChange={() => setAccessType("selected")}
          />
        </RadioButtonContainer>
        {accessType === "selected" ? (
          <>
            <H5>{t("Search for your customers")}</H5>
            <InputWrapper>
              <SearchSelectInfiniteScroll
                name="selected_customers"
                isSearchBar={true}
                errors={{}}
                formState={{}}
                baseUrl={endpoints.v2_storefronts_id_tenants_id_tenants_basic(
                  storefront_id,
                  tenant_id
                )}
                getOptions={(response: { name: string; id: string }[]) =>
                  response.map(({ name, id }) => ({
                    label: toTitleCase(name),
                    value: id,
                  }))
                }
                placeholder={t(
                  "Search by company name or primary contact email id"
                )}
                // This prevents a value from ever appearing in the input,
                // instead the value is added to the chips.
                value={null}
                onChange={handleSelect}
              />
            </InputWrapper>
            <div style={{ marginBottom: "32px" }}>
              {Array.from(selectedCustomers.values()).map(
                ({ label, value }) => (
                  <NameWithDeleteIcon>
                    <RegularTextSmall>{label}</RegularTextSmall>
                    <DeleteButton
                      testid={`delete-button-${value}`}
                      onClick={() => remove(value)}
                      type="button"
                      height={20}
                      width={20}
                    />
                  </NameWithDeleteIcon>
                )
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <ConfirmOrCancelButtonContainer
          onCancel={closeDialog}
          onConfirm={onContinue}
          isConfirmDisabled={isContinueDisabled}
          isConfirmLoading={loading}
        />
      </DialogWrapper>
    </GenericDialogBody>
  );
};
