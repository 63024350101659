import { SlideOut } from "../SlideOut/SlideOut";
import type { CardGridItem } from "../CardGrid/CardGrid";
import { CardGrid } from "../CardGrid/CardGrid";
import { EditTopRightWithPositioning } from "../EditTopRight/EditTopRightWithPositioning";
import type { RBACRole } from "../../types/types";
import { isDefined } from "../../types/types";
import type {
  PIMProductBasePaginagedOutput,
  PIMProductBase,
} from "../../types/types.PIM";
import type { AxiosError } from "axios";
import React, { useContext, useState } from "react";
import useSWR from "swr";
import { useStoreState } from "../../util/util";
import styled from "styled-components/macro";
import { screenSize } from "../../theme";
import { FeaturedProductsEditForm } from "./FeaturedProductsEditForm";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { Auth, useAuthContext } from "../Auth";
import { HomePageTitle, CenterContainer } from "../../layout/publicPageLayout";
import { Loader } from "../Loader/Loader";
import { ErrorPlaceholder } from "../Error";
import { endpoints } from "../../endpoints";

const FeaturedProductsContainer = styled.div`
  @media ${screenSize.small} {
    margin: 0px 0px 20px;
  }
`;

export interface IFeaturedProductsData {
  title: string;
  productIds: string[];
}

/**
 * Renders the "Featured Products" section for the "Ag3Homepage" template.
 *
 * A potential gotcha to be aware of:
 * - product is both featured and shown on the hompage
 * - on product detail page product is edited to no longer be featured
 * - product's ID is still in the homePageData list of those to be shown (stale)
 * - product won't be shown because it's no longer featured
 * - after featured products is edited again on the homepage, the product's ID
 *   will no longer be in the homePageData for "Featured Products"
 */
export const FeaturedProducts = ({
  homePageData,
  isEditable,
}: {
  homePageData: IHomePageData;
  isEditable: boolean;
}) => {
  const { slug: storefrontSlug, tenant_id } = useStoreState();
  const [editing, setEditing] = useState(false);
  const { user } = useContext(Auth);
  const rbacRole = user?.rbac_role;

  const component = homePageData.config.components.find(
    (component) => component.name === "featuredProducts"
  );
  const data = component?.data;

  // Note: productIds may contain the IDs of products that are no longer
  // featured. See docstring above.
  const productIds: string[] = data?.productIds || [];
  const title: string = data?.title || "--";

  const { data: productsResponse, error: productsError } = useSWR<
    PIMProductBasePaginagedOutput,
    AxiosError<PIMProductBasePaginagedOutput>
  >(
    tenant_id ? endpoints.v2_tenants_id_pim_products_summary(tenant_id) : null,
    {
      revalidateOnFocus: false,
    }
  );

  const allFeaturedProducts = productsResponse?.data || [];

  const productsToShow = productIds
    .map((id) => allFeaturedProducts.find((product) => product.id === id))
    .filter(isDefined);

  const handleEditButtonClick = () => {
    setEditing(true);
  };

  const handleEditSubmit = () => {
    setEditing(false);
  };

  if (productsError) {
    console.error(productsError);
    return (
      <ErrorPlaceholder
        message={"There was an error retrieving the featured products."}
      />
    );
  }

  // TODO: Ideally we could guarantee the storefront slug is always defined.
  if (storefrontSlug) {
    return (
      <FeaturedProductsContainer>
        <SlideOut closeFlyout={() => setEditing(false)} show={editing}>
          <FeaturedProductsEditForm
            data={data}
            maximumProducts={4}
            allFeaturedProducts={allFeaturedProducts}
            handleEditSubmit={handleEditSubmit}
            homePageData={homePageData}
          />
        </SlideOut>
        {isEditable && (
          <EditTopRightWithPositioning onClick={handleEditButtonClick} />
        )}
        <HomePageTitle>{title}</HomePageTitle>
        {productsResponse ? (
          <CardGrid
            items={
              productsToShow.length > 0
                ? productsToGridItems(storefrontSlug, productsToShow, rbacRole)
                : getPlaceholderProducts(4)
            }
          />
        ) : (
          <CenterContainer>
            <Loader isLoading />
          </CenterContainer>
        )}
      </FeaturedProductsContainer>
    );
  }

  return null;
};

/**
 * Given an array of products return an array of CardGrid items.
 */
const productsToGridItems = (
  storefrontSlug: string,
  products: PIMProductBase[],
  rbacRole?: RBACRole
): CardGridItem[] => {
  const items: CardGridItem[] = products.map((product) => {
    const productUrl = `/store/${storefrontSlug}/product/${product.slug}`;
    // The Request Quote button is disabled and does nothing for Sellers
    const { roleIsSomeKindOfSeller } = useAuthContext();

    const requestQuote = roleIsSomeKindOfSeller
      ? () => {}
      : () => {
          window.location.href = productUrl + "?quote=1";
        };

    return {
      itemTitle: product.name,
      imageUrl: product.image_url,
      link: {
        text: "VIEW PRODUCT DETAILS",
        url: productUrl,
      },
      firstButton: {
        text: "Request Quote",
        onClick: requestQuote,
        disabled: roleIsSomeKindOfSeller,
      },
    };
  });

  return items;
};

/**
 * Returns an array of placeholder product data, to be shown initially when
 * there is no product data yet.
 */
const getPlaceholderProducts = (count: number): CardGridItem[] => {
  return Array(count)
    .fill(0)
    .map((_, index) => {
      return {
        itemTitle: `Product ${index + 1}`,
        link: {
          text: "VIEW PRODUCT DETAILS",
          url: "#",
        },
        firstButton: {
          text: "Request Quote",
          onClick: () => {},
          disabled: false,
        },
      };
    });
};
