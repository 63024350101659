import type { IOrderItem, QuoteRequestItem } from "../../types/types";
import { formatPrice } from "../../util/util-components";
import React from "react";
import { calculatePriceForQuantity } from "../../util/QuotesAndOrders";
import { TransactionItemInfoBlock } from "./TransactionItemInfoBlock";
import { makePricePerUnitString } from "./TransactionItemUtils";

/**
 * Shows the price details for a transaction item.
 */
export const TransactionItemInfoBlockPrice = ({
  item,
}: {
  item: QuoteRequestItem | IOrderItem;
}) => {
  const totalPrice = item.price_per_unit
    ? calculatePriceForQuantity(item.price_per_unit, item.total_quantity)
    : null;

  return (
    <TransactionItemInfoBlock
      alignRight={true}
      main={totalPrice ? formatPrice(totalPrice, item.currency) : "--"}
      lines={[
        makePricePerUnitString(
          item.price_per_unit,
          item.currency,
          item.sku?.packaging_unit?.name
        ),
      ]}
    />
  );
};
