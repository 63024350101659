import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { screenSize } from "../../theme";
import {
  ButtonWithConfirmDialog,
  InvisibleButton,
  SaveIconButton,
  TextButton,
} from "../Buttons/Buttons";
import { useTranslation } from "react-i18next";
import { SmallText } from "../Typography/Typography";
import { CheckIcon, RearrangeIcon, XIcon } from "../Icons/Icons";
import ReactTooltip from "react-tooltip";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  @media ${screenSize.small} {
    gap: 10px;
  }
  align-items: center;
`;

const ActionsWrapper = styled.div`
  padding: 7px 0;
  align-items: center;
  display: flex;
  svg {
    vertical-align: middle;
  }

  button {
    margin-left: 5px;
  }
`;

interface ITableReorderControlsProps {
  enableRowReorder: boolean;
  handleEnableRowReorder: () => void;
  handleSaveReorder: () => void;
  handleCancelReorder: () => void;
  reorderConfirmationMessage?: string;
}

export const TableReorderControls = ({
  enableRowReorder = false,
  handleEnableRowReorder,
  handleSaveReorder,
  handleCancelReorder,
  reorderConfirmationMessage,
}: ITableReorderControlsProps) => {
  const [isEnabled, setIsEnabled] = useState(enableRowReorder);
  const { t } = useTranslation();
  const theme = useTheme();

  const saveReorder = () => {
    handleSaveReorder();
    setIsEnabled(false);
  };

  const cancelReorder = () => {
    handleCancelReorder();
    setIsEnabled(false);
  };

  const enableReorder = () => {
    handleEnableRowReorder();
    setIsEnabled(true);
  };

  useEffect(() => {
    setIsEnabled(enableRowReorder);
  }, [enableRowReorder]);

  return (
    <Wrapper style={{ justifyContent: "flex-end" }}>
      <>
        {!isEnabled && (
          <ActionsWrapper>
            <SmallText
              style={{
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextButton
                onClick={enableReorder}
                data-for={"rearrange-tooltip"}
                data-tip={t("Click to rearrange the order")}
              >
                <RearrangeIcon width={22} />
                <span style={{ marginLeft: "5px" }}>{t("Rearrange")}</span>
              </TextButton>
            </SmallText>
            <ReactTooltip id="rearrange-tooltip" />
          </ActionsWrapper>
        )}
        {isEnabled && (
          <ActionsWrapper>
            <SmallText
              style={{
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "5px" }}>
                <RearrangeIcon width={22} />
              </div>
              {t("Confirm Changes")}
            </SmallText>

            <InvisibleButton type="button" onClick={cancelReorder}>
              <XIcon fill={theme.destructiveTextColor} />
            </InvisibleButton>
            {reorderConfirmationMessage ? (
              <ButtonWithConfirmDialog
                Button={SaveIconButton}
                testid={`confirm-reorder`}
                handleConfirm={saveReorder}
                confirmMessage={reorderConfirmationMessage}
              />
            ) : (
              <InvisibleButton onClick={saveReorder}>
                <CheckIcon fill={theme.activeToggleBG} />
              </InvisibleButton>
            )}
          </ActionsWrapper>
        )}
      </>
    </Wrapper>
  );
};
