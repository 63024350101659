import type { FunctionComponent } from "react";
import React, { useEffect, useState, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import "yup-phone";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import type {
  AgilisUsersPaginatedOutput,
  IAddress,
  OptionType,
} from "../../../../../types/types";
import { Controller } from "react-hook-form";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  RadioButtonContainer,
  SubmitButtonContainer,
} from "../../../../../layout/FormLayout";
import { TextField } from "../../../../../components/TextFields/TextFields";
import {
  CancelButton,
  PrimaryButtonMedium,
} from "../../../../../components/Buttons/Buttons";
import {
  getAddressOneInputLabel,
  getAddressTwoInputLabel,
  getStatesInputLabel,
  getZipCodeInputLabel,
  getCountryOption,
  getStateOption,
} from "../../../../../util/location";
import {
  AddressFormSchema,
  useStoreState,
  useFormWrapper,
  getAddressUserOption,
  makeUrlWithParams,
  convertUserToOption,
  POCManualFormSchema,
  POCFormSchema,
} from "../../../../../util/util";
import {
  getCountryCode,
  getPhoneNumber,
  getPhoneCodeOption,
  getPhoneCodesOptions,
} from "../../../../../util/phone";
import { useCountriesList, useStatesList } from "../../../../../util/Locations";
import { useTranslation } from "react-i18next";
import { ToggleSwitchV2 } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import styled from "styled-components";
import { Modal } from "../../../../../components/Modal/Modal";
import type { IAddressDetails } from "./EditLocations";
import { H4Normal } from "../../../../../components/Typography/Typography";
import { StringParam, useQueryParams } from "use-query-params";
import { useDebounce } from "../../../../../util/hooks";
import { endpoints } from "../../../../../endpoints";
import useSWR from "swr";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import * as yup from "yup";
import { useAuthContext } from "../../../../../components/Auth";

const ModalWrapper = styled.div`
  padding: 50px 90px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const WarningTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.warningTextColor};
  margin-bottom: 30px;
`;
export interface LocationInputs {
  // TODO: Editing requires 'contact_name' and creating requires first and last.
  // Making them all optional is not great, but it lets us move faster for now,
  // and the plan is to use first and last for both edit and create, eventually.
  contact_name?: string;
  contact_first_name?: string;
  contact_last_name?: string;
  company_name: string;
  external_id: string;
  email_address?: string;
  phone_number?: string;
  country_code?: OptionType;
  address1: string;
  address2: string;
  country: OptionType;
  county: string;
  state: OptionType;
  city: string;
  postal_code: string;
  is_active: boolean;
  point_of_contact_id?: OptionType;
  same_as_hq?: boolean;
}

interface LocationFormProps {
  onSubmitFn: (
    inputs: LocationInputs,
    pointOfContactType: string
  ) => Promise<boolean>;
  submitButtonText: string;
  address?: IAddress;
  editTaxCRM?: boolean;
  tenantId?: string;
  addressDetails?: IAddressDetails;
  hqAddress?: IAddress;
}

/**
 * Used to create or edit a location. A location includes an address and
 * contact info. When an optional location/address is passed in, populate the
 * form with that data to edit that address, otherwise the form is blank to
 * create a new location. A passed in function is called when the form is
 * submitted.
 */
export const LocationForm: FunctionComponent<LocationFormProps> = ({
  onSubmitFn,
  address,
  editTaxCRM = false,
  tenantId,
  addressDetails,
  hqAddress,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { tenant_id, storefront_id } = useStoreState();
  const { roleIsSellerAdmin, roleIsSomeKindOfBuyer } = useAuthContext();
  const { t } = useTranslation();
  const countries = useCountriesList();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [is_default_state_set, set_is_default_state_set] = useState(false);
  const [pointOfContactType, setPointOfContactType] =
    useState<string>("manual");
  const [query, setQuery] = useQueryParams({
    q: StringParam,
  });
  const [userSearchQuery, setUserSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(userSearchQuery, 1000);
  const [useHqAddress, setUseHqAddress] = useState(
    address?.same_as_hq || false
  ); // Toggle state
  const [storedCountry, setStoredCountry] = useState(
    address?.country === "USA" ? "US" : address?.country
  );

  const {
    handleSubmit,
    register,
    formState,
    control,
    errors,
    reset,
    watch,
    setValue,
  } = useFormWrapper({
    shouldUnregister: false,
    resolver: yupResolver(
      yup.lazy((formValues: any) =>
        yup.object().shape({
          ...(pointOfContactType === "manual"
            ? POCManualFormSchema(t, formValues)
            : {}),
          ...(pointOfContactType === "existing" ? POCFormSchema(t) : {}),
          ...AddressFormSchema(t),
        })
      )
    ),
    defaultValues: address
      ? {
          address1: address.address1,
          address2: address.address2,
          company_name: address.company_name,
          tax_id: address.tax_id,
          crm_id: address.crm_id,
          external_id: address.external_identity?.external_id,
          city: address.city,
          county: address?.county,
          // For editing an address there is just a single 'contact_name'.
          contact_first_name: address.contact_first_name,
          contact_last_name: address.contact_last_name,
          country: getCountryOption(countries, address.country) ?? {
            label: "",
            value: "",
          },
          state: { label: null, value: address.state },
          email_address: address.email_address,
          phone_number: getPhoneNumber(address.phone_number),
          country_code: getPhoneCodeOption(
            getCountryCode(address.phone_number)
          ),
          postal_code: address.postal_code,
          is_active: address.is_active,
          point_of_contact_id: getAddressUserOption(address),
          same_as_hq: address.same_as_hq ?? false, // Added "Same as HQ" toggl with default value
        }
      : {
          country: { label: "", value: "" },
          state: { label: "", value: "" },
          country_code: { label: "", value: "" },
          is_active: true,
          point_of_contact_id: { label: "", value: "" },
          same_as_hq: false, // Added "Same as HQ" toggle with default value
        },
  });
  const selectedCountry = watch("country");
  const states = useStatesList(selectedCountry?.value);
  const watchIsActive = watch("is_active", address?.is_active);
  const watchIsSameAsHQ = watch("same_as_hq", address?.same_as_hq);

  const usersQuery =
    storefront_id && tenantId
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_tenants_id_users(storefront_id, tenantId),
          {
            offset: 0,
            limit: 100,
            order_by: "asc",
            q: debouncedSearchQuery || null,
          }
        )
      : null;

  const { data: usersData } = useSWR<AgilisUsersPaginatedOutput>(usersQuery);

  const onSubmit = async (inputs: LocationInputs) => {
    if (submitting) return;

    setSubmitting(true);
    const didSucceed = await onSubmitFn(
      { ...inputs, same_as_hq: useHqAddress },
      pointOfContactType
    );
    setSubmitting(false);

    if (didSucceed) {
      // Clear the form.
      reset();
    }
  };

  const cancelDeactivateAddress = () => {
    reset();
    setShowConfirmationModal(false);
  };

  const changePointOfContact = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value) {
      setPointOfContactType(e.currentTarget.value);
    }
  };

  const handleUserSearch = (value: string) => {
    if (value) {
      setUserSearchQuery(value);
    } else {
      handleClearSearch();
    }
  };

  const handleClearSearch = () => {
    setUserSearchQuery("");
    setQuery({ q: undefined });
  };

  const checkActiveAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const is_checked = e.currentTarget.checked;
    if (address?.is_active && addressDetails?.has_price_tiers && !is_checked) {
      setShowConfirmationModal(true);
    }
    setValue("is_active", is_checked);
  };

  const checkSameAsHQ = (e: React.ChangeEvent<HTMLInputElement>) => {
    const is_checked = e.currentTarget.checked;
    setUseHqAddress(is_checked);
    handleSameAsHqToggle(is_checked, hqAddress);
    setValue("same_as_hq", is_checked);
  };

  // Handle the "Same as HQ" toggle switch change
  const handleSameAsHqToggle = useCallback(
    (isSameAsHq: boolean, hqAddress: IAddress | undefined) => {
      if (isSameAsHq && hqAddress) {
        // Prefill the form with HQ address details
        setValue("company_name", hqAddress.company_name || "");
        setValue("address1", hqAddress.address1 || "");
        setValue("address2", hqAddress.address2 || "");
        setValue("city", hqAddress.city || "");
        setValue("county", hqAddress.county || "");
        setValue("postal_code", hqAddress.postal_code || "");

        // Ensure countries are loaded before setting
        setValue(
          "country",
          getCountryOption(countries, hqAddress.country) || {
            label: "",
            value: "",
          }
        );

        // Ensure states are loaded before setting
        setValue(
          "state",
          getStateOption(states, hqAddress.state) || { label: "", value: "" }
        );

        // Prefill Point of Contact details
        setValue("contact_first_name", hqAddress.contact_first_name || "");
        setValue("contact_last_name", hqAddress.contact_last_name || "");
        setValue("email_address", hqAddress.email_address || "");
        setValue("phone_number", getPhoneNumber(hqAddress.phone_number) || "");
        setValue(
          "country_code",
          getPhoneCodeOption(getCountryCode(hqAddress.phone_number)) || {
            label: "",
            value: "",
          }
        );
        setValue("same_as_hq", true);
      } else {
        // Clear the form fields if the toggle is off
        setValue("company_name", "");
        setValue("address1", "");
        setValue("address2", "");
        setValue("city", "");
        setValue("county", "");
        setValue("postal_code", "");
        setValue("state", { label: "", value: "" });
        setValue("country", { label: "", value: "" });
        setValue("tax_id", "");

        // Clear Point of Contact details
        setValue("contact_first_name", "");
        setValue("contact_last_name", "");
        setValue("email_address", "");
        setValue("phone_number", "");
        setValue("country_code", { label: "", value: "" });
        setValue("same_as_hq", false);
      }
    },
    [setValue, countries, states]
  );

  useEffect(() => {
    if (selectedCountry?.value && selectedCountry?.value !== storedCountry) {
      setStoredCountry(selectedCountry?.value);
      setValue("address1", "");
      setValue("address2", "");
      setValue("city", "");
      setValue("county", "");
      setValue("postal_code", "");
      setValue("state", { label: "", value: "" });
    }
  }, [selectedCountry, storedCountry, setValue]);

  useEffect(() => {
    if (
      useHqAddress &&
      hqAddress &&
      countries.length > 0 &&
      states.length > 0
    ) {
      // Prefill the form with HQ address details once all dependencies are ready
      handleSameAsHqToggle(true, hqAddress);
    }
  }, [useHqAddress, hqAddress, countries, states, handleSameAsHqToggle]); // Trigger when any of these change

  useEffect(() => {
    if (address?.point_of_contact_id) setPointOfContactType("existing");
  }, [address]);

  useEffect(() => {
    if (pointOfContactType === "manual" && address?.point_of_contact_id) {
      setValue("contact_first_name", "");
      setValue("contact_last_name", "");
      setValue("email_address", "");
      setValue("phone_number", "");
      setValue("country_code", { label: "", value: "" });
    }
  }, [pointOfContactType, address, setValue]);

  useEffect(() => {
    if (address?.point_of_contact_id) setPointOfContactType("existing");
  }, [address]);

  useEffect(() => {
    if (pointOfContactType === "manual" && address?.point_of_contact_id) {
      setValue("contact_first_name", "");
      setValue("contact_last_name", "");
      setValue("email_address", "");
      setValue("phone_number", "");
      setValue("country_code", { label: "", value: "" });
    }
  }, [pointOfContactType, address, setValue]);

  useEffect(() => {
    if (countries.length > 0 && address) {
      setValue("country", getCountryOption(countries, address.country));
    }
  }, [countries, address, setValue]);

  useEffect(() => {
    if (states.length > 0 && !is_default_state_set) {
      address?.state &&
        setValue("state", getStateOption(states, address.state));
      set_is_default_state_set(true);
    }
  }, [address?.state, is_default_state_set, setValue, states]);

  const users = usersData?.data.filter((user) => user.is_active);
  const userOptions = users?.map(convertUserToOption) ?? [];

  const radio_button_id = Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <H4Normal>{t("Address")}</H4Normal>
        <Flex>
          <Flex2 style={{ marginRight: tenant_id === tenantId ? "0" : "14px" }}>
            <TextField
              name="company_name"
              label={t("Company Name")}
              theref={register({
                required: true,
              })}
              formState={formState}
              readOnly={editTaxCRM || useHqAddress}
              errors={errors}
              type="text"
            />
          </Flex2>
          {tenant_id !== tenantId && (
            <Flex1>
              <TextField
                name="external_id"
                label={t("Location ID")}
                readOnly={editTaxCRM || useHqAddress}
                theref={register({
                  required: false,
                })}
                formState={formState}
                errors={errors}
                type="text"
              />
            </Flex1>
          )}
        </Flex>
        <TextField
          name="tax_id"
          label={t("Tax ID")}
          readOnly={
            editTaxCRM && address?.tax_id ? address?.tax_id?.length > 0 : false
          }
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        {!roleIsSomeKindOfBuyer && (
          <TextField
            name="crm_id"
            label={t("CRM ID")}
            readOnly={
              !roleIsSellerAdmin ||
              (editTaxCRM && address?.crm_id
                ? address?.crm_id?.length > 0
                : useHqAddress) // Disable if useHqAddress is true
            }
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
        )}
        <Controller
          as={SelectBoxV2}
          control={control}
          name="country"
          disabled={editTaxCRM || useHqAddress}
          autoComplete={"country-name"}
          placeholder={t("Country")}
          options={countries}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
        />
        {selectedCountry && selectedCountry.value && (
          <>
            <TextField
              name="address1"
              autoComplete="address-line1"
              label={getAddressOneInputLabel(selectedCountry.value, t)}
              readOnly={editTaxCRM || useHqAddress}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="address2"
              autoComplete="address-line2"
              label={getAddressTwoInputLabel(selectedCountry.value, t)}
              readOnly={editTaxCRM || useHqAddress}
              theref={register({
                required: false,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <Flex>
              <Flex2
                style={{
                  marginRight: selectedCountry.value === "IN" ? "14px" : "0",
                }}
              >
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="state"
                  autoComplete="address-level1"
                  placeholder={getStatesInputLabel(selectedCountry.value, t)}
                  disabled={editTaxCRM || useHqAddress}
                  id="portSelectBox"
                  options={states}
                  rules={{
                    required: true,
                  }}
                  errors={errors}
                  formState={formState}
                />
              </Flex2>
              {selectedCountry.value === "IN" && (
                <Flex1>
                  <TextField
                    name="county"
                    label={t("Taluka")}
                    readOnly={editTaxCRM || useHqAddress}
                    theref={register({
                      required: false,
                    })}
                    formState={formState}
                    errors={errors}
                    type="text"
                  />
                </Flex1>
              )}
            </Flex>
            <Flex>
              <Flex2>
                <TextField
                  name="city"
                  label={t("City")}
                  readOnly={editTaxCRM || useHqAddress}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
              </Flex2>
              <Flex1>
                <TextField
                  name="postal_code"
                  autoComplete="postal-code"
                  label={getZipCodeInputLabel(selectedCountry.value, t)}
                  readOnly={editTaxCRM || useHqAddress}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  errors={errors}
                  type="text"
                />
              </Flex1>
            </Flex>
          </>
        )}
        <H4Normal style={{ marginTop: "35px" }}>
          {t("Point of Contact")}
        </H4Normal>
        <RadioButtonContainer>
          <RadioButton
            name={radio_button_id}
            value="existing"
            checked={pointOfContactType === "existing"}
            optionTitle="Existing User"
            disabled={editTaxCRM || useHqAddress}
            handleChange={changePointOfContact}
          />
          <RadioButton
            name={radio_button_id}
            value="manual"
            checked={pointOfContactType === "manual"}
            optionTitle="Add Manually"
            disabled={editTaxCRM || useHqAddress}
            handleChange={changePointOfContact}
          />
        </RadioButtonContainer>
        {pointOfContactType === "existing" && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="point_of_contact_id"
            onInputChange={handleUserSearch}
            placeholder="Select User"
            disabled={editTaxCRM || useHqAddress}
            options={userOptions}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
          />
        )}
        {pointOfContactType === "manual" && (
          <>
            <TextField
              name="contact_first_name"
              label={t("First Name")}
              autoComplete="given-name"
              readOnly={editTaxCRM || useHqAddress}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="contact_last_name"
              autoComplete="family-name"
              label={t("Last Name")}
              readOnly={editTaxCRM || useHqAddress}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <TextField
              name="email_address"
              autoComplete="email"
              label={t("Email")}
              readOnly={editTaxCRM || useHqAddress}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="email"
            />
            <Flex>
              <Flex1>
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="country_code"
                  autoComplete="countryCode"
                  placeholder={t("Country Code")}
                  disabled={editTaxCRM || useHqAddress}
                  id="countryCodeSelectBox"
                  options={getPhoneCodesOptions()}
                  rules={{
                    required: true,
                  }}
                  errors={errors}
                  formState={formState}
                />
              </Flex1>
              <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                <TextField
                  name="phone_number"
                  autoComplete="tel"
                  label={t("Phone Number")}
                  readOnly={editTaxCRM || useHqAddress}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  errors={errors}
                  type="tel"
                />
              </Flex2>
            </Flex>
          </>
        )}
        <div>
          <ToggleSwitchV2
            label={watchIsActive ? t("Active") : t("Inactive")}
            name="is_active"
            ref={register({ required: true })}
            disabled={editTaxCRM || useHqAddress}
            checked={watchIsActive}
            onChange={checkActiveAddress}
          />
        </div>
        <div>
          <ToggleSwitchV2
            label={t("Same as HQ Address")}
            name="same_as_hq"
            ref={register}
            checked={watchIsSameAsHQ}
            onChange={checkSameAsHQ}
            disabled={editTaxCRM}
          />
        </div>
        <SubmitButtonContainer>
          <PrimaryButtonMedium loading={submitting}>
            {address ? t("Save Change") : t("Add")}
          </PrimaryButtonMedium>
        </SubmitButtonContainer>
      </Form>
      <Modal
        overlay
        show={showConfirmationModal}
        closeModal={cancelDeactivateAddress}
        modalWidth={"680px"}
      >
        <ModalWrapper>
          <WarningTitle>{t("This Action will affect pricing")}</WarningTitle>
          <div>
            {t(
              "There are currently active price offers for this location. Disabling this location will remove any existing price offers."
            )}
          </div>
          <div>{t("Would you like to proceed?")}</div>
          <PrimaryButtonMedium
            onClick={() => setShowConfirmationModal(false)}
            style={{ marginTop: "30px" }}
          >
            {t("Proceed")}
          </PrimaryButtonMedium>
          <br />
          <CancelButton
            onClick={cancelDeactivateAddress}
            style={{ padding: "20px 30px" }}
          >
            {t("Cancel")}
          </CancelButton>
        </ModalWrapper>
      </Modal>
    </>
  );
};
