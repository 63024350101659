import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  PrimaryButtonSmall,
  DeleteButton,
} from "../../../../../components/Buttons/Buttons";
import * as zod from "zod";
import useSWR from "swr";
import {
  TEMPORARY_HIGH_LIMIT,
  getCustomLabel,
  useFormWrapper,
} from "../../../../../util/util";

import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { H6 } from "../../../../../components/Typography/Typography";
import type {
  OptionType,
  ProductSelectorCSVDocument,
  StorefrontMetaData,
  TagClassificationConfig,
} from "../../../../../types/types";
import { useTranslation } from "react-i18next";
import { Flex, Form } from "../../../../../layout/FormLayout";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { FilePickerUncontrolled } from "../../../../../components/FilePicker/FilePickerUncontrolled";
import axios from "axios";
import type { AxiosError } from "axios";
import {
  FileCard,
  FileCardUploading,
} from "../../../../../components/FileCard/FileCard";
import { useFieldArray } from "react-hook-form";
import { SelectWithDeleteButton } from "../../../../../layout/shared";
import { AsyncSearchSelect } from "../../../../../components/AsyncSearchSelect/AsyncSearchSelect";
import { debounce } from "lodash";
import { endpoints } from "../../../../../endpoints";
import { SaveButtonPrimaryMedium } from "../shared";
import { Store } from "../../../../../Store";
import type { IStore } from "../../../../../Store";
import { zodSelectBoxDefault } from "../../../../../util/zod.util";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCookies } from "react-cookie";
import { ProductSelectorGuide } from "./ProductSelectorGuide";
import type { PortfolioControlsSchema } from "../../../../../types/types.PIM";

type FormOutput = {
  productSelector: { label: string; value: string }[];
};

export const EditProductSelector = ({
  selectedOptions,
  handleFormSubmit,
  filtersSettings,
  customLabels,
}: {
  selectedOptions?: OptionType[];
  filtersSettings?: PortfolioControlsSchema[];
  handleFormSubmit: () => void;
  customLabels?: TagClassificationConfig[];
}) => {
  const {
    storeState: { storefront_id, storefront_metadata, slug },
    storeDispatch,
  } = useContext<IStore>(Store);
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const [uploadingFile, setUploadingFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectorOptions, setSelectorOptions] = useState<OptionType[]>();
  const [isProductSelectorEnabled, setIsProductSelectorEnabled] =
    useState<boolean>(storefront_metadata.is_product_selector_enabled);

  const [cookies] = useCookies([`preferred-language-${slug}`]);

  const [isCSVEnabled, setIsCSVEnabled] = useState(
    storefront_metadata.product_selector_csv_upload_enabled
  );
  const [productSelectorCSV, setProductSelectorCSV] =
    useState<ProductSelectorCSVDocument | null>();

  const EditProductSelectorSchema = zod.object({
    productSelector: isProductSelectorEnabled
      ? zod.array(zodSelectBoxDefault(t))
      : zod
          .array(
            zod.object({
              value: zod.string().optional(),
              label: zod.string().optional(),
            })
          )
          .optional(),
    product_selector_csv:
      isCSVEnabled && isProductSelectorEnabled && !productSelectorCSV
        ? zod.instanceof(File, { message: t("This is a required Field") })
        : zod.instanceof(File).optional(),
  });

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(EditProductSelectorSchema),
    shouldUnregister: false,
    defaultValues: {
      productSelector: selectedOptions,
    },
  });
  const methodsOfUseFieldArray = useFieldArray({
    control: methodsOfUseForm.control,
    name: "productSelector",
  });

  const { fields, append, remove } = methodsOfUseFieldArray;

  const viewStorefrontDocument = () => {
    if (productSelectorCSV) {
      window.open(productSelectorCSV.signed_url, "_blank");
    } else {
      notifyError(t("Operation failed, something went wrong."));
    }
  };

  const handleFile = async (file: File) => {
    if (file) {
      setUploadingFile(file);
      try {
        const fileFormData = new FormData();
        fileFormData.append("file", file);

        await axios.post<ProductSelectorCSVDocument>(
          `v2/storefronts/${storefront_id}/csv/selectors`,
          fileFormData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        notifySuccess(t("Document uploaded successfully"));
        storeFrontMetadataMutate();
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("Upload failed, something went wrong"),
          {
            error,
          }
        );
        setUploadingFile(undefined);
      }
    }
  };

  const deleteDocument = async () => {
    try {
      await axios.delete(
        `/v1/storefronts/${storefront_id}/documents/${productSelectorCSV?.id}` //
      );
      setProductSelectorCSV(null);
      notifySuccess(t("Document has been removed successfully."));
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error deleting the document"),
        {
          error,
        }
      );
    }
  };

  const filtersAsOptions = useCallback(
    (data: PortfolioControlsSchema[]) => {
      return data?.reduce(
        (
          prev: { label: string; value: string }[],
          filter: PortfolioControlsSchema
        ) => {
          if (filter.is_filterable) {
            return [
              ...prev,
              {
                label: getCustomLabel({
                  filter_type: filter.filter_type,
                  tag_classification_configs: customLabels ?? [],
                  preferred_language: cookies[`preferred-language-${slug}`],
                }),
                value: filter.filter_type,
              },
            ];
          }
          return prev;
        },
        []
      );
    },
    [cookies, slug, customLabels]
  );

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const handleSelectorSearch = useCallback(
    debounce((query, setOptions) => {
      const getOptions = async (query: string) => {
        try {
          const { data } = await axios.get<{ data: PortfolioControlsSchema[] }>(
            `/v2/storefronts/${storefront_id}/portfolio-controls`,
            {
              params: { limit: TEMPORARY_HIGH_LIMIT, q: query, order: "asc" },
            }
          );
          return filtersAsOptions(data.data);
        } catch (error) {
          notifyError(
            t("Could not fetch selector. Something went wrong.", { error })
          );
          return [];
        }
      };
      getOptions(query).then((options) => setOptions(options));
    }, 1000),
    []
  );

  const { data: storeFrontMetadataRes, mutate: storeFrontMetadataMutate } =
    useSWR<StorefrontMetaData, AxiosError<StorefrontMetaData>>(
      endpoints.v1_storefronts_id_metadata(storefront_id)
    );

  const fieldsValues = methodsOfUseForm.watch("productSelector");

  const onSubmit = async (inputs: FormOutput) => {
    setLoading(true);
    try {
      await axios.patch(endpoints.v1_storefronts_id_metadata(storefront_id), {
        is_product_selector_enabled: isProductSelectorEnabled,
        product_selector_csv_upload_enabled: isCSVEnabled,
      });
      await storeFrontMetadataMutate();
      if (isProductSelectorEnabled) {
        const postBody = {
          selectors: inputs.productSelector.map((field, index) => {
            return { name: field.value, order: index + 1 };
          }),
        };
        setLoading(true);

        await axios.post(
          endpoints.v2_storefronts_id_product_selectors(storefront_id),
          postBody
        );
      }
      notifySuccess(t("Your changes have been saved successfully"));
      handleFormSubmit();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not update settings. Please try again later."),
        {
          error,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filtersSettings) {
      setSelectorOptions(filtersAsOptions(filtersSettings));
    }
  }, [filtersSettings, filtersAsOptions]);

  useEffect(() => {
    if (fields.length === 0) {
      append({ label: undefined, value: undefined });
    }
  }, [append, fields]);

  useEffect(() => {
    if (storeFrontMetadataRes) {
      setUploadingFile(undefined);
      storeDispatch({
        type: "SET_STOREFRONT_METADATA",
        payload: storeFrontMetadataRes,
      });

      if (storeFrontMetadataRes?.product_selector_csv_document) {
        setProductSelectorCSV(
          storeFrontMetadataRes?.product_selector_csv_document
        );
      }
    }
  }, [storeFrontMetadataRes, storeDispatch]);

  return (
    <Form style={{ maxWidth: "550px" }}>
      <Flex style={{ gap: "8px" }}>
        <ToggleSwitch
          isChecked={isProductSelectorEnabled}
          onClick={() => setIsProductSelectorEnabled((prev) => !prev)}
        />
        <H6>{t("Product Selector")}</H6>
      </Flex>

      <ProductSelectorGuide />
      {isProductSelectorEnabled && (
        <>
          {fields.map((field, index) => (
            <SelectWithDeleteButton key={field.id}>
              <AsyncSearchSelect
                options={selectorOptions?.filter(
                  (option) =>
                    !fieldsValues?.find((field) => field.value === option.value)
                )}
                name={`productSelector[${index}]`}
                error={
                  methodsOfUseForm.errors?.productSelector
                    ? methodsOfUseForm.errors?.productSelector[index]?.value
                    : undefined
                }
                errors={methodsOfUseForm.errors}
                formState={methodsOfUseForm.formState}
                placeholder={t(`Selector ${index + 1}`)}
                searchFunction={handleSelectorSearch}
                onChange={(data: any) => {
                  methodsOfUseForm.setValue(`productSelector.${index}`, data);
                  if (
                    methodsOfUseForm.errors?.productSelector &&
                    methodsOfUseForm.errors?.productSelector[index]
                  ) {
                    methodsOfUseForm.clearErrors(`productSelector.${index}`);
                  }
                }}
                value={
                  field.value
                    ? { value: field.value, label: field.label }
                    : undefined
                }
                testid={`product_selector_${index}`}
              />
              <DeleteButton
                testid={`delete-button-${index}`}
                onClick={() => remove(index)}
                type="button"
                height={20}
                width={20}
              />
            </SelectWithDeleteButton>
          ))}
          {fields.length <= 4 && (
            <PrimaryButtonSmall
              type="button"
              onClick={() =>
                append({
                  label: undefined,
                  value: undefined,
                })
              }
            >
              {t("Add")}
            </PrimaryButtonSmall>
          )}

          <Flex style={{ gap: "8px", marginTop: "20px" }}>
            <ToggleSwitch
              isChecked={isCSVEnabled}
              onClick={() => setIsCSVEnabled((prev) => !prev)}
            />
            <H6>{t("Upload CSV")}</H6>
          </Flex>
          {isCSVEnabled && (
            <>
              {!uploadingFile && !productSelectorCSV && (
                <>
                  <FilePickerUncontrolled
                    methodsOfUseForm={methodsOfUseForm}
                    placeHolderText={t("Drag and drop Product Selector CSV")}
                    name={"product_selector_csv"}
                    accept={".csv"}
                    handleFile={handleFile}
                    required={isCSVEnabled && !productSelectorCSV}
                  />
                </>
              )}
              {uploadingFile && (
                <FileCardUploading fileName={uploadingFile.name} />
              )}
              {productSelectorCSV && (
                <FileCard
                  file={productSelectorCSV}
                  viewFile={viewStorefrontDocument}
                  deleteProps={{
                    deleteFile: deleteDocument,
                    confirmMessage: t(
                      "Are you sure you want to delete the Product Selector CSV?"
                    ),
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      <SaveButtonPrimaryMedium
        loading={loading}
        onClick={methodsOfUseForm.handleSubmit(onSubmit)}
      >
        {t("Save your changes")}
      </SaveButtonPrimaryMedium>
    </Form>
  );
};
