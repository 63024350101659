import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../../../components/Auth";
import { NavBarTabs } from "../../../../../layout/shared/Nav/NavBar";
import type { Permission } from "../../../../../types/types";
import type { PIMAttributesClassification } from "../../../../../types/types.PIM";
import { useRoutePath } from "../../../../../util/Routing";

type AttributesNavProps = {
  tabName: "Attributes" | "Groups" | "Collections";
  params?: URLSearchParams | undefined;
};

/**
 * This supports a union of the potential tab names instead of an index because
 * user permissions can result in this component displaying between 1 and 3 tabs
 * so passing in a number at the callsite is not a reliable way of getting the
 * tab you are expecting.
 * 
 * usage:
 * <PageWrapper>
      <AttributesNav tabName="Collections" />
      {other components come here}
    </PageWrapper>
 */

export function AttributesNav({
  tabName,
  params,
}: AttributesNavProps): JSX.Element {
  const { adminPath } = useRoutePath();
  const { t } = useTranslation();
  const history = useHistory();
  const { hasPermission } = useAuthContext();

  const tabs: {
    url_part: "attributes" | "collections" | "groups";
    permission: Permission;
    translation: string;
  }[] = [
    {
      url_part: "attributes",
      permission: "view_attributes",
      translation: t("Attributes"),
    },
    { url_part: "groups", permission: "view_groups", translation: t("Groups") },
    {
      url_part: "collections",
      permission: "view_collections",
      translation: t("Collections"),
    },
  ];

  // This is not onelineable because typescript will widen the type Permission
  // to string
  const tabsUserHasPermissionToView = tabs.reduce((acc, tab) => {
    if (hasPermission(tab.permission)) {
      acc.push(tab.translation);
    }
    return acc;
  }, [] as string[]);

  const indexToDisplay = tabsUserHasPermissionToView.findIndex(
    (value) => value === tabName
  );

  const getAttributeNavPath = (index: PIMAttributesClassification) => {
    const translatedTabName = tabsUserHasPermissionToView[index];
    const tab = tabs.find((tab) => tab.translation === translatedTabName);

    return tab?.url_part as string;
  };

  const handleTabsChange = (index: PIMAttributesClassification): void => {
    const pathPart = (() => {
      const possiblePath = getAttributeNavPath(index);
      return possiblePath !== "attributes" ? possiblePath : "";
    })();

    history.replace(
      `${adminPath}/pim/attributes${pathPart ? `/${pathPart}` : ""}${
        params ? `?${params}` : ""
      }`
    );
  };

  return (
    <NavBarTabs
      handleTabsChange={handleTabsChange}
      pageTitle={t("Attributes")}
      tabs={tabsUserHasPermissionToView}
      tabIndex={indexToDisplay}
    />
  );
}
