import type { IIconProps } from "../Icons/Icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components/macro";
import { Badge } from "../../layout/shared";
import { useQuery } from "../../util/hooks";
import { useHistory, useLocation } from "react-router-dom";

interface IContainerGreyLineProps {
  noBorder?: boolean;
}

const ContainerGreyLine = styled.ol<IContainerGreyLineProps>`
  border-bottom: ${({ noBorder, theme }) =>
    noBorder ? 0 : `1px solid ${theme.secondaryBorder}`};
  padding-left: 0;
  list-style: none;
  display: flex;
`;

const ActiveTab = styled.li`
  background-color: ${({ theme }) => theme.primaryBG};
  border-bottom: 4px solid ${({ theme }) => theme.selectedBorder};
  cursor: pointer;
  margin-right: 48px;
  white-space: nowrap;
  padding-bottom: 12px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  svg {
    position: relative;
    top: 3px;
    left: 0px;
    width: 15px;
    height: 15px;
    margin-right: 11px;
    fill: ${(props) => props.theme.primaryLinkColor};
    path,
    circle {
      fill: ${(props) => props.theme.primaryLinkColor};
    }
  }

  :hover {
    color: ${(props) => props.theme.primaryLinkHover};
    svg {
      fill: ${(props) => props.theme.primaryLinkHover};
    }
    path,
    circle {
      fill: ${(props) => props.theme.primaryLinkHover};
      stroke: ${(props) => props.theme.primaryLinkHover};
    }
  }

  ${Badge} {
    height: 17px;
    min-width: 17px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    padding: 2px 3px;
  }
`;

const InactiveTab = styled.li`
  padding-bottom: 16px;
  margin-right: 48px;
  cursor: pointer;
  white-space: nowrap;
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  svg {
    position: relative;
    top: 3px;
    left: 0px;
    width: 15px;
    height: 15px;
    margin-right: 11px;
  }
  :hover {
    color: ${(props) => props.theme.primaryLinkHover};
  }

  ${Badge} {
    height: 17px;
    min-width: 17px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    padding: 2px 3px;
  }
`;

interface ITabs {
  children: JSX.Element | JSX.Element[];
  selectedIndex?: string;
  noBorder?: boolean;
  allowDeepLink?: boolean;
}

interface ISetTab {
  activeTabLabel: string;
  label: string;
  onClick: (_: string) => void;
  Icon?: (props: IIconProps) => JSX.Element;
}

interface ITab {
  icon?: (props: IIconProps) => JSX.Element;
  label: string;
  children: JSX.Element | JSX.Element[];
  onClick?: (_: string) => void;
}
// eslint-ignore
/**
 * @deprecated
 * Use ReachTabs instead
 */
export function Tab({ icon, label, onClick }: ITab) {
  // This only exists as an external means of passing the label and Icon to SetTab
  return null;
}

function SetTab({ activeTabLabel, label, onClick, Icon }: ISetTab) {
  const handleLocalClick = () => onClick(label);
  const theme = useTheme();
  const { t } = useTranslation();
  const tabTranslationGuard = (maybeString: string | undefined) => {
    switch (maybeString) {
      case "Company Info":
        return t("Company Info");
      case "Products":
        return t("Products");
      case "Settings":
        return t("Settings");
      case "Users":
        return t("Users");
      case "Theme & Content":
        return t("Theme & Content");
      case "Terms":
        return t("Terms");
      case "Product Packaging":
        return t("Product Packaging");
      case "Notifications":
        return t("Notifications");
      case "Price Tiers":
        return t("Price Tiers");
      default:
        return maybeString;
    }
  };
  if (activeTabLabel === label) {
    return (
      <ActiveTab onClick={handleLocalClick}>
        <span>
          {Icon && <Icon fill={theme.navIconActive} />}
          {tabTranslationGuard(label)}
        </span>
      </ActiveTab>
    );
  } else {
    return (
      <InactiveTab onClick={handleLocalClick}>
        {Icon && <Icon />}
        {tabTranslationGuard(label)}
      </InactiveTab>
    );
  }
}

/**
 * @deprecated
 * use ReachTabs instead
 */
export function Tabs({
  children,
  selectedIndex,
  noBorder,
  allowDeepLink,
}: ITabs) {
  const childArray = Array.isArray(children) ? children : [children];
  const urlQuery = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    // In practice when there are multiple tabs the `find` below should always
    // return something. If it doesn't it's a bug in the parent. The fallback
    // is to make the first tab the active tab.
    (selectedIndex &&
      childArray.find((child) => child.props.label === selectedIndex)?.props
        .label) ||
      childArray[0]?.props.label ||
      ""
  );

  const onClickTabItem = (label: string) => {
    setActiveTab(label);
    if (allowDeepLink) {
      const params = new URLSearchParams({ tab: label });
      history.push({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  };

  useEffect(() => {
    const tab = urlQuery.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [urlQuery]);

  return (
    <div style={{ display: "inlineBlock" }}>
      <ContainerGreyLine noBorder={noBorder}>
        {childArray.map((child) => {
          const { label, icon, onClick } = child.props;

          return (
            <SetTab
              activeTabLabel={activeTab}
              key={label}
              label={label}
              onClick={(label: string) => {
                if (onClick) onClick(label);
                onClickTabItem(label);
              }}
              Icon={icon}
            />
          );
        })}
      </ContainerGreyLine>
      <div className="tab-content">
        {childArray.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
}
