import styled, { useTheme } from "styled-components";
import type { Assets, GridItemProps } from "../../../../../../types/types.PIM";
import {
  GetDocumentIcon,
  TrimmedName,
  get_content_type,
} from "../util/AssetsUtil";
import {
  ActionsIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  LockIcon,
  NonVisibleIcon,
  UnLockIcon,
  VisibleIcon,
} from "../../../../../../components/Icons/Icons";
import { InvisibleButton } from "../../../../../../components/Buttons/Buttons";
import {
  RenderOnViewportEntry,
  useStoreState,
} from "../../../../../../util/util";
import { DropDown } from "../../../../../../components/DropDown/DropDown";
import {
  RegularTextSmall,
  SmallText,
  SoftHeader2,
} from "../../../../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { AssetDocViewer } from "./AssetDocViewer";
import type { Row } from "react-table";
import { CheckBoxNoLabel } from "../../../../../../components/CheckBoxes/CheckBoxes";
import { useEffect, useState } from "react";
import { DocumentPreview } from "./DocumentPreview";
import ReactTooltip from "react-tooltip";

const GridContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 24px;
  gap: 24px;
`;

const GridItemContainer = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  cursor: pointer;
  height: 300px;
  > div:first-child {
    display: flex;
    align-items: center;
    flex: 0.2;
    gap: 8px;
  }
  .grid_item_dropdown {
    z-index: 2;
    > button {
      padding: 0;
    }
  }
`;

const DocViewerContainer = styled.div<{ can_view: boolean }>`
  position: relative;
  background-color: #eee; // match the pdf container background
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: ${({ can_view }) => (can_view ? "pointer" : "not-allowed")};
  .lock_icon {
    display: ${({ can_view }) => (can_view ? "none" : "flex")};
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 45%;
    left: 45%;
  }
`;

const GridDocumentIconContainer = styled.div`
  flex: 0.2;
`;

const AssetNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

const DropDownItemContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const CheckBoxContainer = styled.div`
  position: absolute;
  right: 0;
  top: 12px;
`;

type GridAssetsSelectProps = {
  selectedItems?: Row<Assets>[];
  handleSelectedItems?: (rows: Row<Assets>[]) => void;
};

type GridItemSelectProps = {
  can_select?: boolean;
  is_selected?: boolean;
  on_select_item?: (asset: Assets) => void;
};

const GridItem = ({
  asset,
  handle_asset_visibility_change,
  on_edit_asset,
  on_download_asset,
  on_remove_asset,
  is_selected,
  can_select,
  on_select_item,
  assets,
  is_existing_asset_page = false,
}: {
  asset: Assets & GridItemProps;
  assets: (Assets & GridItemProps)[];
  handle_asset_visibility_change?: (asset: Assets) => void;
  on_edit_asset?: (asset: Assets) => void;
  on_download_asset?: (asset: Assets) => void;
  on_remove_asset?: (asset: Assets) => void;
  is_existing_asset_page?: boolean;
} & GridItemSelectProps) => {
  const [show_preview, set_show_preview] = useState(false);
  const { is_publishing_product } = useStoreState();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    can_edit,
    can_change_visibility,
    can_delete,
    can_external_download = true,
  } = asset;
  const actionItems = [
    ...(can_edit && !!on_edit_asset
      ? [
          {
            name: "edit",
            item: (
              <DropDownItemContainer
                onClick={() => {
                  on_edit_asset(asset);
                }}
              >
                <EditIcon width={20} height={20} />
                <span id="edit-dropdown-text">{t("Edit")}</span>
              </DropDownItemContainer>
            ),
          },
        ]
      : []),
    ...(can_external_download && !!on_download_asset
      ? [
          {
            name: "download",
            item: (
              <DropDownItemContainer
                onClick={() => {
                  on_download_asset(asset);
                }}
              >
                <DownloadIcon width={20} height={20} />
                <span id="download-dropdown-text">{t("Download")}</span>
              </DropDownItemContainer>
            ),
          },
        ]
      : []),
    ...(can_delete && !!on_remove_asset
      ? [
          {
            name: "delete",
            item: (
              <DropDownItemContainer
                onClick={() => {
                  on_remove_asset(asset);
                }}
              >
                <DeleteIcon width={20} height={20} fill={theme.errorColor} />
                <span id="delete-dropdown-text">{t("Remove")}</span>
              </DropDownItemContainer>
            ),
          },
        ]
      : []),
  ];

  const get_action_item = (name: string, is_existing_asset_page: boolean) => {
    if (name === "edit") {
      return can_edit && !!on_edit_asset ? (
        <InvisibleButton
          style={{ flex: 0.2, justifySelf: "flex-end", padding: 0 }}
          onClick={() => {
            on_edit_asset(asset);
          }}
        >
          <EditIcon width={20} height={20} />
        </InvisibleButton>
      ) : (
        <></>
      );
    } else if (name === "download") {
      return can_external_download &&
        !!on_download_asset &&
        !is_existing_asset_page ? (
        <InvisibleButton
          style={{ flex: 0.2, justifySelf: "flex-end", padding: 0 }}
          onClick={() => {
            on_download_asset(asset);
          }}
        >
          <DownloadIcon width={20} height={20} />
        </InvisibleButton>
      ) : (
        <></>
      );
    } else {
      return can_delete && !!on_remove_asset ? (
        <InvisibleButton
          style={{ flex: 0.2, justifySelf: "flex-end", padding: 0 }}
          onClick={() => {
            on_remove_asset(asset);
          }}
        >
          <DeleteIcon width={20} height={20} fill={theme.errorColor} />
        </InvisibleButton>
      ) : (
        <></>
      );
    }
  };

  const get_tooltip_message = (asset_type: Assets["asset_type"]) => {
    return asset_type === "document"
      ? t("Register to access this document")
      : asset_type === "image"
      ? t("Register to access this image")
      : t("Register to access this video");
  };

  const handle_select_item = () => {
    if (on_select_item) {
      on_select_item(asset);
    }
  };

  const on_preview_click = () => {
    if (can_external_download) {
      set_show_preview(true);
    }
  };

  const assets_to_preview = assets.filter((a) => a.can_external_download);

  return (
    <GridItemContainer>
      <div>
        <GridDocumentIconContainer onClick={on_preview_click}>
          <GetDocumentIcon
            asset_type={asset.asset_type}
            file_type={get_content_type(asset.content_type ?? "pdf")}
          />
        </GridDocumentIconContainer>
        <AssetNameContainer onClick={on_preview_click}>
          <TrimmedName text={asset.name} Content={SoftHeader2} />
          <RegularTextSmall>{asset.asset_category}</RegularTextSmall>
        </AssetNameContainer>
        {can_change_visibility && !!handle_asset_visibility_change && (
          <>
            {asset.asset_type === "document" ? (
              <InvisibleButton
                disabled={is_publishing_product}
                onClick={() => {
                  handle_asset_visibility_change(asset);
                }}
              >
                {asset.is_downloadable ? (
                  <UnLockIcon
                    fill={
                      is_publishing_product ? theme.disabledButtonBG : undefined
                    }
                    width={20}
                    height={20}
                  />
                ) : (
                  <LockIcon
                    fill={
                      is_publishing_product ? theme.disabledButtonBG : undefined
                    }
                    width={20}
                    height={20}
                  />
                )}
              </InvisibleButton>
            ) : (
              <InvisibleButton
                disabled={
                  is_publishing_product ||
                  (asset.is_downloadable && asset.is_cover_image)
                }
                onClick={() => {
                  handle_asset_visibility_change(asset);
                }}
                data-for="disabled-cover-image"
                data-tip={
                  asset.is_downloadable && asset.is_cover_image
                    ? t("You can't hide the product cover image")
                    : ""
                }
              >
                {asset.is_downloadable ? (
                  <VisibleIcon
                    width={20}
                    height={20}
                    fill={
                      asset.is_cover_image
                        ? theme.disabledLinkColor
                        : theme.successIconColor
                    }
                  />
                ) : (
                  <NonVisibleIcon
                    width={20}
                    height={20}
                    fill={theme.errorColor}
                  />
                )}
                <ReactTooltip id={`disabled-cover-image`} />
              </InvisibleButton>
            )}
          </>
        )}
        {actionItems.length > 1 ? (
          <InvisibleButton
            style={{ flex: 0.2, justifySelf: "flex-end", padding: 0 }}
          >
            <DropDown
              placeholder={<ActionsIcon />}
              showIcon={false}
              activeItem={""}
              items={[...actionItems.map(({ item }) => item)]}
              direction="right"
              clickHandler={() => {}}
              className="grid_item_dropdown"
            />
          </InvisibleButton>
        ) : actionItems.length === 1 ? (
          get_action_item(actionItems[0].name, is_existing_asset_page)
        ) : (
          <></>
        )}
      </div>
      <DocViewerContainer
        onClick={on_preview_click}
        can_view={can_external_download}
        data-for="locked_asset"
        data-tip={
          !can_external_download ? get_tooltip_message(asset.asset_type) : ""
        }
      >
        {can_external_download && (
          <AssetDocViewer mode="thumbnail" asset={asset} />
        )}
        <div className="lock_icon">
          <LockIcon fill={theme.primaryIconColor} />
        </div>
        <ReactTooltip effect="solid" id="locked_asset" />
      </DocViewerContainer>
      {can_select && (
        <CheckBoxContainer>
          <CheckBoxNoLabel
            checked={is_selected}
            onChange={() => handle_select_item()}
          />
        </CheckBoxContainer>
      )}
      {can_external_download && show_preview && (
        <DocumentPreview
          assets={assets_to_preview}
          selected_asset_idx={assets_to_preview.findIndex(
            (a) => a.id === asset.id
          )}
          onCancelPreview={() => {
            set_show_preview(false);
          }}
          onEdit={can_edit ? on_edit_asset : undefined}
          onDownload={can_external_download ? on_download_asset : undefined}
        />
      )}
    </GridItemContainer>
  );
};

export const GridAssetView = ({
  assets,
  handle_asset_visibility_change,
  on_edit_asset,
  on_download_asset,
  on_remove_asset,
  selectedItems,
  handleSelectedItems,
  is_existing_asset_page = false,
}: {
  assets: (Assets & GridItemProps)[];
  handle_asset_visibility_change?: (asset: Assets) => void;
  on_remove_asset?: (asset: Assets) => void;
  on_edit_asset?: (asset: Assets) => void;
  on_download_asset?: (asset: Assets) => void;
  is_existing_asset_page?: boolean;
} & GridAssetsSelectProps) => {
  const { t } = useTranslation();
  const [selected_rows, set_selected_rows] = useState(selectedItems);
  const update_selected_items = (asset: Assets) => {
    const row_id = assets.findIndex((item) => item.id === asset.id);
    const updated_rows = selected_rows?.some(
      (row) => row.original.id === asset.id
    )
      ? selected_rows?.filter((row) => row.original.id !== asset.id)
      : [
          ...(!!selected_rows ? selected_rows : []),
          { original: asset, id: `${row_id}` } as Row<Assets>,
        ];
    set_selected_rows(updated_rows);
    handleSelectedItems?.(updated_rows);
  };
  useEffect(() => {
    if (selectedItems) {
      set_selected_rows(selectedItems);
    }
  }, [selectedItems]);
  return (
    <GridContainer>
      {assets.length > 0 ? (
        assets.map((asset) => (
          <RenderOnViewportEntry>
            <GridItem
              key={asset.id}
              asset={asset}
              assets={assets}
              handle_asset_visibility_change={handle_asset_visibility_change}
              on_download_asset={on_download_asset}
              on_edit_asset={on_edit_asset}
              on_remove_asset={on_remove_asset}
              can_select={!!selectedItems}
              is_selected={selected_rows?.some(
                (item) => item.original.id === asset.id
              )}
              on_select_item={() => update_selected_items(asset)}
              is_existing_asset_page={is_existing_asset_page}
            />
          </RenderOnViewportEntry>
        ))
      ) : (
        <SmallText>{t("No items to show.")}</SmallText>
      )}
    </GridContainer>
  );
};
