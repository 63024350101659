import type {
  IOrderItem,
  ProductSKU,
  QuoteRequestItem,
  SkuNoPreference,
} from "../../types/types";
import React from "react";
import { getNumberOfUnits } from "../../util/QuotesAndOrders";
import { formatTotalQuantityString } from "../quoteCart/BuyerQuoteItemForm";
import { TransactionItemInfoBlock } from "./TransactionItemInfoBlock";
import { makeSkuLabel, makeNumberOfUnitsLabel } from "./TransactionItemUtils";

/**
 * Shows the quantity details for a transaction item.
 */
export const TransactionItemInfoBlockQuantity = ({
  item,
  t,
}: {
  item: QuoteRequestItem | IOrderItem;
  t: (s: string) => string;
}) => {
  return (
    <InfoBlockQuantity
      totalQuantity={item.total_quantity}
      sku={item.sku}
      numberOfUnits={getNumberOfUnits(item)}
      t={t}
    />
  );
};

/**
 * Shows the *requested* quantity details for a transaction item, e.g. on the
 * cart page.
 */
export const TransactionItemInfoBlockQuantityRequested = ({
  item,
  t,
}: {
  item: QuoteRequestItem;
  t: (s: string) => string;
}) => {
  return (
    <InfoBlockQuantity
      totalQuantity={item.requested_total_quantity}
      sku={item.requested_sku}
      numberOfUnits={item.requested_no_of_units}
      t={t}
    />
  );
};

/**
 * "Internal" implementation component.
 *
 * @see TransactionItemInfoBlockQuantity
 * @see TransactionItemInfoBlockQuantityRequested
 */
const InfoBlockQuantity = ({
  totalQuantity,
  sku,
  numberOfUnits,
  t,
}: {
  totalQuantity: string;
  sku: ProductSKU | SkuNoPreference | null;
  numberOfUnits: string | null;
  t: (s: string) => string;
}) => {
  const totalQuantityNumber = parseFloat(totalQuantity);

  const totalQuantityString = formatTotalQuantityString(
    sku?.packaging_unit?.name,
    Intl.NumberFormat("en-US").format(totalQuantityNumber)
  );

  return (
    <TransactionItemInfoBlock
      main={totalQuantityString}
      lines={
        !sku || sku.kind === "No preference SKU"
          ? []
          : [makeSkuLabel(sku), makeNumberOfUnitsLabel(numberOfUnits, t)]
      }
    />
  );
};
