import React, { useContext, useEffect, useState } from "react";
import type {
  OptionType,
  SampleRequest,
  SampleRequestDetail,
  SampleRequestSubmitLoginArgs,
  Tenant,
  User,
} from "../../../types/types";
import * as Yup from "yup";
import { strings } from "../../../util/strings";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import type {
  POCManualFormSchemaType,
  POCFormSchemaType,
} from "../../../util/util";
import {
  useStoreState,
  useFormWrapper,
  makeUrlWithParams,
  addressToOption,
  usePolicyDocuments,
  POCFormSchema,
  POCManualFormSchema,
} from "../../../util/util";
import { Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import { DesktopFormContainerCart } from "../../../components/Layout/Layout";
import {
  GoBackButton,
  PrimaryButton,
  PrimaryButtonLarge,
  TextButton,
} from "../../../components/Buttons/Buttons";
import { H3, Title } from "../../../components/Typography/Typography";
import { CartContainer } from "../../../components/Cart/Cart";
import { Form } from "../../../layout/FormLayout";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
} from "../../../components/Form/Form";
import { CheckBoxNoLabel } from "../../../components/CheckBoxes/CheckBoxes";
import { ColoredTextOnError } from "../../../util/util-components";
import type { ChipType } from "../../../components/Chips/Chips";
import { Chips } from "../../../components/Chips/Chips";
import { endpoints } from "../../../endpoints";
import useSWR from "swr";
import {
  CartSubHeader,
  ConfirmationSpacer,
  Container,
  DECISION_CHIPS,
  DOCUMENT_CHIPS,
  FormWrapper,
  Wrapper,
} from "../../public/SampleRequestCart/SampleRequestCart";
import styled from "styled-components/macro";
import { TermsOfServiceLink } from "../../../components/TermsOfServiceLink/TermsOfServiceLink";
import { PrivacyPolicyLink } from "../../../components/PrivacyPolicyLink/PrivacyPolicyLink";
import { SampleRequestItem } from "../../../components/TransactionItem/SampleRequestItem";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import { newShippingAddress } from "../../../components/quoteCart/cartUtils";
import type { RequestAddressSchemaType } from "../../public/RequestAddress/RequestAddress";
import {
  getRequestAddressSchema,
  RequestAddress,
} from "../../public/RequestAddress/RequestAddress";
import { PointOfContact } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/PointOfContactForm";
import { useAddLocation } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/EditLocations";
import type { LocationInputs } from "../../SharedPages/OrganizationPage/CompanyInfo/Edit/LocationForm";
import {
  configured_checkboxes_schema_yup,
  create_configured_checkboxes_fields,
} from "../../admin/SellerAdmin/SellerAdminSettings/TermsTab/ConfigureCheckboxsCard/utils";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { UnlistedProductForm } from "../RequestUnlistedProduct/UnlistedProductFrom";
import { Store } from "../../../Store";

const CartItemsContainer = styled.div`
  margin: 15px 0 0;
`;

const schema = (t: TFunction, pocType: "manual" | "existing") =>
  Yup.lazy((formValues: any) =>
    Yup.object()
      .shape({
        confirmation_tos: Yup.boolean().oneOf([true]),
        documents: Yup.array()
          .of(Yup.object({ id: Yup.string().defined() }).defined())
          .notRequired(),
        is_decision_maker: Yup.object()
          .shape({
            value: Yup.boolean().defined(),
            label: Yup.string().defined(),
          })
          .nullable()
          .required(strings(t).thisIsARequiredField),
        decision_timeline: Yup.array(
          Yup.object().shape({ name: Yup.string().defined() }).defined()
        ).defined(),
        shipping_address: Yup.object()
          .shape({
            value: Yup.string().defined(),
            label: Yup.string().defined(),
          })
          .defined()
          .required(strings(t).thisIsARequiredField),
        ...(formValues.shipping_address?.value === "new_address"
          ? {
              ...getRequestAddressSchema(t, true),
              ...(pocType === "manual"
                ? POCManualFormSchema(t, formValues)
                : POCFormSchema(t)),
            }
          : {}),
        configured_checkboxes: configured_checkboxes_schema_yup({
          formValues,
          t,
        }),
      })
      .required()
  );

// Is using Yup.InferType really better? The types are harder to understand
// than if we had specified them using regular TypeScript types.
interface SubmitSampleRequestCartForm extends RequestAddressSchemaType {
  confirmation_tos: boolean;
  documents: { id: string }[];
  is_decision_maker: { label: string; value: boolean };
  decision_timeline: { name: string }[];
  shipping_address: { label: string; value: string };
  configured_checkboxes?: { value: boolean; label: string }[];
}

interface SubmitSampleRequestCartPOCManualForm
  extends SubmitSampleRequestCartForm,
    POCManualFormSchemaType {}

interface SubmitSampleRequestCartPOCForm
  extends SubmitSampleRequestCartForm,
    POCFormSchemaType {}

/**
 * The sample request cart page for buyer and buyer admin users.
 */
export const BuyerSampleRequestCart = ({
  user,
  sampleRequest,
  mutateSampleRequest,
  goToParentPage,
}: {
  user: User;
  sampleRequest: SampleRequest;
  mutateSampleRequest: () => void;
  goToParentPage: () => void;
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const {
    storefront_id,
    storefront_metadata: { configured_checkboxes },
  } = useStoreState();
  const { t } = useTranslation();
  const { privacyPolicy, termsOfSalePolicy } = usePolicyDocuments();
  const [showSlideOut, setShowSlideOut] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addLocation = useAddLocation({ buyer_tenant_id: user.tenant_id });
  const {
    storeState: { storefront_metadata },
  } = useContext(Store);
  // Fetch the tenant data to get the shipping address options.
  const { data: tenantData } = useSWR<Tenant>(
    endpoints.v1_storefronts_id_tenants_id(storefront_id, user.tenant_id),
    {
      onError: (error) => {
        notifyError(t("There was an error fetching your shipping addresses"), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const [pointOfContactType, setPointOfContactType] =
    useState<"manual" | "existing">("existing");

  const shippingAddresses = tenantData
    ? tenantData.addresses.filter((addr) => addr.type === "Warehouse")
    : [];

  const shippingAddressOptions = [
    ...shippingAddresses.map(addressToOption),
    newShippingAddress(t),
  ];

  const shippingAddressDefaultValue =
    sampleRequest && sampleRequest.shipping_address
      ? addressToOption(sampleRequest.shipping_address)
      : undefined;

  const methodsOfUseForm = useFormWrapper({
    resolver: yupResolver(schema(t, pointOfContactType)),
  });

  const {
    register,
    handleSubmit,
    formState,
    errors,
    control,
    setValue,
    watch,
  } = methodsOfUseForm;

  const { fields, append } = useFieldArray({
    control,
    name: "configured_checkboxes",
  });

  const sampleCheckboxes = configured_checkboxes.filter((item) => item.sample);

  useEffect(() => {
    if (sampleCheckboxes.length > 0 && fields?.length < 1) {
      sampleCheckboxes.forEach((element) => {
        append({ value: false, label: element.name, id: element.id });
      });
    }
  }, [sampleCheckboxes, append, fields]);
  useEffect(() => {
    register({ name: "documents", required: false });
    register({ name: "decision_timeline", required: true });
  }, [register]);

  const handleDecisionTimelineClick = (chips: ChipType[]) => {
    setValue("decision_timeline", chips);
  };

  const handleDocumentsClick = (chips: ChipType[]) => {
    setValue("documents", chips);
  };

  const watchedShippingAddress = watch("shipping_address") as
    | OptionType<string>
    | undefined;

  const onFormSubmit = async <T extends SubmitSampleRequestCartForm>(
    values: T
  ) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const validatedData =
        pointOfContactType === "manual"
          ? ((await schema(t, pointOfContactType).validate(
              values
            )) as SubmitSampleRequestCartPOCManualForm)
          : ((await schema(t, pointOfContactType).validate(
              values
            )) as SubmitSampleRequestCartPOCForm);
      let newAddress: OptionType<string> | null = null;
      if (values.shipping_address.value === "new_address") {
        const inputs: LocationInputs = {
          company_name: validatedData?.company_name ?? "",
          external_id: validatedData?.external_id ?? "",
          address1: validatedData.address1,
          address2: validatedData.address2,
          country: validatedData.country,
          county: validatedData.county,
          state: validatedData.state,
          city: validatedData.city,
          postal_code: validatedData.postal_code,
          is_active: true,
          contact_first_name: (
            validatedData as SubmitSampleRequestCartPOCManualForm
          ).contact_first_name,
          contact_last_name: (
            validatedData as SubmitSampleRequestCartPOCManualForm
          ).contact_last_name,
          email_address: (validatedData as SubmitSampleRequestCartPOCManualForm)
            .email_address,
          phone_number: (validatedData as SubmitSampleRequestCartPOCManualForm)
            .phone_number,
          country_code: (validatedData as SubmitSampleRequestCartPOCManualForm)
            .country_code,
          point_of_contact_id: (validatedData as SubmitSampleRequestCartPOCForm)
            .point_of_contact_id,
        };
        const newLocation = await addLocation({
          inputs,
          pocType: pointOfContactType,
        });
        if (newLocation) {
          newAddress = addressToOption(newLocation);
        }
      }
      const hasAddress =
        values.shipping_address.value === "new_address"
          ? Boolean(newAddress)
          : Boolean(values.shipping_address.value);
      if (hasAddress) {
        try {
          const docIds = values.documents?.map((doc) => doc.id) || [];

          const requestBody: SampleRequestSubmitLoginArgs = {
            is_decision_maker: values.is_decision_maker.value,
            requested_documents: docIds,
            decision_timeline: values.decision_timeline[0].name,
            shipping_address_id: newAddress
              ? newAddress.value
              : values.shipping_address.value,
          };

          await Axios.post(
            makeUrlWithParams(
              endpoints.v1_storefronts_id_sampleRequests_id(
                storefront_id,
                sampleRequest.id
              ),
              {
                source: "logged_in",
              }
            ),
            requestBody
          );

          notifySuccess(t("Cart submitted successfully"));
          setIsSubmitting(false);
          goToParentPage();
          mutateSampleRequest();
        } catch (error) {
          if (newAddress) {
            try {
              await Axios.delete(
                `/v1/tenants/${user.tenant_id}/addresses/${newAddress.value}`
              );
            } catch (_) {
              notifyError(t("There was an error cleaning up the address."));
            }
          }
          notifyError(t("There was an error submitting the cart"), { error });
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
      }
    }
  };

  const removeCartItem = async (itemToRemove: SampleRequestDetail) => {
    try {
      await Axios.delete(
        endpoints.v1_storefronts_id_sampleRequests_id_items_id(
          storefront_id,
          sampleRequest.id,
          itemToRemove.id
        )
      );
      notifySuccess("The item has been deleted successfully");
      mutateSampleRequest();
    } catch (error) {
      notifyError("There was an error deleting the item", { error });
    }
  };

  const termsOfSaleLink = () => {
    return !!termsOfSalePolicy ? (
      !!privacyPolicy ? (
        <>
          {t("I agree to the ")}
          <TermsOfServiceLink />
          {t(" & ")}
        </>
      ) : (
        <>
          {t("I accept the ")}
          <TermsOfServiceLink />
          {"."}
        </>
      )
    ) : null;
  };

  const privacyPolicyLink = () => {
    return !!privacyPolicy ? (
      !!termsOfSalePolicy ? (
        <>
          <PrivacyPolicyLink customPolicy />
          {"."}
        </>
      ) : (
        <>
          {t("I accept the ")}
          <PrivacyPolicyLink customPolicy />
          {"."}
        </>
      )
    ) : null;
  };

  return (
    <Wrapper>
      <DesktopFormContainerCart>
        <TextButton onClick={goToParentPage}>
          <GoBackButton text={t("Portfolio")} />
        </TextButton>
        <Title>{t("Sample Request")}</Title>
        <CartContainer>
          <CartSubHeader>{t("Request a sample for")}</CartSubHeader>
          <CartItemsContainer>
            {sampleRequest.items.map((item, index) => {
              return (
                <SampleRequestItem
                  item={item}
                  handleConfirmDelete={() => removeCartItem(item)}
                  index={index}
                  key={index}
                />
              );
            })}
          </CartItemsContainer>
          {storefront_metadata.unlisted_product_requests_enabled && (
            <div>
              <PrimaryButton
                onClick={() => setShowSlideOut(true)}
                style={{ padding: "5px 8px", margin: "10px 0" }}
              >
                {t("Add unlisted product")}
              </PrimaryButton>
              <SlideOut
                closeFlyout={() => setShowSlideOut(false)}
                show={showSlideOut}
              >
                <UnlistedProductForm
                  handleCloseFlyout={() => setShowSlideOut(false)}
                />
              </SlideOut>
            </div>
          )}
        </CartContainer>
        <Container>
          <H3>{t("Details")}</H3>
          <FormWrapper>
            <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
              <Chips
                selectionMode={"many"}
                header={t("Select all you wish to receive")}
                chips={DOCUMENT_CHIPS}
                handleClick={handleDocumentsClick}
                errors={errors}
                name="documents"
              />
              <H3>Your Information</H3>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="is_decision_maker"
                placeholder={t("Are you the decision maker?")}
                id="is_decision_maker"
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                defaultValue={null}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
              />
              <Chips
                selectionMode={"single"}
                header={t("Decision Timeline")}
                chips={DECISION_CHIPS}
                handleClick={handleDecisionTimelineClick}
                errors={errors}
                name={"decision_timeline"}
              />

              <H3>{t("Shipping Address")}</H3>
              <Controller
                as={SelectBoxV2}
                control={control}
                name="shipping_address"
                placeholder={t("Shipping Address")}
                id="shipping_address"
                options={shippingAddressOptions}
                defaultValue={shippingAddressDefaultValue}
                rules={{ required: true }}
                errors={errors}
                formState={formState}
              />
              {watchedShippingAddress &&
              watchedShippingAddress.value === "new_address" ? (
                <>
                  <RequestAddress
                    methodsOfUseForm={methodsOfUseForm}
                    hideBillingAddress
                    showBillingAddressOption={false}
                    showLocationId={true}
                    formTitle={t("Add Address")}
                  />
                  <PointOfContact
                    methodsOfUseForm={methodsOfUseForm}
                    pointOfContactType={pointOfContactType}
                    setPointOfContactType={setPointOfContactType}
                    defaultExistingUser={user}
                  />
                </>
              ) : (
                <></>
              )}
              <ConfirmationSpacer>
                {(!!privacyPolicy || !!termsOfSalePolicy) && (
                  <CheckBoxContainer>
                    <div style={{ width: "22px", marginRight: "15px" }}>
                      <CheckBoxNoLabel
                        name="confirmation_tos"
                        id="confirmation_tos"
                        ref={register({ required: true })}
                        error={!!errors.confirmation_tos?.message}
                      />
                    </div>
                    <CheckBoxFinePrintLabel htmlFor="confirmation_tos">
                      <ColoredTextOnError
                        isError={!!errors.confirmation_tos?.message}
                      >
                        <>
                          {termsOfSaleLink()}
                          {privacyPolicyLink()}
                        </>
                      </ColoredTextOnError>
                    </CheckBoxFinePrintLabel>
                  </CheckBoxContainer>
                )}
              </ConfirmationSpacer>
              {fields.length > 0 && (
                <>
                  {create_configured_checkboxes_fields({
                    fields,
                    methodsOfUseForm,
                    t,
                  })}
                </>
              )}
              <PrimaryButtonLarge
                style={{ minWidth: "242px" }}
                loading={isSubmitting}
              >
                {t("Request Sample")}
              </PrimaryButtonLarge>
            </Form>
          </FormWrapper>
        </Container>
      </DesktopFormContainerCart>
    </Wrapper>
  );
};
