import React, { useContext } from "react";
import type { CSSProperties } from "styled-components/macro";
import styled, { ThemeContext } from "styled-components/macro";
import type { ProductStatusType } from "../../types/types.PIM";
import type { TFunction } from "react-i18next";

const StatusState = styled.div`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-transform: capitalize;
`;

const StatusContainer = styled.div`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-transform: capitalize;
  padding: 0 4px;
  border-radius: 3px;
`;

const LeftDot = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`;

const RightDot = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
`;

export type StatusColor =
  | "blue"
  | "fullBlue"
  | "green"
  | "orange"
  | "red"
  | "gray"
  | "yellow";

export type ProductStatusColor = "green" | "orange" | "yellow" | "gray" | "red";

export type StatusPropsBase = {
  text: string;
  textStyle?: CSSProperties;
  id?: string;
};

export type ProductStatus = {
  color: ProductStatusColor;
  productStatusType?: "product";
};

export type DefaultStatus = {
  color: StatusColor;
  productStatusType?: "default";
};

export type IStatusProps = StatusPropsBase & (ProductStatus | DefaultStatus);

const colorKeys: Record<
  StatusColor,
  { dotKey: string; textKey: string | null }
> = {
  blue: { dotKey: "primaryBlue", textKey: null },
  fullBlue: { dotKey: "primaryBlue", textKey: "primaryBlue" },
  green: { dotKey: "statusTextGreen", textKey: "mediumGreen" },
  orange: { dotKey: "moreBurntOrange", textKey: "burntOrange" },
  red: { dotKey: "red-2", textKey: "red-1" },
  gray: { dotKey: "gray-2", textKey: "gray-1" },
  yellow: { dotKey: "productStatusYellow", textKey: "yellow" },
};

const productColorKeys: Record<
  ProductStatusColor,
  { dotKey: string; textKey?: string }
> = {
  green: { dotKey: "activeGreen" },
  orange: { dotKey: "productStatusOrange" },
  yellow: { dotKey: "productStatusYellow" },
  gray: { dotKey: "grayText" },
  red: { dotKey: "errorRedBorder" },
};

const getTestId = (id: string | undefined) =>
  id ? `status-text-${id}` : "status-text";

export const StatusLeft = ({
  color,
  text,
  textStyle,
  id,
  productStatusType = "default",
}: IStatusProps) => {
  const theme = useContext(ThemeContext);
  const { dotKey, textKey } =
    productStatusType === "default"
      ? colorKeys[color as StatusColor]
      : productColorKeys[color as ProductStatusColor];
  return (
    <StatusState style={textKey ? { color: theme.colors[textKey] } : undefined}>
      <LeftDot style={{ backgroundColor: theme.colors[dotKey] }} />
      <span style={textStyle} data-testid={getTestId(id)}>
        {text}
      </span>
    </StatusState>
  );
};

export const StatusRight = ({
  color,
  text,
  textStyle,
  id,
  productStatusType = "default",
}: IStatusProps) => {
  const theme = useContext(ThemeContext);
  const { dotKey, textKey } =
    productStatusType === "default"
      ? colorKeys[color as StatusColor]
      : productColorKeys[color as ProductStatusColor];
  return (
    <StatusState style={textKey ? { color: theme.colors[textKey] } : undefined}>
      <span style={textStyle} data-testid={getTestId(id)}>
        {text}
      </span>
      <RightDot style={{ backgroundColor: theme.colors[dotKey] }} />
    </StatusState>
  );
};

export const StatusBar = ({
  color,
  text,
  textStyle,
  id,
  productStatusType = "default",
}: IStatusProps) => {
  const theme = useContext(ThemeContext);
  const { textKey } =
    productStatusType === "default"
      ? colorKeys[color as StatusColor]
      : productColorKeys[color as ProductStatusColor];
  return (
    <StatusContainer
      style={
        textKey
          ? {
              color: theme.colors[textKey],
              border: `1px solid ${theme.colors[textKey]}`,
            }
          : undefined
      }
    >
      <span style={textStyle} data-testid={getTestId(id)}>
        {text}
      </span>
    </StatusContainer>
  );
};

export const getProductStatusText = (
  status: ProductStatusType,
  t: TFunction
): string => {
  switch (status) {
    case "draft":
      return t("Draft");
    case "staged":
      return t("Staged Changes");
    case "published":
      return t("Published");
    case "unpublished":
      return t("Unpublished");
    case "unpublished_staged":
      return t("Unpublished Staged");
    case "archived":
      return t("Archived");
    case "discarded":
      return t("Discarded");
    case "bulk_edit_in_progress":
      return t("Bulk Edit in Progress");
    default:
      console.error("!! UNKNOWN STATUS !!");
      return "";
  }
};

export const getProductStatusValue = (
  status: string,
  t: TFunction
): ProductStatusType => {
  switch (status) {
    case t("Draft"):
      return "draft";
    case t("Staged Changes"):
      return "staged";
    case t("Published"):
      return "published";
    case t("Unpublished"):
      return "unpublished";
    case t("Unpublished Staged"):
      return "unpublished_staged";
    case t("Archived"):
      return "archived";
    case t("Discarded"):
      return "discarded";
    case t("Bulk Edit in Progress"):
      return "bulk_edit_in_progress";
    default:
      console.error("!! UNKNOWN STATUS !!");
      return "draft";
  }
};

export const getProductStatusColor = (
  status: ProductStatusType
): ProductStatusColor => {
  switch (status) {
    case "draft":
      return "orange";
    case "staged":
      return "yellow";
    case "published":
      return "green";
    case "unpublished":
      return "gray";
    case "unpublished_staged":
      return "gray";
    case "archived":
      return "red";
    case "discarded":
      return "red";
    default:
      console.error("!! UNKNOWN STATUS !!");
      return "red";
  }
};
