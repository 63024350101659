import React from "react";
import type { QuoteRequestItem, QuoteStatusProps } from "../../types/types";
import { StatusLeft, StatusRight } from "../Status/Status";
import type { FiltersDropDownItem } from "../FiltersDropDown/FiltersDropDown";
import identity from "lodash/identity";
import type { TFunction } from "react-i18next";

export const BuyerQuoteStatus = ({
  status,
  position,
  id,
  t = identity,
}: QuoteStatusProps) => {
  switch (status) {
    case "requested":
      return position === "left" ? (
        <StatusLeft color="blue" text={t("Request Sent")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("Request Sent")} id={id} />
      );
    case "pending":
    case "pending_activation":
      return position === "left" ? (
        <StatusLeft color="gray" text={t("Pending Activation")} id={id} />
      ) : (
        <StatusRight color="gray" text={t("Pending Activation")} id={id} />
      );
    case "responded":
      return position === "left" ? (
        <StatusLeft color="fullBlue" text={t("Price Received")} id={id} />
      ) : (
        <StatusRight color="fullBlue" text={t("Price Received")} id={id} />
      );
    case "cancelled":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Cancelled")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Cancelled")} id={id} />
      );
    case "declined":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Declined")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Declined")} id={id} />
      );

    default:
      return position === "left" ? (
        <StatusLeft color="gray" text={status} id={id} />
      ) : (
        <StatusRight color="gray" text={status} id={id} />
      );
  }
};

export const SellerQuoteStatus = ({
  status,
  position,
  id,
  t = identity,
}: QuoteStatusProps) => {
  switch (status) {
    case "requested":
      return position === "left" ? (
        <StatusLeft color="blue" text={t("New Request")} id={id} />
      ) : (
        <StatusRight color="blue" text={t("New Request")} id={id} />
      );
    case "pending":
    case "pending_activation":
      return position === "left" ? (
        <StatusLeft color="gray" text={t("Pending Activation")} id={id} />
      ) : (
        <StatusRight color="gray" text={t("Pending Activation")} id={id} />
      );
    case "responded":
      return position === "left" ? (
        <StatusLeft color="fullBlue" text={t("Responded")} id={id} />
      ) : (
        <StatusRight color="fullBlue" text={t("Responded")} id={id} />
      );
    case "cancelled":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Cancelled")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Cancelled")} id={id} />
      );
    case "declined":
      return position === "left" ? (
        <StatusLeft color="red" text={t("Declined")} id={id} />
      ) : (
        <StatusRight color="red" text={t("Declined")} id={id} />
      );
    default:
      return <StatusRight color="gray" text={status} id={id} />;
  }
};

export const getBuyerQuoteStatusFilters = (
  t: (s: string) => string
): FiltersDropDownItem[] => [
  { color: "blue", text: t("Request Sent"), value: "requested" },
  { color: "fullBlue", text: t("Price Received"), value: "responded" },
  { color: "red", text: t("Cancelled"), value: "cancelled" },
  { color: "red", text: t("Declined"), value: "declined" },
];

export const SellerQuoteStatusFilters = (
  t: TFunction
): FiltersDropDownItem[] => [
  { color: "blue", text: t("New Request"), value: "requested" },
  { color: "fullBlue", text: t("Responded"), value: "responded" },
  { color: "red", text: t("Cancelled"), value: "cancelled" },
  { color: "red", text: t("Declined"), value: "declined" },
  { color: "gray", text: t("Pending Activation"), value: "pending_activation" },
];

export const getQuoteProductsAsText = (
  quoteItems: QuoteRequestItem[],
  t: (s: string, options?: object) => string
) => {
  if (quoteItems.length === 1) {
    return `${quoteItems[0].product.name}`;
  }
  if (quoteItems.length > 1) {
    return t("{{brandName}} + {{remainingItems}} more", {
      brandName: quoteItems[0].product.name,
      remainingItems: quoteItems.length - 1,
    });
  }
  return `--`;
};

export function getQuoteItemsList(items: QuoteRequestItem[]) {
  if (items && items.length > 1) {
    return (
      <ul>
        {items.map((item, index) => (
          <li key={index}>{item.product.name}</li>
        ))}
      </ul>
    );
  }
  return null;
}
