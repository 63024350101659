import React, { useState } from "react";
import { ThumbnailWithText } from "../ThumbnailWithText/ThumbnailWithText";
import styled from "styled-components/macro";
import type {
  Filters,
  ProductFiltersMainSchema,
  ITag,
} from "../../types/types";
import { EditTopRightWithPositioning } from "../EditTopRight/EditTopRightWithPositioning";
import type { IEditTagSubmitProp } from "./EditMarketsWeServe";
import { EditMarketsWeServe } from "./EditMarketsWeServe";
import { SlideOut } from "../SlideOut/SlideOut";
import { HomePageTitle } from "../../layout/publicPageLayout";
import useSWR from "swr";
import type { AxiosError } from "axios";
import { useSaveHomePage } from "../../pages/public/HomePage/useSaveHomePage";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { useRoutePath } from "../../util/Routing";
import { screenSize } from "../../theme";
import { endpoints } from "../../endpoints";
import { useStoreState } from "../../util/util";
import {
  HomePageContentWrapper,
  ShadedWrapper,
} from "../../layout/homepage/homepage.utils";

export interface ITagProps {
  title: string;
  content?: ITag[];
  isEditable: boolean;
  homePageData: IHomePageData;
}

export interface IMarketSegment {
  id: number | string;
  name?: string;
  imageUrl?: string;
  description?: string;
  link?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  ${HomePageTitle} {
    margin-top: 10px;
  }
`;

const InnerContainer = styled.div`
  display: grid;
  grid-gap: 20px 2%;
  grid-template-columns: repeat(auto-fit, minmax(100px, 32%));
  justify-content: center;
  margin-top: 10px;
  @media ${screenSize.medium} {
    grid-template-columns: repeat(auto-fit, minmax(100px, 48%));
    grid-gap: 20px 4%;
  }
  @media ${screenSize.small} {
    grid-template-columns: repeat(auto-fit, minmax(100px, 100%));
    grid-gap: 15px 0;
  }
`;

export const MarketsWeServe = ({
  title,
  isEditable,
  homePageData,
}: ITagProps) => {
  const [editMode, setEditMode] = useState(false);
  const { storefront_id } = useStoreState();
  const { saveHomePage } = useSaveHomePage();
  const { storePath } = useRoutePath();
  const component = homePageData.config.components.find(
    (component) => component.name === "tags"
  );

  const { data, error } = useSWR<ProductFiltersMainSchema, AxiosError>(
    storefront_id
      ? endpoints.v2_storefronts_id_products_filters(storefront_id)
      : null,
    { revalidateOnFocus: false }
  );
  const filters = data?.filters;

  if (error) console.error(error);

  const startEditMode = () => setEditMode(true);
  const endEditMode = () => setEditMode(false);

  const handleEditFormData = (
    data: IEditTagSubmitProp & { [x: string]: string }
  ) => {
    return [...(data?.market_segments ?? marketSegment)].map(
      ({ id, description }) => ({
        id,
        description: data?.[id] ? data[id] : description ?? "",
      })
    );
  };

  const handleEditSubmit = async (
    data: IEditTagSubmitProp & { [x: string]: string }
  ) => {
    const newData = {
      title: data.title,
      content: handleEditFormData(data),
    };
    if (component) {
      component.data = newData;
    } else {
      homePageData.config.components.push({
        name: "tags",
        data: newData,
      });
    }

    try {
      await saveHomePage(homePageData);
    } catch (error) {
      console.error(error);
    }
  };

  const buildTagsData = (
    filters: Filters,
    homePageData: ITag[]
  ): IMarketSegment[] => {
    let myArray: IMarketSegment[] = [];

    if ("market_segment" in filters) {
      myArray = homePageData.map((tag) => {
        const filtersObj = filters.market_segment.find(
          (item) => `${item.id}` === tag.id
        );
        return {
          id: tag.id,
          name: filtersObj?.name || "",
          description: tag.description,
          link: filtersObj?.name
            ? `${storePath}/portfolio?market_segment=${encodeURIComponent(
                filtersObj?.name
              )}`
            : undefined,
          imageUrl: filtersObj?.image_url!,
        };
      });
    }
    return myArray;
  };

  const marketSegment = filters
    ? buildTagsData(filters, component?.data.content)
    : [];

  return filters && filters.market_segment ? (
    <ShadedWrapper>
      <HomePageContentWrapper>
        <SlideOut closeFlyout={endEditMode} show={editMode}>
          {filters && (
            <EditMarketsWeServe
              title={component?.data.title}
              data={marketSegment}
              handleEditSubmit={handleEditSubmit}
              endEditMode={endEditMode}
              filters={filters}
            />
          )}
        </SlideOut>
        <Container>
          {isEditable && (
            <EditTopRightWithPositioning onClick={startEditMode} />
          )}
          {title && <HomePageTitle>{component?.data.title}</HomePageTitle>}

          <InnerContainer>
            {marketSegment?.map((marketSegment: IMarketSegment) => (
              <ThumbnailWithText key={marketSegment.id} data={marketSegment} />
            ))}
          </InnerContainer>
        </Container>
      </HomePageContentWrapper>
    </ShadedWrapper>
  ) : (
    <></>
  );
};
