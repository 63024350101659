import * as z from "zod";
import { stringIsNotOnlyWhiteSpaceRegex } from "./regexes";
import { strings } from "./strings";
import type { TFunction } from "react-i18next";

/**
 * works for validating most selectboxes in the app with zod.
 * ```
 * {value: string, label: string}
 * ```
 */
export const zodSelectBoxDefault = (t: TFunction) =>
  z.object({
    value: z.string().min(1, strings(t).thisIsARequiredField),
    label: z.string().min(1, strings(t).thisIsARequiredField),
  });

/**
 * This is used for type inference
 */
export const zodSelectBoxType = z.object({
  value: z.string().min(1),
  label: z.string().min(1),
});
/**
 * This is used for schema validation
 */
export const zodOptionalSelectBoxDefault = z.object({
  value: z.string().optional(),
  label: z.string().optional(),
});

/**
 * works for validating Multi-Select Chips Dropdown in the app with zod.
 * ```
 * {name: string}
 * ```
 */
export const zodMultiSelectDefault = (t: TFunction) =>
  z.object({
    name: z.string().min(1, strings(t).thisIsARequiredField),
  });

/**
 * This is used for type inference
 */
export const zodRequiredStringType = z
  .string()
  .regex(stringIsNotOnlyWhiteSpaceRegex);

/**
 * Works for validating most string inputs, allows anything except pure whitespace
 * ie: " "
 */
export const zodRequiredString = (t: TFunction) =>
  z
    .string()
    .regex(stringIsNotOnlyWhiteSpaceRegex, strings(t).thisIsARequiredField);

/**
 * an optional string with zod.s
 */
export const zodOptionalString = z.string().optional();

/**
 * This works for validating most "Address Blocks" in the app. Outside of login
 * for leads there is the case of the "partial address" where this won't work.
 * However inside login this should be generally applicable.
 */
export const zodAddressDefault = (t: TFunction) => ({
  country: zodSelectBoxDefault(t),
  county: zodOptionalString,
  city: zodRequiredString(t),
  state: zodSelectBoxDefault(t),
  postal_code: zodRequiredString(t),
  address1: zodRequiredString(t),
  address2: zodOptionalString,
});

/**
 * This is used for type inference
 */
export const zodAddressType = {
  country: zodSelectBoxType,
  county: zodOptionalString,
  city: zodRequiredStringType,
  state: zodSelectBoxType,
  postal_code: zodRequiredStringType,
  address1: zodRequiredStringType,
  address2: zodOptionalString,
};

export const LinkAttributeValueSchema = z.object({
  display_text: z.string(),

  url: z.string().optional(),
});

export const LinkAttributeValueSchemaRefined = (t: TFunction) =>
  z
    .object({
      display_text: z.string().optional(),
      url: z.string().optional(),
    })
    .nullish()
    .superRefine((data, ctx) => {
      if (
        (data?.url && !data?.display_text) ||
        (data?.display_text && !data?.url)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: !data?.display_text
            ? t("display text is required if url exists")
            : t("url is required if display text exists"),
          path: !data?.display_text ? ["display_text"] : ["url"],
        });
      }
    });

export const zodOptionalAddress = {
  country: zodOptionalSelectBoxDefault,
  county: zodOptionalString,
  city: zodOptionalString,
  state: zodOptionalSelectBoxDefault.optional().nullable(),
  postal_code: zodOptionalString,
  address1: zodOptionalString,
  address2: zodOptionalString,
};
