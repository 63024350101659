import { Title } from "../components/Typography/Typography";
import styled from "styled-components/macro";
import { screenSize } from "../theme";

interface PageWrapperProps {
  narrow?: boolean;
  fullWidth?: boolean;
}

export const PageWrapper = styled.div<PageWrapperProps>`
  width: 100%;
  max-width: ${({ narrow, fullWidth }) =>
    fullWidth ? "100%" : narrow ? "900px" : "1400px"};
  min-width: 300px;
  margin: 0 auto;
  .ql-editor {
    height: auto !important;
  }
  .__react_component_tooltip.place-top::before {
    bottom: -1px;
    height: 8px !important;
  }
`;

/**
 * Typically used in conjuction with a search bar on the left hand side.
 */
export const HorizontalButtonsContainer = styled.div`
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  height: 37.5px;
  justify-content: flex-end;
  margin-left: auto;
  @media ${screenSize.small} {
    margin-top: 10px;
    justify-content: flex-start;
    margin-right: auto;
    margin-left: 0;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  & > *[data-alignright] {
    margin-left: auto;
  }
  @media ${screenSize.medium} {
    flex-wrap: wrap;
    .searchBar {
      flex: 1 1 100%;
      order: 10;
      max-width: 100%;
    }
  }
`;

export const PageTitle = styled(Title)`
  display: inline-block;
  margin: 10px;
  margin-left: 0;
`;

export const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 0px -8px 5px 0;
  flex-wrap: wrap;
  & > * {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  & > *[data-alignright] {
    margin-left: auto;
  }

  @media ${screenSize.medium} {
    margin: 10px 0 5px;
    & > * {
      margin-right: 5px;
      margin-bottom: 8px;
    }
    & > *[data-alignright] {
      margin-left: 0 !important;
    }
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 10px -8px 5px 0px;
  flex-wrap: wrap;
  width: calc(100% - 150px);
  & > * {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  & > *[data-alignright] {
    margin-left: auto;
  }

  @media ${screenSize.mLarge} {
    width: calc(100% - 205px);
  }

  @media ${screenSize.medium} {
    margin: 10px 0 5px;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: scroll;
    & > * {
      margin-right: 5px;
      margin-bottom: 8px;
    }
    & > .rightAlign {
      margin-left: 0 !important;
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const TwoColumnLayout = styled.div`
  width: 100%;
  display: Flex;
  flex-direction: row;
  @media ${screenSize.medium} {
    flex-direction: column;
  }
`;

export const TwoColumnOrderTotalSection = styled.div`
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  align-items: stretch;
  @media ${screenSize.small} {
    flex-direction: column-reverse;
  }
`;

type CardProps = {
  selectedBorder?: boolean;
};

/**
 * TODO: remove CardNoMargin when the margin can be removed from this component.
 */
export const Card = styled.div<CardProps>`
  border-radius: 4px;
  border: ${({ theme, selectedBorder }) => `${
    selectedBorder ? "2px" : "1px"
  } solid
    ${selectedBorder ? theme.tertiaryBorder : theme.secondaryBorder}`};
  background-color: ${(props) => props.theme.secondaryBG};
  color: #0f1d2b;
  font-size: 13px;
  padding: 20px;
  width: 100%;
  margin: 20px 0;
`;

export const WhiteInnerCard = styled.div`
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  width: 100%;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 4px;
`;

export const SettingsCard = styled(Card)`
  padding-left: 56px;
  @media ${screenSize.small} {
    padding-left: 15px;
  }
`;

export const FlexRow = styled.div<{ rowItems: number; itemMargin: string }>`
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex-grow: 1;
    width: ${(props) => (props.rowItems ? 100 / props.rowItems : 100)}%;
    margin: ${(props) => (props.itemMargin ? props.itemMargin : 0)};
  }
`;

export const TwoColumGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
`;

interface IHorizontalSeparator {
  margin?: string;
}
export const FullWidthHorizontalSeparator = styled.div<IHorizontalSeparator>`
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  left: 0;
  right: 0;
  position: absolute;
  margin: ${({ margin }) => margin ?? 0};
`;
