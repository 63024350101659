import React from "react";
import { Link, useParams } from "react-router-dom";
import { Tabs, Tab } from "../../../components/Tabs/Tabs";
import { GoBackButton } from "../../../components/Buttons/Buttons";
import {
  SettingsIcon,
  UsersIcon,
  SoldByIcon,
} from "../../../components/Icons/Icons";
import { SoftHeader2 } from "../../../components/Typography/Typography";
import {
  PageWrapper,
  PageHeader,
  PageTitle,
  ContentWrapper,
} from "../../../layout/portalPageLayout";
import useSWR from "swr";
import type { Tenant } from "../../../types/types";
import { ErrorPlaceholder } from "../../../components/Error";
import { UsersList } from "./UsersList/UsersList";
import type { AxiosError } from "axios";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import { endpoints } from "../../../endpoints";
import { makeUrlWithParams, useStoreState } from "../../../util/util";
import { CompanyInfo } from "./CompanyInfo/CompanyInfo";
import { CustomerSettings } from "./CustomerSettings/CustomerSettings";
import { PriceTiersTab } from "./PriceTiersTab/PriceTiersTab";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../util/hooks";

type Params = {
  tenant_id: string;
  tenantSlug: string;
};

type PageTabs = "Company Info" | "Settings" | "Users" | "Price Tiers";

/**
 * Customer detail page.  Used for seller admin.
 */
export const CustomerDetailPage = providePrivatePageProps(({ user }) => {
  const {
    storefront_id,
    storefront_metadata: { edition },
  } = useStoreState();
  const { tenant_id: buyerTenantId } = useParams<Params>();
  const [query] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });
  const { adminPath } = useRoutePath();
  const { t } = useTranslation();

  const pageTabs: PageTabs[] = [
    "Company Info",
    "Settings",
    "Users",
    "Price Tiers",
  ];
  const urlQuery = useQuery();
  const [tabIndex] = React.useState(() => {
    const maybeTabIndex = urlQuery.get("tab");
    if (maybeTabIndex && pageTabs.includes(maybeTabIndex as PageTabs)) {
      return maybeTabIndex;
    } else return "Company Info";
  });

  const {
    data: buyerTenant,
    error: buyerTenantError,
    mutate: mutateBuyerTenant,
  } = useSWR<Tenant, AxiosError>(
    buyerTenantId
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_tenants_id(storefront_id, buyerTenantId),
          {
            show_inactive_address: "true",
          }
        )
      : null
  );

  if (buyerTenantError) {
    return <ErrorPlaceholder message={t("Failed to fetch customer.")} />;
  }
  if (!buyerTenant) {
    // Loading.
    return <DelayedSpinner />;
  }

  return (
    <PageWrapper narrow>
      <Link
        to={makeUrlWithParams(`${adminPath}/tenants`, {
          q: query.q,
          offset: query.offset,
          perPage: query.perPage,
        })}
      >
        <GoBackButton text={t("Customers")} />
      </Link>
      <PageHeader>
        <PageTitle>{buyerTenant.name}</PageTitle>
        {buyerTenant.external_identity?.external_id && (
          <SoftHeader2 data-alignright>
            {t("Customer ID") + ":"}{" "}
            {buyerTenant.external_identity?.external_id}
          </SoftHeader2>
        )}
      </PageHeader>
      <ContentWrapper>
        <Tabs selectedIndex={tabIndex} allowDeepLink={true}>
          <Tab label={t("Company Info")} icon={SettingsIcon}>
            <CompanyInfo
              buyerTenant={buyerTenant}
              mutateBuyerTenant={mutateBuyerTenant}
            />
          </Tab>
          <Tab label={t("Users")} icon={UsersIcon}>
            <UsersList tenantID={buyerTenant.id} />
          </Tab>
          <Tab label={t("Settings")} icon={SettingsIcon}>
            <CustomerSettings buyerTenant={buyerTenant} loggedInUser={user} />
          </Tab>
          {edition !== "pim" ? (
            <Tab label={t("Price Tiers")} icon={SoldByIcon}>
              <PriceTiersTab buyerTenant={buyerTenant} user={user} />
            </Tab>
          ) : (
            <></>
          )}
        </Tabs>
      </ContentWrapper>
    </PageWrapper>
  );
});
