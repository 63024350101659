import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../../../components/Auth";
import { Table } from "../../../../../components/Table/Table";
import { H3 } from "../../../../../components/Typography/Typography";
import type { Assets, GridItemProps } from "../../../../../types/types.PIM";
import {
  TablePlaceholder,
  useSupportedLanguages,
} from "../../../../../util/util";
import { AssetNameIcon, DocPortfolioName, TableContainer } from "../util";
import { Row } from "../../../../../components/Layout/Layout";
import { ListGridToggleButton } from "../../../../../components/Buttons/Buttons";
import { GridAssetView } from "../../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/GridAssetView/GridAssetView";

type DocumentsAssetsTable = {
  id: string;
  name: string;
  asset_category: string;
  asset_type: string;
  is_generated: boolean;
  is_downloadable: boolean;
  signed_url: string;
  language: string;
  content_type: string;
  preview_url: string;
};

export const DocumentPortfolioAssets = ({ assets }: { assets: Assets[] }) => {
  const [tableData, setTableData] = useState<DocumentsAssetsTable[]>([]);
  const [list_grid_toggle, set_list_grid_toggle] =
    useState<"list" | "grid">("list");
  const { t } = useTranslation();
  const { roleIsGuest, user } = useAuthContext();
  const { supported_languages } = useSupportedLanguages();

  const tableColumns = useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
        Cell: ({
          value,
          row: {
            original: {
              asset_type,
              signed_url,
              is_downloadable,
              is_generated,
              content_type,
            },
          },
        }: {
          value: string;
          row: {
            original: Assets;
          };
        }) => (
          <AssetNameIcon
            asset_type={asset_type}
            signed_url={signed_url}
            name={value}
            is_downloadable={is_downloadable}
            is_generated={is_generated}
            content_type={content_type}
          />
        ),
      },
      {
        Header: t("Category"),
        accessor: "asset_category",
        Cell: ({
          value,
          row: {
            original: { is_downloadable },
          },
        }: {
          value: string;
          row: { original: Assets };
        }) => (
          <DocPortfolioName disabled={!Boolean(user) && !is_downloadable}>
            {value}
          </DocPortfolioName>
        ),
      },
      {
        Header: t("Language"),
        accessor: "language",
        Cell: ({
          value,
          row: {
            original: { is_downloadable },
          },
        }: {
          value: string;
          row: { original: Assets };
        }) => (
          <DocPortfolioName disabled={!Boolean(user) && !is_downloadable}>
            {value}
          </DocPortfolioName>
        ),
      },
    ],
    [t, user]
  );

  useEffect(() => {
    setTableData(
      assets.reduce<DocumentsAssetsTable[]>(
        (
          acc,
          {
            id,
            name,
            asset_category,
            language,
            is_downloadable,
            is_generated,
            asset_type,
            signed_url,
            content_type,
            preview_url,
          }
        ) => {
          acc.push({
            id,
            name: name ?? "--",
            asset_type: asset_type,
            signed_url,
            is_downloadable,
            is_generated,
            asset_category,
            language: language?.toLocaleUpperCase() ?? "--",
            content_type: content_type ?? "application/pdf",
            preview_url,
          });
          return acc;
        },
        []
      )
    );
  }, [assets, supported_languages]);
  return (
    <>
      <Row style={{ marginBottom: "16px" }}>
        <H3 style={{ margin: 0 }}>{t("Documents")}</H3>
        <div style={{ alignSelf: "flex-start", marginBottom: "16px" }}>
          <ListGridToggleButton
            set_toggle_state={set_list_grid_toggle}
            name="portfolio_documents_list_grid_toggle"
          />
        </div>
      </Row>
      <TableContainer>
        {list_grid_toggle === "list" ? (
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={false}
            firstRowWidthPercentage={70}
            error={undefined}
            lastChildleftAlign
            Placeholder={<TablePlaceholder />}
          />
        ) : (
          <GridAssetView
            assets={
              [...tableData].map((asset) => ({
                ...asset,
                can_edit: false,
                can_delete: false,
                can_change_visibility: false,
                can_external_download: roleIsGuest
                  ? asset.is_downloadable
                  : true,
                can_view_details: false,
              })) as unknown as (Assets & GridItemProps)[]
            }
            on_download_asset={(asset) => {
              window.open(asset.signed_url, "_blank");
            }}
          />
        )}
      </TableContainer>
    </>
  );
};
