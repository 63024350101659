// A file for shared form utilities

/**
 * find duplicate indexes in an array, except when there is no value present.
 * Used for marking duplicates in forms.
 * @param arr
 * @returns
 */
export const findNonEmptyDuplicateIndexes = (arr: string[]) => {
  const idxArr: number[] = [];
  const arrHash: { [prop: string]: number } = {};
  for (let idx = 0; idx < arr.length; idx++) {
    const cur = arr[idx];
    if (cur && cur in arrHash) {
      idxArr.push(idx);
      arrHash[cur] += 1;
    } else {
      arrHash[cur] = 0;
    }
  }
  return idxArr;
};
