import React, { useContext, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DeleteButton,
  PrimaryButtonFitContainer,
  SecondaryButtonWithPlusIcon,
} from "../../../../../components/Buttons/Buttons";
import { SectionTitle } from "../../../../../components/Form/Form";
import { Form } from "../../../../../layout/FormLayout";
import type { AttributeGroupSummary } from "../../../../../types/types.PIM";
import { useFormWrapper, useStoreState } from "../../../../../util/util";
import type { AxiosError } from "axios";
import Axios from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { SelectWithDeleteButton } from "../../../../../layout/shared";
import * as z from "zod";
import {
  zodSelectBoxDefault,
  zodSelectBoxType,
} from "../../../../../util/zod.util";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  MarginBottomH6,
  MarginBottomHeaderLeft,
} from "../SellerAdminPIMAttributes/CreateAttribute";
import { findNonEmptyDuplicateIndexes } from "../../../../../util/form.utils";
import { AsyncSearchSelect } from "../../../../../components/AsyncSearchSelect/AsyncSearchSelect";
import { useHandleAttributesAsyncSearch } from "../SellerAdminPIMAttributesCollections/util";
import { ConfirmDialog } from "../../../../../components/ConfirmDialog/ConfirmDialog";

/**
 * Extracts name from label.
 * @param label is structured this way: `{{name}} ({{input_type}})`
 * @returns string which is the {{name}}
 */
export const getNameFromLabel = (label: string): string => {
  const stringArr = label.split("(");
  stringArr.pop();
  return stringArr.join("(").trim();
};

const AddAttributeToGroupSchema = z.object({
  first_attribute: zodSelectBoxType,
  group: z
    .object({
      attribute: zodSelectBoxType,
    })
    .array()
    .optional(),
});

const AddAttributeToGroupSchemaFn = (
  t: (s: string) => string,
  attributes: string[]
) =>
  z
    .object({
      first_attribute: zodSelectBoxDefault(t).refine(
        ({ label }) =>
          findNonEmptyDuplicateIndexes([getNameFromLabel(label), ...attributes])
            .length === 0,
        t("Please remove all duplicate attributes")
      ),
      group: z
        .object({
          attribute: zodSelectBoxDefault(t),
        })
        .array()
        .optional(),
    })
    .superRefine(({ first_attribute, group }, ctx) => {
      const duplicateIdxs = group
        ? findNonEmptyDuplicateIndexes([
            getNameFromLabel(first_attribute.label),
            ...attributes,
            ...group.map(({ attribute: { label } }) => getNameFromLabel(label)),
          ])
        : [];
      if (duplicateIdxs.length > 0) {
        duplicateIdxs.forEach((idx) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("Duplicate attributes not allowed"),
            path: [`group[${idx - (attributes.length + 1)}].attribute`],
          });
        });
      }
    });

type formOutput = z.infer<typeof AddAttributeToGroupSchema>;

export function AddAttributeToExistingGroup({
  group: { id: group_id, attributes: existingAttributes },
  onSuccess,
  isTemplateInUse = false,
}: {
  group: AttributeGroupSummary;
  onSuccess: () => void;
  isTemplateInUse?: boolean;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const [formValue, setFormValue] = useState<formOutput>();
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { handleAttributesSearch } = useHandleAttributesAsyncSearch();

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(
      AddAttributeToGroupSchemaFn(
        t,
        existingAttributes.map(({ name }) => name)
      )
    ),
    defaultValues: {
      first_attribute: { label: undefined, value: undefined },
    },
  });
  const { handleSubmit, control, formState, errors } = methodsOfUseForm;
  const { notifyError, notifySuccess } = useContext(Notifications);

  const { fields, remove, append } = useFieldArray({
    control,
    name: "group",
  });

  const createFields = (): JSX.Element[] => {
    return fields.map((field, index: number) => (
      <SelectWithDeleteButton key={field.id}>
        <Controller
          key={field.id}
          as={AsyncSearchSelect}
          control={control}
          name={`group[${index}].attribute`}
          defaultValue={{ label: "", value: "" }}
          placeholder={t("Select Attribute")}
          searchFunction={handleAttributesSearch}
          errors={{
            [`group[${index}].attribute`]:
              errors?.group?.[index]?.attribute?.value ??
              errors?.group?.[index]?.attribute ??
              undefined,
          }}
          formState={formState}
          defaultOptions
        />
        <DeleteButton
          testid={`delete-button-${index}`}
          onClick={() => remove(index)}
          type="button"
          height={20}
          width={20}
        />
      </SelectWithDeleteButton>
    ));
  };

  const processForm = async (values: formOutput) => {
    const attrs = values?.group?.map((attr) => [attr?.attribute?.value]) ?? [];

    const attributesToAdd = {
      attribute_ids: [values.first_attribute.value, ...attrs].flat(),
    };

    try {
      await Axios.post(
        `/v2/tenants/${tenant_id}/pim/groups/${group_id}/attributes`,
        attributesToAdd
      );
      notifySuccess(t("Attribute(s) added successfully"));
      onSuccess();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error modifying the group"),
        {
          error,
        }
      );
    }
  };

  const onSubmit = async (values: formOutput) => {
    if (isTemplateInUse) {
      setShowDialog(true);
      setFormValue(values);
    } else {
      await processForm(values);
    }
  };

  return (
    <>
      <MarginBottomHeaderLeft>
        <SectionTitle>{t("Add Attributes")}</SectionTitle>
      </MarginBottomHeaderLeft>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <MarginBottomH6>
          {t("Select from existing list of attributes")}
        </MarginBottomH6>
        <div style={{ width: "420px" }}>
          <Controller
            as={AsyncSearchSelect}
            control={control}
            name="first_attribute"
            searchFunction={handleAttributesSearch}
            placeholder={t("Select Attribute")}
            errors={errors}
            formState={formState}
            defaultOptions
          />
        </div>
        {createFields()}
        <SecondaryButtonWithPlusIcon
          type="button"
          style={{ marginBottom: "40px" }}
          onClick={() => append({ name: "" })}
        >
          {t("Add attribute")}
        </SecondaryButtonWithPlusIcon>
        <PrimaryButtonFitContainer type="submit">
          {t("Add")}
        </PrimaryButtonFitContainer>
      </Form>
      <ConfirmDialog
        show={showDialog}
        closeDialog={() => {
          setFormValue(undefined);
          setShowDialog(false);
        }}
        confirmMessage={t(
          "Any changes will be pushed down to all products using this template. Do you want to continue?"
        )}
        handleConfirm={async () => {
          await processForm(formValue!);
          setShowDialog(false);
        }}
      />
    </>
  );
}
