import React from "react";
import styled from "styled-components/macro";
import { CenterContainer } from "../../layout/publicPageLayout";

const NothingContainer = styled(CenterContainer)`
  height: 200px;
  border-radius: 4px;
`;

/**
 * A placeholder to show when there is no data, nothing to display. The initial
 * use case was for the various sections of homepage templates.
 */
export const NothingToDisplay = ({ text }: { text?: string }) => {
  return <NothingContainer>{text ?? "Nothing to display."}</NothingContainer>;
};
