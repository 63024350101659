import React from "react";
import { useForm } from "react-hook-form";
import { PrimaryButtonFitContainer } from "../../../../../components/Buttons/Buttons";
import { FilePickerUncontrolled } from "../../../../../components/FilePicker/FilePickerUncontrolled";
import { TableWrapper } from "../../../../../components/Table/Table";
import {
  H3,
  SmallSectionHeader,
} from "../../../../../components/Typography/Typography";
import { Form } from "../../../../../layout/FormLayout";
import {
  isNetworkErrorWithMessage,
  useStoreState,
} from "../../../../../util/util";
import Axios from "axios";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";

type BulkListUploadFromCSVProps = {
  onSuccess: () => void;
};

const CSV_FORMAT_EXAMPLE_TABLE_DATA = [
  ["Value 1", "Value 1", "Value 1", "Value 1", "Value 1"],
  ["Value 2", "Value 2", "Value 2", "Value 2", "Value 2"],
  ["Value 3", "Value 3", "Value 3", "Value 3", "Value 3"],
  ["Value 4", "Value 4", "Value 4", undefined, "Value 4"],
  ["Value 5", "Value 5", "Value 5", undefined, "Value 5"],
  ["Value 6", undefined, undefined, undefined, "Value 6"],
  ["Value 7", undefined, undefined, undefined, "Value 7"],
];

export function BulkListUploadFromCSV({
  onSuccess,
}: BulkListUploadFromCSVProps) {
  const { tenant_id } = useStoreState();
  const methodsOfUseForm = useForm();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotifications();
  const { handleSubmit, setError } = methodsOfUseForm;

  const onSubmit = async (value: { csv: File }) => {
    try {
      const formData = new FormData();
      formData.append("file", value.csv);

      await Axios.post(`/v2/tenants/${tenant_id}/pim/lists/bulk`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      notifySuccess(t("Successfully imported list from CSV"));

      onSuccess();
    } catch (error) {
      const message = isNetworkErrorWithMessage(error)
        ? error.response.data.message
        : t(`There was an error importing the CSV`);
      setError("csv", { message: message });

      notifyError(message);
      console.error(error);
    }
  };

  return (
    <>
      <H3>{t("Import Bulk Lists")}</H3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FilePickerUncontrolled
          methodsOfUseForm={methodsOfUseForm}
          placeHolderText={t("Drag and drop the CSV file in given format")}
          name={"csv"}
          accept={".csv"}
          required
        />
        <PrimaryButtonFitContainer type="submit">
          {t("Import")}
        </PrimaryButtonFitContainer>
        <SmallSectionHeader>
          {t("Please follow the below format to import the CSV")}
        </SmallSectionHeader>
        <p>
          {t(
            `The header cells in the CSV will be matched exactly with existing list names in the system. 
             If a matching list is found, the values in that row of the CSV will be appended to that list. 
             If a match is not found, a new list will be created. 
             It is not possible to delete or overwrite values with this feature.`
          )}
        </p>
        <TableWrapper lastChildleftAlign>
          <table>
            <thead>
              <th>List 1</th>
              <th>List 2</th>
              <th>List 3</th>
              <th>List 4</th>
              <th>List X</th>
            </thead>
            <tbody>
              {CSV_FORMAT_EXAMPLE_TABLE_DATA.map((row) => {
                return (
                  <tr>
                    {row.map((value) => (
                      <td>{value}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableWrapper>
      </Form>
    </>
  );
}
