import theme from "../../../theme";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
} from "../../../components/Buttons/Buttons";
import { EditIcon } from "../../../components/Icons/Icons";
import {
  FurthestRightButtonContainer,
  TransactionItem,
} from "../../../components/TransactionItem/TransactionItem";
import { TransactionItemInfoBlock } from "../../../components/TransactionItem/TransactionItemInfoBlock";
import { TransactionItemTopLeft } from "../../../components/TransactionItem/TransactionItemTopLeft";
import {
  makePricePerUnitString,
  makeSkuLabel,
  makeNumberOfUnitsLabel,
} from "../../../components/TransactionItem/TransactionItemUtils";
import type {
  ProductApplication,
  ProductSKU,
  CurrencyCode,
  UUID,
  Product,
} from "../../../types/types";
import { formatPrice } from "../../../util/util-components";
import type { InlineDisplayCustomSKU } from "./AddOrEditProductForm";
import { Row } from "../../../components/Layout/Layout";

/**
 * an object that is not quote an `QuoteRequestItem` or an `IOrderItem`
 * Some things won't exist until the server creates them.
 */
export type OrderItemToBeCreated = {
  price_per_unit: string;
  product_applications: ProductApplication[];
  custom_application?: string;
  product_id: string;
  product: { name: string };
  sku: ProductSKU | InlineDisplayCustomSKU;
  product_identity: { cas_number: string | null };
  currency: CurrencyCode;
  total_quantity: string;
  no_of_units: string;
  id: UUID;
  entire_product: Product;
};

/**
 * Transaction item card for buyer cart items.
 */
export const CreateOrderItemCard = ({
  item,
  index,
  editItem,
  removeItem,
  isSelected,
  setSelectedItemIndex,
}: {
  item: OrderItemToBeCreated;
  index: number;
  editItem: (item: OrderItemToBeCreated) => void;
  removeItem: (item: OrderItemToBeCreated) => void;
  isSelected: boolean;
  setSelectedItemIndex: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  const { t } = useTranslation();

  const topLeft = <TransactionItemTopLeft item={item} index={index} />;

  /**
   * A copy so as not to pollute the entire codebase with `| OrderItemToBeCreated`
   */
  const makeTotalQuantity = () => {
    const total =
      parseFloat(item.total_quantity) * parseFloat(item.price_per_unit ?? "");
    return isNaN(total) ? "-/-" : formatPrice(total, item.currency);
  };

  const topRightCol2 = (
    <TransactionItemInfoBlock
      alignRight={true}
      main={makeTotalQuantity()}
      lines={[
        makePricePerUnitString(
          item.price_per_unit,
          item.currency,
          item.sku?.packaging_unit?.name
        ) ?? "--",
      ]}
    />
  );

  const topRightCol1 = (
    <TransactionItemInfoBlock
      main={`${item.total_quantity} ${item.sku.packaging_unit?.name ?? ""}`}
      lines={[
        item.sku ? makeSkuLabel(item.sku) : "--",
        item.no_of_units ? makeNumberOfUnitsLabel(item.no_of_units, t) : "--",
      ]}
      warningLine={
        item.sku && item.sku.kind === "Buyer SKU" ? t("Custom SKU") : undefined
      }
    />
  );

  const bottomRight = (
    <Row>
      <ButtonWithConfirmDialog
        Button={DeleteButton}
        testid={"delete-quote-cart-item-buyer"}
        handleConfirm={() => removeItem(item)}
        confirmMessage={t("Are you sure you want to remove this item?")}
      />
      <FurthestRightButtonContainer>
        <EditButton
          onClick={() => {
            editItem(item);
            setSelectedItemIndex(index);
          }}
          testid={`buyer-cart-item-edit-button-${index}`}
        >
          <EditIcon fill={theme.primaryButtonBG} />
        </EditButton>
      </FurthestRightButtonContainer>
    </Row>
  );

  return (
    <TransactionItem
      hideSeparatorLine
      selectedBorder={isSelected}
      topLeft={topLeft}
      topRightCol2={topRightCol2}
      topRightCol1={topRightCol1}
      bottomRight={bottomRight}
    />
  );
};
