import { screenSize } from "../../theme";
import React, { useRef } from "react";
import styled from "styled-components/macro";
import type { ProductDocument } from "../../types/types";
import { checkTextOverflow, useMediaQueries } from "../../util/util";
import { DeleteButton, DownloadButton, EditButton } from "../Buttons/Buttons";
import ReactTooltip from "react-tooltip";

export const DocumentCardContainer = styled.div`
  max-width: 515px;
  height: 52px;
  border: 1px solid grey;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;

  background: #ffffff;
  border: 1px solid #cfd1d4;
`;

export const DocumentName = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.small};
  @media ${screenSize.small} {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
`;

export const IconGroup = styled.div`
  display: flex;
  gap: 20px;
  @media ${screenSize.small} {
    gap: 10px;
  }
  align-items: center;
`;

export const DocumentCardsContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export function DocumentCard({
  doc,
  handleEditClick,
  handleDeleteClick,
  editMode = false,
}: {
  doc: ProductDocument;
  handleEditClick: () => void;
  handleDeleteClick: () => void;
  editMode?: boolean;
}) {
  const { isSmallScreen } = useMediaQueries();

  const dimension = isSmallScreen ? 16 : 24;

  const docNameRef = useRef<HTMLDivElement>(null);

  const isDocNameOverflow = () => {
    if (!!docNameRef.current) {
      const { widthOverflow } = checkTextOverflow(docNameRef.current);
      return widthOverflow;
    }
    return false;
  };

  return (
    <DocumentCardContainer>
      <DocumentName
        ref={docNameRef}
        data-for={`document-name-${doc.id}`}
        data-tip={isDocNameOverflow() ? doc.name : ""}
      >
        {doc.name}
      </DocumentName>
      <IconGroup>
        <a href={doc.signed_url} target="_blank" rel="noopener noreferrer">
          <DownloadButton
            width={dimension}
            height={dimension}
            testid="download"
            type="button"
          />
        </a>
        {editMode && (
          <>
            <EditButton
              testid={`edit-document-${doc.id}`}
              width={dimension}
              onClick={handleEditClick}
              height={dimension}
              type="button"
            />
            <DeleteButton
              testid={`delete-document-${doc.id}`}
              onClick={handleDeleteClick}
              width={dimension}
              height={dimension}
              type="button"
            />
          </>
        )}
      </IconGroup>
      <ReactTooltip id={`document-name-${doc.id}`} />
    </DocumentCardContainer>
  );
}
