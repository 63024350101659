import React, { useState, useContext } from "react";
import { Controller } from "react-hook-form";
import type {
  OptionType,
  Product,
  SampleRequestStageOne,
} from "../../../types/types";
import {
  H3,
  SmallSectionHeader,
  Title,
} from "../../../components/Typography/Typography";
import { Form } from "../../../layout/FormLayout";
import { StatusBox } from "../../../components/Form/Form";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../components/TextFields/TextFields";
import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import styled from "styled-components/macro";
import { HeaderSection } from "../../../components/QuoteAndOrderFormShared/QuoteAndOrderFormShared";
import { positiveNumberRegex } from "../../../util/regexes";
import { useStoreState, useFormWrapper } from "../../../util/util";
import Axios from "axios";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { CartContext } from "../../../components/quoteCart/CartContext";
import { mutate } from "swr";
import {
  packagingTypeOrUnitToOption,
  usePackagingUnits,
} from "../../../util/SkuUtils";
import { ProductApplicationSelect } from "../../../components/ProductApplicationSelect/ProductApplicationSelect";
import { useTranslation } from "react-i18next";

const StatusBoxInnerContainer = styled.div`
  width: 100%;
  & > * {
    margin-bottom: 15px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  & > div {
    flex-grow: 1;
    margin-right: 9px;
    width: 49%;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

// Also used by the buyer (logged in) version of this form.
export const SAMPLE_REQUEST_PURPOSES: OptionType<String>[] = [
  {
    label: "New product/formulations development",
    value: "New product/formulations development",
  },
  {
    label: "Establishing alternative to existing formulation",
    value: "Establishing alternative to existing formulation",
  },
  {
    label: "New project implementation",
    value: "New project implementation",
  },
  {
    label: "Customer specification testing & compliance",
    value: "Customer specification testing & compliance",
  },
  {
    label: "Production line trial",
    value: "Production line trial",
  },
];

// Also used by the buyer (logged in) version of this form.
export const ApplicationsSectionContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
  & > * {
    margin-bottom: 15px;
  }
`;

type CreateSampleRequestForm = {
  purpose: OptionType<string>;
  total_quantity: number;
  sample_quantity: number;
  total_quantity_unit: OptionType<string>;
  sample_quantity_unit: OptionType<string>;
  application?: OptionType<string> | OptionType<null>;
  custom_application?: string;
};

type CreateSampleRequestPOST = {
  purpose: string;
  product_id: string;
  total_quantity: number;
  sample_quantity: string;
  total_quantity_packaging_unit_id: string;
  sample_quantity_packaging_unit_id: string;
  applications?: string[];
};

type AddToSampleRequestFormProps = {
  product: Product;
  closeSlideout: () => void;
  cartID: string | undefined;
};

export function AddToSampleRequestForm({
  product,
  closeSlideout,
  cartID,
}: AddToSampleRequestFormProps) {
  const { notifyError, notifySuccess } = useContext(Notifications);
  const { storefront_id } = useStoreState();
  const { setSampleCartID } = useContext(CartContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();

  const methodsOfUseForm = useFormWrapper();

  const {
    register,
    handleSubmit,
    formState,
    errors,
    control,
    setError,
    clearErrors,
  } = methodsOfUseForm;

  const { packagingUnits, packagingUnitsError } = usePackagingUnits();

  const packagingUnitOptions =
    packagingUnits?.map(packagingTypeOrUnitToOption) || [];

  if (packagingUnitsError || errors["total_quantity_unit"]) {
    // Have to check to see if the error has already been set to avoid a render loop.
    if (!errors["total_quantity_unit"]) {
      setError("total_quantity_unit", {
        message: "Error fetching packaging units",
      });
    }
    if (!errors["sample_quantity_unit"]) {
      setError("sample_quantity_unit", {
        message: "Error fetching packaging units",
      });
    }
  } else {
    if (errors["total_quantity_unit"]) {
      clearErrors("total_quantity_unit");
    }
    if (errors["sample_quantity_unit"]) {
      clearErrors("sample_quantity_unit");
    }
  }

  // temporarily disabled for outside login.
  // const sampleSKUS = product.product_skus.reduce<OptionType<ProductSKU>[]>(
  //   (acc, sku) => {
  //     if (!!sku.is_sample) {
  //       acc.push(getSkuOption(sku));
  //     }
  //     return acc;
  //   },
  //   []
  // );

  const onFormSubmit = async (data: CreateSampleRequestForm) => {
    try {
      setIsSubmitting(true);
      const request: CreateSampleRequestPOST = {
        product_id: product.id,
        purpose: data.purpose.value,
        sample_quantity: String(data.sample_quantity),
        total_quantity: data.total_quantity,
        total_quantity_packaging_unit_id: data.total_quantity_unit.value,
        sample_quantity_packaging_unit_id: data.sample_quantity_unit.value,
        ...(data.custom_application
          ? { custom_application: data.custom_application }
          : {}),
      };

      if (data.application?.value) {
        request.applications = [data.application.value];
      }

      if (cartID) {
        await Axios.post(
          `/v1/storefronts/${storefront_id}/sample-requests/${cartID}/items`,
          request
        );
        mutate(`/v1/storefronts/${storefront_id}/sample-requests/${cartID}`);
      } else {
        const { data: sampleRequest } = await Axios.post<SampleRequestStageOne>(
          `v1/storefronts/${storefront_id}/sample-requests`,
          request
        );
        setSampleCartID(sampleRequest.id);
        notifySuccess(t("Sample Request created"));
      }

      closeSlideout();
    } catch (error) {
      notifyError(t("There was an error creating the sample request"), {
        error,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Title style={{ marginTop: "10px" }}>{t("Add to Sample Request")}</Title>
      <Form onSubmit={handleSubmit(onFormSubmit)} noValidate>
        <StatusBox>
          <StatusBoxInnerContainer>
            <HeaderSection product={product} />
            <FlexRow>
              <TextField
                name={"sample_quantity"}
                label={t("Sample Quantity")}
                theref={register({ required: true })}
                errors={errors}
                formState={formState}
                type="number"
              />
              <Controller
                as={SelectBoxV2}
                control={control}
                name="sample_quantity_unit"
                placeholder={t("Unit of Measure")}
                id="sample_quantity_unit"
                options={packagingUnitOptions}
                rules={{
                  required: true,
                }}
                errors={errors}
                formState={formState}
              />
            </FlexRow>
            <SmallSectionHeader>
              {t("Potential Annual Usage")}
            </SmallSectionHeader>
            <FlexRow>
              <div>
                <TextField
                  name={"total_quantity"}
                  label={t("Total Quantity")}
                  theref={register({
                    required: true,
                    pattern: {
                      value: positiveNumberRegex,
                      message: t("Must be a valid numeric value"),
                    },
                  })}
                  errors={errors}
                  formState={formState}
                  type="number"
                />
              </div>
              <div>
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="total_quantity_unit"
                  placeholder={t("Unit of Measure")}
                  id="total_quantity_unit"
                  options={packagingUnitOptions}
                  rules={{
                    required: true,
                  }}
                  errors={errors}
                  formState={formState}
                />
              </div>
            </FlexRow>
          </StatusBoxInnerContainer>
        </StatusBox>
        <ApplicationsSectionContainer>
          <H3>{t("Product application")}</H3>
          <Controller
            as={SelectBoxV2}
            control={control}
            name="purpose"
            placeholder={t("Purpose")}
            rules={{ required: true }}
            options={SAMPLE_REQUEST_PURPOSES}
            errors={errors}
            formState={formState}
          />
          <ProductApplicationSelect
            methodsOfUseForm={methodsOfUseForm}
            applications={product?.product_applications ?? []}
          />
        </ApplicationsSectionContainer>

        <PrimaryButtonFitContainer loading={isSubmitting}>
          {t("Add to Sample Request")}
        </PrimaryButtonFitContainer>
      </Form>
    </div>
  );
}
