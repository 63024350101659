import styled from "styled-components";
import { screenSize } from "../theme";

export const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  background: ${(props) => props.theme.tertiaryBG};
  & * {
    box-sizing: border-box;
  }
`;
interface ISidePanelProps {
  collapsedSideNav?: boolean;
}

export const SidePanel = styled.div<ISidePanelProps>`
  width: ${({ collapsedSideNav }) => (collapsedSideNav ? "45px" : "225px")};
  min-height: 100%;
  transition: all 0.2s ease-out;
  background: ${(props) => props.theme.navBG};
  color: ${(props) => props.theme.invertTextColor};
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.fontFamily};
  border-right: ${(props) => `1px solid ${props.theme.primaryBorder}`};
  z-index: 2;
  @media ${screenSize.large} {
    position: fixed;
    left: ${({ collapsedSideNav }) => (collapsedSideNav ? "-225px" : "0")};
  }
`;

export const SideNavFooterIcon = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
  a {
    color: ${({ theme }) => theme.navLinkColor} !important;
    opacity: ${({ active }) => (active ? 1 : 0.8)};
    &:hover {
      opacity: 1;
    }
  }
`;

export const SideNavFooterWrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 23px;
  margin: 0;
  white-space: nowrap;
  a {
    color: ${({ theme }) => theme.navLinkColor} !important;
    text-decoration: none;
    font-size: ${(props) => props.theme.fontSizes.small};
    opacity: ${({ active }) => (active ? 1 : 0.8)};
    &:hover {
      opacity: 1;
    }
  }
  span {
    opacity: 0.8;
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

export const AppVersionInfo = styled.div`
  opacity: 0.5;
  font-size: ${(props) => props.theme.fontSizes.small};
  display: inline-block;
  margin-right: 5px;
  span {
    text-transform: capitalize;
  }
`;

export const AppHeader = styled.div<ISidePanelProps>`
  padding: 20px 0 10px;
  display: flex;
  z-index: 2;
  justify-content: ${({ collapsedSideNav }) =>
    collapsedSideNav ? "space-between" : "flex-end"};
`;

// App Logo
export const LogoWrapper = styled.div<ISidePanelProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    padding: ${({ collapsedSideNav }) => (collapsedSideNav ? "0 10px" : "0")};
    margin-right: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "0" : "10px"};
    transform: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "none" : "scaleX(-1)"};
    opacity: 0.7;
    margin-top: ${({ collapsedSideNav }) => (collapsedSideNav ? "25px" : "0")};
    margin-bottom: ${({ collapsedSideNav }) =>
      collapsedSideNav ? "30px" : "0"};
    &:hover {
      opacity: 1;
    }
    @media ${screenSize.large} {
      position: ${({ collapsedSideNav }) =>
        collapsedSideNav ? "absolute" : "relative"};
      left: ${({ collapsedSideNav }) => (collapsedSideNav ? "225px" : "auto")};
      top: ${({ collapsedSideNav }) => (collapsedSideNav ? "66px" : "auto")};
      margin-top: ${({ collapsedSideNav }) =>
        collapsedSideNav ? "-44px" : "0"};
    }
  }
  transition: all 0.2s ease-out;
`;

export const Logo = styled("img")`
  max-width: 150px;
  max-height: 80px;
  margin: 30px 20px 40px;
  @media ${screenSize.large} {
    margin: 25px 20px 25px;
  }
`;

export const ContentLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  @media ${screenSize.large} {
    margin-left: 30px;
  }
  img {
    max-width: 100px;
    max-height: 50px;
    margin: 0px 20px;
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;
  overflow-y: auto;
  z-index: 1; // It should be less than the side panel
`;

export const PageWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
  padding: 15px 70px 25px;
  position: relative;
  z-index: 1;
  @media ${screenSize.large} {
    padding: 10px 15px 25px;
  }
`;
