import type { AxiosError } from "axios";
import axios from "axios";
import type {
  DataMutate,
  Language,
  OptionType,
  SupportedLanguage,
} from "../../../types/types";
import type { Assets, PIMProduct } from "../../../types/types.PIM";
import { ExistingAssets } from "../ProductDetailPage/Assets/ExistingAssets";
import { useStoreState } from "../../../util/util";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../../components/Notifications/NotificationsContext";
import { useCookies } from "react-cookie";
import useSWR from "swr";
import { endpoints } from "../../../endpoints";
import { useState } from "react";

export const ExistingTransactionDocuments = <ParentData,>({
  fetchData,
  products,
  transaction_type,
  transaction_type_id,
  onComplete,
}: {
  fetchData: DataMutate<ParentData>;
  products: OptionType<string>[];
  transaction_type: "orders" | "quotes" | "sample-requests";
  transaction_type_id: string;
  onComplete: () => void;
}) => {
  const { storefront_id, slug } = useStoreState();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotifications();
  const [cookies] = useCookies([`preferred-language-${slug}`]);
  const preferred_language: string = cookies[`preferred-language-${slug}`];
  const [assetLanguages, setAssetLanguages] = useState<
    OptionType<SupportedLanguage>[]
  >([]);
  useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onSuccess: (languages) => {
      setAssetLanguages(
        languages.languages.map((item) => ({
          label: `${item.name} (${item.alpha_2.toUpperCase()})`,
          value: item.alpha_2,
        }))
      );
    },
    onError: () =>
      notifyError(t("could not load asset languages, Something went wrong.")),
  });
  const onAddAssets = async ({
    selected_assets,
    selected_product,
    selected_language,
  }: {
    selected_assets: Assets[];
    selected_product?: OptionType<string>;
    selected_language?: OptionType<SupportedLanguage>;
  }) => {
    try {
      await axios.post(
        `/v2/storefronts/${storefront_id}/${transaction_type}/${transaction_type_id}/documents`,
        {
          asset_ids: selected_assets.map(({ id }) => id),
          ...(!selected_product || selected_product?.value === "all_products"
            ? {}
            : { product_id: selected_product.value }),
          language: selected_language?.value ?? preferred_language,
          is_all_product:
            selected_product?.value === "all_products" || !selected_product,
        }
      );
      notifySuccess(t("Documents added successfully"));
      await fetchData();
      onComplete();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not add document. Something went wrong."),
        {
          error,
        }
      );
    }
  };
  return (
    <>
      <ExistingAssets
        title="Documents"
        product={{ status: "archived", id: "" } as PIMProduct}
        fetchParentData={fetchData}
        onAddAssets={onAddAssets}
        products={products}
        languages={assetLanguages}
      />
    </>
  );
};
