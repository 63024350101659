import type { Dispatch, SetStateAction } from "react";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { GenericDialogBody } from "../../../../../../components/ConfirmDialog/ConfirmDialog";
import {
  H3,
  H5,
  RegularText,
} from "../../../../../../components/Typography/Typography";
import { InfoMessageBox } from "../../../../../../components/Form/Form";

type Guideline = {
  text: string;
  subList: string[];
};

const GuideContainer = styled.div`
  width: 100%;
  padding: 32px 16px;
`;

const OL = styled.ol`
  h6 {
    margin-left: -18px;
    margin-bottom: 4px;
  }
  li {
    &::marker {
      font-size: ${({ theme }) => theme.fontSizes.medium};
      font-weight: ${({ theme }) => theme.fontWeights.regular};
      color: ${({ theme }) => theme.secondaryTextColor};
    }
  }
`;

const displayTexts = (overview: (string | Guideline)[]) =>
  overview.map((guideline) => (
    <li key={typeof guideline === "string" ? guideline : guideline.text}>
      {typeof guideline === "string" ? (
        <RegularText>{guideline}</RegularText>
      ) : (
        <>
          <RegularText>{guideline.text}</RegularText>
          <OL type="a">
            {guideline.subList.map((text) => (
              <li key={text}>{<RegularText>{text}</RegularText>}</li>
            ))}
          </OL>
        </>
      )}
    </li>
  ));

/**
 * display helpful information in an ordered list
 */
export const HelpDialog = ({
  show,
  setShow,
  overview,
  guidelines,
  guidelineHeader,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  overview: (t: TFunction) => (string | Guideline)[];
  guidelines: (t: TFunction) => (string | Guideline)[];
  guidelineHeader?: string;
}) => {
  const { t } = useTranslation();
  return (
    <GenericDialogBody show={show} closeDialog={() => setShow(false)}>
      <>
        <H3 style={{ margin: "0 0 28px" }}>{t("Overview & Guidelines")}</H3>
        <InfoMessageBox>
          <GuideContainer>
            <OL>
              <H5>{t("Overview")}</H5>
              {displayTexts(overview(t))}
            </OL>
            <OL>
              <H5>
                {guidelineHeader ?? t("Guidelines for Updating Your CSV Files")}
              </H5>
              {displayTexts(guidelines(t))}
            </OL>
          </GuideContainer>
        </InfoMessageBox>
      </>
    </GenericDialogBody>
  );
};
