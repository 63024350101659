import React, { useContext, useEffect, useState } from "react";
import { SettingsCard } from "../../../../../layout/portalPageLayout";
import styled from "styled-components/macro";
import {
  EditButton,
  CancelButtonSmall,
  SecondaryButtonSmall,
  PrimaryButtonSmall,
} from "../../../../../components/Buttons/Buttons";
import useSWR from "swr";
import {
  makeDeliveryTermsGetEndpoint,
  makePaymentModesGetEndpoint,
  makePaymentTermsGetEndpoint,
  useStoreState,
} from "../../../../../util/util";
import { EditPaymentModes } from "./EditPaymentModes/EditPaymentModes";
import { EditPaymentTerms } from "./EditPaymentTerms/EditPaymentTerms";
import { EditDeliveryTerms } from "./EditDeliveryTerms/EditDeliveryTerms";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { EditPrivacyPolicy } from "./EditPrivacyPolicy/EditPrivacyPolicy";
import { EditTermsOfSale } from "./EditTermsOfSale/EditTermsOfSale";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { H3 } from "../../../../../components/Typography/Typography";
import type {
  PolicyDocument,
  PaymentTermPaginatedOutput,
  PaymentModePaginatedOutput,
  DeliveryTermPaginatedOutput,
} from "../../../../../types/types";
import { useTranslation } from "react-i18next";
import { screenSize } from "../../../../../theme";
import { ConfigureCheckboxsCard } from "./ConfigureCheckboxsCard/ConfigureCheckboxsCard";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TermOrModeRow = styled.div`
  margin: 10px 0 10px;
`;

const FileWrapper = styled.div`
  padding: 10px 15px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 4px;
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: 14px;
  background: ${({ theme }) => theme.primaryBG};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  @media ${screenSize.medium} {
    width: 100%;
  }
`;

const FileName = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.primaryTextColor};
`;

const FileActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  min-width: 100px;
  & > * {
    margin-left: 15px;
    cursor: pointer;
  }
`;

const ViewButton = styled(SecondaryButtonSmall)`
  /* Set the width so it does not change when the loading spinner appears. */
  width: 84px;
`;

export const TermsTab = () => {
  const { storefront_id, storefront_metadata } = useStoreState();
  const { edition } = storefront_metadata;
  const { t } = useTranslation();
  const [editPaymentTerms, setEditPaymentTerms] = useState(false);
  const [editPaymentModes, setEditPaymentModes] = useState(false);
  const [editDeliveryTerms, setEditDeliveryTerms] = useState(false);
  const [editTermsOfSale, setEditTermsOfSale] = useState(false);
  const [editPrivacyPolicy, setEditPrivacyPolicy] = useState(false);
  const [editConfigureCheckbox, setEditConfigureCheckbox] = useState(false);
  const [customPrivacyPolicy, setCustomPrivacyPolicy] = useState(false);
  const [customTermsOfSale, setCustomTermsOfSale] = useState(false);

  const [customPrivacyPolicyDocument, setCustomPrivacyPolicyDocument] =
    useState<PolicyDocument>();

  const [customTermsOfSaleDocument, setCustomTermsOfSaleDocument] =
    useState<PolicyDocument>();

  useEffect(() => {
    if (storefront_metadata) {
      const termsOfSaleDoc = storefront_metadata.policy_documents.find(
        (doc) => {
          return doc.kind === "terms_of_sale";
        }
      );
      const privacyPolicyDoc = storefront_metadata.policy_documents.find(
        (doc) => {
          return doc.kind === "privacy_policy";
        }
      );
      setCustomTermsOfSale(!!termsOfSaleDoc);
      setCustomTermsOfSaleDocument(termsOfSaleDoc);
      setCustomPrivacyPolicyDocument(privacyPolicyDoc);
      setCustomPrivacyPolicy(!!privacyPolicyDoc);
    }
  }, [storefront_metadata]);

  const { notifyError } = useContext(Notifications);
  const {
    data: fetchedPaymentTerms,
    error: paymentTermsError,
    mutate: mutatePaymentTerms,
  } = useSWR<PaymentTermPaginatedOutput>(
    makePaymentTermsGetEndpoint(storefront_id)
  );
  const paymentTerms = fetchedPaymentTerms?.data || [];

  const {
    data: fetchedPaymentModes,
    error: paymentModesError,
    mutate: mutatePaymentModes,
  } = useSWR<PaymentModePaginatedOutput>(
    makePaymentModesGetEndpoint(storefront_id)
  );
  const paymentModes = fetchedPaymentModes?.data || [];

  const {
    data: fetchedDeliveryTerms,
    error: deliveryTermsError,
    mutate: mutateDeliveryTerms,
  } = useSWR<DeliveryTermPaginatedOutput>(
    makeDeliveryTermsGetEndpoint(storefront_id)
  );
  const deliveryTerms = fetchedDeliveryTerms?.data || [];

  const viewStorefrontDocument = async (id: string) => {
    const document = storefront_metadata.policy_documents.find(
      (doc) => doc.id === id
    );
    if (document) {
      window.open(document.signed_url, "_blank");
    } else {
      notifyError(t("Operation failed, something went wrong."));
    }
  };

  return (
    <>
      {edition !== "pim" && (
        <>
          <SettingsCard>
            <Row>
              <H3>{t("Payment Terms")}</H3>
              {!editPaymentTerms && !paymentTermsError && (
                <EditButton
                  testid={"edit-payment-terms"}
                  onClick={() => setEditPaymentTerms(true)}
                  title={t("Edit Payment Terms")}
                />
              )}
              {editPaymentTerms && !paymentTermsError && (
                <CancelButtonSmall onClick={() => setEditPaymentTerms(false)} />
              )}
            </Row>
            {!editPaymentTerms &&
              !paymentTermsError &&
              paymentTerms.map((term) => {
                return <TermOrModeRow key={term.id}>{term.name}</TermOrModeRow>;
              })}
            {editPaymentTerms && !paymentTermsError && (
              <EditPaymentTerms
                paymentTerms={paymentTerms}
                mutatePaymentTerms={mutatePaymentTerms}
                closeEditPaymentTerms={() => setEditPaymentTerms(false)}
              />
            )}
            {paymentTermsError && (
              <ErrorPlaceholder
                message={t("There was an error retrieving the payment terms.")}
              />
            )}
          </SettingsCard>
          <SettingsCard>
            <Row>
              <H3>{t("Payment Method")}</H3>
              {!editPaymentModes && !paymentModesError && (
                <>
                  <EditButton
                    testid={"edit-payment-modes"}
                    data-for="edit-payment-modes"
                    onClick={() => setEditPaymentModes(true)}
                    title={t("Edit Payment Modes")}
                  />
                </>
              )}
              {editPaymentModes && !paymentModesError && (
                <CancelButtonSmall onClick={() => setEditPaymentModes(false)} />
              )}
            </Row>
            {!editPaymentModes &&
              !paymentModesError &&
              paymentModes.map((mode) => {
                return <TermOrModeRow key={mode.id}>{mode.name}</TermOrModeRow>;
              })}
            {editPaymentModes && !paymentModesError && (
              <EditPaymentModes
                paymentModes={paymentModes}
                mutatePaymentModes={mutatePaymentModes}
                closeEditPaymentModes={() => setEditPaymentModes(false)}
              />
            )}
            {paymentModesError && (
              <ErrorPlaceholder
                message={t(
                  "There was an error retrieving the payment methods."
                )}
              />
            )}
          </SettingsCard>
          <SettingsCard>
            <Row>
              <H3>{t("Shipping Terms")}</H3>
              {!editDeliveryTerms && !deliveryTermsError && (
                <EditButton
                  testid={"edit-shipping-terms"}
                  onClick={() => setEditDeliveryTerms(true)}
                  title={t("Edit Shipping Terms")}
                />
              )}
              {editDeliveryTerms && !deliveryTermsError && (
                <CancelButtonSmall
                  onClick={() => setEditDeliveryTerms(false)}
                />
              )}
            </Row>
            {!editDeliveryTerms &&
              !deliveryTermsError &&
              deliveryTerms.map((term) => {
                return (
                  <TermOrModeRow key={term.id}>
                    {term.name} - {term.description}
                  </TermOrModeRow>
                );
              })}
            {editDeliveryTerms && !deliveryTermsError && (
              <EditDeliveryTerms
                deliveryTerms={deliveryTerms}
                mutateDeliveryTerms={mutateDeliveryTerms}
                closeEditDeliveryTerms={() => setEditDeliveryTerms(false)}
              />
            )}
            {deliveryTermsError && (
              <ErrorPlaceholder
                message={t("There was an error retrieving the shipping terms.")}
              />
            )}
          </SettingsCard>
        </>
      )}
      <SettingsCard>
        <Row>
          <H3>{t("Terms of Sale")}</H3>
          {!editTermsOfSale &&
            (customTermsOfSale ? (
              <EditButton
                testid={"edit-terms-of-sale"}
                onClick={() => setEditTermsOfSale(true)}
                title={t("Edit Terms of Sale")}
              />
            ) : (
              <PrimaryButtonSmall onClick={() => setEditTermsOfSale(true)}>
                {t("Add")}
              </PrimaryButtonSmall>
            ))}
          {editTermsOfSale && (
            <CancelButtonSmall
              cancelText={t("Close")}
              onClick={() => setEditTermsOfSale(false)}
            />
          )}
        </Row>
        {!editTermsOfSale && customTermsOfSale && (
          <FileWrapper>
            <FileName>{customTermsOfSaleDocument!!.name}</FileName>
            <FileActions>
              <ViewButton
                onClick={() =>
                  viewStorefrontDocument(customTermsOfSaleDocument!!.id)
                }
                type="button"
              >
                {t("View")}
              </ViewButton>
            </FileActions>
          </FileWrapper>
        )}
        {editTermsOfSale && <EditTermsOfSale />}
      </SettingsCard>

      <SettingsCard>
        <Row>
          <H3>{t("Privacy Policy")}</H3>
          {!editPrivacyPolicy &&
            (customPrivacyPolicy ? (
              <EditButton
                testid={"edit-privacy-policy"}
                onClick={() => setEditPrivacyPolicy(true)}
                title={t("Edit Privacy Policy")}
              />
            ) : (
              <PrimaryButtonSmall onClick={() => setEditPrivacyPolicy(true)}>
                {t("Add")}
              </PrimaryButtonSmall>
            ))}
          {editPrivacyPolicy && (
            <CancelButtonSmall
              cancelText={t("Close")}
              onClick={() => setEditPrivacyPolicy(false)}
            />
          )}
        </Row>
        {!editPrivacyPolicy && customPrivacyPolicy && (
          <FileWrapper>
            <FileName>{customPrivacyPolicyDocument!!.name}</FileName>
            <FileActions>
              <ViewButton
                onClick={() =>
                  viewStorefrontDocument(customPrivacyPolicyDocument!!.id)
                }
                type="button"
              >
                {t("View")}
              </ViewButton>
            </FileActions>
          </FileWrapper>
        )}
        {editPrivacyPolicy && <EditPrivacyPolicy />}
      </SettingsCard>

      <SettingsCard>
        <>
          <Row>
            <H3>{t("Configure check-box on lead and transaction forms")}</H3>
            {!editConfigureCheckbox && (
              <EditButton
                testid={"edit-checkboxs"}
                onClick={() => setEditConfigureCheckbox(true)}
                title={t("Edit check-box on lead and transaction forms")}
              />
            )}
            {editConfigureCheckbox && (
              <CancelButtonSmall
                cancelText={t("Close")}
                onClick={() => setEditConfigureCheckbox(false)}
              />
            )}
          </Row>
          <ConfigureCheckboxsCard editMode={editConfigureCheckbox} />
        </>
      </SettingsCard>
    </>
  );
};
