import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { match } from "ts-pattern";
import { endpoints } from "../../../../../endpoints";
import type { FilterType, OptionType, UUID } from "../../../../../types/types";
import type {
  AttributeObjectType,
  AttributeSchema,
  PaginatedAttributeSchema,
  PortfolioControlsSchema,
} from "../../../../../types/types.PIM";
import { TEMPORARY_HIGH_LIMIT, useStoreState } from "../../../../../util/util";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import Axios from "axios";

export const usePortfolioControlsAttributeName = () => {
  const { t } = useTranslation();
  const [attributeNames, setAttributeNames] =
    useState<{ [prop in AttributeObjectFilterType]?: string }>();
  const { tenant_id } = useStoreState();

  useEffect(() => {
    let ignore = false;

    const getAttributeNames = async () => {
      const {
        data: { attributes },
      } = await axios.get<{
        attributes: {
          name: AttributeObjectFilterType;
          id: string;
          display_name: string;
        }[];
      }>(
        endpoints.v2_tenants_tenant_id_or_slug_pim_attributes_portfolio(
          tenant_id
        )
      );
      if (!ignore) {
        setAttributeNames(
          attributes.reduce<{ [prop in AttributeObjectType]?: string }>(
            (acc, cur) => ({
              ...acc,
              [cur.name]: cur.display_name ? t([cur.display_name]) : "",
            }),
            {
              [attributes[0].name]: attributes[0].display_name
                ? t([attributes[0].display_name])
                : "",
            }
          )
        );
      }
    };
    getAttributeNames();
    return () => {
      ignore = true;
    };
  }, [t, tenant_id]);

  return attributeNames;
};

export type AttributeObjectFilterType = Extract<
  | "applications"
  | "functions"
  | "product_group_1"
  | "product_group_2"
  | "product_group_3"
  | "industries"
  | "market_segments"
  | "produced_by"
  | "product_line"
  | "value_propositions",
  AttributeObjectType
>;

export const mapFilterTypeToAttributeObject = (
  filterType: FilterType
): AttributeObjectFilterType =>
  match(filterType)
    .with("application", () => "applications" as AttributeObjectFilterType)
    .with("function", () => "functions" as AttributeObjectFilterType)
    .with("group1", () => "product_group_1" as AttributeObjectFilterType)
    .with("group2", () => "product_group_2" as AttributeObjectFilterType)
    .with("group3", () => "product_group_3" as AttributeObjectFilterType)
    .with("industry", () => "industries" as AttributeObjectFilterType)
    .with(
      "market_segment",
      () => "market_segments" as AttributeObjectFilterType
    )
    .with("produced_by", () => "produced_by" as AttributeObjectFilterType)
    .with("product_line", () => "product_line" as AttributeObjectFilterType)
    .with(
      "proposition",
      () => "value_propositions" as AttributeObjectFilterType
    )
    // This should be fixed from the BE
    // There is a property "group" that shouldn't be here
    .otherwise(() => "product_group_1" as AttributeObjectFilterType);

export const mapAttributeObjectToFilterType = (
  attrObjectType: AttributeObjectFilterType
): FilterType =>
  match(attrObjectType)
    .with("applications", () => "application" as FilterType)
    .with("functions", () => "function" as FilterType)
    .with("industries", () => "industry" as FilterType)
    .with("market_segments", () => "market_segment" as FilterType)
    .with("produced_by", () => "produced_by" as FilterType)
    .with("product_group_1", () => "group1" as FilterType)
    .with("product_group_2", () => "group2" as FilterType)
    .with("product_group_3", () => "group3" as FilterType)
    .with("product_line", () => "product_line" as FilterType)
    .with("value_propositions", () => "proposition" as FilterType)
    .exhaustive();

export const useAttributesAsyncSearch = ({
  portfolioControlsData,
}: {
  portfolioControlsData?: PortfolioControlsSchema[];
}) => {
  const { notifyError } = useContext(Notifications);
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const [attributeOptions, setAttributeOptions] = useState<
    (OptionType<string> & {
      display_name: string;
      list_id: string | null;
      is_filterable: boolean | null;
    })[]
  >([]);
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const handleAttributesSearch = useCallback(
    debounce((query, setOptions) => {
      const attributeToOption = (
        attribute: AttributeSchema
      ): OptionType<string> => ({
        value: attribute.id,
        label: attribute.name,
      });

      const getOptions = async (query: string) => {
        try {
          const {
            data: { data },
          } = await Axios.get<PaginatedAttributeSchema>(
            `/v2/tenants/${tenant_id}/pim/attributes`,
            {
              params: { limit: TEMPORARY_HIGH_LIMIT, q: query },
            }
          );
          return data.map((attribute) => {
            const attributeOption: OptionType<string> & {
              display_name: string;
              list_id: UUID | null;
              is_filterable: boolean | null;
            } = {
              ...attributeToOption(attribute),
              display_name: attribute.display_name
                ? attribute.display_name
                : attribute.name,
              list_id: attribute.list_id,
              is_filterable: attribute.is_filterable,
            };
            return attributeOption;
          });
        } catch (error) {
          notifyError(
            t("Could not fetch attributes. Something went wrong", { error })
          );
          return [];
        }
      };
      getOptions(query).then((options) => {
        setOptions(
          options.filter(
            (item) =>
              !portfolioControlsData?.find(
                (control) => control.attribute.name === item.label
              )
          )
        );
        setAttributeOptions(options);
      });
    }, 1000),
    []
  );

  return { handleAttributesSearch, attributeOptions };
};
