import React from "react";
import type { Column } from "react-table";
import { useTable } from "react-table";
import styled from "styled-components/macro";
import type { IPriceTier } from "../../types/types";
import { formatPrice } from "../../util/util-components";
import { useCurrencySymbol } from "../../util/util";
import { useTranslation } from "react-i18next";

const StyledTable = styled.table`
  width: 100%;

  & th {
    color: ${({ theme }) => theme.secondaryTextColor};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: normal;
  }

  & th,
  & td {
    text-align: left;
    padding: 4px 0;
  }

  & th:last-child,
  & td:last-child {
    text-align: right;
  }
`;

const priceTierToTableRow = (tier: IPriceTier) => {
  const { minimum_sku_quantity, product_sku, price_per_uom } = tier;

  const sku = `${product_sku.packaging_type?.name ?? "--"} (${
    product_sku?.package_volume ?? "--"
  } ${product_sku?.packaging_unit?.name ?? "--"})`;

  return {
    minimum_sku_quantity,
    sku,
    price_per_uom: formatPrice(price_per_uom, tier.currency),
  };
};

/**
 * A table for displaying price tiers, typically as part of the quote request and
 * re-order workflows.
 */
export const PriceTiersTable = ({ tiers }: { tiers: IPriceTier[] }) => {
  const tiersCurrencySymbol = useCurrencySymbol(tiers[0].currency);
  const { t } = useTranslation();

  const columns: Column[] = React.useMemo(
    // Normally should not have to cast to string, this is special because it
    // needs to be of type Column to satisfy useTable below.
    () => [
      {
        Header: t("Minimum Units") as string,
        accessor: "minimum_sku_quantity",
      },
      {
        Header: t("SKU") as string,
        accessor: "sku",
      },
      {
        Header: t(`Price ({{currencySymbol}}/UoM)`, {
          currencySymbol: tiersCurrencySymbol,
        }) as string,
        accessor: "price_per_uom",
      },
    ],
    [tiersCurrencySymbol, t]
  );

  const rowData = tiers.map(priceTierToTableRow);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data: rowData,
    });

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, headerGroupIndex) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
            {headerGroup.headers.map((column, columnIndex) => (
              <th key={columnIndex}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={rowIndex}>
              {row.cells.map((cell, cellIndex) => (
                <td {...cell.getCellProps()} key={cellIndex}>
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};
