import React, { useState, useContext, useEffect, useCallback } from "react";
import type { IGroupedPriceTier, Product } from "../../../../../types/types";
import { SettingsCard } from "../../../../../layout/portalPageLayout";
import { submitPriceTierEdits } from "../../shared";
import { H3 } from "../../../../../components/Typography/Typography";
import {
  EditButton,
  CancelButtonSmall,
  PrimaryButtonMedium,
  SecondaryButtonSmall,
} from "../../../../../components/Buttons/Buttons";
import { InfoBlockExtraSmall } from "../../../../../components/InfoBlocks/InfoBlocks";
import {
  formatDate,
  promiseAllSettledLogAndThrow,
  useStoreState,
  useFormWrapper,
} from "../../../../../util/util";
import styled from "styled-components/macro";
import {
  usePriceTiersBigTable,
  tierToRowData,
} from "../../../../../components/PriceTiersBigTable/usePriceTiersBigTable";
import { PriceTiersBigTable } from "../../../../../components/PriceTiersBigTable/PriceTiersBigTable";
import { Form } from "../../../../../layout/FormLayout";
import { DatePicker } from "../../../../../components/DatePicker/DatePicker";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { endpoints } from "../../../../../endpoints";
import useSWR from "swr";
import { AddressBlock } from "../../../../../components/LocationsList/LocationsList";
import type { PriceTierIntersection } from "../../../../Buyer/BuyerMyProductsPriceTiers/BuyerMyProductsPriceTiers";
import { useTranslation } from "react-i18next";
import { Row } from "../../../../../components/Layout/Layout";

const PriceTierGroupDetailsGrid = styled.div`
  width: 400px;
  display: grid;
  grid-template-columns: 200px 200px;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

const PriceTiersTableContainer = styled.div`
  margin: 10px 36px 20px 0;
`;

const SubmitButtonContainer = styled.div`
  margin: 20px 0 0;
`;

const ValidUntilBox = styled.div`
  min-height: 65px;
`;

type FormValues = { valid_until: string };

export const ViewOrEditPriceTierGroup = ({
  tierGroup,
  index,
  mutateTiers,
  isEditable = true,
  addToCart,
}: {
  tierGroup: IGroupedPriceTier;
  index: number;
  mutateTiers: () => {};
  isEditable?: boolean;
  addToCart?: (
    row: Pick<PriceTierIntersection, "product_sku" | "minimum_uom_quantity">
  ) => void;
}) => {
  const { storefront_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);

  const [editPriceTiers, setEditPriceTiers] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation();

  const {
    tableRowsData,
    editRowIndex,
    addNewRow,
    startEditingRow,
    cancelEditingRow,
    finishEditingRow,
    deleteRow,
    resetTableRowsData,
  } = usePriceTiersBigTable(tierGroup.terms.map(tierToRowData));

  // Use useCallback to avoid dependency array issues with useEffect below.
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const memoizedResetTableRowsData = useCallback(resetTableRowsData, []);

  useEffect(() => {
    // If the tiers coming in as props change, update the table state.
    // This is needed, for example, because if the user deletes all the tiers
    // from one group, then we want to refresh all the tables with new data from
    // the server. Otherwise the UI gets into an inaccurate state. (Stale table
    // data for each group remains but the product etc. updates so the product
    // and the table data may not match!)
    memoizedResetTableRowsData(tierGroup.terms.map(tierToRowData));
  }, [tierGroup.terms, memoizedResetTableRowsData]);

  const rowEditInProgress = editRowIndex !== null;

  const cancelEditTierGroup = () => {
    setEditPriceTiers(false);
    cancelEditingRow();
    memoizedResetTableRowsData(tierGroup.terms.map(tierToRowData));
  };

  const noSkusMessage =
    "There was an error fetching the product SKUs, please try again";

  // When in edit mode, fetch the product here so we already have the possible
  // SKUs before the user edits a row and so we can immediately show the
  // editable row UI without having to wait on this request first at that point.
  // Also better to not have to make the request each time a row is edited.
  // Also allows us to use a valid product_sku when adding a new row to avoid
  // allowing a null sku in the row/tier data.
  const { data: product } = useSWR<Product>(
    editPriceTiers
      ? endpoints.v2_storefronts_id_pim_products_id(
          storefront_id,
          tierGroup.product.id
        )
      : null,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: (error) => {
        notifyError(noSkusMessage, { error });
        // If we can't get the product, we can't get the SKUs to offer when
        // editing and so we fail early, notify them, and don't let them edit.
        setEditPriceTiers(false);
      },
      onSuccess: (product) => {
        const non_sample_product_skus = product.product_skus.filter(
          (sku) => !sku.is_sample
        );
        if (!non_sample_product_skus[0]) {
          notifyError(noSkusMessage);
          setEditPriceTiers(false);
        }
      },
    }
  );

  const productSkus =
    product?.product_skus?.filter((sku) => !sku.is_sample) || [];
  const firstProductSku = productSkus.length > 0 ? productSkus[0] : null;

  const editPriceTiersAndReady = editPriceTiers && !!firstProductSku;

  const methodsOfUseForm = useFormWrapper<FormValues>({
    defaultValues: {
      valid_until: tierGroup.valid_to_date,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    setSubmitting(true);
    try {
      // The "Valid Until" input comes from the form. The rest of the data from
      // the tiers table is stored in React state, namely in `tableRowsData`
      // from usePriceTiersBigTable.
      const { valid_until } = formValues;

      const promises = submitPriceTierEdits({
        tableRowsData,
        valid_until,
        previousValidUntil: tierGroup.valid_to_date,
        seller_id: tierGroup.seller_id,
        buyer_id: tierGroup.buyer_id,
        currencyCode: tierGroup.currency,
        destination_id: tierGroup.destination.id,
        delivery_term_id: tierGroup.delivery_term.id,
        payment_term_id: tierGroup.payment_term.id,
        storefront_id,
      });
      await promiseAllSettledLogAndThrow(promises);

      notifySuccess("Changes saved");
      setSubmitting(false);
      setEditPriceTiers(false);
      // We need to mutate the tiers to handle the case where the user has
      // deleted all the price tier rows. (The whole group needs to go away.)
      mutateTiers();
    } catch (error) {
      notifyError("There was an error saving changes, please try again", {
        error,
      });
      setSubmitting(false);
    }
  };

  return (
    <SettingsCard>
      <Row>
        <H3>{tierGroup.product.name || "--"}</H3>
        {!editPriceTiersAndReady && isEditable && (
          <EditButton
            testid={`edit-price-tier-group-${index}`}
            onClick={() => {
              setEditPriceTiers(true);
            }}
            disabled={editPriceTiers}
          />
        )}
        {editPriceTiersAndReady && (
          <CancelButtonSmall onClick={cancelEditTierGroup} />
        )}
      </Row>
      <PriceTierGroupDetailsGrid>
        <InfoBlockExtraSmall
          // If everything has icons this can be added back, otherwise it will
          // throw off the spacing. I think everything probably should have
          // icons for consistency?
          // icon={<LocationIcon height={13} width={13} />}
          header={t("Destination")}
          content={<AddressBlock address={tierGroup.destination} />}
        />
        {!editPriceTiersAndReady && (
          <InfoBlockExtraSmall
            header={t("Valid Until")}
            content={formatDate(tierGroup.valid_to_date)}
          />
        )}
        {editPriceTiersAndReady && (
          <ValidUntilBox>
            <Form>
              <DatePicker
                label={t("Valid Until")}
                name={"valid_until"}
                methodsOfUseForm={methodsOfUseForm}
                required={true}
                defaultValue={tierGroup.valid_to_date}
              />
            </Form>
          </ValidUntilBox>
        )}
        <InfoBlockExtraSmall
          header={t("Shipping Terms")}
          content={tierGroup.delivery_term.name || "--"}
        />
        <InfoBlockExtraSmall
          header={t("Payment Terms")}
          content={tierGroup.payment_term.name || "--"}
        />
      </PriceTierGroupDetailsGrid>
      <PriceTiersTableContainer>
        <PriceTiersBigTable
          tableRowsData={tableRowsData}
          editRowIndex={editRowIndex}
          startEditingRow={startEditingRow}
          cancelEditingRow={cancelEditingRow}
          finishEditingRow={finishEditingRow}
          deleteRow={deleteRow}
          currencyCode={tierGroup.currency}
          isInEditMode={editPriceTiersAndReady}
          productSkus={productSkus}
          addToCart={addToCart}
        />
      </PriceTiersTableContainer>
      {editPriceTiersAndReady && (
        <>
          <div>
            <SecondaryButtonSmall
              onClick={() => {
                if (firstProductSku) {
                  // For TypeScript: first product sku will always be defined
                  // here because we won't be able to edit if it's not.
                  addNewRow(firstProductSku);
                }
              }}
              disabled={submitting || rowEditInProgress}
            >
              {t("Add Price Tier")}
            </SecondaryButtonSmall>
          </div>
          <SubmitButtonContainer>
            <PrimaryButtonMedium
              onClick={methodsOfUseForm.handleSubmit(onSubmit)}
              loading={submitting}
              disabled={rowEditInProgress}
            >
              {t("Save Changes")}
            </PrimaryButtonMedium>
          </SubmitButtonContainer>
        </>
      )}
    </SettingsCard>
  );
};
