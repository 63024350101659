import React, { useState } from "react";
import {
  Card,
  HorizontalButtonsContainer,
} from "../../../layout/portalPageLayout";
import type { LeadNote } from "../../../types/types";
import { InfoBlockExtraSmall } from "../../../components/InfoBlocks/InfoBlocks";
import { formatDateTime } from "../../../util/util";
import {
  DestructiveButton,
  PrimaryButtonLarge,
  PrimaryButtonSmall,
} from "../../../components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import { TextArea } from "../../../components/TextArea/TextArea";
import { useForm } from "react-hook-form";
import { Form } from "../../../layout/FormLayout";
import { H3 } from "../../../components/Typography/Typography";
import styled from "styled-components/macro";
import type { TFunction } from "i18next";

export const LeadNotesDisplayContainer = styled.div`
  > * {
    margin-bottom: 8px;
  }
`;

type LeadNotesProps = {
  internal_notes: LeadNote[];
  handleSubmit: ({
    note,
    onSuccess,
  }: {
    note: string;
    onSuccess: () => void;
  }) => Promise<void>;
};

export type HandleLeadNoteSubmitProps = Parameters<
  LeadNotesProps["handleSubmit"]
>[0];

function formatLeadNoteHeader(note: LeadNote, t: TFunction) {
  return `${note.creator_name} ${formatDateTime(note.created_at)} ${
    note.is_system_generated ? t("(Auto Generated)") : ""
  }`;
}

/**
 * Generic lead note display/create component, handles similar but not identical
 * lead notes for contact-us/registration and sample-request quote request leads.
 *
 * Pass in a function to make the apropriate network request, as well as
 * success/failure handlers. the responses for display are the same for all lead types.
 *
 */
export function LeadNotes({
  internal_notes,
  handleSubmit: handleSubmission,
}: LeadNotesProps) {
  const { t } = useTranslation();
  const [isEditingNote, setIsEditingNote] = useState(false);

  const { formState, register, handleSubmit, errors } = useForm();

  const onSubmit = ({ note }: { note: string }) => {
    handleSubmission({
      note,
      // We need this so the form doesn't close and lose what the user wrote in
      // failure case.
      onSuccess: () => setIsEditingNote(false),
    });
  };

  return (
    <Card>
      <H3>{t("Notes")}</H3>
      <LeadNotesDisplayContainer>
        {internal_notes.map((note) => (
          <InfoBlockExtraSmall
            key={note.id}
            header={formatLeadNoteHeader(note, t)}
            content={
              <div style={{ whiteSpace: "pre-wrap" }}>{note.message}</div>
            }
          />
        ))}
      </LeadNotesDisplayContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isEditingNote && (
          <>
            <TextArea
              name={"note"}
              label={t("Note")}
              theref={register({ required: true })}
              formState={formState}
              required={false}
              errors={errors}
            />
            <HorizontalButtonsContainer
              style={{ justifyContent: "flex-start" }}
            >
              <DestructiveButton
                type="button"
                onClick={() => setIsEditingNote(false)}
              >
                {t("Cancel")}
              </DestructiveButton>
              <PrimaryButtonSmall type="submit">{t("Save")}</PrimaryButtonSmall>
            </HorizontalButtonsContainer>
          </>
        )}
      </Form>
      {!isEditingNote && (
        <PrimaryButtonLarge onClick={() => setIsEditingNote(true)}>
          {t("Add")}
        </PrimaryButtonLarge>
      )}
    </Card>
  );
}
