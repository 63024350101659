import React, { useState } from "react";
import styled from "styled-components/macro";
import { CheckBoxNoLabel } from "../CheckBoxes/CheckBoxes";
import { StatusLeft } from "../Status/Status";
import { PrimaryButtonSmall } from "../Buttons/Buttons";
import { useTranslation } from "react-i18next";

export const FilterStateBox = styled.div`
  width: 280px;
  background-color: ${({ theme }) => theme.primaryBG};
  box-shadow: 7px 3px 14px -4px ${({ theme }) => theme.shadowColor};
`;

export const FilterStateContainer = styled.div`
  display: grid;
  ul,
  li {
    list-style: none;
    margin-left: 0;
    padding: 0;
  }
  li {
    margin-bottom: 13px;
  }
  margin-left: 20px;
`;

const FilterHeader = styled.div`
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.large};
  margin-top: 23px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  margin: 0 20px;
  padding-bottom: 20px;
  button {
    margin-left: 20px;
    color: ${({ theme }) => theme.primaryButtonTextColor} !important;
  }
`;

const ClearButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-size: ${({ theme }) => theme.fontSizes.medium} !important;
  font-weight: ${({ theme }) => theme.fontWeights.medium} !important;
  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.primaryLinkHover} !important;
  }
  :disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.primaryButtonTextColor} !important;
    opacity: 0.5;
  }
`;

interface IFilterModalProps {
  activeItems: any[];
  handleApply: (e: any) => void;
  list?: any;
}

export const FilterStates = ({
  activeItems,
  handleApply,
  list,
}: IFilterModalProps) => {
  /** 
  const itemsOneonOne = [
    { color: "blue", text: "New / Offered", value: "created" },
    { color: "blue", text: "Needs SO", value: "accepted" },
    { color: "gray", text: "Expired", value: "expired" },
    { color: "green", text: "Closed", value: "closed" },
    { color: "red", text: "Rejected", value: "rejected" },
  ];
  const itemsOpportunity = [
    { color: "blue", text: "New / No Offers", value: "active" },
    { color: "blue", text: "Responded / Offers", value: "responded" },
    { color: "gray", text: "Expired", value: "expired" },
    { color: "blue", text: "Needs SO", value: "accepted" },
    { color: "red", text: "Rejected", value: "rejected" },
    { color: "green", text: "Closed", value: "closed" },
  ];
  const itemsAuction = [
    { color: "blue", text: "New ", value: "created" },
    { color: "blue", text: "Awaiting SO / Needs SO", value: "accepted" },
    { color: "blue", text: "Accept / Bid Sent", value: "active" },
    { color: "orange", text: "Countered", value: "countered" },
    { color: "red", text: "Rejected", value: "rejected" },
    { color: "green", text: "Closed/ No Bids", value: "closed" },
    { color: "gray", text: "Expired", value: "expired" },
  ];
  */

  const { t } = useTranslation();

  const [pendingItems, setPendingItems] = useState(activeItems);
  const [is_item_clicked, set_is_item_clicked] = useState(false);
  const hasPendingItemsEmpty: boolean = pendingItems.length > 0;

  const clickItem = (item: any) => {
    // item.text includes a function (t)
    // fn !== fn. Hence the need for the fix
    const exists = pendingItems.some((c) => c.value === item.value);
    if (exists) {
      setPendingItems(
        pendingItems.filter((c) => {
          return c.value !== item.value;
        })
      );
    } else {
      setPendingItems([item, ...pendingItems]);
    }
    set_is_item_clicked(true);
  };

  const apply = () => {
    const is_active_same_as_pending = activeItems?.every(
      (c, idx) => c?.value === pendingItems[idx]?.value
    );
    if (is_item_clicked || !is_active_same_as_pending) {
      activeItems = pendingItems;
      handleApply(activeItems);
    }
  };

  const clear = () => {
    if (activeItems.length) {
      activeItems = [];
      setPendingItems([]);
      handleApply(activeItems);
    }
  };

  const checkItem = (item: any) => {
    const found = pendingItems.some((el) => el.value === item.value);
    return found;
  };
  return (
    <FilterStateBox>
      <FilterStateContainer>
        <FilterHeader>{t("Status")}</FilterHeader>
        <ul>
          {list.map((item: any, index: number) => {
            return (
              <li key={index}>
                <CheckBoxNoLabel
                  onChange={() => clickItem(item)}
                  defaultChecked={checkItem(item)}
                />
                <StatusLeft color={item.color} text={item.text} />
              </li>
            );
          })}
        </ul>
      </FilterStateContainer>
      <ButtonContainer>
        <ClearButton onClick={clear} disabled={!hasPendingItemsEmpty}>
          {t("Clear")}
        </ClearButton>
        <PrimaryButtonSmall onClick={apply} disabled={!hasPendingItemsEmpty}>
          {t("Apply")}
        </PrimaryButtonSmall>
      </ButtonContainer>
    </FilterStateBox>
  );
};
