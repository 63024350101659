import { screenSize } from "../../theme";
import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components/macro";
import { XIcon } from "../Icons/Icons";

interface IFlyoutProps {
  show: boolean;
  children: any;
  closeFlyout: () => void;
}
const SlideOutFade = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;
const Slide = keyframes`
 0% { right: -576px; }
 100% { right: 0; }
`;

const CloseIconAnimation = keyframes`
 0% { opacity: 0; transform: rotate(270deg)}
 50% { opacity: 0.3; }
 100% { opacity: 1; transform: rotate(0deg)}
`;

const SlideOutOverlay = styled.div`
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  align-content: stretch;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.overlayColor};
  animation-name: ${SlideOutFade};
  animation-duration: 0.3s;
  z-index: 900;
  overflow: hidden;
`;

const SlideOutContainer = styled.div`
  width: 576px;
  background: ${({ theme }) => theme.primaryBG};
  position: relative;
  flex: 0 0 auto;
  margin: 0px;
  padding: 32px;
  border-radius: 2px;
  font-family: ${({ theme }) => theme.fontFamily};
  overflow: auto;
  max-height: 100%;
  animation-name: ${Slide};
  animation-duration: 0.3s;
  @media ${screenSize.small} {
    width: 100%;
  }
  .__react_component_tooltip.place-top::before {
    bottom: -1px;
    height: 8px !important;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  right: 1rem;
  top: 3.1rem;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: transparent;
  animation-name: ${CloseIconAnimation};
  animation-duration: 0.4s;
`;

const SlideOutPanel = ({ ...props }: IFlyoutProps) => {
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      props.closeFlyout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", escFunction);

    return () => {
      document.removeEventListener("keyup", escFunction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(
    <SlideOutOverlay>
      <SlideOutContainer>{props.children}</SlideOutContainer>
      <CloseButton onClick={props.closeFlyout}>
        <XIcon />
      </CloseButton>
    </SlideOutOverlay>,
    document.body
  );
};

export const SlideOut = ({ ...props }: IFlyoutProps) => {
  if (props.show) {
    return (
      <SlideOutPanel show={props.show} closeFlyout={props.closeFlyout}>
        {props.children}
      </SlideOutPanel>
    );
  }
  return null;
};
