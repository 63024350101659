import { ArrowRight } from "../../../components/Icons/Icons";
import {
  SoftHeaderMedium,
  SoftHeaderMediumDarkText,
} from "../../../components/Typography/Typography";
import { screenSize } from "../../../theme";
import type { OptionType } from "../../../types/types";
import styled from "styled-components";

export const DashboardUpdatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  flex: 1;
  overflow-y: auto;
  height: fit-content;
  max-height: 900px;
  @media ${screenSize.medium} {
    max-height: 350px;
  }
`;

export const DashboardUpdatesHeader = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.secondaryBorder};
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  max-height: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const UpdateWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  cursor: pointer;
`;

export const UpdateUpperSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const UpdateEmptyState = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const KeyValueLabel = styled(SoftHeaderMediumDarkText)`
  color: ${({ theme }) => theme.navLinkColor};
  font-weight: bold;
`;

export const UpdateKeyValuePairs = ({
  data,
}: {
  data: OptionType<string>[];
}) => {
  return (
    <KeyValueWrapper>
      {data.map(({ label, value }) => (
        <span key={label}>
          <KeyValueLabel style={{ display: "inline" }}>
            {label + ": "}
          </KeyValueLabel>
          <SoftHeaderMedium style={{ display: "inline" }}>
            {value}
          </SoftHeaderMedium>
        </span>
      ))}
    </KeyValueWrapper>
  );
};

export const GotoUpdate = () => {
  return (
    <div
      style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}
    >
      <ArrowRight />
    </div>
  );
};

export const valueArrayToString = (properties: string[]) => {
  if (properties) {
    return properties.length <= 10
      ? properties.join(", ")
      : properties.slice(0, 11).join(", ") +
          `and ${properties.length - 10} more`;
  }
  return "--";
};

export const PAGE_SIZE = 10;
