import { zodResolver } from "@hookform/resolvers/zod";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import { IconContainer } from "./ListHome";
import { ListItemName } from "./ListItem";
import {
  ArchiveButton,
  ButtonWithConfirmDialog,
  DeleteButton,
  EditButton,
  PrimaryButtonFitContainer,
  SecondaryButtonFitContainer,
  SecondaryButtonSmall,
} from "../../../../../components/Buttons/Buttons";
import ReactTooltip from "react-tooltip";
import { ItemDetailsWrapper } from "./ListDetails";
import type {
  ListItemSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import {
  checkTextOverflow,
  formatDateTime,
  useFormWrapper,
} from "../../../../../util/util";
import { useAuthContext } from "../../../../../components/Auth";
import { SystemIconWrapper } from "../components/PIM.components.util";
import {
  ImagePlaceHolderIcon,
  SystemDefaultIcon,
} from "../../../../../components/Icons/Icons";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { HeaderLeft } from "../../../../../components/Layout/Layout";
import { Flex, Form } from "../../../../../layout/FormLayout";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { InfoBlockSmall } from "../../../../../components/InfoBlocks/InfoBlocks";
import {
  zodRequiredString,
  zodOptionalString,
} from "../../../../../util/zod.util";
import { z } from "zod";
import styled from "styled-components";
import { UploadWrapper } from "../../../../../components/FilePicker/FilePicker";
import { useDropzone } from "react-dropzone";
import type { FileRejection } from "react-dropzone";
import {
  MAXIMUM_FILE_SIZE,
  SUPPORTED_IMG_TYPES,
} from "../../../../../components/FilePicker/FilePicker.constants";
import { formatBytes } from "../SellarAdminPIMAssets/util/AssetsUtil";
import { UploadedFileName } from "../../../../../components/FileCard/FileCard";
import { XLTitle } from "../../../../../components/Typography/Typography";

const ModifiedUploadWrapper = styled(UploadWrapper)`
  margin: 0px 0px 30px 0 !important;
  word-wrap: break-word;
`;
const FileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  column-gap: 5px;
`;
const Thumbnail = styled.div`
  width: 34px;
  height: 34px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const FileNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 25px;
  padding: 10px;
  border: ${({ theme }) => `1px solid ${theme.primaryBorder}`};
  border-radius: 4px;
`;

const FileNameText = styled.div`
  margin: 0 12px;
`;

const DropHereMessage = styled.div`
  margin: 10px 0px;
`;

export const ListItemDetails = ({
  item: {
    name,
    id,
    is_frozen,
    created_at,
    created_by,
    modified_at,
    modified_by,
    thumbnail,
    file_name,
  },
  parentList,
  showIcon,
  active,
  selectListItem,
  handleDeleteListItem,
  handleEditListItem,
}: {
  item: ListItemSchema;
  parentList: ShortListSchema;
  showIcon: boolean;
  active: boolean;
  selectListItem: () => void;
  handleDeleteListItem: () => void;
  handleEditListItem: (
    formValue: { name?: string; icon?: File; delete_icon?: boolean },
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
}) => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const nameRef = useRef<HTMLHeadingElement>(null);
  const editNameRef =
    useRef<{ setEditMode: (editMode: boolean) => void }>(null);

  const { hasPermission } = useAuthContext();

  const handleEditClick = () => {
    setEditMode(true);
    setTimeout(() => editNameRef.current?.setEditMode(true));
  };

  const onEdit = ({ value, icon }: { value: string; icon: File }) => {
    handleEditListItem(
      {
        name: value?.trim(),
        icon: selectedFile ?? undefined,
      },
      setEditMode
    );
  };

  const isTextOverflow = () =>
    nameRef.current ? checkTextOverflow(nameRef.current).widthOverflow : false;

  const ListItemSchemaFn = (t: TFunction, maxLength?: number) =>
    z.object({
      list_name: zodOptionalString,
      value: zodRequiredString(t).max(
        maxLength ?? 25,
        t("Maximum {{chars}} characters allowed", { chars: maxLength ?? 25 })
      ),
      icon: z.instanceof(File).optional(),
    });

  const { handleSubmit, register, formState, errors, setValue } =
    useFormWrapper({
      resolver: zodResolver(ListItemSchemaFn(t, 50)),
    });

  const [DropzoneError, setDropzoneError] =
    useState<FileRejection | string | null>();

  const handleDeleteIcon = () => {
    handleEditListItem({ delete_icon: true }, () => setEditMode(true));
  };

  const onDrop = useCallback(
    (files: File[], fileRejections) => {
      const errorCode = fileRejections[0]?.errors[0]?.code;
      const fileSize = (
        fileRejections[0]?.file?.size / Math.pow(10, 6)
      ).toFixed(2);

      if (errorCode) {
        if (errorCode === "file-too-large") {
          setDropzoneError(
            t(
              "Size of File is {{fileSize}} MB and should be less than {{maxFileSize}}",
              { fileSize, maxFileSize: formatBytes(MAXIMUM_FILE_SIZE) }
            )
          );
        }
        if (errorCode === "file-invalid-type") {
          setDropzoneError(t(`This file type is not allowed`));
        }
      } else {
        setValue("icon", files[0]);
        setSelectedFile(files[0]);
        setDropzoneError(null);
      }
    },
    [t, setSelectedFile, setValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: MAXIMUM_FILE_SIZE,
    accept: SUPPORTED_IMG_TYPES ?? [],
  });

  const fileName = selectedFile?.name;

  useEffect(() => {
    if (!active && editMode) {
      setEditMode(false);
    }
  }, [active, editMode]);
  return (
    <ItemDetailsWrapper active={active} onClick={selectListItem}>
      <ListItemName
        ref={nameRef}
        data-for={`list-item-details-name-${name}-tooltip`}
        data-tip={isTextOverflow() ? name : ""}
        style={{ display: "flex", alignItems: "center" }}
      >
        {showIcon && (
          <Thumbnail>
            {!thumbnail && <ImagePlaceHolderIcon />}
            {thumbnail && <img src={thumbnail} alt={file_name} />}
          </Thumbnail>
        )}
        {name}
      </ListItemName>
      <ReactTooltip id={`list-item-details-name-${name}-tooltip`} />

      <SystemIconWrapper
        style={{
          padding: 0,
          position: "relative",
          top: "2px",
        }}
        data-for={`system${id}`}
        data-tip={t("System Default")}
      >
        {is_frozen && <SystemDefaultIcon width={18} height={18} />}
      </SystemIconWrapper>
      {is_frozen && <ReactTooltip id={`system${id}`} />}
      <IconContainer>
        {!is_frozen && hasPermission("delete_lists") && (
          <>
            <ButtonWithConfirmDialog
              Button={(props) => (
                <>
                  <ArchiveButton {...props} width={24} height={24} />
                </>
              )}
              testid={`list-item-${name}`}
              handleConfirm={handleDeleteListItem}
              confirmMessage={t(
                "Are you sure you want to archive this list item, {{itemName}}?",
                { itemName: name }
              )}
            />
          </>
        )}
        {hasPermission("modify_lists") && (
          <EditButton onClick={handleEditClick} width={24} height={24} />
        )}
      </IconContainer>

      <SlideOut show={!!editMode} closeFlyout={() => setEditMode(false)}>
        <HeaderLeft>
          <XLTitle>{t("Edit Value")}</XLTitle>
        </HeaderLeft>
        <Form noValidate onSubmit={handleSubmit(onEdit)}>
          <TextField
            name="list_name"
            defaultValue={parentList.name}
            label={t("List Name")}
            theref={register({
              required: false,
            })}
            formState={formState}
            errors={errors}
            type="text"
            disabled
          />
          <TextField
            name="value"
            label={t("Value")}
            defaultValue={name}
            theref={register({
              required: true,
            })}
            formState={formState}
            errors={errors}
            type="text"
          />
          {showIcon && (
            <>
              {!thumbnail && (
                <ModifiedUploadWrapper
                  {...getRootProps()}
                  error={DropzoneError}
                >
                  <input {...getInputProps()} data-testid="file-picker-input" />
                  {isDragActive ? (
                    <p>{t("Drop the icon here...")}</p>
                  ) : (
                    <FileWrapper>
                      {fileName ? (
                        <UploadedFileName fileName={fileName ?? ""} />
                      ) : (
                        <DropHereMessage>
                          {t("Drag and drop a file or")}
                        </DropHereMessage>
                      )}

                      {!selectedFile ? (
                        <SecondaryButtonSmall
                          onClick={(e) => e.preventDefault()}
                          style={{ marginTop: "4px" }}
                        >
                          {t("Click to Upload")}
                        </SecondaryButtonSmall>
                      ) : (
                        <SecondaryButtonSmall
                          onClick={(e) => e.preventDefault()}
                          style={{ marginTop: "4px" }}
                        >
                          {t("Click to replace")}
                        </SecondaryButtonSmall>
                      )}
                      <DropHereMessage>
                        {t("Max file size: {{maxFileSize}}", {
                          maxFileSize: formatBytes(MAXIMUM_FILE_SIZE),
                        })}
                      </DropHereMessage>
                      {SUPPORTED_IMG_TYPES && (
                        <DropHereMessage>
                          {t("Allowed extensions")}{" "}
                          {`( ${SUPPORTED_IMG_TYPES?.join(", ")} )`}
                        </DropHereMessage>
                      )}
                    </FileWrapper>
                  )}
                </ModifiedUploadWrapper>
              )}
              {thumbnail && (
                <>
                  <FileNameContainer>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    >
                      <div style={{ width: "32px", height: "32px" }}>
                        <img
                          src={thumbnail}
                          alt={file_name}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                      <FileNameText>{file_name}</FileNameText>
                    </div>
                    <ButtonWithConfirmDialog
                      Button={DeleteButton}
                      testid={"delete-icon-button"}
                      handleConfirm={handleDeleteIcon}
                      loading={false}
                      buttonText={t("Delete")}
                      confirmMessage={t(
                        "Are you sure you want to delete this icon?"
                      )}
                    />
                  </FileNameContainer>
                </>
              )}
            </>
          )}
          <InfoBlockSmall
            header={t("Created On")}
            content={created_at ? formatDateTime(created_at) : "-"}
          />
          <InfoBlockSmall
            header={t("Created By")}
            content={created_by || "-"}
          />
          <InfoBlockSmall
            header={t("Last Modified On")}
            content={modified_at ? formatDateTime(modified_at) : "-"}
          />
          <InfoBlockSmall
            header={t("Last Modified By")}
            content={modified_by || "-"}
          />
          <Flex style={{ marginTop: "30px" }}>
            <SecondaryButtonFitContainer
              onClick={() => setEditMode(false)}
              style={{ marginRight: "10px" }}
            >
              {t("Cancel")}
            </SecondaryButtonFitContainer>
            <PrimaryButtonFitContainer
              type="submit"
              style={{ marginLeft: "10px" }}
              onClick={() => handleSubmit(onEdit)}
            >
              {t("Save")}
            </PrimaryButtonFitContainer>
          </Flex>
        </Form>
      </SlideOut>
    </ItemDetailsWrapper>
  );
};
