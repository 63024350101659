import React, { useCallback, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import type { Lead } from "../../../components/quoteCart/cartUtils";
import { CartContext } from "../../../components/quoteCart/CartContext";
import type { AxiosError } from "axios";
import { LeadsCart } from "../../public/LeadsCart";
import type { SampleRequestStageOne } from "../../../types/types";
import { useStoreState } from "../../../util/util";
import { SampleRequestCart } from "../../public/SampleRequestCart/SampleRequestCart";
import { useRoutePath } from "../../../util/Routing";
import { Store } from "../../../Store";
import { useHistory } from "react-router-dom";

/**
 * This component occupies the "/cart" route for logged out users and
 * returns either the `LeadsCart` component or the `SampleRequestCart`
 * component depending upon the existence of UUIDs in localStorage. It
 * should not be possible for both IDs to exist simultaneously.
 *
 * This component makes two swr calls, both of which should hit the cache
 * because CartWidget makes the same calls.
 */
export function CartSwitcherGuest() {
  const { cartId, sampleCartID } = useContext(CartContext);
  const { storefront_id } = useStoreState();
  const { storePath } = useRoutePath();
  const {
    storeState: { stored_location },
    storeDispatch,
  } = useContext(Store);
  const history = useHistory();
  const [redirectURI, setRedirectURI] = useState("");

  const {
    data: lead,
    mutate: mutateLeadsCart,
    error: leadsError,
  } = useSWR<Lead, AxiosError>(cartId ? `/v1/cart/${cartId}` : null);

  const {
    data: sampleRequest,
    error: sampleRequestError,
    mutate: mutateSampleRequest,
  } = useSWR<SampleRequestStageOne, AxiosError>(
    sampleCartID
      ? `/v1/storefronts/${storefront_id}/sample-requests/${sampleCartID}`
      : null
  );

  const emptyCart =
    !lead && !leadsError && !sampleRequest && !sampleRequestError;

  // Existing code seems to suggest that the items array can be empty?
  // Perhaps the cart continues to be referencable even after all items are
  // deleted?
  const sampleRequestHasItems = sampleRequest && sampleRequest.items.length > 0;
  const leadHasItems = lead && lead.items.length > 0;

  /**
   * Handler for the "back" button. Redirects to the "parent" page which is the
   * portfolio/product page.  If the user came from a product page, preserve
   * any params for filters and search so they end up where they started.
   * Use the stored location to preserve the params.
   */
  const goToParentPage = useCallback(() => {
    storeDispatch({ type: "SET_STORED_LOCATION", payload: null });

    const productPath = `${storePath}/product`;

    const destination = stored_location?.includes(productPath)
      ? stored_location
      : `${storePath}/portfolio`;

    history.replace(destination);
  }, [storeDispatch, history, stored_location, storePath]);

  useEffect(() => {
    if (emptyCart && !sampleRequestHasItems && !leadHasItems) {
      if (redirectURI) {
        console.info(
          "Redirecting to login page because email associated" +
            " with lead is registered."
        );
        history.replace(redirectURI);
      } else {
        console.info(
          "Redirecting away from the cart page because there are" +
            " no cart items."
        );
        // This call has to be made in a useEffect to avoid a React error about
        // not doing updates during a render.
        goToParentPage();
      }
    }
  }, [
    goToParentPage,
    leadHasItems,
    redirectURI,
    sampleRequestHasItems,
    emptyCart,
    history,
  ]);

  if (lead) {
    return (
      <LeadsCart
        lead={lead}
        mutate={mutateLeadsCart}
        setRedirectURI={setRedirectURI}
        goToParentPage={goToParentPage}
      />
    );
  } else if (sampleRequest) {
    return (
      <SampleRequestCart
        sampleRequest={sampleRequest}
        mutate={mutateSampleRequest}
        setRedirectURI={setRedirectURI}
        goToParentPage={goToParentPage}
      />
    );
  }
  return null;
}
