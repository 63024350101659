import {
  GoBackButton,
  TextButton,
  PrimaryButtonWithPlusIcon,
} from "../../../../../components/Buttons/Buttons";
import styled from "styled-components";
import { Table } from "../../../../../components/Table/Table";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import {
  ContentWrapper,
  HeaderBar,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../../layout/portalPageLayout";
import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { Modal } from "../../../../../components/Modal/Modal";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useAuthContext } from "../../../../../components/Auth";
import {
  providePrivatePageProps,
  useRoutePath,
} from "../../../../../util/Routing";
import { Title } from "../../../../../components/Typography/Typography";
import type { Contact, ContactsTable } from "./ProspectsList";
import { Tab, Tabs } from "../../../../../components/Tabs/Tabs";
import { SettingsIcon, UserIcon } from "../../../../../components/Icons/Icons";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { makeUrlWithParams } from "../../../../../util/util";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";

const HubspotButton = styled.div`
  position: relative;
  width: 100%;
  button {
    position: absolute;
    top: 0px;
    right: 0;
  }
`;

type ListDetailsResponse = {
  list_name: string;
  contact_list: Contact[];
};

const localStrings = (t: TFunction) => ({
  lists: t("Lists"),
  contacts: t("Contacts"),
  emails: t("Emails"),
  companyName: t("Company Name"),
  email: t("Email"),
  phoneNumber: t("Phone Number"),
  contactOwner: t("Contact Owner"),
  associatedCompany: t("Associated Company"),
  title: t("Title"),
  from: t("From"),
  createNew: t("Create New"),
  perPage: t("Per Page"),
});

export const ProspectsListDetails = providePrivatePageProps(({ user }) => {
  const { roleIsSomeKindOfSeller } = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const [perPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState<ContactsTable[]>([]);
  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  const [offset, setOffset] = useState(0);
  const [pagination, setPagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const { list_id } = useParams<{ list_id: string }>();
  const { adminPath } = useRoutePath();
  const [query, setQuery] = useQueryParams({
    view: StringParam,
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(query.view || t("Contacts"));
  const currentTabIsContacts = currentTab === t("Contacts");

  const { data: listDetailsResponse, error: listDetailsError } = useSWR<
    ListDetailsResponse | undefined
  >(`/v1/digital-marketing/list-${list_id}.json`);

  useEffect(() => {
    if (currentTabIsContacts && listDetailsResponse) {
      const contacts = listDetailsResponse.contact_list.slice(
        offset,
        offset + perPage
      );
      setTableData(
        contacts.map((contact: Contact) => {
          return {
            id: contact.contact_id,
            name: `${contact.first_name} ${contact.last_name}`,
            email: contact.email,
            phone_number: contact.phone_number,
            associated_company: contact.company_name,
            contact_owner: contact.contact_owner,
          };
        })
      );
      setPagination({
        perPage: perPage,
        pageCount: Math.ceil(listDetailsResponse.contact_list.length / perPage),
        pageIndex: offset / perPage + 1,
      });
    }
  }, [
    currentTab,
    currentTabIsContacts,
    listDetailsResponse,
    setTableData,
    perPage,
    offset,
  ]);

  const isLoading =
    currentTabIsContacts && !listDetailsResponse && !listDetailsError;

  const handlePageClick = (offset: number) => {
    setOffset(offset);
  };

  const handleRowClick = (e: React.MouseEvent): void => {
    if (currentTab === t("Contacts")) {
      window.open(
        `https://app.hubspot.com/contacts/5943361/contact/${e.currentTarget.id}`,
        "_blank"
      );
    } else if (currentTab === t("Emails")) {
      //history.push("");
    }
  };

  const contactsColumns = useMemo(
    () => [
      {
        accessor: "name",
        Header: localStrings(t).companyName,
      },
      { accessor: "email", Header: localStrings(t).email },
      { accessor: "phone_number", Header: localStrings(t).phoneNumber },
      {
        accessor: "associated_company",
        Header: localStrings(t).associatedCompany,
      },
      {
        accessor: "contact_owner",
        Header: localStrings(t).contactOwner,
        align: "right",
      },
    ],
    [t]
  );

  const clearParamsAndSetTab = (label: string) => {
    // This strips the existing params from the URL without a page reload
    history.push(location.pathname);
    setCurrentTab(label);
    // set the view param to make the tab routable
    setQuery({ view: label });
  };

  return (
    <PageWrapper>
      <Link
        to={makeUrlWithParams(`${adminPath}/prospects`, {
          view: "Lists",
          q: query.q,
          offset: query.offset,
          perPage: query.perPage,
        })}
      >
        <GoBackButton text="Lists" />
      </Link>
      <PageHeader>
        <PageTitle>{listDetailsResponse?.list_name}</PageTitle>
        {roleIsSomeKindOfSeller && (
          <PrimaryButtonWithPlusIcon
            onClick={openModal}
            data-alignright
            disabled
          >
            {localStrings(t).createNew}
          </PrimaryButtonWithPlusIcon>
        )}
        <Modal closeModal={closeModal} show={showModal}>
          <Title>Create New Contact</Title>
        </Modal>
      </PageHeader>
      <HeaderBar></HeaderBar>
      <ContentWrapper>
        <HubspotButton>
          <TextButton
            onClick={() =>
              window.open(
                `https://app.hubspot.com/contacts/5943361/lists/${list_id}`,
                "_blank"
              )
            }
          >
            Manage in Hubspot
          </TextButton>
        </HubspotButton>
        <Tabs selectedIndex={query.view || t("Company Info")}>
          <Tab
            onClick={clearParamsAndSetTab}
            label={localStrings(t).contacts}
            icon={UserIcon}
          >
            <>
              <Table
                columns={
                  // ???
                  currentTab === localStrings(t).contacts
                    ? contactsColumns
                    : contactsColumns
                }
                isLoading={isLoading}
                error={listDetailsError}
                data={tableData}
                rowClick={handleRowClick}
              />
              <Pagination
                pagination={pagination}
                offset={offset}
                handlePageClick={handlePageClick}
              />
            </>
          </Tab>

          <Tab
            onClick={clearParamsAndSetTab}
            label={localStrings(t).emails}
            icon={SettingsIcon}
          >
            <></>
          </Tab>
        </Tabs>
      </ContentWrapper>
    </PageWrapper>
  );
});
