import styled from "styled-components/macro";

export const Title = styled.h1`
  /* 36px */
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes["xl-2"]};
  font-weight: ${({ theme }) => theme.fontWeights.large};
  color: ${({ theme }) => theme.primaryTextColor};
`;

export const SoftHeader = styled.h6`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.secondaryTextColor};
  margin-block-start: 0px;
  margin-block-end: 0px;
`;

export const SoftHeaderMediumWeight = styled(SoftHeader)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const SoftHeader2 = styled(SoftHeader)`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const SoftHeaderMedium = styled(SoftHeader)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const SoftHeaderMediumDarkText = styled(SoftHeaderMedium)`
  color: ${({ theme }) => theme.primaryButtonTextColor};
`;

export const SoftHeaderRegular = styled(SoftHeader)`
  font-size: ${({ theme }) => theme.fontSizes.regular};
`;

export const SoftHeaderPrimaryTextColor = styled(SoftHeader)`
  color: ${({ theme }) => theme.primaryTextColor};
`;

export const H4 = styled(SoftHeader)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.tertiaryBG};
`;

export const H4Normal = styled(H4)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const H5 = styled(SoftHeader)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.tertiaryBG};
`;

export const H5Normal = styled(H5)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const DestructiveText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.destructiveTextColor};
`;

export const H6 = styled(SoftHeader)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.tertiaryBG};
`;
export const H6Bold = styled(H6)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.large};
`;

export const H3 = styled.h3`
  /* 19px */
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.large};
  color: ${({ theme }) => theme.primaryTextColor};
`;

/**
 * 13px height
 */
export const SmallSectionHeader = styled(H3)`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  /* font-weight: ${({ theme }) => theme.fontWeights.large}; */
  color: ${({ theme }) => theme.primaryTextColor};
`;

export const SmallSectionHeaderRegular = styled(SmallSectionHeader)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const H3DarkText = styled(H3)`
  font-size: ${({ theme }) => theme.fontSizes["xl-small"]};
  color: ${({ theme }) => theme.colors.darkText};
`;

export const XLTitle = styled(Title)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  font-weight: ${({ theme }) => theme.fontWeights.xl};
`;

export const XLTitleRegularWeight = styled(Title)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const LargeTitleRegularWeight = styled(Title)`
  font-size: ${({ theme }) => theme.fontSizes["xl-small"]};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const XL4Title = styled(Title)`
  font-size: ${({ theme }) => theme.fontSizes["xl-4"]};
`;

export const FormInputErrorText = styled.span`
  color: ${({ theme }) => theme.errorColor};
  font-size: 10px;
  line-height: 10px;
  display: block;
`;

export const FormInputWarnText = styled.span`
  color: ${({ theme }) => theme.warningTextColor};
  font-size: 10px;
  line-height: 10px;
`;

export const StyledP = styled.p`
  white-space: pre-wrap;
  width: 100%;
  margin: 0;
`;

export const SmallText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const RegularText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.secondaryTextColor};
`;

export const RegularTextSmall = styled(RegularText)`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const RegularParagraph = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.secondaryTextColor};
`;

export const InnerTitle = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

export const ClickableLink = styled.a`
  padding: 0px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const ClickableLinkOverflow = styled(ClickableLink)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
`;

export const InnerTitleText = styled.div`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
`;

export const SubheaderContentText = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin: 0px 5px;
`;
