import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButtonLarge, PrimaryButtonSmall } from "../Buttons/Buttons";
import { SelectBoxV2 } from "../SelectBoxV2/SelectBoxV2";
import { TextArea } from "../TextArea/TextArea";
import { TextField } from "../TextFields/TextFields";
import { Title, XLTitle } from "../Typography/Typography";
import type { ISupportRequest, OptionType } from "../../types/types";
import { Flex, Flex1, Flex2, Form } from "../../layout/FormLayout";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import * as yup from "yup";
import "yup-phone";
import {
  countryCodeMap,
  getCountryCode,
  getPhoneCodeOption,
  getPhoneCodesOptions,
  getPhoneNumber,
} from "../../util/phone";
import { useRoutePath } from "../../util/Routing";
import { strings } from "../../util/strings";
import { isAxiosError, useFormWrapper, useStoreState } from "../../util/util";
import { Auth } from "../Auth";
import { Modal } from "../Modal/Modal";
import { Notifications } from "../Notifications/NotificationsContext";
import {
  captcha_yup_validation,
  ControlledRecaptcha,
} from "../Recaptcha/ControlledRecaptcha";
interface ISupportForm {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  phone_number: string;
  country_code: OptionType;
  message: string;
  captcha_token: string;
}

const Container = styled.div`
  max-width: 780px;
  margin: 0 auto;
`;

const SupportCard = styled.div`
  input {
    margin-bottom: 15px;
  }
  #countryCodeParent {
    input {
      margin-bottom: 0;
    }
  }
`;

const ModalWrapper = styled.div`
  padding: 50px 90px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > div {
    flex-grow: 1;
    margin-right: 9px;
  }
  & > div:nth-last-child(1) {
    margin-right: 0;
  }
`;

const yupSchema = (t: TFunction) =>
  yup.lazy((formValues: any) => {
    return yup.object().shape({
      first_name: yup.string().required(strings(t).thisIsARequiredField),
      last_name: yup.string().required(strings(t).thisIsARequiredField),
      company: yup.string().required(strings(t).thisIsARequiredField),
      email: yup
        .string()
        .email(strings(t).emailAddressMustBeValid)
        .required(strings(t).thisIsARequiredField),
      country_code: yup
        .object()
        .shape({
          label: yup.string().required(strings(t).thisIsARequiredField),
          value: yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      phone_number: yup
        .string()
        .phone(
          countryCodeMap.get(formValues.country_code?.value),
          false,
          strings(t).phoneNumberMustBeValid
        )
        .required(strings(t).thisIsARequiredField),
      ...captcha_yup_validation(t),
      message: yup.string().required("This is a required field"),
    });
  });

export function SupportPage() {
  const { t } = useTranslation();
  const methodsOfuseForm = useFormWrapper<ISupportForm>({
    resolver: yupResolver(yupSchema(t)),
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { notifyError } = useContext(Notifications);
  const { storePath } = useRoutePath();
  const { user } = useContext(Auth);
  const {
    storefront_id,
    storefront_metadata: { custom_support_url },
  } = useStoreState();
  const {
    api_metadata: { captcha_site_key },
  } = useStoreState();

  const { handleSubmit, register, formState, errors, control, reset } =
    methodsOfuseForm;

  const onSubmit = async (inputs: ISupportForm) => {
    // Validate email via sendgrid
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`/v1/emails/validate`, {
        email: inputs.email,
        source: "support form",
      });

      if (response.data.valid) {
        postFormData(inputs);
      }
    } catch (error) {
      notifyError(t("Email validation error, please try again"), { error });
      setLoading(false);
    }
  };

  const postFormData = async (inputs: ISupportForm) => {
    const {
      first_name,
      last_name,
      company,
      email,
      phone_number,
      country_code,
      message,
      captcha_token,
    } = inputs;

    try {
      const dataToPost: ISupportRequest = {
        name: `${first_name} ${last_name}`,
        email: email,
        phone_number: `${country_code.value}${phone_number}`,
        company_name: company,
        description: message,
        captcha_token: captcha_token,
        recaptcha_key: captcha_site_key,
      };

      const response = await axios.post(
        `/v1/storefronts/${storefront_id}/support`,
        dataToPost
      );
      setLoading(false);

      if (response.status === 208) {
        notifyError(
          t(
            "You are already registered on the storefront. Please log in to raise the request"
          )
        );
        history.push(`${storePath}/login`);
        return;
      } else {
        setShowConfirmModal(true);
      }
    } catch (error) {
      if (isAxiosError(error) && error?.response?.status === 409) {
        notifyError(t(`Support request is already in progress`));
        setLoading(false);
        return;
      }
      notifyError(t("There was an error, please try again"), { error });
      setLoading(false);
    }
  };

  const closeConfirmationModal = () => {
    reset();
    setShowConfirmModal(false);
  };

  if (custom_support_url) {
    window.open(custom_support_url, "_blank");
    return <Redirect to={`${storePath}/home`} />;
  }

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Title>{t("Support Request")}</Title>
        <SupportCard>
          <Flex style={{ marginRight: "0px" }}>
            <Flex1 style={{ marginRight: "20px" }}>
              <TextField
                name="first_name"
                label={t("First Name")}
                theref={register({
                  required: true,
                })}
                formState={formState}
                errors={errors}
                type="text"
                tabIndex={1}
                defaultValue={user?.firstname}
                readOnly={user ? true : false}
              />
              <TextField
                name="email"
                label={t("Email Address")}
                theref={register({
                  required: true,
                })}
                formState={formState}
                errors={errors}
                type="email"
                tabIndex={3}
                defaultValue={user?.email_address}
                readOnly={user ? true : false}
              />
            </Flex1>
            <Flex1 style={{ minWidth: "100px" }}>
              <TextField
                name="last_name"
                label={t("Last Name")}
                theref={register({
                  required: true,
                })}
                formState={formState}
                errors={errors}
                type="text"
                tabIndex={2}
                defaultValue={user?.lastname}
                readOnly={user ? true : false}
              />
              <Flex>
                <Flex1 id="countryCodeParent" style={{ minWidth: "130px" }}>
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name="country_code"
                    autoComplete="countryCode"
                    placeholder={t("Country Code")}
                    id="countryCodeSelectBox"
                    options={getPhoneCodesOptions()}
                    defaultValue={
                      user
                        ? getPhoneCodeOption(getCountryCode(user.phone_number))
                        : { label: undefined, value: undefined }
                    }
                    rules={{
                      required: true,
                    }}
                    errors={errors}
                    formState={formState}
                    tabIndex={4}
                  />
                </Flex1>
                <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                  <TextField
                    name="phone_number"
                    label={t("Phone Number")}
                    defaultValue={
                      user ? getPhoneNumber(user.phone_number)?.toString() : ""
                    }
                    theref={register({
                      required: true,
                    })}
                    formState={formState}
                    errors={errors}
                    type="phone"
                    tabIndex={5}
                  />
                </Flex2>
              </Flex>
            </Flex1>
          </Flex>
          <Row>
            <TextField
              name="company"
              label={t("Company")}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
              tabIndex={6}
              defaultValue={user ? user?.company_name ?? "" : ""}
              readOnly={user ? true : false}
            />
          </Row>
          <Row>
            <TextArea
              name="message"
              label={t("Describe your issue")}
              theref={register({
                required: false,
              })}
              required={false}
              errors={errors}
              formState={formState}
              type="text"
              tabIndex={7}
            />
          </Row>
          <ControlledRecaptcha methodsOfUseForm={methodsOfuseForm} />
          <PrimaryButtonLarge
            loading={loading}
            tabIndex={8}
            style={{ marginTop: "15px" }}
          >
            {t("Submit Support Request")}
          </PrimaryButtonLarge>
        </SupportCard>
      </Form>
      <Modal
        closeModal={closeConfirmationModal}
        show={showConfirmModal}
        modalWidth="700px"
        overlay
      >
        <ModalWrapper>
          <XLTitle>{t("Thank you for contacting us.")}</XLTitle>
          <div>
            {t(
              "We have received your support request. A member of our team will reach out to you soon to assist you with this issue."
            )}
          </div>
          <div style={{ marginBottom: "30px" }}>{t("Thank you.")}</div>
          <PrimaryButtonSmall onClick={closeConfirmationModal}>
            {t("Close")}
          </PrimaryButtonSmall>
        </ModalWrapper>
      </Modal>
    </Container>
  );
}
