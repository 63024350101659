import type {
  IGroupedPriceTierSingleTier,
  IPriceTierPatch,
  CurrencyCode,
  IPriceTierCreate,
} from "../../../types/types";
import type { AxiosResponse } from "axios";
import Axios from "axios";
import { twoDatesAreEqual } from "../../../util/util";
import type { PriceTierRowData } from "../../../components/PriceTiersBigTable/PriceTiersBigTable";

const makeEditedRequestBody = (
  rowData: IGroupedPriceTierSingleTier,
  validUntil: string
): IPriceTierPatch => {
  return {
    valid_to_date: validUntil,
    sku_id: rowData.product_sku.id,
    price_per_uom: parseFloat(rowData.price_per_uom),
    minimum_sku_quantity: rowData.minimum_sku_quantity,
  };
};

const makeUnchangedRequestBody = (validUntil: string): IPriceTierPatch => {
  return {
    valid_to_date: validUntil,
  };
};

const makeNewEditedRequestBody = ({
  rowData,
  seller_id,
  buyer_id,
  currencyCode,
  valid_to_date,
  destination_id,
  delivery_term_id,
  payment_term_id,
}: {
  rowData: IGroupedPriceTierSingleTier;
  seller_id: string;
  buyer_id: string;
  currencyCode: CurrencyCode;
  valid_to_date: string;
  destination_id: string;
  delivery_term_id: string;
  payment_term_id: string;
}): IPriceTierCreate => {
  return {
    price_tiers: [
      {
        seller_id,
        buyer_id,
        price_per_uom: rowData.price_per_uom,
        minimum_sku_quantity: rowData.minimum_sku_quantity,
        currency: currencyCode,
        valid_to_date,
        destination_id,
        product_sku_id: rowData.product_sku.id,
        delivery_term_id,
        payment_term_id,
      },
    ],
  };
};

export const submitPriceTierEdits = ({
  tableRowsData,
  valid_until,
  previousValidUntil,
  seller_id,
  buyer_id,
  currencyCode,
  destination_id,
  delivery_term_id,
  payment_term_id,
  storefront_id,
}: {
  tableRowsData: PriceTierRowData[];
  valid_until: string;
  previousValidUntil: string | null;
  seller_id: string;
  buyer_id: string;
  currencyCode: CurrencyCode;
  destination_id: string;
  delivery_term_id: string;
  payment_term_id: string;
  storefront_id: string;
}): Promise<AxiosResponse<any>>[] => {
  const promises = [];
  for (let rowData of tableRowsData) {
    switch (rowData.status) {
      case "deleted":
        promises.push(Axios.delete(`/v1/price-tiers/${rowData.data.tier_id}`));
        break;
      case "edited":
        promises.push(
          Axios.patch(
            `/v1/price-tiers/${rowData.data.tier_id}`,
            makeEditedRequestBody(rowData.data, valid_until)
          )
        );
        break;
      case "new-edited":
        // TODO: could submit these in one request.
        promises.push(
          Axios.post(
            `/v1/price-tiers`,
            makeNewEditedRequestBody({
              rowData: rowData.data,
              valid_to_date: valid_until,
              seller_id,
              buyer_id,
              currencyCode,
              destination_id,
              delivery_term_id,
              payment_term_id,
            })
          )
        );
        break;
      case "unchanged":
        if (
          previousValidUntil &&
          !twoDatesAreEqual(valid_until, previousValidUntil)
        ) {
          promises.push(
            Axios.patch(
              `/v1/price-tiers/${rowData.data.tier_id}`,
              makeUnchangedRequestBody(valid_until)
            )
          );
        }
        break;
      case "new":
        // There should be no "new" status items when the form is
        // submitted. If there are, ignore them.
        break;
    }
  }

  return promises;
};
