import { useEffect, useMemo, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import type { BuyerTableProduct } from "../../SharedPages/OrganizationPage/ProductsList/ProductsList.util";
import type { SortingRule } from "react-table";
import { defaultHandleSort, formatDate } from "../../../util/util";
import { useTranslation } from "react-i18next";
import type {
  PIMProductPaginatedOutput,
  ProductStatusType,
} from "../../../types/types.PIM";
import {
  StatusLeft,
  getProductStatusColor,
  getProductStatusText,
} from "../../../components/Status/Status";
import { DropdownContainer } from "../../../components/Layout/Layout";
import { DropDown } from "../../../components/DropDown/DropDown";
import { ContentWrapper } from "../../../layout/portalPageLayout";
import { Table } from "../../../components/Table/Table";
import { Pagination } from "../../../components/Pagination/Pagination";
import type { AxiosError } from "axios";
import { TotalProducts } from "../../SharedPages/OrganizationPage/ProductsList/ProductsList";

interface IProductsTableViewList {
  productsResponse?: PIMProductPaginatedOutput;
  productsError: AxiosError<any>;
  offset: number;
  setOffset: Dispatch<SetStateAction<number>>;
  perPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
  sortingRules: { sortBy?: string; orderBy: "asc" | "desc" };
  setSortingRules: Dispatch<
    SetStateAction<IProductsTableViewList["sortingRules"]>
  >;
  handleRowClick: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;
}

export const ProductsTableViewList = ({
  productsResponse,
  productsError,
  offset,
  setOffset,
  perPage,
  setPerPage,
  sortingRules,
  setSortingRules,
  handleRowClick,
}: IProductsTableViewList) => {
  const [tableData, setTableData] = useState<BuyerTableProduct[]>([]);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
    totalCount: 0,
  });

  const { t } = useTranslation();
  const perPageItems = [10, 20, 50];

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const isLoading = !productsResponse && !productsError;

  const tableColumns = useMemo(
    () => [
      {
        Header: t("Product ID"),
        accessor: "externalId",
        disableSortBy: true,
      },
      {
        Header: t("Product Name"),
        accessor: "name",
      },
      {
        Header: t("Chemical Name"),
        accessor: "chemical_name",
      },
      {
        Header: t("Last Modified"),
        accessor: "modified_at",
        Cell: ({ value }: { value: { date: string; id: string } }) => (
          <span>{value.date}</span>
        ),
      },
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,
        align: "left",
        Cell: ({ value: status }: { value: ProductStatusType }) => (
          <StatusLeft
            color={getProductStatusColor(status ?? "draft")}
            text={getProductStatusText(status ?? "draft", t)}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
        ),
      },
    ],
    [t]
  );

  useEffect(() => {
    const handleProductsData = ({
      data,
      pagination,
    }: PIMProductPaginatedOutput) => {
      setTableData(
        data.map(
          ({
            id,
            slug,
            name,
            last_modified_full: { modified_at },
            product_schema,
            status,
          }) => ({
            id: slug,
            externalId:
              (product_schema.groups
                ?.find(
                  ({ name, display_name }) =>
                    name === "General" || display_name === "General"
                )
                ?.attributes.find(
                  ({ name, display_name }) =>
                    name === "Product ID" || display_name === "Product ID"
                )?.values?.[0]?.value as string) ?? "--",
            name: name ?? "--",
            chemical_name:
              (product_schema.groups
                ?.find(
                  ({ name, display_name }) =>
                    name === "Identifiers" || display_name === "Identifiers"
                )
                ?.attributes.find(
                  ({ name, display_name }) =>
                    name === "Chemical Name" || display_name === "Chemical Name"
                )?.values?.[0]?.value as string) ?? "--",
            modified_at: {
              date: formatDate(modified_at),
              id,
            },
            status: status ?? "draft",
          })
        )
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
        totalCount: pagination.total,
      });
    };

    if (productsResponse) {
      const { data: products, pagination } = productsResponse;
      handleProductsData({ data: products, pagination });
    }
  }, [productsResponse, perPage]);

  return (
    <>
      <DropdownContainer>
        <DropDown
          items={perPageItems}
          activeItem={perPage}
          textLeft={t("items") + ":"}
          textRight={t("Per Page")}
          direction={"right"}
          className={"per_Page"}
          clickHandler={changePerPage}
        />
        <TotalProducts>
          {tablePagination.totalCount} {t("products")}
        </TotalProducts>
      </DropdownContainer>
      <ContentWrapper>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          error={productsError}
          rowClick={handleRowClick}
          handleSort={handleSort}
        />
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </>
  );
};
