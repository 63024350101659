import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Auth } from "../../components/Auth";
import {
  CancelButtonSmall,
  EditButton,
  PrimaryButtonMedium,
} from "../../components/Buttons/Buttons";
import { Row } from "../../components/Layout/Layout";
import { Notifications } from "../../components/Notifications/NotificationsContext";
import { ToggleSwitch } from "../../components/ToggleSwitch/ToggleSwitch";
import { H3 } from "../../components/Typography/Typography";
import { endpoints } from "../../endpoints";
import type { User } from "../../types/types";
import { useStoreState } from "../../util/util";

const ChatPrimaryButtonMedium = styled(PrimaryButtonMedium)`
  display: block;
  margin-top: 15px;
`;

export const ChatEmailNotification = ({
  user: { chat_email_notification_enabled, tenant_id, id },
}: {
  user: User;
}) => {
  const [edit, setEdit] = useState(false);
  const { storefront_id } = useStoreState();
  const { t } = useTranslation();
  const [notificationEnabled, setNotificationEnabled] = useState(
    chat_email_notification_enabled
  );
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { updateUser } = useContext(Auth);
  const onClick = async () => {
    setLoading(true);
    try {
      const { data: userData } = await Axios.patch<User>(
        endpoints.v1_storefronts_id_tenants_id_users_id(
          storefront_id,
          tenant_id,
          id
        ),
        { chat_email_notification_enabled: notificationEnabled }
      );
      updateUser(userData);
      notifySuccess(t("Chat email notifications enabled"));
    } catch (error) {
      notifyError(
        t("Could not update email notifications. Please try again later"),
        { error }
      );
    } finally {
      setLoading(false);
      setEdit(false);
    }
  };

  const cancelEdit = () => {
    setNotificationEnabled(chat_email_notification_enabled);
    setEdit(false);
  };

  useEffect(() => {
    setNotificationEnabled(chat_email_notification_enabled);
  }, [chat_email_notification_enabled]);

  return (
    <>
      <Row>
        <H3>{t("Chat Message Email Notifications")}</H3>
        {!edit && (
          <EditButton
            testid={"edit-search-filter"}
            onClick={() => setEdit(true)}
            title={t("Edit Portfolio Controls")}
          />
        )}
        {edit && <CancelButtonSmall onClick={cancelEdit} />}
      </Row>
      <ToggleSwitch
        onClick={() => setNotificationEnabled((prev) => !prev)}
        isChecked={notificationEnabled}
        disabled={!edit}
        label={t("Allow email notifications for new chat messages")}
      />
      {edit && (
        <ChatPrimaryButtonMedium
          loading={loading}
          onClick={onClick}
          type="submit"
        >
          {t("Save your changes")}
        </ChatPrimaryButtonMedium>
      )}
    </>
  );
};
