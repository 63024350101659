import styled from "styled-components";
import type {
  Assets,
  TdsGenerateAssetJobSchema,
} from "../../../../../types/types.PIM";
import { SecondaryButtonSmall } from "../../../../../components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import type { AxiosError } from "axios";
import axios from "axios";
import { endpoints } from "../../../../../endpoints";
import { useStoreState } from "../../../../../util/util";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";

const GenerateDocumentWrapper = styled.div`
  padding: 18px 25px 20px;
  margin: 10px 0 30px !important;
  border: ${({ theme }) => `1px solid ${theme.primaryBorder}`};
  border-radius: 4px;
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: 13px;
  background: ${({ theme }) => theme.primaryBG};
  min-height: 115px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

const Link = styled.a`
  color: #0090e3;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
`;

const TIMEOUT_INTERVAL = 2000;

export const GenerateDocument = ({
  asset: existingAsset,
  generate_id,
  product_id,
  onGenerate,
  update_poll_timeout_id,
}: {
  asset?: Assets;
  generate_id: string;
  product_id: string;
  onGenerate: (asset: Assets, updatedProductId?: string) => void;
  update_poll_timeout_id: (timeout_id: NodeJS.Timeout) => void;
}) => {
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { notifyError } = useNotifications();
  const [asset, setAsset] = useState<Assets | undefined>(existingAsset);
  const [isGenerating, setIsGenerating] = useState(false);

  const generate = async () => {
    setIsGenerating(true);
    try {
      await axios.post(
        endpoints.v2_tenants_tenant_id_products_product_id_generate_assets_id_async(
          tenant_id,
          product_id,
          generate_id
        ),
        null,
        {
          params: {
            return_invisible_asset: true,
            regenerate_product_asset: Boolean(existingAsset),
          },
        }
      );
      let timeout_id = setTimeout(() => {
        const polling = async () => {
          const { data } = await axios.get<TdsGenerateAssetJobSchema>(
            endpoints.v2_tenants_tenant_id_products_product_id_generate_assets_id_status(
              tenant_id,
              product_id,
              generate_id
            )
          );
          if (data.status === "finished") {
            setAsset(data.tds_asset);
            onGenerate(data.tds_asset!);
            clearTimeout(timeout_id);
            setIsGenerating(false);
          } else if (data.status === "failed") {
            clearTimeout(timeout_id);
            setIsGenerating(false);
            throw new Error(
              t("Could not generate asset. Please try again later.")
            );
          } else {
            timeout_id = setTimeout(polling, TIMEOUT_INTERVAL);
            update_poll_timeout_id(timeout_id);
          }
        };
        polling();
      }, TIMEOUT_INTERVAL);
      update_poll_timeout_id(timeout_id);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not generate asset. Please try again later."),
        {
          error,
        }
      );
      setIsGenerating(false);
    }
  };

  const generateText = asset
    ? t("Click to regenerate")
    : t("Click to generate");

  return (
    <GenerateDocumentWrapper>
      {asset && (
        <>
          <div>{t("Click to preview the file")}</div>
          <div>
            <Link
              href={asset.signed_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {asset.name}
            </Link>
          </div>
          <div>{t("Or")}</div>
        </>
      )}
      <SecondaryButtonSmall
        style={{ marginTop: "8px" }}
        type="button"
        loading={isGenerating}
        onClick={generate}
      >
        {generateText}
      </SecondaryButtonSmall>
    </GenerateDocumentWrapper>
  );
};
