import React from "react";
import { useStorageState } from "react-storage-hooks";
import { useStoreState } from "../../util/util";

export interface ICartContext {
  cartId: string | undefined;
  setCartId: (cartId: string) => string | undefined;
  setSampleCartID: (id: string) => string | undefined;
  sampleCartID: string | undefined;
  ulpId: string | undefined;
  setUlpId: (ulpId: string) => string | undefined;
  clearCartStorage: () => void;
}

export const CartContext = React.createContext<ICartContext>({
  cartId: undefined,
  setCartId: (_) => undefined,
  setSampleCartID: (_) => undefined,
  sampleCartID: undefined,
  ulpId: undefined,
  setUlpId: (_) => undefined,
  clearCartStorage: () => undefined,
});

export interface CartStorageEntry {
  cartId?: string;
  ulpId?: string;
  sampleCartID?: string;
}

/**
 * To support different carts for different storefronts, we persist
 * (to localStorage) an object that maps storefront slugs to objects that
 * contain a cart ID and an optional ULP ID.
 *
 * This context is used for the GUEST cart (not the BUYER cart).
 *
 * About ULP IDs:
 * We store a "ULP ID" (a transaction ID) when a product was added to the cart
 * via the ULP connector. Then this ID is submitted with the cart. We get the
 * ID from a param in the URL on the product detail page.
 */
export const CartContextProvider = (props: { children: JSX.Element }) => {
  const { slug } = useStoreState();

  const [cartStorage, setCartStorage, cartStorageWriteError] = useStorageState<
    Record<string, CartStorageEntry | undefined>
  >(
    localStorage,
    "CART_STORAGE",
    slug
      ? {
          [slug]: {
            cartId: "",
            ulpId: "",
            sampleCartID: "",
          },
        }
      : {}
  );

  const setCartId = (cartId: string) => {
    if (slug) {
      const oldStorageEntry = cartStorage[slug];
      const newStorageEntry = { ...oldStorageEntry, cartId };

      setCartStorage({ ...cartStorage, [slug]: newStorageEntry });
    }
    return getCartId();
  };

  const setSampleCartID = (id: string) => {
    if (slug) {
      const oldStorageEntry = cartStorage[slug];
      const newStorageEntry = { ...oldStorageEntry, sampleCartID: id };

      setCartStorage({ ...cartStorage, [slug]: newStorageEntry });
    }
    return getCartId();
  };

  const setUlpId = (ulpId: string) => {
    if (slug) {
      const oldStorageEntry = cartStorage[slug];

      // For TypeScript's benefit. There should already be a cartId set when
      // the ulpId is set, so a cartId of "" should never occur.
      const newStorageEntry = oldStorageEntry
        ? { ...oldStorageEntry, ulpId }
        : { cartId: "", ulpId };

      setCartStorage({ ...cartStorage, [slug]: newStorageEntry });
    }

    return getCartId();
  };

  const getCartId = (): string | undefined => {
    if (slug) {
      return cartStorage[slug]?.cartId;
    }
  };

  const getUlpId = (): string | undefined => {
    if (slug) {
      return cartStorage[slug]?.ulpId;
    }
  };

  const getSampleCartID = (): string | undefined => {
    if (slug) {
      return cartStorage[slug]?.sampleCartID;
    }
  };

  /**
   * Clears cart storage for a given storefront, including cart ID and ULP ID.
   */
  const clearCartStorage = () => {
    if (slug) {
      const newStorage = { ...cartStorage };
      delete newStorage[slug];
      setCartStorage(newStorage);
    }
  };

  if (cartStorageWriteError) {
    console.error(
      "Error writing cart information to localStorage (cart ID or ULP ID)",
      cartStorageWriteError
    );
  }

  return (
    <CartContext.Provider
      value={{
        cartId: getCartId(),
        setCartId,
        ulpId: getUlpId(),
        sampleCartID: getSampleCartID(),
        setUlpId,
        setSampleCartID,
        clearCartStorage,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};
