import type { TFunction } from "react-i18next";
import type { StatusColor } from "../../../components/Status/Status";
import type {
  DeprecatedLeadStatus,
  Lead,
  LeadStatus,
  SampleRequestStatusUnion,
} from "../../../types/types";
import type { FiltersDropDownItem } from "../../../components/FiltersDropDown/FiltersDropDown";

type StatusDetails = {
  text: string;
  color: StatusColor;
};

/**
 * Returns UI details for a given lead status, for seller admins.
 */
export const getLeadStatusDetails = (
  status: LeadStatus,
  t: TFunction
): StatusDetails => {
  switch (status) {
    case "New":
      return { text: t("New"), color: "blue" };
    case "Forwarded":
      return { text: t("Forwarded"), color: "fullBlue" };
    case "Added as User":
      return { text: t("Added as User"), color: "fullBlue" };
    case "Converted":
      return { text: t("Converted"), color: "green" };
    case "Accepted":
      return { text: t("Accepted"), color: "green" };
    case "Rejected":
      return { text: t("Rejected"), color: "red" };
    case "Lead Declined":
      return { text: t("Declined"), color: "red" };
    default:
      return { text: status, color: "gray" };
  }
};

export type StatusCellProps = {
  color: StatusColor;
  lead: Lead;
  text: string;
};

export const LeadStatusFilters = (
  t: (s: string) => string
): FiltersDropDownItem<Exclude<LeadStatus, DeprecatedLeadStatus>>[] => [
  { color: "blue", text: t("New"), value: "New" },
  { color: "fullBlue", text: t("Added as User"), value: "Added as User" },
  { color: "green", text: t("Accepted"), value: "Accepted" },
  { color: "red", text: t("Rejected"), value: "Rejected" },
];

export const SampleRequestStatusFilters = (
  t: (s: string) => string
): FiltersDropDownItem<SampleRequestStatusUnion>[] => [
  { color: "blue", text: t("New"), value: "requested" },
  { color: "fullBlue", text: t("Added as User"), value: "Added as User" },
  { color: "green", text: t("Accepted"), value: "accepted" },
  { color: "red", text: t("Rejected"), value: "rejected" },
];
