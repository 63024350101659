import type { FunctionComponent } from "react";
import React from "react";
import { WizardPath } from "../WizardPath/WizardPath";
import type {
  OrderStatus,
  OrderStatusLabel,
  QuoteStatus,
  SampleRequestStatus,
  SampleStatusLabel,
} from "../../types/types";

const orderStatusLabels: OrderStatusLabel[] = [
  "Placed",
  "Accepted",
  "In Progress",
  "Invoiced/Payment Received",
  "Shipped",
  "Completed",
];

const sampleStatusLabels: SampleStatusLabel[] = [
  "New",
  "Accepted",
  "In Progress",
  "Shipped",
  "Completed",
];

export const isPartManualSampleStatusChanges = (
  status: SampleRequestStatus
): boolean => {
  const manualAcceptedsampleStatusChanges: SampleRequestStatus[] = [
    "accepted",
    "in_progress",
    "shipped",
    "completed",
  ];
  return manualAcceptedsampleStatusChanges.includes(status);
};

const sampleMap = new Map([
  ["new", 0],
  ["accepted", 1],
  ["in_progress", 2],
  ["shipped", 3],
  ["completed", 4],
]);

export const getOrderStatusItemsForDropDown = (status: OrderStatus) => {
  if (status === "payment_received") {
    return ["Completed"];
  } else return orderStatusLabels.filter((label) => label !== "Placed");
};

const quoteStatusLabels = ["Placed", "Requested", "Responded", "Accepted"];

const orderMap = new Map([
  ["new", 0],
  ["accepted", 1],
  ["in_progress", 2],
  ["invoiced", 3],
  ["payment_received", 3],
  ["shipped", 4],
  ["completed", 5],
]);

const quoteMap = new Map([
  ["new", 0],
  ["requested", 1],
  ["responded", 2],
  ["accepted", 3],
]);
interface TransactionStatusPathProps {
  transactionStatus: OrderStatus | QuoteStatus | SampleRequestStatus;
  view:
    | "sellerOrder"
    | "sellerQuote"
    | "buyerOrder"
    | "buyerQuote"
    | "sellerSample"
    | "buyerSample";
}

const getTransactionStatusPathPoint = ({
  transactionStatus,
  view,
}: TransactionStatusPathProps): number | undefined => {
  switch (view) {
    case "sellerOrder":
      return orderMap.get(transactionStatus);
    case "sellerQuote":
      return quoteMap.get(transactionStatus);
    case "buyerOrder":
      return orderMap.get(transactionStatus);
    case "buyerQuote":
      return quoteMap.get(transactionStatus);
    case "sellerSample":
      return sampleMap.get(transactionStatus);
    case "buyerSample":
      return sampleMap.get(transactionStatus);
    default:
      return 0;
  }
};

const getViewStatusLabel = (
  view: string
): SampleStatusLabel[] | OrderStatusLabel[] | string[] => {
  switch (view) {
    case "sellerQuote":
      return quoteStatusLabels;
    case "buyerQuote":
      return quoteStatusLabels;
    case "sellerSample":
      return sampleStatusLabels;
    default:
      return orderStatusLabels;
  }
};

export const TransactionStatusPath: FunctionComponent<TransactionStatusPathProps> =
  ({ transactionStatus, view }) => {
    const currentPoint =
      getTransactionStatusPathPoint({ transactionStatus, view }) || 0;

    const labels = getViewStatusLabel(view);

    return <WizardPath labels={labels} currentPoint={currentPoint} />;
  };
