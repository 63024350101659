import Axios from "axios";
import { useContext } from "react";
import { mutate } from "swr";
import type { IHomePageData } from "./HomePage";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { useStoreState } from "../../../util/util";

/**
 * A React hook that returns a function for saving changes to home page data.
 */
export const useSaveHomePage = () => {
  const { notifyError } = useContext(Notifications);
  const { slug } = useStoreState();

  /**
   * Persists home page data to the backend. Returns true on success, false on
   * error.
   */
  const saveHomePage = async (
    homePageData: IHomePageData
  ): Promise<boolean> => {
    try {
      const endpoint = `/v1/storefronts/${slug}/homepage`;
      await Axios.put(endpoint, homePageData);

      mutate(endpoint);
      return true;
    } catch (error) {
      notifyError("There was an error saving the changes", { error });
      return false;
    }
  };

  return { saveHomePage };
};
