import {
  EditButton,
  CancelButtonSmall,
} from "../../../../../../components/Buttons/Buttons";
import { InfoBlockSmall } from "../../../../../../components/InfoBlocks/InfoBlocks";
import { Row } from "../../../../../../components/Layout/Layout";
import { H3 } from "../../../../../../components/Typography/Typography";
import { SettingsCard } from "../../../../../../layout/portalPageLayout";
import React, { useState } from "react";
import { useStoreState } from "../../../../../../util/util";
import { EditAnalyticsSettings } from "./EditAnalyticsSettings";
import { useTranslation } from "react-i18next";

export const AnalyticsSettings = () => {
  const { storefront_metadata } = useStoreState();
  const { google_analytics_key } = storefront_metadata;

  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();

  return (
    <SettingsCard>
      <Row>
        <H3>Google Analytics Integration</H3>
        {!isEditing && (
          <EditButton
            testid={"edit-google-analytics-integration"}
            onClick={() => setIsEditing(true)}
            title={t("Edit Google Analytics Integration")}
          />
        )}
        {isEditing && <CancelButtonSmall onClick={() => setIsEditing(false)} />}
      </Row>
      {!isEditing && (
        <InfoBlockSmall
          header={t("Google Analytics Key")}
          content={google_analytics_key || "--"}
        />
      )}
      {isEditing && (
        <EditAnalyticsSettings
          googleAnalyticsKey={google_analytics_key}
          setIsEditingFalse={() => setIsEditing(false)}
        />
      )}
    </SettingsCard>
  );
};
