import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { SoftHeader } from "../../../components/Typography/Typography";
import { TextButton } from "../../../components/Buttons/Buttons";
import { useState } from "react";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { UnlistedProductForm } from "./UnlistedProductFrom";

const UnlistedProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  align-items: flex-start;
  margin-left: 15px;
  margin-top: 5px;
  button {
    padding-left: 0px;
  }
`;

export const RequestUnlistedProduct = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showSlideOut, setShowSlideOut] = useState(false);

  return (
    <UnlistedProductContainer>
      <SoftHeader>
        {t("Can't find the product you are looking for?")}
      </SoftHeader>
      <TextButton
        onClick={() => setShowSlideOut(true)}
        style={{ color: theme.brandColor }}
      >
        {t("Request unlisted product")}
      </TextButton>
      <SlideOut closeFlyout={() => setShowSlideOut(false)} show={showSlideOut}>
        <UnlistedProductForm handleCloseFlyout={() => setShowSlideOut(false)} />
      </SlideOut>
    </UnlistedProductContainer>
  );
};
