import React from "react";
import styled from "styled-components/macro";

const PolicyContainer = styled.div`
  font-family: "Inter";
  ol {
    li {
      margin-bottom: 15px;
    }
  }
  margin-bottom: 20px;
`;

export function PrivacyPolicy() {
  return (
    <div style={{ paddingBottom: "20px" }}>
      <div>
        <h2 style={{ fontFamily: "Inter" }}>Privacy &amp; Data Security</h2>
      </div>
      <PolicyContainer>
        <p>
          Agilis Chemicals Inc., a Delaware limited liability company,
          (“AGILIS”) operates https://www.agilischemicals.com (the “Site”). This
          document informs the user or customer (“Customer”) of our policies
          regarding the collection, use and disclosure of data AGILIS receives
          from users of the Site.
        </p>
        <ol>
          <li>
            Data Protection. AGILIS respects your privacy. Information may be
            obtained automatically through the use of your web browser or
            through the membership request form. AGILIS may not use Information
            for any purpose other than the performance of its obligations on the
            Web Site. Information shared for general purposes and initial
            membership consultation will be used solely for the purpose of doing
            business with AGILIS. Information will not be shared with any of
            AGILS affiliates, partners or third parties.
          </li>
          <li>
            Data Security. AGILIS will take commercially reasonable steps to
            prevent unauthorized access to the Site, the content, information
            stored on its computers as a result of user interactions with the
            Site (the “Content”). This will be done by Firewall monitoring,
            maintenance, and upgrades when necessary; as well as keeping the
            AGILIS’s server software up to date with the latest security
            patches.
          </li>
          <li>
            Site Content. The Content is the property of AGILIS, and the
            Customer has no Intellectual Property Rights or other ownership
            rights in the Content. For purposes of this agreement, “Intellectual
            Property Rights” include any and all intellectual property and
            proprietary rights in the Content, including without limitation
            patent, copyright, trademark, service mark, trade secret, moral, and
            contract rights in any country or jurisdiction in the world and all
            registrations, initial applications, renewals, extensions,
            continuations, divisions, or reissues of them now or hereafter in
            force.
          </li>
          <li>
            Warranties Regarding Content. The Customer represents and warrants
            that it has sufficient rights to the information that it provides to
            AGILIS. The Customer also represents and warrants that it will not
            put any information on the SITE that is defamatory or obscene or
            that violates any other applicable law. AGILIS has the right to
            delete any Content from the Site that the AGILIS believes in good
            faith breaches any of the Customer’s warranties regarding the
            Content set forth in this agreement.
          </li>
          <li>
            Use of Web site. The Customer may not use, or permit others to use,
            the Site: (a) for any unlawful, invasive, infringing, defamatory,
            fraudulent, or obscene purpose; (b) to send spam or other types of
            unsolicited email of any kind; (c) to send any virus, worm, Trojan
            horse, or harmful code or attachment; or (d) to alter, steal,
            corrupt, disable, destroy, trespass, or violate any security or
            encryption of any computer file, database, or network. AGILIS may
            terminate this agreement immediately if the Customer breaches these
            obligations.
          </li>
          <li>
            AGILIS’s Property. All technology, tools, and know-how owned by,
            leased to or licensed to AGILIS used in the publication of the Site,
            including any technology, tools, and know-how developed specifically
            for AGILIS are the sole property of the AGILIS, and the Customer has
            no Intellectual Property Rights or other ownership rights in these
            items.
          </li>
          <li>
            Disclaimer. Except as provided in this agreement, each party
            disclaims all representations or warranties of any kind, express or
            implied, including without limitation the warranties of
            merchantability, fitness for a particular purpose, title, and
            non-infringement.
          </li>
          <li>
            Liability Limitation. AGILIS will not be liable for any
            consequential, incidental, special, or exemplary damages arising
            from breach of this agreement or a representation or warranty made
            under this agreement. Neither party will be liable for interrupted
            communications, lost or corrupted data, or lost profits, arising out
            of or in connection with this agreement.
          </li>
        </ol>
      </PolicyContainer>
    </div>
  );
}
