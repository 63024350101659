import React, { useCallback, useEffect, useState } from "react";
import type { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { InvisibleButton } from "../Buttons/Buttons";
import type { ChipType } from "../Chips/Chips";
import { getFilterItemName, useStoreState } from "../../util/util";
import { CheckBoxNoLabel } from "../CheckBoxes/CheckBoxes";
import { SoftHeader2 } from "../Typography/Typography";
import { XIcon } from "../Icons/Icons";
import { SearchBar } from "../SearchBar/SearchBar";
import type {
  Filters,
  SupportedLanguage,
  TagClassificationConfig,
} from "../../types/types";
import { Loader } from "../Loader/Loader";
import { LoaderContainer } from "../../pages/public/pages.public.util";
import { useLocation } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import { useCookies } from "react-cookie";

interface AnimatedExpandToggleProps {
  expanded: boolean;
}

const FilterGroupWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
  margin: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  button {
    cursor: pointer;
  }
  transition: max-height 0.25s ease-in;
`;

const FilterGroupHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 2px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const FilterGroupContent = styled.div`
  padding: 0 0 0 10px;
`;

const SearchBarWrapper = styled.div`
  padding: 0 10px 0 0;
  margin-bottom: 10px;
  input {
    border-radius: 4px;
    padding: 10px 13px 10px 35px;
    background: ${({ theme }) => theme.primaryBG};
  }
  svg {
    left: 7px;
    path {
      fill: #000;
    }
  }
`;

const NoSearchResults = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;
`;

const ClearFiltersWrapper = styled.div`
  padding: 0px 0px 0px 6px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  border-radius: 3px;
  height: 23px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: ${({ theme }) => theme.fontSizes.small};
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  button {
    display: flex;
    align-items: center;
  }
`;

const AnimatedExpandToggle = styled.div<AnimatedExpandToggleProps>`
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    transform: scale(1.2);
  }
  &:before,
  &:after {
    content: "";
    display: block;
    background-color: #333;
    position: absolute;
    top: 12px;
    left: 6px;
    transition: 0.35s;
    width: 12px;
    height: 2px;
  }
  &:after {
    width: 13px;
  }
  &:before {
    transform: ${({ expanded }) =>
      expanded ? "translatey(-50%)" : "translatey(-50%) rotate(-90deg)"};
    opacity: ${({ expanded }) => (expanded ? "0" : "1")};
    top: ${({ expanded }) => (expanded ? "12px" : "13px")};
    width: ${({ expanded }) => (expanded ? "12px" : "11px")};
    left: ${({ expanded }) => (expanded ? "6px" : "7px")};

    &:after {
      transform: ${({ expanded }) =>
        expanded
          ? "translatey(-50%) rotate(90deg)"
          : "translatey(-50%) rotate(0)"};
    }
  }
`;

const ScrollUL = styled.div`
  max-height: 350px;
  overflow-y: auto;
  display: block;
  padding: 5px 5px 5px 0px;
  transition: max-height 0.3s ease-in;
  li {
    list-style: none;
    padding: 3px 5px 5px 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.3s ease-in;
  }
`;

interface IFilterBy {
  setter: ({ values, filter }: { values?: ChipType[]; filter: string }) => void;
  options?: ChipType[];
  filters?: Filters;
  filterType: string;
  customLabels: TagClassificationConfig[];
  preselectedFilters: ChipType[];
  expanded: boolean;
}

/**
 * Reads in filters from the URL if they exist and applies them the list.
 * selectedFilters will update when a user clicks on the checkbox. When that array changes the request will run.
 */
export const FilterGroup = ({
  setter,
  options,
  filterType,
  preselectedFilters,
  customLabels,
  filters,
  expanded,
}: IFilterBy) => {
  const [selectedFilters, setSelectedFilters] = useState<ChipType[]>(
    preselectedFilters || []
  );
  const [appliedFilters, setAppliedFilters] = useState<ChipType[]>(
    preselectedFilters || []
  );
  const [query, setQuery] = useQueryParams({
    active_tag_view: StringParam,
  });

  const [showList, setShowList] = useState(
    expanded || query.active_tag_view === filterType
  );

  useEffect(() => {
    setShowList(
      expanded || query.active_tag_view === filterType ? true : false
    );
  }, [query.active_tag_view, filterType, expanded]);

  const { t } = useTranslation();
  const location = useLocation();
  const handleFilterClick = (option: ChipType, isChecked: boolean) => {
    if (isChecked) {
      setSelectedFilters((prev) => [...prev, { name: option.name }]);
      setAppliedFilters((prev) => [...prev, { name: option.name }]);
      setter({
        values: [...selectedFilters, { name: option.name }],
        filter: filterType,
      });
    } else {
      setSelectedFilters((prev) =>
        prev.filter((item) => item.name !== option.name)
      );
      setAppliedFilters((prev) =>
        prev.filter((item) => item.name !== option.name)
      );
      setter({
        values:
          selectedFilters.filter((item) => item.name !== option.name).length > 0
            ? selectedFilters.filter((item) => item.name !== option.name)
            : undefined,
        filter: filterType,
      });
    }
  };

  const clear = useCallback(() => {
    // useCallback is needed here because this a dep of the
    // below useEffect
    setSelectedFilters([]);
    setAppliedFilters([]);
    setter({ values: undefined, filter: filterType });
  }, [setter, filterType]);

  const { slug: tenantSlug } = useStoreState();

  const [cookies] = useCookies([`preferred-language-${tenantSlug}`]);
  const preferredLanguage: SupportedLanguage | undefined = cookies[
    `preferred-language-${tenantSlug}`
  ] as SupportedLanguage;

  const [searchQuery, setSearchQuery] = useState("");
  const handleChange = (val: ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(val.target.value);

  const [label, setLabel] = useState<string>("");
  useEffect(() => {
    const getCustomLabel = (filterType: string) => {
      const customLabel = customLabels
        ?.find((customLabel) => customLabel.filter_type === filterType)
        ?.filter_labels?.find(
          (filterLabel) => filterLabel.language === (preferredLanguage ?? "en")
        )?.label;
      return customLabel ? customLabel : getFilterItemName(filterType);
    };
    setLabel(getCustomLabel(filterType).toLowerCase());
  }, [filterType, customLabels, preferredLanguage]);

  const handleClearInput = () => setSearchQuery("");

  const getFilterName = (filter: ChipType) => {
    if (
      searchQuery.length > 0 &&
      filter.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    ) {
      const start = filter.name
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase());
      const end =
        filter.name.toLowerCase().indexOf(searchQuery.toLowerCase()) +
        searchQuery.length;
      return (
        <>
          {filter.name.substring(0, start)}
          <b>{filter.name.substring(start, end)}</b>
          {filter.name.substring(end, filter.name.length)}
        </>
      );
    }
    return filter.name;
  };

  useEffect(() => {
    if (location) {
      setSelectedFilters(preselectedFilters);
      setAppliedFilters(preselectedFilters);
    }
  }, [preselectedFilters, location]);
  return (
    <FilterGroupWrapper>
      <FilterGroupHeader
      // active={appliedFilters.length > 0 || showList}
      >
        <div
          onClick={() => {
            setShowList(!showList);
            if (query.active_tag_view !== filterType && !expanded) {
              setQuery({ active_tag_view: filterType });
            }
          }}
          style={{ width: "100%", padding: "8px 2px 8px 8px" }}
        >
          {label.toLowerCase().charAt(0).toUpperCase()}
          {label.toLowerCase().slice(1)}
        </div>
        <div style={{ display: "flex" }}>
          {selectedFilters.length > 0 && (
            <ClearFiltersWrapper>
              <div> {selectedFilters.length}</div>
              <InvisibleButton
                onClick={(e) => {
                  e.preventDefault();
                  clear();
                }}
              >
                <XIcon width={15} height={15} />
              </InvisibleButton>
            </ClearFiltersWrapper>
          )}
          {
            <AnimatedExpandToggle
              style={{ padding: "8px 8px 8px 2px" }}
              expanded={showList}
              onClick={() => {
                setShowList(!showList);
              }}
            />
          }
        </div>
      </FilterGroupHeader>
      {showList && (
        <FilterGroupContent>
          <SearchBarWrapper>
            <SearchBar
              placeHolder={`Search ${label
                .charAt(0)
                .toUpperCase()}${label.slice(1)}`}
              query={searchQuery}
              handleChange={handleChange}
              handleClearInput={handleClearInput}
            />
          </SearchBarWrapper>
          {!filters && (
            <LoaderContainer style={{ marginTop: "30px" }}>
              <Loader isLoading={true} width={22} />
            </LoaderContainer>
          )}

          {filters && options && (
            <ScrollUL>
              {options
                .filter((item) =>
                  item.name.toLowerCase().includes(searchQuery?.toLowerCase())
                )
                .map((option, index) => (
                  <li key={index}>
                    <CheckBoxNoLabel
                      name={`is_filter_checked${index}`}
                      id={`is_filter_checked${index}`}
                      checked={
                        appliedFilters.findIndex(
                          (i) => i.name === option.name
                        ) > -1
                      }
                      onChange={(e) =>
                        handleFilterClick(option, e.currentTarget.checked)
                      }
                    />
                    <SoftHeader2
                      onClick={() =>
                        handleFilterClick(
                          option,
                          appliedFilters.some((i) =>
                            i.name.includes(option.name)
                          )
                            ? false
                            : true
                        )
                      }
                    >
                      {getFilterName(option)} (
                      {(option as { name: string; count: number }).count})
                    </SoftHeader2>
                  </li>
                ))}
              {options.filter((item) =>
                item.name.toLowerCase().includes(searchQuery?.toLowerCase())
              ).length === 0 && (
                <NoSearchResults>
                  <SoftHeader2>{t("No results found")}</SoftHeader2>
                </NoSearchResults>
              )}
            </ScrollUL>
          )}
        </FilterGroupContent>
      )}
    </FilterGroupWrapper>
  );
};
