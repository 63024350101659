import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const UnstyledLinkWrapper = styled.div`
  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.brandColor};
  }
`;

interface UnstyledLinkProps {
  to: string;
  children: JSX.Element;
}

export function LinkUnstyled({ to, children }: UnstyledLinkProps) {
  return (
    <UnstyledLinkWrapper>
      <Link to={to}>{children}</Link>
    </UnstyledLinkWrapper>
  );
}
