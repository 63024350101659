import React from "react";
import styled from "styled-components/macro";
import { PrimaryButtonMedium, PrimaryButtonLarge } from "../Buttons/Buttons";
import { Title } from "../Typography/Typography";
import { screenSize } from "../../theme";

export interface IHeroSectionProps {
  data: IHeroData;
  clickAction: () => void;
}

type HAlign = "left" | "center" | "right";

export interface IHeroData {
  imageUrl?: string;
  title?: string;
  caption?: string;
  actionButtonText?: string;
  actionButtonSize?: "medium" | "large";
  hideActionButton?: boolean;
  height?: string;
  titleColor?: string;
  captionColor?: string;
  textAlign?: HAlign;
  contentAlign?: HAlign;
  editMode?: boolean;
}

interface IHeroImageProps {
  imageUrl?: string;
  contentAlign?: HAlign;
  editMode?: boolean;
  height?: string;
}

interface IHeroContentProps {
  contentAlign?: HAlign;
}
interface IHeroTextProps {
  titleColor?: string;
  captionColor?: string;
  textAlign?: HAlign;
}

const HeroImageContainer = styled.div<IHeroImageProps>`
  background: ${({ imageUrl, theme }) =>
    imageUrl
      ? `url(${imageUrl})`
      : `linear-gradient(to bottom, ${theme.secondaryButtonBG}, ${theme.secondaryBG})`};
  height: ${({ height }) => (height ? `${height}px` : "450px")};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-out;
  z-index: ${({ editMode }) => (editMode ? 900 : 0)};
  margin-right: ${({ editMode }) => (editMode ? "500px" : 0)};
  @media ${screenSize.small} {
    align-items: flex-end;
    padding-bottom: 20px;
  }
`;

const HeroText = styled.div<IHeroTextProps>`
  text-align: ${({ textAlign }) => textAlign || "left"};
  padding: 5px 20px 5px;
  h1 {
    font-size: ${({ theme }) => theme.fontSizes["xl-4"]};
    color: ${({ titleColor }) => titleColor};
    margin: 5px 0 10px;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
  p {
    font-size: ${({ theme }) => theme.fontSizes["xl-4"]};
    color: ${({ captionColor }) => captionColor};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    margin: 0 0 20px;
  }
  @media ${screenSize.medium} {
    h1,
    p {
      font-size: ${({ theme }) => theme.fontSizes["xl-2"]};
    }
  }
  @media ${screenSize.small} {
    h1,
    p {
      font-size: ${({ theme }) => theme.fontSizes.xl};
    }
  }
`;

const ContentWrapper = styled.div<IHeroContentProps>`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: ${({ contentAlign }) =>
    contentAlign === "center"
      ? "center"
      : contentAlign === "right"
      ? "flex-end"
      : "flex-start"};
`;

export const HeroSection = ({ data, clickAction }: IHeroSectionProps) => {
  const {
    title,
    caption,
    imageUrl,
    titleColor,
    height,
    captionColor,
    contentAlign,
    textAlign,
    actionButtonText,
    hideActionButton,
    actionButtonSize,
    editMode = false,
  } = data;

  return (
    <HeroImageContainer imageUrl={imageUrl} editMode={editMode} height={height}>
      <ContentWrapper contentAlign={contentAlign}>
        <HeroText
          titleColor={titleColor}
          captionColor={captionColor}
          textAlign={textAlign}
        >
          <Title>{title}</Title>
          <p>{caption}</p>
          {!hideActionButton &&
            actionButtonText &&
            (actionButtonSize && actionButtonSize === "medium" ? (
              <PrimaryButtonMedium onClick={clickAction}>
                {actionButtonText}
              </PrimaryButtonMedium>
            ) : (
              <PrimaryButtonLarge onClick={clickAction}>
                {actionButtonText}
              </PrimaryButtonLarge>
            ))}
        </HeroText>
      </ContentWrapper>
    </HeroImageContainer>
  );
};
