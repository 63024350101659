import React from "react";
import styled from "styled-components/macro";
import { screenSize } from "../../theme";
import { XLTitleRegularWeight } from "../Typography/Typography";

interface IImageGrid {
  data?: IImageGridData[];
  numberPerRow?: number;
  maxImageWidth?: number;
  maxImageHeight?: number;
}

export interface IImageGridData {
  imageSrc: string;
  targetUrl?: string;
  targetBlank?: boolean;
  alt?: string;
}

interface ImageContainerProps {
  numberPerRow?: number;
  maxImageWidth?: number;
  maxImageHeight?: number;
}

const ImageGridContainer = styled.div<ImageContainerProps>`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: ${({ numberPerRow }) =>
    numberPerRow
      ? `repeat(auto-fit, minmax(100px, ${Math.floor(numberPerRow)}%))`
      : `repeat(auto-fit, minmax(100px, 22%))`};
  justify-content: center;
  @media ${screenSize.medium} {
    grid-template-columns: ${() => `repeat(auto-fit, minmax(100px, 30%))`};
    grid-gap: 5%;
  }
  @media ${screenSize.small} {
    grid-template-columns: ${() => `repeat(auto-fit, minmax(100px, 45%))`};
    grid-gap: 10%;
  }
`;

const Image = styled.div<ImageContainerProps>`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  box-sizing: border-box;
  align-items: center;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
  img {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 337/300;
    max-width: ${({ maxImageWidth }) =>
      maxImageWidth ? `${maxImageWidth}px` : `100%`};
    max-height: ${({ maxImageHeight }) =>
      maxImageHeight ? `${maxImageHeight}px` : `100%`};
  }
`;

export const ImageGrid = ({
  data,
  numberPerRow = 4,
  maxImageWidth,
  maxImageHeight,
}: IImageGrid) => {
  return (
    <ImageGridContainer numberPerRow={100 / numberPerRow - numberPerRow}>
      {data &&
        data.map((image, index) => (
          <Image
            key={index}
            maxImageWidth={maxImageWidth}
            maxImageHeight={maxImageHeight}
          >
            {image.targetUrl ? (
              <a
                href={image.targetUrl}
                target={image.targetBlank ? "_blank" : "self"}
                rel="noreferrer"
              >
                <img src={image.imageSrc} alt={image.alt ? image.alt : ""} />
                {image.alt && (
                  <XLTitleRegularWeight>{image.alt}</XLTitleRegularWeight>
                )}
              </a>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={image.imageSrc} alt={image.alt ? image.alt : ""} />
                {image.alt && (
                  <XLTitleRegularWeight>{image.alt}</XLTitleRegularWeight>
                )}
              </div>
            )}
          </Image>
        ))}
    </ImageGridContainer>
  );
};
