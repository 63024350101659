import React from "react";
import type { IOrganizationPageTab } from "../../../SharedPages/OrganizationPage/OrganizationPage";
import { OrganizationPage } from "../../../SharedPages/OrganizationPage/OrganizationPage";
import { providePrivatePageProps } from "../../../../util/Routing";
import { SettingsIcon, UserIcon } from "../../../../components/Icons/Icons";
import { CompanyInfo } from "../../../SharedPages/OrganizationPage/CompanyInfo/CompanyInfo";
import { ManageUsersList } from "../../../SharedPages/OrganizationPage/UsersList/UsersList";
import { useAuthContext } from "../../../../components/Auth";

/**
 * `providePrivatePageProps` is used in part because this is the page you hit
 * after a seller admin logs in, so we want to be sure it works fine for that
 * case.
 */
export const SellerAdminOrganization = providePrivatePageProps(({ user }) => {
  const { roleIsSellerAdmin } = useAuthContext();

  const tabs: IOrganizationPageTab[] = React.useMemo(() => {
    // Only seller admin has "HR rights" to CRUD users
    if (roleIsSellerAdmin) {
      return [
        {
          label: "Company Info",
          icon: SettingsIcon,
          component: <CompanyInfo user={user} />,
        },
        {
          label: "Users",
          icon: UserIcon,
          component: <ManageUsersList user={user} />,
        },
      ];
    } else {
      return [
        {
          label: "Company Info",
          icon: SettingsIcon,
          component: <CompanyInfo user={user} />,
        },
      ];
    }
  }, [user, roleIsSellerAdmin]);

  return <OrganizationPage tabs={tabs} />;
});
