import { PrimaryButtonMedium } from "../../../../../../components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import type { Dispatch, SetStateAction } from "react";
import { useState, useMemo, useEffect, useCallback } from "react";
import type {
  SupportedAssetCategoryType,
  UploadedAssetSchema,
} from "../../../../../../types/types.PIM";
import { IDMobile } from "../../../../../../components/IDs/IDs";
import {
  defaultHandleSort,
  formatDateTime,
  toTitleCase,
  useStoreState,
} from "../../../../../../util/util";
import type {
  SupportedLanguage,
  WithPagination,
} from "../../../../../../types/types";
import useSWR from "swr";
import { endpoints } from "../../../../../../endpoints";
import type { Row, SortingRule } from "react-table";
import { H3 } from "../../../../../../components/Typography/Typography";
import { DropdownContainer } from "../../../../../../components/Layout/Layout";
import { DropDown } from "../../../../../../components/DropDown/DropDown";
import { ContentWrapper } from "../../../../../../layout/portalPageLayout";
import { Table } from "../../../../../../components/Table/Table";
import { Pagination } from "../../../../../../components/Pagination/Pagination";

const AssetsListContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

export const UploadedAssetsList = ({
  setStage,
  onSelect,
  isCheckBoxRequired = false,
}: {
  setStage: Dispatch<SetStateAction<"list" | "add_files">>;
  onSelect?: (selectedAssets: UploadedAssetSchema[]) => void;
  isCheckBoxRequired?: boolean;
}) => {
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();

  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "desc",
  });
  const [tableData, setTableData] = useState<UploadedAssetSchema[]>([]);
  const [offset, setOffset] = useState(0);
  const perPageItems = [10, 20, 50];
  const [perPage, setPerPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });

  const tableColumns = useMemo(
    () => [
      {
        Header: t("Upload ID"),
        accessor: "number",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => <IDMobile>{value}</IDMobile>,
      },
      {
        Header: t("Asset Type"),
        accessor: "asset_type",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <span>{toTitleCase(value)}</span>
        ),
      },
      {
        Header: t("Category"),
        accessor: "category",
        disableSortBy: true,
        Cell: ({
          value,
        }: {
          value: { name: SupportedAssetCategoryType; id: string };
        }) => <span>{toTitleCase(value.name)}</span>,
      },
      {
        Header: t("Language"),
        accessor: "language",
        disableSortBy: true,
        Cell: ({ value }: { value: SupportedLanguage }) => (
          <span>{value?.toLocaleUpperCase()}</span>
        ),
      },
      {
        Header: t("# of assets"),
        disableSortBy: true,
        accessor: "number_of_assets",
      },
      {
        Header: "Uploaded By",
        disableSortBy: true,
        accessor: "modified_by",
      },
      {
        Header: "Uploaded On",
        accessor: "modified_at",
      },
    ],
    [t]
  );

  const { data: previousUploadsResponse, error } = useSWR<
    WithPagination<{ data: UploadedAssetSchema[] }>
  >(
    [
      endpoints.v2_tenants_tenant_id_pim_assets_uploads(tenant_id),
      useMemo(
        () => ({
          params: {
            offset,
            limit: perPage,
            ...(sortingRules.sortBy ? { sort_by: sortingRules.sortBy } : {}),
            order_by: sortingRules.orderBy ?? "desc",
          },
        }),
        [offset, perPage, sortingRules.orderBy, sortingRules.sortBy]
      ),
    ],
    { revalidateOnMount: true }
  );

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const isLoading = !previousUploadsResponse && !error;

  useEffect(() => {
    const handlePreviousUpdatesData = ({
      data,
      pagination,
    }: WithPagination<{ data: UploadedAssetSchema[] }>) => {
      setTableData(
        data.map((data) => ({
          ...data,
          modified_at: formatDateTime(data.modified_at),
        }))
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };
    if (previousUploadsResponse) {
      handlePreviousUpdatesData(previousUploadsResponse);
    }
  }, [perPage, previousUploadsResponse]);

  const handleSelect = useCallback(
    (
      selectedRows: { [pageNumber: string]: Row<UploadedAssetSchema>[] } | "all"
    ) => {
      if (onSelect && selectedRows !== "all") {
        const selectedAssets = Object.values(selectedRows)
          .flat()
          .map((row) => row.original);
        onSelect(selectedAssets);
      }
    },
    [onSelect]
  );

  return (
    <AssetsListContainer>
      {!isCheckBoxRequired && (
        <PrimaryButtonMedium
          style={{ maxWidth: "168px" }}
          onClick={() => setStage("add_files")}
        >
          {t("Start a new upload")}
        </PrimaryButtonMedium>
      )}
      <H3 style={{ marginTop: "38px" }}>{t("Previous Uploads")}</H3>
      <div>
        <DropdownContainer>
          <DropDown
            items={perPageItems}
            activeItem={perPage}
            textLeft={t("items") + ":"}
            textRight={t(" Per Page")}
            direction={"right"}
            className={"per_Page"}
            clickHandler={changePerPage}
          />
        </DropdownContainer>
        <ContentWrapper>
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
            error={error}
            handleSort={handleSort}
            lastChildleftAlign={true}
            handleSelectRows={isCheckBoxRequired ? handleSelect : undefined}
          />
          <Pagination
            pagination={tablePagination}
            offset={offset}
            handlePageClick={changePage}
          />
        </ContentWrapper>
      </div>
    </AssetsListContainer>
  );
};
