import { screenSize } from "../../theme";
import React, { Fragment } from "react";
import styled from "styled-components/macro";

export type KeyValuePair = {
  key: string;
  value: string | JSX.Element;
};

const KeyValueContainer = styled.section`
  display: grid;
  grid-gap: 2em;
`;

const KeyText = styled.dt`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.secondaryTextColor};
  white-space: nowrap;
`;

const ValueText = styled.dd`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.primaryTextColor};
`;

const AttributesContainer = styled.dl`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10ch, 2fr));
  grid-gap: 1em;

  ${KeyText} {
    grid-column-start: 1;
  }

  ${ValueText} {
    grid-column-start: span 2;
    text-align: right;
    margin-inline-start: 25px;
    @media ${screenSize.medium} {
      text-align: left;
    }
  }
`;

/**
 * display key value pairs in a grid. This is designed for short pieces
 * of text but it won't break if the text wraps.
 * @param data
 */
export function KeyValueDisplay({ data }: { data: KeyValuePair[] }) {
  return (
    <KeyValueContainer>
      <AttributesContainer>
        {data.map((item) => (
          <Fragment key={item.key}>
            <KeyText>{item.key}</KeyText>
            <ValueText data-testid={item.key}>{item.value}</ValueText>
          </Fragment>
        ))}
      </AttributesContainer>
    </KeyValueContainer>
  );
}
