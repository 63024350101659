import React, { useState } from "react";
import { AddPOForm } from "./AddPOForm";
import styled from "styled-components/macro";
import {
  EditButton,
  PrimaryButtonSmall,
} from "../../../../components/Buttons/Buttons";
import type { IPurchaseOrder, OrderDocument } from "../../../../types/types";
import { PoNumberAndDocumentLink } from "../../../../components/PoNumberAndDocumentLink/PoNumberAndDocumentLink";
import { useTranslation } from "react-i18next";

interface IAddPO {
  order: IPurchaseOrder;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InnerTitle = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

export const AddPO = ({ order }: IAddPO) => {
  const { t } = useTranslation();
  const [addingPO, setAddingPO] = useState(false);
  const poNumber = order.alternative_po_number;

  const poDocument = order.documents.find(
    (doc: OrderDocument) => doc.kind === "purchase_order"
  );

  const packingListDocument = order.documents.find(
    (doc: OrderDocument) => doc.kind === "packaging_list"
  );

  return (
    <>
      {!addingPO && (
        <>
          <Row>
            <InnerTitle>{t("PO Number")}</InnerTitle>
            {poNumber &&
              !packingListDocument &&
              order.status !== "completed" && (
                <EditButton
                  testid={"add-po-number"}
                  onClick={() => setAddingPO(true)}
                />
              )}
            {!poNumber && order.status !== "completed" && (
              <PrimaryButtonSmall onClick={() => setAddingPO(true)}>
                {t("Add")}
              </PrimaryButtonSmall>
            )}
          </Row>
          {poNumber && (
            <PoNumberAndDocumentLink
              poNumber={poNumber}
              poDocument={poDocument}
            />
          )}
        </>
      )}
      {addingPO && (
        <AddPOForm
          order_number={order.id}
          show={setAddingPO}
          PONumber={poNumber}
          PODocument={poDocument}
          addPO={addingPO}
        />
      )}
    </>
  );
};
