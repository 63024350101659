import React, { useState, useContext, useEffect, useCallback } from "react";
import { Form } from "../../../../layout/FormLayout";
import {
  makeUrlWithParams,
  useStoreState,
  convertUserToOption,
  useFormWrapper,
  TEMPORARY_HIGH_LIMIT,
} from "../../../../util/util";
import type {
  Tenant,
  AgilisUsersPaginatedOutput,
  User,
  AccountSubscriber,
} from "../../../../types/types";
import useSWR from "swr";
import {
  DeleteButton,
  PrimaryButtonMedium,
  PrimaryButtonSmall,
} from "../../../../components/Buttons/Buttons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import Axios from "axios";
import { endpoints } from "../../../../endpoints";
import { FormContainer, SaveButtonContainer } from "./CustomerSettings";
import { t } from "i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { zodSelectBoxDefault } from "../../../../util/zod.util";
import * as zod from "zod";
import { SelectWithDeleteButton } from "../../../../layout/shared";
import { AsyncSearchSelect } from "../../../../components/AsyncSearchSelect/AsyncSearchSelect";
import { debounce } from "lodash";
import axios from "axios";
import { useFieldArray } from "react-hook-form";

type FormOutput = {
  subscribers: { label: string; value: string }[];
};

export const EditAccountSubscribers = ({
  buyerTenant,
  loggedInUser,
  refreshSettingsData,
  doneEditing,
  subscribers,
  accountManager,
  customerServiceRep,
}: {
  buyerTenant: Tenant;
  loggedInUser: User;
  accountManager?: User;
  customerServiceRep?: User;
  refreshSettingsData: () => void;
  doneEditing: () => void;
  subscribers: AccountSubscriber[];
}) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { storefront_id } = useStoreState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const EditSubscribersSchema = zod.object({
    subscribers: zod.array(zodSelectBoxDefault(t)),
  });

  const {
    handleSubmit,
    control,
    errors,
    formState,
    watch,
    clearErrors,
    setValue,
  } = useFormWrapper<FormOutput>({
    resolver: zodResolver(EditSubscribersSchema),
    shouldUnregister: false,
    defaultValues: {
      subscribers: subscribers.map((subscriber) => ({
        value: subscriber.id,
        label: `${subscriber.firstname} ${subscriber.lastname} - ${subscriber.email_address}`,
      })),
    },
  });

  const methodsOfUseFieldArray = useFieldArray({
    control: control,
    name: "subscribers",
  });

  const { fields, append, remove } = methodsOfUseFieldArray;

  const fieldsValues = watch("subscribers");

  const { data: usersData } = useSWR<AgilisUsersPaginatedOutput>(
    buyerTenant.id
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_tenants_id_users(
            storefront_id,
            loggedInUser.tenant_id
          ),
          {
            offset: 0,
            is_active: "true",
            limit: TEMPORARY_HIGH_LIMIT,
          }
        )
      : null
  );

  const users = usersData?.data.filter(
    (user) =>
      user.is_active &&
      user.id !== accountManager?.id &&
      user.id !== customerServiceRep?.id
  );
  const userOptions =
    users
      ?.map(convertUserToOption)
      .filter(
        (option) => !fieldsValues?.find((field) => field.value === option.value)
      ) ?? [];

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const handleSelectorSearch = useCallback(
    debounce((query, setOptions) => {
      const getOptions = async (query: string) => {
        try {
          const { data } = await axios.get<{
            data: User[];
          }>(
            endpoints.v1_storefronts_id_tenants_id_users(
              storefront_id,
              loggedInUser.tenant_id
            ),
            {
              params: {
                limit: TEMPORARY_HIGH_LIMIT,
                q: query,
                order: "asc",
                is_active: "true",
              },
            }
          );
          return data.data
            .filter(
              (user) =>
                user?.is_active &&
                user.id !== accountManager?.id &&
                user.id !== customerServiceRep?.id
            )
            ?.map(convertUserToOption)
            .filter(
              (option) =>
                !fieldsValues?.find((field) => field.value === option.value)
            );
        } catch (error) {
          notifyError(
            t("Could not fetch Users. Something went wrong.", { error })
          );
          return [];
        }
      };
      getOptions(query).then((options) => setOptions(options));
    }, 1000),
    []
  );

  const onSubmit = async (formData: FormOutput) => {
    setIsSubmitting(true);
    try {
      const postBody = {
        subscribers: formData.subscribers.map((field) => {
          return { id: field.value };
        }),
      };

      await Axios.patch(
        endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
          storefront_id,
          loggedInUser.tenant_id,
          buyerTenant.id
        ),
        postBody
      );
      notifySuccess("Changes saved");
      setIsSubmitting(false);
      refreshSettingsData();
      doneEditing();
    } catch (error) {
      notifyError("There was an error submitting the changes", { error });
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({ label: undefined, value: undefined });
    }
  }, [append, fields]);

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <SelectWithDeleteButton key={field.id}>
            <AsyncSearchSelect
              options={userOptions.filter(
                (option) =>
                  !fieldsValues?.find((field) => field.value === option.value)
              )}
              name={`subscribers[${index}]`}
              error={
                errors?.subscribers
                  ? errors?.subscribers[index]?.value
                  : undefined
              }
              errors={errors}
              formState={formState}
              placeholder={t(`User`)}
              searchFunction={handleSelectorSearch}
              onChange={(data: any) => {
                setValue(`subscribers.${index}`, data);
                if (errors?.subscribers && errors?.subscribers[index]) {
                  clearErrors(`subscribers.${index}`);
                }
              }}
              value={
                field.value
                  ? { value: field.value, label: field.label }
                  : undefined
              }
              testid={`subscribers${index}`}
            />
            <DeleteButton
              testid={`delete-button-${index}`}
              onClick={() => remove(index)}
              type="button"
              height={20}
              width={20}
            />
          </SelectWithDeleteButton>
        ))}
        <PrimaryButtonSmall
          type="button"
          onClick={() =>
            append({
              label: undefined,
              value: undefined,
            })
          }
        >
          {t("Add")}
        </PrimaryButtonSmall>
        <SaveButtonContainer>
          <PrimaryButtonMedium loading={isSubmitting}>
            {t("Save your changes")}
          </PrimaryButtonMedium>
        </SaveButtonContainer>
      </Form>
    </FormContainer>
  );
};
