import styled, { useTheme } from "styled-components/macro";
import React from "react";
import { EditIcon } from "../Icons/Icons";

export const TopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

type EditTopRightProps = {
  onClick: () => void;
};

export const EditTopRight = ({ onClick }: EditTopRightProps) => {
  const theme = useTheme();
  return (
    <TopRight onClick={onClick}>
      <EditIcon fill={theme.primaryLinkColor} />
    </TopRight>
  );
};
