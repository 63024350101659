import React from "react";
import {
  UserIcon,
  SettingsIcon,
  InfoIcon,
} from "../../../../components/Icons/Icons";
import { providePrivatePageProps } from "../../../../util/Routing";
import type { IOrganizationPageTab } from "../../../SharedPages/OrganizationPage/OrganizationPage";
import { OrganizationPage } from "../../../SharedPages/OrganizationPage/OrganizationPage";
import { ManageUsersList } from "../../../SharedPages/OrganizationPage/UsersList/UsersList";
import { CompanyInfo } from "../../../SharedPages/OrganizationPage/CompanyInfo/CompanyInfo";
import { BuyerSettings } from "../../../SharedPages/OrganizationPage/BuyerSettings/BuyerSettings";

/**
 * Page component for the Buyer Admin "Organization" page.
 *
 * `providePrivatePageProps` is used because this is the page you hit after
 * logging in, so we want to be sure it works fine for that case.
 */
export const BuyerAdminOrganization = providePrivatePageProps(({ user }) => {
  // For the moment until the Tabs component is replaced by Reach tabs these are
  // intentionally not wrapped in `t`.
  const tabs: IOrganizationPageTab[] = user.is_buyer_activated
    ? [
        {
          label: "Company Info",
          icon: InfoIcon,
          component: <CompanyInfo user={user} />,
        },
        {
          label: "Users",
          icon: UserIcon,
          component: <ManageUsersList user={user} />,
        },
        {
          label: "Settings",
          icon: SettingsIcon,
          component: <BuyerSettings />,
        },
      ]
    : [
        {
          label: "Company Info",
          icon: InfoIcon,
          component: <CompanyInfo user={user} />,
        },
      ];

  return <OrganizationPage tabs={tabs} />;
});
