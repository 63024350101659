import { useMemo, useState } from "react";
import styled from "styled-components/macro";
import {
  EditButton,
  AddNewIconButton,
  CancelButtonSmall,
} from "../../../../../components/Buttons/Buttons";
import { H3, H6 } from "../../../../../components/Typography/Typography";
import { useStoreState, useSupportedLanguages } from "../../../../../util/util";
import { Table } from "../../../../../components/Table/Table";
import { Modal } from "../../../../../components/Modal/Modal";
import {
  EditSenderName,
  NotificationTemplateEditor,
} from "./NotificationTemplateEditor";
import { ErrorPlaceholder } from "../../../../../components/Error";
import type {
  EmailTemplateKey,
  IEmailTemplateEditorData,
  IStorefrontEmailTemplate,
  OptionType,
  SupportedLanguage,
} from "../../../../../types/types";
import useSWR from "swr";
import { endpoints } from "../../../../../endpoints";
import { useTranslation } from "react-i18next";
import {
  SettingsCard,
  WhiteInnerCard,
} from "../../../../../layout/portalPageLayout";
import { Row } from "../../../../../components/Layout/Layout";
import { InfoBlockSmall } from "../../../../../components/InfoBlocks/InfoBlocks";
import { ReminderNotifications } from "./ReminderNotifications";

const TableWrapper = styled(WhiteInnerCard)`
  & > div {
    padding: 20px 0 !important;
    background: transparent !important;
    border: 0 !important;
  }
  table td,
  table th,
  table th:last-child {
    text-align: center !important;
    border-bottom: 0 !important;
  }
  table td:first-child {
    text-align: left !important;
    border-left: 0 !important;
  }
  table td:last-child {
    text-align: center !important;
  }
`;

const ModalWrapper = styled.div`
  div {
    max-width: 80% !important;
  }
`;

type EmailTemplateType = { label: string } & {
  [prop: string]: false | JSX.Element;
};

export const NotificationsTab = () => {
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [editSenderNameSection, setEditSenderNameSection] = useState(false);
  const { storefront_id, storefront_metadata } = useStoreState();
  const { supportedLanguageOptions } = useSupportedLanguages();
  const { edition, sender_name, enable_email_reminder } = storefront_metadata;
  const [editorData, setEditorData] = useState<IEmailTemplateEditorData>();
  const { t } = useTranslation();
  const openTemplateEditor = () => setShowNotificationModal(true);

  const closeTemplateEditor = () => {
    mutateEmailTemplatesList();
    setShowNotificationModal(false);
  };

  const {
    data: customTemplatesList,
    error: customTemplatesListError,
    mutate: mutateEmailTemplatesList,
  } = useSWR<IStorefrontEmailTemplate[]>(
    endpoints.v1_storefronts_id_emailTemplates(storefront_id)
  );

  const storeLanguagesColumns = Object.keys(
    storefront_metadata.supported_languages
  ).map((key) => ({
    accessor: key,
    Header: storefront_metadata.supported_languages[key],
  }));

  const templateLanguagesColumns = useMemo(
    () => [
      {
        accessor: "label",
        Header: "",
      },
      ...storeLanguagesColumns,
    ],
    [storeLanguagesColumns]
  );

  const handleEditEmailTemplate = ({
    template,
    label,
  }: {
    template: IStorefrontEmailTemplate;
    label: string;
  }) => {
    setEditorData({
      label: label,
      language: template.language,
      key: template.template_key,
      templateData: template,
    });
    openTemplateEditor();
  };

  const InfoBlockColumn = styled.div`
    & > div {
      margin: 15px 0 15px;
    }
  `;

  const handleAddEmailTemplate = ({
    label,
    language,
    key,
  }: {
    label: string;
    language: SupportedLanguage;
    key: EmailTemplateKey;
  }) => {
    setEditorData({
      label: label,
      language: language,
      key: key,
    });
    openTemplateEditor();
  };

  const isCustomTemplateLanguage = (
    key: EmailTemplateKey,
    language: SupportedLanguage,
    label: string
  ) => {
    if (customTemplatesList) {
      const template = customTemplatesList.find(
        (template) =>
          template.template_key === key && template.language === language
      );
      return template ? (
        <EditButton
          testid="editLanguage"
          onClick={() => handleEditEmailTemplate({ template, label })}
        />
      ) : language === "en" ? (
        <EditButton
          testid="editLanguage"
          onClick={() => handleAddEmailTemplate({ label, language, key })}
        />
      ) : (
        <AddNewIconButton
          testid="addLanguage"
          onClick={() => handleAddEmailTemplate({ label, language, key })}
        />
      );
    }
    return false;
  };

  const emailTemplateMapper = (templateLabels: OptionType<string>[]) => {
    return templateLabels.map(
      ({ value: emailTemplateKey, label: emailTemplateLabel }) =>
        supportedLanguageOptions.reduce<EmailTemplateType>(
          (acc, cur) => ({
            ...acc,
            [cur.value]: isCustomTemplateLanguage(
              emailTemplateKey as EmailTemplateKey,
              cur.value,
              emailTemplateLabel
            ),
          }),
          { label: emailTemplateLabel } as EmailTemplateType
        )
    );
  };

  const leadEmailTemplateLabels = [
    { label: "Quote Request", value: "notify_customer_quote_request" },
    { label: "Registration", value: "notify_customer_registration" },
    {
      label: "Request Decline",
      value: "notify_customer_lead_rejected_quote_request",
    },
    { label: "Contact us", value: "notify_customer_contact_form" },
  ];

  const accountEmailTemplateLabels = [
    { label: "Activation", value: "user_activation" },
    { label: "Forget Username", value: "forgot_username" },
    {
      label: "Forget Password",
      value: "forgot_password",
    },
  ];

  const transactionalEmailTemplateLabels = [
    { label: "New Quote Request", value: "transaction_quote_requested" },
    { label: "Quote Responded", value: "transaction_quote_responded" },
    { label: "Order Complete", value: "transaction_order_completed" },
    { label: "Order Decline", value: "transaction_order_declined" },
    { label: "Order Placed", value: "transaction_quote_accepted" },
    { label: "Order Cancel", value: "transaction_order_cancelled" },
  ];

  const leadEmailTemplates = emailTemplateMapper(leadEmailTemplateLabels);

  const accountEmailTemplates = emailTemplateMapper(accountEmailTemplateLabels);

  const transactionalEmailTemplates = emailTemplateMapper(
    transactionalEmailTemplateLabels
  );

  return (
    <>
      {enable_email_reminder && <ReminderNotifications />}
      <SettingsCard>
        <Row>
          <H3>{t("Configure Sender Name")}</H3>
          {!editSenderNameSection && (
            <EditButton
              testid={"edit-sender-name"}
              onClick={() => setEditSenderNameSection(true)}
              title={t("Edit Sender Name")}
            />
          )}
          {editSenderNameSection && (
            <CancelButtonSmall
              onClick={() => setEditSenderNameSection(false)}
            />
          )}
        </Row>
        {!editSenderNameSection && (
          <InfoBlockColumn>
            <InfoBlockSmall
              header={t("Sender Name")}
              content={sender_name || "--"}
            />
          </InfoBlockColumn>
        )}

        {editSenderNameSection && (
          <EditSenderName
            sender_name={storefront_metadata.sender_name}
            closeEditSenderName={() => setEditSenderNameSection(false)}
          />
        )}
      </SettingsCard>
      <SettingsCard>
        {customTemplatesList && (
          // This whole page seems to depend on the custom templates. the tables
          // have undefined/false passed in because the loading is handled here
          // and the tables display static data
          <>
            <H3>{t("Customer Email Notifications")}</H3>
            {edition !== "pim" && (
              <TableWrapper>
                <H6>{t("Leads Acknowledgement Emails")}</H6>
                <Table
                  columns={templateLanguagesColumns}
                  showLeftBorder={false}
                  data={leadEmailTemplates}
                  isLoading={false}
                  error={undefined}
                />
              </TableWrapper>
            )}

            <TableWrapper>
              <H6>{t("Account & Approval Emails")}</H6>
              <Table
                columns={templateLanguagesColumns}
                showLeftBorder={false}
                isLoading={false}
                error={undefined}
                data={accountEmailTemplates}
              />
            </TableWrapper>
            {edition !== "pim" && (
              <TableWrapper>
                <H6>{t("Transactional Emails")}</H6>
                <Table
                  isLoading={false}
                  showLeftBorder={false}
                  error={undefined}
                  columns={templateLanguagesColumns}
                  data={transactionalEmailTemplates}
                />
              </TableWrapper>
            )}
            <ModalWrapper>
              <Modal
                closeModal={closeTemplateEditor}
                show={showNotificationModal}
                overlay
                modalWidth={"100%"}
              >
                {editorData && (
                  <NotificationTemplateEditor
                    editorData={editorData}
                    closeEditor={closeTemplateEditor}
                  />
                )}
              </Modal>
            </ModalWrapper>
          </>
        )}
        {customTemplatesListError && (
          <ErrorPlaceholder message="There was an error retrieving the email templates. Please try again!" />
        )}
      </SettingsCard>
    </>
  );
};
