import type {
  CustomPageConfig,
  SelectionWizardResponse,
  SEODetail,
} from "../../../types/types";
import type { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import { CustomPageCarousel } from "../../../components/CustomPageCarousel/CustomPageCarousel";
import { ErrorPlaceholder } from "../../../components/Error";
import { SelectorWizard } from "../../../components/SelectorWizard/SelectorWizard";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { endpoints } from "../../../endpoints";
import { useRoutePath } from "../../../util/Routing";
import { useStoreState } from "../../../util/util";
import { getBrowserLanguage } from "../../../util/util-components";

/**
 * Page component for rendering custom pages where the HTML and CSS is provided
 * by the backend.
 */
export const CustomStorefrontPage = ({
  pageConfig,
}: {
  pageConfig: CustomPageConfig;
}) => {
  const { storePath } = useRoutePath();
  const history = useHistory();
  const {
    slug,
    storefront_id,

    storefront_metadata: {
      theme_object,
      is_product_selector_enabled,
      enable_product_selector,
    },
  } = useStoreState();
  const [cookies] = useCookies([`preferred-language-${slug}`]);
  const preferredLanguage: string | undefined =
    cookies[`preferred-language-${slug}`];
  const location = useLocation();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [params, setParams] = useState<URLSearchParams | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    isAgilisProductSelectorModalOpen,
    setIsAgilisProductSelectorModalOpen,
  ] = useState(false);
  const [filters, setFilters] = useState<string[] | null>(null);
  const { tenantSlug } = useParams<{ tenantSlug: string }>();

  // This is a "callback ref" used to add vanilla JS to the rendered HTML.
  // https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
  const handleContainerRef = useCallback(
    (container: HTMLDivElement | null): void => {
      setContainer(container);
    },
    []
  );

  // If the user hasn't set a preference go by the browser.
  const language = !preferredLanguage
    ? getBrowserLanguage()
    : preferredLanguage;

  // Find the page ID to use to get the correct translation of the page.
  // fall back to english if we don't have a translation for the users language.
  const pageContent =
    pageConfig.page_content.find((content) => content.language === language) ??
    pageConfig.page_content.find((content) => content.language === "en");
  const pageId = pageContent?.id;

  const {
    data: html,
    error: htmlError,
    mutate: mutateHTML,
  } = useSWR<string>(
    pageId ? `/v1/storefronts/custom-pages/${pageId}/html` : null,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  const {
    data: css,
    error: cssError,
    mutate: mutateCSS,
  } = useSWR<string>(
    pageId ? `/v1/storefronts/custom-pages/${pageId}/css` : null,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { data: homePageSEO } = useSWR<SEODetail, AxiosError<SEODetail>>(
    endpoints.v1_storefronts_id_or_slug_seo_page(tenantSlug, "home_page")
  );

  const baseURL = slug?.includes("brenntag")
    ? `/v1/storefronts/${storefront_id}/selections?language=${language}`
    : `/v2/storefronts/${storefront_id}/selections?language=${language}`;
  const shouldFetchFilters = slug?.includes("brenntag")
    ? !!document.querySelector("#open-modal-button")
    : is_product_selector_enabled && enable_product_selector
    ? document.querySelector("#product-selector-button")
    : false;
  const { data: selectionsResponse, mutate } = useSWR<
    SelectionWizardResponse,
    AxiosError
  >(shouldFetchFilters ? (params ? `${baseURL}&${params}` : baseURL) : null, {
    onSuccess: (data) => {
      const lastFilters = data.filters[data.filters.length - 1].filter_values;
      if (!data.show_submit) {
        setFilters(lastFilters);
      }
    },
  });

  useEffect(() => {
    if (preferredLanguage !== undefined) {
      // when preference cookie changes get mutate to prevent the user from
      // having to reload the page.
      mutateCSS();
      mutateHTML();
    }
  }, [preferredLanguage, mutateCSS, mutateHTML]);

  useEffect(() => {
    // This useEffect sets up the JavaScript event listeners, but only when
    // everything is ready.
    if (!html || htmlError || cssError || !container) {
      return;
    }

    // Search form.
    // The search form HTML structure and classes should basically be like this,
    // so the JS event handler code will work.
    //
    // <form class="agc-search-main">
    //   <input type="search" class="agc-search-main-bar" >
    //   <input type="submit" value="Search">
    // </form>

    const searchForm: HTMLFormElement | null =
      container.querySelector(".agc-search-main");
    const searchBar: HTMLInputElement | null = container.querySelector(
      ".agc-search-main-bar"
    );
    if (searchForm && searchBar) {
      searchForm.addEventListener("submit", (event: Event) => {
        event.preventDefault();
        history.push(`${storePath}/portfolio?q=${searchBar.value}`);
      });
    } else {
      if (!searchForm) {
        console.error("Custom page: missing search form", searchForm);
      }
      if (!searchBar) {
        console.error("Custom page: missing search bar", searchBar);
      }
    }

    const agilisProductSelectorButton: HTMLButtonElement | null =
      slug?.includes("brenntag")
        ? container?.querySelector("#open-modal-button")
        : enable_product_selector && is_product_selector_enabled
        ? container?.querySelector("#product-selector-button")
        : null; //agilis_product_selector

    if (agilisProductSelectorButton) {
      agilisProductSelectorButton.addEventListener("click", () => {
        mutate();
        setIsAgilisProductSelectorModalOpen(true);
      });
    }
  }, [
    container,
    html,
    htmlError,
    cssError,
    history,
    storePath,
    slug,
    enable_product_selector,
    is_product_selector_enabled,
    mutate,
  ]);

  useEffect(() => {
    if (!html || htmlError || cssError || !container) {
      return;
    }
    const bannerCarousel: HTMLElement | null = container.querySelector(
      ".agc-banner-carousel"
    );

    if (
      bannerCarousel &&
      pageConfig?.page_banner?.length > 0 &&
      pageConfig.page_banner_settings
    ) {
      ReactDOM.render(
        <CustomPageCarousel
          banners={pageConfig.page_banner}
          settings={pageConfig.page_banner_settings}
        />,
        bannerCarousel
      );
    }
  }, [container, html, htmlError, cssError, pageConfig]);

  useEffect(() => {
    // Handle FAQ in separate useEffect to avoid multiple event listeners being added
    if (!html || htmlError || cssError || !container) {
      return;
    }

    // FAQ.
    // The FAQ HTML structure and classes should basically always be like this.
    //
    // <div class="agc-faq-wrap">
    //   <div class="agc-faq-question-wrap">
    //     <a href="#" class="agc-faq-question">
    //       <div class="agc-questions-title">Question number 1</div>
    //       <div class="agc-arrow"><img src="..."/></div>
    //     </a>
    //     <div class="agc-faq-content">
    //       <p>Answer number 1</p>
    //     </div>
    //   </div>
    //   <!-- Additional FAQs here... -->
    // </div>

    const faqContainers: NodeListOf<HTMLElement> =
      container.querySelectorAll(".agc-faq-wrap");
    faqContainers.forEach((faqContainer) => {
      if (faqContainer) {
        const faqItems: NodeListOf<HTMLLIElement> =
          faqContainer.querySelectorAll(".agc-faq-question-wrap");

        faqItems.forEach((item) => {
          item.addEventListener("click", (event: Event) => {
            event.preventDefault();
            event.stopPropagation();

            if (event.target !== null) {
              // Cast to HTMLElement to satisfy TypeScript.
              const target: unknown = event.target;
              if (
                target instanceof HTMLAnchorElement &&
                target.classList.contains("agc-link")
              ) {
                const href = target.getAttribute("href");
                if (href) {
                  window.location.href = href;
                }
                // window.location.href = target.attributes.href.textContent;
              }

              // Check to see if the click was on the question part of the FAQ,
              // (and not the answer part).
              if (
                target instanceof HTMLDivElement &&
                (target.classList.contains("agc-faq-question") ||
                  target.classList.contains("agc-questions-title") ||
                  target.classList.contains("agc-arrow"))
              ) {
                // If the click is on the active question, just make it inactive.
                if (item.classList.contains("active")) {
                  item.classList.remove("active");
                  return;
                }

                // Make all questions inactive; then make the clicked one active.
                faqItems.forEach((faqItem) =>
                  faqItem.classList.remove("active")
                );
                item.classList.toggle("active");
              }
            }
          });
        });
      } else {
        console.error("Custom page: missing FAQ container", faqContainer);
      }
    });
  }, [container, cssError, html, htmlError]);

  useEffect(() => {
    if (location.hash) {
      //workaround to scroll to element after content get loaded
      setTimeout(() => {
        const element_to_scroll_to = document.querySelector(location.hash);
        element_to_scroll_to?.scrollIntoView({ behavior: "smooth" });
      }, 350);
    }
  }, [location.hash]);

  // Extra CSS for the FAQ.
  const extraFaqCss = `
    .agc-questions-title{
      z-index: 2;
    }
    .agc-arrow {
      position: absolute;
      right: 20px;
      z-index: 1;
    }
    .agc-faq-question-wrap.active .agc-faq-content {
      max-height: 800px;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    .agc-faq-question-wrap.active .agc-arrow {
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      transform: rotate(180deg);
    }

    .agc-faq-content {
      max-height: 0;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  `;

  // Extra CSS for the carousel text box
  // to be active only when fixed_overlay === true
  const carouselTextBoxCss = `
     @media screen and (max-width: 767px) {
      .agc-hero-container {
        margin-left: 3em !important;
        height: 100px !important;
        transform: translateY(90%) !important;
        width: 60%;
        top: 50px;
      }
      .agc-hero {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
    @media screen and (max-width: 618px) {
      .agc-hero-container {
        transform: translateY(100%) !important;
      }
      .agc-h1 {
        font-size: 1.5em !important;
      }
    }
    @media screen and (max-width: 563px) {
      .agc-cta {
        font-size: 0.7em;
      }
    }
    @media screen and (max-width: 479px) {
      .agc-hero.agc-banner-carousel {
        height: 500px !important;
      }
      .agc-hero-container {
        top: 100px;
      }
    }

  `;

  const productSelectorCss = `
    .agc-grid-answers {
      display: flex;
      margin-top: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
    }
    .agc-cta-2 {
      display: flex;
      min-width: auto;
      margin-right: 0px;
      margin-bottom: 20px;
      padding: 10px 76px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      -webkit-box-pack: center;
      justify-content: center;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      align-items: center;
      border: 1px solid ${theme_object.branding.brandColor};
      border-radius: 4px;
      background-color: #fff;
      transition: all 200ms ease;
      color: ${theme_object.branding.brandColor};
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.5px;
      text-decoration: none;
      text-transform: none;
    }
  `;

  if (htmlError) {
    console.error("Custom page: html fetching error", htmlError);
  }
  if (cssError) {
    console.error("Custom page: css fetching error", cssError);
  }
  if (!pageId) {
    console.error("Custom page: the page ID could not be found");
  }
  if (htmlError || cssError || !pageId) {
    return <ErrorPlaceholder message="There was an error loading the page." />;
  }

  if (html && css) {
    const finalCss =
      pageConfig?.page_banner_settings?.fixed_overlay ?? false
        ? css + extraFaqCss + carouselTextBoxCss + productSelectorCss
        : css + extraFaqCss + productSelectorCss;
    return (
      <>
        {homePageSEO ? (
          <Helmet>
            <title>
              {homePageSEO?.page_title ?? pageContent?.browser_title ?? ""}
            </title>
            <style type="text/css">{finalCss}</style>
            {homePageSEO.meta_description && (
              <meta name="description" content={homePageSEO.meta_description} />
            )}
            {homePageSEO.meta_title && (
              <meta name="title" content={homePageSEO.meta_title} />
            )}
            {homePageSEO.meta_keywords && (
              <meta name="keywords" content={homePageSEO.meta_keywords} />
            )}
            {homePageSEO.og_type && (
              <meta property="og:type" content={homePageSEO.og_type} />
            )}
            {homePageSEO.og_title ? (
              <meta
                name="title"
                property="og:title"
                content={homePageSEO.og_title}
              />
            ) : homePageSEO.meta_title ? (
              <meta
                name="title"
                property="og:title"
                content={homePageSEO.meta_title}
              />
            ) : (
              ""
            )}
            {homePageSEO.og_description ? (
              <meta
                name="description"
                property="og:description"
                content={homePageSEO.og_description}
              />
            ) : homePageSEO.meta_description ? (
              <meta
                name="description"
                property="og:description"
                content={homePageSEO.meta_description}
              />
            ) : (
              ""
            )}
            {homePageSEO.og_url && (
              <meta name="url" property="og:url" content={homePageSEO.og_url} />
            )}

            {theme_object.branding.publicLogoUrl && (
              <meta
                name="image"
                property="og:image"
                content={theme_object.branding.publicLogoUrl}
              />
            )}
            {homePageSEO.og_locale && (
              <meta property="og:locale" content={homePageSEO.og_locale} />
            )}
            {homePageSEO.og_url && (
              <meta property="og:url" content={homePageSEO.og_url} />
            )}
          </Helmet>
        ) : (
          <Helmet>
            <title>{pageContent?.browser_title ?? ""}</title>
            <style type="text/css">{finalCss}</style>
          </Helmet>
        )}
        <SlideOut
          show={isModalOpen || isAgilisProductSelectorModalOpen}
          closeFlyout={() => {
            setIsModalOpen(false);
            setIsAgilisProductSelectorModalOpen(false);
            // Wizard doesn't have back button so clear selections when modal closes
            setParams(null);
            window.history.pushState("", "", location.pathname);
          }}
        >
          <SelectorWizard
            setParams={setParams}
            isFinal={selectionsResponse?.is_final}
            showSubmit={selectionsResponse?.show_submit}
            filterKey={
              selectionsResponse?.filters[
                selectionsResponse?.filters?.length - 1
              ]?.filter_key as string
            }
            hasError={undefined}
            filterQuestion={
              selectionsResponse?.filters[selectionsResponse.filters.length - 1]
                ?.filter_question
            }
            currentFilters={filters}
            queryParams={selectionsResponse?.filter_query_parameters}
          />
        </SlideOut>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          ref={handleContainerRef}
        />
      </>
    );
  }
  // Render nothing while the HTML and CSS are being fetched.
  return null;
};
