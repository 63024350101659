import { Badge } from "../../layout/shared";
import React, { useContext } from "react";
import type { CSSProperties } from "styled-components/macro";
import styled, { ThemeContext } from "styled-components/macro";

const ButtonGroup = styled.div`
  min-width: 220px;
  button {
    background-color: ${({ theme }) => theme.primaryBG};
    border: solid 1px ${({ theme }) => theme.primaryBorder};
    padding: 10px 24px;
    cursor: pointer;
    display: flex;
    float: left;
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.secondaryTextColor};
    svg {
      margin-right: 5px;
    }
  }

  button:first-of-type {
    border-radius: 4px 0 0 4px;
  }

  button:last-child {
    border-radius: 0 4px 4px 0;
  }

  :after {
    content: "";
    clear: both;
    display: table;
  }

  button:not(:last-child) {
    border-right: none;
  }

  button:focus {
    background-color: ${({ theme }) => theme.secondaryButtonBG};
    outline: none;
    border: solid 1px ${({ theme }) => theme.selectedBorder};
    color: ${({ theme }) => theme.primaryTextColor};
  }

  button:hover {
    :not(:focus) :not(:disabled) {
      border: solid 1px ${({ theme }) => theme.tertiaryBorder};
      color: ${({ theme }) => theme.primaryTextColor};
    }
  }

  button {
    :disabled {
      cursor: not-allowed;
    }
  }
  ${Badge} {
    height: 16px;
    min-width: 16px;
    padding: 1px 3px;
    margin-left: 8px;
    font-size: 11px;
  }
`;

type ToggleGroupProps<ButtonName extends string> = {
  names: ButtonName[];
  clickHandler: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    buttonName: ButtonName
  ) => void;
  activeButton: ButtonName;
  icons?: JSX.Element[];
  style?: CSSProperties;
  disabled?: boolean;
  badges?: (string | undefined)[];
};

export function ToggleGroup<ButtonName extends string>({
  names,
  clickHandler,
  activeButton,
  icons,
  style,
  disabled,
  badges,
}: ToggleGroupProps<ButtonName>) {
  const theme = useContext(ThemeContext);

  if (!names) {
    console.error(
      `<ToggleGroup/> requires an array of names to be rendered: ['Button1', 'Button2']`
    );
    return null;
  }
  if (activeButton === undefined) {
    console.error(
      `<ToggleGroup/> require an activeButton prop, this is a string that should match one of the names in names[]`
    );
    return null;
  }

  return (
    <ButtonGroup style={style}>
      {names.map((name, index) => {
        const isActiveButton = name === activeButton;
        const style = isActiveButton
          ? {
              backgroundColor: theme.primaryButtonBG,
              borderColor: theme.selectedBorder,
              borderRight: `1px solid ${theme.selectedBorder}`,
            }
          : { backgroundColor: theme.primaryBG };

        return (
          <button
            key={index}
            data-name={name}
            onClick={(e) => {
              clickHandler(e, name);
            }}
            style={style}
            disabled={disabled}
          >
            {icons?.[index]}
            {name}
            {badges?.[index] && <Badge>{badges[index]}</Badge>}
          </button>
        );
      })}
    </ButtonGroup>
  );
}
