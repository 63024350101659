import ReactDOM from "react-dom";
import styled, { keyframes } from "styled-components/macro";
import { SearchBar } from "../../../components/SearchBar/SearchBar";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { ProductAttributesSummary } from "../../../types/types.PIM";
import useSWR from "swr";
import { CustomH3, PAGE_SIZE } from "../pages.public.util";
import type {
  TagClassificationConfig,
  WithPagination,
} from "../../../types/types";
import { screenSize } from "../../../theme";
import { useTranslation } from "react-i18next";
import { ErrorPlaceholder } from "../../../components/Error";
import { DelayedSpinner } from "../../../components/DelayedSpinner/DelayedSpinner";
import { FiltersArea } from "../../../components/Filters/Filters";
import { Loader } from "../../../components/Loader/Loader";
import { endpoints } from "../../../endpoints";
import { useStoreState } from "../../../util/util";
import { H3 } from "../../../components/Typography/Typography";
import { AttributeValueDisplay } from "../../SharedPages/ProductDetailPage/ProductOverview/product_overview.utils";
import { TextButton } from "../../../components/Buttons/Buttons";

const Popout = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;

const PopupFade = keyframes`
    0% { transform: scale(0.4)}
    50% {transform: scale(0.7)}
    100% {transform: scale(1)}
`;

const SearchPopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.overlayColor};
  z-index: 900;
  overflow: hidden;
  animation-name: ${Popout};
  animation-duration: 0.3s;
  .__react_component_tooltip.place-top::before {
    bottom: -1px;
    height: 8px !important;
  }
`;

const PopupContainer = styled.div<{
  left_position: string;
  top_position: string;
}>`
  max-width: 1400px;
  min-width: 300px;
  display: flex;
  background: ${({ theme }) => theme.primaryBG};
  position: absolute;
  left: ${({ left_position }) => left_position};
  top: ${({ top_position }) => top_position};
  margin-right: 70px;
  height: ${({ top_position }) => `calc(100% - (${top_position} + 25px))`};
  width: ${({ left_position }) => `calc(100% - (${left_position} + 70px))`};
  animation-name: ${PopupFade};
  animation-duration: 0.3s;
  border-radius: 5px;
  padding: 8px;
  gap: 8px;
  @media ${screenSize.medium} {
    flex-direction: column-reverse;
    width: ${({ left_position }) => `calc(100% - (${left_position} + 25px))`};
  }
`;

const SectionDivider = styled.div`
  display: none;
  @media ${screenSize.medium} {
    display: block;
    height: 1px;
    width: 100%;
    border-bottom: ${({ theme }) => `1px solid ${theme.primaryBorder}`};
  }
`;

const SearchBarContainer = styled.div<{
  search_bar_left_position: number;
  search_bar_top_position: number;
}>`
  position: absolute;
  left: ${({ search_bar_left_position }) => `${search_bar_left_position}px`};
  top: ${({ search_bar_top_position }) => `${search_bar_top_position}px`};
  width: 100%;
  height: fit-content;
`;

const ProductsSection = styled.div`
  display: flex;
  flex: 0.65;
  flex-direction: column;
  height: 100%;
  max-width: 65%;
  position: relative;
  @media ${screenSize.medium} {
    max-height: 65%;
    max-width: 100%;
  }
`;

const FiltersSection = styled.div`
  flex: 0.35;
  height: 100%;
  overflow-y: scroll;
  @media ${screenSize.medium} {
    max-height: 35%;
    width: 100%;
    overflow-x: scroll;
  }
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow-y: scroll;
`;

const ProductItem = styled.div`
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.primaryBorder}`};
  padding: 8px;
  flex-direction: column;
  gap: 8px;
  .ql-editor {
    padding: 0;
  }
  cursor: pointer;
  &:hover {
    border: solid 1.5px ${({ theme }) => theme.tertiaryBorder};
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
`;

const EmptyProductsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextButtonWrapper = styled(TextButton)`
  padding: 20px 8px;
  align-self: flex-start;
`;

const highlightText = (text: string, highlight: string) => {
  if (!highlight || text.includes("<mark>")) {
    return text;
  }
  const highlight_parts = [highlight, ...highlight.split(" ")].join("|");
  return text.replace(
    new RegExp(highlight_parts, "gi"),
    (match: string) => `<mark>${match}</mark>`
  );
};

export const ProductSearchPopup = <
  ProductType extends ProductAttributesSummary
>({
  search_bar_left_position,
  search_bar_top_position,
  search_query,
  debounced_search_query,
  placeholder,
  handle_parent_search,
  handle_parent_clear_search,
  search_bar_max_width,
  custom_labels,
  close_search_popup,
  clear_filter,
  handle_product_click,
}: {
  search_bar_left_position: number;
  search_bar_top_position: number;
  search_query: string;
  debounced_search_query: string;
  placeholder: string;
  handle_parent_search: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handle_parent_clear_search: () => void;
  close_search_popup: () => void;
  search_bar_max_width?: string;
  custom_labels?: TagClassificationConfig[];
  clear_filter: () => void;
  handle_product_click: (product_id: string) => void;
}) => {
  const [offset, set_offset] = useState(0);
  const [products, set_products] = useState<ProductType[]>([]);
  const [is_loading_more_data, set_is_loading_more_data] = useState(false);
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { data, error: productsError } = useSWR<
    WithPagination<{ data: ProductType[] }>
  >(
    [
      endpoints.v2_tenants_tenant_id_pim_products_attributes_summary(tenant_id),
      useMemo(
        () => ({
          params: {
            q: debounced_search_query,
            limit: PAGE_SIZE,
            offset,
          },
        }),
        [debounced_search_query, offset]
      ),
    ],
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (product_response) => {
        set_products([...products, ...product_response.data]);
        set_is_loading_more_data(false);
      },
    }
  );

  const reset_products = () => {
    set_offset(0);
    set_products([]);
  };

  const handle_clear_search = () => {
    handle_parent_clear_search();
    close_search_popup();
  };

  const on_load_more = () => {
    set_offset(offset + PAGE_SIZE);
    set_is_loading_more_data(true);
  };

  const on_enter_press = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handle_parent_search({
          target: { value: search_query },
        } as unknown as React.ChangeEvent<HTMLInputElement>);
        close_search_popup();
      }
    },
    [close_search_popup, handle_parent_search, search_query]
  );

  useEffect(() => {
    if (debounced_search_query) {
      reset_products();
    }
  }, [debounced_search_query]);

  useEffect(() => {
    if (search_query) {
      document.getElementById("search_popup_search_bar")?.focus();
    }
  }, [search_query]);

  useEffect(() => {
    document.addEventListener("keyup", on_enter_press);

    return () => {
      document.removeEventListener("keyup", on_enter_press);
    };
  }, [on_enter_press]);

  const top_position = `${search_bar_top_position + 48}px`;

  const is_loading_initial_data = !data && !products.length && !productsError;

  const can_load_more =
    data && data.pagination.total > 0
      ? data.pagination.total > products.length
      : false;

  return ReactDOM.createPortal(
    <SearchPopupOverlay>
      <SearchBarContainer
        search_bar_left_position={search_bar_left_position}
        search_bar_top_position={search_bar_top_position}
      >
        <SearchBar
          query={search_query}
          placeHolder={placeholder}
          handleChange={handle_parent_search}
          handleClearInput={handle_clear_search}
          maxWidth={search_bar_max_width}
          dataTestId="search_popup_search_bar"
          id="search_popup_search_bar"
        />
      </SearchBarContainer>
      <PopupContainer
        top_position={top_position}
        left_position={`${search_bar_left_position}px`}
      >
        <ProductsSection>
          {productsError ? (
            <ErrorPlaceholder
              message={t("Could not load products, please try again later.")}
            />
          ) : (
            <>
              {is_loading_initial_data ? (
                <DelayedSpinner />
              ) : (
                <>
                  <ProductsContainer>
                    {products.length === 0 ? (
                      <EmptyProductsContainer>
                        <H3>{t("No products found")}</H3>
                      </EmptyProductsContainer>
                    ) : (
                      <>
                        {products.map((product) => (
                          <Fragment key={product.product_id}>
                            <ProductItem
                              onClick={() => {
                                handle_product_click(product.product_id);
                                close_search_popup();
                              }}
                            >
                              <CustomH3 title={product.product_name}>
                                {product.product_name}
                              </CustomH3>
                              {product.attributes.reduce<JSX.Element[]>(
                                (acc, attr) => {
                                  if (!!attr.values?.[0]?.value) {
                                    const modified_attri_values =
                                      attr.input_type === "multiline_entry"
                                        ? attr.values.map((attr_value) => ({
                                            ...attr_value,
                                            value: highlightText(
                                              attr_value.value as string,
                                              debounced_search_query
                                            ),
                                          }))
                                        : attr.values;
                                    attr.values = modified_attri_values;
                                    acc.push(
                                      <AttributeValueDisplay
                                        key={attr.values[0].attribute_id}
                                        attribute={attr}
                                        isPortfolio={true}
                                        max_line_clamp={3}
                                      />
                                    );
                                  }
                                  return acc;
                                },
                                []
                              )}
                            </ProductItem>
                          </Fragment>
                        ))}
                        {can_load_more && !is_loading_more_data && (
                          <TextButtonWrapper onClick={on_load_more}>
                            {t("Load more")}
                          </TextButtonWrapper>
                        )}
                        {is_loading_more_data && (
                          <LoaderContainer>
                            <Loader
                              isLoading={is_loading_more_data}
                              omitContainer={true}
                            />
                          </LoaderContainer>
                        )}
                      </>
                    )}
                  </ProductsContainer>
                </>
              )}
            </>
          )}
        </ProductsSection>
        <SectionDivider />
        <FiltersSection>
          <FiltersArea
            document_and_ai_search_filter={true}
            customLabels={custom_labels ?? []}
            clearFilter={clear_filter}
            exit_document_and_ai_search_filter={handle_clear_search}
          />
        </FiltersSection>
      </PopupContainer>
    </SearchPopupOverlay>,
    document.body
  );
};
