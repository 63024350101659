import React, { useContext, useState } from "react";
import { FooterWrapper, CopyRight } from "../../shared";
import { useMediaQueries, useStoreState } from "../../../util/util";
import { PrivacyPolicyLink } from "../../../components/PrivacyPolicyLink/PrivacyPolicyLink";
import { useRoutePath } from "../../../util/Routing";
import { Auth } from "../../../components/Auth";
import { useTranslation } from "react-i18next";
import { ClickableLink } from "../../../components/Typography/Typography";
import { CookiePolicyModal } from "../../../components/CookiePolicyModal/CookiePolicyModal";
import { useTheme } from "styled-components";
import { IonicPIMLogo } from "../../../components/Icons/Icons";

export const Footer = () => {
  const { t } = useTranslation();
  const { api_metadata, edition, storefront_metadata } = useStoreState();
  const { storePath } = useRoutePath();
  const { user } = useContext(Auth);
  const [showCookiePolicyModal, setShowCookiePolicyModal] = useState(false);
  const theme = useTheme();

  const { isSmallScreen } = useMediaQueries();

  const footerLogo = (() => {
    if (edition === "pim") {
      return (
        <a
          href={"https://ionicpim.com"}
          style={{ all: "unset", cursor: "pointer" }}
          target="_blank"
          rel="noreferrer"
        >
          <IonicPIMLogo />
        </a>
      );
    } else {
      return (
        <a
          href={"https://www.agiliscommerce.com"}
          style={{ all: "unset", cursor: "pointer" }}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://cdn.agilischemicals.com/assets/Agilis_Logo_rainbow.svg"
            width="30"
            style={{ verticalAlign: "middle", marginBottom: "3px" }}
            alt="Agilis Logo"
            data-tip={api_metadata && `${api_metadata.release_version}`}
          />
        </a>
      );
    }
  })();

  const footerText = (() => {
    if (edition === "pim") {
      return (
        <div>
          {t("Product Information Management system powered by ")}
          <a
            href={"https://ionicpim.com"}
            target="_blank"
            rel="noreferrer"
            style={{ margin: "0", padding: "0" }}
          >
            ionicPIM
          </a>
          {" \u00A9"} {new Date().getFullYear()}. {t("All Rights Reserved.")}
        </div>
      );
    } else {
      return (
        <div>
          {t("Digital commerce portal powered by ")}
          <a
            href={"https://www.agiliscommerce.com"}
            target="_blank"
            rel="noreferrer"
            style={{ margin: "0", padding: "0" }}
          >
            Agilis Commerce
          </a>
          {" \u00A9"} {new Date().getFullYear()}. {t("All Rights Reserved.")}
        </div>
      );
    }
  })();

  return (
    <>
      <FooterWrapper>
        <CopyRight>
          {footerLogo}
          {!isSmallScreen && footerText}
        </CopyRight>
        <PrivacyPolicyLink text={t("Privacy Policy")} agilisPolicy />
        {!user && (
          <>
            {storefront_metadata.custom_support_url ? (
              <ClickableLink
                href={storefront_metadata.custom_support_url}
                target="_blank"
              >
                {t("Support")}
              </ClickableLink>
            ) : (
              <ClickableLink href={`${storePath}/support`}>
                {t("Support")}
              </ClickableLink>
            )}
          </>
        )}
        <ClickableLink onClick={() => setShowCookiePolicyModal(true)}>
          {t("Cookie Preferences")}
        </ClickableLink>
        <CookiePolicyModal
          withdrawMode
          show={showCookiePolicyModal}
          closeModal={() => setShowCookiePolicyModal(false)}
        />
      </FooterWrapper>
      {isSmallScreen && (
        <div
          style={{
            backgroundColor: theme.footerBg,
            paddingLeft: "10px",
          }}
        >
          <CopyRight>{footerText}</CopyRight>
        </div>
      )}
    </>
  );
};
