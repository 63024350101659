import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { H6Bold, SoftHeader2 } from "../../../components/Typography/Typography";
import { AuthPage, AuthWrapper } from "../../../layout/publicPageLayout";
import { AuthLogoWrapper, redirectByRole } from "../../Misc/Login";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  RadioButtonContainer,
  SubmitButtonContainer,
} from "../../../layout/FormLayout";
import { RadioButton } from "../../../components/RadioButton/RadioButton";
import styled, { useTheme } from "styled-components";
import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { strings } from "../../../util/strings";
import {
  countryCodeMap,
  getCountryCode,
  getPhoneCodeOption,
  getPhoneCodesOptions,
  getPhoneNumber,
} from "../../../util/phone";
import { useFormWrapper, useStoreState } from "../../../util/util";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../components/TextFields/TextFields";
import type {
  OptionType,
  User,
  TwoFactorTypes,
  TOTPSchema,
} from "../../../types/types";
import {
  PrimaryButtonMedium,
  TextButton,
} from "../../../components/Buttons/Buttons";
import type { AxiosError } from "axios";
import axios from "axios";
import { StringParam, useQueryParams } from "use-query-params";
import { useRoutePath } from "../../../util/Routing";
import { useNotifications } from "../../../components/Notifications/NotificationsContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { zodRequiredString } from "../../../util/zod.util";
import QRCode from "react-qr-code";
import { LoadingIcon } from "../../../components/Icons/Icons";
import { useAuthContext } from "../../../components/Auth";
import { endpoints } from "../../../endpoints";
import { Redirect, useLocation } from "react-router-dom";

const TwoFaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  width: 100%;
`;

const TOTPWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const TwoFAForm = styled(Form)`
  width: 100%;
`;

type FormInput = {
  country_code: OptionType<string>;
  phone_number: string;
};

const PhoneSchema = (t: TFunction) =>
  yup.lazy((formValues: any) =>
    yup.object().shape({
      country_code: yup
        .object()
        .shape({
          label: yup.string().required(strings(t).thisIsARequiredField),
          value: yup.string().required(strings(t).thisIsARequiredField),
        })
        .required(strings(t).thisIsARequiredField),
      phone_number: yup
        .string()
        .phone(
          countryCodeMap.get(formValues.country_code.value),
          false,
          strings(t).phoneNumberMustBeValid
        )
        .required(strings(t).thisIsARequiredField),
    })
  );

export const TwoFAPage = () => {
  const [preferred_2fa_type, set_preferred_2fa_type] =
    useState<TwoFactorTypes>("sms");
  const [
    is_submitting_initial_verification,
    set_is_submitting_initial_verification,
  ] = useState(false);
  const [query] = useQueryParams({
    redirect_url: StringParam,
  });
  const [is_2fa_auth_setup_completed, set_is_2fa_auth_setup_completed] =
    useState(false);
  const [logged_in_user, set_logged_in_user] = useState<User>();
  const [totp_uri, set_totp_uri] = useState<string>();
  const [totp_recovery_code, set_totp_recovery_code] = useState<string>();
  const [can_redirect, set_can_redirect] = useState(false);
  const [is_submitting_2fa_code, set_is_submitting_2fa_code] = useState(false);
  const { t } = useTranslation();
  const { notifyError } = useNotifications();
  const routePaths = useRoutePath();
  const theme = useTheme();
  const { logout, updateUser } = useAuthContext();
  const { storefront_id } = useStoreState();
  const { storePath } = useRoutePath();
  const location = useLocation();

  const totp_details = location.state as TOTPSchema | null;

  const user_phone_number = totp_details?.sms_2fa_phone_number ?? "";
  const user_preferred_2fa_type = totp_details?.preferred_2fa_type ?? null;

  const {
    control: phone_number_form_control,
    errors: phone_number_form_errors,
    formState: phone_number_form_state,
    handleSubmit: phone_number_form_handle_submit,
    register: phone_number_form_register,
  } = useFormWrapper({
    defaultValues: {
      country_code: getPhoneCodeOption(getCountryCode(user_phone_number)),
      phone_number: getPhoneNumber(user_phone_number),
    },
    resolver: yupResolver(PhoneSchema(t)),
  });

  const {
    errors: tfa_code_form_errors,
    formState: tfa_code_formState,
    handleSubmit: tfa_code_form_handle_submit,
    register: tfa_code_form_register,
  } = useFormWrapper({
    resolver: zodResolver(
      z.object({
        "2fa_code": zodRequiredString(t),
      })
    ),
  });

  const handle_preferred_2fa_type_radio_change = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    set_preferred_2fa_type(value as TwoFactorTypes);
    if (value === "totp" && !totp_uri) {
      await get_totp_uri();
    }
  };

  const on_submit_phone_number = async ({
    country_code,
    phone_number,
  }: FormInput) => {
    set_is_submitting_initial_verification(true);
    try {
      await axios.post<User>("/v1/users/two-factor-auth-login", {
        sms_phone_number: `${country_code.value}${phone_number}`,
        two_factor_auth_type: preferred_2fa_type,
        ...(query?.redirect_url && { redirect_uri: query.redirect_url }),
      });
      set_is_2fa_auth_setup_completed(true);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("2FA initialisation failed, please try again later."),
        {
          error,
        }
      );
    } finally {
      set_is_submitting_initial_verification(false);
    }
  };

  const on_submit_2fa_code = async ({
    "2fa_code": code,
  }: {
    "2fa_code": string;
  }) => {
    set_is_submitting_2fa_code(true);
    try {
      await axios.get("/v1/users/two-factor-auth-login", {
        params: {
          two_factor_auth_type: preferred_2fa_type,
          code,
          redirect_uri: `${window.location.origin}${routePaths.storePath}/login`,
        },
        maxRedirects: 0,
      });
      const { data: user } = await axios.get<User>(
        endpoints.v1_storefronts_id_users_login(storefront_id)
      );
      updateUser(user);
      set_logged_in_user(user);
      set_can_redirect(true);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not confirm the 2FA code, please try again later"),
        {
          error,
        }
      );
    } finally {
      set_is_submitting_2fa_code(false);
    }
  };

  const get_totp_uri = useCallback(async () => {
    const { data } = await axios.post<{
      totp_uri: "string";
      recovery_code: "string";
    }>("/v1/users/totp-keys");
    set_totp_uri(data.totp_uri);
    set_totp_recovery_code(data.recovery_code);
  }, []);

  const initiate_totp_verification = useCallback(async () => {
    set_is_submitting_initial_verification(true);
    try {
      await axios.post<User>("/v1/users/two-factor-auth-login", {
        two_factor_auth_type: "totp",
        ...(query?.redirect_url && { redirect_uri: query.redirect_url }),
      });
      set_is_2fa_auth_setup_completed(true);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("2FA initialisation failed, please try again later."),
        {
          error,
        }
      );
    } finally {
      set_is_submitting_initial_verification(false);
    }
  }, [notifyError, query.redirect_url, t]);

  function handle_logout() {
    logout()
      .then(() => window.location.replace(`${routePaths.storePath}/login`))
      .catch(() => {
        window.location.replace(`${routePaths.storePath}/login`);
      });
  }

  useEffect(() => {
    if (user_preferred_2fa_type) {
      set_preferred_2fa_type(
        user_preferred_2fa_type as "totp" | "sms" | "voice"
      );
    }
  }, [user_preferred_2fa_type]);

  useEffect(() => {
    if (
      user_preferred_2fa_type === "totp" &&
      !is_2fa_auth_setup_completed &&
      totp_details &&
      totp_details.has_totp_key
    ) {
      initiate_totp_verification();
    }
  }, [
    initiate_totp_verification,
    is_2fa_auth_setup_completed,
    totp_details,
    user_preferred_2fa_type,
  ]);

  useEffect(() => {
    if (
      user_preferred_2fa_type === "totp" &&
      totp_details &&
      !totp_details.has_totp_key &&
      !totp_uri
    ) {
      get_totp_uri();
    }
  }, [get_totp_uri, totp_details, totp_uri, user_preferred_2fa_type]);

  if (can_redirect && logged_in_user) {
    if (query?.redirect_url) {
      window.open(query?.redirect_url, "_blank");
    }
    return redirectByRole({
      user: logged_in_user,
      routePaths: routePaths,
    });
  }

  if (!totp_details) {
    return <Redirect to={`${storePath}/login`} />;
  }

  return (
    <AuthPage>
      <AuthWrapper style={{ padding: "16px" }}>
        <AuthLogoWrapper />
        {!Boolean(user_preferred_2fa_type) && (
          <TwoFaWrapper>
            <H6Bold style={{ margin: 0 }}>
              {t("Choose your preferred 2FA method")}
            </H6Bold>
            <RadioButtonContainer>
              <RadioButton
                name="preferred_2fa_type"
                value="totp"
                optionTitle={t("Authenticator app")}
                color={
                  preferred_2fa_type === "totp"
                    ? theme.primaryTextColor
                    : theme.secondaryTextColor
                }
                handleChange={handle_preferred_2fa_type_radio_change}
                checked={preferred_2fa_type === "totp"}
                marginRight={4}
              />
              <RadioButton
                name="preferred_2fa_type"
                value="sms"
                optionTitle={t("SMS")}
                color={
                  preferred_2fa_type === "sms"
                    ? theme.primaryTextColor
                    : theme.secondaryTextColor
                }
                handleChange={handle_preferred_2fa_type_radio_change}
                checked={preferred_2fa_type === "sms"}
                marginRight={4}
              />
              <RadioButton
                name="preferred_2fa_type"
                value="voice"
                optionTitle={t("Voice")}
                color={
                  preferred_2fa_type === "voice"
                    ? theme.primaryTextColor
                    : theme.secondaryTextColor
                }
                handleChange={handle_preferred_2fa_type_radio_change}
                checked={preferred_2fa_type === "voice"}
              />
            </RadioButtonContainer>
          </TwoFaWrapper>
        )}
        {preferred_2fa_type !== "totp" ? (
          <>
            <TwoFAForm
              noValidate
              onSubmit={phone_number_form_handle_submit(on_submit_phone_number)}
            >
              <Flex>
                <Flex1>
                  <Controller
                    as={SelectBoxV2}
                    control={phone_number_form_control}
                    name="country_code"
                    autoComplete="countryCode"
                    placeholder={t("Country Code")}
                    id="countryCodeSelectBox"
                    options={getPhoneCodesOptions()}
                    rules={{
                      required: true,
                    }}
                    errors={phone_number_form_errors}
                    formState={phone_number_form_state}
                  />
                </Flex1>
                <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                  <TextField
                    name="phone_number"
                    label={t("Phone Number")}
                    theref={phone_number_form_register({
                      required: true,
                    })}
                    formState={phone_number_form_state}
                    errors={phone_number_form_errors}
                    type="tel"
                    readOnly={Boolean(totp_details.sms_2fa_phone_number)}
                  />
                </Flex2>
              </Flex>
              <SubmitButtonContainer>
                <PrimaryButtonMedium
                  disabled={is_2fa_auth_setup_completed}
                  loading={is_submitting_initial_verification}
                >
                  {t("Initiate verification")}
                </PrimaryButtonMedium>
              </SubmitButtonContainer>
            </TwoFAForm>
          </>
        ) : (
          <TOTPWrapper>
            {!Boolean(user_preferred_2fa_type) || !totp_details.has_totp_key ? (
              <>
                <SoftHeader2>
                  {t(
                    "Scan the QR code below with your authenticator app, then initiate verification"
                  )}
                </SoftHeader2>
                {totp_uri && <QRCode value={totp_uri} />}
                {totp_recovery_code && (
                  <>
                    <SoftHeader2>
                      {t("This recovery code will allow to reset your 2fa:")}
                    </SoftHeader2>
                    <H6Bold>{totp_recovery_code}</H6Bold>
                  </>
                )}
                <SubmitButtonContainer>
                  <PrimaryButtonMedium
                    disabled={is_2fa_auth_setup_completed}
                    loading={is_submitting_initial_verification}
                    onClick={initiate_totp_verification}
                  >
                    {t("Initiate verification")}
                  </PrimaryButtonMedium>
                </SubmitButtonContainer>
              </>
            ) : (
              <>
                {is_submitting_initial_verification && (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <LoadingIcon
                      width={15}
                      height={15}
                      fill={theme.primaryButtonTextColor}
                    />
                    <span>
                      {t("Please wait, verifying your 2FA authentication...")}
                    </span>
                  </div>
                )}
              </>
            )}
          </TOTPWrapper>
        )}
        {is_2fa_auth_setup_completed && (
          <TwoFAForm
            style={{ marginTop: "16px" }}
            noValidate
            onSubmit={tfa_code_form_handle_submit(on_submit_2fa_code)}
          >
            <SoftHeader2>
              {preferred_2fa_type === "totp"
                ? t(
                    "Please enter the code from your authenticator app (Or your recovery code)"
                  )
                : t("Please enter the code sent to your phone")}
            </SoftHeader2>
            <TextField
              name={"2fa_code"}
              label={t("2FA Code")}
              theref={tfa_code_form_register({
                required: true,
              })}
              formState={tfa_code_formState}
              errors={tfa_code_form_errors}
              type="text"
            />
            <SubmitButtonContainer style={{ display: "flex", gap: "8px" }}>
              <PrimaryButtonMedium loading={is_submitting_2fa_code}>
                {t("Send code")}
              </PrimaryButtonMedium>
              <TextButton>
                <span onClick={handle_logout}>{t("Retry Login")}</span>
              </TextButton>
            </SubmitButtonContainer>
          </TwoFAForm>
        )}
      </AuthWrapper>
    </AuthPage>
  );
};
