import styled from "styled-components";
import type { ProductDocument } from "../../types/types";
import { SoftHeader2 } from "../Typography/Typography";
import { useTranslation } from "react-i18next";
import { InvisibleButton } from "../Buttons/Buttons";
import { FileType } from "../FileCard/FileCard";

const TaxExemptDocumentSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: top;
  svg {
    margin-top: "-10px";
  }
`;

export const TaxExemptDocument = ({
  document,
}: {
  document: ProductDocument;
}) => {
  const { t } = useTranslation();
  return (
    <TaxExemptDocumentSection>
      <SoftHeader2>{t("Tax Exempt (PDF)")}</SoftHeader2>
      <InvisibleButton
        type="button"
        onClick={(e) => {
          e.preventDefault();
          window.open(document.signed_url, "_blank");
        }}
        title={document.name}
        style={{
          paddingRight: "0px",
        }}
      >
        {/* not translated because its a file extension */}
        <FileType style={{ marginRight: "0px" }}>PDF</FileType>
      </InvisibleButton>
    </TaxExemptDocumentSection>
  );
};
