import React, { useCallback, useState, useEffect, useContext } from "react";
import Axios from "axios";
import styled, { ThemeContext } from "styled-components/macro";
import { XIcon, LoadingIcon } from "../../../../components/Icons/Icons";
import { useDropzone } from "react-dropzone";
import { SecondaryButtonSmall } from "../../../../components/Buttons/Buttons";
import { ConfirmDialog } from "../../../../components/ConfirmDialog/ConfirmDialog";
import type { ProductDocument } from "../../../../types/types";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../../../util/util";

const UploadWrapper = styled.div`
  padding: 18px 25px 20px;
  margin: 10px 0 30px !important;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: 13px;
  background: ${({ theme }) => theme.primaryBG};
  min-height: 115px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;

const FileWrapper = styled.div`
  padding: 10px 15px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 4px;
  color: ${({ theme }) => theme.disabledButtonBG};
  font-size: 14px;
  background: ${({ theme }) => theme.primaryBG};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const FileType = styled.span`
  display: inline-block;
  padding: 1px 4px 1px 6px;
  margin-right: 10px;
  font-size: 10px;
  color: ${({ theme }) => theme.primaryBG};
  background: ${({ theme }) => theme.brandColor};
  text-transform: uppercase;
`;

export const FileName = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.primaryTextColor};
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  min-width: 100px;
  & > * {
    margin-left: 15px;
    cursor: pointer;
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: 12px;
  margin-bottom: 20px !important;
`;

const FileError = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: 10px;
`;

interface IFileUpload {
  id: string;
  documents: any;
  type: string;
}

export const POFileUpload = (props: IFileUpload) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const [documents, setDocuments] = useState<any>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [selectedDocument, setSelectedDocument] = useState<any>({});
  const [docType] = useState<string>("tds");
  const [showTypeError, setShowTypeError] = useState<boolean>(false);
  // Confirm Dialog
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const [confirmId, setConfirmId] = useState(0);
  const [loadViewDoc, setLoadViewDoc] = useState(false);
  //Theme Object
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();

  const closeDialog = () => {
    setShowConfirmDialog(false);
    setConfirmDialogMessage("");
  };

  const confirmDialog = (message: string, id: number) => {
    setConfirmDialogMessage(message);
    setConfirmId(id);
    setShowConfirmDialog(true);
  };

  const onDrop = useCallback((files: any) => {
    setSelectedDocument(files[0]);
  }, []);

  const deleteDocument = (id: number) => {
    confirmDialog(t(`Are you sure you want to delete this document?`), id);
  };

  const confirmDelete = (id: number) => {
    setShowConfirmDialog(false);
    Axios.delete(
      `/v1/storefronts/${storefront_id}/orders/${props.id}/documents/${id}`
    ).then((response) => {
      if (response.status === 200 && response.data === 204) {
        setUploadedDocuments(
          uploadedDocuments.filter((doc: any) => doc.id !== id)
        );
        notifySuccess(t("Document has been removed successfully."));
      }
    });
  };

  const upload = useCallback(
    (file: any) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (docType === "" && file.name) {
        setShowTypeError(true);
      } else if (docType !== "" && selectedDocument.name) {
        setShowTypeError(false);
        setDocuments([file, ...documents]);
        setSelectedDocument({});
        const formData = new FormData();
        formData.append("file", file);
        Axios.patch(
          `/v1/storefronts/${storefront_id}/orders/${props.id}/documents/${props.type}`,
          formData,
          config
        )
          .then((response) => {
            setUploadedDocuments([response.data, ...uploadedDocuments]);
            setDocuments(
              documents.filter(
                (doc: ProductDocument) => doc.name !== response.data.name
              )
            );
          })
          .catch((error) => {
            setDocuments(
              documents.filter((doc: ProductDocument) => doc.name !== file.name)
            );
            const failedFile = file;
            if (
              error.response.status === 400 &&
              error.response.data.error.indexOf("400") === 0
            ) {
              notifyError(t("Upload failed, document format not supported."));

              failedFile.error = t("Not supported.");
              setUploadedDocuments([failedFile, ...uploadedDocuments]);
            } else if (
              error.response.status === 400 &&
              error.response.data.error.indexOf("413") === 0
            ) {
              notifyError(t("Upload failed, document is too large."));
              failedFile.error = t("Document too large");
              setUploadedDocuments([failedFile, ...uploadedDocuments]);
            } else {
              notifyError(t("Upload failed, something went wrong."));
              failedFile.error = t("Error!");
              setUploadedDocuments([failedFile, ...uploadedDocuments]);
            }
          });
      }
    },
    [
      docType,
      selectedDocument.name,
      documents,
      storefront_id,
      props.id,
      props.type,
      uploadedDocuments,
      notifyError,
      t,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (selectedDocument.name) {
      upload(selectedDocument);
    }
  }, [selectedDocument, docType, upload]);

  useEffect(() => {
    setUploadedDocuments(props.documents);
  }, [props.documents]);

  const viewDocument = async (id: string) => {
    setLoadViewDoc(true);
    try {
      const { data } = await Axios.get(
        `/v1/storefronts/${storefront_id}/orders/${props.id}/documents/${id}`
      );
      window.open(data.signed_url, "_blank");
      setLoadViewDoc(false);
    } catch (error) {
      setLoadViewDoc(false);
      notifyError("Operation failed, something went wrong.");
    }
  };

  return (
    <>
      {showTypeError && (
        <ErrorMessage>{t("*Select document type to upload")}</ErrorMessage>
      )}

      {uploadedDocuments.length === 0 && documents.length === 0 && (
        <UploadWrapper {...getRootProps()}>
          <input
            {...getInputProps()}
            disabled={uploadedDocuments.length > 0}
            multiple={false}
          />
          {isDragActive ? (
            <p>{t("Drop the document here ...")}</p>
          ) : (
            <>
              {docType === "" && selectedDocument.name ? (
                <>
                  <FileName>
                    <FileType>
                      {selectedDocument.name.split(".").pop()}
                    </FileType>
                    <>{selectedDocument.name}</>
                  </FileName>
                </>
              ) : (
                <>
                  {t("Drag and drop document here or")},
                  <br />
                  <SecondaryButtonSmall
                    onClick={(e) => e.preventDefault()}
                    style={{ marginTop: "10px" }}
                  >
                    {t("Click to select document")}
                  </SecondaryButtonSmall>{" "}
                </>
              )}
            </>
          )}
        </UploadWrapper>
      )}
      {uploadedDocuments.length === 0 &&
        documents.map((doc: any, index: number) => {
          return (
            <FileWrapper key={index}>
              <FileName>
                <FileType>{doc.name.split(".").pop()}</FileType>
                <>{doc.name}</>
              </FileName>
              <>
                <LoadingIcon width={22} height={22}></LoadingIcon>
                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                  {t("Uploading...")}
                </span>
              </>
            </FileWrapper>
          );
        })}
      {uploadedDocuments.length > 0 && (
        <FileWrapper>
          <FileName>
            <FileType>{uploadedDocuments[0].name.split(".").pop()}</FileType>
            <>{uploadedDocuments[0].name}</>
          </FileName>
          <Actions>
            {uploadedDocuments[0].error ? (
              <FileError>{uploadedDocuments[0].error}</FileError>
            ) : (
              <>
                <SecondaryButtonSmall
                  onClick={() => viewDocument(uploadedDocuments[0].id)}
                >
                  {!loadViewDoc && <>{t("View")}</>}
                  {loadViewDoc && <>{t("Fetching..")}</>}
                </SecondaryButtonSmall>
                <span onClick={() => deleteDocument(uploadedDocuments[0].id)}>
                  <XIcon
                    width={20}
                    height={20}
                    fill={theme.colors.errorRedBorder}
                  ></XIcon>
                </span>
              </>
            )}
          </Actions>
        </FileWrapper>
      )}
      <ConfirmDialog
        show={showConfirmDialog}
        confirmMessage={confirmDialogMessage}
        handleConfirm={() => confirmDelete(confirmId)}
        closeDialog={closeDialog}
      />
    </>
  );
};
