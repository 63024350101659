import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { H3 } from "../../../../../../components/Typography/Typography";
import { useEffect, useMemo, useState } from "react";
import type { PIMProductUploadSchema } from "../../../../../../types/types.PIM";
import { IDMobile } from "../../../../../../components/IDs/IDs";
import type { ProductStatusColor } from "../../../../../../components/Status/Status";
import { StatusLeft } from "../../../../../../components/Status/Status";
import { InvisibleButton } from "../../../../../../components/Buttons/Buttons";
import { DownloadIcon } from "../../../../../../components/Icons/Icons";
import type { SortingRule } from "react-table";
import {
  defaultHandleSort,
  formatDateTime,
  useStoreState,
} from "../../../../../../util/util";
import useSWR from "swr";
import type { WithPagination } from "../../../../../../types/types";
import { endpoints } from "../../../../../../endpoints";
import { DropdownContainer } from "../../../../../../components/Layout/Layout";
import { DropDown } from "../../../../../../components/DropDown/DropDown";
import { Table } from "../../../../../../components/Table/Table";
import { ContentWrapper } from "../../../../../../layout/portalPageLayout";
import { Pagination } from "../../../../../../components/Pagination/Pagination";

const getStatusColor = (
  status: PIMProductUploadSchema["status"]
): ProductStatusColor => {
  switch (status) {
    case "failed":
      return "red";
    case "in_progress":
      return "yellow";
    case "successful":
      return "green";
  }
};

const getStatusText = (
  status: PIMProductUploadSchema["status"],
  t: TFunction
): string => {
  switch (status) {
    case "failed":
      return t("Failed");
    case "in_progress":
      return t("In Progress");
    case "successful":
      return t("Successful");
  }
};

export const PreviousUploadsTable = () => {
  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "desc",
  });
  const [tableData, setTableData] = useState<PIMProductUploadSchema[]>([]);
  const [offset, setOffset] = useState(0);
  const perPageItems = [10, 20, 50];
  const [perPage, setPerPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });

  const { tenant_id } = useStoreState();
  const { t } = useTranslation();

  const tableColumns = useMemo(
    () => [
      {
        Header: t("Upload ID"),
        accessor: "upload_number",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => <IDMobile>{value}</IDMobile>,
      },
      {
        Header: t("Template Name"),
        accessor: "template_name",
      },
      {
        Header: t("Uploaded on"),
        accessor: "created_at",
      },
      {
        Header: t("Uploaded By"),
        accessor: "created_by",
      },
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,
        Cell: ({
          value: status,
        }: {
          value: PIMProductUploadSchema["status"];
        }) => (
          <StatusLeft
            color={getStatusColor(status)}
            text={getStatusText(status, t)}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
        ),
      },
      {
        Header: "",
        accessor: "upload_log_url",
        Cell: ({ value: link }: { value: string | undefined }) =>
          link ? (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", width: "fit-content" }}
            >
              <InvisibleButton title={t("Log report")}>
                <DownloadIcon width={16} height={16} />
              </InvisibleButton>
            </a>
          ) : (
            <></>
          ),
      },
    ],
    [t]
  );

  const { data: previousUploadsResponse, error } = useSWR<
    WithPagination<{ data: PIMProductUploadSchema[] }>
  >([
    endpoints.v2_tenants_tenant_id_product_uploads(tenant_id),
    useMemo(
      () => ({
        params: {
          offset,
          limit: perPage,
          ...(sortingRules.sortBy ? { sort_by: sortingRules.sortBy } : {}),
          order_by: sortingRules.orderBy ?? "desc",
        },
      }),
      [offset, perPage, sortingRules.orderBy, sortingRules.sortBy]
    ),
  ]);

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const isLoading = !previousUploadsResponse && !error;

  useEffect(() => {
    const handlePreviousUpdatesData = ({
      data,
      pagination,
    }: WithPagination<{ data: PIMProductUploadSchema[] }>) => {
      setTableData(
        data.map(
          ({
            id,
            upload_number,
            template_name,
            created_at,
            created_by,
            status,
            upload_log_url,
          }) => ({
            id,
            upload_number,
            template_name,
            created_at: formatDateTime(created_at),
            created_by,
            status,
            upload_log_url,
          })
        )
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };
    if (previousUploadsResponse) {
      handlePreviousUpdatesData(previousUploadsResponse);
    }
  }, [perPage, previousUploadsResponse]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <H3 style={{ margin: 0 }}>{t("Previous Uploads")}</H3>
      <div>
        <DropdownContainer>
          <DropDown
            items={perPageItems}
            activeItem={perPage}
            textLeft={t("items") + ":"}
            textRight={t(" Per Page")}
            direction={"right"}
            className={"per_Page"}
            clickHandler={changePerPage}
          />
        </DropdownContainer>
        <ContentWrapper>
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
            error={error}
            handleSort={handleSort}
            lastChildleftAlign={true}
          />
          <Pagination
            pagination={tablePagination}
            offset={offset}
            handlePageClick={changePage}
          />
        </ContentWrapper>
      </div>
    </div>
  );
};
