import React, { useContext } from "react";
import type { DeepMap, FieldError, FormState } from "react-hook-form";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import styled, { ThemeContext } from "styled-components/macro";
import type { OptionType } from "../../types/types";
import {
  displayFieldErrorMessage,
  showErrors,
} from "../../util/util-components";
import { SearchIcon } from "../Icons/Icons";
import { useTranslation } from "react-i18next";

/* 
  This should be used as a controlled input inside of a <form> tag
  managed by react-hook-form (just like SelectBoxV2).

          <Controller
            as={SelectBoxV2}
            control={control}
            name="product"
            placeholder="Product Name"
            options={products}
            rules={{ required: true }}
            errors={errors}
            formState={formState}
          />
*/

interface AsyncSearchSelectProps {
  formState: FormState<Record<string, any>>;
  errors: DeepMap<Record<string, any>, FieldError>;
  error?: FieldError;
  name: string;
  placeholder: string;
  searchFunction:
    | ((query: string) => Promise<OptionType[]>)
    | ((query: string, callback: (options: OptionType[]) => void) => void);
  [x: string]: any;
  onChange?: any;
  options?: OptionType[];
}

const { ValueContainer, Placeholder } = components;

const InterDiv = styled.div`
  font-family: "Inter";
  margin-top: 6px;
`;

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <InterDiv>
        <Placeholder
          {...props}
          isFocused={props.isFocused}
          style={{ marginTop: "-5px" }}
        >
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </InterDiv>
    </ValueContainer>
  );
};

// Its not clear to me from the docs what props
// react select adds here.
const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    )
  );
};

const Menu = (props: any) => (
  <InterDiv>
    <components.Menu {...props}>{props.children}</components.Menu>
  </InterDiv>
);
/**
 * @deprecated
 * dwswin favor of `SearchSelectInfiniteScroll
 */
export function AsyncSearchSelect({
  formState,
  errors,
  error,
  name,
  searchFunction,
  onChange,
  options,
  value,
  ...rest
}: AsyncSearchSelectProps) {
  const { submitCount } = formState;
  const styledTheme = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <div style={{ position: "relative" }} data-testid={rest.testid}>
      <AsyncSelect
        onChange={onChange}
        loadOptions={searchFunction}
        defaultOptions={options}
        defaultValue={value}
        cacheOptions
        className="basic-single"
        classNamePrefix="select"
        components={{
          ValueContainer: CustomValueContainer,
          IndicatorSeparator: () => null,
          Menu: Menu,
          DropdownIndicator,
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary:
              submitCount > 0 && (error || errors[name])
                ? styledTheme.errorColor
                : styledTheme.tertiaryBG,
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            ...(submitCount > 0 &&
              (error || errors[name]) && {
                border: `2px solid ${styledTheme.errorColor}`,
                "&:hover": {
                  border: `2px solid ${styledTheme.errorColor}`,
                },
              }),
            height: 50,
            minHeight: 50,
          }),
          // dropdownIndicator: (base, state) => ({
          //   ...base,
          //   color:
          //     submitCount > 0 && errors[name]
          //       ? styledTheme.errorColor
          //       : "#808080",
          // }),
          option: (base, state) => ({
            ...base,
            color: styledTheme.primaryTextColor,
            backgroundColor:
              state.isFocused || state.isSelected
                ? styledTheme.secondaryButtonBG
                : styledTheme.primaryBG,
            ":hover": {
              backgroundColor: styledTheme.secondaryButtonBG,
            },
          }),
          valueContainer: (provided: any, state: any) => ({
            ...provided,
            overflow: "visible",
            marginTop: state.isSelected ? "0px" : "9px",
          }),
          placeholder: (provided: any, state: any) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? 2 : "40%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 11,
            marginBottom: 2,
            color:
              submitCount > 0 && (error || errors[name])
                ? styledTheme.errorColor
                : "#808080",
          }),
        }}
        {...rest}
      />
      {showErrors({ errors, name, t })}
      {error && displayFieldErrorMessage({ error })}
    </div>
  );
}
