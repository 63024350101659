import React from "react";
import { SellerOrdersListPage } from "../../SharedPages/SellerOrdersListPage/SellerOrdersListPage";

/**
 * Page component for "Orders", a list of orders as viewed by a seller (as
 * opposed to a buyer).  Used by seller and seller admin accounts.
 */
export const SellerOrdersList = () => {
  return <SellerOrdersListPage />;
};
