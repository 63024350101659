import type { IOrderItem } from "../../types/types";
import React from "react";
import { TransactionItem } from "./TransactionItem";
import { TransactionItemInfoBlockPrice } from "./TransactionItemInfoBlockPrice";
import { TransactionItemTopLeft } from "./TransactionItemTopLeft";
import { TransactionItemInfoBlockQuantity } from "./TransactionItemInfoBlockQuantity";

/**
 * Transaction item card for orders. (It's the same thing for buyer and seller.)
 */
export const OrderItem = ({
  item,
  index,
  t,
}: {
  item: IOrderItem;
  index: number;
  t: (s: string) => string;
}) => {
  return (
    <TransactionItem
      topLeft={<TransactionItemTopLeft item={item} index={index} />}
      topRightCol1={<TransactionItemInfoBlockQuantity item={item} t={t} />}
      topRightCol2={<TransactionItemInfoBlockPrice item={item} />}
      hideSeparatorLine={true}
    />
  );
};
