import { zodAddressDefault, zodOptionalAddress } from "./../../util/zod.util";
import { z } from "zod";
import {
  zodOptionalSelectBoxDefault,
  zodOptionalString,
  zodRequiredString,
  zodSelectBoxDefault,
} from "../../util/zod.util";
import { strings } from "../../util/strings";
import { isValidPhoneNumber } from "../../util/util";
import type {
  Alpha2,
  CurrencyCode,
  ITenantCustomerSettingsCreation,
  Lead,
  OptionType,
  SampleRequestStageTwo,
  SupportedLanguage,
  Tenant,
} from "../../types/types";
import type { TFunction } from "react-i18next";
/**
 * This is used for schema validation
 */
export const CreateNewTenantSchema = (
  t: TFunction,
  mandatory_fields: boolean
) =>
  z
    .object({
      name: zodRequiredString(t),
      contact_first_name: zodRequiredString(t),
      contact_last_name: zodRequiredString(t),
      contact_email: z.string().email(),
      country_code: zodSelectBoxDefault(t),
      contact_phone: z.string().min(1, strings(t).phoneNumberMustBeValid),
      ...(mandatory_fields ? zodAddressDefault(t) : zodOptionalAddress),
      account_manager: zodSelectBoxDefault(t),
      customer_service_rep: zodSelectBoxDefault(t),
      currency: zodSelectBoxDefault(t),
      preferred_language: z.object({
        label: z.string(),
        value: z.string(),
      }),
      payment_term: mandatory_fields
        ? zodSelectBoxDefault(t)
        : zodOptionalSelectBoxDefault,
      payment_mode: mandatory_fields
        ? zodSelectBoxDefault(t)
        : zodOptionalSelectBoxDefault,
      delivery_term: mandatory_fields
        ? zodSelectBoxDefault(t)
        : zodOptionalSelectBoxDefault,
      doing_business_as: mandatory_fields
        ? zodRequiredString(t)
        : zodOptionalString,
      external_id: zodOptionalString,
      type: zodOptionalString,
    })
    .refine(
      ({ contact_phone, country_code }) => {
        return isValidPhoneNumber({
          phone_number: contact_phone,
          country_code,
        });
      },
      { message: strings(t).phoneNumberMustBeValid, path: ["contact_phone"] }
    );

/**
 * This is used for schema validation
 */
export const CreatePIMEditionNewTenantSchema = (
  t: TFunction,
  mandatory_fields: boolean
) =>
  z
    .object({
      name: zodRequiredString(t),
      contact_first_name: zodRequiredString(t),
      contact_last_name: zodRequiredString(t),
      contact_email: z.string().email(),
      country_code: zodSelectBoxDefault(t),
      contact_phone: z.string().min(1, strings(t).phoneNumberMustBeValid),
      account_manager: zodOptionalSelectBoxDefault,
      customer_service_rep: zodOptionalSelectBoxDefault,
      preferred_language: z.object({
        label: z.string(),
        value: z.string(),
      }),
      doing_business_as: mandatory_fields
        ? zodRequiredString(t)
        : zodOptionalString,
      external_id: zodOptionalString,
      type: zodOptionalString,
    })
    .refine(
      ({ contact_phone, country_code }) => {
        return isValidPhoneNumber({
          phone_number: contact_phone,
          country_code,
        });
      },
      { message: strings(t).phoneNumberMustBeValid, path: ["contact_phone"] }
    );
// I'm leaving this here in this file because I think it will change
type AccountType = "Buyer" | "Seller" | "Distributor";

export interface PimEditionCreateTenantPOST {
  name: string;
  type: AccountType;
  doing_business_as: string | null;
  external_id: string | null;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  contact_phone: string;
  preferred_language: SupportedLanguage | string;
  lead_id?: string; // Required when converting from lead to tenant/customer.
  sample_request_id?: string;
}

export type PimEditionFormValue = {
  name: string;
  type: AccountType;
  doing_business_as: string | null;
  external_id: string | null;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  contact_phone: string;
  country_code: OptionType<string>;
  preferred_language: OptionType<SupportedLanguage>;
  account_manager: OptionType<string>;
  customer_service_rep: OptionType<string>;
  sample_request_id?: string;
};

export interface CreateTenantPOST {
  name: string;
  type: AccountType;
  address1: string | null;
  address2: string | null;
  country: Alpha2 | null;
  state: string | null;
  county: string | undefined;
  city: string | null;
  doing_business_as: string | null;
  external_id: string | null;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  contact_phone: string;
  preferred_language: SupportedLanguage | string;
  postal_code: string | null; // I think internationally this isn't alway a number.
  lead_id?: string; // Required when converting from lead to tenant/customer.
  sample_request_id?: string;
  settings: ITenantCustomerSettingsCreation;
}

export type CreateTenantFormValue = {
  name: string;
  type: AccountType;
  address1: string | null;
  address2: string | null;
  country: OptionType<Alpha2> | null;
  state: OptionType<string> | null;
  county: string | undefined;
  city: string | null;
  doing_business_as: string | null;
  external_id: string | null;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  contact_phone: string;
  country_code: OptionType<string>;
  preferred_language: OptionType<SupportedLanguage>;
  postal_code: string | null; // I think internationally this isn't alway a number.
  account_manager: OptionType<string>;
  customer_service_rep: OptionType<string>;
  currency: OptionType<CurrencyCode>;
  payment_term: OptionType<string>;
  payment_mode: OptionType<string>;
  delivery_term: OptionType<string>;
  sample_request_id?: string;
};

export interface patchTenantSettings {
  tenantSettings: ITenantCustomerSettingsCreation;
  tenantId: string;
}

export interface CreateNewTenantProps {
  tenant: Tenant;
  lead?: Lead | SampleRequestStageTwo;
  onSuccess: (tenantId: string) => void;
  sampleRequestMode?: true;
  countries: OptionType<string>[];
  userOptions: OptionType<string>[];
  deliveryTermsOptions: OptionType<string>[];
  paymentTermsOptions: OptionType<string>[];
  paymentModesOptions: OptionType<string>[];
  originalStatesList: any;
  type: "Buyer" | "Distributor";
}

/**
 * appease typescript and return the correct phone number regardless of what
 * schema we're dealing with.
 * @param lead
 */
export function getCorrectPhoneNumber(
  lead: Lead | SampleRequestStageTwo | undefined
): string | undefined {
  if (!lead) {
    // Lead might not exist because this form is used to create a tenant without
    // the lead to prefill some values.
    return "";
  } else if ("buyer_phone" in lead) {
    // sample request
    return lead.buyer_phone;
  } else if ("buyer_phone_number" in lead) {
    // "lead"
    return lead.buyer_phone_number;
  }
}

export function getCorrectCountry(
  lead: Lead | SampleRequestStageTwo | undefined
): string | null {
  if (!lead) {
    // Lead might not exist because this form is used to create a tenant without
    // the lead to prefill some values.
    // else if ("billing_address" in lead) {
    //   return lead.billing_address?.country ?? null;
    // }
    return null;
  } else if ("billing_address" in lead && lead.billing_address !== null) {
    // lead
    return lead.billing_address?.country ?? null;
  } else if ("address" in lead) {
    // lead
    return lead.address?.country ?? null;
  } else {
    // "sample request
    return lead.buyer_address.country;
  }
}

export function getCorrectState(
  lead: Lead | SampleRequestStageTwo | undefined
): string | null {
  if (!lead) {
    // Lead might not exist because this form is used to create a tenant without
    // the lead to prefill some values.
    return null;
  } else if ("billing_address" in lead && lead.billing_address !== null) {
    return lead.billing_address?.state ?? null;
  } else if ("address" in lead) {
    return lead.address?.state ?? null;
  } else {
    // "sample request
    return lead.buyer_address.state;
  }
}
