import React from "react";
import { LeadsPage } from "../../../SharedPages/LeadsPage/LeadsPage";

/**
 * The "Leads" page that displays a list of quote requests, registrations,
 * contact us, etc.
 */
export function SellerAdminLeadsList() {
  return <LeadsPage />;
}
