import React, { useState } from "react";
import type {
  LeadAssignee,
  OptionType,
  UUID,
  User,
} from "../../../types/types";
import {
  convertUserToOption,
  useFormWrapper,
  useStoreState,
} from "../../../util/util";
import { Form } from "../../../layout/FormLayout";
import axios from "axios";
import { endpoints } from "../../../endpoints";
import { useTranslation } from "react-i18next";
import { PrimaryButtonFitContainer } from "../../../components/Buttons/Buttons";
import { SearchSelectInfiniteScroll } from "../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { Controller } from "react-hook-form";

type AssignLeadToUsersProps = {
  onSuccess: (state: "assigned" | "unassigned") => void;
  onError: (error: unknown) => void;
  /**
   * The URL to send the assign the lead to the user. Different for different
   * types of leads.
   */
  url: string;
  prefilledUser?: LeadAssignee | null;
};

/**
 * onSuccess
 * onError
 * url
 * list of users?
 */
export function AssignLeadToUser({
  onSuccess,
  onError,
  url,
  prefilledUser,
}: AssignLeadToUsersProps) {
  const { handleSubmit, setValue, errors, formState, register, control } =
    useFormWrapper({
      defaultValues: {
        user: prefilledUser ? convertUserToOption(prefilledUser) : null,
      },
    });
  const { tenant_id, storefront_id } = useStoreState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();

  const onSubmit = async ({ user }: { user: OptionType<UUID> | null }) => {
    try {
      setIsSubmitting(true);
      await axios.patch(url, { id: user?.value ?? null });
      onSuccess(user?.value ? "assigned" : "unassigned");
    } catch (error) {
      onError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h1>{t("Assign Lead To User")}</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          as={SearchSelectInfiniteScroll}
          name={"user"}
          params={(() => {
            const params = new URLSearchParams();
            params.append("is_active", "true");
            return params;
          })()}
          theref={register({ required: false })}
          baseUrl={endpoints.v1_storefronts_id_tenants_id_users(
            storefront_id,
            tenant_id
          )}
          getOptions={(response: User[]) => response.map(convertUserToOption)}
          errors={errors}
          formState={formState}
          isClearable
          placeholder={t("Select User")}
          onChange={(data: any) => {
            setValue("user", data.value);
          }}
        />
        <PrimaryButtonFitContainer type="submit" loading={isSubmitting}>
          {t("Save")}
        </PrimaryButtonFitContainer>
      </Form>
    </>
  );
}
