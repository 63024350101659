import React, { useContext, useEffect, useState } from "react";
import type { DefaultTheme } from "styled-components/macro";
import { ThemeProvider, createGlobalStyle } from "styled-components/macro";
import { QueryParamProvider } from "use-query-params";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AG3Theme } from "./theme";
import { SWRConfig } from "swr";
import { fetcher, SwrOnError, useSupportedLanguages } from "./util/util";
import Axios from "axios";
import { Routes } from "./Routes";
import { AuthProvider } from "./components/Auth";
import { NotificationsProvider } from "./components/Notifications/NotificationsContext";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { CartContextProvider } from "./components/quoteCart/CartContext";
import type { IStore } from "./Store";
import { Store } from "./Store";
import GoogleFontLoader from "react-google-font-loader";
import { HelmetDefault } from "./layout/shared/HelmetDefault/HelmetDefault";
import { RedirectOnInvalidRoutes } from "./components/RedirectOnInvalidRoute/RedirectOnInvalidRoute";
import { useCookies } from "react-cookie";
import { getBrowserLanguage } from "./util/util-components";
import { AnalyticsTracker } from "./components/AnalyticsTracker/AnalyticsTracker";
import { i18nFn } from "./i18n/i18nFn";
import type { i18n } from "i18next";
import { ChatBot } from "./components/ChatBot/ChatBot";

const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  * {
    box-sizing: border-box;
    font-family: ${AG3Theme.fontFamily};
  }
  /* Disable a warning about importing default CSS for reach tabs. */
  :root {
    --reach-tabs: 1;
  }
`;

export function App() {
  Axios.defaults.baseURL = "/api";
  const { storeState } = useContext<IStore>(Store);
  const { supportedLanguageOptions } = useSupportedLanguages();
  const [i18n_default, set_i18n_default] = useState<i18n>();

  const [cookies] = useCookies([`preferred-language-${storeState.slug}`]);
  const preferred: string | undefined =
    cookies[`preferred-language-${storeState.slug}`];

  useEffect(() => {
    if (storeState.tenant_id) {
      let i18n_instance = i18n_default;
      if (!i18n_instance) {
        i18n_instance = i18nFn({ tenant_id: storeState.tenant_id });
      }
      if (preferred && i18n_instance.language !== preferred) {
        i18n_instance.changeLanguage(preferred);
      }
      set_i18n_default(i18n_instance);
    }
  }, [i18n_default, preferred, storeState.tenant_id]);

  useEffect(() => {
    const onLanguageChanged = () => {
      const lang = getBrowserLanguage();
      const isLangSupported =
        supportedLanguageOptions.findIndex(({ value }) => value === lang) !==
        -1;
      if (
        isLangSupported &&
        i18n_default &&
        i18n_default.language !== lang &&
        !preferred
      ) {
        i18n_default.changeLanguage(lang);
      }
    };
    window.addEventListener("languagechange", onLanguageChanged);
    return () => {
      window.removeEventListener("languagechange", onLanguageChanged);
    };
  }, [i18n_default, preferred, supportedLanguageOptions]);

  return (
    <ErrorBoundary>
      {storeState?.storefront_metadata.theme_object.branding
        .customFontFamily && (
        <GoogleFontLoader
          fonts={[
            {
              font: storeState.storefront_metadata.theme_object.branding
                .customFontFamily,
              weights: [300, 400, 500, 600],
            },
          ]}
        />
      )}
      <SWRConfig
        value={{
          onError: SwrOnError, // handle errors on global level
          fetcher: fetcher, // avoids passing in fetcher every time
        }}
      >
        <AuthProvider>
          <HelmetDefault />
          {storeState.theme &&
            storeState.storefront_metadata.is_chatbot_configured && (
              <ThemeProvider theme={storeState.theme}>
                <ChatBot />
              </ThemeProvider>
            )}
          <Router>
            <AnalyticsTracker />
            <RedirectOnInvalidRoutes>
              <NotificationsProvider>
                <GlobalStyle />
                <QueryParamProvider ReactRouterRoute={Route}>
                  <CartContextProvider>
                    <Routes />
                  </CartContextProvider>
                </QueryParamProvider>
              </NotificationsProvider>
            </RedirectOnInvalidRoutes>
          </Router>
        </AuthProvider>
      </SWRConfig>
    </ErrorBoundary>
  );
}
