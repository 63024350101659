import Axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
  PrimaryButtonSmall,
} from "../../../../components/Buttons/Buttons";
import { PDFIcon } from "../../../../components/Icons/Icons";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import type { IPurchaseOrder } from "../../../../types/types";
import { useStoreState } from "../../../../util/util";
import { AddInvoiceForm } from "./AddInvoiceForm";
import { Row } from "../../../../components/Layout/Layout";

const InnerTitle = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const FileNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 15px;
`;

const FileNameText = styled.div`
  margin: 0 12px;
`;

const InvoiceButton = styled(PrimaryButtonSmall)`
  margin-right: 18px;
  &:last-child {
    margin-right: 0;
  }
`;

type InvoiceSectionProps = {
  order: IPurchaseOrder;
} & (
  | {
      readOnly: true;
    }
  | {
      readOnly: false;
      mutateOrderAndEvents: () => void;
    }
);

/**
 * Renders the invoice section on the order detail page for buyer and seller.
 * For buyers it is read-only, for sellers it is read/write.
 */
export const InvoiceSection = (props: InvoiceSectionProps) => {
  const { order, readOnly } = props;
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const [deletingInvoice, setDeletingInvoice] = useState(false);
  const [showAddInvoiceForm, setShowAddInvoiceForm] = useState(false);

  const invoice = order.documents.find((doc) => doc.kind === "invoice");

  const addInvoice = () => {
    setShowAddInvoiceForm(true);
  };

  const deleteInvoice = async () => {
    setDeletingInvoice(true);
    try {
      // The invoice should always be there, otherwise we wouldn't even be able
      // to try to delete it.
      if (invoice?.id) {
        await Axios.delete(
          `/v1/storefronts/${storefront_id}/orders/${order.id}/invoices`
        );
        notifySuccess("Invoice deleted");

        // This check is just for TypeScript's sake.
        if (props.readOnly === false) {
          props.mutateOrderAndEvents();
        }
      }
    } catch (error) {
      notifyError(t("Something went wrong: the invoice was not deleted"), {
        error,
      });
    }
    setDeletingInvoice(false);
  };

  const closeAddInvoiceForm = () => setShowAddInvoiceForm(false);

  return (
    <>
      <Row>
        <InnerTitle>{t("Invoice")}</InnerTitle>
        {!readOnly && !invoice && (
          <InvoiceButton
            data-testid={"add-invoice-button"}
            onClick={addInvoice}
          >
            {t("Add")}
          </InvoiceButton>
        )}
      </Row>

      {invoice && (
        <FileNameContainer>
          <PDFIcon height={24} width={24} />
          <a href={invoice.signed_url}>
            <FileNameText>{invoice.name}</FileNameText>
          </a>
          {!readOnly && invoice && (
            <ButtonWithConfirmDialog
              Button={DeleteButton}
              testid={"delete-invoice-button"}
              handleConfirm={deleteInvoice}
              loading={deletingInvoice}
              buttonText={t("Delete")}
              confirmMessage={t(
                "Are you sure you want to delete the generated Invoice?"
              )}
            />
          )}
        </FileNameContainer>
      )}

      <SlideOut show={showAddInvoiceForm} closeFlyout={closeAddInvoiceForm}>
        <AddInvoiceForm
          orderId={order.id}
          currencyCode={order.currency || "USD"}
          closeForm={closeAddInvoiceForm}
        />
      </SlideOut>
    </>
  );
};
