import React, { useState } from "react";
import styled from "styled-components/macro";
import type { DataMutate, SEODetail } from "../../../types/types";
import { EditSEO } from "./EditSEO";
import { StyledP } from "../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../../util/util";
import { DEFAULT_SEO_DESCRIPTION } from "../../../layout/shared/HelmetDefault/HelmetDefault";
import { AccordionCardWithEditFeatures } from "../../../components/AccordionCard/AccordionCardwithEditFeatures";

const InnerTitleText = styled.p`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const InnerContentText = styled(StyledP)`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

export type UserConfigurableSEO =
  | "product"
  | "register_page"
  | "contact_page"
  | "home_page";

export const getSEOUrl = (
  type: UserConfigurableSEO,
  storefrontId: string,
  id?: string
) => {
  switch (type) {
    case "product":
      return `/v1/storefronts/${storefrontId}/products/${id}/seo`;
    case "contact_page":
    case "register_page":
    case "home_page":
      return `/v1/storefronts/${storefrontId}/seo/${type}`;
    default:
      return `/v1/storefronts/${storefrontId}/seo/${type}`;
  }
};

interface ISEOProps<T> {
  seoDetail: SEODetail;
  fetchData: DataMutate<T>;
  seoType: UserConfigurableSEO;
  id?: string;
  title?: string;
}
export const SEO = <T extends object>({
  seoDetail,
  fetchData,
  seoType,
  id,
  title = "SEO",
}: ISEOProps<T>) => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const {
    storefront_metadata: { browser_title, tenant_name },
  } = useStoreState();
  return (
    <AccordionCardWithEditFeatures
      title={title}
      handleEdit={() => setEditMode(!editMode)}
      expanded={true}
      editing={editMode}
      handleCancel={() => setEditMode(false)}
    >
      {!editMode && (
        <>
          <div>
            <InnerTitleText>{t("Page Title")}</InnerTitleText>
            <InnerContentText>
              {seoDetail && seoDetail.page_title
                ? seoDetail.page_title
                : browser_title
                ? browser_title
                : "N/A"}
            </InnerContentText>
          </div>
          <div>
            <InnerTitleText>{t("Meta Title")}</InnerTitleText>
            <InnerContentText>
              {seoDetail && seoDetail.meta_title ? seoDetail.meta_title : "N/A"}
            </InnerContentText>
          </div>
          <div>
            <InnerTitleText>{t("Meta Keywords")}</InnerTitleText>
            <InnerContentText>
              {seoDetail && seoDetail.meta_keywords
                ? seoDetail.meta_keywords
                : "N/A"}
            </InnerContentText>
          </div>
          <div>
            <InnerTitleText>{t("Meta Description")}</InnerTitleText>
            <InnerContentText>
              {seoDetail && seoDetail.meta_description
                ? seoDetail.meta_description
                : DEFAULT_SEO_DESCRIPTION(tenant_name)}
            </InnerContentText>
          </div>
        </>
      )}
      {editMode && (
        <EditSEO
          id={id}
          seoDetail={seoDetail}
          setEdit={setEditMode}
          fetchData={fetchData}
          seoType={seoType}
        />
      )}
    </AccordionCardWithEditFeatures>
  );
};
