import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { SectionTitle } from "../../../../../components/Form/Form";
import { HeaderLeft } from "../../../../../components/Layout/Layout";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import { RadioButtonContainer } from "../../../../../layout/FormLayout";
import type { DataMutate } from "../../../../../types/types";
import type { PIMProduct } from "../../../../../types/types.PIM";
import { NewAssetForm } from "../NewAssetForm";
import { ExistingAssets } from "../ExistingAssets";
import { useTranslation } from "react-i18next";

const MarginBottomHeaderLeft = styled(HeaderLeft)`
  margin-bottom: 28px;
`;

export const AddAssetsForm = ({
  title,
  product,
  onComplete,
  fetchProductData,
}: {
  title: "Documents" | "Digital Media" | null;
  onComplete: (productId?: string) => void;
  product: PIMProduct;
  fetchProductData: DataMutate<PIMProduct>;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isSelectExist, setIsSelectExist] = useState<boolean>(true);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (value === "search" && checked) {
      setIsSelectExist(true);
    } else if (value === "upload" && checked) {
      setIsSelectExist(false);
    }
  };

  const translatedTitle =
    title === "Documents"
      ? t("Add Documents")
      : title === "Digital Media"
      ? t("Add Digital Media")
      : null;

  const subTitle = title
    ? title.split(" ").length > 1
      ? title?.split(" ")[1].toLowerCase()
      : title?.split(" ")[0].toLowerCase()
    : "";

  return (
    <>
      {translatedTitle && (
        <MarginBottomHeaderLeft>
          <SectionTitle>{translatedTitle}</SectionTitle>
        </MarginBottomHeaderLeft>
      )}
      <RadioButtonContainer>
        <RadioButton
          name="doc"
          value="search"
          optionTitle={t(`Search from existing {{subTitle}}`, { subTitle })}
          fontSize={theme.fontSizes.medium}
          width={14}
          height={14}
          color={isSelectExist ? theme.colors.darkText : theme.colors.grayText}
          handleChange={handleRadioChange}
          checked={isSelectExist}
          marginRight={4}
        />
        <RadioButton
          name="doc"
          value="upload"
          optionTitle={t(`Upload new {{subTitle}}`, { subTitle })}
          fontSize={theme.fontSizes.medium}
          width={14}
          height={14}
          color={isSelectExist ? theme.colors.grayText : theme.colors.darkText}
          handleChange={handleRadioChange}
          checked={!isSelectExist}
          marginRight={4}
        />
      </RadioButtonContainer>
      {isSelectExist ? (
        <ExistingAssets
          title={title ?? "Documents"}
          product={product}
          onComplete={onComplete}
          fetchParentData={fetchProductData}
        />
      ) : (
        <NewAssetForm
          defaultAssetType={title ?? "Documents"}
          product={product}
          refreshParentData={fetchProductData}
          onComplete={onComplete}
        />
      )}
    </>
  );
};
