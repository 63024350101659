import React, { useContext } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import styled, { ThemeContext } from "styled-components/macro";
import type { SelectBoxProps } from "../SelectBoxV2/SelectBoxV2";
import { showErrors } from "../../util/util-components";
import { useTranslation } from "react-i18next";
import { hasValue } from "../SelectBoxV2/SelectBoxV2.utils";

/* 
  This cannot be called by itself, only as a controlled input inside of <form> tage
  managed by react-hook-form.

  This does not have an interface because the controller works by spreading the props onto the component

          <Controller
            as={CreatableSelect}
            control={control}
            name="product"
            placeholder="Product Name"
            options={products}
            rules={{ required: true }}
            errors={errors}
            formState={formState}
          />

          see CreateNewOffer for a working example.
*/

// interface ISelectBoxProps {
//   styles: any;
//   formState: any;
//   errors: any;
//   rest: any;
//   name: string;
// }

const { ValueContainer, Placeholder } = components;

const InterDiv = styled.div`
  font-family: "Inter";
  margin-top: 6px;
`;

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <InterDiv>
        <Placeholder
          {...props}
          isFocused={props.isFocused}
          style={{ marginTop: "-5px" }}
        >
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </InterDiv>
    </ValueContainer>
  );
};

const Menu = (props: any) => (
  <InterDiv>
    <components.Menu {...props}>{props.children}</components.Menu>
  </InterDiv>
);

export function CreatableSelectBox({
  styles,
  formState,
  errors,
  name,
  onInputChange,
  isCreatable = false,
  normalPlaceholder,
  ...rest
}: SelectBoxProps) {
  const { submitCount } = formState;

  const styledTheme = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <div data-testid={name} style={{ position: "relative" }}>
      <CreatableSelect
        className="basic-single"
        classNamePrefix="select"
        isClearable
        isDisabled={rest.disabled ?? false}
        isValidNewOption={() => isCreatable}
        onInputChange={onInputChange}
        components={{
          ValueContainer: CustomValueContainer,
          IndicatorSeparator: () => null,
          Menu: Menu,
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary:
              submitCount > 0 && errors[name]?.type
                ? styledTheme.errorColor
                : styledTheme.tertiaryBorder,
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            ...(submitCount > 0 &&
              errors[name]?.type && {
                border: `2px solid ${styledTheme.errorColor}`,
                "&:hover": {
                  border: `2px solid ${styledTheme.errorColor}`,
                },
              }),
            height: 50,
            minHeight: 50,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color:
              submitCount > 0 && errors[name]?.type
                ? styledTheme.errorColor
                : "#808080",
          }),
          option: (base, state) => ({
            ...base,
            color: "#0f1d2b",
            fontSize: styledTheme.fontSizes.medium,
            backgroundColor:
              state.isFocused || state.isSelected
                ? styledTheme.secondaryButtonBG
                : styledTheme.primaryBG,
            ":hover": {
              backgroundColor: styledTheme.secondaryButtonBG,
            },
          }),
          valueContainer: (provided: any, state: any) => ({
            ...provided,
            overflow: "visible",
            marginTop: state.isSelected ? "0px" : "9px",
            fontSize: styledTheme.fontSizes.medium,
          }),
          placeholder: (provided: any, state: any) => ({
            ...provided,
            position: normalPlaceholder ? "relative" : "absolute",
            top:
              hasValue(state.selectProps.value) || state.selectProps.inputValue
                ? 2
                : "40%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize:
              (hasValue(state.selectProps.value) ||
                state.selectProps.inputValue) &&
              styledTheme.fontSizes.xs,
            marginBottom: 2,
            display:
              normalPlaceholder &&
              (hasValue(state.selectProps.value) ||
                state.selectProps.inputValue)
                ? "none"
                : "block",
            color:
              submitCount > 0 && errors[name]?.type
                ? styledTheme.errorColor
                : "#808080",
          }),
        }}
        {...rest}
      />
      {showErrors({ errors, name, t })}
    </div>
  );
}
