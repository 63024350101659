import React from "react";
import styled from "styled-components/macro";

// intentionally not exported
const Card = styled.div`
  height: 78px;
  width: 332.7px;
  padding: 16px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.primaryBorder};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryTextColor};
`;

const CardTextBold = styled.div`
  line-height: 1.53;
  letter-spacing: -0.15px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-align: left;
  width: 298px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardTextSoft = styled(CardTextBold)`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.secondaryTextColor};
`;

type ListingGridCardProps = {
  firstLineBold: string;
  secondLineSoft?: string;
};

export function ListingGridCard({
  firstLineBold,
  secondLineSoft,
}: ListingGridCardProps) {
  return (
    <Card title={firstLineBold}>
      <CardTextBold>{firstLineBold}</CardTextBold>
      {secondLineSoft && <CardTextSoft>{secondLineSoft}</CardTextSoft>}
    </Card>
  );
}
