import React, { useContext, useState, useEffect } from "react";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { TextField } from "../../../../components/TextFields/TextFields";
import { Form, SubmitButtonContainer } from "../../../../layout/FormLayout";
import {
  CancelButtonSmall,
  PrimaryButtonMedium,
} from "../../../../components/Buttons/Buttons";
import Axios from "axios";
import { POFileUpload } from "./Upload";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { strings } from "../../../../util/strings";
import styled from "styled-components/macro";
import { mutate } from "swr";
import type { OrderDocument } from "../../../../types/types";
import { useFormWrapper, useStoreState } from "../../../../util/util";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

const FieldName = styled.p`
  color: ${({ theme }) => theme.secondaryLinkColor};
  size: ${({ theme }) => theme.fontSizes.small};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InnerTitle = styled.p`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const AddPOSchema = (t: TFunction) =>
  yup.object().shape({
    alternative_po_number: yup
      .string()
      .required(strings(t).thisIsARequiredField),
  });

interface IAddPO {
  order_number: string;
  show: (arg: boolean) => void;
  PONumber: string | null;
  PODocument: OrderDocument | undefined;
  addPO: boolean;
}
interface IAddPOPost {
  alternative_po_number: string;
}
export const AddPOForm = ({
  order_number,
  show,
  PONumber,
  PODocument,
  addPO,
}: IAddPO) => {
  const { t } = useTranslation();
  const { handleSubmit, register, formState, errors } = useFormWrapper({
    resolver: yupResolver(AddPOSchema(t)),
    defaultValues: {
      alternative_po_number: PONumber,
    },
  });
  const [loading, setLoading] = useState(false);
  const { notifyError } = useContext(Notifications);
  const [PODoc, setPODoc] = useState<OrderDocument[]>([]);
  const { storefront_id } = useStoreState();

  const onSubmit = async (data: IAddPOPost) => {
    setLoading(true);
    try {
      // Don't send if its same to avoid duplicate event in timeline
      if (data.alternative_po_number !== PONumber) {
        await Axios.patch(
          `/v1/storefronts/${storefront_id}/orders/${order_number}`,
          data
        );
      }
      setLoading(false);
      show(false);
      await mutate(`/v1/storefronts/${storefront_id}/orders/${order_number}`);
      await mutate(
        `/v1/storefronts/${storefront_id}/orders/${order_number}/events?limit=100`
      );
    } catch (error) {
      setLoading(false);
      notifyError(t("There was an error saving the PO Number"), { error });
    }
  };

  useEffect(() => {
    if (!!PODocument) {
      setPODoc([PODocument]);
    }
  }, [setPODoc, PODocument]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <InnerTitle>{t("PO Number")}</InnerTitle>
        {addPO && <CancelButtonSmall onClick={() => show(false)} />}
      </Row>

      <InputContainer>
        <TextField
          name="alternative_po_number"
          autoComplete=""
          label={t("PO Number")}
          theref={register({
            required: true,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <div>
          <FieldName>{t("PO PDF (optional)")}</FieldName>
          <POFileUpload
            id={order_number}
            documents={PODoc}
            type={"purchase_order"}
          />
        </div>
      </InputContainer>
      <SubmitButtonContainer>
        <PrimaryButtonMedium loading={loading}>
          {t("Save your changes")}
        </PrimaryButtonMedium>
      </SubmitButtonContainer>
    </Form>
  );
};
