import React, { useMemo, useState } from "react";
import { H3, H6Bold } from "../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import {
  ButtonWithConfirmDialog,
  DeleteButton,
} from "../../../components/Buttons/Buttons";
import { Table } from "../../../components/Table/Table";
import { FormItems, RadioButtonContainer } from "../../../layout/FormLayout";
import type {
  DataMutate,
  OptionType,
  TransactionDocument,
} from "../../../types/types";
import styled, { useTheme } from "styled-components/macro";
import { ExistingTransactionDocuments } from "./ExistingTransactionDocuments";
import { RadioButton } from "../../../components/RadioButton/RadioButton";
import { rowHover, useStoreState } from "../../../util/util";
import type { AxiosError } from "axios";
import axios from "axios";
import { useNotifications } from "../../../components/Notifications/NotificationsContext";
import { NewTransactionDocument } from "./NewTransactionDocument";
import { useAuthContext } from "../../../components/Auth";

const TableWrapper = styled.div`
  table {
    td {
      max-width: 140px;
    }
  }
`;

export function AddDocumentToTransaction<ParentData>({
  existing_documents,
  products,
  transaction_type,
  transaction_type_id,
  fetchData,
  onComplete,
}: {
  existing_documents: (TransactionDocument & { should_delete?: boolean })[];
  products: { name: string; id: string }[];
  transaction_type: "orders" | "quotes" | "sample-requests";
  transaction_type_id: string;
  fetchData: DataMutate<ParentData>;
  onComplete: () => void;
}) {
  const { t } = useTranslation();
  const { storefront_id } = useStoreState();
  const { notifyError } = useNotifications();
  const { roleIsSomeKindOfBuyer } = useAuthContext();
  const theme = useTheme();
  const [select_existing_documents, set_select_existing_documents] =
    useState<boolean>(!roleIsSomeKindOfBuyer);

  const columns = useMemo(() => {
    const deleteTransactionAsset = async (id: string) => {
      try {
        await axios.delete(
          `/v2/storefronts/${storefront_id}/${transaction_type}/${transaction_type_id}/documents/${id}`
        );
        await fetchData();
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage
            ? errorMessage
            : t("Could not delete document. Something went wrong."),
          {
            error,
          }
        );
      }
    };
    return [
      { accessor: "name", Header: t("Document Name") },
      { accessor: "product_name", Header: t("Product Name") },
      {
        accessor: "category",
        Header: t("Category"),
      },
      {
        accessor: "actions",
        Header: "",
        align: "right",
        Cell: ({
          value,
          row: { original },
        }: {
          value: { id: string };
          row: { original: { should_delete: boolean } };
        }) => (
          <ButtonWithConfirmDialog
            Button={(props) => <DeleteButton {...props} />}
            testid="remove-transaction-document"
            disabled={!original.should_delete}
            datatip={
              !original.should_delete
                ? t("This document cannot be deleted.")
                : ""
            }
            datafor="remove-transaction-document-tooltip"
            handleConfirm={() => deleteTransactionAsset(value.id)}
            confirmMessage={t("Are you sure you want to delete this document?")}
          />
        ),
      },
    ];
  }, [
    fetchData,
    notifyError,
    storefront_id,
    t,
    transaction_type,
    transaction_type_id,
  ]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (value === "search" && checked) {
      set_select_existing_documents(true);
    } else if (value === "upload" && checked) {
      set_select_existing_documents(false);
    }
  };

  const tableData = existing_documents.map(
    ({ product_name, id, name, kind, should_delete }) => ({
      name,
      product_name: product_name || t("All"),
      category: kind,
      actions: { id },
      id,
      should_delete: should_delete ?? true,
    })
  );

  return (
    <FormItems>
      <H3>{t("Documents")}</H3>
      {tableData.length > 0 && (
        <>
          <H6Bold>{t("Existing Documents")}</H6Bold>
          <TableWrapper>
            <Table
              columns={columns}
              isLoading={false}
              error={undefined}
              data={tableData}
              rowHover={rowHover}
            />
          </TableWrapper>
        </>
      )}
      <H6Bold>{t("New Document")}</H6Bold>
      {!roleIsSomeKindOfBuyer && (
        <RadioButtonContainer>
          <RadioButton
            name="doc"
            value="search"
            optionTitle={t(`Search from existing documents`)}
            fontSize={theme.fontSizes.medium}
            width={14}
            height={14}
            color={
              select_existing_documents
                ? theme.colors.darkText
                : theme.colors.grayText
            }
            handleChange={handleRadioChange}
            checked={select_existing_documents}
            marginRight={4}
          />
          <RadioButton
            name="doc"
            value="upload"
            optionTitle={t(`Upload new document`)}
            fontSize={theme.fontSizes.medium}
            width={14}
            height={14}
            color={
              select_existing_documents
                ? theme.colors.grayText
                : theme.colors.darkText
            }
            handleChange={handleRadioChange}
            checked={!select_existing_documents}
            marginRight={4}
          />
        </RadioButtonContainer>
      )}
      {select_existing_documents ? (
        <ExistingTransactionDocuments
          fetchData={fetchData}
          transaction_type={transaction_type}
          transaction_type_id={transaction_type_id}
          products={[
            ...products.reduce<OptionType<string>[]>(
              (acc, { name, id }) =>
                id ? [...acc, { label: name, value: id }] : acc,
              []
            ),
            { label: t("All products"), value: "all_products" },
          ]}
          onComplete={onComplete}
        />
      ) : (
        <NewTransactionDocument
          fetchData={fetchData}
          transaction_type={transaction_type}
          transaction_type_id={transaction_type_id}
          products={[
            ...products.reduce<OptionType<string>[]>(
              (acc, { name, id }) =>
                id ? [...acc, { label: name, value: id }] : acc,
              []
            ),
            { label: t("All products"), value: "all_products" },
          ]}
          onComplete={onComplete}
        />
      )}
    </FormItems>
  );
}
