import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../../../../../components/Table/Table";
import { H3 } from "../../../../../components/Typography/Typography";
import type { AssetsExternalLinks } from "../../../../../types/types.PIM";
import { TablePlaceholder } from "../../../../../util/util";
import { AssetLink, TableContainer } from "../util";

export const ExternalLinksPortfolioAssets = ({
  links,
}: {
  links: AssetsExternalLinks[];
}) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<{}[]>([]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
        Cell: ({
          value,
          row: {
            original: { url, is_visible },
          },
        }: {
          value: string;
          row: {
            original: {
              url: string;
              is_visible: boolean;
            };
          };
        }) => <AssetLink url={url} name={value} is_visible={is_visible} />,
      },
    ],
    [t]
  );

  useEffect(() => {
    setTableData(
      links.reduce<
        { id: string; url: string; name: string; is_visible: boolean }[]
      >((acc, { id, url, name, is_visible }) => {
        acc.push({
          id,
          url,
          is_visible,
          name: name ?? "--",
        });
        return acc;
      }, [])
    );
  }, [links]);
  return (
    <>
      <H3 style={{ marginTop: "40px" }}>{t("External Links")}</H3>
      <TableContainer lastChild>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={false}
          error={undefined}
          lastChildleftAlign
          Placeholder={<TablePlaceholder />}
        />
      </TableContainer>
    </>
  );
};
