import { getQuoteOrOrderSubTotalValue } from "../../util/QuotesAndOrders";
import React from "react";
import styled from "styled-components/macro";
import type {
  Fee,
  CurrencyCode,
  QuoteRequestItem,
  IOrderItem,
  Payment,
} from "../../types/types";
import { formatPrice } from "../../util/util-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  max-width: 340px;
  background-color: ${({ theme }) => theme.secondaryBG};
  padding: 0 6px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

const ItemText = styled.span`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin-right: 30px;
`;

const NumberText = styled.span`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const TotalText = styled.span`
  color: ${({ theme }) => theme.primaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin-right: 30px;
`;

const TotalValue = styled(TotalText)`
  margin-right: 0;
`;

const HorizontalSeparator = styled.ol`
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
  padding-left: 0;
  list-style: none;
  display: flex;
`;

type CalculatePriceDetailsProps = {
  items: QuoteRequestItem[] | IOrderItem[];
  fees: Fee[];
  currencyCode: CurrencyCode;
};

/**
 * Calculate the price details for a quote or order.
 * The return values can be passed as props to the PriceDetails component.
 * We use this separate calculation function so the values can be accessible
 * in the parent components, which is sometimes needed.
 */
export const calculatePriceDetails = ({
  items,
  fees,
  currencyCode,
}: CalculatePriceDetailsProps) => {
  const subtotal = getQuoteOrOrderSubTotalValue(items);

  const feesTotal = fees.reduce(
    (total, fee) => total + (Number(fee.amount) || 0),
    0
  );

  const total =
    subtotal === null || isNaN(feesTotal) ? null : subtotal + feesTotal;

  const subtotalString =
    subtotal === null ? "--" : formatPrice(subtotal, currencyCode);

  const totalString = total ? formatPrice(total, currencyCode) : "--";

  return {
    subtotal,
    feesTotal,
    total,
    subtotalString,
    totalString,
  };
};

/**
 * display payments object as line items. Either display the estimated amounts
 * or the collected amounts but never a combination. If the data isn't in the
 * shape we expect display an error.
 */
export function StripePayments({
  payment,
  currencyCode,
}: {
  payment: Partial<Payment>;
  currencyCode: CurrencyCode;
}) {
  const { t } = useTranslation();

  if (payment.agilis_fee && payment.stripe_fee && payment.payment_net) {
    const agilisFee = formatPrice(Number(payment.agilis_fee), currencyCode);
    const stripeFee = formatPrice(Number(payment.stripe_fee), currencyCode);
    const net = formatPrice(Number(payment.payment_net), currencyCode);
    return (
      <>
        <Row>
          <ItemText>{t("Payment Processing Fee")}</ItemText>
          <NumberText>
            {isNaN(Number(payment.stripe_fee)) ? t("N/A") : stripeFee}
          </NumberText>
        </Row>
        <Row>
          <ItemText>{t("Platform Fee")}</ItemText>
          <NumberText>
            {isNaN(Number(payment.agilis_fee)) ? t("N/A") : agilisFee}
          </NumberText>
        </Row>
        <Row>
          <ItemText>{t("Net")}</ItemText>
          <NumberText>
            {isNaN(Number(payment.payment_net)) ? t("N/A") : net}
          </NumberText>
        </Row>
      </>
    );
  } else if (
    payment.estimated_agilis_fee &&
    payment.estimated_stripe_fee &&
    payment.estimated_net
  ) {
    const estimatedAgilisFee = formatPrice(
      Number(payment.estimated_agilis_fee),
      currencyCode
    );
    const estimatedStripeFee = formatPrice(
      Number(payment.estimated_stripe_fee),
      currencyCode
    );
    const estimatedNet = formatPrice(
      Number(payment.estimated_net),
      currencyCode
    );

    return (
      <>
        <Row>
          <ItemText>{t("Est. Payment Processing Fee")}</ItemText>
          <NumberText>
            {isNaN(Number(payment.estimated_stripe_fee))
              ? t("N/A")
              : estimatedStripeFee}
          </NumberText>
        </Row>
        <Row>
          <ItemText>{t("Est. Platform Fee")}</ItemText>
          <NumberText>
            {isNaN(Number(payment.estimated_agilis_fee))
              ? t("N/A")
              : estimatedAgilisFee}
          </NumberText>
        </Row>
        <Row>
          <ItemText>{t("Est. Net")}</ItemText>
          <NumberText>
            {isNaN(Number(payment.estimated_net)) ? t("N/A") : estimatedNet}
          </NumberText>
        </Row>
      </>
    );
  } else return null;
}

/**
 * Fees include "Taxes", "Shipping", etc.
 */
export const PriceDetails = ({
  subtotal,
  total,
  fees,
  currencyCode,
  payment,
}: {
  subtotal: string;
  total: string;
  fees: Fee[];
  currencyCode: CurrencyCode;
  payment?: Partial<Payment>;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row>
        <ItemText>{t("Subtotal")}</ItemText>
        <NumberText data-testid={"subtotal"}>{subtotal}</NumberText>
      </Row>

      {fees.map((fee, index) => (
        <Row key={fee.id}>
          <ItemText>{fee.name}</ItemText>
          <NumberText data-testid={`fee-${index}`}>
            {
              // Just in case fee.amount is a string of "0", cast it to a
              // number for this check.
              Number(fee.amount) === 0
                ? t("Free")
                : formatPrice(fee.amount, currencyCode)
            }
          </NumberText>
        </Row>
      ))}

      <HorizontalSeparator />
      <Row>
        <TotalText>{t("Total")}</TotalText>
        <TotalValue data-testid={"total"}>{total}</TotalValue>
      </Row>
      {payment && (
        <StripePayments payment={payment} currencyCode={currencyCode} />
      )}
    </Container>
  );
};
