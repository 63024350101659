import styled from "styled-components/macro";

export const CheckBoxContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 460px;
  flex-direction: row;
`;

export const CheckBoxFinePrintLabel = styled.label`
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.primaryTextColor};
  cursor: pointer;
`;

export const GrayBox = styled.section`
  background-color: ${({ theme }) => theme.secondaryBG};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  width: 100%;
`;

export const SectionTitle = styled.h3`
  font-size: 19px;
  font-weight: 600;
  margin-top: 50px;
`;

export const StatusBox = styled.div`
  border-radius: 4px;
  border: solid 0.6px ${({ theme }) => theme.primaryBorder};
  background-color: ${({ theme }) => theme.secondaryBG};
  color: 0f1d2b;
  font-size: 13px;
  padding: 10px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    padding: 5px 0;
    margin: 0 auto;
    color: ${({ theme }) => theme.secondaryButtonTextColor};
  }
`;

export const StatusBoxLine = styled.div`
  height: 20px;
  width: 100%;
  position: relative;
  &::after {
    content: "";
    height: 100%;
    border-left: 1px solid ${({ theme }) => theme.primaryBorder};
    width: 1px;
    position: absolute;
    left: 50%;
    top: 0;
  }
`;

export const SmallTextField = styled.div`
  max-width: 176px;
`;
interface ICustomTermInput {
  highlight: boolean;
  has_error: boolean;
}

export const CustomTermContainer = styled.div<ICustomTermInput>`
  .select__control {
    border: 1px solid
      ${({ has_error, highlight, theme }) =>
        has_error
          ? "inherit"
          : highlight
          ? theme.warningTextColor
          : theme.primaryBorder};

    box-shadow: ${({ has_error, highlight, theme }) =>
      has_error
        ? "inherit"
        : highlight
        ? `0px 0px 3px 0px ${theme.warningTextColor}`
        : "none"};
  }
`;

export const WarningMessage = styled.span`
  color: ${({ theme }) => theme.warningTextColor};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

export const WarningMessageBox = styled.span`
  color: ${({ theme }) => theme.warningTextColor};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.warningTextColor};
  background-color: ${({ theme }) => `${theme.warningTextColor}20`};
  border-radius: 3px;
  margin: 10px 0;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const InfoMessageBox = styled.div`
  border: 1px solid ${({ theme }) => theme.brandColor};
  background-color: ${({ theme }) => `${theme.brandColor}10`};
  border-radius: 8px;
`;

export const SuccessMessageBox = styled.div`
  border: 1px solid ${({ theme }) => theme.successIconColor};
  background-color: ${({ theme }) => `${theme.successIconColor}10`};
  border-radius: 8px;
`;

export const ErrorMessageBox = styled.div`
  border: 1px solid ${({ theme }) => theme.errorColor};
  background-color: ${({ theme }) => `${theme.errorColor}10`};
  border-radius: 8px;
`;
