import type {
  SampleRequestPaginatedOutput,
  StorefrontUnifiedCart,
} from "../../../types/types";
import React, { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import useSWR from "swr";
import { ErrorPlaceholder } from "../../../components/Error";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import { endpoints } from "../../../endpoints";
import { Store } from "../../../Store";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import { makeUrlWithParams } from "../../../util/util";
import { BuyerQuoteOrReorderCart } from "../../Buyer/BuyerQuoteOrReorderCart/BuyerQuoteOrReorderCart";
import { BuyerSampleRequestCart } from "../../Buyer/BuyerSampleRequestCart/BuyerSampleRequestCart";

export const CartSwitcherBuyer = providePrivatePageProps(({ user }) => {
  const { notifyError } = useContext(Notifications);
  const {
    storeState: { stored_location, storefront_id },
    storeDispatch,
  } = useContext(Store);

  const history = useHistory();
  const { storePath } = useRoutePath();

  const {
    data: unifiedCart,
    error: unifiedCartError,
    mutate: mutateUnifiedCart,
    isValidating: isValidatingUnifiedCart,
  } = useSWR<StorefrontUnifiedCart>(
    `/v1/storefronts/${storefront_id}/unified-cart`,
    {
      onError: (error) => {
        notifyError("There was an error fetching cart data", { error });
      },
    }
  );

  const {
    data: sampleRequestResponse,
    error: sampleRequestError,
    mutate: mutateSampleRequest,
    isValidating: isValidatingSampleRequest,
  } = useSWR<SampleRequestPaginatedOutput>(
    makeUrlWithParams(
      endpoints.v1_storefronts_id_sampleRequests(storefront_id),
      {
        source: "logged_in",
        status: "new",
        limit: "1",
      }
    ),
    {
      onError: (error) => {
        notifyError("There was an error fetching cart data", { error });
      },
    }
  );

  const sampleRequest = sampleRequestResponse?.data[0];

  const quoteRequestHasItems =
    // the two checks for unifiedCart and unifiedCart.quote seems extraneous but
    // they are not.
    unifiedCart && unifiedCart.quote && unifiedCart?.quote?.items?.length > 0;
  const sampleRequestHasItems = sampleRequest && sampleRequest.items.length > 0;

  const isLoading =
    (!unifiedCart && !unifiedCartError) ||
    isValidatingUnifiedCart ||
    (!sampleRequestResponse && !sampleRequestError) ||
    isValidatingSampleRequest;

  /**
   * Handler for the "back" button. Redirects to the "parent" page.
   *
   * If it's a quote request cart, go to the portfolio/product page.
   * In this case if they came from a product page, preserve any params for
   * filters and search so they end up where they started.
   * Use the stored location to preserve the params.
   */
  const goToParentPage = useCallback(() => {
    storeDispatch({ type: "SET_STORED_LOCATION", payload: null });

    history.replace(stored_location ?? `${storePath}/portfolio`); // always navigate to stored_location unless when it's not available
  }, [stored_location, storeDispatch, storePath, history]);

  useEffect(() => {
    // If there are no more items in the cart, then go to parent page.
    if (!isLoading && !sampleRequestHasItems && !quoteRequestHasItems) {
      console.info(
        "Redirected away from the cart page because there are no cart items."
      );
      goToParentPage();
    }
  }, [goToParentPage, isLoading, sampleRequestHasItems, quoteRequestHasItems]);

  if (sampleRequest && sampleRequest.items.length > 0) {
    return (
      <BuyerSampleRequestCart
        user={user}
        sampleRequest={sampleRequest}
        mutateSampleRequest={mutateSampleRequest}
        goToParentPage={goToParentPage}
      />
    );
  } else if (unifiedCart?.quote) {
    return (
      <BuyerQuoteOrReorderCart
        user={user}
        goToParentPage={goToParentPage}
        quoteRequest={unifiedCart?.quote}
        mutateQuoteRequest={mutateUnifiedCart}
      />
    );
  } else if (isLoading) {
    return null;
  }
  return (
    <ErrorPlaceholder message="An error occurred while fetching data, please try again." />
  );
});
