import { PrimaryButtonFitContainer } from "../../components/Buttons/Buttons";
import styled from "styled-components";
import {
  PasswordToggleTextField,
  TextField,
} from "../../components/TextFields/TextFields";
import { Form } from "../../layout/FormLayout";
import { DesktopNotification } from "../../components/Notifications/Notifications";
import React, { useContext, useState, useRef, useEffect } from "react";
import { AuthContainer, AuthPage } from "../../layout/publicPageLayout";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { Notifications } from "../../components/Notifications/NotificationsContext";
import { useFormWrapper } from "../../util/util";
import { useRoutePath } from "../../util/Routing";
import { Auth } from "../../components/Auth";
import useSWR from "swr";
import { StringParam, useQueryParams } from "use-query-params";
import { useTranslation } from "react-i18next";
import { H3DarkText } from "../../components/Typography/Typography";
import { AuthLogoWrapper } from "./Login";

type ActivateCredentials = {
  id: string;
  password: string;
};

interface ITokenResponse {
  user_id: string;
  username: string;
  token: string;
}

const NotificationWrapper = styled.div`
  & > div {
    grid-template-columns: 47px 270px;
  }
`;

export function ActivateAccount() {
  const { handleSubmit, register, formState, errors, watch } = useFormWrapper(
    {}
  );
  const password = useRef({});
  password.current = watch("password", "");
  const [loading, setLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { storePath } = useRoutePath();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { logout } = useContext(Auth);
  const [userID, setUserID] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [language] = useQueryParams({
    lang: StringParam,
  });
  const { lang: preferredLanguage } = language;

  const { data: tokenResponse, error: tokenError } = useSWR<ITokenResponse>(
    token ? `/v1/users/activations/${token}` : null,
    {
      revalidateOnFocus: false,
    }
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setUsername(tokenResponse?.username);
  }, [tokenResponse]);

  useEffect(() => {
    if (preferredLanguage && i18n.language !== preferredLanguage) {
      i18n.changeLanguage(preferredLanguage);
    }
  }, [i18n, preferredLanguage]);

  useEffect(() => {
    if (tokenError) {
      if (tokenError.message?.includes("403")) {
        setTokenExpired(true);
      } else if (tokenError.message?.includes("404")) {
        notifyError(t("Token is invalid, please contact us for support."), {
          error: tokenError,
          logMessage: "Token not found, redirecting...",
        });
        history.push(`${storePath}/login`);
      } else {
        notifyError(t("Something went wrong! please contact us for support."), {
          error: tokenError,
        });
      }
    }
  }, [tokenError, history, storePath, notifyError, t]);

  if (tokenResponse && !userID && tokenResponse.user_id) {
    setUserID(tokenResponse.user_id);
  }

  const onSubmit = async ({ password }: ActivateCredentials) => {
    setLoading(true);
    if (!userID) {
      notifyError(
        t("Could not find user account, please contact us for support.")
      );
      setLoading(false);
      return;
    }
    try {
      await Axios.patch(`/v1/users/activations/${token}`, {
        password: password,
      });

      notifySuccess(t("Your account has been activated successfully."));
      logout();
      history.push(`${storePath}/login`);
    } catch (error) {
      notifyError(t("Something went wrong, please contact us for support."), {
        error,
        logMessage: "Submit failed",
      });
      setLoading(false);
    }
  };

  const closeExpiredTokenNotification = () => {
    setTokenExpired(false);
    history.push(storePath);
  };

  const TokenExpired = (hasTokenExpired: boolean) => {
    return (
      hasTokenExpired && (
        <NotificationWrapper>
          <DesktopNotification
            notificationType="error"
            text={t(
              "Link has expired, please contact us to activate your account."
            )}
            id={1}
            removeNotification={closeExpiredTokenNotification}
          />
        </NotificationWrapper>
      )
    );
  };

  return (
    <AuthPage>
      <AuthContainer>
        <AuthLogoWrapper />
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <H3DarkText>{t("Create Password")}</H3DarkText>
          {tokenExpired && TokenExpired(tokenExpired)}
          {!tokenExpired && (
            <>
              <TextField
                name="username"
                label={t("User Name")}
                theref={register({
                  required: false,
                })}
                formState={formState}
                errors={errors}
                disabled
                defaultValue={username}
                type="text"
              />
              <PasswordToggleTextField
                name="password"
                autoComplete="new-password"
                label={t("Password")}
                theref={register({
                  required: t("You must specify a password") as string,
                  minLength: {
                    value: 8,
                    message: t(
                      "Password must have at least 8 characters"
                    ) as string,
                  },
                })}
                formState={formState}
                errors={errors}
              />
              <PasswordToggleTextField
                name="confirmPassword"
                autoComplete="confirm-password"
                label={t("Confirm password")}
                theref={register({
                  validate: (value) =>
                    value === password.current ||
                    (t("The passwords do not match") as string),
                })}
                formState={formState}
                errors={errors}
              />
              <PrimaryButtonFitContainer
                style={{ marginTop: 0 }}
                type="submit"
                loading={loading}
              >
                {t("Create password")}
              </PrimaryButtonFitContainer>
            </>
          )}
        </Form>
      </AuthContainer>
    </AuthPage>
  );
}
