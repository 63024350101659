import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components/macro";
import { SuccessIcon } from "../Icons/Icons";

interface IDropDownModalProps {
  items: any[];
  activeItem: any;
  clickHandler: (e: any) => void;
}

const DropDownContainer = styled.div`
  box-shadow: 0px 6px 16px -6px ${({ theme }) => theme.shadowColor};
  display: block;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
  button {
    display: flex;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.primaryBG};
    border: solid 1px ${({ theme }) => theme.secondaryBorder};
    border: none;
    width: 100%;
    white-space: nowrap;
    padding: 12px 8px;
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.primaryTextColor};
    span {
      margin-right: 5px;
    }
  }

  button:focus {
    background-color: ${({ theme }) => theme.secondaryButtonBG};
    outline: none;
    color: ${({ theme }) => theme.primaryTextColor};
  }

  button:hover {
    background-color: ${({ theme }) => theme.secondaryButtonBG};
    color: ${({ theme }) => theme.primaryTextColor};
  }
`;

export const DropDownModal = ({
  items,
  activeItem,
  clickHandler,
}: IDropDownModalProps) => {
  const theme = useContext(ThemeContext);
  return (
    <DropDownContainer>
      {items.map((item) => {
        if (item === activeItem) {
          return (
            <div key={item}>
              <button data-name={item} onClick={clickHandler}>
                {item}
                <span>
                  <SuccessIcon fill={theme.brandColor} width={15} height={15} />
                </span>
              </button>
            </div>
          );
        } else {
          return (
            <div key={item}>
              <button data-name={item} onClick={clickHandler}>
                {item}
              </button>
            </div>
          );
        }
      })}
    </DropDownContainer>
  );
};
