import type { ArrayField } from "react-hook-form";
import { Controller } from "react-hook-form";
import { ConfirmationSpacer } from "../../../../../public/SampleRequestCart/SampleRequestCart";
import {
  CheckBoxContainer,
  CheckBoxFinePrintLabel,
} from "../../../../../../components/Form/Form";
import { CheckBoxNoLabel } from "../../../../../../components/CheckBoxes/CheckBoxes";
import type { MethodsOfUseForm } from "../../../../../../types/types";
import { ColoredTextOnError } from "../../../../../../util/util-components";
import { strings } from "../../../../../../util/strings";
import type { TFunction } from "react-i18next";
import * as Yup from "yup";

export const create_configured_checkboxes_fields = ({
  fields,
  methodsOfUseForm,
  t,
}: {
  fields: Partial<ArrayField<Record<string, any>, "id">>[];
  methodsOfUseForm: MethodsOfUseForm;
  t: TFunction;
}) => {
  const {
    control,
    errors,
    formState: { submitCount },
    getValues,
    register,
  } = methodsOfUseForm;
  const has_error = (index: number) =>
    !!errors.configured_checkboxes?.[index]?.value?.message ||
    (submitCount > 0 &&
      getValues()["configured_checkboxes"]?.[index]?.value === false);
  return fields.map((item, index) => (
    <div key={item.id}>
      <Controller
        name={`configured_checkboxes[${index}].value`}
        control={control}
        render={({ onChange }) => (
          <ConfirmationSpacer>
            <CheckBoxContainer>
              <div style={{ width: "22px", marginRight: "15px" }}>
                <CheckBoxNoLabel
                  name={`configured_checkboxes[${index}].value`}
                  id={`configured_checkboxes[${index}].value`}
                  error={has_error(index)}
                  ref={register({
                    validate: (val: boolean) =>
                      val || strings(t).thisIsARequiredField,
                  })}
                  onChange={(e) => {
                    onChange(e.currentTarget.checked);
                  }}
                />
              </div>
              <CheckBoxFinePrintLabel
                htmlFor={`configured_checkboxes[${
                  index as unknown as string
                }].value`}
              >
                <ColoredTextOnError isError={has_error(index)}>
                  {item.label}
                </ColoredTextOnError>
              </CheckBoxFinePrintLabel>
            </CheckBoxContainer>
          </ConfirmationSpacer>
        )}
      />
    </div>
  ));
};

export const configured_checkboxes_schema_yup = ({
  formValues,
  t,
}: {
  formValues: any;
  t: TFunction;
}) =>
  Yup.array().of(
    Yup.object().shape({
      value: Yup.boolean()
        .oneOf([true])
        .required(t("This is a required field")),
      label: Yup.string().notRequired(),
    })
  );
