import type { ReactNode } from "react";
import React from "react";
import styled from "styled-components/macro";
import { InvisibleButton } from "../Buttons/Buttons";
import { XIcon } from "../Icons/Icons";
import { Title } from "../Typography/Typography";

const EditableItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  padding: 10px 10px 10px 16px;
  border: solid 1px ${({ theme }) => theme.primaryBorder};
  border-radius: 4px;
  background: ${({ theme }) => theme.secondaryBG};
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  margin: 0 0 0 16px;
`;

const ItemTitle = styled(Title)`
  font-size: ${({ theme }) => theme.fontSizes.regular};
`;

const ChildrenContainer = styled.div`
  margin: 20px 0 10px;
`;

const DeleteButton = styled(InvisibleButton)`
  padding: 0;
`;

/**
 * A list item with a title, a delete button, and optional children. The initial
 * use case was in the edit forms for tags and featured products in homepage
 * templates. In the future this could be used for "drag and drop" lists to
 * allow re-ordering the items.
 */
export const EditableListItem = ({
  title,
  deleteFunction,
  children,
}: {
  title: string;
  deleteFunction: () => void;
  children?: ReactNode;
}) => {
  return (
    <EditableItemContainer>
      <LeftColumn>
        <ItemTitle>{title}</ItemTitle>
        {children && <ChildrenContainer>{children}</ChildrenContainer>}
      </LeftColumn>
      <RightColumn>
        <DeleteButton onClick={deleteFunction}>
          <XIcon height={16} width={16} />
        </DeleteButton>
      </RightColumn>
    </EditableItemContainer>
  );
};
