import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "../../../components/Tabs/Tabs";
import type { IIconProps } from "../../../components/Icons/Icons";
import {
  PageWrapper,
  PageHeader,
  PageTitle,
  ContentWrapper,
} from "../../../layout/portalPageLayout";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRoutePath } from "../../../util/Routing";

export type IOrganizationPageTab = {
  label: string;
  icon: ((props: IIconProps) => JSX.Element) | undefined;
  component: JSX.Element;
};

/**
 * Page component for the seller admin, buyer admin "Organization" page.
 */
export const OrganizationPage = ({
  tabs,
}: {
  tabs: IOrganizationPageTab[];
}) => {
  const location = useLocation();
  const [view, setView] = useState<string | undefined>();
  const { t } = useTranslation();
  const history = useHistory();
  const { adminPath } = useRoutePath();
  const clearParamsAndSetTab = (label: string) => {
    // This strips the existing params from the URL without a page reload
    switch (label) {
      case "Users":
        history.push(`${adminPath}/organization/users`);
        break;
      case "Settings":
        history.push(`${adminPath}/organization/settings`);
        break;
      case "Company Info":
        history.push(`${adminPath}/organization/company-info`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/users")) {
      setView("Users");
    } else if (location.pathname.includes("/settings")) {
      setView("Settings");
    } else {
      setView("Company Info");
    }
  }, [location]);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>{t("Organization")}</PageTitle>
      </PageHeader>
      <ContentWrapper>
        {view && (
          <Tabs selectedIndex={view}>
            {tabs.map((tab) => (
              <Tab
                onClick={clearParamsAndSetTab}
                label={tab.label}
                icon={tab.icon}
                key={tab.label}
              >
                {tab.component}
              </Tab>
            ))}
          </Tabs>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};
