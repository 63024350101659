import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  EditButton,
  CancelButtonSmall,
} from "../../../../../components/Buttons/Buttons";
import {
  H3,
  SoftHeader2,
} from "../../../../../components/Typography/Typography";
import {
  TwoColumnLayout,
  SettingsCard,
} from "../../../../../layout/portalPageLayout";
import type {
  CustomFont,
  Language,
  SEODetail,
  StorefrontCustomizableFormSchema,
  StorefrontFormData,
  StorefrontMetaData,
} from "../../../../../types/types";
import type { AxiosError } from "axios";
import useSWR from "swr";
import { AG3Branding } from "../../../../../theme";
import { LoadingIcon } from "../../../../../components/Icons/Icons";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { useStoreState, useSupportedLanguages } from "../../../../../util/util";
import { endpoints } from "../../../../../endpoints";
import { InfoBlockSmall } from "../../../../../components/InfoBlocks/InfoBlocks";
import { EditAssets } from "./EditAssets/EditAssets";
import { EditPortfolioPageContent } from "./EditPortfolioPageContent/EditPortfolioPageContent";
import { EditColorScheme } from "./EditColorScheme/EditColorScheme";
import { ColorInputContainer, ColorInputColumn } from "../shared";
import { AnalyticsSettings } from "./AnalyticsSettings/AnalyticsSettings";
import { useTranslation } from "react-i18next";
import { EditPageDescription } from "./EditPageDescription/EditPageDescription";
import { defaultRegistrationDescription } from "../../../../public/Registration/Registration";
import { defaultContactDescription } from "../../../../public/ContactUsPage/ContactUsPage";
import { SEO } from "../../../../SharedPages/SEO/SEO";
import { OutsideLoginCustomFieldsSettings } from "./OutsideLoginCustomFields/OutsideLoginCustomFieldsSettings";
import { EditAssetLanguages } from "./EditAssets/EditAssetLanguages";
import type { ChipType } from "../../../../../components/Chips/Chips";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";

const LoadingWrapper = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InfoBlockColumn = styled.div`
  & > div {
    margin: 15px 0 15px;
  }
`;

// TODO: this shouldn't be here, should probably be coming from the backend.
const languages: Record<string, { name: string; englishName: string }> = {
  en: { name: "English", englishName: "English" },
  ja: { name: "日本語", englishName: "Japanese" },
  zh: { name: "简体中文", englishName: "Chinese" },
  de: { name: "Deutsch", englishName: "German" },
  fr: { name: "Français", englishName: "French" },
};

/**
 * The "Theme & Content" tab for the seller admin settings page.
 */
export const ThemeAndContentTab = () => {
  const { storefront_id, storefront_metadata } = useStoreState();
  const { edition, custom_domain } = storefront_metadata;
  const { getLanguageLabel } = useSupportedLanguages();

  const [editColorSection, setEditColorSection] = useState(false);
  const [editAssetsSection, setEditAssetsSection] = useState(false);
  const [editAssetLanguagesSection, setEditAssetLanguagesSection] =
    useState(false);
  const [editPageDescription, setEditPageDescription] = useState(false);
  const { notifyError } = useContext(Notifications);
  // Since 'undefined' is falsy we can initialize this with an empty object.
  // It is a record like: { en: false, ja: false, de: true }
  const [editLocalizations, setEditLocalizations] = useState<
    Record<string, boolean>
  >({});

  const { t } = useTranslation();

  const {
    data: storefrontMetadata,
    error: metadataError,
    mutate: mutateStorefrontMetadata,
  } = useSWR<StorefrontMetaData, AxiosError<StorefrontMetaData>>(
    storefront_id ? endpoints.v1_storefronts_id_metadata(storefront_id) : null
  );

  const {
    data: storefrontFormData,
    error: formDataError,
    mutate: mutateStorefrontFormData,
  } = useSWR<StorefrontFormData, AxiosError<StorefrontFormData>>(
    endpoints.v1_storefronts_id_form_data(storefront_id, "en")
  );

  const { data: contactPageSEO, mutate: mutateContactPageSEO } = useSWR<
    SEODetail,
    AxiosError<SEODetail>
  >(
    endpoints.v1_storefronts_id_or_slug_seo_page(storefront_id, "contact_page")
  );

  const { data: registerPageSEO, mutate: mutateRegisterPageSEO } = useSWR<
    SEODetail,
    AxiosError<SEODetail>
  >(
    endpoints.v1_storefronts_id_or_slug_seo_page(storefront_id, "register_page")
  );

  const { data: homePageSEO, mutate: mutateHomePageSEO } = useSWR<
    SEODetail,
    AxiosError<SEODetail>
  >(endpoints.v1_storefronts_id_or_slug_seo_page(storefront_id, "home_page"));

  const {
    data: leadCustomFormsContact,
    error: leadCustomFormsContactError,
    mutate: mutateContactUs,
  } = useSWR<StorefrontCustomizableFormSchema>(
    `/v2/storefronts/${storefront_id}/custom-forms/contact-us`
  );

  const {
    data: leadCustomFormsRegistration,
    error: leadCustomFormsRegistrationError,
    mutate: mutateRegistration,
  } = useSWR<StorefrontCustomizableFormSchema>(
    `/v2/storefronts/${storefront_id}/custom-forms/registration`
  );

  const localizations = {
    // Alas, "en" isn't included in metadata_localization, so include it here.
    en: {
      browser_title: storefrontMetadata?.browser_title || "--",
      header: storefrontMetadata?.header || "--",
      placeholder: storefrontMetadata?.placeholder || "--",
      sub_header: storefrontMetadata?.sub_header || "--",
      is_active: true,
    },
    ...(storefrontMetadata?.metadata_localization || {}),
  };

  const [branding, setBranding] = useState(AG3Branding);

  const [fontContent, setFontContent] = useState("--");

  const [customFont, setCustomFont] =
    useState<CustomFont | undefined>(undefined);

  const [showCustomFont, setShowCustomFont] = useState(false);
  const [assetLanguageChips, setAssetLanguageChips] = useState<ChipType[]>([]);
  const contactUsDescription = storefrontFormData?.data?.Contact
    ? storefrontFormData?.data?.Contact?.Description
    : defaultContactDescription(t);
  const registrationDescription = storefrontFormData?.data?.Register
    ? storefrontFormData?.data?.Register?.Description
    : defaultRegistrationDescription(t);

  const { mutate: mutateAssetLanguages } = useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onSuccess: (data) => {
      setAssetLanguageChips(
        data.languages.map((item) => ({
          name: `${item.name} (${item.alpha_2.toUpperCase()})`,
          id: item.alpha_2,
          active: item.is_deletable,
        }))
      );
    },
    onError: () =>
      notifyError(t("could not load asset languages, Something went wrong.")),
  });

  useEffect(() => {
    setBranding(storefrontMetadata?.theme_object.branding || AG3Branding);
    setFontContent(
      (storefrontMetadata?.theme_object.branding || AG3Branding)
        .customFontFamily
        ? (storefrontMetadata?.theme_object.branding || AG3Branding)
            .customFontFamily
        : "--"
    );
    setCustomFont(storefrontMetadata?.custom_font);
    setShowCustomFont(
      !!(
        !(storefrontMetadata?.theme_object.branding || AG3Branding)
          .customFontFamily && storefrontMetadata?.custom_font
      )
    );
  }, [storefrontMetadata]);

  const makeToggleEditPortfolioPageContent = (
    langCode: string,
    value: boolean
  ) => {
    return () => {
      setEditLocalizations((previous) => ({
        ...previous,
        [langCode]: value,
      }));
    };
  };

  if (metadataError) {
    return <ErrorPlaceholder message={t("Something went Wrong!")} />;
  }
  if (!storefrontMetadata) {
    return (
      <LoadingWrapper>
        <LoadingIcon />
      </LoadingWrapper>
    );
  }
  // No metadataError and storefrontMetadata is defined.
  return (
    <>
      <SettingsCard>
        <Row>
          <H3>{t("Color Scheme")}</H3>
          {!editColorSection && (
            <EditButton
              testid={"edit-color-scheme"}
              onClick={() => setEditColorSection(true)}
              title={t("Edit Color Scheme")}
            />
          )}
          {editColorSection && (
            <CancelButtonSmall onClick={() => setEditColorSection(false)} />
          )}
        </Row>
        {!editColorSection && (
          <TwoColumnLayout>
            <ColorInputColumn>
              <ColorInputContainer>
                <SoftHeader2>{t("Main Brand Color")}</SoftHeader2>
                <input
                  type="color"
                  id="brandColor"
                  name="brandColor"
                  value={branding.brandColor}
                  disabled
                />
              </ColorInputContainer>
            </ColorInputColumn>
          </TwoColumnLayout>
        )}
        {editColorSection && (
          <EditColorScheme
            brandColor={branding.brandColor}
            closeEditColorScheme={() => setEditColorSection(false)}
            mutateStorefrontMetadata={mutateStorefrontMetadata}
          />
        )}
      </SettingsCard>
      {leadCustomFormsContact &&
        leadCustomFormsRegistration &&
        !leadCustomFormsContactError &&
        !leadCustomFormsRegistrationError &&
        edition !== "pim" && (
          <OutsideLoginCustomFieldsSettings
            onComplete={async () => {
              await mutateRegistration();
              await mutateContactUs();
            }}
            registrationConfig={leadCustomFormsRegistration}
            contactUsConfig={leadCustomFormsContact}
          />
        )}

      <SettingsCard>
        <Row>
          <H3>{t("Assets")}</H3>
          {!editAssetsSection && (
            <EditButton
              testid={"edit-assets"}
              onClick={() => setEditAssetsSection(true)}
              title={t("Edit Assets")}
            />
          )}
          {editAssetsSection && (
            <CancelButtonSmall onClick={() => setEditAssetsSection(false)} />
          )}
        </Row>
        {!editAssetsSection && (
          <InfoBlockColumn>
            <InfoBlockSmall
              header={t("Public Logo URL")}
              content={branding.publicLogoUrl || "--"}
            />
            <InfoBlockSmall
              header={t("Portal / Admin Logo URL")}
              content={branding.portalLogoUrl || "--"}
            />
            <InfoBlockSmall
              header={t("Browser Tab Icon (Favicon)")}
              content={branding.favIconUrl || "--"}
            />
            <InfoBlockSmall
              header={t("Google Font Family Name")}
              content={fontContent}
            />
            {showCustomFont && (
              <InfoBlockSmall
                header={t("Custom Font Family Name")}
                content={customFont?.font_family_name ?? "--"}
              />
            )}
          </InfoBlockColumn>
        )}
        {editAssetsSection && (
          <EditAssets
            portalLogoUrl={branding.portalLogoUrl}
            publicLogoUrl={branding.publicLogoUrl}
            customFontFamily={branding.customFontFamily}
            customFont={customFont}
            favIconUrl={branding.favIconUrl}
            closeEditAssetsSection={() => setEditAssetsSection(false)}
            mutateStorefrontMetadata={mutateStorefrontMetadata}
          />
        )}
      </SettingsCard>

      <SettingsCard>
        <Row>
          <H3>{t("Asset Languages")}</H3>
          {!editAssetLanguagesSection && (
            <EditButton
              testid={"edit-asset-languages"}
              onClick={() => setEditAssetLanguagesSection(true)}
              title={t("Edit Asset Languagess")}
            />
          )}
          {editAssetLanguagesSection && (
            <CancelButtonSmall
              onClick={() => setEditAssetLanguagesSection(false)}
            />
          )}
        </Row>
        {!editAssetLanguagesSection && (
          <InfoBlockColumn>
            <InfoBlockSmall
              header={t("")}
              content={
                (
                  <ul>
                    {assetLanguageChips?.map((chip) => (
                      <li>{chip.name}</li>
                    ))}
                  </ul>
                ) || "--"
              }
            />
          </InfoBlockColumn>
        )}
        {editAssetLanguagesSection && (
          <EditAssetLanguages
            assetLanguageChips={assetLanguageChips}
            closeEditAssetLanguagesSection={() =>
              setEditAssetLanguagesSection(false)
            }
            mutateAssetLanguages={mutateAssetLanguages}
          />
        )}
      </SettingsCard>

      {Object.entries(localizations)
        // Only allow viewing and editing active localizations.
        .filter(([_, localization]) => localization.is_active)
        .map(([langCode, localization]) => {
          const { name: langName, englishName: englishLangName } = languages?.[
            langCode
          ] ?? { englishName: getLanguageLabel(langCode), name: undefined };
          const langNameString =
            langCode === "en"
              ? langName
              : `${englishLangName} ${langName ? `(${langName})` : ""}`;

          return (
            <SettingsCard key={langCode}>
              <Row>
                <H3>{t("Portfolio Page Content") + ": " + langNameString}</H3>
                {!editLocalizations[langCode] && (
                  <EditButton
                    testid={""}
                    onClick={makeToggleEditPortfolioPageContent(langCode, true)}
                    title={
                      t("Edit Portfolio Page Content") + ": " + langNameString
                    }
                  />
                )}
                {editLocalizations[langCode] && (
                  <CancelButtonSmall
                    onClick={makeToggleEditPortfolioPageContent(
                      langCode,
                      false
                    )}
                  />
                )}
              </Row>
              {!editLocalizations[langCode] && (
                <InfoBlockColumn>
                  {/* 
                    TODO: support translating company name? or relocate?
                    Note that PATCH to /v1/storefronts/{id}/metadata
                    does not change tenant_name.
                  */}
                  {/*
                    <InfoBlockSmall
                      header={"Company Name"}
                      content={storefrontMetadata.tenant_name}
                    />
                  */}
                  <InfoBlockSmall
                    header={t("Header")}
                    content={localization.header || "--"}
                  />
                  <InfoBlockSmall
                    header={t("Sub Header")}
                    content={localization.sub_header || "--"}
                  />
                  <InfoBlockSmall
                    header={t("Search Placeholder")}
                    content={localization.placeholder || "--"}
                  />
                  <InfoBlockSmall
                    header={t("Browser Title")}
                    content={localization.browser_title || "--"}
                  />
                </InfoBlockColumn>
              )}
              {editLocalizations[langCode] && (
                <EditPortfolioPageContent
                  header={localization.header}
                  sub_header={localization.sub_header}
                  placeholder={localization.placeholder}
                  browser_title={localization.browser_title}
                  langCode={langCode}
                  closeEditPortfolioPageContent={makeToggleEditPortfolioPageContent(
                    langCode,
                    false
                  )}
                  mutateStorefrontMetadata={mutateStorefrontMetadata}
                />
              )}
            </SettingsCard>
          );
        })}
      {edition !== "pim" && (
        <>
          <SettingsCard>
            <Row>
              <H3>{t("Registration and Contact Us Form Description")}</H3>
              {!editPageDescription && (
                <EditButton
                  testid={"edit-page-description"}
                  onClick={() => setEditPageDescription(true)}
                  title={t("Edit Page Description")}
                />
              )}
              {editPageDescription && (
                <CancelButtonSmall
                  onClick={() => setEditPageDescription(false)}
                />
              )}
            </Row>
            {formDataError && (
              <ErrorPlaceholder message={t("Something went Wrong!")} />
            )}
            {!editPageDescription && !formDataError && (
              <InfoBlockColumn>
                <InfoBlockSmall
                  header={t("Registration Form Description")}
                  content={
                    registrationDescription ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div class="ql-editor">${registrationDescription}</div>`,
                        }}
                      ></div>
                    ) : (
                      "--"
                    )
                  }
                />
                <InfoBlockSmall
                  header={t("Contact Us Form Description")}
                  content={
                    contactUsDescription ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div class="ql-editor">${contactUsDescription}</div>`,
                        }}
                      ></div>
                    ) : (
                      "--"
                    )
                  }
                />
              </InfoBlockColumn>
            )}
            {editPageDescription && !formDataError && (
              <EditPageDescription
                registration={registrationDescription}
                contact_us={contactUsDescription}
                formData={storefrontFormData?.data}
                closeEditPageDescription={() => setEditPageDescription(false)}
                mutatePageDescription={mutateStorefrontFormData}
              />
            )}
          </SettingsCard>

          {homePageSEO && (
            <SettingsCard>
              <SEO
                seoDetail={homePageSEO}
                fetchData={mutateHomePageSEO}
                seoType="home_page"
                title={`SEO - ${t("Home Page")}`}
              />
            </SettingsCard>
          )}
          {contactPageSEO && (
            <SettingsCard>
              <SEO
                seoDetail={contactPageSEO}
                fetchData={mutateContactPageSEO}
                seoType="contact_page"
                title={`SEO - ${t("Contact Us")}`}
              />
            </SettingsCard>
          )}
          {registerPageSEO && (
            <SettingsCard>
              <SEO
                seoDetail={registerPageSEO}
                fetchData={mutateRegisterPageSEO}
                seoType="register_page"
                title={`SEO - ${t("Register")}`}
              />
            </SettingsCard>
          )}
        </>
      )}
      {/* Only show google analytics settings if storefront is on a custom
          domain. */}
      {custom_domain &&
        (edition === "business" || edition === "enterprise") && (
          <AnalyticsSettings />
        )}
    </>
  );
};
