import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import type { CustomPageConfig } from "../../types/types";

const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
    height: 100%;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: "";
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    & > div {
      height: 100%;
    }
  }
  [dir="rtl"].slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-dots,
  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
  }
  .slick-dots li button:before,
  .slick-next:before,
  .slick-prev:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-next,
  .slick-prev {
    z-index: 1;
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
  }
  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0;
  }
  .slick-next:focus:before,
  .slick-next:hover:before,
  .slick-prev:focus:before,
  .slick-prev:hover:before {
    opacity: 1;
  }
  .slick-next.slick-disabled:before,
  .slick-prev.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 70px;
    line-height: 1;
    opacity: 0.75;
    color: #ffffff;
    text-shadow: 0 0 5px #00000070;
  }
  .slick-prev {
    left: 15px;
  }
  [dir="rtl"].slick-prev {
    right: 15px;
    left: auto;
  }
  .slick-prev:before {
    content: "‹";
  }
  .slick-next:before,
  [dir="rtl"].slick-prev:before {
    content: "›";
  }
  .slick-next {
    right: 15px;
  }
  [dir="rtl"].slick-next {
    right: auto;
    left: 15px;
  }
  [dir="rtl"].slick-next:before {
    content: "‹";
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    bottom: -25px;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
  }
  .slick-dots li button:focus,
  .slick-dots li button:hover {
    outline: 0;
  }
  .slick-dots li button:focus:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: #000;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #000;
  }
`;
interface ISlideProps {
  image: string;
}

const SlideContainer = styled.div<ISlideProps>`
  background-image: url(${({ image }) => image});
  width: 100%;
  height: 100%;
  display: flex !important;
  background-position: 50% 50%;
  background-size: cover;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
`;

type CustomPageCarouselProps = {
  banners: {
    index: number;
    image_url: string;
    title: string | null;
    caption: string | null;
    link_url: string | null;
  }[];
  settings: CustomPageConfig["page_banner_settings"];
};

export function CustomPageCarousel({
  banners,
  settings,
}: CustomPageCarouselProps) {
  const carouselSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    draggable: true,
    pauseOnFocus: true,
    pauseOnHover: false,
    adaptiveHeight: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <SliderWrapper>
      {settings &&
        settings.fixed_overlay &&
        banners.length > 0 &&
        (settings.title || settings.caption) && (
          <div
            className="agc-maxwidth1400px agc-heroflex"
            style={{ padding: 0 }}
          >
            <div
              className="agc-hero-container"
              style={{
                position: "absolute",
                transform: "translateY(60%)",
                marginLeft: "1.42em",
                height: "200px",
              }}
            >
              <div className="agc-hero-headline">
                {settings.title && <h1 className="agc-h1">{settings.title}</h1>}
                {settings.link_url && (
                  <a href={settings.link_url} className="agc-cta w-button">
                    {settings.caption}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      {banners.length > 0 && (
        <Slider {...carouselSettings}>
          {banners.map((banner) => (
            <SlideContainer image={banner.image_url}>
              {(banner.title || banner.caption) &&
                settings &&
                settings.fixed_overlay === false && (
                  <div className="agc-maxwidth1400px agc-heroflex">
                    <div className="agc-hero-container">
                      <div className="agc-hero-headline">
                        {banner.title && (
                          <h1 className="agc-h1">{banner.title}</h1>
                        )}
                        {banner.link_url && (
                          <a
                            href={banner.link_url}
                            className="agc-cta w-button"
                          >
                            {banner.caption}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </SlideContainer>
          ))}
        </Slider>
      )}
    </SliderWrapper>
  );
}
