import noop from "lodash/noop";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import type { InputHTMLAttributes } from "react";
import ReactTooltip from "react-tooltip";
import type { Place } from "react-tooltip";
import styled, { ThemeContext, useTheme } from "styled-components/macro";

interface IToggleSwitchProps {
  label?: string;
  onClick?: () => void;
  theref?: any;
  name?: string;
  isChecked?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const ToggleParentContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const ToggleContainer = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const Label = styled.span`
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.secondaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-left: 11px;
`;

const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 37px;
  height: 22px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:hover,
    &:focus {
      & + span {
        border: 1px solid ${({ theme }) => theme.tertiaryBorder};
      }
    }
  }
`;

const Slider = styled.span<{ disabled?: boolean }>`
  position: absolute;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.toggleBG};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};

  :before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0;
    /* left: 4px; */
    /* bottom: 4px; */
    top: 0px;
    background-color: ${({ theme }) => theme.primaryBG};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:hover,
  &:focus {
    :before {
      border: 1px solid ${({ theme }) => theme.toggleBG};
    }
  }
`;

const Toggle = styled.input.attrs((props) => ({
  type: "checkbox",
}))`
  :checked + span {
    background-color: ${({ theme }) => theme.activeToggleBG};
  }

  :checked + span:before {
    transform: translateX(15px);
  }
`;

/**
 * A toggle element (like a checkbox). This component has local
 * `checked` state and also an `isChecked` prop.  The local state is updated
 * to reflect `isChecked` when `isChecked` changes.  This allows the checked
 * state to be managed either in a parent component or by this component itself.
 *
 * (It might be simpler to have separate components for each use case, one that
 * manages its state locally, and one that always reflects the state of a prop.)
 * @deprecated in favor of ToggleSwitchV2
 */
export const ToggleSwitch = ({
  label,
  onClick,
  theref,
  name,
  isChecked,
  disabled,
  tooltip,
}: IToggleSwitchProps) => {
  const theme = useContext(ThemeContext);
  const [checked, setChecked] = useState<boolean | undefined>(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleClick = () => {
    if (checked === true || checked === false) {
      setChecked(!checked);
    } else {
      setChecked(!isChecked);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <ToggleParentContainer>
      <ToggleContainer disabled={disabled} data-tip={tooltip}>
        <ToggleLabel>
          <Toggle
            name={name}
            id={name}
            ref={theref}
            tabIndex={0}
            onClick={handleClick}
            checked={checked}
            // silence warning about providing checked prop to a form field
            // without and `onChange` handler.
            onChange={noop}
            disabled={disabled}
          />
          <Slider disabled={disabled} />
        </ToggleLabel>
      </ToggleContainer>
      {!!label && (
        <Label style={checked ? { color: theme.primaryTextColor } : undefined}>
          {label}
        </Label>
      )}
      <ReactTooltip effect="solid" />
    </ToggleParentContainer>
  );
};

/**
 * Completetly standard checkbox component, this should be used going forward
 */
export const ToggleSwitchV2 = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    tooltipPlace?: Place;
    tooltip?: string;
  }
>((props, ref) => {
  const theme = useContext(ThemeContext);
  const place = props.tooltipPlace;
  return (
    <ToggleParentContainer>
      <ToggleContainer
        disabled={props.disabled}
        data-tip={props.tooltip}
        data-for={props.name}
      >
        <ToggleLabel>
          <Toggle
            id={props.name}
            tabIndex={0}
            name={props.name}
            disabled={props.disabled}
            ref={ref}
            checked={props.checked}
            defaultChecked={props.defaultChecked}
            onChange={props.onChange}
          />
          <Slider disabled={props.disabled} />
        </ToggleLabel>
      </ToggleContainer>
      {!!props.label && (
        <Label
          style={props.checked ? { color: theme.primaryTextColor } : undefined}
        >
          {props.label}
        </Label>
      )}

      {props.tooltip && (
        <ReactTooltip place={place} id={props.name} effect="solid" />
      )}
    </ToggleParentContainer>
  );
});

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  tooltip?: string;
  label?: string;
  inputRef: any;
}

export function ToggleWithController(props: ToggleProps) {
  const theme = useTheme();

  const { tooltip, label, ...rest } = props;

  return (
    <ToggleParentContainer>
      <ToggleContainer disabled={props.disabled} data-tip={props.tooltip}>
        <ToggleLabel>
          <Toggle {...rest} type="checkbox" />
          <Slider disabled={props.disabled} />
        </ToggleLabel>
      </ToggleContainer>
      {!!props.label && (
        <Label
          style={props.checked ? { color: theme.primaryTextColor } : undefined}
        >
          {props.label}
        </Label>
      )}
      <ReactTooltip effect="solid" />
    </ToggleParentContainer>
  );
}
