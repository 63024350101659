import React, { useState } from "react";
import { SettingsCard } from "../../../../../layout/portalPageLayout";
import { H3, H5, H6 } from "../../../../../components/Typography/Typography";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import useSWR from "swr";
import { useStoreState } from "../../../../../util/util";
import { match } from "ts-pattern";
import type {
  OptionType,
  StorefrontEmailReminderKind,
  StorefrontEmailRemindersSchema,
} from "../../../../../types/types";
import styled from "styled-components/macro";
import type { AxiosError } from "axios";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import {
  CancelButtonSmall,
  EditButton,
} from "../../../../../components/Buttons/Buttons";
import { Row } from "../../../../../components/Layout/Layout";
import { InfoBlockExtraSmall } from "../../../../../components/InfoBlocks/InfoBlocks";
import { ReminderNotificationForm } from "./ReminderNotificationForm";

export const REMINDER_SELECT_BOX_OPTIONS: OptionType<number>[] = [
  { label: "1 Day", value: 1 },
  ...(() => {
    const reminders = [];
    for (let i = 2; i <= 14; i++) {
      reminders.push({ label: `${i} days`, value: i });
    }
    return reminders;
  })(),
];

const REMINDER_ORDER: ReadonlyArray<StorefrontEmailReminderKind> = [
  "accept_order",
  "quote_request",
  "respond_sample",
  "quote_request_lead",
  "contact_us",
  "register",
  "sample_request",
  "accept_price",
] as const;

export function getTitleForReminderType(
  t: TFunction,
  reminder_type: StorefrontEmailReminderKind
): string {
  return match(reminder_type)
    .with("accept_price", () => t("Accept Price"))
    .with("contact_us", () => t("Contact Us Lead"))
    .with("quote_request", () => t("Respond Quote Request"))
    .with("sample_request", () => t("Sample Request Lead"))
    .with("respond_sample", () => t("Respond Sample Request"))
    .with("accept_order", () => t("Accept Order"))
    .with("quote_request_lead", () => t("Quote Request Lead"))
    .with("register", () => t("Registration Lead"))
    .exhaustive();
}

export const ReminderGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 150px) minmax(75px, 200px);
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
`;

export const GridOfReminders = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-column-gap: 20px;
`;

export function ReminderNotifications() {
  const { t } = useTranslation();

  const { storefront_id } = useStoreState();
  const [editMode, setEditMode] = useState(false);

  const {
    data: reminders,
    error: remindersError,
    mutate: mutateReminders,
  } = useSWR<StorefrontEmailRemindersSchema, AxiosError>(
    `/v2/storefronts/${storefront_id}/email-reminders`,
    {
      revalidateOnFocus: false,
    }
  );

  const isLoadingReminders = !reminders && !remindersError;

  if (remindersError) {
    return (
      <SettingsCard>
        <ErrorPlaceholder
          message={t("There was an error loading email reminder configuration")}
        />
      </SettingsCard>
    );
  } else if (isLoadingReminders) {
    return (
      <SettingsCard>
        <DelayedSpinner />
      </SettingsCard>
    );
  } else if (reminders) {
    const sortedReminders = reminders.reminder_data.sort((a, b) => {
      return (
        REMINDER_ORDER.indexOf(a.reminder_type) -
        REMINDER_ORDER.indexOf(b.reminder_type)
      );
    });

    const teamReminders = {
      reminder_data: sortedReminders.filter(
        ({ reminder_type }) => reminder_type !== "accept_price"
      ),
    };

    const customerReminders = {
      reminder_data: sortedReminders.filter(
        ({ reminder_type }) => reminder_type === "accept_price"
      ),
    };

    return (
      <SettingsCard>
        <Row>
          <H3>{t("Email Reminders")}</H3>
          {!editMode && (
            <EditButton
              testid={"edit-email-reminders"}
              onClick={() => {
                setEditMode(true);
              }}
              title={t("Edit Email Reminders")}
            />
          )}
          {editMode && (
            <CancelButtonSmall
              onClick={() => {
                setEditMode(false);
              }}
            />
          )}
        </Row>
        <p style={{ marginTop: "0px" }}>
          {t(
            "Enable or disable reminders based on your preferences, and configure when they will be sent."
          )}
          <br />
          {t(
            "You can set reminders to trigger a specific number of days after an event."
          )}
        </p>
        {!editMode ? (
          <>
            <H5 style={{ marginBottom: "10px" }}>{t("Team Reminders")}</H5>
            <GridOfReminders>
              {teamReminders.reminder_data.map(
                ({ reminder_type, first_reminder, second_reminder }, idx) => {
                  return (
                    <div key={`${reminder_type}_${idx}`}>
                      <H6 style={{ marginBottom: "20px" }}>
                        {getTitleForReminderType(t, reminder_type)}
                      </H6>
                      <ReminderGrid>
                        <InfoBlockExtraSmall
                          header={t("First message sent after")}
                          content={
                            REMINDER_SELECT_BOX_OPTIONS.find(
                              ({ value }) => value === first_reminder
                            )?.label ?? "--"
                          }
                        />

                        <InfoBlockExtraSmall
                          header={t("Second message sent after")}
                          content={
                            REMINDER_SELECT_BOX_OPTIONS.find(
                              ({ value }) => value === second_reminder
                            )?.label ?? "--"
                          }
                        />
                      </ReminderGrid>
                    </div>
                  );
                }
              )}
            </GridOfReminders>
            <H5 style={{ marginBottom: "10px" }}>{t("Customer Reminders")}</H5>
            <GridOfReminders>
              {customerReminders.reminder_data.map(
                ({ reminder_type, first_reminder, second_reminder }, idx) => {
                  return (
                    <div key={`${reminder_type}_${idx}`}>
                      <H6 style={{ marginBottom: "20px" }}>
                        {getTitleForReminderType(t, reminder_type)}
                      </H6>
                      <ReminderGrid>
                        <InfoBlockExtraSmall
                          header={t("First message sent after")}
                          content={
                            REMINDER_SELECT_BOX_OPTIONS.find(
                              ({ value }) => value === first_reminder
                            )?.label ?? "--"
                          }
                        />

                        <InfoBlockExtraSmall
                          header={t("Second message sent after")}
                          content={
                            REMINDER_SELECT_BOX_OPTIONS.find(
                              ({ value }) => value === second_reminder
                            )?.label ?? "--"
                          }
                        />
                      </ReminderGrid>
                    </div>
                  );
                }
              )}
            </GridOfReminders>
          </>
        ) : (
          <ReminderNotificationForm
            teamReminders={teamReminders}
            customerReminders={customerReminders}
            onSuccess={() => {
              setEditMode(false);
              mutateReminders();
            }}
          />
        )}
      </SettingsCard>
    );
  } else return null;
}
