import React from "react";
import styled from "styled-components/macro";
import ReactTooltip from "react-tooltip";
import { SystemDefaultIcon } from "../../../../../components/Icons/Icons";
import { useTranslation } from "react-i18next";

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
`;
export const SystemIconWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  padding-top: 1px;
  margin-left: 8px;
`;
export const NameWithSystemDefaultIcon = ({
  id,
  name,
  is_system,
}: {
  name: string;
  is_system: boolean;
  id: string;
}) => {
  const { t } = useTranslation();
  return (
    <NameContainer>
      {name ?? "--"}
      <SystemIconWrapper data-for={id} data-tip={t("System Default")}>
        {is_system && <SystemDefaultIcon width={18} height={18} />}
      </SystemIconWrapper>
      {is_system && <ReactTooltip id={id} />}
    </NameContainer>
  );
};

const nonAlphanumericCharacters = /[^a-z0-9]/gi;

/**
 * only used for multiline entry attributes, because quill is known to crash
 * with any `&` in the naem with a message about invalid dom selector
 */
export const normalizeAttributeName = (name: string): string =>
  name.replace(nonAlphanumericCharacters, "_");
