import type {
  OptionType,
  StorefrontEmailReminderKind,
  StorefrontEmailReminderNumberRange,
  StorefrontEmailReminderSchema,
  StorefrontEmailRemindersSchema,
} from "../../../../../types/types";
import axios from "axios";
import { t } from "i18next";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import { useFormWrapper, useStoreState } from "../../../../../util/util";
import {
  GridOfReminders,
  REMINDER_SELECT_BOX_OPTIONS,
  ReminderGrid,
  getTitleForReminderType,
} from "./ReminderNotifications";
import type { FieldError, FormState } from "react-hook-form";
import { Controller } from "react-hook-form";
import { PrimaryButtonMedium } from "../../../../../components/Buttons/Buttons";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { ToggleSwitchV2 } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { H5, H6 } from "../../../../../components/Typography/Typography";
import { strings as validations_strings } from "../../../../../util/strings";

type EmailReminderFormValues = Record<
  | `${StorefrontEmailReminderKind}`
  | `${StorefrontEmailReminderKind}_first`
  | `${StorefrontEmailReminderKind}_second`,
  | null
  | boolean
  | Record<
      "first_reminder" | "second_reminder",
      OptionType<StorefrontEmailReminderNumberRange> | null
    >
>;

type ReminderErrorType = Record<
  "first_reminder" | "second_reminder",
  FieldError | undefined
>;

export function ReminderNotificationForm({
  customerReminders,
  teamReminders,
  onSuccess,
}: {
  customerReminders: StorefrontEmailRemindersSchema;
  teamReminders: StorefrontEmailRemindersSchema;
  onSuccess: () => void;
}) {
  const { notifySuccess, notifyError } = useNotifications();
  const { storefront_id } = useStoreState();

  const combinedRemdinders = {
    reminder_data: [
      ...teamReminders.reminder_data,
      ...customerReminders.reminder_data,
    ],
  };

  const defaultValues: EmailReminderFormValues | undefined =
    combinedRemdinders?.reminder_data?.reduce((acc, reminder) => {
      const { reminder_type, first_reminder, second_reminder } = reminder;
      acc[`${reminder_type}_first`] = Boolean(first_reminder);
      acc[`${reminder_type}_second`] = Boolean(second_reminder);
      acc[reminder_type] =
        first_reminder || second_reminder
          ? {
              first_reminder: first_reminder
                ? {
                    label: REMINDER_SELECT_BOX_OPTIONS.find(
                      ({ value }) => value === first_reminder
                    )?.label as string,
                    value: first_reminder,
                  }
                : null,
              second_reminder: second_reminder
                ? {
                    label: REMINDER_SELECT_BOX_OPTIONS.find(
                      ({ value }) => value === second_reminder
                    )?.label as string,
                    value: second_reminder,
                  }
                : null,
            }
          : null;
      return acc;
    }, {} as EmailReminderFormValues);

  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    setValue,
    clearErrors,
  } = useFormWrapper<EmailReminderFormValues>({
    defaultValues,
    shouldUnregister: true,
  });

  const strings = combinedRemdinders?.reminder_data.reduce((acc, reminder) => {
    const { reminder_type } = reminder;
    acc.push(`${reminder_type}_first`);
    acc.push(`${reminder_type}_second`);
    acc.push(`${reminder_type}.first_reminder`);
    acc.push(`${reminder_type}.second_reminder`);
    return acc;
  }, [] as string[]);

  const valuesToWatch: { [key: string]: any } = watch(strings ? strings : []);

  const onSubmit = async (values: EmailReminderFormValues) => {
    const PUTValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (typeof value === "object" && value !== null) {
        acc.push({
          reminder_type: key as StorefrontEmailReminderKind,
          first_reminder: value.first_reminder
            ? value.first_reminder.value
            : null,
          second_reminder: value.second_reminder
            ? value.second_reminder.value
            : null,
        });
      }
      return acc;
    }, [] as StorefrontEmailReminderSchema[]);

    try {
      await axios.put(`/v2/storefronts/${storefront_id}/email-reminders`, {
        reminder_data: PUTValues,
      });

      notifySuccess(t("Email reminders updated successfully"));
      onSuccess();
    } catch (error) {
      notifyError(t("Failed to update email reminders"));
    }
  };

  const generateFormSection = (reminders: StorefrontEmailRemindersSchema) => {
    return (
      <GridOfReminders>
        {reminders.reminder_data.map(
          ({ reminder_type, first_reminder, second_reminder }, idx) => {
            const firstReminderOption =
              valuesToWatch[`${reminder_type}.first_reminder`];

            const optionsForSecondSelect = REMINDER_SELECT_BOX_OPTIONS.filter(
              (option) => option.value > firstReminderOption?.value
            );

            const reminder_error =
              valuesToWatch[`${reminder_type}.second_reminder`]?.value <
              valuesToWatch[`${reminder_type}.first_reminder`]?.value;

            const reminder_error_msg = reminder_error
              ? { message: t("Date must be before second reminder") }
              : null;

            const required_first_reminder_error =
              (errors?.[`${reminder_type}`] as unknown as ReminderErrorType)
                ?.first_reminder?.type === "required"
                ? { message: "Required" }
                : null;

            const required_second_reminder_error =
              (errors?.[`${reminder_type}`] as unknown as ReminderErrorType)
                ?.second_reminder?.type === "required"
                ? { message: "Required" }
                : null;

            return (
              <div key={`${reminder_type}_${idx}`}>
                <H6 style={{ marginBottom: "20px" }}>
                  {getTitleForReminderType(t, reminder_type)}
                </H6>
                <ReminderGrid>
                  <ToggleSwitchV2
                    label={t("First Reminder")}
                    name={`${reminder_type}_first`}
                    ref={register({
                      required: false,
                    })}
                    defaultChecked={Boolean(first_reminder)}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setValue(`${reminder_type}.first_reminder`, null);
                        setValue(`${reminder_type}.second_reminder`, null);
                        setValue(`${reminder_type}_first`, false);
                        setValue(`${reminder_type}_second`, false, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                        clearErrors(`${reminder_type}.first_reminder`);
                        clearErrors(`${reminder_type}.second_reminder`);
                      }
                    }}
                  />
                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name={`${reminder_type}.first_reminder`}
                    placeholder={t("Send After")}
                    options={REMINDER_SELECT_BOX_OPTIONS}
                    shouldUnregister={true}
                    defaultValue={REMINDER_SELECT_BOX_OPTIONS.find(
                      ({ value }) => value === first_reminder
                    )}
                    rules={{
                      required: Boolean(
                        valuesToWatch[`${reminder_type}_first`]
                      ),
                      validate: (reminder_value: OptionType<number>) =>
                        Boolean(valuesToWatch[`${reminder_type}_first`]) &&
                        !reminder_value?.value
                          ? validations_strings(t).thisIsARequiredField
                          : reminder_value?.value && reminder_error
                          ? (t("Date must be before second reminder") as string)
                          : true,
                    }}
                    isDisabled={!valuesToWatch[`${reminder_type}_first`]}
                    errors={errors}
                    error={reminder_error_msg ?? required_first_reminder_error}
                    formState={
                      {
                        submitCount: 1,
                      } as unknown as FormState<EmailReminderFormValues>
                    }
                  />

                  <ToggleSwitchV2
                    label={t("Second Reminder")}
                    name={`${reminder_type}_second`}
                    ref={register({
                      required: false,
                    })}
                    disabled={!valuesToWatch[`${reminder_type}_first`]}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setValue(`${reminder_type}.second_reminder`, null);
                        clearErrors(`${reminder_type}.second_reminder`);
                      }
                    }}
                    defaultChecked={Boolean(second_reminder)}
                  />

                  <Controller
                    as={SelectBoxV2}
                    control={control}
                    name={`${reminder_type}.second_reminder`}
                    placeholder={t("Send After")}
                    shouldUnregister={true}
                    defaultValue={REMINDER_SELECT_BOX_OPTIONS.find(
                      ({ value }) => value === second_reminder
                    )}
                    options={optionsForSecondSelect}
                    rules={{
                      required: Boolean(
                        valuesToWatch[`${reminder_type}_second`]
                      ),
                      validate: (reminder_value: OptionType<number>) =>
                        Boolean(valuesToWatch[`${reminder_type}_second`]) &&
                        !reminder_value?.value
                          ? validations_strings(t).thisIsARequiredField
                          : true,
                    }}
                    isDisabled={!valuesToWatch[`${reminder_type}_second`]}
                    errors={errors}
                    error={required_second_reminder_error}
                    formState={
                      {
                        submitCount: 1,
                      } as unknown as FormState<EmailReminderFormValues>
                    }
                  />
                </ReminderGrid>
              </div>
            );
          }
        )}
      </GridOfReminders>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <H5 style={{ marginBottom: "10px" }}>{t("Team Reminders")}</H5>
      {generateFormSection(teamReminders)}
      <H5 style={{ marginBottom: "10px" }}>{t("Customer Reminders")}</H5>
      {generateFormSection(customerReminders)}
      <PrimaryButtonMedium type="submit">
        {t("Save your changes")}
      </PrimaryButtonMedium>
    </form>
  );
}
