import type { MethodsOfUseForm } from "../../types/types";
import { useState, useEffect } from "react";
import type { PackagingOption } from "./BuyerQuoteItemForm";

/**
 * A hook for clearing all the relevant fields in the buyer quote item form
 * whenever the user changes the "Preferred SKU" (`packaging`) input.
 * It is used in the form component so that its storedPackaging state persists
 * as long as the form component is mounted.
 *
 * Clearing the fields is more consistent and simpler than preserving their
 * values and then making calculations to update things so everything is
 * correct.
 */
export const useQuantityInputFieldClearer = (
  methodsOfUseForm: MethodsOfUseForm
) => {
  const { watch, setValue, clearErrors } = methodsOfUseForm;

  const watchedPackaging: PackagingOption = watch("packaging");

  const [storedPackaging, setStoredPackaging] = useState(watchedPackaging);

  useEffect(() => {
    if (storedPackaging !== watchedPackaging) {
      setValue("no_of_units", "");
      clearErrors("no_of_units");

      setValue("total_quantity", "");
      clearErrors("total_quantity");

      setValue("custom_packaging_type", undefined);
      clearErrors("custom_packaging_type");

      setValue("custom_packaging_quantity", "");
      clearErrors("custom_packaging_quantity");

      setValue("unit_of_measure", undefined);
      clearErrors("unit_of_measure");

      if (
        watchedPackaging.value === "custom_packaging" ||
        watchedPackaging.value === "no_packaging"
      ) {
        // We only clear the disabled UOM field for custom sku and no preference.
        // For product SKUs it needs to stay pre-filled with the UoM from the
        // selected product SKU.
        setValue("disabled_uom", "");
        clearErrors("disabled_uom");
      }

      setStoredPackaging(watchedPackaging);
    }
  }, [
    setValue,
    setStoredPackaging,
    storedPackaging,
    clearErrors,
    watchedPackaging,
  ]);
};
