import type { TFunction } from "i18next";
import type {
  OptionType,
  Tenant,
  TransactionPlacedVia,
  User,
} from "../../types/types";

/**
 * options a seller can select when recording a transaction (quote/order/sample)
 * that occurred offline.
 */
export const TransactionPlacedViaOptions = (
  t: TFunction
): OptionType<TransactionPlacedVia>[] => [
  { value: "Email", label: t("Email") },
  { value: "Fax", label: t("Fax") },
  { value: "Phone", label: t("Phone") },
  { value: "Text", label: t("Text") },
];

export const getUserDetailsFromTenant = (user: Tenant): User => {
  const {
    primary_contact: { first_name, last_name, email_address },
  } = user;
  return {
    tenant_id: user.id,
    id: user.primary_contact_id,
    firstname: first_name,
    lastname: last_name,
    email_address,
  } as User;
};
