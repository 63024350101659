import { Controller } from "react-hook-form";
import { Form } from "../../../../../layout/FormLayout";
import { SearchSelectInfiniteScroll } from "../../../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import { useFormWrapper, useStoreState } from "../../../../../util/util";
import { useTranslation } from "react-i18next";
import { endpoints } from "../../../../../endpoints";
import type { AttributeSchema } from "../../../../../types/types.PIM";
import type {
  OptionType,
  TranslationsSchema,
} from "../../../../../types/types";
import { attributeToOption } from "../../PIM/SellerAdminPIMAttributeGroups/CreateNewAttributeGroup";
import { useState } from "react";
import { SettingsCard } from "../../../../../layout/portalPageLayout";
import { Row } from "../../../../../components/Layout/Layout";
import { H3 } from "../../../../../components/Typography/Typography";
import { SearchFilterCaption } from "./PorfolioControlsTab";
import {
  CancelButtonSmall,
  EditButton,
} from "../../../../../components/Buttons/Buttons";
import {
  InfoBlockColumn,
  InfoBlockSmall,
} from "../../../../../components/InfoBlocks/InfoBlocks";
import useSWR from "swr";
import { useCookies } from "react-cookie";
import { SaveButtonPrimaryMedium } from "../shared";
import axios from "axios";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";

type FormValue = {
  search_attribute: OptionType<string> & {
    display_name: string;
    id: string;
  };
};

export const EnterpriseSearchSuggestionsSettings = () => {
  const [edit_search_suggestions, set_edit_search_suggestions] =
    useState(false);
  const [is_submitting, set_is_submitting] = useState(false);
  const { slug, storefront_id, tenant_id } = useStoreState();
  const [cookies] = useCookies([`preferred-language-${slug}`]);
  const [selected_attribute, set_selected_attribute] =
    useState<TranslationsSchema & { id: string }>();
  const { control, errors, formState, handleSubmit } = useFormWrapper();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotifications();
  const preferred_language = cookies[`preferred-language-${slug}`];
  const { mutate } = useSWR<{ data: AttributeSchema[] }>(
    endpoints.v2_storefronts_id_search_display_attributes(storefront_id),
    {
      onSuccess: (data) => {
        const selected_attr = data?.data?.[0];
        const translated_selected_attr = selected_attr?.translations?.find(
          ({ language }) => language === preferred_language
        );
        set_selected_attribute(
          translated_selected_attr
            ? { ...translated_selected_attr, id: selected_attr.id }
            : {
                language: preferred_language,
                display_name: selected_attr?.display_name ?? "",
                description: selected_attr?.description ?? "",
                id: selected_attr.id,
              }
        );
      },
    }
  );

  const onSubmit = async (formValues: FormValue) => {
    set_is_submitting(true);
    try {
      await axios.put(
        endpoints.v2_storefronts_id_search_display_attributes(storefront_id),
        {
          attributes: [formValues.search_attribute.id],
        }
      );
      await mutate();
      notifySuccess(t("Search suggestions updated successfully"));
    } catch (error) {
      notifyError(t("Failed to update search suggestions"));
    } finally {
      set_is_submitting(false);
      set_edit_search_suggestions(false);
    }
  };

  return (
    <SettingsCard>
      <Row>
        <div>
          <H3>{t("Enterprise Search Suggestions")}</H3>
          <SearchFilterCaption>
            {t(
              "Configure which attribute to display in search suggestions. Any restricted attributes will not be included."
            )}
          </SearchFilterCaption>
        </div>
        {!edit_search_suggestions && (
          <EditButton
            testid={"edit-search-suggestions"}
            onClick={() => set_edit_search_suggestions(true)}
            title={t("Edit Enterprise Search Suggestions")}
          />
        )}
        {edit_search_suggestions && (
          <CancelButtonSmall
            onClick={() => set_edit_search_suggestions(false)}
          />
        )}
      </Row>
      {!edit_search_suggestions && (
        <InfoBlockColumn>
          <InfoBlockSmall
            header={t("")}
            content={selected_attribute?.display_name ?? "Description"}
          />
        </InfoBlockColumn>
      )}
      {edit_search_suggestions && (
        <Form
          style={{ maxWidth: "500px" }}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            as={SearchSelectInfiniteScroll}
            control={control}
            name="search_attribute"
            autoComplete="on"
            baseUrl={endpoints.v2_tenants_id_pim_attributes(tenant_id)}
            getOptions={(response: AttributeSchema[]) =>
              response.reduce(
                (
                  acc: FormValue["search_attribute"][],
                  cur: AttributeSchema
                ) => {
                  const attribute_option = {
                    ...attributeToOption(cur),
                    display_name: cur.display_name
                      ? cur.display_name
                      : cur.name,
                    id: cur.id,
                  };
                  acc.push(attribute_option);
                  return acc;
                },
                []
              )
            }
            placeholder={t("Attribute")}
            testid="search_attribute"
            defaultOptions
            defaultValue={{
              label: selected_attribute?.display_name ?? "",
              value: selected_attribute?.id ?? "",
            }}
            errors={errors}
            formState={formState}
          />
          <SaveButtonPrimaryMedium loading={is_submitting}>
            {t("Save your changes")}
          </SaveButtonPrimaryMedium>
        </Form>
      )}
    </SettingsCard>
  );
};
