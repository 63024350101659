import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useAuthContext } from "../../../../../components/Auth";
import {
  RestoreButton,
  ButtonWithConfirmDialog,
} from "../../../../../components/Buttons/Buttons";
import type {
  ListItemPatchArgSchema,
  ListItemSchema,
  PaginatedListItemSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import { toTitleCase, useStoreState } from "../../../../../util/util";
import { ItemDetailsWrapper } from "./ListDetails";
import { IconContainer, ListFooter, ListHeader } from "./ListHome";
import { ListItemBase, ListItemName } from "./ListItem";
import useSWR from "swr";
import { endpoints } from "../../../../../endpoints";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { Pagination } from "../../../../../components/Pagination/Pagination";
import axios from "axios";
import type { AxiosError } from "axios";
import { useNotifications } from "../../../../../components/Notifications/NotificationsContext";
import { ErrorPlaceholder } from "../../../../../components/Error";

export const ArchivedListWrapper = styled.div<{ height: string }>`
  flex: 1 1 80%;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height};
  overflow-y: scroll;
`;

export const ArchivedListItemDetails = ({
  item,
  onRestore,
  pageHeight,
}: {
  item: ShortListSchema;
  onRestore: () => Promise<void>;
  pageHeight: string;
}) => {
  const perPageOptions = [10, 20, 50];
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(perPageOptions[0]);
  const [pagination, setPagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [listItems, setListItems] = useState<ListItemSchema[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>();
  const { tenant_id } = useStoreState();
  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useNotifications();
  const { hasPermission } = useAuthContext();

  const {
    data,
    error: archivedListItemError,
    mutate: mutateArchivedListItem,
  } = useSWR<PaginatedListItemSchema>(
    [
      endpoints.v2_tenants_id_or_slug_pim_lists_id_items(tenant_id, item.id),
      useMemo(
        () => ({
          params: { offset, limit: perPage, status: "archived" },
        }),
        [offset, perPage]
      ),
    ],
    {
      revalidateOnFocus: false,
      onSuccess: ({ data }) => setListItems(data),
    }
  );

  const selectItem = useCallback((item: string) => setSelectedItem(item), []);

  const updateOffset = () => {
    const itemsRemainingInCurrentPage = (data!.pagination.total - 1) % perPage;
    if (itemsRemainingInCurrentPage === 0 && offset > 0) {
      // load the previous page data
      setOffset(offset - perPage);
    }
  };

  const restoreArchivedItem = async (listItem: ListItemSchema) => {
    try {
      const formData = new FormData();
      formData.append("is_deleted", "false");

      await axios.patch<ListItemPatchArgSchema, ListItemSchema>(
        endpoints.v2_tenants_id_or_slug_pim_lists_id_items_id(
          tenant_id,
          item.id,
          listItem.id
        ),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      notifySuccess(t("List item restored successfully"));
      updateOffset();
      mutateArchivedListItem();
      await onRestore();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not restore list item. Something went wrong."),
        {
          error,
        }
      );
    }
  };

  useEffect(() => {
    setPagination({
      perPage: perPage,
      pageCount: Math.ceil((data?.pagination.total ?? 10) / perPage),
      pageIndex: (data?.pagination.offset ?? 10) / perPage + 1,
    });
  }, [data?.pagination.offset, data?.pagination.total, perPage]);

  return archivedListItemError ? (
    <ErrorPlaceholder
      message={t(
        "There was an error fetching the list details. Please try again later."
      )}
    />
  ) : !data && !archivedListItemError ? (
    <DelayedSpinner />
  ) : (
    <ArchivedListWrapper height={pageHeight}>
      <ListHeader>{`${toTitleCase(item.name)} > Archived`}</ListHeader>
      {listItems.map((listItem) => (
        <ItemDetailsWrapper
          active={selectedItem === listItem.name}
          onClick={() => selectItem(listItem.name)}
          key={listItem.id}
        >
          <ListItemName>{toTitleCase(listItem.name)}</ListItemName>
          <IconContainer>
            {hasPermission("modify_lists") && (
              <ButtonWithConfirmDialog
                Button={(props) => (
                  <RestoreButton {...props} width={24} height={24} />
                )}
                testid={`list-item-${listItem.id}`}
                handleConfirm={() => restoreArchivedItem(listItem)}
                confirmMessage={t(
                  "Are you sure you want to restore this list item, {{itemName}}?",
                  { itemName: listItem.name }
                )}
              />
            )}
          </IconContainer>
        </ItemDetailsWrapper>
      ))}
      {pagination.pageCount > 1 && (
        <ListItemBase style={{ height: "fit-content" }}>
          <Pagination
            pagination={pagination}
            offset={offset}
            handlePageClick={(offset) => setOffset(offset)}
          />
        </ListItemBase>
      )}
      <ListFooter />
    </ArchivedListWrapper>
  );
};
