import type { Payment, PaymentFeePercentages } from "../../types/types";

/**
 * returns a payment object with the values as strings representing a number of
 * cents. This format is to match what the backend sends us as part of
 * order/quote objects to avoid code duplication when it comes time to display
 * @param percentages
 * @param total
 * @returns
 */
export function createPaymentObjectFromPercentages(
  percentages: PaymentFeePercentages,
  total: number
): Pick<
  Payment,
  "estimated_agilis_fee" | "estimated_stripe_fee" | "estimated_net"
> {
  const estimated_agilis_fee =
    total * Number(percentages.agilis_application_fee_percent) +
    Number(percentages.agilis_application_fee_base);

  const estimated_stripe_fee =
    total * Number(percentages.stripe_fee_percent) +
    Number(percentages.stripe_fee_base);

  const estimated_net =
    total - Number(estimated_agilis_fee) - Number(estimated_stripe_fee);

  return {
    estimated_agilis_fee: String(estimated_agilis_fee),
    estimated_stripe_fee: String(estimated_stripe_fee),
    estimated_net: String(estimated_net),
  };
}
