import React, { useState } from "react";
import styled from "styled-components/macro";
import { VisibleIcon } from "../Icons/Icons";

const Header = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.secondaryTextColor};
  line-height: 1.45;
`;

const LargeContent = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.large};
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: 1.45;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const InfoBlockExtraSmallContainer = styled.div`
  max-width: 100%;
`;

interface IInfoBlockLargeProps {
  header: string;
  content: JSX.Element | string;
  icon?: any;
  callback?: any;
}

export const InfoBlockLarge = ({
  header,
  content,
  icon,
  callback,
}: IInfoBlockLargeProps) => {
  const [hover, setHover] = useState(false);
  if (icon !== undefined && callback !== undefined) {
    return (
      <div>
        <Header>{header}</Header>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <LargeContent>{content}</LargeContent>
          <span
            onClick={callback}
            style={{
              cursor: hover ? "pointer" : "none",
              marginLeft: "5px",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {icon}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Header>{header}</Header>
        <LargeContent>{content}</LargeContent>
      </div>
    );
  }
};

const MediumContent = styled.div`
  display: inline-block;
  font-family: ${({ theme }) => theme["fontFamily"]};
  font-weight: ${({ theme }) => theme.fontWeights["medium"]};
  font-size: ${({ theme }) => theme.fontSizes["medium"]};
  line-height: 1.45;
`;

interface IInfoBlockMediumProps {
  header: string;
  content: JSX.Element | string;
  icon?: any;
}

export const InfoBlockMedium = ({
  header,
  content,
  icon,
}: IInfoBlockMediumProps) => (
  <>
    <Header>{header}</Header>
    <IconContainer>
      <span style={{ marginRight: "4px" }}>{icon}</span>
      <MediumContent>{content}</MediumContent>
    </IconContainer>
  </>
);

const SmallContent = styled.div`
  /* This is 15px for font-size because cole went of the plan for fontsizes. Its a special case. */
  margin: 0;
  font-family: ${({ theme }) => theme["fontFamily"]};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: 15px;
  line-height: 1.54;
  max-width: 100%;
  /* prevent collapse if briefly no content during conditional render. */
  min-height: 23px;
`;

interface IInfoBlockSmallProps {
  header: string;
  content?: JSX.Element | string;
  icon?: any;
  testid?: string;
}

export const InfoBlockSmall = ({
  header,
  content,
  icon,
  testid,
}: IInfoBlockSmallProps) => {
  return (
    <div data-testid={testid}>
      <Header>{header}</Header>
      {icon ? (
        <IconContainer>
          <span style={{ marginRight: "4px" }}>{icon}</span>
          <SmallContent>{content}</SmallContent>
        </IconContainer>
      ) : (
        <SmallContent>{content}</SmallContent>
      )}
    </div>
  );
};

const ContentExtraSmall = styled(SmallContent)`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const HeaderExtraSmall = styled(Header)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

const IconWrapper = styled.span`
  margin: 4px 4px 0 0;
`;

export const InfoBlockExtraSmall = ({
  header,
  content,
  icon,
  testid,
}: IInfoBlockSmallProps) => {
  return (
    <InfoBlockExtraSmallContainer data-testid={testid}>
      <HeaderExtraSmall>{header}</HeaderExtraSmall>
      {icon ? (
        <IconContainer>
          <IconWrapper>{icon}</IconWrapper>
          <ContentExtraSmall>{content}</ContentExtraSmall>
        </IconContainer>
      ) : (
        <ContentExtraSmall>{content}</ContentExtraSmall>
      )}
    </InfoBlockExtraSmallContainer>
  );
};

const MobileBlock = styled.div`
  display: inline-block;
  font-family: ${({ theme }) => theme["fontFamily"]};
  font-weight: ${({ theme }) => theme.fontWeights["extra-small-body-plus"]};
  font-size: ${({ theme }) => theme.fontSizes["extra-small-body-plus"]};
`;

interface IMobileInfoBlockProps {
  ask: any;
  left: any;
}

export const InfoBlockMobile = ({ ask, left }: IMobileInfoBlockProps) => (
  <MobileBlock>
    <span style={{ marginRight: "4px" }}>
      <VisibleIcon width={13} height={9} />
    </span>
    {ask} / {left}
  </MobileBlock>
);

const GreyLine = styled.div`
  width: 1px;
  height: 100%;
  border: solid 2px #e7e8ea;
  margin-right: 15px;
`;
const GreyLineContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
const GreyLineContent = styled.span`
  font-family: "Inter";
  font-size: 11px;
  line-height: 1.45;
  color: #6f777f;
`;
interface IGreyLineBlock {
  content: string;
}

export const GreyLineBlock = ({ content }: IGreyLineBlock) => (
  <GreyLineContainer>
    <GreyLine />
    <GreyLineContent>{content}</GreyLineContent>
  </GreyLineContainer>
);

// Used to display info blocks in a row. Typically used with InfoBlockSmall.
// TODO: add media queries here, these styles break on mobile
export const InfoBlockRow = styled.div`
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  div {
    margin-right: 20px;
  }
  padding-top: 27px;
  padding-bottom: 27px;
  /* padding-left: 21px; */
`;

// Used to add spacing between info blocks arranged in a column. Typically used
// with InfoBlockExtraSmall.
export const InfoBlockSpacer = styled.div`
  margin-top: 14px;
  margin-bottom: 10px;
`;

export const InfoBlockColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  padding-top: 5px;
  padding-bottom: 27px;
  div {
    margin-bottom: 5px;
  }
`;
