/**
 * get types and values for asset page
 */

import type { CSSProperties } from "react";
import { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import type { DefaultTheme, StyledComponent } from "styled-components/macro";
import styled, { useTheme } from "styled-components/macro";
import { checkTextOverflow } from "../../../../../../util/util";
import type {
  SupportedAssetCategoryType,
  SupportedAssetType,
} from "../../../../../../types/types.PIM";
import type {
  OptionType,
  SupportedLanguage,
} from "../../../../../../types/types";
import type { SupportedFileType } from "../../../../../../components/FilePicker/FilePicker.constants";
import {
  CSVIcon,
  DOCIcon,
  DOCXIcon,
  DocumentIcon,
  ImageIcon,
  JSONIcon,
  PDFIcon,
  PPTIcon,
  PPTXIcon,
  RTFIcon,
  TXTIcon,
  VideoIcon,
} from "../../../../../../components/Icons/Icons";

const CONTENT_TYPE_SUFFIX_MAP = {
  "image/gif": "gif",
  "application/csv": "csv",
  "text/csv": "csv",
  "image/svg+xml": "svg",
  "image/png": "png",
  "image/jpeg": "jpg",
  "application/pdf": "pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
  "application/vnd.ms-powerpoint": "ppt",
  "application/json": "json",
  "text/plain": "txt",
  "video/mp4": "mp4",
  "video/quicktime": "mov",
  "video/x-ms-asf": "wmv",
  "video/x-msvideo": "avi",
  "video/webm": "webm",
  "video/x-flv": "flv",
  "video/x-matroska": "mkv",
  "application/zip": "zip",
  "application/rtf": "rtf",
  "text/rtf": "rtf",
};

export type AssetCategory = {
  asset_categories: { id: string; name: SupportedAssetCategoryType }[];
};

export type AssetType = {
  asset_types: SupportedAssetType[];
};

export const TrimmedSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
`;

/**
 * Attaches a tooltip to a styled component or an html element
 * that overflows its proposed width.
 * It truncates the overflow with an ellipsis, then on hover, a tooltip with the complete text appears.
 */
export const TrimmedName = ({
  text,
  Content,
}: {
  text: string;
  Content?: StyledComponent<"span" | "div", DefaultTheme, {}, never>;
}) => {
  const [textOverflow, setTextOverflow] = useState(false);
  const theme = useTheme();
  const [spanRef] = useState(useRef<HTMLSpanElement | HTMLDivElement>(null));

  const trimStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    width: "100%",
  } as CSSProperties;

  useEffect(() => {
    const isTextOverflow = spanRef.current
      ? checkTextOverflow(spanRef.current).widthOverflow
      : false;
    setTextOverflow(isTextOverflow);
  }, [spanRef]);

  return (
    <>
      {Content ? (
        <Content
          style={trimStyle}
          data-for={`${text}-tooltip`}
          data-tip={text}
          ref={spanRef}
        >
          {text}
        </Content>
      ) : (
        <TrimmedSpan data-for={`${text}-tooltip`} data-tip={text} ref={spanRef}>
          {text}
        </TrimmedSpan>
      )}
      {textOverflow && (
        <ReactTooltip
          id={`${text}-tooltip`}
          place="top"
          data-html={true}
          effect="solid"
          backgroundColor={theme.tooltipBG}
          multiline={true}
        />
      )}
    </>
  );
};

export function formatBytes(bytes: number, decimals: number = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const getLanguageOptionFromSupportedLanguage = (
  langLabel: string,
  supportedLanguages: Record<string, SupportedLanguage>
) => {
  return Object.entries(supportedLanguages).reduce<
    OptionType<SupportedLanguage>
  >(
    (acc, supportedLang) => {
      const [value, label] = supportedLang;
      if (label === langLabel) {
        acc = { label, value };
      }
      return acc;
    },
    { label: "", value: "" }
  );
};

export const get_content_type = (content_type: string) =>
  `.${
    CONTENT_TYPE_SUFFIX_MAP[
      content_type as keyof typeof CONTENT_TYPE_SUFFIX_MAP
    ]
  }` as SupportedFileType;

export const GetDocumentIcon = ({
  asset_type,
  file_type,
  width = 24,
  height = 24,
  fill,
}: {
  asset_type: SupportedAssetType;
  file_type: SupportedFileType;
  width?: number;
  height?: number;
  fill?: string;
}) => {
  switch (asset_type) {
    case "image":
      return <ImageIcon width={width} height={height} fill={fill} />;
    case "video":
      return <VideoIcon width={width} height={height} fill={fill} />;
    case "document":
      switch (file_type) {
        case ".csv":
          return <CSVIcon width={width} height={height} fill={fill} />;
        case ".doc":
          return <DOCIcon width={width} height={height} fill={fill} />;
        case ".docx":
          return <DOCXIcon width={width} height={height} fill={fill} />;
        case ".pdf":
          return <PDFIcon width={width} height={height} fill={fill} />;
        case ".json":
          return <JSONIcon width={width} height={height} fill={fill} />;
        case ".ppt":
          return <PPTIcon width={width} height={height} fill={fill} />;
        case ".pptx":
          return <PPTXIcon width={width} height={height} fill={fill} />;
        case ".rtf":
          return <RTFIcon width={width} height={height} fill={fill} />;
        case ".txt":
          return <TXTIcon width={width} height={height} fill={fill} />;
        default:
          return <DocumentIcon width={width} height={height} fill={fill} />;
      }
  }
};
