/**
 * Returns true on truthy values and some falsy values
 * Permitted falsy values are: 0, -0, false
 * @param val
 * @returns
 */
export const hasValue = (
  val:
    | string
    | { label: string; value: string | number | boolean }
    | null
    | undefined
) => {
  return val
    ? typeof val === "string"
      ? !!val
      : val.value !== "" && val.value !== null && val.value !== undefined
    : false;
};
