import { strings } from "./strings";
import Axios from "axios";
import { useContext } from "react";
import { Notifications } from "../components/Notifications/NotificationsContext";
import { useTranslation } from "react-i18next";

interface EmailValidationResponse {
  valid: boolean;
  error_message: string | null;
}

type ValidateEmailProps = {
  email: string;
  source?: string;
};

/**
 * A hook to validate an email address via sendgrid.
 */
export const useValidateEmail = () => {
  const { notifyError } = useContext(Notifications);
  const { t } = useTranslation();

  /**
   * Validate an email address via sendgrid, passing in an object as props.
   *
   * @param props
   * @param props.email The email address to validate.
   * @param props.source The source of the email address in the UI.
   * @returns True for valid, false for invalid.
   */
  const validateEmail = async ({
    email,
    source,
  }: ValidateEmailProps): Promise<boolean> => {
    try {
      const response = await Axios.post<EmailValidationResponse>(
        `/v1/emails/validate`,
        source ? { email, source } : { email }
      );
      return response.data.valid;
    } catch (error) {
      notifyError(strings(t).emailValidationError, { error });
      return false;
    }
  };

  return { validateEmail };
};
