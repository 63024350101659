import { useCallback, useEffect, useState } from "react";
import {
  ContentWrapper,
  FullWidthHorizontalSeparator,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../../layout/portalPageLayout";
import {
  HeaderLeft,
  HeaderRight,
} from "../../../../../components/Layout/Layout";
import { CancelButton } from "../../../../../components/Buttons/Buttons";
import { useTranslation } from "react-i18next";
import { CreateNewProductPage } from "./CreateNewProductPage";
import { CreateFromExistingProductPage } from "./CreateFromExistingProductPage";
import { useHistory } from "react-router-dom";
import type { FormSource } from "./CreatePimProduct.utils";
import { NumberParam, useQueryParams } from "use-query-params";
import { useRoutePath } from "../../../../../util/Routing";
import { TabPanels, Tabs } from "@reach/tabs";
import { CustomTabPanel } from "../../../../../components/ReachTabs/TabPanel";
import { TabList } from "../../../../../components/ReachTabs/TabList";
import { Tab } from "../../../../../components/ReachTabs/Tab";
import { CreatePimProductFromUploads } from "./CreateFromUploads/CreatePimProductFromUpload";
import { useStoreState } from "../../../../../util/util";

export const CreatePimProductPage = () => {
  const [formSource, setFormSource] = useState<FormSource>("new");
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [query, setQuery] = useQueryParams({ tab: NumberParam });
  const {
    storefront_metadata: { only_bulk_upload_enabled },
  } = useStoreState();

  const { t } = useTranslation();
  const { adminPath } = useRoutePath();
  const history = useHistory();

  /**
   * Despite the naming here in reality for every storefront except TER all 3 tabs will show up,
   * perhaps one day there will be 3 separate product creation RBAC permissions
   */

  const tabsThatAlwaysExist = [
    <Tab key="product_upload">{t("Bulk Upload")}</Tab>,
  ];

  const optionalTabs = [
    <Tab key="new_product">{t("New Product")}</Tab>,
    <Tab key="existing_product">{t("Use Existing Product")}</Tab>,
  ];

  const TabsList = [
    ...(!only_bulk_upload_enabled ? optionalTabs : []),
    ...tabsThatAlwaysExist,
  ];

  const TabPanelListAlwaysExists = [
    <CustomTabPanel key="product_upload" refreshKey={currentTab}>
      <CreatePimProductFromUploads />
    </CustomTabPanel>,
  ];

  const TabPanelListOptional = [
    <CustomTabPanel key="new_product" refreshKey={currentTab}>
      <CreateNewProductPage formSource={formSource} />
    </CustomTabPanel>,
    <CustomTabPanel key="existing_product" refreshKey={currentTab}>
      <CreateFromExistingProductPage formSource={formSource} />
    </CustomTabPanel>,
  ];

  const TabPanelList = [
    ...(!only_bulk_upload_enabled ? TabPanelListOptional : []),
    ...TabPanelListAlwaysExists,
  ];

  const changeTab = useCallback(
    (index: number) => {
      setCurrentTab(index);
      setQuery({ tab: index });
      if (index < 2) {
        setFormSource(index === 0 ? "new" : "existing");
      }
    },
    [setQuery]
  );

  useEffect(() => {
    if (query.tab) {
      changeTab(query.tab);
    }
  }, [changeTab, query]);

  return (
    <PageWrapper>
      <PageHeader>
        <HeaderLeft>
          <PageTitle>{t("Create Product")}</PageTitle>
        </HeaderLeft>
        <HeaderRight>
          <CancelButton
            onClick={() => history.push(`${adminPath}/pim/products`)}
          >
            {t("Cancel")}
          </CancelButton>
        </HeaderRight>
      </PageHeader>
      <ContentWrapper>
        <Tabs onChange={changeTab} index={currentTab}>
          <TabList>{TabsList}</TabList>
          <FullWidthHorizontalSeparator />
          <TabPanels>{TabPanelList}</TabPanels>
        </Tabs>
      </ContentWrapper>
    </PageWrapper>
  );
};
