import type { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { Table } from "../../../../components/Table/Table";
import type {
  AssignedProductSchema,
  AssignedUserSchema,
  TeamDetailsSchema,
} from "../../../../types/types";
import {
  toTitleCase,
  useHistoryGoBack,
  useStoreState,
} from "../../../../util/util";
import {
  FullWidthHorizontalSeparator,
  PageHeader,
  PageTitle,
  PageWrapper,
} from "../../../../layout/portalPageLayout";
import {
  InvisibleButton,
  GoBackButtonSmall,
  DestructiveButtonLarge,
  PrimaryButtonLarge,
  ButtonWithConfirmDialog,
} from "../../../../components/Buttons/Buttons";
import { useRoutePath } from "../../../../util/Routing";
import { H3 } from "../../../../components/Typography/Typography";
import {
  ArchiveIcon,
  EditIcon,
  RestoreIcon,
} from "../../../../components/Icons/Icons";
import { useTheme } from "styled-components";
import {
  StatusLeft,
  getProductStatusColor,
  getProductStatusText,
} from "../../../../components/Status/Status";
import type { ProductStatusType } from "../../../../types/types.PIM";
import axios from "axios";
import { useNotifications } from "../../../../components/Notifications/NotificationsContext";
import { HeaderLeft, HeaderRight } from "../../../../components/Layout/Layout";
import { useAuthContext } from "../../../../components/Auth";
import { useParams } from "react-router-dom";
import { SlideOut } from "../../../../components/SlideOut/SlideOut";
import { SellerAdminEditTeam } from "./SellerAdminEditTeam";

const LargeDestructiveButtonWithArchiveIcon = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  const theme = useTheme();
  return (
    <DestructiveButtonLarge {...props}>
      <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <ArchiveIcon width={22} height={22} fill={theme.destructiveTextColor} />
        {props.children}
      </span>
    </DestructiveButtonLarge>
  );
};

const LargePrimaryButtonWithRestoreIcon = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <PrimaryButtonLarge {...props}>
      <span style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <RestoreIcon width={22} height={22} />
        {props.children}
      </span>
    </PrimaryButtonLarge>
  );
};

export function SellerAdminTeamDetail() {
  const [userTableData, setUserTableData] = useState<
    {
      id: string;
      user_name: string;
      role: string;
      status: boolean;
    }[]
  >([]);

  const [productTableData, setProductTableData] = useState<
    {
      product_number: string;
      product_name: string;
      template: string;
      status: string;
    }[]
  >([]);

  const { tenant_id } = useStoreState();
  const { roleIsSellerAdmin } = useAuthContext();
  const [showEditTeamForm, setShowEditTeamForm] = useState(false);

  const { team_id } = useParams<{ team_id: string }>();

  const { t } = useTranslation();

  const { historyGoBack } = useHistoryGoBack();
  const { adminPath } = useRoutePath();

  const { notifyError, notifySuccess } = useNotifications();

  const {
    data: team,
    mutate: mutateTeam,
    error: teamError,
  } = useSWR<TeamDetailsSchema, AxiosError>(
    `/v2/tenants/${tenant_id}/pim/teams/${team_id}`
  );

  const isLoading = !team && !teamError;

  const userTableColumns = useMemo(
    () => [
      {
        Header: t("User Name"),
        accessor: "user_name",
      },
      {
        Header: t("Role"),
        accessor: "role",
        Cell: ({ value }: { value: string }) => <div>{toTitleCase(value)}</div>,
      },
      {
        Header: t("Status"),
        accessor: "status",
        width: 50,
        minWidth: 50,
        align: "left",
        Cell: ({ value }: { value: boolean }) => (
          <StatusLeft
            color={value ? "green" : "red"}
            text={value ? t("Active") : t("Inactive")}
          />
        ),
      },
    ],
    [t]
  );

  const productTableColumns = useMemo(
    () => [
      {
        Header: t("Product ID"),
        accessor: "product_number",
      },
      {
        Header: t("Product Name"),
        accessor: "product_name",
      },
      {
        Header: t("Template"),
        accessor: "template",
      },
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,
        align: "left",
        Cell: ({ value: status }: { value: ProductStatusType }) => (
          <StatusLeft
            color={getProductStatusColor(status ?? "draft")}
            text={getProductStatusText(status ?? "draft", t)}
            textStyle={{ fontSize: "13px" }}
            productStatusType="product"
          />
        ),
      },
    ],
    [t]
  );

  const theme = useTheme();

  const onArchiveTeam = async () => {
    try {
      await axios.patch(`/v2/tenants/${tenant_id}/pim/teams/${team_id}`, {
        status: "archived",
      });
      notifySuccess(t("Team archived successfully"));
      await mutateTeam();
    } catch (error) {
      notifyError("There was an error archiving the team");
    }
  };

  const onRestoreTeam = async () => {
    try {
      await axios.patch(`/v2/tenants/${tenant_id}/pim/teams/${team_id}`, {
        status: "active",
      });
      notifySuccess(t("Team restored successfully"));
      await mutateTeam();
    } catch (error) {
      notifyError("There was an error restoring the team");
    }
  };

  const onEditSuccess = async () => {
    setShowEditTeamForm(false);
    await mutateTeam();
  };

  useEffect(() => {
    const handleUsersData = (data: AssignedUserSchema[]) => {
      setUserTableData(
        data.map((user) => ({
          id: user.user_id,
          user_name: `${user.firstname} ${user.lastname}`,
          role: user.role ?? "--", // For at least some users locally this is nullable
          status: user.is_active,
        }))
      );
    };

    const handleProductsData = (data: AssignedProductSchema[]) => {
      setProductTableData(
        team?.status === "archived"
          ? []
          : data.map((product) => ({
              product_number: product.product_number,
              product_name: product.product_name,
              template: product.product_schema.template_name,
              status: product.status,
            }))
      );
    };
    if (team?.users) {
      handleUsersData(team.users);
    }

    if (team?.products) {
      handleProductsData(team.products);
    }
  }, [team?.users, team?.products, team?.status]);

  return (
    <PageWrapper>
      <InvisibleButton
        onClick={() => historyGoBack(`${adminPath}/organization/teams`)}
      >
        <GoBackButtonSmall text={t("Back")} />
      </InvisibleButton>
      <PageHeader>
        <HeaderLeft>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <PageTitle>{team ? team.team_name : ""}</PageTitle>
          </div>
        </HeaderLeft>
        {team?.status && (
          <HeaderRight>
            <StatusLeft
              color={team.status === "active" ? "green" : "red"}
              text={team.status}
              textStyle={{ fontSize: "13px" }}
              productStatusType="product"
            />
          </HeaderRight>
        )}
      </PageHeader>
      <FullWidthHorizontalSeparator />
      <div style={{ marginBottom: "30px" }}></div>
      <H3>{t("Users")}</H3>
      <Table
        columns={userTableColumns}
        isLoading={isLoading}
        error={teamError}
        data={userTableData}
      />

      <H3>{t("Assigned Products")}</H3>
      <Table
        columns={productTableColumns}
        isLoading={isLoading}
        error={teamError}
        data={productTableData}
      />
      {roleIsSellerAdmin && (
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            gap: "24px",
          }}
        >
          {team && team.status === "active" && (
            <ButtonWithConfirmDialog
              Button={LargeDestructiveButtonWithArchiveIcon}
              testid={"archive-button"}
              buttonText={t("Archive Team")}
              handleConfirm={onArchiveTeam}
              confirmMessage={t("Are you sure you want to archive this team?")}
            />
          )}
          {team && team.status === "archived" && (
            <ButtonWithConfirmDialog
              Button={LargePrimaryButtonWithRestoreIcon}
              testid={`restore-team`}
              handleConfirm={onRestoreTeam}
              buttonText={t("Restore Team")}
              confirmMessage={t("Are you sure you want to restore this team?")}
            />
          )}

          {team && team.status !== "archived" ? (
            <PrimaryButtonLarge onClick={() => setShowEditTeamForm(true)}>
              <span
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <EditIcon
                  width={20}
                  height={20}
                  fill={theme.primaryButtonTextColor}
                />
                {t("Edit Team")}
              </span>
            </PrimaryButtonLarge>
          ) : (
            <></>
          )}
          <SlideOut
            show={showEditTeamForm}
            closeFlyout={() => setShowEditTeamForm(false)}
          >
            {team?.products && team?.users && team?.team_name && (
              <SellerAdminEditTeam
                onSuccess={onEditSuccess}
                onCancel={() => setShowEditTeamForm(false)}
                products={team.products}
                users={team.users}
                teamName={team.team_name}
              />
            )}
          </SlideOut>
        </div>
      )}
    </PageWrapper>
  );
}
