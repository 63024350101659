import { TabList as ReachTabList } from "@reach/tabs";
import { screenSize } from "../../theme";
import styled from "styled-components/macro";

/**
 * Documentation: https://reach.tech/tabs/#tablist
 */
interface ITabListProps {
  width?: string;
}
export const TabList = styled(ReachTabList)<ITabListProps>`
  display: flex;
  height: 49px;
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
  max-width: ${({ width }) => (width ? width : "fit-content")};
  overflow: auto;
  @media ${screenSize.mLarge} {
    svg {
      display: none;
    }
  }
`;
