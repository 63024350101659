import React, { useState } from "react";
import { EditIntroduction } from "./EditIntroduction";
import { EditTopRightWithPositioning } from "../EditTopRight/EditTopRightWithPositioning";
import { SlideOut } from "../SlideOut/SlideOut";
import styled from "styled-components/macro";
import type { IHomePageData } from "../../pages/public/HomePage/HomePage";
import { NothingToDisplay } from "../NothingToDisplay/NothingToDisplay";
import { StyledP } from "../Typography/Typography";

export interface IntroductionParagraphProps {
  homePageData: IHomePageData;
  isEditable: boolean;
}

export interface IIintroductionParagraphData {
  desc: string;
}

const FullBleedGray = styled.section`
  position: relative;
  white-space: pre-wrap;
  display: flex;

  p {
    // The width of the content wrapper
    padding-top: 20px;
    padding-bottom: 30px;
  }
`;

export const IntroductionParagraph = ({
  homePageData,
  isEditable,
}: IntroductionParagraphProps) => {
  const [editMode, setEditMode] = useState(false);
  const startEditMode = () => setEditMode(true);
  const endEditMode = () => setEditMode(false);

  const component = homePageData.config.components.find(
    (component) => component.name === "introduction"
  );
  const data = component?.data;
  const description = data?.desc;

  if (!description) {
    return <NothingToDisplay text={"Add an introductory description here"} />;
  }

  return (
    <>
      <SlideOut closeFlyout={endEditMode} show={editMode}>
        <EditIntroduction
          endEditMode={endEditMode}
          homePageData={homePageData}
        />
      </SlideOut>
      <FullBleedGray>
        <StyledP>{description}</StyledP>
      </FullBleedGray>

      {isEditable && <EditTopRightWithPositioning onClick={startEditMode} />}
    </>
  );
};
