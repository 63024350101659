import React, { Children, cloneElement } from "react";
import type { TabPanelProps } from "@reach/tabs";
import { TabPanel as ReachTabPanel } from "@reach/tabs";

/**
 * Custom TabPanel that ensures that a key is added to Child elements.
 * This ensures that on tab visible, it's data is refreshed.
 * refreshKey should be a piece of state that is updated in the parent when the
 * tab changes.
 * @see https://github.com/reach/reach-ui/issues/828
 * @param props: {@link TabPanelProps} & {refreshKey: string | number | undefined}
 */
export const CustomTabPanel = ({
  refreshKey: key,
  children,
  ...props
}: TabPanelProps & { refreshKey: string | number | undefined }) => (
  <ReachTabPanel {...props}>
    {Children.map(children, (child) =>
      child &&
      typeof child !== "string" &&
      typeof child !== "number" &&
      typeof child !== "boolean" &&
      "type" in child
        ? cloneElement(child, { key })
        : child
    )}
  </ReachTabPanel>
);
