import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { endpoints } from "../../endpoints";
import type {
  DeliveryTermPaginatedOutput,
  PaymentTermPaginatedOutput,
  PaymentModePaginatedOutput,
  AgilisUsersPaginatedOutput,
  Lead,
  SampleRequestStageTwo,
  Tenant,
} from "../../types/types";
import { useCountriesList, useStatesList } from "../../util/Locations";
import {
  makeDeliveryTermsGetEndpoint,
  makePaymentTermsGetEndpoint,
  makePaymentModesGetEndpoint,
  convertDeliveryTermToOption,
  convertPaymentTermToOption,
  convertPaymentModeToOption,
  useStoreState,
  makeUrlWithParams,
  convertUserToOption,
} from "../../util/util";
import { DelayedSpinner } from "../DelayedSpinner/DelayedSpinner";
import { ErrorPlaceholder } from "../Error";
import { Notifications } from "../Notifications/NotificationsContext";
import { CreateNewTenantForm } from "./CreateNewTenantForm";
import { getCorrectCountry } from "./CreateNewTenantForm.util";

export function CreateNewTenantFormContainer(props: {
  sellerTenantId: string;
  lead?: Lead | SampleRequestStageTwo;
  onSuccess: (tenantId: string) => void;
  sampleRequestMode?: true;
  type: "Buyer" | "Distributor";
}) {
  const { storefront_id } = useStoreState();
  const { notifyError } = useContext(Notifications);
  const TEMPORARY_HIGH_LIMIT_FIX_ME = 100;

  const { t } = useTranslation();

  const countries = useCountriesList();

  const { data: fetchedDeliveryTerms, isValidating: isFetchingDeliveryTerms } =
    useSWR<DeliveryTermPaginatedOutput>(
      makeDeliveryTermsGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError(t("There was an error fetching the shipping terms."), {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const { data: fetchedPaymentTerms, isValidating: isFetchingPaymentTerms } =
    useSWR<PaymentTermPaginatedOutput>(
      makePaymentTermsGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError(t("There was an error fetching the payment terms."), {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const { data: fetchedPaymentModes, isValidating: isFetchingPaymentModes } =
    useSWR<PaymentModePaginatedOutput>(
      makePaymentModesGetEndpoint(storefront_id),
      {
        onError: (error) => {
          notifyError(t("There was an error fetching the payment methods."), {
            error,
          });
        },
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    );

  const {
    data: usersData,
    error: userError,
    isValidating: isFetchingUsers,
  } = useSWR<AgilisUsersPaginatedOutput>(
    props.sellerTenantId
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_tenants_id_users(
            storefront_id,
            props.sellerTenantId
          ),
          {
            offset: 0,
            // TODO: this won't work if there are more than 100 users.
            limit: TEMPORARY_HIGH_LIMIT_FIX_ME,
          }
        )
      : null,
    {
      onError: (error) => {
        notifyError(t("There was an error fetching users."), { error });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const {
    data: tenant,
    error: tenantError,
    isValidating: isFetchingTenantData,
  } = useSWR<Tenant>(
    endpoints.v1_storefronts_id_tenants_id(storefront_id, props.sellerTenantId),
    {
      onError: (error) => {
        notifyError(t("There was an error fetching seller tenant data."), {
          error,
        });
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const originalStatesList = useStatesList(getCorrectCountry(props.lead));

  const users = usersData?.data.filter((user) => user.is_active);
  const userOptions = users?.map(convertUserToOption) ?? [];

  const deliveryTermsOptions =
    fetchedDeliveryTerms?.data.map(convertDeliveryTermToOption) ?? [];

  const paymentTermsOptions =
    fetchedPaymentTerms?.data.map(convertPaymentTermToOption) ?? [];

  const paymentModesOptions =
    fetchedPaymentModes?.data.map(convertPaymentModeToOption) ?? [];

  const isFetchingData =
    countries.length === 0 ||
    isFetchingTenantData ||
    isFetchingUsers ||
    isFetchingDeliveryTerms ||
    isFetchingPaymentModes ||
    isFetchingPaymentTerms ||
    // the states hook needs to be called at top level, but it might not return
    // anything. Don't want to hang in an infinite loading state if the lead
    // exists but it has not address.
    (originalStatesList.length === 0 &&
      props.lead &&
      // The two possible places "state" might exist
      ("buyer_address" in props.lead ||
        ("address" in props.lead && props.lead.address !== null)));

  if (userError || tenantError) {
    return (
      <ErrorPlaceholder
        message={t("There was an error loading the data for the form")}
      />
    );
  }

  if (isFetchingData) {
    return <DelayedSpinner />;
  } else {
    return (
      <CreateNewTenantForm
        {...props}
        tenant={tenant!}
        countries={countries}
        originalStatesList={props.lead === undefined ? [] : originalStatesList}
        userOptions={userOptions}
        deliveryTermsOptions={deliveryTermsOptions}
        paymentTermsOptions={paymentTermsOptions}
        paymentModesOptions={paymentModesOptions}
        type={props.type}
      />
    );
  }
}
