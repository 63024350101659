import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  PrimaryButtonFitContainer,
  CancelButton,
} from "../../../components/Buttons/Buttons";
import { KeyValueDisplay } from "../../../components/KeyValueDisplay";
import { PriceTiersTable } from "../../../components/PriceTiersTable/PriceTiersTable";
import {
  calculateTotalQuantity,
  PriceOffersHeader,
} from "../../../components/quoteCart/BuyerQuoteItemForm";
import {
  customPackagingOption,
  QuantityFormPartCustomSku,
  QuantityFormPartProductSku,
} from "../../../components/quoteCart/BuyerQuoteItemFormParts";
import { SelectBoxV2 } from "../../../components/SelectBoxV2/SelectBoxV2";
import { TextField } from "../../../components/TextFields/TextFields";
import { endpoints } from "../../../endpoints";
import type {
  OptionType,
  ProductSKU,
  IPriceTier,
  CurrencyCode,
  Product,
  UUID,
} from "../../../types/types";
import {
  calculatePriceTiersPricePerUom,
  calculatePriceForQuantity,
} from "../../../util/QuotesAndOrders";
import {
  usePackagingUnits,
  packagingTypeOrUnitToOption,
  useStorefrontPackagingTypes,
} from "../../../util/SkuUtils";
import {
  useStoreState,
  useCurrencySymbol,
  convertProductSKUToOption,
} from "../../../util/util";
import { formatPrice } from "../../../util/util-components";
import type { CustomSkuData } from "../../SharedPages/SellerQuoteDetailPage/AddCustomSkuForm";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { strings } from "../../../util/strings";
import { SearchSelectInfiniteScroll } from "../../../components/SearchSelectInfiniteScroll/SearchSelectInfiniteScroll";
import type { PIMProductBase } from "../../../types/types.PIM";
import { ProductApplicationSelect } from "../../../components/ProductApplicationSelect/ProductApplicationSelect";

/**
 * Heavily derived from `FormInputs` in `BuyerQuoteItemForm`
 * `price_per_uom` will only exist if price tiers don't.
 */
export type AddOrEditProductFormOutput = {
  application?: OptionType<string> | OptionType<null>;
  custom_application?: string;
  // The actual inputs in the form depend on what the user selects for packaging.
  // We put all of them here, some as optional, and then enforce things with
  // form validation. (I tried creating a stricter type but it led to too many
  // type checking errors related to react-hook-form, e.g. for default arguments.)
  packaging: OptionType<ProductSKU> | OptionType<"custom_packaging">;
  total_quantity: string;
  no_of_units: string;
  price_per_unit: string;
  unit_of_measure?: OptionType<string>;
  custom_packaging_type?: OptionType<string>;
  custom_packaging_quantity?: string;
  product_id: string;
};

export type InlineDisplayCustomSKU = Omit<
  CustomSkuData,
  "package_description"
> & { kind: "Buyer SKU" };

type AddOrEditProductFormProps = {
  priceTiers: IPriceTier[] | undefined;
  isDisabled: boolean;
  currencyCode: CurrencyCode;
  product: Product | null;
  setProduct: React.Dispatch<React.SetStateAction<Product | null>>;
  isEditMode: boolean;
  onCancel: () => void;
  buyer_tenant_id: UUID;
};

export function AddOrEditProductForm({
  priceTiers,
  isDisabled,
  currencyCode,
  product,
  setProduct,
  isEditMode,
  onCancel,
  buyer_tenant_id,
}: AddOrEditProductFormProps) {
  const methodsOfUseForm = useFormContext();
  const { control, errors, formState, watch, register, setValue } =
    methodsOfUseForm;
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { packagingUnits } = usePackagingUnits();
  const packagingUnitOptions = packagingUnits
    ? packagingUnits?.map(packagingTypeOrUnitToOption)
    : [];

  //TODO: is this the correct storefront?
  const { packagingTypes } = useStorefrontPackagingTypes();
  const packagingTypeOptions = packagingTypes
    ? packagingTypes?.map(packagingTypeOrUnitToOption)
    : [];

  const currencySymbol = useCurrencySymbol(currencyCode);

  const { packaging: packagingOption, no_of_units: numberOfUnits } = watch([
    "packaging",
    "no_of_units",
  ]);

  const handleProductChange = (option: OptionType<Product>) => {
    setValue("product_id", option.value.id);
    if (product) {
      setValue("unit_of_measure", { value: undefined, label: undefined });
      setValue("packaging", { value: undefined, label: undefined });
      setValue("custom_packaging_quantity", null);
      setValue("no_of_units", null);
      setValue("total_quantity", null);
      setValue("disabled_uom", null);
      setValue("price_per_unit", null);
    }
    setProduct(option.value);
  };

  useEffect(() => {
    register({ name: "product_id", required: true });
  }, [register]);

  const priceTiersExist = priceTiers && priceTiers.length > 0;

  const productSkuOptions =
    product &&
    product.product_skus
      .filter((sku) => !sku.is_sample)
      .map(convertProductSKUToOption);

  const packagingOptions = (() => {
    if (product && productSkuOptions && product.allow_custom_sku) {
      return [...productSkuOptions, customPackagingOption];
    } else if (productSkuOptions) {
      return [...productSkuOptions];
    }
  })();

  // If the user selects a product SKU then packagingSkuOption won't be null.
  const packagingSkuOption =
    packagingOption && packagingOption.value !== "custom_packaging"
      ? packagingOption
      : null;

  const pricePerUom =
    priceTiersExist && priceTiers && numberOfUnits !== undefined
      ? calculatePriceTiersPricePerUom(priceTiers, parseFloat(numberOfUnits))
      : null;

  const pricePerUomString =
    pricePerUom === null ? "--" : formatPrice(pricePerUom, currencyCode);

  const totalQuantity = calculateTotalQuantity(
    packagingSkuOption?.value?.package_volume,
    numberOfUnits
  );

  const totalPrice =
    totalQuantity === null || pricePerUom === null
      ? null
      : calculatePriceForQuantity(pricePerUom, totalQuantity);

  const totalPriceString =
    totalPrice === null ? "--" : formatPrice(totalPrice, currencyCode);

  const submitButtonIsDisabled = (() => {
    // Don't ask me why the check before length is needed.
    if (priceTiers && priceTiers.length > 0) {
      if (numberOfUnits) {
        if (
          calculatePriceTiersPricePerUom(priceTiers, parseFloat(numberOfUnits))
        ) {
          return false;
        } else return true;
      }
    } else return false;
  })();

  // We don't want user to be able to change product when editing, instead they
  // should remove it from the order and create a new item.
  const productSelectIsDisabled = isDisabled || isEditMode;

  const noOptionsMessage = ({ inputValue }: { inputValue: string }) =>
    t("No SKUs are available for this Product");

  return (
    <>
      <>
        <SearchSelectInfiniteScroll
          name={"product_id"}
          errors={errors}
          formState={formState}
          placeholder={t("Product")}
          value={{
            value: product,
            label: product ? product.name : null,
          }}
          baseUrl={endpoints.v2_tenants_id_pim_products_summary(tenant_id)}
          params={(() => {
            const params = new URLSearchParams();
            params.append("order_by", "asc");
            params.append("status", "published");
            params.append("include_skus", "true");
            params.append("include_applications", "true");
            if (buyer_tenant_id) {
              params.append("buyer_tenant_id", buyer_tenant_id);
            }
            return params;
          })()}
          getOptions={(products: PIMProductBase[]) =>
            products.map((product) => ({ value: product, label: product.name }))
          }
          onChange={(data: OptionType<Product>) => handleProductChange(data)}
          isDisabled={productSelectIsDisabled}
          testid={"master-product-search"}
        />
        <div>
          <Controller
            as={SelectBoxV2}
            control={control}
            isDisabled={isDisabled}
            name="packaging"
            placeholder={t("Preferred SKU")}
            options={packagingOptions}
            rules={{ required: true }}
            errors={errors}
            formState={formState}
            noOptionsMessage={noOptionsMessage}
          />
        </div>

        {packagingOption?.value === "custom_packaging" && (
          <>
            <QuantityFormPartCustomSku
              methodsOfUseForm={methodsOfUseForm}
              unitOfMeasureOptions={packagingUnitOptions}
              packagingTypeOptions={packagingTypeOptions}
            />
          </>
        )}
        {packagingSkuOption && (
          <>
            <QuantityFormPartProductSku
              isDisabled={isDisabled}
              methodsOfUseForm={methodsOfUseForm}
              unitOfMeasure={packagingSkuOption?.value?.packaging_unit?.name}
              skuPackageVolume={packagingSkuOption?.value?.package_volume}
            />
          </>
        )}
        {priceTiersExist && priceTiers && (
          <>
            <PriceOffersHeader>
              {t("You have price offers from the seller:")}
            </PriceOffersHeader>
            <PriceTiersTable tiers={priceTiers} />
          </>
        )}
        {priceTiersExist && (
          <KeyValueDisplay
            data={[
              {
                key: `Price (${currencySymbol}/UoM)`,
                value: pricePerUomString,
              },
              {
                key: t("Total Price"),
                value: totalPriceString,
              },
            ]}
          />
        )}
        {!priceTiersExist && (
          <TextField
            name={"price_per_unit"}
            label={`Price (${currencySymbol}/UoM)`}
            disabled={isDisabled}
            theref={register({
              required: true,
            })}
            errors={errors}
            formState={formState}
            type="number"
          />
        )}
        <ProductApplicationSelect
          methodsOfUseForm={methodsOfUseForm}
          applications={product?.product_applications ?? []}
        />
      </>
      <PrimaryButtonFitContainer
        data-tip={!submitButtonIsDisabled ? "" : strings(t).priceTierMatchError}
        data-for={"price-tiers-warning"}
      >
        {isEditMode ? t("Save Changes") : t("Add product")}
      </PrimaryButtonFitContainer>
      <ReactTooltip
        delayShow={1000}
        id="price-tiers-warning"
        delayHide={500}
        effect="solid"
      />
      {isEditMode && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CancelButton onClick={onCancel}>{t("Cancel")}</CancelButton>
        </div>
      )}
    </>
  );
}
