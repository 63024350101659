// "google-libphonenumber" is a dependency of "yup-phone".
import type { Alpha2, OptionType } from "../types/types";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

type HardcodedPhoneList = {
  code: Alpha2;
  phoneCode: string;
};
const countryPhoneCodeList: HardcodedPhoneList[] = [
  { code: "CA", phoneCode: "+1" },
  { code: "US", phoneCode: "+1" },
  { code: "AG", phoneCode: "+1" },
  { code: "BS", phoneCode: "+1" },
  { code: "BB", phoneCode: "+1" },
  { code: "DO", phoneCode: "+1" },
  { code: "JM", phoneCode: "+1" },
  { code: "PR", phoneCode: "+1" },
  { code: "KN", phoneCode: "+1" },
  { code: "LC", phoneCode: "+1" },
  { code: "VC", phoneCode: "+1" },
  { code: "TT", phoneCode: "+1" },
  { code: "EG", phoneCode: "+20" },
  { code: "ZA", phoneCode: "+27" },
  { code: "GR", phoneCode: "+30" },
  { code: "NL", phoneCode: "+31" },
  { code: "BE", phoneCode: "+32" },
  { code: "FR", phoneCode: "+33" },
  { code: "ES", phoneCode: "+34" },
  { code: "HU", phoneCode: "+36" },
  { code: "IT", phoneCode: "+39" },
  { code: "RO", phoneCode: "+40" },
  { code: "CH", phoneCode: "+41" },
  { code: "AT", phoneCode: "+43" },
  { code: "GB", phoneCode: "+44" },
  { code: "DK", phoneCode: "+45" },
  { code: "SE", phoneCode: "+46" },
  { code: "NO", phoneCode: "+47" },
  { code: "PL", phoneCode: "+48" },
  { code: "DE", phoneCode: "+49" },
  { code: "PE", phoneCode: "+51" },
  { code: "MX", phoneCode: "+52" },
  { code: "AR", phoneCode: "+54" },
  { code: "BR", phoneCode: "+55" },
  { code: "CL", phoneCode: "+56" },
  { code: "CO", phoneCode: "+57" },
  { code: "VE", phoneCode: "+58" },
  { code: "MY", phoneCode: "+60" },
  { code: "AU", phoneCode: "+61" },
  { code: "ID", phoneCode: "+62" },
  { code: "PH", phoneCode: "+63" },
  { code: "NZ", phoneCode: "+64" },
  { code: "SG", phoneCode: "+65" },
  { code: "TH", phoneCode: "+66" },
  { code: "JP", phoneCode: "+81" },
  { code: "KR", phoneCode: "+82" },
  { code: "VN", phoneCode: "+84" },
  { code: "CN", phoneCode: "+86" },
  { code: "TR", phoneCode: "+90" },
  { code: "IN", phoneCode: "+91" },
  { code: "NG", phoneCode: "+234" },
  { code: "PT", phoneCode: "+351" },
  { code: "IE", phoneCode: "+353" },
  { code: "FI", phoneCode: "+358" },
  { code: "EE", phoneCode: "+372" },
  { code: "CZ", phoneCode: "+420" },
  { code: "BZ", phoneCode: "+501" },
  { code: "GT", phoneCode: "+502" },
  { code: "SV", phoneCode: "+503" },
  { code: "HN", phoneCode: "+504" },
  { code: "NI", phoneCode: "+505" },
  { code: "CR", phoneCode: "+506" },
  { code: "PA", phoneCode: "+507" },
  { code: "HT", phoneCode: "+509" },
  { code: "BO", phoneCode: "+591" },
  { code: "GY", phoneCode: "+592" },
  { code: "EC", phoneCode: "+593" },
  { code: "PY", phoneCode: "+595" },
  { code: "UY", phoneCode: "+598" },
  { code: "KH", phoneCode: "+855" },
  { code: "TW", phoneCode: "+886" },
  { code: "SA", phoneCode: "+966" },
  { code: "AE", phoneCode: "+971" },
  { code: "IL", phoneCode: "+972" },
  { code: "QA", phoneCode: "+974" },
];

export const phoneNumberUtil = new PhoneNumberUtil();

/**
 * The `.parse` method can throw, so use a try/catch.
 */
export function formatPhoneNumber(phoneNumber: string, countryCode: string) {
  try {
    const formatted = phoneNumberUtil.format(
      phoneNumberUtil.parse(phoneNumber, countryCode),
      PhoneNumberFormat.NATIONAL
    );
    return formatted;
  } catch (error) {
    return phoneNumber || "--";
  }
}

export const getPhoneNumber = (phoneNumber: string | undefined) => {
  if (phoneNumber) {
    /**
     * handle existing saved phone numbers as a US phone numbers if the phone number doesn't include country code
     */
    const phone_number =
      phoneNumber?.charAt(0) === "+" ? phoneNumber : `+1${phoneNumber}`;
    try {
      return String(phoneNumberUtil.parse(phone_number).getNationalNumber());
    } catch (e) {
      console.error(e);
      return phoneNumber;
    }
  }
  return undefined;
};

export const getCountryCode = (phoneNumber: string | undefined) => {
  if (phoneNumber) {
    /**
     * handle existing saved phone numbers as a US phone numbers if the phone number doesn't include country code
     */
    const phone_number =
      phoneNumber?.charAt(0) === "+" ? phoneNumber : `+1${phoneNumber}`;
    try {
      return `+${phoneNumberUtil.parse(phone_number).getCountryCode()}`;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
};

/**
 * A temporary fix for getting country code string from the country code number
 * for form validation purposes.
 */
export const countryCodeMap = countryPhoneCodeList.reduce((acc, value) => {
  acc.set(value.phoneCode, value.code);
  return acc;
}, new Map());

/**
 * filters out duplicate phoneCodes so that "+1" only appears once in the
 * dropdown. "+1" is in there twice so that prefilling based on the selected
 * "country" still works.
 */
export const getPhoneCodesOptions = () => {
  const storage: Record<string, boolean> = {};
  return countryPhoneCodeList.reduce((acc, country) => {
    if (!storage[country.phoneCode]) {
      acc.push({
        value: country.phoneCode,
        label: country.phoneCode,
      });
    }

    storage[country.phoneCode] = true;

    return acc;
  }, [] as OptionType[]);
};

export const getPhoneCodeOption = (
  countryCode: string | null
): OptionType<string> | {} => {
  if (countryCode) {
    return getPhoneCodesOptions().find(
      (phoneCodeOption) => phoneCodeOption.value === countryCode
    ) as OptionType<string>;
  }
  return {};
};

export const formateInternationalPhoneNumber = (phoneNumber: string) => {
  try {
    const phone_number = () => {
      if (phoneNumber && phoneNumber.charAt(0) === "+") {
        return phoneNumber;
      } else if (phoneNumber && phoneNumber.charAt(0) !== "+") {
        return `+1${phoneNumber}`;
      }
      return phoneNumber;
    };

    return phoneNumberUtil.format(
      phoneNumberUtil.parse(phone_number()),
      PhoneNumberFormat.INTERNATIONAL
    );
  } catch (e) {
    console.error(e);
    return phoneNumber;
  }
};

export function getPhoneOptionByAlpha2(code: Alpha2 | null | undefined) {
  if (!code) {
    return { value: "", label: "" };
  }
  let value = {};
  // eslint-disable-next-line array-callback-return
  countryPhoneCodeList.find((obj) => {
    if (obj.code === code) {
      value = {
        value: obj.phoneCode,
        label: obj.phoneCode,
      };
    }
  });
  return value;
}
